import { Component, Injector, OnInit, Pipe, PipeTransform, ChangeDetectorRef, ViewChild, HostListener, AfterContentInit, Renderer2, Inject } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { FileDownloadService } from '@shared/utils/file-download.service';
import {
    CableproServiceProxy, CableproXmlInput, InsulationDto, ConductorDto, CoreDto, PhaseDto, InstallTableDto, SpecifyConductorDto,
    UiControlsLookupDto, WindSpeedDto, CalculationReportInput, SettingOutput, CustomereportFieldsInput, UserLoginInfoDto,
    DRFServiceServiceProxy, DRFWizrd, TableItem, CalculationInput, WizardServiceProxy, NetworkWizardFactorOutput, LoadData, LoadItem, LoadInput, SubGroupInput, NetworkWizardFactorInput, ArmourDto, BSDRFServiceServiceProxy, DeviceOutput, SubscriptionServiceProxy
} from '@shared/service-proxies/service-proxies';
import { ArcFlashLabelDiagramModel } from '../models/arcflash.model';
import {
    ListProtectiveInput, ProtectiveXmlInput, ManufacturerOutput, MCBOutput, BreakingOutput,
} from '@shared/service-proxies/service-proxies';
import { CableproCalculationServiceProxy, MaxDemandData, SettingsData } from '@shared/service-proxies/service-proxy-calc';
import { LoaderService } from './../loader.service';
import { Papa } from 'ngx-papaparse';
import { MessageService } from 'abp-ng2-module/dist/src/message/message.service';
import { GetCurrentLoginInformationsOutput } from '@shared/service-proxies/service-proxies';
import { ProtectiveData, ProtectiveItem } from '@shared/service-proxies/service-proxies';
import { debounceTime } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { PdfReportServiceProxy } from '@shared/service-proxies/service-proxy-rpt';
import * as moment from 'moment';
import { SettingService, DRFService, CalculationService, ProductService } from '../../../../services/project.service';
import * as _ from 'lodash';
import { ITreeOptions, KEYS, TreeComponent, TreeModel, TreeNode, TREE_ACTIONS } from 'angular-tree-component';
import { CablePullingXmlInput } from '../models/cablePulling-xml-input.model';
import { CablePullingModel } from '../models/cablePulling.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ArcFlashData } from '../models/arcflash.model';
import { CableSizeCalcInput, ErrorWarningModel, MaxDemandCalcInput, Model, NetworkCalcInputXML, NetworkCapacitorBank, NetworkHVRelay, NetworkLoad, NetworkSettings, NetworkSwitchBoard, PowerFactorCorrection, ProtectionConfigurationModel, SupplySettings, Table22Model, Table23InstallationModel, Table23Model, Table25Model, Table271Model, Table28Model, Table29Model } from '../models/network-calc.model';
import { CompanyAccountService } from '@shared/service-proxies/company-account.service';
import { ConfirmationModalComponent } from '@app/shared/common/confirmation-modal/confirmation-modal.component';
import { AppSessionService } from '@shared/common/session/app-session.service';

@Pipe({ name: 'symbol' })
export class CustomName2 implements PipeTransform {
    transform(name: string) {
        let val = name.split(' ')[0];
        let unit = name.split(' ')[1];
        return val + ' ' + unit;
    }
}
@Component({
    templateUrl: './networkcalc.component.html',
    animations: [appModuleAnimation()],
    styleUrls: ['../loader.component.css']
})
export class NetworkCalcComponent extends AppComponentBase implements OnInit, AfterContentInit {

    @ViewChild(TreeComponent, { static: true })
    private tree: TreeComponent;
    public tempinputXML: NetworkCalcInputXML = new NetworkCalcInputXML();
    arcFlashEventSubject: Subject<any> = new Subject();
    public maxDemandDataArray: MaxDemandCalcInput[] = [];
    public loadId: number = 0;
    public cableCalculationStandard: string = '0';
    public settingCalculationStandard: string = '0';
    public oldCableCalculationStandard: string = '0';
    public cableCalcProtectiveDeviceSource: string = '1';
    public oldCableCalcProtectiveDeviceSource: string = '1';
    public protectionCoordinationStandard: string = '0';
    public reportType: number = 0;
    public allLabelStrings = [];
    public messageReport: Subscription;
    public allLabelNameStrings = [];
    public CollectionLabelStrings = [];
    public CollectionLabelNameStrings = [];
    public categorySources: any = [
        { "id": "0", "text": "Electrical Arc Flash Hazard Management Guideline" },
        { "id": "1", "text": "NFPA 70E-2024 Table 130.7(C)(15)(c)" },
        { "id": "2", "text": "NFPA 70E-2024 Table 130.5(G)" },
        { "id": "3", "text": "NFPA 70E-2024 Table H.2" },
        { "id": "4", "text": "DGUV 203-077" },
        { "id": "5", "text": "Custom from file" }
    ]
    public earthConductorData: any = [
        { "id": 0, "text": "Same as phase conductor" },
        { "id": 1, "text": "Copper" },
        { "id": 2, "text": "Aluminium" }
    ]
    public calculationMethods: any = [
        { "id": "0", "text": "IEEE 1584-2018 (AC)" },
        { "id": "1", "text": "ENA NENS 09-2014 (AC)" },
        { "id": "2", "text": "DGUV 203-077 (AC)" },
        { "id": "3", "text": "DGUV 203-077 (DC, iterative)", disabled: true },
        { "id": "4", "text": "Stokes method (DC)", disabled: true },
        { "id": "5", "text": "Maximum power method (DC)", disabled: true }
    ]
    public standardSettingsData: any;
    public protectionCoordinationStandardData: any;
    public templist: NetworkLoad[] = [];
    public tempswitchlist: any[] = [];
    public tempcapacitorlist: any[] = [];
    public temppowerfactorcorrections: any[] = [];
    public tempcablesizelist: any[] = [];
    public temppowerfactorlist: any[] = [];
    public temphvrelaylist: any[] = [];
    public isCableCalValue: boolean = false;
    public isCableProtectionValue: boolean = false;
    public isCableSectionValue: boolean = false;
    public isInsulationChange: boolean = false;
    public isInstallationStatus: boolean = false;
    public phaseDropdownIndex: any;
    public coreDropdownIndex: any;
    public insulatorDropdownIndex: any;
    public isHideGroupasCircuit: boolean = true;
    public cableDropdownIndex: any;
    public earthConductorDropdownIndex: any;
    public armourDropdownIndex: any;
    public resultFaultImpedence: any[] = [];
    public protectiveDeviceSourceArray: any[] = [];
    public arcFlashResultArray: any[] = [];
    public arcFlashResultHeader: any[] = [];
    public loadScheduleArray: any[] = [];
    public resultAccumulatedvoltage: any[] = [];
    public resultSectionCableData: any[] = [];
    public resultFaultLevel: any[] = [];
    public resultMaxDemand: any[] = [];
    public resultSectionPowerfactor: any[] = [];
    public resultVoltageDrop: any[] = [];
    public resultMaxDemandtable: any[] = [];
    public accumulatedLevel: any;
    public cableLevel: any;
    public sectionFaultLevel: any;
    public maxDemandLevel: any;
    public powerFactorLevel: any;
    public voltageDropLevel: any;
    public SupplyResultsArray: any[] = [];
    public generatorResultsArray: any[] = [];
    public transformerResultsArray: any[] = [];
    public networkFeederResultsArray: any[] = [];
    public powerStationResultsArray: any[] = [];
    public switchboardResultsArray: any[] = [];
    public factorResults: any;
    public Cablesizecalculationsresult: any;
    public ArcFlashResult: any;
    public ArcFlashCompleteResult: any;
    public powerFactorResultsArray: any[] = [];
    public cableSizeResultsArray: any[] = [];
    public hvRelayResultsArray: any[] = [];
    public maxDemandResultsArray: any[] = [];
    public childrenNodesArray: any[] = [];
    public capcitorCount: number = 1;
    public modelChanged = new Subject<NetworkCalcInputXML>();
    public protectiveModelChanged = new Subject<DeviceOutput>();
    public showgeneratorResult: boolean = false;
    public showFeederResult: boolean = true;
    public showPowerStationResult: boolean = false;
    public showTransformerResult: boolean = false;
    public showPowerFactorResult: boolean = false;
    public showSwitchboardResult: boolean = false;
    public isMaxDemandDataShown: boolean = false;
    public powerFactorClusterData: any[] = [];


    public calculationInput: CalculationInput = new CalculationInput();
    public wizardFactorInput: NetworkWizardFactorInput = new NetworkWizardFactorInput();
    public wizardFactorInputList: NetworkWizardFactorInput[] = [];
    public wizardFactorOutput: NetworkWizardFactorOutput[] = [];
    public mode: string = 'add';
    public transformerHVRelay: boolean = false;
    public HVFuse: boolean = false;
    public HvFuseXml: string = '';
    public HvFuseTrippingXml: string = '';
    public supplyTypeReopenData: boolean = false;
    public powerHVRelay: boolean = false;
    public isHVRelay: boolean = false;
    public isXmlTabShown: boolean = false;
    public insulationObj: any;
    public conductorObj: any;
    public coreObj: any;
    public phaseObj: any;
    public faultLevel: any;
    public insulationData: InsulationDto[] = [];
    public conductorData: ConductorDto[] = [];
    public coreData: CoreDto[] = [];
    public phaseData: PhaseDto[] = [];
    public armourData: ArmourDto[] = [];
    public protectiveXmlInput: ProtectiveXmlInput = new ProtectiveXmlInput();
    public protectiveXmlInputList: ProtectiveXmlInput[] = [];
    public installTableData: InstallTableDto[] = [];
    public specifyConductorData: SpecifyConductorDto[] = [];
    public uiControlsLookUpData: UiControlsLookupDto[] = [];
    public windSpeedData: WindSpeedDto[] = [];
    public PrevInsulationId = 0;
    public PrevConductorId = 0;
    public PrevEarthConductorId = 0;
    public PrevCoreId = 0;
    public PrevArmourId = 0;
    public PrevPhaseId = 0;
    public selectedValmodel: number = 0;
    public selectedNetworkmodel: number = 0;
    public TransformerHVRelaymodel: boolean = false;
    public CalculateXandR: string = "0";
    public CalculateTransformerXandR: string = "0";
    public Impedencecorrectionmodel: boolean;
    public ngModelHVChecked = false;
    public PrevImageIndex = 0;
    public CableproInput = new Model();
    public hasWind: boolean;
    public TableId: number;
    public ShowActive = false;
    public showActiveLab = true;
    public ShowNeutral = false;
    public ShowNeutralLab = true;
    public ShowEarth = false;
    public ShowEarthLab = true;
    public CableSize = {};
    public PrevCableSize = null;
    public Xml: any;
    public PrevActiveValue: any;
    public PrevNeutralValue: any;
    public PrevMCBVal: any = '';
    public inc: number = 1
    public PrevMCCBVal: any = '';
    public PrevACBVal: any = '';
    public PrevHRCVal: any = '';
    public PrevCurveVal: any = '';
    public PrevCustomeRatingVal: any = '';
    public showLable = false;
    public active_res: any = '-';
    public neutral_res: any = '-';
    public earth_res: any = '-';
    public voltage_res: any = '-';
    public voltage_res1: any = '-';
    public faultLoop_res: any = '-';
    public conduit_res: any = '-';
    public loadCurrent: any = '-';
    public loadPowerFactor: any = '-';
    public supplyVoltageDrop: any = '-';
    public supplyFaultLevel: any = '-';
    public supplyType: number = 2;
    public NodesStringData: string = '';
    public Circuitrating_drated: any;
    public Currentrating_cable_tabulated: any;
    public Currentrating_cable_tabulated_Ref_Desc_TableNo: any;
    public Currentrating_cable_tabulated_Ref_Desc_ColumnNo: any;
    public CCC_Currentrating_cable_tabulated_Operating_temperature: any;
    public VoltageDrop_RefAndDesc_Resistance_TableNo: any;
    public VoltageDrop_RefAndDesc_Resistance_ColumnNo_MinTemp: any;
    public VoltageDrop_RefAndDesc_Resistance_ColumnNo_MaxTemp: any;
    public VoltageDrop_RefAndDesc_Reactance_TableNo: any;
    public VoltageDrop_RefAndDesc_Reactance_ColumnNo: any;
    public VoltageDrop_Data_PhaseConductor_Resistance: any;
    public VoltageDrop_Data_PhaseConductor_Reactance: any;
    public VoltageDrop_Result_MaxLengthOfRun: any;
    public ShortCircuitPerformance_PhaseConductor_MinActive_Cond_Size: any = '-';
    public ShortCircuitPerformance_PhaseConductor_kfactor: any = '-';
    public ShortCircuitPerformance_Short_cct_Currents_PhaseShort_cct: any = '-';
    public ShortCircuitPerformance_NuetralConductor_MinNuetral_Cond_Size: any = '-';
    public ShortCircuitPerformance_NuetralConductor_Kfactor: any = '-';
    public ShortCircuitPerformance_EarthConductor_MinEarth_Cond_Size: any = '-';
    public ShortCircuitPerformance_EarthConductor_Kfactor: any = '-';
    public ShortCircuitPerformance_EarthConductor_PhaseToEarthShort_cct: any = '-';
    public CircuitCurrentrating_Adequate: any;
    public VoltageDrop_Result_VoltageDrop_Acceptable: any;
    public EarthLoop_ok: any;
    public MaxAllowedDevice: any;
    public ConditSizeFound: any;

    public ConditCableDiameter: any;
    public ConditNoofCablesPerCondit: any;
    public ConditSpaceFactorActual: any;
    public ConditSpaceFactorStandard: any;
    public newCalculationId: number;
    public projectId: number;
    public cableproXmlInput: CableproXmlInput = new CableproXmlInput();
    public dc_Voltage: string;
    public onePhase_Voltage: string;
    public threePhase_Voltage: string;
    public flag = false;
    public Spare_current_capacity_of_circuit: any = '-';
    public active_NoOf_circuit: any = '1';
    public neutral_NoOf_circuit: any = '1';
    public earth_NoOf_circuit: any = '1';

    public faultloop1: any;
    public faultloop2: any;
    public faultloop3: any;
    public faultloop4: any;
    public faultloop5: any;
    public faultloop6: any;
    public firstElement: any;
    public lastElement: any;
    public newwizardInstallationtype = 0;

    //calculation report
    public CalculationReportInput: CalculationReportInput = new CalculationReportInput();
    public OriDrfWizard: DRFWizrd;
    //report fileds
    public ReportShowLogo: boolean = false;
    public ReportShowCustomFields: boolean = false;
    public ReportLogoFileName: string = '';
    public ReportCustomFields: any;
    public ReportStandardCountry: string = '';
    public isBritishStandard: boolean = false;
    public vdrop: any;
    public vdroppecent: any;
    public vdropPermissibale: any;
    public referencetempdegCReport: any;
    public standardCountryReport: any;
    public calculation_type: string = "1";
    public calculation_standard: string = "0";//AUS
    public activeCondz1Report: any;
    public Earthcondz2Report: any;
    public settingOutput: SettingOutput = new SettingOutput();
    public companyLogo_ShowLogo: boolean;
    public customeReportFields_ShowCustomField: boolean;
    public companyLogo_FileName: string;
    public customFields: CustomereportFieldsInput[] = [];
    public calculationName: string;
    public projectName: string;
    public VoltageDrop_Data_NeutralConductor_Resistance: any;
    public VoltageDrop_Data_NeutralConductor_Reactance: any;
    public validProjectName = false;
    public validJobName = false;
    public Three_phaseShort_circuitCurrent: any;
    public Active_to_EarthShort_circuitCurrent: any;
    public tempDeracting: string;
    public currentUnit: string = "1";
    public tempRating: string;
    public showEarthButton = true;
    public showEfficency = false;
    public user!: UserLoginInfoDto | undefined;
    public premiumUser!: boolean;
    public planLevel: string | number = '';
    public CableSizeCount = 1;
    public cableSizeReopenData: any;
    public networkCalculationResponse: any;
    public isCableSizeModified: boolean = false;
    public isButtonShown: boolean = false;
    public addRemoveNode: boolean = false;
    private isPlanAddedManually: boolean = false;
    public isCableSizeCalcStatus: boolean = false;
    public isNewNodeAdded: boolean = false;

    public showreportloader: boolean = false;
    public coordinationloader: boolean = false;
    public protectivecoordinationoutputxml: string = '';
    public isInputAPIStatus: boolean = false;
    public showScroll: boolean = false;
    public loadStatus: boolean = false;
    public resetStatus: boolean = false;
    public isXmlStatus: boolean = true;
    public switchboardColumncount: number = 0;
    public isCapacitorAdded: boolean = true;
    public drInstallType: number | 1;
    public drBunchingType: number | 1;
    public objectType: number | 1;
    public objectId: number = 3;
    public drBunchingCore: number | 1;

    public requestCount: number = 0;
    public responseCount: number = 0;
    public insulationValues: any;

    public table22model: Table22Model;
    public drTable22output: any;
    public drTable271output: any;
    public drTable25output: any;
    public drTable22ArrangementValues: any;
    public drTable22ArrangementValuesBs: any;
    public drTable22CircuitValues: any;

    public table271model: Table271Model;
    public conductorTempValues: any;

    public table25model: Table25Model;
    public table25NoOfCircuitValues: any;
    public table25NoOfCircuitValuesBS: any;

    public table25CableTypeValues: any;
    public table25ArrangementValues: any;
    public table25ArrangementValuesBS: any;

    public table28model: Table28Model;
    public table28output: any;
    public table28ConductorSizeValues: any;
    public displayCurrentError!: boolean;
    public wizard: any;

    public table29model: Table29Model;
    public table29output: any;
    public table29InstallationValues: any;


    private selectInstallTableMethodIndex: any;
    private selectInstallTableTableId: any;
    private selectInstallTableName: string;
    private selectInstallTableDescription: string;
    private selectInstallTableVoltagedropTable: string;
    private selectInstallTableCurrentTable: string;

    public table23model: Table23Model;
    public table23output: any;
    public table23RowValues: any;
    public table23RowValuesBs: any;
    public table23CableValues: any;
    public loading: boolean;
    public isCalculating: boolean;
    public finalDrf: number;
    public UserWizard: boolean;
    public voltageSupplyLabel: string = '';
    public lblcurrent: string = 'Current';
    public prevVoltageSupplyLabel: string = '';
    public toRemoveData: any;
    private SelectInstallTableMethodIndex: any;
    private SelectInstallTableTableId: any;
    private SelectInstallTableName: string;
    private SelectInstallTableDescription: string;
    public powerunit: string = '1';
    public efficiency: number = 100;
    public cablePullingXmlInput: CablePullingXmlInput = new CablePullingXmlInput();
    public cablePullingData: CablePullingModel;
    spanclass: string = 'fa fa-caret-square-o-right';
    success: boolean = true;
    selnode: any;
    maxcount: number;
    executionTime: string;
    totaltime: string;

    matrix: any;
    matrixdata: any;
    allnodes: any;
    allnodesid: number = 0;
    switchboardcount: number = 0;
    loadcount: number = 0;
    capacitorcount: number = 0;
    allnodesinfo: any;
    public isMobile = true;
    public tabId: string = '';
    public sldbase64string: string;
    public isScrollforCableSize_MaxDemand = false;

    selectedNetworkData: any;
    selectedTreeNode: any;
    supplyNetworkData: any;

    ListProtectiveInputCurrentObj: ListProtectiveInput;



    //AUTOMAX
    public LoadDataXml: LoadData = new LoadData();

    public modalHeader: string = 'Add Load';
    public editLoadGroupId: number;
    public isSubGroupShown: boolean = false;
    public editSubGroupId: number;

    public LoadStorage: LoadItem = new LoadItem();
    public Loaddata: LoadInput[] = [];
    public SubData: SubGroupInput[] = [];

    public showError: boolean = false;
    public showQuantityError: boolean = false;
    public showMaxError: boolean = false;
    public editDataIndex: number;
    public treeNodeId: number;
    public treeNodeName: string = 'cb';

    public dataSet: any;
    public PhaseR: string;
    public PhaseW: string;
    public PhaseB: string;
    public MaximumDemand: string = '0';
    public PhaseImbalance: string;

    public maxDemandData: MaxDemandData = new MaxDemandData();
    public CalculationId: number;
    public installId: number;
    public electricInstallationValues: any;
    public isCustomInstallation: boolean = false;
    public rules: string = '';
    public isHideForLoadTypes: boolean = true;
    public isPowerFactor: boolean = true;
    public isHideQty: boolean = false;
    public isBlockInstallation: boolean = false;
    public phaseAllocationValues: any;
    public isShowPhaseQty: boolean = false;
    public lable2: string = '';
    public isFactor: boolean = true;
    public nodeChildIndex: any;
    // public editRowData: any;
    private deleteLoadId: number = 0;
    public total: number;
    public LoadDataXmlnew: LoadData = new LoadData();
    public HVRelayTrippingCurrentValue: any = "0";
    public requiredCompensation: any = "0";
    public capacitorRating: any = "0";
    public powerFactorBeforeCorrection: any = "";
    public powerFactorAfterCorrection: any = "";
    public HVRelayObjectData: any;

    public installationName: string = '';
    public lable1: string = '';
    public ratingLab: string = '';
    public allSubGroup: SubGroupInput[] = [];
    public resultSectionData: number = 8;
    public capacitorRatingArray = [
        { 'value': '2.5' },
        { 'value': '5' },
        { 'value': '7.5' },
        { 'value': '10' },
        { 'value': '15' },
        { 'value': '20' },
        { 'value': '25' },
        { 'value': '35' },
        { 'value': '50' },
        { 'value': '60' },
        { 'value': '100' }
    ]
    public MaxDisconnectionTimeOptions: Array<any>;
    public EarthingSystemOptions: Array<any> = [
        { id: 'TN', text: 'TN' },
        { id: 'TT', text: 'TT' },
    ];


    options: ITreeOptions = {
        displayField: 'name',
        isExpandedField: 'expanded',
        idField: 'id',
        hasChildrenField: 'children',

        actionMapping: {

            mouse: {
                drop: (tree: TreeModel, node: TreeNode, $event: any, { from, to }) => {

                    if (from.data.name == "Generator" ||
                        from.data.name == "Transformer" ||
                        from.data.name == "Power station" ||
                        from.data.name == "Network feeder" ||
                        from.data.name == "Existing switchboard"
                        ||
                        from.data.name == "Main Switchboard") {
                        return false;

                    }
                    else if (to.parent.data.name == "Generator" ||
                        to.parent.data.name == "Transformer" ||
                        to.parent.data.name == "Power station" ||
                        to.parent.data.name == "Network feeder" ||
                        to.parent.data.name == "Existing switchboard"
                        ||
                        to.parent.data.objecttype == "2"
                        ||
                        to.parent.data.objecttype == "3") {
                        return false;
                    }
                    else {

                        var toid = to.parent.data.id;
                        var toobjectcode = to.parent.data.objectcode;
                        var tochildindex = to.parent.data.children.length;

                        tree.moveNode(from, to);
                        this.tree.treeModel.update();
                        this.updateparentidandchildindexbyId(this.items, from.data.id, toobjectcode, tochildindex);
                        // update to node parent id and childindex


                        this.allnodes = [];
                        this.matrix = [];
                        this.getallnodes(this.items[0]);
                        this.printArray(this.items[0]);
                        this.onInputChange();

                        return true;
                    }
                }
                ,
                dblClick: (tree, node, $event) => {
                    // click: TREE_ACTIONS.TOGGLE_ACTIVE_MULTI
                    if ($event.srcElement.className.indexOf("data__name") == -1) {
                        if (node.hasChildren) TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
                    }
                },
                click: (tree, node, $event) => {
                    if ($event.srcElement.className.indexOf("data__name") == -1) {
                        if (node.hasChildren) TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
                        $event.shiftKey
                            ? TREE_ACTIONS.TOGGLE_ACTIVE_MULTI(tree, node, $event)
                            : TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event);
                    }
                    else {
                        $event.shiftKey
                            ? TREE_ACTIONS.TOGGLE_ACTIVE_MULTI(tree, node, $event)
                            : TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event);
                    }
                }
            },
            keys: {
                [KEYS.ENTER]: (tree, node, $event) => {
                    node.expandAll();
                }
            }
        },
        nodeHeight: 1,
        allowDrag: true,
        allowDrop: true,
        allowDragoverStyling: true,
        levelPadding: 10,
        useVirtualScroll: true,
        animateExpand: true,
        scrollOnActivate: true,
        animateSpeed: 30,
        animateAcceleration: 1.2,
        scrollContainer: document.documentElement // HTML
    };

    public chartData: any;
    testOutput: Observable<any>;
    stringVar = new Subject<number>();
    stringVar$ = this.stringVar.asObservable();
    activeNode: any;
    IsCableCalcStandardChanged: boolean = false;
    discriminatedData: any = null;
    warningErrorStrData: string = '';
    warningErrorBtnText: string = "Warnings";
    warningErrorHeader: string = "Errors";
    warningErrorList: ErrorWarningModel[] = [];
    warningErrorListPristine: ErrorWarningModel[] = [];
    isProtectiveConfigChanged: boolean = false;
    protectionConfigurationModel: ProtectionConfigurationModel = new ProtectionConfigurationModel();
    protectionConfigurationModel_Pristine: ProtectionConfigurationModel = new ProtectionConfigurationModel();
    // protectionCoordinationAnnotations: any;
    paginationConfig = {
        id: 'error-pagination',
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: 4
    };
    displayErrorBtn: boolean = false;
    coordinationDisplayErrorBtn: boolean = false;
    coordinationWarningErrorStrData: string = '';
    coordinationWarningErrorBtnText: string = "Warnings";
    coordinationWarningErrorHeader: string = "Errors";
    coordinationWarningErrorList: ErrorWarningModel[] = [];
    coordinationPaginationConfig = {
        id: 'coordination-error',
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: 4
    };
    // protection variables
    public loadProtectiveXmlInput: ProtectiveXmlInput = new ProtectiveXmlInput();
    public defaultManId: number = 104;
    public defaultFamilyId: string = "";
    public defaultModelId: number = 0;
    public FilterBreaking: number = 5;
    public xmlData: string = "";
    public xmlData_Original: string = "";
    public xmlData_Tripping: string = "";
    public Tripping_Xml_Original: string = "<Cluster><Name>Tripping calculations</Name><NumElts>2</NumElts><Cluster><Name>Tripping time calculation</Name><NumElts>1</NumElts><DBL><Name>Current (A)</Name><Val>0</Val></DBL></Cluster><Cluster><Name>Tripping current calculation</Name><NumElts>1</NumElts><DBL><Name>Time (s)</Name><Val>[Tripping_time]</Val></DBL></Cluster></Cluster>";
    public defaultRatingId: string = "";
    public defaultCurveId: string = "";
    public model: string;
    public isloadedfirst: boolean = true;
    public isloadfirsttime: boolean = true;
    public rpcaption: string = "Rating plug";
    public outputXmlData: string = "";
    public trippingCurrentAtSpecifiedTime: string = "";
    public trippingCurrentAtSpecifiedTime_Original: string = "";
    public dimSizeData: any;

    // public deviceno: number = 0;
    public selectListFive: string = 'Curve Class';
    public curveClassOrTripUnit: string = 'Curve Class';

    public slidertmir: string = '';
    public slidertmircalc: string = '';
    public slidertmiroptions: any;

    public slidertmim: string = '';
    public slidertmimcalc: string = '';
    public slidertmimoptions: any;

    public slidermim: string = '';
    public slidermimcalc: string = '';
    public slidermimoptions: any;
    public slidercurrenttolerance: string = '';
    public slidercurrenttolerancecalc: string = '';
    public slidercurrenttoleranceoptions: any;

    public slidertimetolerance: string = '';
    public slidertimetolerancecalc: string = '';
    public slidertimetoleranceoptions: any = '';

    public slidertimetobreak: string = '';
    public slidertimetobreakcalc: string = '';
    public slidertimetobreakoptions: any = '';
    public rp: string = "";

    public slidereio: string = '';
    public slidereiocalc: string = '';
    public slidereiooptions: any = '';

    public slidereir: string = '';
    public slidereircalc: string = '';
    public slidereiroptions: any = '';

    public slideridmtl: string = '';
    public slideridmtlcalc: string = '';
    public slideridmtloptions: any = '';


    public slideretr: string = '';
    public slideretrcalc: string = '';
    public slideretroptions: any = '';

    public slidereisd: string = '';
    public slidereisdcalc: string = '';
    public slidereisdoptions: any = '';

    public slideretsd1: any = '1';
    public slideretsdcalc: string = '';
    public slideretsdoptions: any = '';

    public slidereli: string = '';
    public sliderelicalc: string = '';
    public sliderelioptions: any = '';
    public multiplierEIR: number = 0;
    public multiplierEIO: number = 0;
    public txtrating: string = '';
    public txtmodal: string = '';
    public txtfamily: string = '';
    public txtTripUnit: string = '';


    public txtratingfuse: string = '';
    public txtmodalfuse: string = '';
    public txtfamilyfuse: string = '';
    public txtTripUnitfuse: string = '';
    public selectedModalId: number = 0;
    
    public RCD: boolean = false;
    public protectiveUnknown: boolean = false;
    public Isfromunknownchk: boolean = false;

    public multiplierIN: number = 0;
    public deviceType: string = "1";
    public IsFormvalid: boolean = true;
    public IsGeneric: boolean = true;
    public searchResult$: Observable<DeviceOutput>;
    public btnAddDeviceDisabled: boolean = false;
    public btnAddDeviceRequestCounter: number = 0;
    public breakingOutput: BreakingOutput[] = [];
    public polesTableOutput: MCBOutput[] = [];
    public manufacturerOutput: ManufacturerOutput[] = [];
    public filesOutput: ManufacturerOutput[] = [];
    public familyOutput: ManufacturerOutput[] = [];
    public selectedFamily: ManufacturerOutput = new ManufacturerOutput();
    public modelOutput: ManufacturerOutput[] = [];
    public deviceOutput: DeviceOutput = new DeviceOutput();
    public mcbOutput: MCBOutput[] = [];
    public curveOutput: MCBOutput[] = [];
    public ratingPlugList: MCBOutput[] = [];
    public ddlmodel: number;
    public gmanufacturerId: number = 0;
    public gmodelId: number = 0;
    public index: number = 0;
    public modifyindex: number = -1;
    public cindex: number = 0;
    public crownumber: number = 0;
    public showFilter: boolean = false;
    public gfamilyId: string = "";

    public ProtectiveLoadStorage: ProtectiveItem = new ProtectiveItem();
    public ListLoadStorage: ProtectiveItem[] = [];
    public ProtectiveLoadDataXml: ProtectiveData = new ProtectiveData();
    public chkexportlimited: boolean = false;
    public hvRelayCurve: any;
    public hvFuseCurve: any;
    public HVFuseCurveMultiplier: any;

    public ddlmodelChanged = new Subject<DeviceOutput>();

    public voltage_txt: string;
    public devices: any = [
        { "id": '0', "text": "Unknown" },
        { "id": '1', "text": "MCB" },
        { "id": '2', "text": "MCCB" },
        { "id": '3', "text": "ACB" },
        { "id": '4', "text": "HRC Fuse" },
        { "id": '5', "text": "Custom" }
    ]
    public device: string = "0";
    public NeutralDisabled: boolean = false;

    public earthList: any = [
        { "id": '1', "text": "1 mm²" },
        { "id": '1.5', "text": "1.5 mm²" },
        { "id": '2.5', "text": "2.5 mm²" },
        { "id": '4', "text": "4 mm²" },
        { "id": '6', "text": "6 mm²" },
        { "id": '10', "text": "10 mm²" },
        { "id": '16', "text": "16 mm²" },
        { "id": '25', "text": "25 mm²" },
        { "id": '35', "text": "35 mm²" },
        { "id": '50', "text": "50 mm²" },
        { "id": '70', "text": "70 mm²" },
        { "id": '95', "text": "95 mm²" },
        { "id": '120', "text": "120 mm²" },
        { "id": '150', "text": "150 mm²" },
        { "id": '185', "text": "185 mm²" },
        { "id": '240', "text": "240 mm²" },
        { "id": '300', "text": "300 mm²" },
        { "id": '400', "text": "400 mm²" },
        { "id": '500', "text": "500 mm²" },
        { "id": '630', "text": "630 mm²" },

    ]

    public SupplyVoltage: any = "0";
    public SupplyVoltageList: any = [
        { "id": '0', "text": "230/400 (Wye)" },
        { "id": '1', "text": "240/415 (Wye)" },
        { "id": '2', "text": "254/440 (Wye)" },
        { "id": '3', "text": "220/380 (Wye)" },
        { "id": '4', "text": "400/690 (Wye)" },
        { "id": '5', "text": "380/660 (Wye)" },
        { "id": '6', "text": "1000 (Wye)" }
    ]

    public Frequency: any = "0";
    public FrequencyList: any = [
        { "id": '0', "text": "50" },
        { "id": '1', "text": "60" }
    ]

    public VoltageTolerance: any = "0";
    public VoltageToleranceList: any = [
        { "id": '0', "text": "+10 %" },
        { "id": '1', "text": "+6 %" }
    ]

    public CableCalcStandardList: any = [
        { "id": '0', "text": "Australian Standard AS/NZS 3008.1.1" },
        { "id": '1', "text": "New Zealand Standard AS/NZS 3008.1.2" },
        { "id": '2', "text": "British Standard BS 7671" },
        { "id": '3', "text": "International Standard IEC 60364-5-52" }
    ]

    public CableCalcProtectiveDeviceSourceList: any = [
        { "id": '1', "text": "Manufacturer devices with time-current curves" },
        { "id": '2', "text": "Simple protective devices (legacy setting)" }
    ]

    public ProtectionCoordinationStandardList: any = [
        { "id": '0', "text": "Australian Standard AS/NZS 3000" },
        { "id": '1', "text": "British Standard BS 7671" }
    ]

    public TransformerTypicalDataSource: any = "0";
    public TransformerTypicalDataSourceList: any = [
        { "id": '0', "text": "IEC 60076 + Areva book" },
        { "id": '1', "text": "IEEE C57.12 + Beeman book" }
    ]

    public networksupplytype: any = "0";
    public NetworkSupplyTypeList: any = [
        { "id": '0', "text": "Network feeder" },
        { "id": '1', "text": "Existing switchboard" },
        { "id": '2', "text": "Transformer" },
        { "id": '3', "text": "Generator" },
        { "id": '4', "text": "Power station" },
    ]

    public Autosizetransformer: boolean = true;
    public Customtransformer: boolean = false;
    public Impedencecorrection: boolean = false;

    public Transformer_Calculation_Method: any = "0";
    public Transformer_Calculation_Methods: any = [
        { "id": '0', "text": "Load losses" },
        { "id": '1', "text": "X/R ratio" }
    ]

    public Autosizegenerator: boolean = true;
    public Customgenerator: boolean = false;
    public GeneratorImpedencecorrection: boolean = false;

    public Autosizepower: boolean = true;
    public PowerStationCustomgenerator: boolean = false;
    public PowerStationCustomtransformer: boolean = false;
    public PowerStationImpedencecorrection: boolean = false;
    public Power_Station_Calc_Transformer_XandR: any = "0";

    public selectResults: string = "8";
    public ResultList: any = [
        { "id": '8', "text": "Maximum demand at switchboards" },
        { "id": '0', "text": "Fault level at switchboards" },
        { "id": '1', "text": "Voltage drop of cables" },
        { "id": '2', "text": "Accumulated voltage drop" },
        { "id": '3', "text": "Fault loop impedance of cables" },
        { "id": '5', "text": "Switchboards schedule" },
        { "id": '4', "text": "Cables schedule" },
        { "id": '9', "text": "Protective devices schedule" },
        { "id": '7', "text": "Loads schedule" },
        { "id": '6', "text": "Capacitor banks schedule" },
        { "id": '10', "text": "Arc flash results" },
    ]

    @ViewChild('confirmationModal')
    modalComponent!: ConfirmationModalComponent;

    public innerWidth: any;
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }
    constructor(
        injector: Injector,
        private route: ActivatedRoute,
        private papa: Papa,
        private _messageService: MessageService,
        private _CableproService: CableproServiceProxy,
        private _CableproCalc: CableproCalculationServiceProxy,
        private _settingService: SettingService,
        private loaderService: LoaderService,
        private _appSessionService: AppSessionService,
        private ref: ChangeDetectorRef,
        private drfService: DRFServiceServiceProxy,
        private drfServicebs: BSDRFServiceServiceProxy,
        private _settingServiceProxy: SettingService,
        private _pdfReportSerivceProxy: PdfReportServiceProxy,
        private drfGetService: DRFService,
        private _calculationService: CalculationService,
        private _wizardService: WizardServiceProxy,
        private _router: Router,
        private cd: ChangeDetectorRef,
        private _renderer2: Renderer2,
        private _companyAccountService: CompanyAccountService,
        private _fileDownloadService: FileDownloadService,
        private _projectService: ProductService,
        private _subscriptionService: SubscriptionServiceProxy,
        @Inject(DOCUMENT) private _document: Document

    ) {
        super(injector);
        (window as any).networkCalc = this;



        this.toRemoveData = this.route.params.subscribe((params) => {
            this.LoadStorage.installationId = 5;
            sessionStorage.setItem("nodecount", "0");
            sessionStorage.setItem("loadlist", "0");
            sessionStorage.setItem("switchlist", "0");
            sessionStorage.setItem("capacitorlist", "0");
            sessionStorage.setItem("hvrelaylist", "0");
            sessionStorage.setItem("cablesizelist", "0");
            sessionStorage.setItem("powerfactorlist", "0");
            this.tempinputXML.NetworkSettings = new NetworkSettings();
            this.tempinputXML.NetworkSettings.CategorySource = "0";
            this.tempinputXML.NetworkSettings.CalculationMethod = "0";
            this.tempinputXML.NetworkSettings.ppeCategoriesTable = [];
            this.tempinputXML.NetworkSettings.ppeCategoriesTableHeaders = [];
            this.tempinputXML.NetworkSettings.IsArcFlash = false;
            this._settingService.hideArcFlashReport = true;
            var startTimes = performance.now();


            this.newCalculationId = parseInt(params.cid);
            this.CalculationId = parseInt(params.cid);
            this.projectId = parseInt(params.pid);
            this.calculationName = this.route.snapshot.queryParamMap.get('cname');

            this._settingService.hidenavbar();
            this.projectName = this.route.snapshot.queryParamMap.get('name');
            this.CalculationReportInput.projectname = this.projectName;
            this.CalculationReportInput.jobNo = this.calculationName;
            this._calculationService.getVoltageDrop().subscribe(result => {
                this.voltageSupplyLabel = result;
                this.prevVoltageSupplyLabel = result;

                if (this.voltageSupplyLabel === undefined || this.voltageSupplyLabel === null) {
                    this.voltageSupplyLabel = 'drop';
                    this.prevVoltageSupplyLabel = 'drop';
                }
                if (this.voltageSupplyLabel == 'rise') {

                    if (this.currentUnit == "1") {
                        this.lblcurrent = 'Output current';
                    }
                    else {
                        this.lblcurrent = 'Output power';
                    }
                }
            });

            this.modelChanged.pipe(
                debounceTime(3000))
                .subscribe(() => {
                    //alert()




                    this.tempinputXML.treeItems = JSON.stringify(this.items);
                    this.tempinputXML.networkTree = this.items[0];
                    this.tempinputXML.calculationId = this.CalculationId;
                    this.tempinputXML.browserName = this.detectBrowserName();
                    this.tempinputXML.UserName = this.user.userName;
                    this.requestCount++;
                    this._CableproCalc
                        .NetworkCalculation(this.tempinputXML).subscribe((response) => {

                            this.networkCalculationResponse = response;
                            let calculationRes = response.calculationResponse;
                            if (calculationRes == 1) {
                                if (response.errorstring) {
                                    this.warningErrorStrData = JSON.stringify(response.errorstring);
                                    this.generateErrorsModel(response.errorstring);
                                }

                                if (response.HVrelaycurve) {
                                    this.hvRelayCurve = response.HVrelaycurve;
                                }
                                else
                                    this.hvRelayCurve = undefined;

                                if (response.HVfusecurve) {
                                    this.hvFuseCurve = response.HVfusecurve;
                                }
                                else
                                    this.hvFuseCurve = undefined;

                                if (response.HVfusecurvemultiplier) {
                                    this.HVFuseCurveMultiplier = response.HVfusecurvemultiplier;
                                }
                                else
                                    this.HVFuseCurveMultiplier = undefined;

                                //  this.showLoader();
                                this.isNewNodeAdded = true;
                                var endTimes = performance.now();
                                var totalTimes = endTimes - startTimes; // time took to run in milliseconds
                                this.sldbase64string = response.sld;

                                this.executionTime = response.executionTimeDll;
                                this.GetAllResultSectionValues(response);
                                if (response.dxfstring) {
                                    this.textToFileDownload(this.calculationName + ".dxf", response.dxfstring);
                                }
                                this.tempinputXML.isSingleLineDiagram = 0;
                                this.tempinputXML.isDXF = 0;
                                if (this.newCalculationId) {
                                    if (this.isXmlStatus === true) {
                                        if (navigator.onLine === true) {
                                            //this.showLoader();
                                            this.cablePullingXmlInput = new CablePullingXmlInput();
                                            this.cablePullingXmlInput.id = this.newCalculationId;
                                            //  this.cablePullingXmlInput.TreeValue = JSON.stringify(this.items);
                                            this.cablePullingXmlInput.TreeValue = response.treeItems;
                                            this.cablePullingXmlInput.InputxmlValue = response.input;
                                            this.cablePullingXmlInput.OutputxmlValue = response.output;
                                            this.cablePullingXmlInput.SwitchboardCount = response.SwitchboardCount;
                                            //alert(this.cablePullingXmlInput.SwitchboardCount);
                                            // this.switchBoardCount = parseInt(this.cablePullingXmlInput.SwitchboardCount);
                                            this.cablePullingXmlInput.Supply = response.Supply;
                                            this.cablePullingXmlInput.cableSizeData = response.CableSizeCalcInputs;
                                            this.cablePullingXmlInput.sld = this.sldbase64string;
                                            // if (this.maxDemandDataArray.length > 0) {
                                            //     this.cablePullingXmlInput.maxDemandCalcData = JSON.stringify(this.tempinputXML.MaxDemandDataArray);
                                            // }
                                            if (response.MaxDemandDataArray != undefined && response.MaxDemandDataArray != null && response.MaxDemandDataArray.length > 0) {
                                                this.cablePullingXmlInput.maxDemandCalcData = JSON.stringify(response.MaxDemandDataArray);
                                            }

                                            if (this.HVFuse == true) {
                                                this.cablePullingXmlInput.HVFuseXml = this.HvFuseXml;
                                                this.cablePullingXmlInput.HVFuseTrippingXml = this.HvFuseTrippingXml;
                                            }
                                            else {
                                                this.cablePullingXmlInput.HVFuseXml = undefined;
                                                this.cablePullingXmlInput.HVFuseTrippingXml = undefined;
                                            }
                                            //alert(this.cablePullingXmlInput.TreeValue);
                                            this.cablePullingXmlInput.HVFuseXml = this.tempinputXML.SupplySettings.HvFuseXml;
                                            this.cablePullingXmlInput.HVFuseTrippingXml = this.tempinputXML.SupplySettings.HvFuseTrippingXml;

                                            this.UpdateXmlValues(this.cablePullingXmlInput);
                                        } else {
                                            //this.hideLoader();;
                                            $("#popup-mask").show();
                                        }
                                    }
                                    //this.hideLoader();;
                                }
                                this.isXmlStatus = true;
                                this.totaltime = totalTimes.toString();
                            }
                            else if (calculationRes == 2) {
                                if (response.errorstring) {
                                    this.warningErrorStrData = JSON.stringify(response.errorstring);
                                    this.generateErrorsModel(response.errorstring);
                                }
                            }
                            this.responseCount++;
                            if (this.requestCount == this.responseCount) {
                                this.hideLoader();
                                this.options.allowDrag = true;
                                this.options.allowDrop = true;
                                this.addRemoveNode = false;
                            }
                        },
                            (error) => {
                                this.responseCount++;
                                this.hideLoader();
                                this.options.allowDrag = true;
                                this.options.allowDrop = true;
                                this.addRemoveNode = false;
                            });

                });


            this.protectiveModelChanged
                .pipe(
                    debounceTime(300))
                .subscribe(() => {
                    // this.setFilterBreaking();

                    this.GetModelGroupByManufacturerIdnew(this.gmanufacturerId, this.gfamilyId, this.FilterBreaking);
                })
            this.ddlmodelChanged
                .pipe(
                    debounceTime(300))
                .subscribe(() => {
                    this.GetRatingByManufacturerId(this.gmanufacturerId, this.gfamilyId, this.gmodelId, true);

                })
            this.getSettingReportsMaxDemand();
        });

        // MAX Demand

    }


    public dropdownEnabled = true;
    public items = [{
        name: "Network feeder", objecttype: -1, objectcode: "NF0", id: 1, class: 'fa fa-plus-square-o', img: '/assets/cablepro/img/Network_Feeder.png', childindex: -1, parentid: 0, children: [{
            name: "Main Switchboard", objecttype: 1, objectcode: "MSB01", id: 2, class: 'normal', img: '/assets/cablepro/img/Main_switchboard.png', parentid: "NF0", childindex: 0, children: [],
            // name:"Main Switchboard",objecttype:0,objectcode:"MSB",id:2,class:'normal',img:'/assets/cablepro/img/mainswitchboard.png',childindex:0,children:[]

        }]

    }];
    public secondParentNodeId: string = '';
    public insertrow: boolean = false;
    public objectCount: number = 2;
    public functionCallCount: number = 0;
    public insertrow2: boolean = false;
    public values: number[];
    // public config = TreeviewConfig.create({
    //     hasAllCheckBox: false,
    //   hasFilter: false,
    //   hasCollapseExpand: false,
    //   decoupleChildFromParent: false,
    //   maxHeight: 400
    // });

    buttonClasses = [
        'btn-outline-primary',
        'btn-outline-secondary',
        'btn-outline-success',
        'btn-outline-danger',
        'btn-outline-warning',
        'btn-outline-info',
        'btn-outline-light',
        'btn-outline-dark'
    ];
    buttonClass = this.buttonClasses[0];

    onNetworkOptionsSelected(value: any) {
        //alert(value);
    }
    selectchild(selectedval) {
        this.tempinputXML.isSingleLineDiagram = 0;
        this.isScrollforCableSize_MaxDemand = false;
        this.showScroll = true;
        this.isInputAPIStatus = false;
        this.selectedNetworkmodel = selectedval;
        this.isCableSectionValue = true;
        this.isCableSizeCalcStatus = true;
        this.isMobile = true;
        $('#maxdemandtable').hide();
        $('#faultleveltable').hide();
        $('#loadScheduletable').hide();
        $('#protectiveDeviceSourcetable').hide();
        $('#Voltagedroptable').hide();
        $('#Accumulatedvoltagedroptable').hide();
        $('#Faultloopimpedancetable').hide();
        $('#Cablesdatatable').hide();
        $('#Maximumdemandtable').hide();
        $('#Powerfactorcorrectiontable').hide();
        $('#Resultschild').hide();
        $('#ResultsSelection').hide();
        $("#calculationresultstab").hide();
        $("#arcFlashResulttable").hide();
        // $("#calculationresulttab2").hide();
        $('#isCableSizeShown').css('display', 'none');

        //Network
        if (selectedval == 0) {
            this.showScroll = true;
            // $('#networkinputs').css('width', '40%');

            if (window.innerWidth < 1025) {
                $('#networkinputs').css('width', '100%');
            } else {
                $('#networkinputs').css('width', '40%');
            }

            $('#tempresults').css('display', 'block');

            $('#SwitchboardHead').css('display', 'none');
            $("#MaxDemandHead").css('display', 'none');
            $("#ArcFlashHead").css('display', 'none');
            $("#CableSizeHead").css('display', 'none');
            $('#SingleLineDiagramTab').css('display', 'none');
            $('#Resultschild').css('display', 'none');
            $('#ResultsSelection').css('display', 'none');


            $('#LoadHead').css('display', 'none');
            $('#CapacitorHead').css('display', 'none');
            $('#PowerfactorHead').css('display', 'none');

            $('#Settingschild').css('display', 'none');
            $('#Supplychild').css('display', 'none');
            //$('#HVRelayHead').css('display', 'none');


            this.showgeneratorResult = false;
            this.showFeederResult = false;
            this.showPowerStationResult = false;
            this.showTransformerResult = false;
            this.showPowerFactorResult = false;
            this.showSwitchboardResult = true;

            if ((sessionStorage.getItem("currentnode") == null) || (sessionStorage.getItem("currentnode") == undefined)) {
                sessionStorage.setItem("currentnode", JSON.stringify(this.items[0].children[0]));
                this.selectedNetworkData = this.items[0].children[0];
            }
            else {
                var selectedData = JSON.parse(sessionStorage.getItem("currentnode"));
                this.selectedNetworkData = selectedData;
                if (selectedData.objecttype == -1) {
                    sessionStorage.setItem("currentnode", JSON.stringify(this.items[0].children[0]));
                    this.selectedNetworkData = this.items[0].children[0];
                }
            }

            if (JSON.parse(sessionStorage.getItem("currentnode")).objecttype == 1) {

                $('#isCableSizeShown').css('display', 'block');
                //$("#calculationresulttab2").show();
                $("#calculationresultstab").show();

                $('#SwitchboardHead').css('display', 'block');
                $('#MaxDemandHead').css('display', 'block');
                $('#CableSizeHead').css('display', 'block');
                $('#ArcFlashHead').css('display', 'block');
                document.getElementById('networktab3').style.display = "block";

                let a_element: HTMLElement = document.getElementById('aSwitchboard') as HTMLElement;
                a_element.click();
                $(".SwitchboardHead").click();

            }
            else if (JSON.parse(sessionStorage.getItem("currentnode")).objecttype == 2) {

                $('#LoadHead').css('display', 'block');
                $('#CableSizeHead').css('display', 'block');
                document.getElementById('networktab4').style.display = "block";

                let a_element: HTMLElement = document.getElementById('aLoad') as HTMLElement;
                a_element.click();
                $(".LoadHead").click();
            }
            else if (JSON.parse(sessionStorage.getItem("currentnode")).objecttype == 3) {

                this.showgeneratorResult = false;
                this.showFeederResult = false;
                this.showPowerStationResult = false;
                this.showTransformerResult = false;
                this.showPowerFactorResult = false;
                this.showSwitchboardResult = false;

                $('#CapacitorHead').css('display', 'block');
                $('#PowerfactorHead').css('display', 'block');
                $('#CableSizeHead').css('display', 'block');

                document.getElementById('networktab5').style.display = "block";
                let a_element: HTMLElement = document.getElementById('aCapacitor') as HTMLElement;
                a_element.click();
                $(".CapacitorHead").click();
            }
        }
        //Network Settings
        else if (selectedval == 1) {
            this.showScroll = false;
            this.isMobile = false;

            $('#Settingschild').css('display', 'block');
            $('#tempresults').css('display', 'block');
            $('#networkinputs').css('width', '90%');

            document.getElementById('defaultOpen').click();

            $('#SingleLineDiagramTab').css('display', 'none');

            $('#Supplychild').css('display', 'none');

            $('#networkcalctab').css('display', 'none');


            //$('#HVRelayHead').css('display', 'none');

            $('#SwitchboardHead').css('display', 'none');
            $("#MaxDemandHead").css('display', 'none');
            $("#CableSizeHead").css('display', 'none');
            $("#ArcFlashHead").css('display', 'none');

            $('#LoadHead').css('display', 'none');
            $('#CapacitorHead').css('display', 'none');
            $('#PowerfactorHead').css('display', 'none');
            let a_element: HTMLElement = document.getElementById('Settingschild') as HTMLElement;
            a_element.click();

        }
        //SUPPLY TAB
        else if (selectedval == 2) {
            $('#powerStationTreeview').css('display', 'none');

            this.showScroll = false;
            //WRONG IDs
            let ddllistitems: any[] = [
                { id: 0, name: 'Network feeder', },
                { id: 1, name: 'Existing switchboard', },
                { id: 2, name: 'Transformer', },
                { id: 3, name: 'Generator', },
                { id: 4, name: 'Power station', }
            ];

            $('#Supplychild').css('display', 'block');
            $('#tempresults').css('display', 'block');

            // $('#networkinputs').css('width', '40%');

            $('#SingleLineDiagramTab').css('display', 'none');

            $('#Settingschild').css('display', 'none');

            $('#networkcalctab').css('display', 'none');

            //$('#HVRelayHead').css('display', 'none');

            $('#SwitchboardHead').css('display', 'none');
            $("#MaxDemandHead").css('display', 'none');
            $("#CableSizeHead").css('display', 'none');
            $("#ArcFlashHead").css('display', 'none');

            $('#LoadHead').css('display', 'none');
            $('#CapacitorHead').css('display', 'none');
            $('#PowerfactorHead').css('display', 'none');
            //$("#calculationresulttab2").show();
            $('#isCableSizeShown').css('display', 'block');
            $("#calculationresultstab").show();

            // alert(this.items[0].name);
            let tempindex = ddllistitems.map(e => e.name).indexOf(this.items[0].name);


            if (tempindex == 0) {
                this.showgeneratorResult = false;
                this.showFeederResult = true;
                this.showPowerStationResult = false;
                this.showTransformerResult = false;
                this.showPowerFactorResult = false;
                this.showSwitchboardResult = false;
            }
            if (tempindex == 1) {
                this.showgeneratorResult = false;
                this.showFeederResult = true;
                this.showPowerStationResult = false;
                this.showTransformerResult = false;
                this.showPowerFactorResult = false;
                this.showSwitchboardResult = false;
            }
            if (tempindex == 2) {
                this.showgeneratorResult = false;
                this.showFeederResult = false;
                this.showPowerStationResult = false;
                this.showTransformerResult = true;
                this.showPowerFactorResult = false;
                this.showSwitchboardResult = false;
            }
            if (tempindex == 3) {
                this.showgeneratorResult = true;
                this.showFeederResult = false;
                this.showPowerStationResult = false;
                this.showTransformerResult = false;
                this.showPowerFactorResult = false;
                this.showSwitchboardResult = false;
            }
            if (tempindex == 4) {
                $('#powerStationTreeview').css('display', 'block');
                this.showgeneratorResult = false;
                this.showFeederResult = false;
                this.showPowerStationResult = true;
                this.showTransformerResult = false;
                this.showPowerFactorResult = false;
                this.showSwitchboardResult = false;
            }
            // alert(tempindex);

            // alert($('#supplytype').select2.name);
            // $('#supplytype').prop("selectedIndex", 3);
            this.networksupplytype = tempindex.toString();
            let obj = this.NetworkSupplyTypeList.find(m => m.id == this.networksupplytype);
            if (this.supplyTypeReopenData == true) {
                this.onddlNetworkSupplyTypeChange(obj, "true");
                // $("#networksupplytype").val(tempindex.toString()).trigger('change', "true");
            }
            else {
                this.onddlNetworkSupplyTypeChange(obj, "true");
                // $("#networksupplytype").val(tempindex.toString()).trigger('change', "false");
            }


            //(<any>$('#networksupplytype')).select2('val', tempindex.toString());

            // $('#networksupplytype option').filter(function() {
            //     //alert($(this).text()+'               |  '+ nodename + '         '+($(this).text().toLowerCase().trim() == nodename.toString().toLowerCase().trim()));
            //     // alert();
            //     return $(this).text().toLowerCase().trim() == nodename.toString().toLowerCase().trim()
            //   }).prop("selected", 'selected');
        }
        //
        else if (selectedval == 3) {
            this.showScroll = false;
            this.isMobile = false;
            $('#Resultschild').css('display', 'block');
            $('#ResultsSelection').css('display', 'block');

            $('#tempresults').css('display', 'none');
            $('#SingleLineDiagramTab').css('display', 'none');


            $('#networkcalctab').css('display', 'none');
            $('#Settingschild').css('display', 'none');
            $('#Supplychild').css('display', 'none');


            //$('#HVRelayHead').css('display', 'none');

            $('#SwitchboardHead').css('display', 'none');
            $("#MaxDemandHead").css('display', 'none');
            $("#CableSizeHead").css('display', 'none');
            $("#ArcFlashHead").css('display', 'none');

            $('#LoadHead').css('display', 'none');
            $('#CapacitorHead').css('display', 'none');
            $('#PowerfactorHead').css('display', 'none');
            this.selectResults = this.resultSectionData.toString();
            this.selectresultstable();
            // $("#selectResults").val(this.resultSectionData).trigger('change', "true");
        }
        //
        else if (selectedval == 4) {
            this.tempinputXML.isSingleLineDiagram = 1;
            this.showScroll = false;
            this.isMobile = false;
            $('#SingleLineDiagramTab').css('display', 'block');

            // $('#networkinputs').css('width', '70%');


            $('#tempresults').css('display', 'none');
            $('#Resultschild').css('display', 'none');
            $('#ResultsSelection').css('display', 'none');


            $('#networkcalctab').css('display', 'none');
            $('#Settingschild').css('display', 'none');
            $('#Supplychild').css('display', 'none');


            //$('#HVRelayHead').css('display', 'none');

            $('#SwitchboardHead').css('display', 'none');
            $("#MaxDemandHead").css('display', 'none');
            $("#CableSizeHead").css('display', 'none');
            $("#ArcFlashHead").css('display', 'none');

            $('#LoadHead').css('display', 'none');
            $('#CapacitorHead').css('display', 'none');
            $('#PowerfactorHead').css('display', 'none');

            this.onInputChange();
        }
    }

    getResponseFromLocal(response: any) {
        let calculationRes = response.calculationResponse;
        if (calculationRes == 1) {
            if (response.errorstring) {
                this.warningErrorStrData = JSON.stringify(response.errorstring);
                this.generateErrorsModel(response.errorstring);
            }

            if (response.HVrelaycurve) {
                this.hvRelayCurve = response.HVrelaycurve;
            }
            else
                this.hvRelayCurve = undefined;

            if (response.HVfusecurve) {
                this.hvFuseCurve = response.HVfusecurve;
            }
            else
                this.hvFuseCurve = undefined;

            if (response.HVfusecurvemultiplier) {
                this.HVFuseCurveMultiplier = response.HVfusecurvemultiplier;
            }
            else
                this.HVFuseCurveMultiplier = undefined;

            //  this.showLoader();
            this.isNewNodeAdded = true;
            var endTimes = performance.now();
            this.sldbase64string = response.sld;

            this.executionTime = response.executionTimeDll;
            this.GetAllResultSectionValues(response);
            if (response.dxfstring) {
                this.textToFileDownload(this.calculationName + ".dxf", response.dxfstring);
            }
            this.tempinputXML.isSingleLineDiagram = 0;
            this.tempinputXML.isDXF = 0;
            if (this.newCalculationId) {
                if (this.isXmlStatus === true) {
                    if (navigator.onLine === true) {
                        this.cablePullingXmlInput = new CablePullingXmlInput();
                        this.cablePullingXmlInput.id = this.newCalculationId;
                        this.cablePullingXmlInput.TreeValue = response.treeItems;
                        this.cablePullingXmlInput.InputxmlValue = response.input;
                        this.cablePullingXmlInput.OutputxmlValue = response.output;
                        this.cablePullingXmlInput.SwitchboardCount = response.SwitchboardCount;
                        this.cablePullingXmlInput.Supply = response.Supply;
                        this.cablePullingXmlInput.cableSizeData = response.CableSizeCalcInputs;
                        this.cablePullingXmlInput.sld = this.sldbase64string;
                        if (response.MaxDemandDataArray != undefined && response.MaxDemandDataArray != null && response.MaxDemandDataArray.length > 0) {
                            this.cablePullingXmlInput.maxDemandCalcData = JSON.stringify(response.MaxDemandDataArray);
                        }

                        if (this.HVFuse == true) {
                            this.cablePullingXmlInput.HVFuseXml = this.HvFuseXml;
                            this.cablePullingXmlInput.HVFuseTrippingXml = this.HvFuseTrippingXml;
                        }
                        else {
                            this.cablePullingXmlInput.HVFuseXml = undefined;
                            this.cablePullingXmlInput.HVFuseTrippingXml = undefined;
                        }
                        //alert(this.cablePullingXmlInput.TreeValue);
                        this.cablePullingXmlInput.HVFuseXml = this.tempinputXML.SupplySettings.HvFuseXml;
                        this.cablePullingXmlInput.HVFuseTrippingXml = this.tempinputXML.SupplySettings.HvFuseTrippingXml;

                        //                    this.UpdateXmlValues(this.cablePullingXmlInput);
                    } else {
                        //this.hideLoader();;
                        $("#popup-mask").show();
                    }
                }
                //this.hideLoader();;
            }
            this.isXmlStatus = true;
        }
        else if (calculationRes == 2) {
            if (response.errorstring) {
                this.warningErrorStrData = JSON.stringify(response.errorstring);
                this.generateErrorsModel(response.errorstring);
            }
        }
        this.hideLoader();
    }
    //INUSE Cable size calculation 
    onCurrentChange(e: any) {
        if (!this.premiumUser && e.target.value > 100) {
            $('#current-restriction-model').modal('show');
            e.target.value = this.CableSize['current'];
            return false;
        }
        else {

            this.CommonValidationFn(e);
            this.Allowdecimalwithplaces(e, 4);
            this.CableSize['current'] = e.target.value;
            this.updatecablesizeinarray();
            this.getAllControlsValue();
        }
    }

    //INUSE Cable size calculation 
    getAllControlsValue(isFromTreeNode: boolean = false) {

        this.flag = false;
        // this.showLoader();
        this.isCalculating = true;
        this.isMaxDemandDataShown = false;
        if (this.CableSizeValuesUpdate()) {
            if (!this.isCableSizeCalcStatus && !this.isInputAPIStatus) {
                this.onInputChange(isFromTreeNode);
            }
        }
        else {

            if (!this.isCableSizeCalcStatus && !this.isInputAPIStatus) {

                this.onInputChange(isFromTreeNode);
            }
        }
        this.isNewNodeAdded = false;
        this.isCableSectionValue = false;
        this.isCableSizeCalcStatus = false;
        this.isInputAPIStatus = false;
        this.isXmlStatus = true;
        //this.hideLoader();;
    }

    //INUSE Cable size calculation 
    onEfficiencyChange(e: any) {
        this.CommonValidationFn(e);
        this.Allowdecimal(e);

        this.CableSize['efficiency'] = e.target.value;
        this.updatecablesizeinarray();
        this.getAllControlsValue();
    }

    //INUSE Cable size calculation 
    onDeratingFactorChange(e: any) {

        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 3);
        this.UserWizard = false;
        this.CableSize["isderatingwiz"] = "0";
        // $('#checkboxUseWizard').removeAttr('checked');

        this.CableSize['deratingfactor'] = e.target.value;
        this.deleteWizardFactorValues(this.activeNode.objectcode);
        this.updatecablesizeinarray();

        this.CalculationReportInput.wizardValues = new DRFWizrd();
        this.CalculationReportInput.wizardValues.finalDrf = Number(parseFloat(e.target.value).toFixed(2));
        this.CalculationReportInput.wizardValues.result = [];
        //this.updateDeratingFactorValue(false, this.CalculationReportInput.wizardValues);

        this.onInputChange();
    }

    //INUSE Cable size calculation 
    // updateDeratingFactorValue(useWizard: boolean, data: DRFWizrd) {

    //     this.showLoader();
    //     this._CableproService.storeDHROutput(this.newCalculationId, useWizard, data).subscribe((result) => {
    //         this.hideLoader();;
    //     }, error => {
    //         this.hideLoader();
    //     });
    // }

    //INUSE Cable size calculation 
    UseWizardChange(e) {
        setTimeout(() => {
            // this.UserWizard = $('#checkboxUseWizard:checked').length > 0;

            if (this.UserWizard) {
                $('#showWizard').click();
            }
            else {
                this.CableSize['isderatingwiz'] = "0";
                this.deleteWizardFactorValues(this.activeNode.objectcode);
                this.updatecablesizeinarray();
                this.StoreDHROutput(this.CalculationReportInput.wizardValues);
                this.onInputChange();
            }

        });
    }

    //INUSE Cable size calculation 
    StoreDHROutput(data: DRFWizrd) {
        if (data === undefined) {
            return
        }
        if (data.finalDrf === 1 && data.result.length === 0) {
            return;
        }

        // this.UserWizard = $('#checkboxUseWizard:checked').length > 0;

        //this.updateDeratingFactorValue(this.UserWizard, data);
    }
    bindWizardbs(startStep: number = 1) {
        // if(startStep==1)
        // {
        //     this.onCancel();
        // }

        // this.UserWizard = true;
        // $('.chkUW').prop('checked', 'true');        
        this.finalDrf = 1;
        this.wizard = (<any>$('#m_wizard')).mWizard({
            startStep: startStep,
            manualNav: false,
        });
        if (this.drInstallType == 1) {
            this.insulationValues = [
                // { "name": "60 &#176;C thermosetting", "value": 1 },
                { "name": "70 &#176;C thermoplastic", "value": 2 },
                { "name": "90 &#176;C thermosetting", "value": 3 },
                { "name": "Mineral - Thermoplastic covered or bare and exposed to touch 70 &#176;C", "value": 4 },
                { "name": "Mineral - Bare and not exposed to touch 105 &#176;C", "value": 5 },
            ];
        }
        else {
            this.insulationValues = [
                { "name": "70 &#176;C thermoplastic", "value": 2 },
                { "name": "90 &#176;C thermosetting", "value": 3 },
            ];
        }

        if ($('.c-select').length) {
            let $singleSelect = $('.c-select'),
                $singleSelectHasSearch = $('.c-select.has-search'),
                $multipleSelect = $('.c-select.c-select--multiple'),
                $disabledSelect = $('.c-select.is-disabled');

            $singleSelect.select2({
                width: '100%',
                minimumResultsForSearch: Infinity // disable search
            });

            // multiple select
            $multipleSelect.select2({
                width: '100%',
                multiple: true
            });

            // disabled select
            $disabledSelect.select2({
                width: '100%',
                disabled: true
            });
        }


        // $('#optDrInstallType1').click();
        $('input[name=optDrBunchingType],input[name=optDrInstallType]').on('click', (e) => {
            setTimeout((e) => {
                this.wizard.steps = (<any>$('#m_wizard')).find('.m-wizard__step');
                this.wizard.totalSteps = this.wizard.steps.length;
            }, 300);
            return true;
        });
        // $('#drTable25NoOfCicruits').on('select2:select', (e) => {
        //     this.table25model.NoOfCircuits = (<any>e.target).value;
        //     if (this.table25model.NoOfCircuits == 1) {
        //         this.table25model.drf = 1;
        //         this.drTable25output = undefined;
        //         return false;
        //     }
        //     setTimeout(() => {
        //         var ddlArr = $('#drTable25Arrangement');
        //         if (ddlArr.length > 0) {
        //             if (!ddlArr.data('select2')) {
        //                 ddlArr.select2({
        //                     width: '100%',
        //                     minimumResultsForSearch: Infinity // disable search
        //                 });
        //                 // ddlArr.on('select2:select', (e) => {
        //                 //     this.setTable25Values((<any>e.target).value);
        //                 // });
        //             }
        //             $('#drTable25Arrangement').on('select2:select', (e) => {
        //                 this.table25model.Arrangement = (<any>e.target).value;
        //                 this.setTable25Values((<any>e.target).value);
        //             });
        //         }
        //         this.setTable25Dr();
        //     }, 500);
        // });
        // $('#drTable23Cable').on('select2:select', (e) => {
        //     this.table23model.Cables = (<any>e.target).value;
        //     setTimeout(() => {
        //         this.GetTable23Dr(null);
        //     }, 800);

        // });
        // $('#drTable23Row').on('select2:select', (e) => {
        //     this.table23model.Row = (<any>e.target).value;
        //     setTimeout(() => {
        //         this.GetTable23Dr(null);
        //     }, 800);

        // });
        // (<any>$('#drTable22Arrangement')).on('select2:select', (e) => {
        //     this.table22model.Arrangement = parseInt(e.target.value);

        //     this.drTable22CircuitValues = [
        //         { "name": 1, "value": 1 },
        //         { "name": 2, "value": 2 },
        //         { "name": 3, "value": 3 },
        //         { "name": 4, "value": 4 },
        //         { "name": 5, "value": 5 },
        //         { "name": 6, "value": 6 },
        //         { "name": 7, "value": 7 },
        //         { "name": 8, "value": 8 },
        //         { "name": 9, "value": 9 },
        //         { "name": 12, "value": 12 },
        //         { "name": 16, "value": 16 },
        //         { "name": 20, "value": 20 }];

        //     this.GetTable22Dr(null);
        // });
        // (<any>$('#drTable22NoOfCircuits')).on('select2:select', (e) => {

        //     this.table22model.NoOfCircuits = parseInt(e.target.value);
        //     if (this.table22model.NoOfCircuits == 1) {
        //         this.table22model.drf = 1;
        //         this.drTable22output = undefined;
        //         return false;
        //     }
        //     this.GetTable22Dr(null);
        // });

        this.SetFirstWizardScreen();

    }
    //INUSE Cable size calculation 
    bindWizard(startStep: number = 1) {
        if (this.cableCalculationStandard == "2") {
            this.bindWizardbs(startStep);
            return;
        }
        //this.UserWizard = true;
        //$('#checkboxUseWizard').attr('checked', 'true');
        //this.finalDrf = 1;

        if ($('.c-select').length) {
            let $singleSelect = $('.c-select'),
                $singleSelectHasSearch = $('.c-select.has-search'),
                $multipleSelect = $('.c-select.c-select--multiple'),
                $disabledSelect = $('.c-select.is-disabled');

            $singleSelect.select2({
                width: '100%',
                minimumResultsForSearch: Infinity // disable search
            });

            // multiple select
            $multipleSelect.select2({
                width: '100%',
                multiple: true
            });

            // disabled select
            $disabledSelect.select2({
                width: '100%',
                disabled: true
            });
        }

        // $('#drTable25NoOfCicruits').on('select2:select', (e) => {
        //     this.table25model.NoOfCircuits = (<any>e.target).value;
        //     if (this.table25model.NoOfCircuits == 1) {
        //         this.table25model.drf = 1;
        //         this.drTable25output = undefined;
        //         return false;
        //     }
        //     setTimeout(() => {
        //         var ddlArr = $('#drTable25Arrangement');
        //         if (ddlArr.length > 0) {
        //             if (!ddlArr.data('select2')) {
        //                 ddlArr.select2({
        //                     width: '100%',
        //                     minimumResultsForSearch: Infinity // disable search
        //                 });
        //                 // ddlArr.on('select2:select', (e) => {
        //                 //     this.setTable25Values((<any>e.target).value);
        //                 // });
        //             }
        //             $('#drTable25Arrangement').on('select2:select', (e) => {
        //                 this.table25model.Arrangement = (<any>e.target).value;
        //                 this.setTable25Values((<any>e.target).value);
        //             });
        //         }
        //         this.setTable25Dr();
        //     }, 500);
        // });
        // $('#drTable23Cable').on('select2:select', (e) => {
        //     this.table23model.Cables = (<any>e.target).value;
        //     setTimeout(() => {
        //         this.GetTable23Dr(null);
        //     }, 800);

        // });
        // $('#drTable23Row').on('select2:select', (e) => {
        //     this.table23model.Row = (<any>e.target).value;
        //     setTimeout(() => {
        //         this.GetTable23Dr(null);
        //     }, 800);

        // });
        // (<any>$('#drTable22Arrangement')).on('select2:select', (e) => {
        //     this.table22model.Arrangement = parseInt(e.target.value);
        //     if (this.table22model.Arrangement === 1) {
        //         this.drTable22CircuitValues = [{ "name": 1, "value": 1 },
        //         { "name": 2, "value": 2 },
        //         { "name": 3, "value": 3 },
        //         { "name": 4, "value": 4 },
        //         { "name": 5, "value": 5 },
        //         { "name": 6, "value": 6 }];
        //     }
        //     else {
        //         this.drTable22CircuitValues = [
        //             { "name": 1, "value": 1 },
        //             { "name": 2, "value": 2 },
        //             { "name": 3, "value": 3 },
        //             { "name": 4, "value": 4 },
        //             { "name": 5, "value": 5 },
        //             { "name": 6, "value": 6 },
        //             { "name": 7, "value": 7 },
        //             { "name": 8, "value": 8 },
        //             { "name": 9, "value": 9 },
        //             { "name": 10, "value": 10 },
        //             { "name": 12, "value": 12 },
        //             { "name": 14, "value": 14 },
        //             { "name": 16, "value": 16 },
        //             { "name": 18, "value": 18 },
        //             { "name": "20 or more", "value": 20 }];
        //     }
        //     if (this.table22model.NoOfCircuits > 6 && this.table22model.Arrangement == 1) {
        //         this.table22model.NoOfCircuits = 6;
        //     }
        // });
        // (<any>$('#drTable22NoOfCircuits')).on('select2:select', (e) => {

        //     this.table22model.NoOfCircuits = parseInt(e.target.value);
        //     if (this.table22model.NoOfCircuits == 1) {
        //         this.table22model.drf = 1;
        //         this.drTable22output = undefined;
        //         return false;
        //     }
        //     this.GetTable22Dr(null);
        // });

        this.SetFirstWizardScreen();
    }

    //INUSE Cable size calculation 
    onPowerFactorChange(e: any) {
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 3);
        //alert(e.target.value);
        this.CableSize['powerfactor'] = e.target.value;
        this.updatecablesizeinarray();
        this.getAllControlsValue();
    }

    //INUSE Cable size calculation 
    onVoltageDrop(e: any) {
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 2);
        this.CableSize['voltagedrop'] = e.target.value;
        this.updatecablesizeinarray();
        this.getAllControlsValue();
    }

    //INUSE Cable size calculation 
    SetVoltageLabelValue(calledBy: string) {

        this.CableSize['usevd'] = '1';
        this.CableSize['isVoltageDrop'] = true;

        if (calledBy == 'rise') {
            // this.lblcurrent='Output current';

            if (this.currentUnit == "1") {
                this.lblcurrent = 'Output current';
            }
            else {
                this.lblcurrent = 'Output power';
            }
        }
        else {
            // this.lblcurrent='Current';

            if (this.currentUnit == "1") {
                this.lblcurrent = 'Current';
            }
            else {
                this.lblcurrent = 'Power';
            }

        }
        this.voltageSupplyLabel = calledBy;

        if (this.voltageSupplyLabel !== this.prevVoltageSupplyLabel) {
            this.prevVoltageSupplyLabel = this.voltageSupplyLabel;
            this.UpdateVoltageLabelValue();
        }
    }

    //INUSE Cable size calculation 
    onVoltageSupplyChange(e: any) {
        if (this.CableproInput.PhaseId.toString() === '102') /* DC */
            $('input[name="Voltage-Supply"]').attr('max', 1500);
        else
            $('input[name="Voltage-Supply"]').attr('max', 1000);

        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 2);

        this.CableSize['supplyvoltage'] = e.target.value;
        this.updatecablesizeinarray();
        this.getAllControlsValue();
    }

    //INUSE Cable size calculation 
    onLengthOfRunChange(e: any) {
        if (!this.premiumUser && e.target.value > 50) {
            $('#length-of-run-restriction-model').modal('show');
            e.target.value = this.CableSize['lengthofrun'];
            return false;
        }
        else {
            this.CommonValidationFn(e);
            this.Allowdecimalwithplaces(e, 2);

            this.CableSize['lengthofrun'] = e.target.value;
            this.updatecablesizeinarray();
            this.getAllControlsValue();
        }
    }

    //INUSE Cable size calculation 
    onFaultLevelChange(e: any) {
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 4);

        this.CableSize['faultlevel'] = e.target.value;
        this.updatecablesizeinarray();
        this.getAllControlsValue();
    }


    //INUSE Cable size calculation 
    showWarningOnInstallationChange(methodIndex: any, tableId: any, name: string, description: string, voltagedropTable: string, currentTable: string, callby: string) {
        if (callby == 'mainpage') {

            this.isInsulationChange = true;
            this.isNewNodeAdded = false;
            this.isCableSectionValue = false;
            this.isInputAPIStatus = false;
            this.isCableSizeCalcStatus = false;
            this.SelectInstallTableMethodIndex = methodIndex;
            this.SelectInstallTableTableId = tableId;
            this.SelectInstallTableName = name;
            this.SelectInstallTableDescription = description;
            this.selectInstallTableVoltagedropTable = voltagedropTable;
            this.selectInstallTableCurrentTable = currentTable;

        }

        let newImageName = (name + ' ' + description).toLowerCase().trim();
        var mainInstallationType = 0;

        if (newImageName.search('buried') == -1)
            mainInstallationType = 1;
        else
            mainInstallationType = 2;

        if (callby == 'mainpage') {
            var standard = parseInt(this.CableSize['calculationstandard']);
            if (mainInstallationType == 1) {
                this.table271model.AbientTemp = standard == 0 ? 40 : 30;
            } else {
                this.table271model.AbientTemp = standard == 0 ? 25 : 15;
            }
            this.CableSize['deratingtemp'] = this.table271model.AbientTemp.toString();
        }
        if (this.wizardFactorOutput.length > 0) {

            let wizardInstallationtype = this.wizardFactorOutput[0].installationType;

            if (wizardInstallationtype != mainInstallationType) {
                if (callby == 'mainpage') {
                    this.onSelectInstallTable();
                }

                this.UserWizard = false;
                this.CableSize["isderatingwiz"] = "0";
                // $('#checkboxUseWizard').removeAttr('checked');
                this.onResetControlValues();
                this.resetWizardFactorValue();
            }
            else {

                if (callby == 'mainpage') {
                    this.onSelectInstallTable();
                }
                if (mainInstallationType == 2) {
                    this.resetWizardControlValuesOnInstallationChange(newImageName);
                }
                if (callby == 'getdata') {
                    this.resetWizardFactorValue();
                }

            }
        }
        else {

            if (callby == 'mainpage') {
                this.onSelectInstallTable();
            }

            if (callby == 'getdata') {
                this.resetWizardFactorValue();
            }
            this.onResetControlValues();

        }
        //this.loadPreviousValues();
        if (callby == 'mainpage') {
            this.drTable22output = undefined;
            this.drTable271output = undefined;
            setTimeout(() => {
                this.onCancel();
            });
        }
        if (!this.isCableSectionValue && this.isInsulationChange) {

            this.updatecablesizeinarray();
        }
    }

    //INUSE Cable size calculation 
    onSelectInstallTable() {

        let methodIndex = this.SelectInstallTableMethodIndex;
        let tableId = this.SelectInstallTableTableId;
        let name = this.SelectInstallTableName;
        let description = this.SelectInstallTableDescription;
        let voltagedropTable = this.selectInstallTableVoltagedropTable;
        let currentTable = this.selectInstallTableCurrentTable;


        if (this.CableproInput.PhaseId.toString() !== '100' && this.CableproInput.PhaseId.toString() !== '102') {
            if (this.CableSize['tableno'] === '17') {
                setTimeout(() => {
                    $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                    $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                }, 100);
            } else if (this.CableSize['tableno'] === '18') {
                setTimeout(() => {
                    $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                    $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                    $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                }, 100);
            }
        }
        if (this.CableSize['tableno'] === '16') {
            setTimeout(() => {
                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('title', 'Derating factor: 0.76');
                $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('title', 'Derating factor: 0.58');
                $('#divInstallTbl table tr:nth-child(4) img')[0].setAttribute('title', 'Derating factor: 0.47');
                $('#divInstallTbl table tr:nth-child(5) img')[0].setAttribute('title', 'Derating factor: 0.4');
            }, 100);
        }
        this.CableproInput.MethodIndex = methodIndex;
        this.PrevImageIndex = methodIndex;
        $('#divInstallTbl').find('img').each(function (e) {
            if ($(this).attr('data-index') === methodIndex.toString()) {
                $(this).attr('style', 'border:2px #2ea1f8 solid').attr('style', 'width:100%');
            } else {
                $(this).attr('style', 'border:2px #fff solid').attr('style', 'width:100%');
            }
        });
        this.CableSize['tablectrlvalue'] = this.CableproInput.MethodIndex;
        this.CableSize['description'] = name + ' ' + description;
        this.CableSize['currentTable'] = currentTable;
        this.CableSize['voltagedropTable'] = voltagedropTable;
        this.CableSize['tableno'] = tableId;
        this.CableSize['columnno'] = methodIndex;
        // var armour = 0;
        // if ($('#armour').length > 0 && $('#armour').val())
        //     armour = parseInt((<any>$('#armour')).val());
        // this.GetData('installation', '', 0, this.CableproInput.ConductorId, 0, 0, this.CableproInput.MethodIndex, tableId, 0, '', '', this.newCalculationId, 0, 0);
        this.GetCableSizeData('installation', '', 0, this.CableproInput.ConductorId, 0, 0, this.CableproInput.MethodIndex, this.CableproInput.InstallTableId, 0, '', '', this.newCalculationId, 0, 0, this.CableproInput.ArmourId, null, false, this.CableproInput.EarthConductorId);

        setTimeout(() => {
            // if ($('#active-toggle').is(':checked')) {
            //     (<any>$('#Active')).val(this.CableproInput.ActiveId).trigger('change');
            // }
            // if ($('#neutral-toggle').is(':checked')) {
            //     (<any>$('#Neutral')).val(this.CableproInput.NeutralId).trigger('change');
            // }
        }, 800);

    }

    //INUSE Cable size calculation 
    onActiveCircuitChange(e: any) {

        let val = parseFloat(e.target.value);
        $(e.target).val(val.toFixed(0));
        this.CableSize['specifyconductor']['phase']['circuits'] = val.toFixed(0);
        this.active_NoOf_circuit = val.toFixed(0);
        $('#Active_c').val(this.active_NoOf_circuit);
        this.updatecablesizeinarray();
        this.onInputChange();
    }

    //INUSE Cable size calculation 
    onNeutralCircuitChange(e: any) {

        let val = parseFloat(e.target.value);
        $(e.target).val(val.toFixed(0));
        this.CableSize['specifyconductor']['neutral']['circuits'] = val.toFixed(0);
        this.earth_NoOf_circuit = val.toFixed(0);
        $('#Earth_c').val(this.earth_NoOf_circuit);

        this.updatecablesizeinarray();
        this.onInputChange();
    }

    //INUSE Cable size calculation 
    onEarthCircuitChange(e: any) {

        let val = parseFloat(e.target.value);
        $(e.target).val(val.toFixed(0));
        this.CableSize['specifyconductor']['earth']['circuits'] = val.toFixed(0);
        this.neutral_NoOf_circuit = val.toFixed(0);
        $('#Neutral_c').val(this.neutral_NoOf_circuit);
        this.updatecablesizeinarray();
        this.onInputChange();
    }


    //INUSE Cable size calculation 
    resetWizardFactorValue() {
        if (this.finalDrf) {
            this.CalculationReportInput.wizardValues = new DRFWizrd();
            this.CalculationReportInput.wizardValues.finalDrf = parseFloat(this.finalDrf.toFixed(2));
            this.CalculationReportInput.wizardValues.result = [];
            //this.updateDeratingFactorValue(false, this.CalculationReportInput.wizardValues);
        }
    }

    //INUSE Cable size calculation 
    //    GetData(mode: string, value: string, insulationId: number, conductorId: number, coreId: number, phaseId: number, methodIndex: number, installTableId: number, windSpeedId: number, load: string, protection: string, calculationId: number, ConductorIndex: number, CoreIndex: number) { // tempobj current node cable size data
    GetCableSizeData(mode: string, value: string, insulationId: number, conductorId: number, coreId: number, phaseId: number, methodIndex: number, installTableId: number, windSpeedId: number, load: string, protection: string, calculationId: number, ConductorIndex: number, CoreIndex: number, armourId: number, tempobj: any = null, isFromTreeNode: boolean = false, earthConductorId: number) {
        var startTime = performance.now();
        //this.showLoader();
        //var armourId = 0;
        calculationId = -1;

        this.showLoader();
        this.requestCount++;
        this._CableproService.getNetworkCableproes(mode, value, insulationId, conductorId, armourId, coreId, phaseId, methodIndex, installTableId, windSpeedId, load, protection, calculationId, ConductorIndex, CoreIndex, this.cableCalculationStandard, earthConductorId).subscribe((result) => {
            //this.showLoader();
            this.responseCount++;
            if (mode === 'Load') {
                // alert('Load!!!');
                var endTime = performance.now();
                var totalTime = endTime - startTime; // time took to run in milliseconds

                if (mode === 'Load' || result.cableproXmlInputOpen === null || result.cableproXmlInputOpen === undefined) {
                    this.insulationData = result.insulationDto;
                    this.conductorData = result.conductorDto;
                    this.coreData = result.coreDto;
                    this.phaseData = result.phaseDto;
                    this.armourData = result.armourDto;
                    this.installTableData = result.installTableDto;
                    this.specifyConductorData = result.specifyConductorDto;
                    this.uiControlsLookUpData = result.uiControlsLookupDto;
                    this.windSpeedData = result.windSpeedDto;
                    if (this.windSpeedData && this.windSpeedData.length > 0 && this.cableCalculationStandard != '2') {
                        this.hasWind = true;
                        if (tempobj.CableSizeCalcInputXML != undefined && tempobj.CableSizeCalcInputXML.installtablewind != null && tempobj.CableSizeCalcInputXML.installtablewind.wind) {
                            this.CableproInput.WindSpeedId = this.windSpeedData[tempobj.CableSizeCalcInputXML.installtablewind.wind].id;
                            this.CableSize['installtablewind']['wind'] = this.windSpeedData[tempobj.CableSizeCalcInputXML.installtablewind.wind].xmlValue;// ak change


                        }
                        else {
                            this.CableproInput.WindSpeedId = this.windSpeedData[0].id;
                            this.CableSize['installtablewind']['wind'] = this.windSpeedData[0].xmlValue;// ak change
                        }
                    } else {
                        this.hasWind = false;
                    }
                    this.drBunchingCore = 1;
                    if (tempobj) {
                        if (!tempobj.CableSizeCalcInputXML || tempobj.CableSizeCalcInputXML['supplyvoltage'] == '')
                            this.getDefaultSupplyVoltage(tempobj.Node_Id);
                        else {
                            this.CableSize['supplyvoltage'] = tempobj.CableSizeCalcInputXML['supplyvoltage'];
                            this.voltage_txt = this.CableSize['supplyvoltage'];
                            // $('input[name="Voltage-Supply"]').val(this.CableSize['supplyvoltage']);
                        }
                    }
                    this.CableproInput.MethodIndex = parseInt(tempobj.CableSizeCalcInputXML['tablectrlvalue']);
                    this.onResetControlValues();
                    this.PrevImageIndex = this.CableproInput.MethodIndex;
                    if (this.phaseData.length > 0) {
                        this.phaseDropdownIndex = this.phaseData.find(x => x.xmlValue == parseInt(tempobj.CableSizeCalcInputXML['phase'])).id;
                        this.CableproInput.PhaseId = this.phaseDropdownIndex;
                    }
                    else {
                        this.phaseDropdownIndex = 100;
                        this.CableproInput.PhaseId = this.phaseDropdownIndex;
                    }
                    if (this.insulationData.length > 0) {
                        this.insulatorDropdownIndex = this.insulationData.find(x => x.xmlValue == parseInt(tempobj.CableSizeCalcInputXML['insulation'])).id;
                        this.CableproInput.InsulationId = this.insulatorDropdownIndex;
                        if (tempobj.CableSizeCalcInputXML && tempobj.CableSizeCalcInputXML.installtablewind && tempobj.CableSizeCalcInputXML.installtablewind.wind) {
                            //this.CableproInput.WindSpeedId = parseInt(tempobj.CableSizeCalcInputXML['installtablewind']['wind']);// [parseInt(tempobj.CableSizeCalcInputXML['installtablewind']['wind'])].id;
                            this.CableSize['installtablewind']['wind'] = parseInt(tempobj.CableSizeCalcInputXML['installtablewind']['wind']);//this.windSpeedData[0].xmlValue;
                        }
                    }
                    else {
                        this.insulatorDropdownIndex = 100;
                        this.CableproInput.InsulationId = this.insulatorDropdownIndex;
                    }
                    if (this.conductorData.length > 0) {
                        this.PrevConductorId = parseInt(tempobj.CableSizeCalcInputXML['conductor']);
                        this.cableDropdownIndex = this.conductorData.find(x => x.xmlValue == parseInt(tempobj.CableSizeCalcInputXML['conductor'])).id;
                        this.CableproInput.ConductorId = this.cableDropdownIndex;
                    }
                    else {
                        this.PrevConductorId = 0;
                        this.cableDropdownIndex = 100;
                        this.CableproInput.ConductorId = this.cableDropdownIndex;
                    }
                    if (this.earthConductorData.length > 0 && tempobj.CableSizeCalcInputXML['earthconductor'] != null) {
                        this.PrevEarthConductorId = parseInt(tempobj.CableSizeCalcInputXML['earthconductor']);
                        this.earthConductorDropdownIndex = parseInt(tempobj.CableSizeCalcInputXML['earthconductor']);
                        this.CableproInput.EarthConductorId = parseInt(tempobj.CableSizeCalcInputXML['earthconductor']);
                    }
                    else {
                        this.PrevEarthConductorId = 1;
                        this.earthConductorDropdownIndex = 1;
                        this.CableproInput.EarthConductorId = 1;
                    }
                    if (this.coreData.length > 0) {
                        this.PrevCoreId = parseInt(tempobj.CableSizeCalcInputXML['core']);
                        this.coreDropdownIndex = this.coreData.find(x => x.xmlValue == parseInt(tempobj.CableSizeCalcInputXML['core'])).id;
                        this.CableproInput.CoreId = this.coreDropdownIndex;
                    }
                    else {
                        this.PrevCoreId = 0;
                        this.coreDropdownIndex = 100;
                        this.CableproInput.CoreId = this.coreDropdownIndex;
                    }

                    if (this.armourData && this.armourData.length > 0) {
                        this.PrevArmourId = parseInt(tempobj.CableSizeCalcInputXML['armour']);
                        let tempArmour = this.armourData.find(x => x.xmlValue == parseInt(tempobj.CableSizeCalcInputXML['armour']));
                        if (tempArmour) {
                            this.armourDropdownIndex = tempArmour.id;
                            this.CableproInput.ArmourId = this.armourDropdownIndex;
                        }
                        else {
                            this.armourDropdownIndex = this.armourData[0].id;
                            this.CableproInput.ArmourId = this.armourDropdownIndex;
                        }
                    }
                    else {
                        this.PrevArmourId = 0;
                        this.armourDropdownIndex = 100;
                        this.CableproInput.ArmourId = this.armourDropdownIndex;
                    }

                    if (tempobj.CableSizeCalcInputXML['isVoltageDrop'] == false) {
                        this.voltageSupplyLabel = 'rise';
                        this.prevVoltageSupplyLabel = 'rise';
                    }
                    else {
                        this.voltageSupplyLabel = 'drop';
                        this.prevVoltageSupplyLabel = 'drop';
                    }
                    if (this.voltageSupplyLabel == 'rise') {
                        if (this.currentUnit == "1") {
                            this.lblcurrent = 'Output current';
                        }
                        else {
                            this.lblcurrent = 'Output power';
                        }
                    }
                    //Active_toggle button
                    if (tempobj.CableSizeCalcInputXML['specifyconductor']['phase']['mode'] == '1') {

                        $('#active-toggle').prop('checked', true);
                        this.ShowActive = true;
                        $('#Active_c').removeAttr('disabled');
                        $('#Active_c').val(tempobj.CableSizeCalcInputXML['specifyconductor']['phase']['circuits']);
                        this.showActiveLab = false;
                        this.CableproInput.ActiveId = 100;
                        if (this.specifyConductorData != null && this.specifyConductorData != undefined) {
                            let phaseData = tempobj.CableSizeCalcInputXML['specifyconductor']['phase']['size'] + ' mm²'
                            var selectedItem = this.specifyConductorData.find(x => x.value === phaseData);
                            if (selectedItem) {
                                this.PrevActiveValue = phaseData;
                                this.CableproInput.ActiveId = selectedItem.id;
                            }
                        }
                    }
                    else {
                        $('#active-toggle').prop('checked', false);
                        this.ShowActive = false;
                        $('#Active_c').attr('disabled', 'true');
                        this.active_NoOf_circuit = 1;
                        this.showActiveLab = true;
                        this.CableproInput.ActiveId = 100;
                        if (this.specifyConductorData != null && this.specifyConductorData != undefined) {
                            let phaseData = tempobj.CableSizeCalcInputXML['specifyconductor']['phase']['size'] + ' mm²'
                            var selectedItem = this.specifyConductorData.find(x => x.value === phaseData);
                            if (selectedItem) {
                                this.CableproInput.ActiveId = selectedItem.id;
                            }
                        }
                    }


                    //Neutral_toggle button
                    if (tempobj.CableSizeCalcInputXML['specifyconductor']['neutral']['mode'] == '1') {

                        $('#neutral-toggle').prop('checked', true);
                        this.ShowNeutral = true;
                        $('#Neutral_c').removeAttr('disabled');
                        $('#Neutral_c').val(tempobj.CableSizeCalcInputXML['specifyconductor']['neutral']['circuits']);
                        this.neutral_NoOf_circuit = tempobj.CableSizeCalcInputXML['specifyconductor']['neutral']['circuits'];

                        this.ShowNeutralLab = false;
                        this.CableproInput.NeutralId = 100;
                        if (this.specifyConductorData != null && this.specifyConductorData != undefined) {
                            let neutralData = tempobj.CableSizeCalcInputXML['specifyconductor']['neutral']['size'] + ' mm²'
                            var selectedItem = this.specifyConductorData.find(x => x.value === neutralData);
                            if (selectedItem) {
                                this.PrevNeutralValue = neutralData;
                                this.CableproInput.NeutralId = selectedItem.id;
                            }
                        }
                        // $('#Neutral').val(this.CableproInput.NeutralId).trigger('change', 'true');
                    }
                    else {
                        $('#neutral-toggle').prop('checked', false);
                        this.ShowNeutral = false;
                        $('#Neutral_c').attr('disabled', 'true');
                        this.neutral_NoOf_circuit = 1;
                        this.ShowNeutralLab = true;
                        this.CableproInput.NeutralId = 100;
                        if (this.specifyConductorData != null && this.specifyConductorData != undefined) {
                            let neutralSelectedData = tempobj.CableSizeCalcInputXML['specifyconductor']['neutral']['size'] + ' mm²'
                            var selectedItem = this.specifyConductorData.find(x => x.value === neutralSelectedData);
                            if (selectedItem) {
                                this.CableproInput.NeutralId = selectedItem.id;
                            }
                        }
                    }

                    //Earth toggle button
                    if (tempobj.CableSizeCalcInputXML['specifyconductor']['earth']['mode'] === '2') {
                        $('#earth-toggle').prop('checked', true);
                        this.ShowEarth = true;
                        $('#Earth_c').removeAttr('disabled');
                        $('#earth-toggle').removeAttr('disabled');
                        this.ShowEarthLab = false;
                        this.showEarthButton = true;
                        $('#Earth_c').val(tempobj.CableSizeCalcInputXML['specifyconductor']['earth']['circuits']);
                        this.earth_NoOf_circuit = tempobj.CableSizeCalcInputXML['specifyconductor']['earth']['circuits'];
                        var obj = this.earthList.find(x => x.id == tempobj.CableSizeCalcInputXML['specifyconductor']['earth']['size']);
                        if (obj) {
                            this.CableproInput.EarthId = obj.id;
                        }
                        // $('#Earth').val(tempobj.CableSizeCalcInputXML['specifyconductor']['earth']['size']).trigger('change', 'true');
                        $('#earth-chk').prop('checked', false);
                    }
                    else if (tempobj.CableSizeCalcInputXML['specifyconductor']['earth']['mode'] === '0') {
                        $('#earth-chk').prop('checked', true);
                        $('#earth-toggle').attr('disabled', 'true');
                        this.ShowEarth = false;
                        $('#Earth_c').attr('disabled', 'true');
                        $('#Earth_c').val(this.earth_NoOf_circuit);
                        this.CableproInput.EarthId = "1";
                        // $('#Earth').val('1').trigger('change', 'true');
                        this.ShowEarthLab = true;
                        this.showEarthButton = false;
                    }
                    else {
                        this.showEarthButton = true;
                        $('#earth-toggle').removeAttr('disabled');
                        $('#earth-chk').prop('checked', false);
                        $('#earth-toggle').prop('checked', false);
                        this.ShowEarth = false;
                        $('#Earth_c').attr('disabled', 'true');
                        this.CableproInput.EarthId = "1";
                        // $('#Earth').val('1').trigger('change', 'true');
                        this.ShowEarthLab = true;
                        this.earth_NoOf_circuit = 1;
                        $('#Earth_c').val(1);
                    }

                    $("#phase").val(this.phaseDropdownIndex).trigger('change', "true");
                    this.CableproInput.InsulationId = this.insulatorDropdownIndex;
                    let insulation = this.insulationData.find(m => m.id == this.insulatorDropdownIndex);
                    this.onddlInsulationChange(insulation, "true");
                    this.CableproInput.ConductorId = this.cableDropdownIndex;
                    this.onddlConductorChange(this.cableDropdownIndex, "true");
                    this.CableproInput.EarthConductorId = this.earthConductorDropdownIndex;
                    this.onddlEarthConductorChange(this.earthConductorDropdownIndex, "true");

                    this.CableproInput.CoreId = this.coreDropdownIndex;
                    let coreData = this.coreData.find(m => m.id == this.coreDropdownIndex);
                    this.onddlCoreChange(coreData, "true");
                    this.CableproInput.ArmourId = this.armourDropdownIndex;
                    $('#deratingFactor').val(tempobj.CableSizeCalcInputXML['deratingfactor']);

                    $("#ddlunit").val(tempobj.CableSizeCalcInputXML['powerunit']).trigger('change', "true");
                    this.efficiency = tempobj.CableSizeCalcInputXML['efficiency'];
                    $("#cablesizephase").val(tempobj.CableSizeCalcInputXML['powerunit']);
                    $("#cablesizelengthofrun").val(tempobj.CableSizeCalcInputXML['lengthofrun']);

                    this.device = tempobj.CableSizeCalcInputXML['device'];
                    this.onddlDeviceChange(this.device, "true", isFromTreeNode);
                    $(".clsfaulttime").val(tempobj.CableSizeCalcInputXML['protection']['shortcircuittime']).trigger('change', "true");
                    $(".clsearthfaulttime").val(tempobj.CableSizeCalcInputXML['protection']['earthfaulttime']).trigger('change', "true");
                    $(".clscustomrating").val(tempobj.CableSizeCalcInputXML['protection']['customrating']).trigger('change', "true");
                    $("input[caption='Thermal setting']").val(tempobj.CableSizeCalcInputXML['protection']['thermalsetting']).trigger('change', "true");
                    $(".clsthermalsetting").val(tempobj.CableSizeCalcInputXML['protection']['thermalsetting']).trigger('change', "true");
                    $(".clstripcurrent").val(tempobj.CableSizeCalcInputXML['protection']['tripcurrent']).trigger('change', "true");
                    $(".clstripmultiplier").val(tempobj.CableSizeCalcInputXML['protection']['tripmultiplier']).trigger('change', "true");
                    $(".clsRating").val(tempobj.CableSizeCalcInputXML['device']).trigger('change', "true");
                    $(".clsCurve").val(tempobj.CableSizeCalcInputXML['protection']['curve']).trigger('change', "true");
                    $(".clsDescription").val(tempobj.CableSizeCalcInputXML['protection']['description']).trigger('change', "true");



                    if (result.installTableDto[0].id !== 0 && result.installTableDto[0].name !== null) {

                        this.installTableData = result.installTableDto;
                        this.CableproInput.InstallTableId = this.installTableData[0].id;
                        if (this.cableCalculationStandard == '2') {
                            this.CableSize['tableno'] = this.installTableData[0].id;
                        }
                        else {
                            this.CableSize['tableno'] = this.installTableData[0].name;
                        }
                        var installTableImageName = '', installTableImageDesc = '';


                        if (this.installTableData[0].images.length - 1 < this.PrevImageIndex) {
                            this.CableproInput.MethodIndex = this.installTableData[0].images[0].index;
                            this.CableSize['tablectrlvalue'] = this.CableproInput.MethodIndex.toString();
                            this.CableSize['description'] = this.installTableData[0].images[0].name + ' ' + this.installTableData[0].images[0].description;
                            installTableImageName = this.installTableData[0].images[0].name;
                            installTableImageDesc = this.installTableData[0].images[0].description;

                            this.CableSize['currentTable'] = this.installTableData[0].images[0].currentTable;
                            this.CableSize['voltagedropTable'] = this.installTableData[0].images[0].voltagedropTable;

                        } else {

                            if (this.installTableData[0].images.length - 1 >= this.PrevImageIndex) {
                                this.CableproInput.MethodIndex = this.installTableData[0].images[this.PrevImageIndex].index;
                                this.CableSize['currentTable'] = this.installTableData[0].images[this.PrevImageIndex].currentTable;
                                this.CableSize['voltagedropTable'] = this.installTableData[0].images[this.PrevImageIndex].voltagedropTable;
                            }
                            else {
                                this.CableproInput.MethodIndex = this.installTableData[0].images[0].index;
                                this.CableSize['currentTable'] = this.installTableData[0].images[0].currentTable;
                                this.CableSize['voltagedropTable'] = this.installTableData[0].images[0].voltagedropTable;
                            }

                            // this.CableproInput.MethodIndex = this.installTableData[0].images[this.PrevImageIndex].index;
                            this.CableSize['tablectrlvalue'] = this.CableproInput.MethodIndex.toString();
                            this.CableSize['description'] = this.installTableData[0].images[this.PrevImageIndex].name + ' ' + this.installTableData[0].images[this.PrevImageIndex].description;
                            installTableImageName = this.installTableData[0].images[this.PrevImageIndex].name;
                            installTableImageDesc = this.installTableData[0].images[this.PrevImageIndex].description;
                        }
                        this.isInsulationChange = true;
                        this.isCableSectionValue = false;
                        this.UpdateWizardOnInstallationChange(this.CableproInput.MethodIndex, this.CableSize['tableno'], installTableImageName, installTableImageDesc, this.CableSize['voltagedropTable'], this.CableSize['currentTable'], 'getdata');

                        this.showWarningOnInstallationChange(this.CableproInput.MethodIndex, this.CableSize['tableno'], installTableImageName, installTableImageDesc, this.CableSize['voltagedropTable'], this.CableSize['currentTable'], 'getdata');

                        if (this.CableproInput.PhaseId.toString() !== '100' && this.CableproInput.PhaseId.toString() !== '102') {
                            if (this.CableSize['tableno'] === '17') {
                                setTimeout(() => {
                                    // $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                                    // $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                                }, 1000);
                            } else if (this.CableSize['tableno'] === '18') {
                                setTimeout(() => {
                                    $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                                    $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                                    $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                                }, 1000);
                            }
                        }
                        if (this.CableSize['tableno'] === '16') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('title', 'Derating factor: 0.76');
                                $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('title', 'Derating factor: 0.58');
                                $('#divInstallTbl table tr:nth-child(4) img')[0].setAttribute('title', 'Derating factor: 0.47');
                                $('#divInstallTbl table tr:nth-child(5) img')[0].setAttribute('title', 'Derating factor: 0.4');
                            }, 1000);
                        }
                    }



                }
                else {

                    this.flag = true;
                    this.calculation_type = result.cableproXmlInputOpen.calculationType;
                    if (result.cableproXmlInputOpen.phaseXmlVal === '0') {
                        if ($('#neutral-toggle').val() !== 'on') {
                            $('#neutral-toggle').click();
                        }
                        this.NeutralDisabled = true;
                        // $('#Neutral').attr('disabled', 'true');
                        $('#Neutral_c').attr('disabled', 'true');
                        $('#neutral-toggle').attr('disabled', 'true');
                    }
                    else if (result.cableproXmlInputOpen.phaseXmlVal === '1') {
                        this.NeutralDisabled = false;
                        // $('#Neutral').removeAttr('disabled');
                        $('#Neutral_c').removeAttr('disabled');
                    }

                    else if (result.cableproXmlInputOpen.phaseXmlVal === '2') {
                        if ($('#neutral-toggle').prop('checked')) {
                            $('#neutral-toggle').click();
                        }
                        this.NeutralDisabled = true;
                        // $('#Neutral').attr('disabled', 'true');
                        $('#Neutral_c').attr('disabled', 'true');
                        $('#neutral-toggle').attr('disabled', 'true');
                    }
                    else if (result.cableproXmlInputOpen.phaseXmlVal === '3' || result.cableproXmlInputOpen.phaseXmlVal === '4') {
                        this.NeutralDisabled = false;
                        // $('#Neutral').removeAttr('disabled');
                        $('#Neutral_c').removeAttr('disabled');
                    }
                    // 

                    $('#Active_c').attr('disabled', 'true');
                    $('#Earth_c').attr('disabled', 'true');

                    if (!result.cableproXmlInputOpen.protection_device || result.cableproXmlInputOpen.protection_device == '')
                        result.cableproXmlInputOpen.protection_device = "0";
                    this.device = result.cableproXmlInputOpen.protection_device;
                    this.onddlDeviceChange(this.device, "false", isFromTreeNode);
                    // $('#device').val(result.cableproXmlInputOpen.protection_device).trigger('change');
                    $('input[name="current"]').val(result.cableproXmlInputOpen.load_current);
                    $('input[name="Derating factor"]').val(result.cableproXmlInputOpen.load_deratingFactor);
                    if (result.cableproXmlInputOpen.efficiency != 0)
                        this.efficiency = result.cableproXmlInputOpen.efficiency;

                    //  $('input[name="Efficiency"]').val(result.cableproXmlInputOpen.efficiency);
                    this.tempDeracting = result.cableproXmlInputOpen.load_deratingFactor;
                    $('input[name="power-factor"]').val(result.cableproXmlInputOpen.load_powerFactor);
                    $('input[name="Voltage Drop"]').val(result.cableproXmlInputOpen.supply_voltageDrop);
                    // $('input[name="Voltage-Supply"]').val(result.cableproXmlInputOpen.supply_voltage);
                    this.voltage_txt = result.cableproXmlInputOpen.supply_voltage;
                    $('input[name="fault-level"]').val(result.cableproXmlInputOpen.supply_faultLevel);
                    $('input[name="length-of-run"]').val(result.cableproXmlInputOpen.supply_lengthOfrun);
                    // alert(result.cableproXmlInputOpen.powerunit);
                    if (result.cableproXmlInputOpen.powerunit != null) {
                        this.powerunit = result.cableproXmlInputOpen.powerunit;
                        $("#ddlunit").val(this.powerunit).trigger('change', "true");
                        $("#ddlunit").val(this.powerunit);

                    }
                    else {
                        this.powerunit = "1";
                        $("#ddlunit").val(this.powerunit).trigger('change', "true");
                        $("#ddlunit").val(this.powerunit);
                    }
                    if (result.cableproXmlInputOpen.supply_useVd === '1') {
                        $('#use-vd').prop('checked', true);
                    } else {
                        $('#use-vd').prop('checked', false);
                    }

                    this.insulationData = result.insulationDto;
                    this.conductorData = result.conductorDto;
                    this.coreData = result.coreDto;
                    this.phaseData = result.phaseDto;
                    this.armourData = result.armourDto;
                    this.installTableData = result.installTableDto;
                    this.specifyConductorData = result.specifyConductorDto;
                    this.uiControlsLookUpData = result.uiControlsLookupDto;
                    this.windSpeedData = result.windSpeedDto;
                    if (this.windSpeedData && this.windSpeedData.length > 0 && this.cableCalculationStandard != '2') {
                        this.hasWind = true;
                        if (result.cableproXmlInputOpen != undefined && result.cableproXmlInputOpen != null) {
                            this.CableproInput.WindSpeedId = this.windSpeedData[result.cableproXmlInputOpen.windSpeedId].id;
                            this.CableSize['installtablewind']['wind'] = this.windSpeedData[result.cableproXmlInputOpen.windSpeedId].xmlValue;// ak change


                        }
                        else {
                            this.CableproInput.WindSpeedId = this.windSpeedData[0].id;
                            this.CableSize['installtablewind']['wind'] = this.windSpeedData[0].xmlValue;// ak change
                        }
                    } else {
                        this.hasWind = false;
                    }
                    this.CableproInput.InsulationId = result.cableproXmlInputOpen.insulationId;
                    this.CableproInput.ConductorId = result.cableproXmlInputOpen.conductorId;
                    this.CableproInput.EarthConductorId = result.cableproXmlInputOpen.earthConductorId;
                    if (result.cableproXmlInputOpen.isVoltageDrop == false) {
                        this.voltageSupplyLabel = 'rise';
                        this.prevVoltageSupplyLabel = 'rise';
                    }
                    else {
                        this.voltageSupplyLabel = 'drop';
                        this.prevVoltageSupplyLabel = 'drop';
                    }
                    if (this.voltageSupplyLabel == 'rise') {
                        if (this.currentUnit == "1") {
                            this.lblcurrent = 'Output current';
                        }
                        else {
                            this.lblcurrent = 'Output power';
                        }
                    }
                    this.CableproInput.CoreId = result.cableproXmlInputOpen.coreId;
                    this.CableproInput.PhaseId = result.cableproXmlInputOpen.phaseId;
                    this.CableproInput.MethodIndex = result.cableproXmlInputOpen.methodIndex;
                    this.CableproInput.InstallTableId = result.cableproXmlInputOpen.installTableId;
                    // this.CableproInput.ArmourId = result.cableproXmlInputOpen.armourId;
                    // this.CableSize['armour'] = result.cableproXmlInputOpen.armourXmlVal;
                    if (result.cableproXmlInputOpen.conductorXmlVal)
                        this.PrevConductorId = parseInt(result.cableproXmlInputOpen.conductorXmlVal);
                    else
                        this.PrevConductorId = 0;

                    if (result.cableproXmlInputOpen.earthconductorXmlVal)
                        this.PrevEarthConductorId = parseInt(result.cableproXmlInputOpen.earthconductorXmlVal);
                    else
                        this.PrevEarthConductorId = 1;
                    if (result.cableproXmlInputOpen.coreXmlVal)
                        this.PrevCoreId = parseInt(result.cableproXmlInputOpen.coreXmlVal);
                    else
                        this.PrevCoreId = 0;

                    if (result.cableproXmlInputOpen.armourXmlVal)
                        this.PrevArmourId = result.cableproXmlInputOpen.armourXmlVal;
                    else
                        this.PrevArmourId = 0;

                    //this.PrevArmourId = result.cableproXmlInputOpen.armourXmlVal;
                    if (this.PrevCoreId == 0)
                        this.drBunchingCore = 1;
                    else
                        this.drBunchingCore = 2;
                    this.OnChangeCore(null);
                    this.PrevImageIndex = this.CableproInput.MethodIndex;

                    if (result.cableproXmlInputOpen.protection_device === '0') {
                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;
                        setTimeout(() => {
                            $('#protection input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);
                        }, 1000);
                    }

                    else if (result.cableproXmlInputOpen.protection_device === '1') {

                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;

                        this.CableSize['protection']['MCBrating'] = result.cableproXmlInputOpen.protection_MCBrating;
                        this.CableSize['protection']['curve'] = result.cableproXmlInputOpen.protection_curve;
                        this.CableSize['protection']['earthfaulttime'] = result.cableproXmlInputOpen.protection_earthFault;
                        this.CableSize['protection']['rcd'] = result.cableproXmlInputOpen.protection_rcd;
                        this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                        this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;

                        setTimeout(() => {

                            $('input[caption="Trip Multiplier"]').val(result.cableproXmlInputOpen.protection_tr_multiplier);
                            $('input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);
                            $('#select_Earthfaulttime').val(result.cableproXmlInputOpen.protection_earthFault).trigger('change');
                            $('#select_MCB').val(result.cableproXmlInputOpen.protection_MCBrating).trigger('change');
                            $('#select_Curve').val(result.cableproXmlInputOpen.protection_curve).trigger('change', "false");
                            if (result.cableproXmlInputOpen.protection_rcd === '1') {
                                this.RCD = true;
                                $('#rcd').prop('checked', true);
                            } else {
                                this.RCD = false;
                                $('#rcd').prop('checked', false);
                            }
                            var currValue = parseFloat(result.cableproXmlInputOpen.protection_tr_current);
                            if (currValue > 0) {
                                $('input[caption="Trip current"]').val(currValue);
                            }
                            else {
                                currValue = parseFloat(result.cableproXmlInputOpen.protection_tr_multiplier) * parseInt(result.cableproXmlInputOpen.protection_MCBrating);
                                $('input[caption="Trip current"]').val(currValue);
                            }
                        }, 1000);
                    } else if (result.cableproXmlInputOpen.protection_device === '2') {

                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;
                        this.CableSize['protection']['MCCBrating'] = result.cableproXmlInputOpen.protection_MCCBrating;
                        this.CableSize['protection']['earthfaulttime'] = result.cableproXmlInputOpen.protection_earthFault;
                        this.CableSize['protection']['rcd'] = result.cableproXmlInputOpen.protection_rcd;
                        this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                        this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;

                        this.CableSize['protection']['thermalsetting'] = result.cableproXmlInputOpen.protection_thermal;
                        setTimeout(() => {
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            $('input[caption="Trip Multiplier"]').val(result.cableproXmlInputOpen.protection_tr_multiplier);
                            $('input[caption="Thermal setting"]').val(result.cableproXmlInputOpen.protection_thermal);
                            $('input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);

                            let max = $('#select_MCCB option:selected')[0].innerText.split(" ")[1];
                            $('input[caption="Thermal setting"]').attr('max', max);
                            $('#select_Earthfaulttime').val(result.cableproXmlInputOpen.protection_earthFault).trigger('change');
                            $('#select_MCCB').val(result.cableproXmlInputOpen.protection_MCCBrating).trigger('change');

                            if (result.cableproXmlInputOpen.protection_rcd === '1') {
                                $('#rcd').prop('checked', true);
                                this.RCD = true;
                            } else {
                                $('#rcd').prop('checked', false);
                                this.RCD = false;
                            }
                            /* code added on 29-11-2019 */
                            this.CableSize['protection']['MCCBrating'] = result.cableproXmlInputOpen.protection_MCCBrating;
                            this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                            this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                            this.CableSize['protection']['thermalsetting'] = result.cableproXmlInputOpen.protection_thermal;
                            $('input[caption="Thermal setting"]').val(result.cableproXmlInputOpen.protection_thermal);
                            /* code added on 29-11-2019 */

                            var currValue = parseFloat(result.cableproXmlInputOpen.protection_tr_current);
                            if (currValue > 0) {
                                $('input[caption="Trip current"]').val(currValue);
                            }
                            else {
                                currValue = parseFloat(result.cableproXmlInputOpen.protection_tr_multiplier) * parseInt(result.cableproXmlInputOpen.protection_MCCBrating);
                                $('input[caption="Trip current"]').val(currValue);
                            }
                        }, 1000);
                    } else if (result.cableproXmlInputOpen.protection_device === '3') {
                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;
                        this.CableSize['protection']['ACBrating'] = result.cableproXmlInputOpen.protection_ACBrating;
                        this.CableSize['protection']['earthfaulttime'] = result.cableproXmlInputOpen.protection_earthFault;
                        this.CableSize['protection']['rcd'] = result.cableproXmlInputOpen.protection_rcd;
                        this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                        this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                        this.CableSize['protection']['thermalsetting'] = result.cableproXmlInputOpen.protection_thermal;
                        this.CableSize['protection']['IsUnknown'] = result.cableproXmlInputOpen.isUnknown;
                        this.CableSize['protection']['protectionConfigurationModel'] = result.cableproXmlInputOpen.protectionConfigurationModel;
                        this.CableSize['protection']['Xml'] = result.cableproXmlInputOpen.xml;
                        this.CableSize['protection']['TrippingXml'] = result.cableproXmlInputOpen.trippingXml;
                        this.protectiveUnknown = result.cableproXmlInputOpen.isUnknown;
                        // this.protectionConfigurationModel=result.cableproXmlInputOpen.protectionConfigurationModel;
                        setTimeout(() => {
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            $('input[caption="Trip Multiplier"]').val(result.cableproXmlInputOpen.protection_tr_multiplier);
                            $('input[caption="Thermal setting"]').val(result.cableproXmlInputOpen.protection_thermal);
                            $('input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);
                            let max = $('#select_ACB option:selected')[0].innerText.split(" ")[1];
                            $('input[caption="Thermal setting"]').attr('max', max);
                            $('#select_Earthfaulttime').val(result.cableproXmlInputOpen.protection_earthFault).trigger('change');
                            $('#select_ACB').val(result.cableproXmlInputOpen.protection_ACBrating).trigger('change');
                            if (result.cableproXmlInputOpen.protection_rcd === '1') {
                                $('#rcd').prop('checked', true);
                                this.RCD = true;
                            } else {
                                $('#rcd').prop('checked', false);
                                this.RCD = false;
                            }
                            /* code added on 29-11-2019 */
                            this.CableSize['protection']['ACBrating'] = result.cableproXmlInputOpen.protection_MCCBrating;
                            this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                            this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                            this.CableSize['protection']['thermalsetting'] = result.cableproXmlInputOpen.protection_thermal;
                            $('input[caption="Thermal setting"]').val(result.cableproXmlInputOpen.protection_thermal);
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            /* code added on 29-11-2019 */
                        }, 1000);
                    } else if (result.cableproXmlInputOpen.protection_device === '4') {
                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;
                        this.CableSize['protection']['HRCFuserating'] = result.cableproXmlInputOpen.protection_HRCrating;
                        this.CableSize['protection']['earthfaulttime'] = result.cableproXmlInputOpen.protection_earthFault;
                        this.CableSize['protection']['rcd'] = result.cableproXmlInputOpen.protection_rcd;
                        this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                        this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                        setTimeout(() => {
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            $('input[caption="Trip Multiplier"]').val(result.cableproXmlInputOpen.protection_tr_multiplier);
                            $('input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);
                            $('#select_Earthfaulttime').val(result.cableproXmlInputOpen.protection_earthFault).trigger('change');
                            $('#select_HRCFuse').val(result.cableproXmlInputOpen.protection_HRCrating).trigger('change');
                            if (result.cableproXmlInputOpen.protection_rcd === '1') {
                                $('#rcd').prop('checked', true);
                                this.RCD = true;
                            } else {
                                $('#rcd').prop('checked', false);
                                this.RCD = false;
                            }
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                        }, 1000);
                    } else if (result.cableproXmlInputOpen.protection_device === '5') {
                        this.CableSize['protection']['shortcircuittime'] = result.cableproXmlInputOpen.protection_short_c_time;
                        this.CableSize['protection']['customrating'] = result.cableproXmlInputOpen.protection_Customrating;
                        this.CableSize['protection']['earthfaulttime'] = result.cableproXmlInputOpen.protection_earthFault;
                        this.CableSize['protection']['rcd'] = result.cableproXmlInputOpen.protection_rcd;
                        this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                        this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                        this.CableSize['protection']['thermalsetting'] = result.cableproXmlInputOpen.protection_thermal;
                        this.CableSize['protection']['description'] = result.cableproXmlInputOpen.protection_description;
                        setTimeout(() => {
                            $('input[caption="Custom Rating"]').val(result.cableproXmlInputOpen.protection_Customrating);
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                            $('input[caption="Trip Multiplier"]').val(result.cableproXmlInputOpen.protection_tr_multiplier);
                            $('input[caption="Thermal setting"]').val(result.cableproXmlInputOpen.protection_thermal);
                            $('input[caption="Short-circuit time"]').val(result.cableproXmlInputOpen.protection_short_c_time);
                            $('input[caption="Description"]').val(result.cableproXmlInputOpen.protection_description);
                            $('#select_Earthfaulttime').val(result.cableproXmlInputOpen.protection_earthFault).trigger('change');
                            if (result.cableproXmlInputOpen.protection_rcd === '1') {
                                $('#rcd').prop('checked', true);
                                this.RCD = true;
                            } else {
                                $('#rcd').prop('checked', false);
                                this.RCD = false;
                            }

                            /* code added on 29-11-2019 */
                            this.CableSize['protection']['Custom Rating'] = result.cableproXmlInputOpen.protection_MCCBrating;
                            this.CableSize['protection']['tripcurrent'] = result.cableproXmlInputOpen.protection_tr_current;
                            this.CableSize['protection']['tripmultiplier'] = result.cableproXmlInputOpen.protection_tr_multiplier;
                            this.CableSize['protection']['thermalsetting'] = result.cableproXmlInputOpen.protection_thermal;
                            $('input[caption="Thermal setting"]').val(result.cableproXmlInputOpen.protection_thermal);
                            /* code added on 29-11-2019 */
                            $('input[caption="Trip current"]').val(result.cableproXmlInputOpen.protection_tr_current);
                        }, 1000);
                    }
                    this.CableSize['insulation'] = result.cableproXmlInputOpen.insulationXmlVal;
                    this.CableSize['conductor'] = result.cableproXmlInputOpen.conductorXmlVal;
                    this.CableSize['earthconductor'] = result.cableproXmlInputOpen.earthconductorXmlVal;
                    this.CableSize['core'] = result.cableproXmlInputOpen.coreXmlVal;
                    this.CableSize['armour'] = result.cableproXmlInputOpen.armourXmlVal;
                    this.CableSize['phase'] = result.cableproXmlInputOpen.phaseXmlVal;
                    this.CableSize['faultlevel'] = result.cableproXmlInputOpen.supply_faultLevel;
                    this.CableSize['voltagedrop'] = result.cableproXmlInputOpen.supply_voltageDrop;
                    this.CableSize['powerunit'] = result.cableproXmlInputOpen.powerunit;
                    if (result.cableproXmlInputOpen.efficiency != 0)
                        this.CableSize['efficiency'] = result.cableproXmlInputOpen.efficiency;
                    else
                        this.CableSize['efficiency'] = this.efficiency;

                    this.CableSize['supplyvoltage'] = result.cableproXmlInputOpen.supply_voltage;
                    this.CableSize['lengthofrun'] = result.cableproXmlInputOpen.supply_lengthOfrun;
                    this.CableSize['usevd'] = result.cableproXmlInputOpen.supply_useVd;
                    this.CableSize['isVoltageDrop'] = result.cableproXmlInputOpen.isVoltageDrop;
                    this.CableSize['isderatingwiz'] = result.cableproXmlInputOpen.isderatingwiz;
                    this.CableSize['deratingtemp'] = result.cableproXmlInputOpen.deratingtemp;
                    // if(result.cableproXmlInputOpen.supply_useVd =='0')
                    // {
                    //     $(".lblcurrent").text('Output current (A)');
                    // }
                    // else{
                    //     $(".lblcurrent").text('Current (A)');

                    // } 

                    this.CableSize['current'] = result.cableproXmlInputOpen.load_current;
                    this.CableSize['currentamp'] = result.cableproXmlInputOpen.load_currentamp;
                    this.CableSize['powerfactor'] = result.cableproXmlInputOpen.load_powerFactor;
                    this.CableSize['deratingfactor'] = parseFloat(result.cableproXmlInputOpen.load_deratingFactor).toFixed(2);
                    this.CableSize['device'] = result.cableproXmlInputOpen.protection_device;
                    // alert(result.cableproXmlInputOpen.specify_Phaseconductor_circuit + "getdata");
                    if (result.cableproXmlInputOpen.specify_Phaseconductor_mode === '1') {
                        $('#active-toggle').prop('checked', true);
                        this.showActiveLab = false;
                        this.ShowActive = true;
                        $('#Active_c').removeAttr('disabled');
                        $('#Active_c').val(result.cableproXmlInputOpen.specify_Phaseconductor_circuit);
                        this.active_NoOf_circuit = result.cableproXmlInputOpen.specify_Phaseconductor_circuit;
                        this.CableSize['specifyconductor']['phase']['mode'] = '1';
                        this.CableSize['specifyconductor']['phase']['circuits'] = result.cableproXmlInputOpen.specify_Phaseconductor_circuit;
                        this.CableSize['specifyconductor']['phase']['size'] = result.cableproXmlInputOpen.specify_Phaseconductor_size;
                        var selectedItem = result.specifyConductorDto.find(x => x.value === result.cableproXmlInputOpen.specify_Phaseconductor_size + ' mm²');
                        if (selectedItem) {
                            this.CableproInput.ActiveId = selectedItem.id;
                            this.PrevActiveValue = selectedItem.value.replace(' mm²', '');
                        }
                    }
                    else {
                        this.ShowActive = false;
                        this.showActiveLab = true;
                    }
                    if (result.cableproXmlInputOpen.specify_Neutralconductor_mode === '1') {
                        $('#neutral-toggle').prop('checked', true);
                        $('#Neutral_c').val(result.cableproXmlInputOpen.specify_Neutralconductor_circuit);
                        this.CableSize['specifyconductor']['neutral']['circuits'] = result.cableproXmlInputOpen.specify_Neutralconductor_circuit;
                        this.CableSize['specifyconductor']['neutral']['size'] = result.cableproXmlInputOpen.specify_Neutralconductor_size;
                        this.CableSize['specifyconductor']['neutral']['mode'] = 1
                        this.ShowNeutral = true;
                        this.ShowNeutralLab = false
                        var selectedItem = result.specifyConductorDto.find(x => x.value === result.cableproXmlInputOpen.specify_Neutralconductor_size || x.value === result.cableproXmlInputOpen.specify_Neutralconductor_size + ' mm²');
                        if (selectedItem) {
                            this.CableproInput.NeutralId = selectedItem.id;
                            this.PrevNeutralValue = selectedItem.value.replace(' mm²', '');
                        }
                    }
                    else {
                        this.ShowNeutral = false;
                        this.ShowNeutralLab = true;
                        $('#neutral-toggle').prop('checked', false);
                        $('#Neutral_c').attr('disabled', 'true');
                    }
                    if (result.cableproXmlInputOpen.specify_Earthconductor_mode === '0') {
                        $('#earth-chk').prop('checked', true);
                        this.CableSize['specifyconductor']['earth']['circuits'] = "0";
                        this.CableSize['specifyconductor']['earth']['size'] = '0'
                        this.CableSize['specifyconductor']['earth']['mode'] = '0';
                        $('#earth-toggle').attr('disabled', 'true');
                        this.ShowEarth = false;
                        $('#Earth_c').attr('disabled', 'true');
                        // $('#Earth_c').val(1);
                        this.earth_NoOf_circuit = 1;
                        this.ShowEarthLab = true;
                        this.showEarthButton = false;

                    } else if (result.cableproXmlInputOpen.specify_Earthconductor_mode === '2') {
                        $('#earth-toggle').prop('checked', true);
                        $('#Earth_c').removeAttr('disabled');
                        this.earth_NoOf_circuit = result.cableproXmlInputOpen.specify_Earthconductor_circuit;
                        $('#Earth_c').val(result.cableproXmlInputOpen.specify_Earthconductor_circuit);
                        this.CableSize['specifyconductor']['earth']['circuits'] = result.cableproXmlInputOpen.specify_Earthconductor_circuit;
                        var obj = this.earthList.find(x => x.id == result.cableproXmlInputOpen.specify_Earthconductor_size);
                        if (obj) {
                            this.CableproInput.EarthId = obj.id;
                        }
                        // $('#Earth').val(result.cableproXmlInputOpen.specify_Earthconductor_size).trigger('change');
                        this.ShowEarth = true;
                        this.ShowEarthLab = false;
                    }
                    //
                    this.CableSize['tableno'] = result.cableproXmlInputOpen.installTable;
                    this.CableSize['tablectrlvalue'] = result.cableproXmlInputOpen.methodIndex;
                    this.CableSize['description'] = result.cableproXmlInputOpen.installTable_description;
                    this.onResetControlValues();
                    if (this.CableproInput.PhaseId.toString() !== '100' && this.CableproInput.PhaseId.toString() !== '102') {
                        if (this.CableSize['tableno'] === '17') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                            }, 1000);
                        } else if (this.CableSize['tableno'] === '18') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                                $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                            }, 100);
                        }
                    }
                    if (this.CableSize['tableno'] === '16') {
                        setTimeout(() => {
                            $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('title', 'Derating factor: 0.76');
                            $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('title', 'Derating factor: 0.58');
                            $('#divInstallTbl table tr:nth-child(4) img')[0].setAttribute('title', 'Derating factor: 0.47');
                            $('#divInstallTbl table tr:nth-child(5) img')[0].setAttribute('title', 'Derating factor: 0.4');
                        }, 1000);
                    }
                    if (this.cableCalculationStandard == '0' || this.cableCalculationStandard == '1')
                        this.onCancel();
                    if (result.cableproXmlInputOpen.deratingFactorData) {
                        this.finalDrf = parseFloat(result.cableproXmlInputOpen.deratingFactorData.finalDrf.toFixed(2));
                        this.CalculationReportInput.wizardValues = result.cableproXmlInputOpen.deratingFactorData;
                        // $('#checkboxUseWizard').attr('checked', 'true');
                        $('#deratingFactor').val(this.finalDrf);
                        this.UserWizard = true;
                        this.CableSize["isderatingwiz"] = "1";
                        this.OriDrfWizard = result.cableproXmlInputOpen.deratingFactorData;
                    }
                    this.loadProtectionCalculation();
                    this.setWizardFactorValues();
                }
                this.loading = false;
                this.flag = false;
                this.loadPreviousValues();
            }
            else {
                var endTime = performance.now();
                var totalTime = endTime - startTime; // time took to run in milliseconds

                if (result.insulationDto !== null && result.insulationDto !== undefined) {
                    this.insulationData = result.insulationDto;
                    this.CableproInput.InsulationId = this.insulationData[0].id;
                    this.CableSize['insulation'] = this.insulationData[0].xmlValue.toString();
                }
                if (result.conductorDto !== null && result.conductorDto !== undefined) {
                    if (result.conductorDto.length > 0) {
                        if (this.PrevConductorId == -1) {
                            this.PrevConductorId = 0;
                        }
                        this.conductorData = result.conductorDto;

                        if (this.conductorData && this.conductorData.length > 0) {
                            var item = this.conductorData.find(x => x.xmlValue == this.PrevConductorId);
                            if (item) {
                                this.CableSize['conductor'] = item.xmlValue;
                                this.CableproInput.ConductorId = item.id;
                            }
                            else {
                                this.CableSize['conductor'] = this.coreData[0].xmlValue;
                                this.CableproInput.ConductorId = this.coreData[0].id;
                            }
                        }

                    }

                }


                if (this.earthConductorData && this.earthConductorData.length > 0) {
                    if (this.PrevEarthConductorId == -1) {
                        this.PrevEarthConductorId = 1;
                    }
                    // var item = this.earthConductor.find(x => x.xmlValue == this.PrevEarthConductorId);

                    this.CableSize['earthconductor'] = this.PrevEarthConductorId;
                    this.CableproInput.EarthConductorId = this.PrevEarthConductorId;

                }

                if (result.coreDto !== null && result.coreDto !== undefined) {

                    if (result.coreDto.length > 0) {
                        this.coreData = result.coreDto;
                        if (!this.PrevCoreId || this.PrevCoreId == -1 || Number.isNaN(this.PrevCoreId)) {
                            this.PrevCoreId = 0;
                        }
                        if (this.coreData && this.coreData.length > 0) {
                            var item = this.coreData.find(x => x.xmlValue == this.PrevCoreId);
                            if (item) {
                                this.CableSize['core'] = item.xmlValue;
                                this.CableproInput.CoreId = item.id;
                            }
                            else {
                                this.CableSize['core'] = this.coreData[0].xmlValue;
                                this.CableproInput.CoreId = this.coreData[0].id;
                            }
                        }
                    }
                }
                if (result.armourDto !== null && result.armourDto !== undefined) {
                    this.armourData = result.armourDto;
                    // if (this.CableSize['armour'])
                    //     this.CableproInput.ArmourId = parseInt(this.CableSize['armour']);
                    // else
                    // this.CableproInput.ArmourId = armourId;
                    // var item = this.armourData.find(x => x.id == this.CableproInput.ArmourId);
                    // if (item) {
                    //     this.CableSize['armour'] = item.xmlValue;
                    // }
                    // else {
                    //     this.CableproInput.ArmourId = this.armourData[0].id;
                    //     this.CableSize['armour'] = this.armourData[0].xmlValue;
                    // }
                    // setTimeout((e) => {
                    //     $('#armour').val(this.CableproInput.ArmourId).select2({
                    //         width: '100%',
                    //         minimumResultsForSearch: 5 // disable search
                    //     });
                    // }, 100);

                }
                if (result.phaseDto !== null && result.phaseDto !== undefined) {
                    if (result.phaseDto.length > 0) {
                        this.phaseData = result.phaseDto;
                        this.CableproInput.PhaseId = phaseId;
                        var item = result.phaseDto.find(x => x.id == phaseId);
                        if (item) {
                            this.CableSize['phase'] = result.phaseDto.find(x => x.id == phaseId).xmlValue;
                        }
                        else {
                            this.CableproInput.PhaseId = this.phaseData[0].id;
                            this.CableSize['phase'] = this.phaseData[0].xmlValue.toString();
                            if (this.CableproInput.PhaseId.toString() === '100') {
                                this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
                            }
                            else if (this.CableproInput.PhaseId.toString() === '101') {
                                this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
                            }
                            else if (this.CableproInput.PhaseId.toString() === '102') {
                                this.CableSize['supplyvoltage'] = this.dc_Voltage;
                            }
                            else if (this.CableproInput.PhaseId.toString() === '103') {
                                this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
                            }
                            else if (this.CableproInput.PhaseId.toString() === '104') {
                                this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
                            }
                        }
                    }
                }

                if (result.installTableDto !== null && result.installTableDto !== undefined) {

                    if (result.installTableDto[0].id !== 0 && result.installTableDto[0].name !== null) {

                        this.installTableData = result.installTableDto;
                        this.CableproInput.InstallTableId = this.installTableData[0].id;
                        if (this.cableCalculationStandard == '2') {
                            this.CableSize['tableno'] = this.installTableData[0].id;
                        }
                        else {
                            this.CableSize['tableno'] = this.installTableData[0].name;
                        }
                        var installTableImageName = '', installTableImageDesc = '';


                        if (this.installTableData[0].images.length - 1 < this.PrevImageIndex) {
                            this.CableproInput.MethodIndex = this.installTableData[0].images[0].index;
                            this.CableSize['tablectrlvalue'] = this.CableproInput.MethodIndex.toString();
                            this.CableSize['description'] = this.installTableData[0].images[0].name + ' ' + this.installTableData[0].images[0].description;
                            installTableImageName = this.installTableData[0].images[0].name;
                            installTableImageDesc = this.installTableData[0].images[0].description;

                            this.CableSize['currentTable'] = this.installTableData[0].images[0].currentTable;
                            this.CableSize['voltagedropTable'] = this.installTableData[0].images[0].voltagedropTable;

                        } else {

                            if (this.installTableData[0].images.length - 1 >= this.PrevImageIndex) {
                                this.CableproInput.MethodIndex = this.installTableData[0].images[this.PrevImageIndex].index;
                                this.CableSize['currentTable'] = this.installTableData[0].images[this.PrevImageIndex].currentTable;
                                this.CableSize['voltagedropTable'] = this.installTableData[0].images[this.PrevImageIndex].voltagedropTable;
                            }
                            else {
                                this.CableproInput.MethodIndex = this.installTableData[0].images[0].index;
                                this.CableSize['currentTable'] = this.installTableData[0].images[0].currentTable;
                                this.CableSize['voltagedropTable'] = this.installTableData[0].images[0].voltagedropTable;
                            }

                            // this.CableproInput.MethodIndex = this.installTableData[0].images[this.PrevImageIndex].index;
                            this.CableSize['tablectrlvalue'] = this.CableproInput.MethodIndex.toString();
                            this.CableSize['description'] = this.installTableData[0].images[this.PrevImageIndex].name + ' ' + this.installTableData[0].images[this.PrevImageIndex].description;
                            installTableImageName = this.installTableData[0].images[this.PrevImageIndex].name;
                            installTableImageDesc = this.installTableData[0].images[this.PrevImageIndex].description;
                        }
                        this.isInsulationChange = true;
                        this.UpdateWizardOnInstallationChange(this.CableproInput.MethodIndex, this.CableSize['tableno'], installTableImageName, installTableImageDesc, this.CableSize['voltagedropTable'], this.CableSize['currentTable'], 'getdata');

                        this.showWarningOnInstallationChange(this.CableproInput.MethodIndex, this.CableSize['tableno'], installTableImageName, installTableImageDesc, this.CableSize['voltagedropTable'], this.CableSize['currentTable'], 'getdata');

                        if (this.CableproInput.PhaseId.toString() !== '100' && this.CableproInput.PhaseId.toString() !== '102') {
                            if (this.CableSize['tableno'] === '17') {
                                setTimeout(() => {
                                    // $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                                    // $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                                }, 1000);
                            } else if (this.CableSize['tableno'] === '18') {
                                setTimeout(() => {
                                    $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                                    $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                                    $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                                }, 1000);
                            }
                        }
                        if (this.CableSize['tableno'] === '16') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('title', 'Derating factor: 0.76');
                                $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('title', 'Derating factor: 0.58');
                                $('#divInstallTbl table tr:nth-child(4) img')[0].setAttribute('title', 'Derating factor: 0.47');
                                $('#divInstallTbl table tr:nth-child(5) img')[0].setAttribute('title', 'Derating factor: 0.4');
                            }, 1000);
                        }
                    }

                }
                if (result.specifyConductorDto !== null && result.specifyConductorDto !== undefined) {
                    if (result.specifyConductorDto.length > 0) {
                        this.specifyConductorData = result.specifyConductorDto;

                        if (this.PrevActiveValue !== undefined) {

                            let val = this.specifyConductorData.filter(x => x.value === this.PrevActiveValue.trim() || x.value === this.PrevActiveValue.trim() + ' mm²');
                            if (val.length > 0) {
                                this.CableproInput.ActiveId = val[0].id;
                                this.CableSize['specifyconductor']['phase']['size'] = val[0].value.replace(' mm²', '');
                            } else {

                                this.CableproInput.ActiveId = this.specifyConductorData[0].id;
                                this.CableSize['specifyconductor']['phase']['size'] = this.specifyConductorData[0].value.replace(' mm²', '');
                            }
                        } else {
                            this.CableproInput.ActiveId = this.specifyConductorData[0].id;
                            this.CableSize['specifyconductor']['phase']['size'] = this.specifyConductorData[0].value.replace(' mm²', '');
                        }
                        if (this.PrevNeutralValue !== undefined) {

                            let val = this.specifyConductorData.filter(x => x.value === this.PrevNeutralValue.trim() || x.value === this.PrevNeutralValue.trim() + ' mm²');
                            if (val.length > 0) {
                                this.CableproInput.NeutralId = val[0].id;
                                this.CableSize['specifyconductor']['neutral']['size'] = val[0].value.replace(' mm²', '');
                            } else {
                                this.CableproInput.NeutralId = this.specifyConductorData[0].id;
                                this.CableSize['specifyconductor']['neutral']['size'] = this.specifyConductorData[0].value.replace(' mm²', '');
                            }
                        } else {
                            this.CableproInput.NeutralId = this.specifyConductorData[0].id;
                            this.CableSize['specifyconductor']['neutral']['size'] = this.specifyConductorData[0].value.replace(' mm²', '');
                        }
                    }

                }
                if (result.windSpeedDto !== null && result.windSpeedDto !== undefined) {
                    if (result.windSpeedDto.length > 0) {
                        this.windSpeedData = result.windSpeedDto;
                        if (this.windSpeedData.length > 0 && this.cableCalculationStandard != '2') {
                            this.hasWind = true;
                            // 
                            if (result.cableproXmlInputOpen != undefined && result.cableproXmlInputOpen != null) {
                                this.CableproInput.WindSpeedId = this.windSpeedData[result.cableproXmlInputOpen.windSpeedId].id;
                                this.CableSize['installtablewind']['wind'] = this.windSpeedData[result.cableproXmlInputOpen.windSpeedId].xmlValue; // ak change
                            }
                            else {
                                if (!(this.CableSize && this.CableSize['installtablewind'] && this.CableSize['installtablewind']['wind'])) {
                                    this.CableproInput.WindSpeedId = this.windSpeedData[0].id;
                                    this.CableSize['installtablewind']['wind'] = this.windSpeedData[0].xmlValue;
                                }
                                else {
                                    this.CableproInput.WindSpeedId = this.windSpeedData[parseInt(this.CableSize['installtablewind']['wind'])].id;
                                }
                            }

                        } else {
                            this.hasWind = false;
                        }
                    }

                }

                if (result.uiControlsLookupDto !== null && result.uiControlsLookupDto !== undefined) {

                    this.uiControlsLookUpData = result.uiControlsLookupDto;

                    if (this.cableSizeReopenData != null && this.cableSizeReopenData != undefined) {

                        this.CableSize['protection'] = this.cableSizeReopenData;

                        if (this.isCableSizeModified == false) {

                            if (this.uiControlsLookUpData[0].uiControlsLookUpId === 102) {
                                this.uiControlsLookUpData[0].controles[0].fDefaultValue = this.cableSizeReopenData.shortcircuittime;
                            }
                            if (this.uiControlsLookUpData[0].uiControlsLookUpId === 103) {

                                this.uiControlsLookUpData[0].controles[0].fDefaultValue = this.cableSizeReopenData.shortcircuittime;
                                this.uiControlsLookUpData[0].controles[1].fDefaultValue = this.cableSizeReopenData.earthfaulttime;
                                let ratingID = parseInt(this.cableSizeReopenData.MCBrating);
                                this.PrevMCBVal = this.cableSizeReopenData.MCBrating;
                                var ratingName = this.uiControlsLookUpData[0].controles[2].cValue[ratingID].name;
                                this.uiControlsLookUpData[0].controles[2].fDefaultValue = ratingName;
                                let curveIndex = parseInt(this.cableSizeReopenData.curve);
                                this.uiControlsLookUpData[0].controles[3].fDefaultValue = this.uiControlsLookUpData[0].controles[3].cValue[curveIndex].name;
                                // this.uiControlsLookUpData[0].controles[3].fDefaultValue = this.cableSizeReopenData.curve;
                                this.uiControlsLookUpData[0].controles[4].fDefaultValue = this.cableSizeReopenData.rcd;
                                this.uiControlsLookUpData[0].controles[5].fDefaultValue = this.cableSizeReopenData.tripcurrent;
                                this.uiControlsLookUpData[0].controles[6].fDefaultValue = this.cableSizeReopenData.tripmultiplier;
                            }
                            if (this.uiControlsLookUpData[0].uiControlsLookUpId === 107) {
                                this.PrevCustomeRatingVal = this.cableSizeReopenData.customrating;
                                this.uiControlsLookUpData[0].controles[0].fDefaultValue = this.cableSizeReopenData.shortcircuittime;
                                this.uiControlsLookUpData[0].controles[1].fDefaultValue = this.cableSizeReopenData.earthfaulttime;
                                this.uiControlsLookUpData[0].controles[2].fDefaultValue = this.cableSizeReopenData.customrating;
                                this.uiControlsLookUpData[0].controles[3].fDefaultValue = this.cableSizeReopenData.thermalsetting;
                                this.uiControlsLookUpData[0].controles[4].fDefaultValue = this.cableSizeReopenData.rcd;
                                this.uiControlsLookUpData[0].controles[5].fDefaultValue = this.cableSizeReopenData.tripcurrent;
                                this.uiControlsLookUpData[0].controles[6].fDefaultValue = this.cableSizeReopenData.tripmultiplier;
                                this.uiControlsLookUpData[0].controles[7].fDefaultValue = this.cableSizeReopenData.description;
                                if ($('input').filter("[caption='Thermal setting']").length) {
                                    let fcaption = this.uiControlsLookUpData[0].controles[3].fCaption;
                                    if (fcaption == "Thermal setting") {
                                        $('input').filter("[caption='Thermal setting']").val(this.uiControlsLookUpData[0].controles[3].fDefaultValue);
                                        this.CableSize['protection']['thermalsetting'] = this.uiControlsLookUpData[0].controles[3].fDefaultValue;
                                        let Val1: any = $('input').filter("[caption='Thermal setting']").val().toString();
                                        const MultiplierValue = $('input').filter("[caption='Trip Multiplier']").val().toString();
                                        let currentValue: any = '';
                                        let Val2: any = '';
                                        if (!isNaN(Val1) && Val1.toString().indexOf('.') != -1) {
                                            Val1 = parseFloat(Val1);
                                        } else {
                                            Val1 = parseInt(Val1);
                                        }
                                        if (!Number.isNaN(Number(MultiplierValue)) && MultiplierValue.toString().indexOf('.') != -1) {
                                            Val2 = parseFloat(MultiplierValue);
                                        } else {
                                            Val2 = parseInt(MultiplierValue);
                                        }
                                        currentValue = Val1 * Val2;
                                        currentValue = currentValue.toFixed(2);
                                        if ($('input').filter("[caption='Trip current']").length) {
                                            $('input').filter("[caption='Trip current']").val(currentValue);
                                            this.CableSize['protection']['tripcurrent'] = currentValue;
                                        }
                                    }
                                }

                            }
                            if (this.uiControlsLookUpData[0].uiControlsLookUpId === 106) {

                                this.uiControlsLookUpData[0].controles[0].fDefaultValue = this.cableSizeReopenData.shortcircuittime;
                                this.uiControlsLookUpData[0].controles[1].fDefaultValue = this.cableSizeReopenData.earthfaulttime;
                                this.PrevHRCVal = this.cableSizeReopenData.HRCFuserating;
                                let ratingID = parseInt(this.cableSizeReopenData.HRCFuserating);
                                var ratingName = this.uiControlsLookUpData[0].controles[2].cValue[ratingID].name;
                                this.uiControlsLookUpData[0].controles[2].fDefaultValue = ratingName;
                                this.uiControlsLookUpData[0].controles[3].fDefaultValue = this.cableSizeReopenData.rcd;
                                this.uiControlsLookUpData[0].controles[4].fDefaultValue = this.cableSizeReopenData.tripcurrent;
                                this.uiControlsLookUpData[0].controles[5].fDefaultValue = this.cableSizeReopenData.tripmultiplier;
                            }
                            if (this.uiControlsLookUpData[0].uiControlsLookUpId === 104) {

                                this.uiControlsLookUpData[0].controles[0].fDefaultValue = this.cableSizeReopenData.shortcircuittime;
                                this.uiControlsLookUpData[0].controles[1].fDefaultValue = this.cableSizeReopenData.earthfaulttime;
                                let ratingID = parseInt(this.cableSizeReopenData.MCCBrating);

                                this.PrevMCCBVal = this.cableSizeReopenData.MCCBrating;
                                var ratingName = this.uiControlsLookUpData[0].controles[2].cValue[ratingID].name;
                                this.uiControlsLookUpData[0].controles[2].fDefaultValue = ratingName;
                                this.uiControlsLookUpData[0].controles[3].fDefaultValue = this.cableSizeReopenData.thermalsetting;
                                this.uiControlsLookUpData[0].controles[4].fDefaultValue = this.cableSizeReopenData.rcd;
                                this.uiControlsLookUpData[0].controles[5].fDefaultValue = this.cableSizeReopenData.tripcurrent;
                                this.uiControlsLookUpData[0].controles[6].fDefaultValue = this.cableSizeReopenData.tripmultiplier;
                            }
                            if (this.uiControlsLookUpData[0].uiControlsLookUpId === 105) {

                                this.uiControlsLookUpData[0].controles[0].fDefaultValue = this.cableSizeReopenData.shortcircuittime;
                                this.uiControlsLookUpData[0].controles[1].fDefaultValue = this.cableSizeReopenData.earthfaulttime;
                                this.PrevACBVal = this.cableSizeReopenData.ACBrating;
                                let ratingID = parseInt(this.cableSizeReopenData.ACBrating);
                                var ratingName = this.uiControlsLookUpData[0].controles[2].cValue[ratingID].name;
                                this.uiControlsLookUpData[0].controles[2].fDefaultValue = ratingName;
                                this.uiControlsLookUpData[0].controles[3].fDefaultValue = this.cableSizeReopenData.thermalsetting;
                                this.uiControlsLookUpData[0].controles[4].fDefaultValue = this.cableSizeReopenData.rcd;
                                this.uiControlsLookUpData[0].controles[5].fDefaultValue = this.cableSizeReopenData.tripcurrent;
                                this.uiControlsLookUpData[0].controles[6].fDefaultValue = this.cableSizeReopenData.tripmultiplier;
                            }
                        }


                    }
                    else {
                        if (this.cableCalculationStandard != '2') {
                            this.PrevACBVal = '0';
                            this.PrevMCBVal = '21';
                            this.PrevMCCBVal = '6';
                            this.PrevHRCVal = '12';
                            this.PrevCustomeRatingVal = '100';
                        }
                        this.CableSize['protection'] = {
                            shortcircuittime: '0.1', MCBrating: '0', ACBrating: '0', HRCFuserating: '0', MCCBrating: '0',
                            curve: '0', earthfaulttime: '0', rcd: '0', tripcurrent: '0', tripmultiplier: '0', thermalsetting: '0', customrating: '0', description: '0', IsUnknown: undefined,
                            Xml: undefined, protectionConfigurationModel: {}
                        }

                    }


                    for (let i = 0; i < result.uiControlsLookupDto.length; i++) {
                        if (result.uiControlsLookupDto[i].type === 'Protection' && protection !== '') {
                            if (result.uiControlsLookupDto[i].uiControlsValues === protection) {
                                for (let j = 0; j < result.uiControlsLookupDto[i].controles.length; j++) {
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Short-circuit time') {
                                        this.CableSize['protection']['shortcircuittime'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Earth fault time') {
                                        this.CableSize['protection']['earthfaulttime'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Rating') {
                                        for (let k = 0; k < result.uiControlsLookupDto[i].controles[j].cValue.length; k++) {
                                            if (result.uiControlsLookupDto[i].controles[j].cValue[k].name === result.uiControlsLookupDto[i].controles[j].fDefaultValue) {
                                                if (protection === 'HRC Fuse') {
                                                    this.CableSize['protection']['HRCFuserating'] = result.uiControlsLookupDto[i].controles[j].cValue[k].value;
                                                }
                                                else {
                                                    this.CableSize['protection']['' + protection + 'rating'] = result.uiControlsLookupDto[i].controles[j].cValue[k].value;
                                                }
                                            }
                                        }
                                        setTimeout(() => {

                                            if (protection === 'MCB') {

                                                if (this.PrevMCBVal === '') {
                                                    this.PrevMCBVal = $('#select_MCB').prop("selectedIndex");
                                                }
                                                if (this.PrevMCBVal !== '') {

                                                    $('#select_' + protection)[0][this.PrevMCBVal].selected = true;
                                                    this.CableSize['protection']['' + protection + 'rating'] = $('#select_' + protection)[0][this.PrevMCBVal].value;

                                                    this.ProtectionCalcDropdown('', $('#select_' + protection)[0][this.PrevMCBVal]);
                                                }
                                            } else if (protection === 'MCCB') {

                                                if (this.PrevMCCBVal === '') {
                                                    this.PrevMCCBVal = $('#select_MCCB').prop("selectedIndex");
                                                }
                                                if (this.PrevMCCBVal !== '') {
                                                    $('#select_' + protection)[0][this.PrevMCCBVal].selected = true;
                                                    this.CableSize['protection']['' + protection + 'rating'] = $('#select_' + protection)[0][this.PrevMCCBVal].value;

                                                    this.ProtectionCalcDropdown('', $('#select_' + protection)[0][this.PrevMCCBVal]);
                                                    let max = $('#select_MCCB option:selected')[0].innerText.split(" ")[1];
                                                    $('input[caption="Thermal setting"]').attr('max', max);
                                                }
                                            } else if (protection === 'ACB') {
                                                if (this.PrevACBVal === '') {
                                                    this.PrevACBVal = $('#select_ACB').prop("selectedIndex");
                                                }
                                                if (this.PrevACBVal !== '') {
                                                    $('#select_' + protection)[0][this.PrevACBVal].selected = true;
                                                    this.CableSize['protection']['' + protection + 'rating'] = $('#select_' + protection)[0][this.PrevACBVal].value;
                                                    this.ProtectionCalcDropdown('', $('#select_' + protection)[0][this.PrevACBVal]);
                                                    let max = $('#select_ACB option:selected')[0].innerText.split(" ")[1];

                                                    $('input[caption="Thermal setting"]').attr('max', max);
                                                }
                                            } else if (protection === 'HRC Fuse') {
                                                if (this.PrevHRCVal === '') {
                                                    this.PrevHRCVal = $('#select_HRCFuse').prop("selectedIndex");
                                                }
                                                if (this.PrevHRCVal !== '') {
                                                    $('#select_HRCFuse')[0][this.PrevHRCVal].selected = true;
                                                    this.CableSize['protection']['HRCFuserating'] = $('#select_HRCFuse')[0][this.PrevHRCVal].value;
                                                    this.ProtectionCalcDropdown('', $('#select_HRCFuse')[0][this.PrevHRCVal]);
                                                }
                                            }

                                            if ($('.c-select').length) {
                                                let $singleSelect = $('.c-select'),
                                                    $singleSelectHasSearch = $('.c-select.has-search'),
                                                    $multipleSelect = $('.c-select.c-select--multiple'),
                                                    $disabledSelect = $('.c-select.is-disabled');

                                                $singleSelect.select2({
                                                    width: '100%',
                                                    minimumResultsForSearch: Infinity // disable search
                                                });

                                                // multiple select
                                                $multipleSelect.select2({
                                                    width: '100%',
                                                    multiple: true
                                                });

                                                // disabled select
                                                $disabledSelect.select2({
                                                    width: '100%',
                                                    disabled: true
                                                });
                                            }

                                        }, 100);
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Curve') {
                                        for (let k = 0; k < result.uiControlsLookupDto[i].controles[j].cValue.length; k++) {
                                            if (result.uiControlsLookupDto[i].controles[j].cValue[k].name === result.uiControlsLookupDto[i].controles[j].fDefaultValue) {
                                                this.CableSize['protection']['curve'] = result.uiControlsLookupDto[i].controles[j].cValue[k].value;
                                            }
                                        }
                                        // setTimeout(() => {
                                        //     if (this.PrevCurveVal !== '') {
                                        //         $('#select_Curve')[0][this.PrevCurveVal].selected = true;
                                        //         this.CableSize['protection']['curve'] = $('#select_Curve')[0][this.PrevCurveVal].value;
                                        //     }
                                        // }, 100);
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'RCD') {
                                        this.CableSize['protection']['rcd'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Trip current') {
                                        this.CableSize['protection']['tripcurrent'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Trip Multiplier') {
                                        this.CableSize['protection']['tripmultiplier'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                        this._calculationService.setPrevValue('').subscribe((result) => { }, error => { });
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Thermal setting') {
                                        this.CableSize['protection']['thermalsetting'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                        //this._calculationService.setPrevValue('').subscribe((result) => { }, error => { });
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Custom Rating') {
                                        this.CableSize['protection']['customrating'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                        setTimeout(() => {
                                            if (this.PrevCustomeRatingVal === '') {

                                                this.PrevCustomeRatingVal = $('input').filter("[caption='Custom Rating']").val();
                                            }
                                            if (this.PrevCustomeRatingVal !== '') {

                                                $('input').filter("[caption='Custom Rating']").val(this.PrevCustomeRatingVal);
                                                this.CableSize['protection']['customrating'] = $('input').filter("[caption='Custom Rating']").val();


                                                const MultiplierValue = this.CableSize['protection']['tripmultiplier'];
                                                let RatingValue = this.CableSize['protection']['customrating'];
                                                let thermalsetting = this.CableSize['protection']['thermalsetting'];
                                                $('input').filter("[caption='Thermal setting']").val(this.CableSize['protection']['thermalsetting']);
                                                $('input[caption="Thermal setting"]').attr('max', RatingValue);
                                                //this.CableSize['protection']['thermalsetting'] = RatingValue;

                                                let currentValue: any = '';
                                                let Val1: any = '';
                                                let Val2: any = '';
                                                if (thermalsetting < (100).toString()) {
                                                    this.showLable = true;
                                                } else {
                                                    this.showLable = false;
                                                }
                                                if (!Number.isNaN(Number(thermalsetting)) && thermalsetting.toString().indexOf('.') != -1) {
                                                    Val1 = parseFloat(thermalsetting);
                                                } else {
                                                    Val1 = parseInt(thermalsetting);
                                                }
                                                if (!Number.isNaN(Number(MultiplierValue)) && MultiplierValue.toString().indexOf('.') != -1) {
                                                    Val2 = parseFloat(MultiplierValue);
                                                } else {
                                                    Val2 = parseInt(MultiplierValue);
                                                }
                                                currentValue = Val1 * Val2;
                                                currentValue = currentValue.toFixed(2);
                                                $('input').filter("[caption='Trip current']").val(currentValue);
                                                this.CableSize['protection']['tripcurrent'] = currentValue;

                                                if (!this.isCableCalValue) {
                                                    this.updatecablesizeinarray();
                                                    this.getAllControlsValue();
                                                }
                                                //this.ProtectionCalcForCustomRating();
                                            }
                                        }, 100);
                                    }
                                    if (result.uiControlsLookupDto[i].controles[j].fCaption === 'Description') {
                                        this.CableSize['protection']['description'] = result.uiControlsLookupDto[i].controles[j].fDefaultValue;
                                    }
                                }
                            }
                        }
                    }
                    let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));

                    //this.tempcablesizelist = JSON.parse(this.tempinputXML.CableSizeCalcInputs);

                    this.tempcablesizelist.forEach(
                        itemnode => {
                            if (itemnode.Node_Id == selectednode.id || itemnode.Node_Prefix == selectednode.objectcode) {
                                itemnode.CableSizeCalcInputXML['protection'] = this.CableSize['protection'];

                            }
                        });

                    this.tempinputXML.CableSizeCalcInputs = JSON.stringify(this.tempcablesizelist);
                    this.onInputChange(isFromTreeNode);
                }
            }

            //this.loadProtectionCalculation();
            if (!this.isNewNodeAdded) {
                if (mode === 'phase' || mode === 'core' || mode === 'conductor' || mode === 'insulation' || mode === 'armour') {

                    if (!this.isInstallationStatus) {
                        this.updatecablesizeinarray();
                        this.getAllControlsValue(isFromTreeNode);
                    }
                }
                else {
                    if (!this.isInputAPIStatus) {
                        this.updatecablesizeinarray();
                        this.getAllControlsValue(isFromTreeNode);
                    }
                }
            }
            //this.hideLoader();;
        },
            error => {
                this.options.allowDrag = true;
                this.options.allowDrop = true;
                this.addRemoveNode = false;
                this.hideLoader();;
            });

    }
    /* New Work regarding wizard saving values */
    UpdateWizardOnInstallationChange(methodIndex: any, tableId: any, name: string, description: string, voltagedropTable: string, currentTable: string, callby: string) {

        if (callby == 'mainpage') {
            this.selectInstallTableMethodIndex = methodIndex;
            this.selectInstallTableTableId = tableId;
            this.selectInstallTableName = name;
            this.selectInstallTableDescription = description;
            this.selectInstallTableVoltagedropTable = voltagedropTable;
            this.selectInstallTableCurrentTable = currentTable;
        }
        var mainInstallationType = 0;

        let newImageName = (name + ' ' + description).toLowerCase().trim();

        if (newImageName.search('ground') == -1)
            mainInstallationType = 1;
        else
            mainInstallationType = 2;

        if (this.wizardFactorOutput.length > 0) {
            let wizardInstallationtype = this.wizardFactorOutput[0].installationType;
            if (this.newwizardInstallationtype == 0)
                this.newwizardInstallationtype = this.wizardFactorOutput[0].installationType;

            if (this.newwizardInstallationtype != mainInstallationType) {
                this.newwizardInstallationtype = mainInstallationType;
                if (callby == 'mainpage')
                    this.onSelectInstallTable();
                this.UserWizard = false;
                this.CableSize["isderatingwiz"] = "0";
                $('.chkUW').prop('checked', false);

                this.onResetControlValues();
                this.resetWizardFactorValue();
            }
            else {
                if (callby == 'mainpage')
                    this.onSelectInstallTable();
                if (callby == 'getdata')
                    this.resetWizardFactorValue();
            }
        }
        else {
            if (callby == 'mainpage')
                this.onSelectInstallTable();
            if (callby == 'getdata')
                this.resetWizardFactorValue();
            this.onResetControlValues();
            this.setWizardFactorValues();
        }
    }

    //INUSE Cable size calculation 
    ProtectionCalcDropdown(e: any, elem: any) {


        this._calculationService.getPrevValue().subscribe((result) => {

            let prevRatingValue = result;
            const MultiplierValue = $('input').filter("[caption='Trip Multiplier']").val().toString();

            let RatingValue: any = '';
            if (e !== '') {
                RatingValue = e.target.options[e.target.selectedIndex].innerHTML.split(" ")[1];
                if (RatingValue < 100) {
                    this.showLable = true;
                } else {
                    this.showLable = false;
                }
            }
            if (elem !== '') {
                RatingValue = elem.innerHTML.split(" ")[1];
                if (RatingValue < 100) {
                    this.showLable = true;
                } else {
                    this.showLable = false;
                }
            }
            if (prevRatingValue == null || prevRatingValue == undefined || prevRatingValue == '') {
                prevRatingValue = RatingValue;
                this._calculationService.setPrevValue(RatingValue).subscribe((result) => { }, error => { });
            }

            if (prevRatingValue != RatingValue) {
                this._calculationService.setPrevValue(RatingValue).subscribe((result) => {
                    if ($('input').filter("[caption='Thermal setting']").length) {
                        $('input').filter("[caption='Thermal setting']").val(RatingValue);
                        this.CableSize['protection']['thermalsetting'] = RatingValue;
                    }

                    let currentValue: any = '';
                    let Val1: any = '';
                    let Val2: any = '';
                    if (!isNaN(RatingValue) && RatingValue.toString().indexOf('.') != -1) {
                        Val1 = parseFloat(RatingValue);
                    } else {
                        Val1 = parseInt(RatingValue);
                    }
                    if (!Number.isNaN(Number(MultiplierValue)) && MultiplierValue.toString().indexOf('.') != -1) {
                        Val2 = parseFloat(MultiplierValue);
                    } else {
                        Val2 = parseInt(MultiplierValue);
                    }
                    currentValue = Val1 * Val2;
                    currentValue = currentValue.toFixed(2);
                    if ($('input').filter("[caption='Trip current']").length) {
                        $('input').filter("[caption='Trip current']").val(currentValue);
                        this.CableSize['protection']['tripcurrent'] = currentValue;
                    }

                    if (!this.isCableCalValue) {

                        if (this.isCableProtectionValue == true) {
                            this.isCableSizeCalcStatus = false;
                        }
                        this.updatecablesizeinarray();
                        this.getAllControlsValue();
                    }
                });
            }

        });
    }


    //INUSE Cable size calculation 
    ProtectionCalcForCustomRating() {

        const MultiplierValue = $('input').filter("[caption='Trip Multiplier']").val().toString();
        let RatingValue = $('input').filter("[caption='Custom Rating']").val().toString();
        // let thermalSettingValue = this.CableSize['protection']['thermalsetting'];
        // if (RatingValue == thermalSettingValue) {
        $('input').filter("[caption='Thermal setting']").val(RatingValue);
        $('input[caption="Thermal setting"]').attr('max', RatingValue);
        this.CableSize['protection']['thermalsetting'] = RatingValue;
        // }
        // else {
        //     RatingValue = thermalSettingValue;
        // }
        let currentValue: any = '';
        let Val1: any = '';
        let Val2: any = '';
        if (RatingValue < (100).toString()) {
            this.showLable = true;
        } else {
            this.showLable = false;
        }
        if (!Number.isNaN(Number(RatingValue)) && RatingValue.toString().indexOf('.') != -1) {
            Val1 = parseFloat(RatingValue);
        } else {
            Val1 = parseInt(RatingValue);
        }
        if (!Number.isNaN(Number(MultiplierValue)) && MultiplierValue.toString().indexOf('.') != -1) {
            Val2 = parseFloat(MultiplierValue);
        } else {
            Val2 = parseInt(MultiplierValue);
        }
        currentValue = Val1 * Val2;
        currentValue = currentValue.toFixed(2);
        $('input').filter("[caption='Trip current']").val(currentValue);
        this.CableSize['protection']['tripcurrent'] = currentValue;

        if (!this.isCableCalValue) {
            this.updatecablesizeinarray();
            this.getAllControlsValue();
        }
    }


    //INUSE Cable size calculation 
    specifyConductorChange() {

        $('#active-toggle').change((e) => {
            //
            if ($(e.target).is(':checked') === true) {
                //
                if (this.specifyConductorData && this.specifyConductorData.length > 0) {
                    let firstConductorData = this.specifyConductorData[0];
                    this.CableproInput.ActiveId = firstConductorData.id;
                }
                else
                    this.CableproInput.ActiveId = 100;
                this.PrevActiveValue = undefined;
                this.isCableSizeCalcStatus = false;
                this.isInputAPIStatus = false;
                this.ShowActive = true;
                // $('#Active').val(this.CableproInput.ActiveId).trigger('change', 'true');
                $('#Active_c').removeAttr('disabled');
                this.showActiveLab = false;
                this.CableSize['specifyconductor']['phase']['mode'] = '1';
                this.CableSize['specifyconductor']['phase']['size'] = this.PrevActiveValue !== undefined ? parseFloat(this.PrevActiveValue.replace(' mm²', '')) : 1;
                this.CableSize['specifyconductor']['phase']['circuits'] = '1';

                this.updatecablesizeinarray();
                this.getAllControlsValue();
            } else {
                //alert('active toggle button 4_Active ID:-  ' + this.CableproInput.ActiveId);
                this.CableproInput.ActiveId = 100;
                // $('#Active').val(this.CableproInput.ActiveId).trigger('change', 'true');
                this.isCableSizeCalcStatus = false;
                this.isInputAPIStatus = false;
                this.ShowActive = false;
                $('#Active_c').attr('disabled', 'true');
                this.showActiveLab = true;
                this.CableSize['specifyconductor']['phase']['mode'] = '0';
                this.CableSize['specifyconductor']['phase']['size'] = '25';
                this.CableSize['specifyconductor']['phase']['circuits'] = '1';
                this.updatecablesizeinarray();
                this.getAllControlsValue();
            }
        });
        $('#neutral-toggle').change((e) => {
            if ($(e.target).is(':checked') === true) {
                this.CableproInput.NeutralId = 100;
                this.ShowNeutral = true;
                this.PrevNeutralValue = undefined;
                this.isCableSizeCalcStatus = false;
                this.isInputAPIStatus = false;
                $('#Neutral_c').removeAttr('disabled');
                this.ShowNeutralLab = false;
                this.CableSize['specifyconductor']['neutral']['mode'] = '1';
                // let index = ($('#Neutral')[0] as HTMLSelectElement).options.selectedIndex;

                this.CableSize['specifyconductor']['neutral']['size'] = this.PrevNeutralValue !== undefined ? parseFloat(this.PrevNeutralValue.replace(' mm²', '')) : 1;
                this.CableSize['specifyconductor']['neutral']['circuits'] = '1';

                this.updatecablesizeinarray();
                this.getAllControlsValue();
            }
            else {
                this.CableproInput.NeutralId = 100;
                this.isCableSizeCalcStatus = false;
                this.isInputAPIStatus = false;
                this.ShowNeutral = false;
                $('#Neutral_c').attr('disabled', 'true');
                this.ShowNeutralLab = true;
                this.CableSize['specifyconductor']['neutral']['mode'] = '0';
                this.CableSize['specifyconductor']['neutral']['size'] = '25';
                this.CableSize['specifyconductor']['neutral']['circuits'] = '1';
                this.updatecablesizeinarray();
                this.getAllControlsValue();
            }
        });
        $('#earth-toggle').change((e) => {
            if ($(e.target).is(':checked') === true) {
                this.ShowEarth = true;
                this.isCableSizeCalcStatus = false;
                this.isInputAPIStatus = false;
                $('#Earth_c').removeAttr('disabled');
                $('#Earth_c').val('1').trigger('change', 'true');
                this.ShowEarthLab = false;
                this.earth_NoOf_circuit = 1;
                this.CableSize['specifyconductor']['earth']['mode'] = '2';
                this.CableSize['specifyconductor']['earth']['size'] = '1';
                // let index = ($('#Earth')[0] as HTMLSelectElement).options.selectedIndex;
                // if (index !== -1)
                //     this.CableSize['specifyconductor']['earth']['size'] = ($('#Earth')[0] as HTMLSelectElement).options[index].innerHTML.split(" ")[0];
                this.CableSize['specifyconductor']['earth']['circuits'] = '1';

                this.updatecablesizeinarray();
                this.getAllControlsValue();
            }
            else {
                this.ShowEarth = false;
                this.isCableSizeCalcStatus = false;
                this.isInputAPIStatus = false;
                this.CableproInput.EarthId = "1";

                // $('#Earth').val('1').trigger('change', 'true');
                $('#Earth_c').attr('disabled', 'true');
                this.ShowEarthLab = true;
                this.CableSize['specifyconductor']['earth']['mode'] = '1';
                this.CableSize['specifyconductor']['earth']['size'] = '25';
                this.CableSize['specifyconductor']['earth']['circuits'] = '1';
                this.updatecablesizeinarray();
                this.getAllControlsValue();

            }
        });
    }

    //INUSE Cable size calculation 
    onDropDownChange() {
        $(document).off('change', '#select_HRCFuse');

        $(document).on('change', '#select_HRCFuse', (e: any) => {
            const caption: string = e.target.options[e.target.selectedIndex].getAttribute('caption');
            if (caption === 'Rating') {
                this.CableSize['protection']['HRCFuserating'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevHRCVal = e.target.selectedIndex;
                this.isCableCalValue = false;
                this.ProtectionCalcDropdown(e, '');
                //  this.getAllControlsValue();

            } else if (caption === 'Curve') {
                this.CableSize['protection']['curve'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevCurveVal = e.target.selectedIndex;
                this.updatecablesizeinarray();
                this.getAllControlsValue();
            }
            //this.getAllControlsValue();
        });
        //  $('#select_MCB').unbind('change');
        $(document).off('change', '#select_MCB');
        $(document).on('change', '#select_MCB', (e: any) => {
            const caption: string = e.target.options[e.target.selectedIndex].getAttribute('caption');
            if (caption === 'Rating') {
                this.CableSize['protection']['MCBrating'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevMCBVal = e.target.selectedIndex;
                this.isCableCalValue = false;
                this.ProtectionCalcDropdown(e, '');
                //  this.getAllControlsValue();
            } else if (caption === 'Curve') {
                this.CableSize['protection']['curve'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevCurveVal = e.target.selectedIndex;
                this.updatecablesizeinarray();
                this.getAllControlsValue();
            }
            //this.getAllControlsValue();
        });
        $(document).off('change', '#select_MCCB');

        $(document).on('change', '#select_MCCB', (e: any) => {

            const caption: string = e.target.options[e.target.selectedIndex].getAttribute('caption');
            if (caption === 'Rating') {

                this.CableSize['protection']['MCCBrating'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.tempRating = e.target.options[e.target.selectedIndex].innerText.split(" ")[1];
                $('input[caption="Thermal setting"]').attr('max', this.tempRating);
                this.CableSize['protection']['thermalsetting'] = this.tempRating;
                this.PrevMCCBVal = e.target.selectedIndex;
                this.isCableCalValue = false;

                this.ProtectionCalcDropdown(e, '');
                // this.getAllControlsValue();

            } else if (caption === 'Curve') {
                this.CableSize['protection']['curve'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevCurveVal = e.target.selectedIndex;
                this.updatecablesizeinarray();
                this.getAllControlsValue();
            }
            //this.getAllControlsValue();
        });
        $(document).off('change', '#select_ACB');

        $(document).on('change', '#select_ACB', (e: any) => {
            const caption: string = e.target.options[e.target.selectedIndex].getAttribute('caption');
            if (caption === 'Rating') {
                this.tempRating = e.target.options[e.target.selectedIndex].innerText.split(" ")[1];
                $('input[caption="Thermal setting"]').attr('max', this.tempRating);
                this.CableSize['protection']['ACBrating'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevACBVal = e.target.selectedIndex;
                this.CableSize['protection']['thermalsetting'] = this.tempRating;
                this.isCableCalValue = false;
                this.ProtectionCalcDropdown(e, '');
                // this.getAllControlsValue();
            } else if (caption === 'Curve') {
                this.CableSize['protection']['curve'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevCurveVal = e.target.selectedIndex;
                this.updatecablesizeinarray();
                this.getAllControlsValue();
            }
            //this.getAllControlsValue();
        });
        $(document).off('change', '#select_Curve');

        $(document).on('change', '#select_Curve', (e: any, isTriggered) => {
            const caption: string = e.target.options[e.target.selectedIndex].getAttribute('caption');
            if (caption === 'Curve') {
                this.CableSize['protection']['curve'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.PrevCurveVal = e.target.selectedIndex;

                if (this.CableSize['protection']['curve'] === '0') {
                    if (isTriggered != 'false')
                        $('input[caption="Trip Multiplier"]').val('4');
                    let trip = $('input[caption="Trip Multiplier"]').val().toString();
                    let rat: any = $('#select_MCB option:selected')[0].innerText.split(" ")[1];
                    if (!isNaN(rat) && rat.toString().indexOf('.') != -1) {
                        rat = parseFloat(rat);
                    } else {
                        rat = parseInt(rat);
                    }
                    let count = parseInt(trip) * rat;
                    $('input').filter("[caption='Trip current']").val(count);
                    this.CableSize['protection']['tripcurrent'] = count;
                    this.CableSize['protection']['tripmultiplier'] = trip;
                } else if (this.CableSize['protection']['curve'] === '1') {

                    if (isTriggered != 'false')
                        $('input[caption="Trip Multiplier"]').val('7.5');
                    let trip = $('input[caption="Trip Multiplier"]').val().toString();
                    let rat: any = $('#select_MCB option:selected')[0].innerText.split(" ")[1];
                    if (!isNaN(rat) && rat.toString().indexOf('.') != -1) {
                        rat = parseFloat(rat);
                    } else {
                        rat = parseInt(rat);
                    }
                    let count = parseFloat(trip) * rat;
                    $('input').filter("[caption='Trip current']").val(count);
                    this.CableSize['protection']['tripcurrent'] = count;
                    this.CableSize['protection']['tripmultiplier'] = trip;
                } else {

                    if (isTriggered != 'false')
                        $('input[caption="Trip Multiplier"]').val('15');
                    let trip = $('input[caption="Trip Multiplier"]').val().toString();
                    let rat: any = $('#select_MCB option:selected')[0].innerText.split(" ")[1];
                    if (!isNaN(rat) && rat.toString().indexOf('.') != -1) {
                        rat = parseFloat(rat);
                    } else {
                        rat = parseInt(rat);
                    }
                    let count = parseInt(trip) * rat;
                    $('input').filter("[caption='Trip current']").val(count);
                    this.CableSize['protection']['tripcurrent'] = count;
                    this.CableSize['protection']['tripmultiplier'] = trip;
                }
            }
            this.updatecablesizeinarray();
            this.getAllControlsValue();
        });
        $(document).off('change', '#select_Earthfaulttime');

        $(document).on('change', '#select_Earthfaulttime', (e: any) => {
            const caption: string = e.target.options[e.target.selectedIndex].getAttribute('caption');
            if (caption === 'Earth fault time') {
                this.CableSize['protection']['earthfaulttime'] = e.target.options[e.target.selectedIndex].getAttribute('xmlvalue');
                this.updatecablesizeinarray();
                this.getAllControlsValue();
            }
        });
    }

    //INUSE Cable size calculation 
    onCheckBoxChange() {
        $(document).off('change', '.chkGroup input:checkbox');
        $(document).on('change', '.chkGroup input:checkbox', (e: any) => {
            if ($(e.target).prop('checked') == true) {
                this.CableSize['protection']['rcd'] = '1';
            } else {
                this.CableSize['protection']['rcd'] = '0';
            }
            this.updatecablesizeinarray();
            this.getAllControlsValue();
        });
    }

    //INUSE Cable size calculation 
    onNoEarthChange() {
        $('#earth-chk').on('change', (e: any) => {

            if ($(e.target).is(':checked') === true) {
                this.CableSize['specifyconductor']['earth']['mode'] = '0';
                this.CableSize['specifyconductor']['earth']['size'] = '0'
                this.CableSize['specifyconductor']['earth']['circuits'] = '0';
                $('#earth-toggle').attr('disabled', 'true');
                this.ShowEarth = false;
                $('#Earth_c').attr('disabled', 'true');
                $('#Earth_c').val(1);
                this.earth_NoOf_circuit = 1;
                this.ShowEarthLab = true;
                this.showEarthButton = false;
            } else {
                $('#earth-toggle').removeAttr('disabled');
                this.showEarthButton = true;
                if ($('#earth-toggle').is(':checked') === true) {
                    this.ShowEarth = true;
                    $('#Earth_c').removeAttr('disabled');
                    this.ShowEarthLab = false;
                    this.CableSize['specifyconductor']['earth']['mode'] = '2';
                    let index = ($('#Earth')[0] as HTMLSelectElement).options.selectedIndex;
                    // this.CableSize['specifyconductor']['earth']['size'] = ($('#Earth')[0] as HTMLSelectElement).options[index].innerHTML.split(" ")[0];
                    let obj = this.earthList.find(m => m.id == this.CableproInput.EarthId);
                    if (obj)
                        this.CableSize['specifyconductor']['earth']['size'] = obj.text.split(" ")[0];
                    this.CableSize['specifyconductor']['earth']['circuits'] = '1';
                } else {
                    this.ShowEarth = false;
                    $('#Earth_c').attr('disabled', 'true');
                    $('#Earth_c').val(1);
                    this.ShowEarthLab = true;
                    this.CableSize['specifyconductor']['earth']['mode'] = '1';
                    this.CableSize['specifyconductor']['earth']['circuits'] = '1';
                }
            }
            this.updatecablesizeinarray();
            this.onInputChange();
        });
    }

    //#endregion

    toggleVisibility(e) {
        alert(e.target.checked);
    }
    onCustomWcInputChange() {
        //alert('F');

        if ($("#TransformerHVRelay").is(":checked")) {
            // this.cablePullingData.formation.customwc = true;
            // this.isCustomWcChecked = true;
        }
        else {
            // this.cablePullingData.formation.customwc = false;
            // this.isCustomWcChecked = false;
        }
        // this.onInputChange();
    }
    selectnode(event, itemselected) {
        this.selnode = itemselected;
        event.target.css("background-color", "#f6f6f6");
    }
    openModal() {

    }
    //INUSE
    updatename(arr, type, txt) {
        for (var k in arr) {
            if (typeof arr[k] == "object" && arr[k] !== null) {

                this.updatename(arr[k], type, txt)
                // childind=childind+1;
            }
            else {


                if (k == 'objecttype') {
                    if (arr[k] == type) {
                        arr['name'] = txt;
                    }
                }
            }
        }

        this.items = arr;
    }

    //INUSE
    updatenamebyId(arr, type, txt, objectcode) {
        for (var k in arr) {
            if (typeof arr[k] == "object" && arr[k] !== null) {

                this.updatenamebyId(arr[k], type, txt, objectcode)
                // childind=childind+1;
            }
            else {


                if (k == 'objecttype') {
                    if (arr[k] == type && arr['objectcode'] == objectcode) {
                        arr['name'] = txt;
                    }
                }
            }
        }

        this.items = arr;
    }

    updateparentidandchildindexbyId(arr, nodeid, parentidtoupdate, childindextoupdate) {
        for (var k in arr) {
            if (typeof arr[k] == "object" && arr[k] !== null) {

                this.updateparentidandchildindexbyId(arr[k], nodeid, parentidtoupdate, childindextoupdate);
                // childind=childind+1;
            }
            else {


                if (k == 'id') {
                    if (arr[k] == nodeid) {
                        arr['parentid'] = parentidtoupdate;
                        arr['childindex'] = childindextoupdate;
                    }
                }
            }
        }

        this.items = arr;
    }
    getParentId(arr, nodeid) {
        for (var k in arr) {
            if (typeof arr[k] == "object" && arr[k] !== null) {

                this.getParentId(arr[k], nodeid);
            }
            else {


                if (k == 'objectcode') {
                    if (arr[k] == nodeid) {
                        this.secondParentNodeId = arr['parentid'];
                    }
                }
            }
        }


    }


    getmaxcountbytype(arr, type) {
        for (var k in arr) {
            if (typeof arr[k] == "object" && arr[k] !== null) {

                this.getmaxcountbytype(arr[k], type)
                // childind=childind+1;
            }
            else {
                if (k == 'objecttype') {
                    if (arr[k] == type) {
                        this.maxcount = this.maxcount + 1;
                    }
                }
            }
        }
    }

    //INUSE
    getload(nodeitem: any, nodeitemid: number, node_Prefix, isFromTreeNode: boolean = false) {

        let nodeid = nodeitemid != null ? nodeitemid : nodeitem.id;

        // let tempnode =this.templist.find(tempnode => tempnode.Load_Id == nodeitem.id );
        let tempnode = this.tempinputXML.Loads.find(tempnode => tempnode.Load_Id == nodeid);

        // nodeitem.name=tempnode.Load_Name;
        $('#Loadname').val(tempnode.Load_Name);


        if (tempnode.Load_Phases == 0) {
            $('#radio3Phase').prop('checked', true);
            $('#loadthreephase').css('display', 'block');
            $('#loadsinglephase').css('display', 'none');
            $('#loadradiocurrents').css('display', 'block');
        }
        else if (tempnode.Load_Phases == 1) {
            $('#radio1Phase').prop('checked', true);
            $('#loadsinglephase').css('display', 'block');
            $('#loadthreephase').css('display', 'none');
            $('#loadradiocurrents').css('display', 'none');
        }
        else {
            $('#radio3Phase').prop('checked', true);
            $('#loadthreephase').css('display', 'block');
            $('#loadsinglephase').css('display', 'none');
        }


        $('#Phase1Phase').val((tempnode.Load_Phase_1_Phase == undefined ? 0 : tempnode.Load_Phase_1_Phase).toString()).trigger('change', 'true');

        $('#Phase1Current').val(tempnode.Load_Phase_1_Current == undefined ? 0 : tempnode.Load_Phase_1_Current);
        $('#Phase1Powerfactor').val(tempnode.Load_Phase_1_Powerfactor == undefined ? 0.9 : tempnode.Load_Phase_1_Powerfactor);
        $('#Phase1Harmonics').val(tempnode.Load_Phase_1_Harmonics == undefined ? 0 : tempnode.Load_Phase_1_Harmonics);


        $('#Phase3PhaseCurrent').val(tempnode.Load_Phase_3_Phase_Current == undefined ? 100 : tempnode.Load_Phase_3_Phase_Current);
        $('#Phase3Powerfactor').val(tempnode.Load_Phase_3_Powerfactor == undefined ? 0.9 : tempnode.Load_Phase_3_Powerfactor);
        $('#Phase3HarmonicsBal').val(tempnode.Load_Phase_3_Harmonics_Balanced == undefined ? 0 : tempnode.Load_Phase_3_Harmonics_Balanced);
        $('#Phase3HarmonicsUnBal').val(tempnode.Load_Phase_3_Harmonics_UnBalanced == undefined ? 0 : tempnode.Load_Phase_3_Harmonics_UnBalanced);


        if (tempnode.Load_Phase_3_Currents == 0) {
            $('#balcurrent').prop('checked', true);

            $('#loadbalgroup').css('display', 'block');
            $('#loadunbalgroup').css('display', 'none');
        }
        else if (tempnode.Load_Phase_3_Currents == 1) {
            $('#unbalcurrent').prop('checked', true);

            $('#loadunbalgroup').css('display', 'block');
            $('#loadbalgroup').css('display', 'none');
        }

        $('#Phase3PhaseCurrentL1').val(tempnode.Load_Phase_3_Phase_Current_L1 == undefined ? 100 : tempnode.Load_Phase_3_Phase_Current_L1);
        $('#Phase3PhaseCurrentL2').val(tempnode.Load_Phase_3_Phase_Current_L2 == undefined ? 100 : tempnode.Load_Phase_3_Phase_Current_L2);
        $('#Phase3PhaseCurrentL3').val(tempnode.Load_Phase_3_Phase_Current_L3 == undefined ? 100 : tempnode.Load_Phase_3_Phase_Current_L3);

        $('#Phase3PowerfactorL1').val(tempnode.Load_Phase_3_Powerfactor_L1 == undefined ? 100 : tempnode.Load_Phase_3_Powerfactor_L1);
        $('#Phase3PowerfactorL2').val(tempnode.Load_Phase_3_Powerfactor_L2 == undefined ? 100 : tempnode.Load_Phase_3_Powerfactor_L2);
        $('#Phase3PowerfactorL3').val(tempnode.Load_Phase_3_Powerfactor_L3 == undefined ? 100 : tempnode.Load_Phase_3_Powerfactor_L3);

        this.loadStatus = false;
        this.getcablesize(nodeitemid, node_Prefix, isFromTreeNode);

    }

    //INUSE
    addnewload(tempobjectId) {
        this.isCableSizeCalcStatus = true;
        let count = parseInt(sessionStorage.getItem("loadlist"));

        if (this.tempinputXML.Loads != undefined)
            this.templist = this.tempinputXML.Loads;
        else if (this.tempinputXML.Loads == undefined)
            this.templist.length = 0;


        let tempload = new NetworkLoad();

        let loadprefix =
            'L' + (count + 1).toString();

        $('#Loadname').val("Load" + count);

        tempload.Load_Id = tempobjectId;
        tempload.Load_Name = $('#Loadname').val().toString();
        tempload.Load_Matrix_Node = loadprefix.toString();
        tempload.Load_Phases = 0;
        if (tempload.Load_Phases == 0) {
            $('#radio3Phase').prop('checked', true);
            $('#radio1Phase').prop('checked', false);
            $('#loadthreephase').css('display', 'block');
            $('#loadsinglephase').css('display', 'none');
            $('#loadradiocurrents').css('display', 'block');
        }

        tempload.Load_Phase_1_Current = 100;
        tempload.Load_Phase_1_Phase = 0;
        tempload.Load_Phase_1_Powerfactor = 0.9;
        tempload.Load_Phase_1_Harmonics = 0;

        tempload.Load_Phase_3_Phase_Current = 100;
        tempload.Load_Phase_3_Currents = 0;
        tempload.Load_Phase_3_Powerfactor = 0.9;
        tempload.Load_Phase_3_Harmonics_Balanced = 0;

        tempload.Load_Phase_3_Phase_Current_L1 = 100;
        tempload.Load_Phase_3_Phase_Current_L2 = 100;
        tempload.Load_Phase_3_Phase_Current_L3 = 100;

        tempload.Load_Phase_3_Powerfactor_L1 = 0.9;
        tempload.Load_Phase_3_Powerfactor_L2 = 0.9;
        tempload.Load_Phase_3_Powerfactor_L3 = 0.9;

        if (tempload.Load_Phase_3_Currents == 0) {
            $('#balcurrent').prop('checked', true);
            $('#unbalcurrent').prop('checked', false);
            $('#loadbalgroup').css('display', 'block');
            $('#loadunbalgroup').css('display', 'none');
        }



        this.templist.push(tempload);
        this.tempinputXML.Loads = [];

        this.tempinputXML.Loads = this.templist;

        this.addcablesize(tempload, 'Load');
        this.getload([], tempobjectId, tempload.Load_Matrix_Node);

        this.onInputChange();
        sessionStorage.setItem("loadlist", (count + 1).toString());

    }
    //INUSE
    updateloaddata(event: any, controlname?: string) {

        let nodeid = (JSON.parse(sessionStorage.getItem("currentnode"))).id;

        this.tempinputXML.Loads.forEach(
            itemnode => {
                if (itemnode.Load_Id == nodeid) {


                    if (controlname == 'loadname') {
                        itemnode.Load_Name = $('.loadname').val().toString();
                    }
                    if (controlname == 'phasesradio') {
                        itemnode.Load_Phases = event.target.id == 'radio3Phase' ? 0 : 1;
                    }
                    if (controlname == 'Phase1Current') {
                        itemnode.Load_Phase_1_Current = parseFloat($("#Phase1Current").val().toString());
                    }

                    if (controlname == 'Phase1Phase') {

                        itemnode.Load_Phase_1_Phase = parseInt($("#Phase1Phase").val().toString());
                    }
                    if (controlname == 'Phase1Powerfactor') {
                        itemnode.Load_Phase_1_Powerfactor = parseFloat($("#Phase1Powerfactor").val().toString());
                    }
                    if (controlname == 'Phase1Harmonics') {
                        itemnode.Load_Phase_1_Harmonics = parseFloat($("#Phase1Harmonics").val().toString());
                    }


                    if (controlname == 'Phase3PhaseCurrent') {
                        itemnode.Load_Phase_3_Phase_Current = parseFloat($("#Phase3PhaseCurrent").val().toString());
                    }
                    if (controlname == 'Phase3Powerfactor') {
                        itemnode.Load_Phase_3_Powerfactor = parseFloat($("#Phase3Powerfactor").val().toString());
                    }
                    if (controlname == 'Phase3HarmonicsBal') {
                        itemnode.Load_Phase_3_Harmonics_Balanced = parseFloat($("#Phase3HarmonicsBal").val().toString());
                    }

                    if (controlname == 'unbalcurrent' || controlname == 'balcurrent') {
                        itemnode.Load_Phase_3_Currents = event.target.id == 'balcurrent' ? 0 : 1;
                        // $("#unbalcurrent").val();
                    }


                    if (controlname == 'Phase3PhaseCurrentL1') {
                        itemnode.Load_Phase_3_Phase_Current_L1 = parseFloat($("#Phase3PhaseCurrentL1").val().toString());
                    }
                    if (controlname == 'Phase3PhaseCurrentL2') {
                        itemnode.Load_Phase_3_Phase_Current_L2 = parseFloat($("#Phase3PhaseCurrentL2").val().toString());
                    }
                    if (controlname == 'Phase3PhaseCurrentL3') {
                        itemnode.Load_Phase_3_Phase_Current_L3 = parseFloat($("#Phase3PhaseCurrentL3").val().toString());
                    }


                    if (controlname == 'Phase3PowerfactorL1') {
                        itemnode.Load_Phase_3_Powerfactor_L1 = parseFloat($("#Phase3PowerfactorL1").val().toString());
                    }
                    if (controlname == 'Phase3PowerfactorL2') {
                        itemnode.Load_Phase_3_Powerfactor_L2 = parseFloat($("#Phase3PowerfactorL2").val().toString());
                    }
                    if (controlname == 'Phase3PowerfactorL3') {
                        itemnode.Load_Phase_3_Powerfactor_L3 = parseFloat($("#Phase3PowerfactorL3").val().toString());
                    }
                    if (controlname == 'Phase3HarmonicsUnBal') {
                        itemnode.Load_Phase_3_Harmonics_UnBalanced = parseFloat($("#Phase3HarmonicsUnBal").val().toString());
                    }

                }
            }
        );
        if (!this.loadStatus) {
            this.onInputChange();
        }
    }

    //INUSE
    getswitchboard(nodeitem: any, isFromTreeNode: boolean = false) {
        // let tempnode =this.tempswitchlist.find(tempnode => tempnode.SwitchBoard_Id == nodeitem.id );
        let tempnode = this.tempinputXML.Switchboards.find(tempnode => tempnode.SwitchBoard_Id == nodeitem.id);

        // nodeitem.name=tempnode.SwitchBoard_Name;
        $('.sbname').val(tempnode.SwitchBoard_Name);

        if (tempnode.SwitchBoard_Phases == 0) {
            $('#switchradio3phase').prop('checked', true);
            $('#threephasegroup').css('display', 'block');
            $('#singlephasegroup').css('display', 'none');
        }
        else if (tempnode.SwitchBoard_Phases == 1) {
            $('#switchradio1phase').prop('checked', true);

            $('#singlephasegroup').css('display', 'block');
            $('#threephasegroup').css('display', 'none');
        }
        $('#switchvoltagedrop').val(tempnode.SwitchBoard_Allowable_Voltage_Drop);
        $("#SwitchBoard_Total_Maximum_Demand").val(tempnode.SwitchBoard_Total_Maximum_Demand).trigger('change', "true");
        $("#SwitchBoard_Phase_1_Phase").val(tempnode.SwitchBoard_Phase_1_Phase).trigger('change', "true");

        //    $('#SwitchBoard_Total_Maximum_Demand').val(tempnode.SwitchBoard_Total_Maximum_Demand);

        //EVENT triggering
        // (<any>$('#SwitchBoard_Total_Maximum_Demand')).select2('val', (tempnode.SwitchBoard_Total_Maximum_Demand == undefined ? 0 : tempnode.SwitchBoard_Total_Maximum_Demand).toString());

        $('#SwitchBoard_Phase_1_Current').val(tempnode.SwitchBoard_Phase_1_Current);
        // (<any>$('#SwitchBoard_Phase_1_Phase')).select2('val', (tempnode.SwitchBoard_Phase_1_Phase == undefined ? 0 : tempnode.SwitchBoard_Phase_1_Phase).toString());
        $('#SwitchBoard_Phase_1_Power_factor').val(tempnode.SwitchBoard_Phase_1_Power_factor);

        if (tempnode.SwitchBoard_Phase_3_Currents == 0) {
            $('#balradio').prop('checked', true);

            $('#switchbalgroup').css('display', 'block');
            $('#switchunbalgroup').css('display', 'none');
        }
        else if (tempnode.SwitchBoard_Phase_3_Currents == 1) {
            $('#unbalradio').prop('checked', true);

            $('#switchunbalgroup').css('display', 'block');
            $('#switchbalgroup').css('display', 'none');
        }



        $('#SwitchBoard_Phase_3_Phase_Current').val(tempnode.SwitchBoard_Phase_3_Phase_Current);
        $('#SwitchBoard_Phase_3_Phase_Power_factor').val(tempnode.SwitchBoard_Phase_3_Phase_Power_factor);
        $('#SwitchBoard_Phase_3_Phase_Current').val(tempnode.SwitchBoard_Phase_3_Phase_Current);

        $('#SwitchBoard_Phase_3_Phase_Power_factor_L1').val(tempnode.SwitchBoard_Phase_3_Phase_Power_factor_L1);
        $('#SwitchBoard_Phase_3_Phase_Power_factor_L2').val(tempnode.SwitchBoard_Phase_3_Phase_Power_factor_L2);
        $('#SwitchBoard_Phase_3_Phase_Power_factor_L3').val(tempnode.SwitchBoard_Phase_3_Phase_Power_factor_L3);

        $('#SwitchBoard_Phase_3_Phase_Current_L1').val(tempnode.SwitchBoard_Phase_3_Phase_Current_L1);
        $('#SwitchBoard_Phase_3_Phase_Current_L2').val(tempnode.SwitchBoard_Phase_3_Phase_Current_L2);
        $('#SwitchBoard_Phase_3_Phase_Current_L3').val(tempnode.SwitchBoard_Phase_3_Phase_Current_L3);

        //extra
        //this.onInputChange();
        this.getcablesize(tempnode.SwitchBoard_Id, tempnode.SwitchBoard_Matrix_Node, isFromTreeNode);

    }


    //INUSE
    addnewswitchboard(tempswitchId) {
        this.isCableSizeCalcStatus = true;
        this.isNewNodeAdded = true;
        // this.isCableCalValue = false;
        let count = parseInt(sessionStorage.getItem("switchlist"));

        $('.sbname').val("Switchboard" + count);
        let tempobj = new NetworkSwitchBoard();

        let switchprefix =
            'SB' + (count + 1).toString();

        if (tempswitchId == 2) { //MAIN SB
            switchprefix = "MSB01";
            $('.sbname').val("Main Switchboard");
        }


        tempobj.SwitchBoard_Id = tempswitchId;
        tempobj.SwitchBoard_Matrix_Node = switchprefix;
        tempobj.SwitchBoard_Name = $('.sbname').val().toString();
        tempobj.SwitchBoard_Phases = 0;

        if ($("#switchradio3phase").is(":checked")) {
            tempobj.SwitchBoard_Phases = 0;
        }
        else if ($("#switchradio1phase").is(":checked")) {
            tempobj.SwitchBoard_Phases = 1;
        }


        tempobj.SwitchBoard_Allowable_Voltage_Drop = 1.5;
        tempobj.SwitchBoard_Total_Maximum_Demand = 0;
        tempobj.SwitchBoard_Phase_1_Current = 100;
        tempobj.SwitchBoard_Phase_1_Phase = 0;
        tempobj.SwitchBoard_Phase_1_Power_factor = 0.9;

        tempobj.SwitchBoard_Phase_3_Currents = 0;//BAL/UNBAL
        //BAL
        tempobj.SwitchBoard_Phase_3_Phase_Current = 100;
        tempobj.SwitchBoard_Phase_3_Phase_Power_factor = 0.9;

        //UNBAL
        tempobj.SwitchBoard_Phase_3_Phase_Current_L1 = 100;
        tempobj.SwitchBoard_Phase_3_Phase_Current_L2 = 100;
        tempobj.SwitchBoard_Phase_3_Phase_Current_L3 = 100;
        tempobj.SwitchBoard_Phase_3_Phase_Power_factor_L1 = 0.9;
        tempobj.SwitchBoard_Phase_3_Phase_Power_factor_L2 = 0.9;
        tempobj.SwitchBoard_Phase_3_Phase_Power_factor_L3 = 0.9;

        if (tempswitchId != 2) {
            //Reset to default
            $('.sbname').val(tempobj.SwitchBoard_Name);
            tempobj.SwitchBoard_Phases = 0;
            tempobj.SwitchBoard_Phase_3_Currents
            if (tempobj.SwitchBoard_Phases == 0) {
                $('#switchradio3phase').prop('checked', true);
                $('#threephasegroup').css('display', 'block');
                $('#singlephasegroup').css('display', 'none');
            }
            $('#switchvoltagedrop').val(tempobj.SwitchBoard_Allowable_Voltage_Drop);
            $("#SwitchBoard_Total_Maximum_Demand").val(tempobj.SwitchBoard_Total_Maximum_Demand).trigger('change', "true");
            $("#SwitchBoard_Phase_1_Phase").val(tempobj.SwitchBoard_Phase_1_Phase).trigger('change', "true");

            $('#SwitchBoard_Phase_1_Current').val(tempobj.SwitchBoard_Phase_1_Current);

            $('#SwitchBoard_Phase_1_Power_factor').val(tempobj.SwitchBoard_Phase_1_Power_factor);

            if (tempobj.SwitchBoard_Phase_3_Currents == 0) {
                $('#balradio').prop('checked', true);

                $('#switchbalgroup').css('display', 'block');
                $('#switchunbalgroup').css('display', 'none');
            }
            $('#SwitchBoard_Phase_3_Phase_Current').val(tempobj.SwitchBoard_Phase_3_Phase_Current);
            $('#SwitchBoard_Phase_3_Phase_Power_factor').val(tempobj.SwitchBoard_Phase_3_Phase_Power_factor);
            $('#SwitchBoard_Phase_3_Phase_Current').val(tempobj.SwitchBoard_Phase_3_Phase_Current);

            $('#SwitchBoard_Phase_3_Phase_Power_factor_L1').val(tempobj.SwitchBoard_Phase_3_Phase_Power_factor_L1);
            $('#SwitchBoard_Phase_3_Phase_Power_factor_L2').val(tempobj.SwitchBoard_Phase_3_Phase_Power_factor_L2);
            $('#SwitchBoard_Phase_3_Phase_Power_factor_L3').val(tempobj.SwitchBoard_Phase_3_Phase_Power_factor_L3);

            $('#SwitchBoard_Phase_3_Phase_Current_L1').val(tempobj.SwitchBoard_Phase_3_Phase_Current_L1);
            $('#SwitchBoard_Phase_3_Phase_Current_L2').val(tempobj.SwitchBoard_Phase_3_Phase_Current_L2);
            $('#SwitchBoard_Phase_3_Phase_Current_L3').val(tempobj.SwitchBoard_Phase_3_Phase_Current_L3);

        }

        sessionStorage.removeItem("SwitchboardResults");
        this.tempswitchlist.push(tempobj);

        this.tempinputXML.Switchboards = [];

        this.tempinputXML.Switchboards = this.tempswitchlist;

        sessionStorage.setItem("SwitchboardResults", tempobj.SwitchBoard_Matrix_Node);

        let maxData = new MaxDemandCalcInput();
        maxData.CableSizeCalcInputId = this.CalculationId;
        maxData.Node_Id = tempobj.SwitchBoard_Id;
        maxData.Node_Name = tempobj.SwitchBoard_Name;
        maxData.Node_Prefix = tempobj.SwitchBoard_Matrix_Node;
        maxData.PhaseR = '0';
        maxData.PhaseW = '0';
        maxData.PhaseB = '0';
        maxData.MaximumDemand = '0';
        maxData.PhaseImbalance = '0';
        maxData.LoadItemsData = [];
        this.maxDemandDataArray.push(maxData);


        this.addcablesize(tempobj, 'Switchboard');

        // this.onInputChange();

        sessionStorage.setItem("switchlist", (count + 1).toString());
    }

    //Copy function of Switchboard
    copySwitchboardData(switchObject, nodeId, nodeMatrix) {

        let tempobj = new NetworkSwitchBoard();

        tempobj.SwitchBoard_Id = nodeId;
        tempobj.SwitchBoard_Matrix_Node = nodeMatrix;
        tempobj.SwitchBoard_Name = switchObject.SwitchBoard_Name;
        tempobj.SwitchBoard_Phases = switchObject.SwitchBoard_Phases;
        tempobj.SwitchBoard_Allowable_Voltage_Drop = switchObject.SwitchBoard_Allowable_Voltage_Drop;
        tempobj.SwitchBoard_Total_Maximum_Demand = switchObject.SwitchBoard_Total_Maximum_Demand;
        tempobj.SwitchBoard_Phase_1_Current = switchObject.SwitchBoard_Phase_1_Current;
        tempobj.SwitchBoard_Phase_1_Phase = switchObject.SwitchBoard_Phase_1_Phase;
        tempobj.SwitchBoard_Phase_1_Power_factor = switchObject.SwitchBoard_Phase_1_Power_factor;

        tempobj.SwitchBoard_Phase_3_Currents = switchObject.SwitchBoard_Phase_3_Currents;//BAL/UNBAL
        //BAL
        tempobj.SwitchBoard_Phase_3_Phase_Current = switchObject.SwitchBoard_Phase_3_Phase_Current;
        tempobj.SwitchBoard_Phase_3_Phase_Power_factor = switchObject.SwitchBoard_Phase_3_Phase_Power_factor;

        //UNBAL
        tempobj.SwitchBoard_Phase_3_Phase_Current_L1 = switchObject.SwitchBoard_Phase_3_Phase_Current_L1;
        tempobj.SwitchBoard_Phase_3_Phase_Current_L2 = switchObject.SwitchBoard_Phase_3_Phase_Current_L2;
        tempobj.SwitchBoard_Phase_3_Phase_Current_L3 = switchObject.SwitchBoard_Phase_3_Phase_Current_L3;
        tempobj.SwitchBoard_Phase_3_Phase_Power_factor_L1 = switchObject.SwitchBoard_Phase_3_Phase_Power_factor_L1;
        tempobj.SwitchBoard_Phase_3_Phase_Power_factor_L2 = switchObject.SwitchBoard_Phase_3_Phase_Power_factor_L2;
        tempobj.SwitchBoard_Phase_3_Phase_Power_factor_L3 = switchObject.SwitchBoard_Phase_3_Phase_Power_factor_L3;

        //Switchboards
        $('.sbname').val(tempobj.SwitchBoard_Name);

        if (tempobj.SwitchBoard_Phases == 0) {
            $('#switchradio3phase').prop('checked', true);
            $('#threephasegroup').css('display', 'block');
            $('#singlephasegroup').css('display', 'none');
        }
        $('#switchvoltagedrop').val(tempobj.SwitchBoard_Allowable_Voltage_Drop);
        $("#SwitchBoard_Total_Maximum_Demand").val(tempobj.SwitchBoard_Total_Maximum_Demand).trigger('change', "true");
        $("#SwitchBoard_Phase_1_Phase").val(tempobj.SwitchBoard_Phase_1_Phase).trigger('change', "true");

        $('#SwitchBoard_Phase_1_Current').val(tempobj.SwitchBoard_Phase_1_Current);

        $('#SwitchBoard_Phase_1_Power_factor').val(tempobj.SwitchBoard_Phase_1_Power_factor);

        if (tempobj.SwitchBoard_Phase_3_Currents == 0) {
            $('#balradio').prop('checked', true);

            $('#switchbalgroup').css('display', 'block');
            $('#switchunbalgroup').css('display', 'none');
        }
        $('#SwitchBoard_Phase_3_Phase_Current').val(tempobj.SwitchBoard_Phase_3_Phase_Current);
        $('#SwitchBoard_Phase_3_Phase_Power_factor').val(tempobj.SwitchBoard_Phase_3_Phase_Power_factor);
        $('#SwitchBoard_Phase_3_Phase_Current').val(tempobj.SwitchBoard_Phase_3_Phase_Current);

        $('#SwitchBoard_Phase_3_Phase_Power_factor_L1').val(tempobj.SwitchBoard_Phase_3_Phase_Power_factor_L1);
        $('#SwitchBoard_Phase_3_Phase_Power_factor_L2').val(tempobj.SwitchBoard_Phase_3_Phase_Power_factor_L2);
        $('#SwitchBoard_Phase_3_Phase_Power_factor_L3').val(tempobj.SwitchBoard_Phase_3_Phase_Power_factor_L3);

        $('#SwitchBoard_Phase_3_Phase_Current_L1').val(tempobj.SwitchBoard_Phase_3_Phase_Current_L1);
        $('#SwitchBoard_Phase_3_Phase_Current_L2').val(tempobj.SwitchBoard_Phase_3_Phase_Current_L2);
        $('#SwitchBoard_Phase_3_Phase_Current_L3').val(tempobj.SwitchBoard_Phase_3_Phase_Current_L3);


        sessionStorage.removeItem("SwitchboardResults");


        this.tempswitchlist.push(tempobj);
        this.tempinputXML.Switchboards = [];
        this.tempinputXML.Switchboards = this.tempswitchlist;

        if (this.maxDemandDataArray.length > 0) {

            let copiedMaxData = this.maxDemandDataArray.find(x => x.Node_Id == switchObject.SwitchBoard_Id || x.Node_Prefix == switchObject.SwitchBoard_Matrix_Node);
            if (copiedMaxData !== null && copiedMaxData !== undefined) {
                if (copiedMaxData.LoadItemsData.length > 0) {

                    let maxData = new MaxDemandCalcInput();
                    maxData.LoadItemsData = [];
                    maxData.CableSizeCalcInputId = this.CalculationId;
                    maxData.Node_Id = tempobj.SwitchBoard_Id;
                    maxData.Node_Name = tempobj.SwitchBoard_Name;
                    maxData.Node_Prefix = tempobj.SwitchBoard_Matrix_Node;
                    maxData.PhaseR = copiedMaxData.PhaseR;
                    maxData.PhaseW = copiedMaxData.PhaseW;
                    maxData.PhaseB = copiedMaxData.PhaseB;
                    maxData.MaximumDemand = copiedMaxData.MaximumDemand;
                    maxData.PhaseImbalance = copiedMaxData.PhaseImbalance;

                    copiedMaxData.LoadItemsData.forEach(element => {

                        this.LoadStorage = new LoadItem();
                        this.loadId = this.loadId + 1;
                        this.LoadStorage.id = this.loadId;
                        this.LoadStorage.loadGroup = element.loadGroup;
                        //  this.LoadStorage.description = element.description;
                        this.LoadStorage.loadGroupId = element.loadGroupId;
                        this.LoadStorage.installationId = element.installationId;
                        this.LoadStorage.subGroupId = element.subGroupId;
                        this.LoadStorage.description = element.description;
                        this.LoadStorage.phases = element.phases;
                        this.LoadStorage.rating = element.rating;
                        this.LoadStorage.units = element.units;
                        this.LoadStorage.powerFactor = element.powerFactor;
                        this.LoadStorage.red = element.red;
                        this.LoadStorage.white = element.white;
                        this.LoadStorage.blue = element.blue;
                        this.LoadStorage.diversity = element.diversity;
                        this.LoadStorage.factor = element.factor;
                        this.LoadStorage.loadName = element.loadName;
                        this.LoadStorage.subName = element.subName;
                        this.LoadStorage.loadGroup = element.loadGroup;
                        this.LoadStorage.subGroup = element.subGroup;
                        this.LoadStorage.quantity = element.quantity;
                        this.LoadStorage.automaticAllocation = element.automaticAllocation;
                        this.LoadStorage.loadCode = element.loadCode;
                        this.LoadStorage.groupAsCircuit = element.groupAsCircuit;
                        this.LoadStorage.phaseAllocation = element.phaseAllocation;
                        this.LoadStorage.loadType = element.loadType;

                        maxData.LoadItemsData.push(this.LoadStorage);
                    });


                    this.maxDemandDataArray.push(maxData);
                }
                else {
                    let maxData = new MaxDemandCalcInput();
                    maxData.CableSizeCalcInputId = this.CalculationId;
                    maxData.Node_Id = tempobj.SwitchBoard_Id;
                    maxData.Node_Name = tempobj.SwitchBoard_Name;
                    maxData.Node_Prefix = tempobj.SwitchBoard_Matrix_Node;
                    maxData.PhaseR = copiedMaxData.PhaseR;
                    maxData.PhaseW = copiedMaxData.PhaseW;
                    maxData.PhaseB = copiedMaxData.PhaseB;
                    maxData.MaximumDemand = copiedMaxData.MaximumDemand;
                    maxData.PhaseImbalance = copiedMaxData.PhaseImbalance;
                    maxData.LoadItemsData = [];
                    this.maxDemandDataArray.push(maxData);
                }
            }

        }


        sessionStorage.setItem("SwitchboardResults", nodeMatrix);
    }

    //Copy function of Cable Size
    copyCableSizeFn(cablesObject, nodeId, nodeMatrix) {

        let tempcablesizeinput = new CableSizeCalcInput();

        let tempCableSizeCalcInputXML = {};

        let count = parseInt(sessionStorage.getItem("cablesizelist"));

        tempcablesizeinput.CableSizeCalcInputId = nodeId;
        tempcablesizeinput.Node_Id = nodeId.toString();
        tempcablesizeinput.Node_Prefix = nodeMatrix;
        tempcablesizeinput.Node_Name = cablesObject.Node_Name;
        tempcablesizeinput.DeviceName = cablesObject.DeviceName;
        tempcablesizeinput.CableSizeId = "C" + (nodeId - 1);

        tempCableSizeCalcInputXML = {
            insulation: '0',
            conductor: '0',
            earthconductor: '0',
            core: '0',
            phase: '0',
            faultlevel: '3',
            supplyvoltage: '',
            voltagedrop: '2.5',
            powerunit: '1',
            efficiency: '100',
            lengthofrun: '50',
            current: '100',
            powerfactor: '0.9',
            unit: '0',
            deratingfactor: '1',
            device: '0',
            usevd: '1',
            isVoltageDrop: true,
            protection: {
                shortcircuittime: '0.1',
                MCBrating: '0',
                ACBrating: '0',
                HRCFuserating: '0',
                MCCBrating: '0',
                curve: '0',
                earthfaulttime: '0',
                rcd: '0',
                tripcurrent: '0',
                tripmultiplier: '0',
                thermalsetting: '0',
                customrating: '0',
                description: '0',
                IsUnknown: undefined,
                Xml: undefined,
                TrippingXml: undefined,
                protectionConfigurationModel: {}
            },
            installtablewind: { wind: '' },
            specifyconductor: {
                phase: {
                    mode: '0',
                    size: '0',
                    circuits: '1'
                },
                neutral: {
                    mode: '0',
                    size: '0',
                    circuits: '1'
                },
                earth: {
                    mode: '1',
                    size: '0',
                    circuits: '1'
                }
            },
            tableno: '7',
            currentTable: '4D1A,2',
            voltagedropTable: '4D1B,3',
            armour: '0',
            columnno: '0',
            startingrowindex: '0',
            endingrowindex: '0',
            description: 'Unenclosed Spaced',
            tablectrlvalue: '0',
            voltagefactor: '1.1',
            calculationstandard: '0',
            arcFlashData: {},
            isderatingwiz: '0',
            deratingtemp: ''
        };


        tempCableSizeCalcInputXML['dcvoltage'] = cablesObject.CableSizeCalcInputXML['dcvoltage'];
        tempCableSizeCalcInputXML['onephasevoltage'] = cablesObject.CableSizeCalcInputXML['onephasevoltage'];
        tempCableSizeCalcInputXML['threephasevoltage'] = cablesObject.CableSizeCalcInputXML['threephasevoltage'];
        tempCableSizeCalcInputXML['insulation'] = cablesObject.CableSizeCalcInputXML['insulation'];
        tempCableSizeCalcInputXML['conductor'] = cablesObject.CableSizeCalcInputXML['conductor'];
        tempCableSizeCalcInputXML['core'] = cablesObject.CableSizeCalcInputXML['core'];
        tempCableSizeCalcInputXML['armour'] = cablesObject.CableSizeCalcInputXML['armour'];
        tempCableSizeCalcInputXML['earthconductor'] = cablesObject.CableSizeCalcInputXML['earthconductor'];
        tempCableSizeCalcInputXML['phase'] = cablesObject.CableSizeCalcInputXML['phase'];
        tempCableSizeCalcInputXML['faultlevel'] = cablesObject.CableSizeCalcInputXML['faultlevel'];
        tempCableSizeCalcInputXML['supplyvoltage'] = cablesObject.CableSizeCalcInputXML['supplyvoltage'];
        tempCableSizeCalcInputXML['voltagedrop'] = cablesObject.CableSizeCalcInputXML['voltagedrop'];
        tempCableSizeCalcInputXML['powerunit'] = cablesObject.CableSizeCalcInputXML['powerunit'];
        tempCableSizeCalcInputXML['efficiency'] = cablesObject.CableSizeCalcInputXML['efficiency'];

        tempCableSizeCalcInputXML['lengthofrun'] = cablesObject.CableSizeCalcInputXML['lengthofrun'];
        tempCableSizeCalcInputXML['current'] = cablesObject.CableSizeCalcInputXML['current'];
        tempCableSizeCalcInputXML['powerfactor'] = cablesObject.CableSizeCalcInputXML['powerfactor'];
        tempCableSizeCalcInputXML['unit'] = cablesObject.CableSizeCalcInputXML['unit'];
        tempCableSizeCalcInputXML['deratingfactor'] = cablesObject.CableSizeCalcInputXML['deratingfactor'];
        tempCableSizeCalcInputXML['device'] = cablesObject.CableSizeCalcInputXML['device'];
        tempCableSizeCalcInputXML['devicename'] = cablesObject.CableSizeCalcInputXML['devicename'];

        tempCableSizeCalcInputXML['usevd'] = cablesObject.CableSizeCalcInputXML['usevd'];
        tempCableSizeCalcInputXML['isVoltageDrop'] = cablesObject.CableSizeCalcInputXML['isVoltageDrop'];

        tempCableSizeCalcInputXML['protection']['shortcircuittime'] = cablesObject.CableSizeCalcInputXML['protection']['shortcircuittime'];
        tempCableSizeCalcInputXML['protection']['MCBrating'] = cablesObject.CableSizeCalcInputXML['protection']['MCBrating'];
        tempCableSizeCalcInputXML['protection']['ACBrating'] = cablesObject.CableSizeCalcInputXML['protection']['ACBrating'];
        tempCableSizeCalcInputXML['protection']['HRCFuserating'] = cablesObject.CableSizeCalcInputXML['protection']['HRCFuserating'];
        tempCableSizeCalcInputXML['protection']['MCCBrating'] = cablesObject.CableSizeCalcInputXML['protection']['MCCBrating'];
        tempCableSizeCalcInputXML['protection']['curve'] = cablesObject.CableSizeCalcInputXML['protection']['curve'];
        tempCableSizeCalcInputXML['protection']['earthfaulttime'] = cablesObject.CableSizeCalcInputXML['protection']['earthfaulttime'];
        tempCableSizeCalcInputXML['protection']['rcd'] = cablesObject.CableSizeCalcInputXML['protection']['rcd'];
        tempCableSizeCalcInputXML['protection']['tripcurrent'] = cablesObject.CableSizeCalcInputXML['protection']['tripcurrent'];
        tempCableSizeCalcInputXML['protection']['tripmultiplier'] = cablesObject.CableSizeCalcInputXML['protection']['tripmultiplier'];
        tempCableSizeCalcInputXML['protection']['thermalsetting'] = cablesObject.CableSizeCalcInputXML['protection']['thermalsetting'];
        tempCableSizeCalcInputXML['protection']['customrating'] = cablesObject.CableSizeCalcInputXML['protection']['customrating'];
        tempCableSizeCalcInputXML['protection']['description'] = cablesObject.CableSizeCalcInputXML['protection']['description'];
        tempCableSizeCalcInputXML['protection']['IsUnknown'] = cablesObject.CableSizeCalcInputXML['protection']['IsUnknown'];
        tempCableSizeCalcInputXML['protection']['protectionConfigurationModel'] = cablesObject.CableSizeCalcInputXML['protection']['protectionConfigurationModel'];
        tempCableSizeCalcInputXML['protection']['Xml'] = cablesObject.CableSizeCalcInputXML['protection']['Xml'];
        tempCableSizeCalcInputXML['protection']['TrippingXml'] = cablesObject.CableSizeCalcInputXML['protection']['TrippingXml'];
        tempCableSizeCalcInputXML['installtablewind']['wind'] = cablesObject.CableSizeCalcInputXML['installtablewind']['wind'];


        tempCableSizeCalcInputXML['specifyconductor']['phase']['mode'] = cablesObject.CableSizeCalcInputXML['specifyconductor']['phase']['mode'];
        tempCableSizeCalcInputXML['specifyconductor']['phase']['circuits'] = cablesObject.CableSizeCalcInputXML['specifyconductor']['phase']['circuits'];
        tempCableSizeCalcInputXML['specifyconductor']['phase']['size'] = cablesObject.CableSizeCalcInputXML['specifyconductor']['phase']['size'];

        tempCableSizeCalcInputXML['specifyconductor']['neutral']['circuits'] = cablesObject.CableSizeCalcInputXML['specifyconductor']['neutral']['circuits'];
        tempCableSizeCalcInputXML['specifyconductor']['neutral']['size'] = cablesObject.CableSizeCalcInputXML['specifyconductor']['neutral']['size'];
        tempCableSizeCalcInputXML['specifyconductor']['neutral']['mode'] = cablesObject.CableSizeCalcInputXML['specifyconductor']['neutral']['mode'];

        tempCableSizeCalcInputXML['specifyconductor']['earth']['mode'] = cablesObject.CableSizeCalcInputXML['specifyconductor']['earth']['mode'];
        tempCableSizeCalcInputXML['specifyconductor']['earth']['size'] = cablesObject.CableSizeCalcInputXML['specifyconductor']['earth']['size'];
        tempCableSizeCalcInputXML['specifyconductor']['earth']['circuits'] = cablesObject.CableSizeCalcInputXML['specifyconductor']['earth']['circuits'];

        tempCableSizeCalcInputXML['tableno'] = cablesObject.CableSizeCalcInputXML['tableno'];
        tempCableSizeCalcInputXML['currentTable'] = cablesObject.CableSizeCalcInputXML['currentTable'];
        tempCableSizeCalcInputXML['voltagedropTable'] = cablesObject.CableSizeCalcInputXML['voltagedropTable'];
        tempCableSizeCalcInputXML['columnno'] = cablesObject.CableSizeCalcInputXML['columnno'];
        tempCableSizeCalcInputXML['startingrowindex'] = cablesObject.CableSizeCalcInputXML['startingrowindex'];
        tempCableSizeCalcInputXML['endingrowindex'] = cablesObject.CableSizeCalcInputXML['endingrowindex'];
        tempCableSizeCalcInputXML['description'] = cablesObject.CableSizeCalcInputXML['description'];
        tempCableSizeCalcInputXML['tablectrlvalue'] = cablesObject.CableSizeCalcInputXML['tablectrlvalue'];
        tempCableSizeCalcInputXML['voltagefactor'] = cablesObject.CableSizeCalcInputXML['voltagefactor'];
        tempCableSizeCalcInputXML['calculationstandard'] = cablesObject.CableSizeCalcInputXML['calculationstandard'];
        tempCableSizeCalcInputXML['arcFlashData'] = cablesObject.CableSizeCalcInputXML['arcFlashData'];
        tempCableSizeCalcInputXML['isderatingwiz'] = cablesObject.CableSizeCalcInputXML['isderatingwiz'];
        tempCableSizeCalcInputXML['deratingtemp'] = cablesObject.CableSizeCalcInputXML['deratingtemp'];


        tempcablesizeinput.CableSizeCalcInputXML = tempCableSizeCalcInputXML;
        this.tempcablesizelist.push(tempcablesizeinput);

        this.tempinputXML.CableSizeCalcInputs = JSON.stringify(this.tempcablesizelist);

        sessionStorage.setItem("cablesizelist", (count + 1).toString());

    }

    //Copy function of Load Object
    copyLoadData(loadObject, nodeId, nodeMatrix) {

        if (this.tempinputXML.Loads != undefined)
            this.templist = this.tempinputXML.Loads;
        else if (this.tempinputXML.Loads == undefined)
            this.templist.length = 0;


        let tempload = new NetworkLoad();

        tempload.Load_Id = nodeId;
        tempload.Load_Name = loadObject.Load_Name;
        tempload.Load_Matrix_Node = nodeMatrix
        tempload.Load_Phases = loadObject.Load_Phases;
        tempload.Load_Phase_1_Current = loadObject.Load_Phase_1_Current;
        tempload.Load_Phase_1_Phase = loadObject.Load_Phase_1_Phase;
        tempload.Load_Phase_1_Powerfactor = loadObject.Load_Phase_1_Powerfactor;
        tempload.Load_Phase_1_Harmonics = loadObject.Load_Phase_1_Harmonics;

        tempload.Load_Phase_3_Phase_Current = loadObject.Load_Phase_3_Phase_Current;
        tempload.Load_Phase_3_Currents = loadObject.Load_Phase_3_Currents;
        tempload.Load_Phase_3_Powerfactor = loadObject.Load_Phase_3_Powerfactor;
        tempload.Load_Phase_3_Harmonics_Balanced = loadObject.Load_Phase_3_Harmonics_Balanced;
        tempload.Load_Phase_3_Harmonics_UnBalanced = loadObject.Load_Phase_3_Harmonics_UnBalanced;

        tempload.Load_Phase_3_Phase_Current_L1 = loadObject.Load_Phase_3_Phase_Current_L1;
        tempload.Load_Phase_3_Phase_Current_L2 = loadObject.Load_Phase_3_Phase_Current_L2;
        tempload.Load_Phase_3_Phase_Current_L3 = loadObject.Load_Phase_3_Phase_Current_L3;

        tempload.Load_Phase_3_Powerfactor_L1 = loadObject.Load_Phase_3_Powerfactor_L1;
        tempload.Load_Phase_3_Powerfactor_L2 = loadObject.Load_Phase_3_Powerfactor_L2;
        tempload.Load_Phase_3_Powerfactor_L3 = loadObject.Load_Phase_3_Powerfactor_L3;

        this.templist.push(tempload);
        this.tempinputXML.Loads = [];
        this.tempinputXML.Loads = this.templist;
    }

    //Copy function of Capacitor Object
    copyCapacitorData(capacitorObject, nodeId, nodeMatrix) {
        let tempNetworkCapacitorBank = new NetworkCapacitorBank();

        tempNetworkCapacitorBank.NetworkCapacitorBank_Id = nodeId;
        tempNetworkCapacitorBank.NetworkCapacitorBank_Node_Id = nodeId.toString();
        tempNetworkCapacitorBank.NetworkCapacitorBank_Name = capacitorObject.NetworkCapacitorBank_Name;
        tempNetworkCapacitorBank.NetworkCapacitorBank_Matrix_Node = nodeMatrix;

        tempNetworkCapacitorBank.NetworkCapacitorBank_Power_Factor_After_Correction = capacitorObject.NetworkCapacitorBank_Power_Factor_After_Correction;
        tempNetworkCapacitorBank.NetworkCapacitorBank_Power_Factor_Before_Correction = capacitorObject.NetworkCapacitorBank_Power_Factor_Before_Correction;
        tempNetworkCapacitorBank.NetworkCapacitorBank_Rating = capacitorObject.NetworkCapacitorBank_Rating;
        tempNetworkCapacitorBank.NetworkCapacitorBank_Required_Compensation = capacitorObject.NetworkCapacitorBank_Required_Compensation;

        this.tempinputXML.CapacitorBanks = [];
        this.tempcapacitorlist.push(tempNetworkCapacitorBank);
        this.tempinputXML.CapacitorBanks = this.tempcapacitorlist;

        //Power Factor Correction
        let tempPFC = new PowerFactorCorrection();
        let count = parseInt(sessionStorage.getItem("powerfactorlist"));
        let pfcData = this.temppowerfactorcorrections.find(x => x.Node_Id == capacitorObject.NetworkCapacitorBank_Node_Id || x.Node_Prefix == capacitorObject.NetworkCapacitorBank_Matrix_Node);
        tempPFC.PFC_Id = count.toString();
        tempPFC.Node_Id = tempNetworkCapacitorBank.NetworkCapacitorBank_Node_Id;

        tempPFC.Node_Prefix = tempNetworkCapacitorBank.NetworkCapacitorBank_Matrix_Node;
        tempPFC.Node_Name = tempNetworkCapacitorBank.NetworkCapacitorBank_Name;
        if (pfcData != null && pfcData != undefined) {

            tempPFC.PFC_Target_Power_Factor = pfcData.PFC_Target_Power_Factor;
            tempPFC.PFC_Auto_Size_Capacitor_Bank = pfcData.PFC_Auto_Size_Capacitor_Bank;
            tempPFC.PFC_Capacitor_Bank_To_Install = pfcData.PFC_Capacitor_Bank_To_Install;
            tempPFC.PFC_Capacitor_Bank_Custom_Rating = pfcData.PFC_Capacitor_Bank_Custom_Rating;

            tempPFC.PFC_Economic_Calculation = pfcData.PFC_Economic_Calculation;
            tempPFC.PFC_Installation_Cost = pfcData.PFC_Installation_Cost;
            tempPFC.PFC_Demand_Charge = pfcData.PFC_Demand_Charge;
            tempPFC.PFC_Utility_Factor = pfcData.PFC_Utility_Factor;
            tempPFC.PFC_Benefit_Time_Period = pfcData.PFC_Benefit_Time_Period;
            tempPFC.PFC_Interest_Rate = pfcData.PFC_Interest_Rate;
        }
        else {
            tempPFC.PFC_Target_Power_Factor = "0.9";
            tempPFC.PFC_Auto_Size_Capacitor_Bank = "1";
            tempPFC.PFC_Capacitor_Bank_To_Install = "10";
            tempPFC.PFC_Capacitor_Bank_Custom_Rating = 10;
            tempPFC.PFC_Economic_Calculation = "0";
            tempPFC.PFC_Installation_Cost = 3000;
            tempPFC.PFC_Demand_Charge = 30;
            tempPFC.PFC_Utility_Factor = 80;
            tempPFC.PFC_Benefit_Time_Period = 7;
            tempPFC.PFC_Interest_Rate = 7;
        }
        this.temppowerfactorcorrections.push(tempPFC);
        this.tempinputXML.PowerFactorCorrections = this.temppowerfactorcorrections;
        sessionStorage.setItem("powerfactorlist", (count + 1).toString());

    }

    //Copying Nodes Data Function
    copyTreeObject(tree: any) {
        if ((!this.premiumUser || this.planLevel == '1' || this.planLevel == '2') && ((this.objectType == 1 && parseInt(this.cablePullingXmlInput.SwitchboardCount) >= 2)
            || (this.objectType == 2 && this.loadcount >= 1) || (this.objectType == 3 && this.capacitorcount >= 1))
        ) {
            $('#limit-trial-device').modal('show');
        }
        else {
            if (tree != null) {
                if (tree.data.id !== 1 && tree.data.id !== 2) {
                    this.options.allowDrag = false;
                    this.options.allowDrop = false;
                    this.addRemoveNode = true;
                    document.getElementById('defaultCableOpen2').click();
                    let isNodeCopied = false;

                    if (tree.data.objecttype !== 3) {

                        let objecttype = 1;
                        var txts = "Switchboard";
                        var objectcodes = "SB";
                        var imgs = '/assets/cablepro/img/Switchboard_icon.png';
                        if (tree.data.objecttype == 1 && tree.data.children.length == 0) {
                            // this.showLoader();
                            isNodeCopied = true;
                            objecttype = 1;
                            txts = tree.data.name;

                            let S_treeNodeCountData = parseInt(sessionStorage.getItem("switchlist"));
                            this.maxcount = S_treeNodeCountData + 1;
                            sessionStorage.setItem("switchlist", this.maxcount.toString());

                            if (this.tempinputXML.Switchboards.length > 0) {

                                let switchDatas = this.tempinputXML.Switchboards.find(x => x.SwitchBoard_Id == tree.data.id);
                                let matrixNode = objectcodes + this.maxcount;

                                this.copySwitchboardData(switchDatas, this.objectId, matrixNode);
                                this._wizardService.copyNetworkProtection(tree.data.objectcode, matrixNode, this.newCalculationId).subscribe(result => {
                                }, (error) => {
                                });
                            }

                        }
                        else if (tree.data.objecttype == 2 && tree.data.children.length == 0) {
                            // this.showLoader();
                            isNodeCopied = true;
                            objecttype = 2;
                            txts = tree.data.name;
                            objectcodes = "L";
                            imgs = '/assets/cablepro/img/Load_icon.png';

                            let L_treeNodeCountData = parseInt(sessionStorage.getItem("loadlist"));
                            this.maxcount = L_treeNodeCountData + 1;
                            sessionStorage.setItem("loadlist", this.maxcount.toString());

                            if (this.tempinputXML.Loads.length == 0) {
                                this.tempinputXML.Loads = []
                            }

                            let loadsRecord = this.tempinputXML.Loads.find(x => x.Load_Id == tree.data.id);
                            let loadMatrix = objectcodes + this.maxcount;
                            this.copyLoadData(loadsRecord, this.objectId, loadMatrix)
                            this._wizardService.copyNetworkProtection(tree.data.objectcode, loadMatrix, this.newCalculationId).subscribe(result => {
                            }, (error) => {
                            });
                        }
                        else if (tree.data.objecttype == 1 && tree.data.children.length > 0) {

                            // this.showLoader();
                            isNodeCopied = false;

                            let S_treeNodeCountData = parseInt(sessionStorage.getItem("switchlist"));
                            this.maxcount = S_treeNodeCountData + 1;
                            sessionStorage.setItem("switchlist", this.maxcount.toString());


                            if (this.tempinputXML.Switchboards.length > 0) {

                                let switchDatas = this.tempinputXML.Switchboards.find(x => x.SwitchBoard_Id == tree.data.id);
                                let matrixNode = objectcodes + this.maxcount;

                                this.copySwitchboardData(switchDatas, this.objectId, matrixNode);
                            }

                            tree.parent.data.children.push({
                                name: tree.data.name,
                                img: imgs,
                                class: 'normal',
                                childindex: tree.parent.data.children.length,
                                id: this.objectId,
                                objecttype: 1,
                                objectcode: objectcodes + this.maxcount,
                                parentid: tree.parent.data.objectcode,
                                children: []
                            });
                            this.activeNode = undefined;
                            this.cd.detectChanges();
                            this.activeNode = {
                                name: tree.data.name,
                                img: imgs,
                                class: 'normal',
                                childindex: tree.parent.data.children.length,
                                id: this.objectId,
                                objecttype: 1,
                                objectcode: objectcodes + this.maxcount,
                                parentid: tree.parent.data.objectcode,
                                children: []
                            };

                            tree.treeModel.update();


                            this.finalDrf = parseFloat($('#deratingFactor').val().toString());
                            if (this.UserWizard == true) {
                                this.updateWizardFactorValues(true);
                            }

                            let templist = JSON.parse(this.tempinputXML.CableSizeCalcInputs)
                            var cablesDataObject = templist.find(x => x.Node_Id === tree.data.id.toString() || x.Node_Prefix == tree.data.objectcode);
                            let nodeMatrice = objectcodes + this.maxcount;
                            this.copyCableSizeFn(cablesDataObject, this.objectId, nodeMatrice);

                            this.objectId = this.objectId + 1;

                            sessionStorage.setItem('nodecount', this.objectId.toString());


                            this.copyMultipleNodesData(tree.data.children, tree.treeModel.getNodeById(this.objectId - 1)).then(
                                (res) => {
                                    this._wizardService.copyNetworkProtection(tree.data.objectcode, nodeMatrice, this.newCalculationId).subscribe(result => {
                                    }, (error) => {
                                    });
                                    this.allnodes = [];
                                    this.matrix = [];

                                    this.getallnodes(this.items[0]);
                                    this.printArray(this.items[0]);
                                    this.changekeyofmatrix();


                                    tree.treeModel.getNodeById(this.objectId - 1).setActiveAndVisible();
                                    sessionStorage.removeItem("currentnode");
                                    var currentNodeIndexData = tree.treeModel.getNodeById(this.objectId - 1)
                                    this.selectedNetworkData = currentNodeIndexData.data;
                                    this.selectedTreeNode = this.selectedNetworkData;
                                    this.activeNode = undefined;
                                    this.cd.detectChanges();
                                    this.activeNode = this.selectedTreeNode;
                                    sessionStorage.setItem("currentnode", JSON.stringify(this.selectedTreeNode));


                                    this.onInputChange();
                                    this.changediv(currentNodeIndexData.data);

                                    this.copyTreeObjectPostFn(isNodeCopied, txts, imgs, objecttype, objectcodes, tree);
                                },
                                (err) => {

                                }
                            );



                        }
                        if (!(tree.data.objecttype == 1 && tree.data.children.length > 0)) {
                            this.copyTreeObjectPostFn(isNodeCopied, txts, imgs, objecttype, objectcodes, tree);
                        }
                    }
                    else {
                        this.addRemoveNode = false;
                    }

                }
            }
        }

    }

    copyTreeObjectPostFn(isNodeCopied: boolean, txts: string, imgs: string, objecttype: number, objectcodes: string, tree: any) {
        if (isNodeCopied) {

            tree.parent.data.children.push({
                name: txts,
                img: imgs,
                class: 'normal',
                childindex: tree.parent.data.children.length,
                id: this.objectId,
                objecttype: objecttype,
                objectcode: objectcodes + this.maxcount,
                parentid: tree.parent.data.objectcode,
                children: []
            });


            let templist = JSON.parse(this.tempinputXML.CableSizeCalcInputs)
            var cablesDataObject = templist.find(x => x.Node_Id === tree.data.id.toString() || x.Node_Prefix == tree.data.objectcode);
            let nodeMatrice = objectcodes + this.maxcount;
            this.copyCableSizeFn(cablesDataObject, this.objectId, nodeMatrice);

            this.objectId = this.objectId + 1;

            sessionStorage.setItem('nodecount', this.objectId.toString());
            tree.treeModel.update();
        }

        if (isNodeCopied) {

            this.allnodes = [];
            this.matrix = [];
            this.getallnodes(this.items[0]);
            this.printArray(this.items[0]);
            this.changekeyofmatrix();

            tree.treeModel.getNodeById(this.objectId - 1)
                .setActiveAndVisible();
            sessionStorage.removeItem("currentnode");
            var currentNodeIndexData = tree.treeModel.getNodeById(this.objectId - 1)
            this.selectedNetworkData = currentNodeIndexData.data;
            this.selectedTreeNode = this.selectedNetworkData;
            this.activeNode = undefined;
            this.cd.detectChanges();
            this.activeNode = this.selectedTreeNode;
            sessionStorage.setItem("currentnode", JSON.stringify(this.selectedTreeNode));


            this.onInputChange();
            this.changediv(currentNodeIndexData.data);
        }
        if (!(tree.data.objecttype == 1 && tree.data.children.length > 0)) {
            this.activeNode = undefined;
            this.cd.detectChanges();
            this.activeNode = {
                name: txts,
                img: imgs,
                class: 'normal',
                //childindex: tree.parent.data.children.length,
                id: this.objectId - 1,
                objecttype: objecttype,
                objectcode: objectcodes + this.maxcount,
                parentid: tree.data.parentid,
                children: []
            };
            this.finalDrf = parseFloat($('#deratingFactor').val().toString());
            if (this.UserWizard == true) {
                this.updateWizardFactorValues(true);
            }
        }
    }
    //Recursive function for multiple nodes
    copyMultipleNodesData(childrenData: any, tree: any) {
        return new Promise((resolve, reject) => {
            childrenData.forEach(element => {
                let newObjectCode = undefined;
                if (element.objecttype == 1) {

                    let S_treeNodeCountData = parseInt(sessionStorage.getItem("switchlist"));
                    this.maxcount = S_treeNodeCountData + 1;
                    sessionStorage.setItem("switchlist", this.maxcount.toString());

                    if (this.tempinputXML.Switchboards.length > 0) {

                        let switchDatas = this.tempinputXML.Switchboards.find(x => x.SwitchBoard_Id == element.id);
                        let matrixNode = "SB" + this.maxcount;

                        this.copySwitchboardData(switchDatas, this.objectId, matrixNode);
                    }

                    let templist = JSON.parse(this.tempinputXML.CableSizeCalcInputs)
                    var cablesDataObject = templist.find(x => x.Node_Id === element.id.toString() || x.Node_Prefix == element.objectcode);
                    let nodeMatrice = "SB" + this.maxcount;
                    this.copyCableSizeFn(cablesDataObject, this.objectId, nodeMatrice);


                    tree.data.children.push({
                        name: element.name,
                        img: element.img,
                        class: 'normal',
                        childindex: tree.data.children.length,
                        id: this.objectId,
                        objecttype: element.objecttype,
                        objectcode: "SB" + this.maxcount,
                        parentid: tree.data.objectcode,
                        children: []
                    });
                    newObjectCode = nodeMatrice;
                    this.objectId = this.objectId + 1;
                    sessionStorage.setItem('nodecount', this.objectId.toString());
                    tree.treeModel.update();



                    if (element.children.length > 0) {
                        this.copyMultipleNodesData(element.children, tree.treeModel.getNodeById(this.objectId - 1));
                    }
                }
                if (element.objecttype == 2) {

                    let L_treeNodeCountData = parseInt(sessionStorage.getItem("loadlist"));
                    this.maxcount = L_treeNodeCountData + 1;
                    sessionStorage.setItem("loadlist", this.maxcount.toString());

                    if (this.tempinputXML.Loads.length == 0) {
                        this.tempinputXML.Loads = []
                    }

                    let loadsRecord = this.tempinputXML.Loads.find(x => x.Load_Id == element.id);
                    let loadMatrix = "L" + this.maxcount;
                    this.copyLoadData(loadsRecord, this.objectId, loadMatrix)


                    let templist = JSON.parse(this.tempinputXML.CableSizeCalcInputs)
                    var cablesDataObject = templist.find(x => x.Node_Id === element.id.toString() || x.Node_Prefix == element.objectcode);
                    let nodeMatrice = "L" + this.maxcount;
                    this.copyCableSizeFn(cablesDataObject, this.objectId, nodeMatrice);


                    tree.data.children.push({
                        name: element.name,
                        img: element.img,
                        class: 'normal',
                        childindex: tree.data.children.length,
                        id: this.objectId,
                        objecttype: element.objecttype,
                        objectcode: "L" + this.maxcount,
                        parentid: tree.data.objectcode,
                        children: []
                    });
                    tree.treeModel.update();

                    newObjectCode = nodeMatrice;
                    this.objectId = this.objectId + 1;
                    sessionStorage.setItem('nodecount', this.objectId.toString());
                }
                if (element.objecttype == 3) {


                    let C_treeNodeCountData = parseInt(sessionStorage.getItem("capacitorlist"));
                    this.maxcount = C_treeNodeCountData + 1;
                    sessionStorage.setItem("capacitorlist", this.maxcount.toString());

                    if (this.tempinputXML.CapacitorBanks.length == 0) {
                        this.tempinputXML.CapacitorBanks = []
                    }

                    let capacitorRecords = this.tempinputXML.CapacitorBanks.find(x => x.NetworkCapacitorBank_Id === element.id);
                    let cMatrix = "CB" + this.maxcount;
                    this.copyCapacitorData(capacitorRecords, this.objectId, cMatrix)


                    let templist = JSON.parse(this.tempinputXML.CableSizeCalcInputs)
                    var cablesDataObject = templist.find(x => x.Node_Id === element.id.toString() || x.Node_Prefix == element.objectcode);
                    let nodeMatrice = "CB" + this.maxcount;
                    this.copyCableSizeFn(cablesDataObject, this.objectId, nodeMatrice);


                    tree.data.children.push({
                        name: element.name,
                        img: element.img,
                        class: 'normal',
                        childindex: tree.data.children.length,
                        id: this.objectId,
                        objecttype: element.objecttype,
                        objectcode: "CB" + this.maxcount,
                        parentid: tree.data.objectcode,
                        children: []
                    });
                    tree.treeModel.update();

                    newObjectCode = nodeMatrice;
                    this.objectId = this.objectId + 1;
                    sessionStorage.setItem('nodecount', this.objectId.toString());
                }
                if (newObjectCode) {
                    this._wizardService.copyNetworkWizardFactors(element.objectcode, newObjectCode, this.newCalculationId, this.drInstallType).subscribe(result => {
                        resolve('done');
                    }, (error) => {
                        resolve('done');
                    });
                    this._wizardService.copyNetworkProtection(element.objectcode, newObjectCode, this.newCalculationId).subscribe(result => {
                        resolve('done');
                    }, (error) => {
                        resolve('done');
                    });
                }
                else {
                    resolve('done');
                }
            });
        });
    }


    //INUSE
    updateswitchdata(e: any, controlname: string) {

        let nodeid = (JSON.parse(sessionStorage.getItem("currentnode"))).id;

        // this.templist.find(x => x.Load_Id == nodeid).Load_Phases = 0;

        // this.tempswitchlist.forEach(
        this.tempinputXML.Switchboards.forEach(
            itemnode => {
                if (itemnode.SwitchBoard_Id == nodeid) {
                    if (controlname == 'switchphasesradio') {
                        itemnode.SwitchBoard_Phases = e.target.id == 'switchradio3phase' ? 0 : 1;
                    }
                    if (controlname == 'Phase1Current') {
                        itemnode.SwitchBoard_Phase_1_Current = parseFloat($("#Phase1Current").val().toString());
                    }
                    if (controlname == 'switchvoltagedrop') {
                        itemnode.SwitchBoard_Allowable_Voltage_Drop = parseFloat($("#switchvoltagedrop").val().toString());
                    }

                    if (controlname == "SwitchBoard_Total_Maximum_Demand")
                        itemnode.SwitchBoard_Total_Maximum_Demand = parseInt($("#SwitchBoard_Total_Maximum_Demand").val().toString());

                    if (controlname == "switchName")
                        itemnode.SwitchBoard_Name = $("#switchName").val().toString();

                    //phase 1
                    if (controlname == "SwitchBoard_Phase_1_Current")
                        itemnode.SwitchBoard_Phase_1_Current = parseFloat($("#SwitchBoard_Phase_1_Current").val().toString());
                    if (controlname == "SwitchBoard_Phase_1_Phase")
                        itemnode.SwitchBoard_Phase_1_Phase = parseFloat($("#SwitchBoard_Phase_1_Phase").val().toString());
                    if (controlname == "SwitchBoard_Phase_1_Power_factor")
                        itemnode.SwitchBoard_Phase_1_Power_factor = parseFloat($("#SwitchBoard_Phase_1_Power_factor").val().toString());

                    //phase 3

                    if (controlname == 'SwitchBoard_Phase_3_Currents')
                        itemnode.SwitchBoard_Phase_3_Currents = e.target.id == 'balradio' ? 0 : 1;
                    if (controlname == "SwitchBoard_Phase_3_Phase_Current")
                        itemnode.SwitchBoard_Phase_3_Phase_Current = parseFloat($("#SwitchBoard_Phase_3_Phase_Current").val().toString());
                    if (controlname == "SwitchBoard_Phase_3_Phase_Power_factor")
                        itemnode.SwitchBoard_Phase_3_Phase_Power_factor = parseFloat($("#SwitchBoard_Phase_3_Phase_Power_factor").val().toString());

                    if (controlname == "SwitchBoard_Phase_3_Phase_Current_L1")
                        itemnode.SwitchBoard_Phase_3_Phase_Current_L1 = parseFloat($("#SwitchBoard_Phase_3_Phase_Current_L1").val().toString());

                    if (controlname == "SwitchBoard_Phase_3_Phase_Current_L2")
                        itemnode.SwitchBoard_Phase_3_Phase_Current_L2 = parseFloat($("#SwitchBoard_Phase_3_Phase_Current_L2").val().toString());

                    if (controlname == "SwitchBoard_Phase_3_Phase_Current_L3")
                        itemnode.SwitchBoard_Phase_3_Phase_Current_L3 = parseFloat($("#SwitchBoard_Phase_3_Phase_Current_L3").val().toString());



                    if (controlname == "SwitchBoard_Phase_3_Phase_Power_factor_L1")
                        itemnode.SwitchBoard_Phase_3_Phase_Power_factor_L1 = parseFloat($("#SwitchBoard_Phase_3_Phase_Power_factor_L1").val().toString());

                    if (controlname == "SwitchBoard_Phase_3_Phase_Power_factor_L2")
                        itemnode.SwitchBoard_Phase_3_Phase_Power_factor_L2 = parseFloat($("#SwitchBoard_Phase_3_Phase_Power_factor_L2").val().toString());

                    if (controlname == "SwitchBoard_Phase_3_Phase_Power_factor_L3")
                        itemnode.SwitchBoard_Phase_3_Phase_Power_factor_L3 = parseFloat($("#SwitchBoard_Phase_3_Phase_Power_factor_L3").val().toString());


                }
            });

        let switchboards = [...this.tempinputXML.Switchboards];
        this.tempinputXML.Switchboards = switchboards;
        // this.updatecablesizeinarray();

        this.onInputChange();
    }

    //INUSE
    addNode(tree: any) {
        //alert(this.cablePullingXmlInput.SwitchboardCount)
        if ((!this.premiumUser || this.planLevel == '1' || this.planLevel == '2') && ((this.objectType == 1 && parseInt(this.cablePullingXmlInput.SwitchboardCount) >= 2)
            || (this.objectType == 2 && this.loadcount >= 1) || (this.objectType == 3 && this.capacitorcount >= 1))
        ) {
            $('#limit-trial-device').modal('show');
        }
        else {
            this.showScroll = true;
            this.isCapacitorAdded = true;
            this.isMaxDemandDataShown = false;
            this.insertrow = false;
            this.insertrow2 = false;
            this.functionCallCount = 0;
            this.isInputAPIStatus = true;
            this.isXmlStatus = true;
            this.isNewNodeAdded = true;
            this.objectCount = this.objectCount + 1;

            if (tree.data.parentid !== 0 && tree.data.id !== 1) {
                this.options.allowDrag = false;
                this.options.allowDrop = false;
                this.addRemoveNode = true;
                document.getElementById('defaultCableOpen2').click();
                this.isButtonShown = true;
                if (tree != null && tree.data != null) {
                    $('#selectNetwork').val('0');

                    var txt = "Switchboard";
                    var objectcode = "SB";
                    var img = '/assets/cablepro/img/Switchboard_icon.png';
                    //SWITCHBOARD
                    if (this.objectType == 1) {

                        let S_treeNodeCountData = parseInt(sessionStorage.getItem("switchlist"));
                        this.maxcount = S_treeNodeCountData + 1;

                        // sessionStorage.setItem("loadlist",(count + 1).toString());
                        txt = "Switchboard" + parseInt(sessionStorage.getItem("switchlist")).toString();

                        $("#SwitchboardHead").show();
                        $("#MaxDemandHead").show();
                        $("#CableSizeHead").show();
                        $("#ArcFlashHead").show();
                        //$("#calculationresulttab2").show();
                        $('#isCableSizeShown').css('display', 'block');

                        $(".SwitchboardHead").click();

                        let a_element: HTMLElement = document.getElementById('aSwitchboard') as HTMLElement;
                        a_element.click();

                        // $('#drTable23Row').trigger('change');
                        $("#LoadHead").hide();
                        $("#CapacitorHead").hide();
                        $('#PowerfactorHead').hide();
                        this.showgeneratorResult = false;
                        this.showFeederResult = false;
                        this.showPowerStationResult = false;
                        this.showTransformerResult = false;
                        this.showPowerFactorResult = false;
                        this.showSwitchboardResult = true;
                    }
                    //LOAD
                    if (this.objectType == 2) {

                        let L_treeNodeCountData = parseInt(sessionStorage.getItem("loadlist"));
                        this.maxcount = L_treeNodeCountData + 1;


                        txt = "Load" + parseInt(sessionStorage.getItem("loadlist")).toString();
                        objectcode = "L";
                        img = '/assets/cablepro/img/Load_icon.png';
                        $("#SwitchboardHead").hide();
                        $("#MaxDemandHead").hide();
                        $("#ArcFlashHead").hide();
                        // $("#CableSizeHead").hide();

                        $("#LoadHead").show();
                        $("#CableSizeHead").show();
                        //   $("#calculationresulttab2").hide();
                        $('#isCableSizeShown').css('display', 'none');

                        let a_element: HTMLElement = document.getElementById('aLoad') as HTMLElement;
                        a_element.click();

                        $("#CapacitorHead").hide();
                        $('#PowerfactorHead').hide();
                        $(".LoadHead").click();
                        this.showgeneratorResult = false;
                        this.showFeederResult = false;
                        this.showPowerStationResult = false;
                        this.showTransformerResult = false;
                        this.showPowerFactorResult = false;
                        this.showSwitchboardResult = false;



                    }
                    //CAPACITOR
                    if (this.objectType == 3) {

                        if (tree.data.objecttype == 1) {

                            if (tree.data.children.length > 0) {
                                let capacitorBankIndex = tree.data.children.findIndex(x => x.objecttype === 3);
                                if (capacitorBankIndex !== -1) {
                                    this.isCapacitorAdded = false;
                                    this.options.allowDrag = true;
                                    this.options.allowDrop = true;
                                    this.addRemoveNode = false;
                                    $('#capacitor-bank-model').modal('show');
                                }
                                else {
                                    $("#SwitchboardHead").hide();
                                    $("#MaxDemandHead").hide();
                                    $("#ArcFlashHead").hide();
                                    $("#LoadHead").hide();

                                    let a_element: HTMLElement = document.getElementById('aCapacitor') as HTMLElement;
                                    a_element.click();

                                    $("#PowerfactorHead").show();
                                    $("#CableSizeHead").show();
                                    $("#CapacitorHead").show();
                                    //     $("#calculationresulttab2").hide();
                                    $('#isCableSizeShown').css('display', 'none');

                                    txt = "Capacitor bank" + parseInt(sessionStorage.getItem("capacitorlist")).toString();
                                    objectcode = "CB";
                                    img = '/assets/cablepro/img/Capacitor_bank.png';
                                    this.showgeneratorResult = false;
                                    this.showFeederResult = false;
                                    this.showPowerStationResult = false;
                                    this.showTransformerResult = false;
                                    this.showPowerFactorResult = false;
                                    this.showSwitchboardResult = false;


                                    let C_treeNodeCountData = parseInt(sessionStorage.getItem("capacitorlist"));
                                    this.maxcount = C_treeNodeCountData + 1;
                                }
                            }
                            else {

                                $("#SwitchboardHead").hide();
                                $("#MaxDemandHead").hide();
                                $("#ArcFlashHead").hide();
                                $("#LoadHead").hide();

                                let a_element: HTMLElement = document.getElementById('aCapacitor') as HTMLElement;
                                a_element.click();

                                $("#PowerfactorHead").show();
                                $("#CableSizeHead").show();
                                $("#CapacitorHead").show();
                                //    $("#calculationresulttab2").hide();
                                $('#isCableSizeShown').css('display', 'none');

                                txt = "Capacitor bank" + parseInt(sessionStorage.getItem("capacitorlist")).toString();
                                objectcode = "CB";
                                img = '/assets/cablepro/img/Capacitor_bank.png';
                                this.showgeneratorResult = false;
                                this.showFeederResult = false;
                                this.showPowerStationResult = false;
                                this.showTransformerResult = false;
                                this.showPowerFactorResult = false;
                                this.showSwitchboardResult = false;


                                let C_treeNodeCountData = parseInt(sessionStorage.getItem("capacitorlist"));
                                this.maxcount = C_treeNodeCountData + 1;
                            }
                        }
                        else {

                            if (tree.parent.data.children.length > 0) {
                                let capacitorBankIndex = tree.parent.data.children.findIndex(x => x.objecttype === 3);
                                if (capacitorBankIndex !== -1) {
                                    this.isCapacitorAdded = false;
                                    this.options.allowDrag = true;
                                    this.options.allowDrop = true;
                                    this.addRemoveNode = false;
                                    $('#capacitor-bank-model').modal('show');
                                }
                                else {
                                    $("#SwitchboardHead").hide();
                                    $("#MaxDemandHead").hide();
                                    $("#ArcFlashHead").hide();
                                    $("#LoadHead").hide();

                                    let a_element: HTMLElement = document.getElementById('aCapacitor') as HTMLElement;
                                    a_element.click();

                                    $("#PowerfactorHead").show();
                                    $("#CableSizeHead").show();
                                    $("#CapacitorHead").show();
                                    $("#calculationresulttab2").hide();
                                    $('#isCableSizeShown').css('display', 'none');

                                    txt = "Capacitor bank" + parseInt(sessionStorage.getItem("capacitorlist")).toString();
                                    objectcode = "CB";
                                    img = '/assets/cablepro/img/Capacitor_bank.png';
                                    this.showgeneratorResult = false;
                                    this.showFeederResult = false;
                                    this.showPowerStationResult = false;
                                    this.showTransformerResult = false;
                                    this.showPowerFactorResult = false;
                                    this.showSwitchboardResult = false;


                                    let C_treeNodeCountData = parseInt(sessionStorage.getItem("capacitorlist"));
                                    this.maxcount = C_treeNodeCountData + 1;
                                }
                            }
                            else {

                                $("#SwitchboardHead").hide();
                                $("#MaxDemandHead").hide();
                                $("#ArcFlashHead").hide();
                                $("#LoadHead").hide();

                                let a_element: HTMLElement = document.getElementById('aCapacitor') as HTMLElement;
                                a_element.click();

                                $("#PowerfactorHead").show();
                                $("#CableSizeHead").show();
                                $("#CapacitorHead").show();
                                //    $("#calculationresulttab2").hide();
                                $('#isCableSizeShown').css('display', 'none');

                                txt = "Capacitor bank" + parseInt(sessionStorage.getItem("capacitorlist")).toString();
                                objectcode = "CB";
                                img = '/assets/cablepro/img/Capacitor_bank.png';
                                this.showgeneratorResult = false;
                                this.showFeederResult = false;
                                this.showPowerStationResult = false;
                                this.showTransformerResult = false;
                                this.showPowerFactorResult = false;
                                this.showSwitchboardResult = false;


                                let C_treeNodeCountData = parseInt(sessionStorage.getItem("capacitorlist"));
                                this.maxcount = C_treeNodeCountData + 1;
                            }
                        }
                    }

                    if (this.isCapacitorAdded) {
                        if (tree.data.objecttype == 2 || tree.data.objecttype == 3) {

                            tree.parent.data.children.push({
                                name: txt,
                                img: img,
                                class: 'normal',
                                childindex: tree.parent.data.children.length,
                                id: this.objectId,
                                objecttype: this.objectType,
                                objectcode: objectcode + this.maxcount,
                                parentid: tree.parent.data.objectcode,
                                children: []
                            });


                        }
                        else {

                            if (tree.data.children != null && tree.data.children.length > 0) {

                                tree.data.children.push({
                                    name: txt,
                                    img: img,
                                    class: 'normal',
                                    childindex: tree.data.children.length,
                                    id: this.objectId,
                                    objecttype: this.objectType,
                                    objectcode: objectcode + this.maxcount,
                                    parentid: tree.data.objectcode,
                                    children: []
                                });
                            }
                            else {
                                tree.data.children = [{
                                    name: txt,
                                    img: img,
                                    class: 'normal',
                                    childindex: 0,
                                    objecttype: this.objectType,
                                    objectcode: objectcode + this.maxcount,
                                    id: this.objectId,
                                    parentid: tree.data.objectcode,
                                    children: []
                                }];
                                tree.data.class = "fa fa-minus-square-o";
                            }
                        }

                        this.objectId = this.objectId + 1;
                        tree.treeModel.update();
                    }

                }
                this.switchboardcount = 0;
                this.loadcount = 0;
                this.capacitorcount = 0;
                this.switchBoardCount(this.items[0]);
                this.capacitorCount(this.items[0]);
                this.loadCount(this.items[0]);
                if (this.isCapacitorAdded) {

                    this.allnodes = [];
                    this.matrix = [];
                    this.getallnodes(this.items[0]);
                    this.printArray(this.items[0]);
                    this.changekeyofmatrix();

                    //Switchboard
                    if (this.objectType == 1) {
                        this.addnewswitchboard(this.objectId - 1);
                    }
                    //Load
                    if (this.objectType == 2) {
                        this.addnewload(this.objectId - 1);
                    }

                    //Capacitor

                    if (this.objectType == 3) {

                        this.addCapacitorbank(this.objectId - 1);

                    }



                    tree.treeModel.getNodeById(this.objectId - 1)
                        .setActiveAndVisible();
                    sessionStorage.removeItem("currentnode");
                    var currentNodeIndexData = tree.treeModel.getNodeById(this.objectId - 1)
                    this.selectedNetworkData = currentNodeIndexData.data;
                    this.selectedTreeNode = this.selectedNetworkData;
                    this.activeNode = undefined;
                    this.cd.detectChanges();
                    this.activeNode = this.selectedTreeNode;
                    sessionStorage.setItem("currentnode", JSON.stringify(this.selectedTreeNode));
                    this.isInputAPIStatus = false;
                }
                $('#deratingFactor').val(1);
                this.CableSize['deratingfactor'] = 1;
                this.UserWizard = false;
                this.CableSize["isderatingwiz"] = "0";
                // $('#checkboxUseWizard').removeAttr('checked');
                this.protectiveUnknown = true;
                this.protectionConfigurationModel = new ProtectionConfigurationModel();
                this.saveDefaultProtectiveDetail(this.activeNode.objectcode, () => {
                    this.index = 0;
                    this.getNetworkProtectiveDataByNodeId(this.newCalculationId, this.activeNode.objectcode);
                });// Add default data to ECPNetworkProtectiveCalcDetail table for this node

            }

        }
        this.loadProtectiveXmlInput = new ProtectiveXmlInput();
        this.defaultManId = 104;
        // this.defaultFamilyId = "";
        // this.defaultModelId = 0;
        // this.FilterBreaking = 5;    
        // this.defaultRatingId= "100";
        // this.defaultCurveId = "";

    }

    //INUSE
    addCapacitorbank(capacitorID) {
        this.isCableSizeCalcStatus = true;
        let count = parseInt(sessionStorage.getItem("capacitorlist"));
        let treenodecount = parseInt(sessionStorage.getItem("nodecount"));

        let tempNetworkCapacitorBank = new NetworkCapacitorBank();

        $('#NetworkCapacitorBank_Name').val('Capacitor bank' + count.toString());


        $('#NetworkCapacitorBank_Power_Factor_After_Correction').val(this.powerFactorAfterCorrection);
        $('#NetworkCapacitorBank_Power_Factor_Before_Correction').val(this.powerFactorBeforeCorrection);
        $('#NetworkCapacitorBank_Rating').val(this.capacitorRating);
        $('#NetworkCapacitorBank_Required_Compensation').val(this.requiredCompensation);


        tempNetworkCapacitorBank.NetworkCapacitorBank_Id = capacitorID;
        tempNetworkCapacitorBank.NetworkCapacitorBank_Node_Id = treenodecount.toString();
        tempNetworkCapacitorBank.NetworkCapacitorBank_Name = $('#NetworkCapacitorBank_Name').val().toString();
        tempNetworkCapacitorBank.NetworkCapacitorBank_Matrix_Node = 'CB' + (count + 1);

        tempNetworkCapacitorBank.NetworkCapacitorBank_Power_Factor_After_Correction = this.powerFactorAfterCorrection;
        tempNetworkCapacitorBank.NetworkCapacitorBank_Power_Factor_Before_Correction = this.powerFactorBeforeCorrection;
        tempNetworkCapacitorBank.NetworkCapacitorBank_Rating = this.capacitorRating;
        tempNetworkCapacitorBank.NetworkCapacitorBank_Required_Compensation = this.requiredCompensation;

        this.tempinputXML.CapacitorBanks = [];
        this.tempcapacitorlist.push(tempNetworkCapacitorBank);
        this.tempinputXML.CapacitorBanks = this.tempcapacitorlist;

        this.addPowerFactorCorrection(tempNetworkCapacitorBank);

        this.addcablesize(tempNetworkCapacitorBank, 'CapacitorBank');

        this.onInputChange();

        sessionStorage.setItem("CapacitorNode", tempNetworkCapacitorBank.NetworkCapacitorBank_Matrix_Node);
        sessionStorage.setItem("capacitorlist", (count + 1).toString());
        sessionStorage.setItem("nodecount", (treenodecount + 1).toString());

    }

    //INUSE
    updateCapacitorBank() {

        let tempNetworkCapacitorBank = new NetworkCapacitorBank();
        let currentnode = (JSON.parse(sessionStorage.getItem("currentnode")));



        this.tempcapacitorlist = this.tempinputXML.CapacitorBanks;

        this.tempcapacitorlist.forEach(elementx => {
            if (elementx.NetworkCapacitorBank_Node_Id == currentnode.id || elementx.NetworkCapacitorBank_Matrix_Node == currentnode.objectcode) {
                // alert(elementx.NetworkCapacitorBank_Node_Id.toString()+"    "+currentnode.id.toString());
                elementx.NetworkCapacitorBank_Name = $('#NetworkCapacitorBank_Name').val();
            }
        });

        this.tempinputXML.CapacitorBanks = this.tempcapacitorlist;

        // this.updatecablesizeinarray();
    }

    //INUSE
    getCapacitorbank(nodeitem: any, isFromTreeNode: boolean = false) {

        let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));

        this.tempcapacitorlist = this.tempinputXML.CapacitorBanks;

        let tempcapacitorBank = this.tempcapacitorlist.find(x => x.NetworkCapacitorBank_Node_Id == selectednode.id || x.NetworkCapacitorBank_Matrix_Node == selectednode.objectcode);

        $('#NetworkCapacitorBank_Name').val(tempcapacitorBank.NetworkCapacitorBank_Name);

        this.getPowerFactorCorrection();
        this.getcablesize(nodeitem.id, nodeitem.objectcode, isFromTreeNode);


    }


    //INUSE
    addPowerFactorCorrection(tempobj: any) {

        let tempPFC = new PowerFactorCorrection();

        let count = parseInt(sessionStorage.getItem("powerfactorlist"));

        let powerFactor = "0.9";
        let customRating = "10";
        let installationCost = "3000";
        $('#PFC_Target_Power_Factor').val(powerFactor);
        $('#PFC_Auto_Size_Capacitor_Bank').prop('checked', true).trigger('change', 'true');
        $('#powerfactorinstall').css('display', 'none');
        $('#PFC_Capacitor_Bank_Custom_Rating_Cell1').hide();
        $('#PFC_Capacitor_Bank_Custom_Rating_Cell2').hide();
        $('#PFC_Capacitor_Bank_To_Install').val(customRating).trigger('change', 'true');
        $('#PFC_Capacitor_Bank_Custom_Rating').val(20);
        $('#PFC_Economic_Calculation').prop('checked', false).trigger('change', 'true');
        $('#PFC_Installation_Cost').val(installationCost);
        $('#PFC_Demand_Charge').val("30");
        $('#PFC_Utility_Factor').val("80");
        $('#PFC_Benefit_Time_Period').val("7");
        $('#PFC_Interest_Rate').val("7");




        tempPFC.PFC_Id = count.toString();
        tempPFC.Node_Id = tempobj.NetworkCapacitorBank_Node_Id; //count.toString().trim();

        tempPFC.Node_Prefix = tempobj.NetworkCapacitorBank_Matrix_Node;
        tempPFC.Node_Name = tempobj.NetworkCapacitorBank_Name;

        tempPFC.PFC_Target_Power_Factor = $('#PFC_Target_Power_Factor').val().toString();
        tempPFC.PFC_Auto_Size_Capacitor_Bank = "1";
        tempPFC.PFC_Capacitor_Bank_To_Install = $('#PFC_Capacitor_Bank_To_Install').val().toString();
        tempPFC.PFC_Capacitor_Bank_Custom_Rating = parseFloat($('#PFC_Capacitor_Bank_Custom_Rating').val().toString());

        tempPFC.PFC_Economic_Calculation = "0";

        tempPFC.PFC_Installation_Cost = parseInt($('#PFC_Installation_Cost').val().toString());
        tempPFC.PFC_Demand_Charge = parseInt($('#PFC_Demand_Charge').val().toString());
        tempPFC.PFC_Utility_Factor = parseInt($('#PFC_Utility_Factor').val().toString());
        tempPFC.PFC_Benefit_Time_Period = parseInt($('#PFC_Benefit_Time_Period').val().toString());
        tempPFC.PFC_Interest_Rate = parseInt($('#PFC_Interest_Rate').val().toString());

        this.temppowerfactorcorrections.push(tempPFC);
        this.tempinputXML.PowerFactorCorrections = this.temppowerfactorcorrections;


        sessionStorage.setItem("powerfactorlist", (count + 1).toString());

    }

    //INUSE
    getPowerFactorCorrection() {
        let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));

        // this.temppowerfactorlist = this.tempinputXML.PowerFactorCorrections;

        // let tempPFCobj = this.tempinputXML.PowerFactorCorrections[0];
        let tempPFCobj = this.tempinputXML.PowerFactorCorrections.find(x => x.Node_Id == selectednode.id || x.Node_Prefix == selectednode.objectcode);

        if (tempPFCobj != undefined) {
            if (tempPFCobj.PFC_Target_Power_Factor != undefined) {
                $('#PFC_Target_Power_Factor').val(tempPFCobj.PFC_Target_Power_Factor);
            }

            if (tempPFCobj.PFC_Auto_Size_Capacitor_Bank != undefined) {
                if (tempPFCobj.PFC_Auto_Size_Capacitor_Bank == "1") {
                    $('#PFC_Auto_Size_Capacitor_Bank').prop('checked', true).trigger('change', 'true');
                    $('#powerfactorinstall').css('display', 'none');
                }
                else {
                    $('#PFC_Auto_Size_Capacitor_Bank').prop('checked', false);
                    $('#powerfactorinstall').css('display', 'block');
                }
                // $('#PFC_Auto_Size_Capacitor_Bank').val(tempPFCobj.PFC_Auto_Size_Capacitor_Bank);
            }


            if (tempPFCobj.PFC_Capacitor_Bank_To_Install != undefined) {
                let customIndex = this.capacitorRatingArray.findIndex(x => x.value === tempPFCobj.PFC_Capacitor_Bank_To_Install);
                if (customIndex == -1) {
                    $('#PFC_Capacitor_Bank_Custom_Rating_Cell1').show();
                    $('#PFC_Capacitor_Bank_Custom_Rating_Cell2').show();

                    let customRating = "11";
                    $('#PFC_Capacitor_Bank_To_Install').val(customRating).trigger('change', 'true');
                    if (tempPFCobj.PFC_Capacitor_Bank_Custom_Rating != undefined) {
                        $('#PFC_Capacitor_Bank_Custom_Rating').val(tempPFCobj.PFC_Capacitor_Bank_Custom_Rating);
                    }
                }
                else {
                    $('#PFC_Capacitor_Bank_Custom_Rating_Cell1').hide();
                    $('#PFC_Capacitor_Bank_Custom_Rating_Cell2').hide();
                    let customRating = "20";
                    $('#PFC_Capacitor_Bank_To_Install').val(tempPFCobj.PFC_Capacitor_Bank_To_Install).trigger('change', 'true');
                    $('#PFC_Capacitor_Bank_Custom_Rating').val(customRating);
                }

            }

            $('#PFC_Economic_Calculation').prop('checked', false).trigger('change', 'true');
            $('#economiccalculationgroup').css('display', 'none');

            if (tempPFCobj.PFC_Economic_Calculation != undefined) {
                if (tempPFCobj.PFC_Economic_Calculation == "1") {
                    $('#PFC_Economic_Calculation').prop('checked', true);
                    $('#economiccalculationgroup').css('display', 'block');
                }
            }


            if (tempPFCobj.PFC_Installation_Cost != undefined) {
                $('#PFC_Installation_Cost').val(tempPFCobj.PFC_Installation_Cost);
            }
            if (tempPFCobj.PFC_Demand_Charge != undefined) {
                $('#PFC_Demand_Charge').val(tempPFCobj.PFC_Demand_Charge);
            }
            if (tempPFCobj.PFC_Utility_Factor != undefined) {
                $('#PFC_Utility_Factor').val(tempPFCobj.PFC_Utility_Factor);
            }
            if (tempPFCobj.PFC_Benefit_Time_Period != undefined) {
                $('#PFC_Benefit_Time_Period').val(tempPFCobj.PFC_Benefit_Time_Period);
            }
            if (tempPFCobj.PFC_Interest_Rate != undefined) {
                $('#PFC_Interest_Rate').val(tempPFCobj.PFC_Interest_Rate);
            }

        }

    }

    //INUSE
    updatePowerFactorCorrection() {

        let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));

        this.temppowerfactorlist = this.tempinputXML.PowerFactorCorrections;

        this.temppowerfactorlist.forEach(
            itemnode => {
                if (itemnode.Node_Id == selectednode.id || itemnode.Node_Prefix == selectednode.objectcode) {

                    itemnode.PFC_Target_Power_Factor = $('#PFC_Target_Power_Factor').val().toString();

                    itemnode.PFC_Auto_Size_Capacitor_Bank = "1";
                    if ($('#PFC_Auto_Size_Capacitor_Bank').is(":checked") == false) {
                        //Dropdown
                        itemnode.PFC_Auto_Size_Capacitor_Bank = "0";

                        itemnode.PFC_Capacitor_Bank_To_Install = $('#PFC_Capacitor_Bank_To_Install').val().toString();

                    }


                    if ($('#PFC_Capacitor_Bank_Custom_Rating').val() != undefined) {

                        if ($('#PFC_Capacitor_Bank_To_Install').val().toString() != '11') {
                            itemnode.PFC_Capacitor_Bank_Custom_Rating = $('#PFC_Capacitor_Bank_To_Install').val().toString();
                        }
                        else if ($('#PFC_Capacitor_Bank_To_Install').val().toString() == '11') {
                            itemnode.PFC_Capacitor_Bank_Custom_Rating = $('#PFC_Capacitor_Bank_Custom_Rating').val().toString();
                            itemnode.PFC_Capacitor_Bank_To_Install = $('#PFC_Capacitor_Bank_Custom_Rating').val().toString();
                        }
                    }


                    itemnode.PFC_Economic_Calculation = "1";
                    if ($('#PFC_Economic_Calculation').is(":checked") == false) {
                        itemnode.PFC_Economic_Calculation = "0";
                    }

                    itemnode.PFC_Installation_Cost = $('#PFC_Installation_Cost').val().toString();
                    itemnode.PFC_Demand_Charge = $('#PFC_Demand_Charge').val().toString();
                    itemnode.PFC_Utility_Factor = $('#PFC_Utility_Factor').val().toString();
                    itemnode.PFC_Benefit_Time_Period = $('#PFC_Benefit_Time_Period').val().toString();
                    itemnode.PFC_Interest_Rate = $('#PFC_Interest_Rate').val().toString();

                }
            });


        this.tempinputXML.PowerFactorCorrections = this.temppowerfactorlist;

    }

    //INUSE
    checkHVRelay(e) {
        // e is a boolean, true if checked, otherwise false
        var supplyData = sessionStorage.getItem('SupplyType');
        if (e.target.checked) {
            this.isHVRelay = true;
            if (this.tempinputXML.NetworkHVRelay === undefined) {
                this.addHVRelay();
            }
            if (supplyData === "Transformer") {
                this.transformerHVRelay = true;
            }
            if (supplyData === "Power Factor") {
                this.powerHVRelay = true;
            }
            //$('#HVRelayHead').css('display', 'block');
            this.getHVRelay();
        }
        else if (!e.target.checked) {
            this.isHVRelay = false;
            //$('#HVRelayHead').css('display', 'none');
            if (supplyData === "Transformer") {
                this.transformerHVRelay = false;
            }
            if (supplyData === "Power Factor") {
                this.powerHVRelay = false;
            }

        }

        this.onInputChange();

    }

    checkHVFuse(e) {
        if (e.target.checked) {
            if (this.cableCalcProtectiveDeviceSource == "2") {
                this.HVFuse = true;
                this.onInputChange();
            }
            else {
                this.checkingHVFuse = true;
                this.openHvFuseSelectorPopup();
            }
        }
        else if (!e.target.checked) {
            this.HVFuse = false;
            this.deleteProtectionData(this.items[0].objectcode, false);
            this.onInputChange();
        }
    }

    openHvFuseSelectorPopup() {
        this.addingDeviceForHvFuse = true;
        this.showProtectiveDeviceUI("1");
    }

    //INUSE
    addHVRelay() {
        let tempHVRelay = new NetworkHVRelay();

        tempHVRelay.HVRelay_Id = 0;
        tempHVRelay.HVRelay_Curve_Equation = $('#HVRelay_Curve_Equation').val().toString();

        if (tempHVRelay.HVRelay_Curve_Equation !== "3") {
            tempHVRelay.HVRelay_Curve_Type = parseInt($('#HVRelay_Curve_Type').val().toString());
        }
        else {
            tempHVRelay.HVRelay_Curve_Type = 1;
        }

        tempHVRelay.HVRelay_Pick_Up_Current = parseFloat($('#HVRelay_Pick_Up_Current').val().toString());
        tempHVRelay.HVRelay_TMS_TDS = parseFloat($('#HVRelay_TMS_TDS').val().toString());
        tempHVRelay.HVRelay_Max_Disconnection_Time = parseFloat($('#HVRelay_Max_Disconnection_Time').val().toString());
        tempHVRelay.HVRelay_Tipping_Current_Max_Disconnection_Time = parseFloat($('#HVRelay_Tipping_Current_Max_Disconnection_Time').val().toString()); //parseFloat($('#HVRelay_Tipping_Current_Max_Disconnection_Time').val().toString());
        tempHVRelay.HVRelay_Tolerance = parseFloat($('#HVRelay_Tolerance').val().toString()); //parseFloat($('#HVRelay_Tipping_Current_Max_Disconnection_Time').val().toString());        

        this.temphvrelaylist.push(tempHVRelay);
        this.tempinputXML.NetworkHVRelay = this.temphvrelaylist;
    }

    //INUSE
    getHVRelay() {
        let tempHVRelayobj = this.tempinputXML.NetworkHVRelay[0];

        $('#HVRelay_Curve_Equation').val(tempHVRelayobj.HVRelay_Curve_Equation);

        $('#HVRelay_Curve_Type').val(tempHVRelayobj.HVRelay_Curve_Type);
        $('#HVRelay_Pick_Up_Current').val(tempHVRelayobj.HVRelay_Pick_Up_Current);
        $('#HVRelay_TMS_TDS').val(tempHVRelayobj.HVRelay_TMS_TDS);
        $('#HVRelay_Max_Disconnection_Time').val(tempHVRelayobj.HVRelay_Max_Disconnection_Time);
        $('#HVRelay_Tolerance').val(tempHVRelayobj.HVRelay_Tolerance);
        $('#HVRelay_Tipping_Current_Max_Disconnection_Time').val(this.HVRelayTrippingCurrentValue);


    }

    //INUSE
    updateHVRelay() {

        let tempHVRelayobj = this.tempinputXML.NetworkHVRelay[0];

        tempHVRelayobj.HVRelay_Curve_Equation = $('#HVRelay_Curve_Equation').val().toString();
        var curveEquation = $('#HVRelay_Curve_Equation').val().toString();

        if (curveEquation !== "3") {
            tempHVRelayobj.HVRelay_Curve_Type = parseInt($('#HVRelay_Curve_Type').val().toString());
        }

        tempHVRelayobj.HVRelay_Pick_Up_Current = parseFloat($('#HVRelay_Pick_Up_Current').val().toString());
        tempHVRelayobj.HVRelay_TMS_TDS = parseFloat($('#HVRelay_TMS_TDS').val().toString());
        tempHVRelayobj.HVRelay_Max_Disconnection_Time = parseFloat($('#HVRelay_Max_Disconnection_Time').val().toString());
        //parseFloat($('#HVRelay_Tipping_Current_Max_Disconnection_Time').val().toString());
        tempHVRelayobj.HVRelay_Tipping_Current_Max_Disconnection_Time = parseFloat($('#HVRelay_Tipping_Current_Max_Disconnection_Time').val().toString());
        tempHVRelayobj.HVRelay_Tolerance = parseFloat($('#HVRelay_Tolerance').val().toString());

        this.tempinputXML.NetworkHVRelay[0] = tempHVRelayobj;

    }

    //INUSE
    addcablesize(tempobj: any, nodetype: string) {

        let tempcablesizeinput = new CableSizeCalcInput();
        let tempCableSizeCalcInputXML = {};

        let count = parseInt(sessionStorage.getItem("cablesizelist"));
        let treenodecount = parseInt(sessionStorage.getItem("nodecount"));
        tempcablesizeinput.CableSizeCalcInputId = treenodecount;

        if (nodetype == 'Switchboard') {

            tempcablesizeinput.Node_Id = treenodecount.toString();
            tempcablesizeinput.Node_Prefix = tempobj.SwitchBoard_Matrix_Node;
            tempcablesizeinput.Node_Name = tempobj.SwitchBoard_Name;

        }
        else if (nodetype == 'Load') {

            tempcablesizeinput.Node_Id = treenodecount.toString();
            tempcablesizeinput.Node_Prefix = tempobj.Load_Matrix_Node;
            tempcablesizeinput.Node_Name = tempobj.Load_Name;

        }
        else if (nodetype == 'CapacitorBank') {

            tempcablesizeinput.Node_Id = treenodecount.toString();
            tempcablesizeinput.Node_Prefix = tempobj.NetworkCapacitorBank_Matrix_Node;
            tempcablesizeinput.Node_Name = tempobj.NetworkCapacitorBank_Name;

        }
        else {

        }


        tempcablesizeinput.CableSizeId = "C" + (treenodecount - 1);
        tempcablesizeinput.DeviceName = this.deviceNumber;

        tempCableSizeCalcInputXML = {
            insulation: '0',
            conductor: '0',
            earthconductor: '0',
            core: '0',
            phase: '1',
            faultlevel: '3',
            supplyvoltage: '',
            voltagedrop: '2.5',
            powerunit: '1',
            efficiency: '100',
            lengthofrun: '50',
            current: '100',
            powerfactor: '0.9',
            unit: '0',
            deratingfactor: '1',
            device: '0',
            usevd: '1',
            isVoltageDrop: true,
            protection: {
                shortcircuittime: '0.1',
                MCBrating: '0',
                ACBrating: '0',
                HRCFuserating: '0',
                MCCBrating: '0',
                curve: '0',
                earthfaulttime: '0',
                rcd: '0',
                tripcurrent: '0',
                tripmultiplier: '0',
                thermalsetting: '0',
                customrating: '0',
                description: '0',
                IsUnknown: true,
                Xml: undefined,
                protectionConfigurationModel: {},
                TrippingXml: undefined
            },
            installtablewind: { wind: '' },
            specifyconductor: {
                phase: {
                    mode: '0',
                    size: '0',
                    circuits: '1'
                },
                neutral: {
                    mode: '0',
                    size: '0',
                    circuits: '1'
                },
                earth: {
                    mode: '1',
                    size: '0',
                    circuits: '1'
                }
            },
            tableno: '7',
            currentTable: '4D1A,2',
            voltagedropTable: '4D1B,3',
            armour: '0',
            columnno: '0',
            startingrowindex: '0',
            endingrowindex: '0',
            description: 'Unenclosed Spaced',
            tablectrlvalue: '0',
            voltagefactor: '1.1',
            calculationstandard: this.cableCalculationStandard, //'0'
            cableCalcProtectiveDeviceSource: this.cableCalcProtectiveDeviceSource,
            arcFlashData: {},
            isderatingwiz: '0',
            deratingtemp: ''
        };
        this.CableproInput.PhaseId = 101;
        tempcablesizeinput.CableSizeCalcInputXML = tempCableSizeCalcInputXML;
        this.tempcablesizelist.push(tempcablesizeinput);

        this.tempinputXML.CableSizeCalcInputs = JSON.stringify(this.tempcablesizelist);
        this.isCableSizeModified = true;

        sessionStorage.setItem("cablesizelist", (count + 1).toString());
        sessionStorage.setItem("nodecount", (treenodecount + 1).toString());


        if (treenodecount >= 3) {
            this.CableSizeCount = treenodecount - 1;
            sessionStorage.setItem("NodesCableSize", ("C" + this.CableSizeCount).toString());
        }
        else {
            this.CableSizeCount = 1;
            sessionStorage.setItem("NodesCableSize", ("C" + this.CableSizeCount).toString());
        }
        this.getcablesize(tempcablesizeinput.CableSizeCalcInputId, tempcablesizeinput.Node_Prefix);

    }

    //INUSE
    getcablesize(NodeID, node_Prefix, isFromTreeNode: boolean = false) {
        this.tempcablesizelist = JSON.parse(this.tempinputXML.CableSizeCalcInputs);
        let tempobj = this.tempcablesizelist.find(x => x.CableSizeCalcInputId === NodeID || x.Node_Prefix == node_Prefix);

        if (tempobj !== undefined) {

            this.cableSizeReopenData = tempobj.CableSizeCalcInputXML['protection'];

            this.flag = false;
            this.getCableSizeSectionsValue(tempobj, NodeID, isFromTreeNode);



        }
    }

    getCableSizeSectionsValue(objData, NodeID, isFromTreeNode: boolean = false) {

        this.CableSize = {
            insulation: '0',
            earthconductor: '0',
            conductor: '0',
            core: '0',
            phase: '1',
            faultlevel: '3',
            supplyvoltage: '',
            voltagedrop: '2.5',
            powerunit: '1',
            efficiency: '100',
            lengthofrun: '50',
            current: '100',
            powerfactor: '0.9',
            unit: '0',
            deratingfactor: '1',
            device: '0',
            usevd: '1',
            isVoltageDrop: true,
            protection: {
                shortcircuittime: '0.1',
                MCBrating: '0',
                ACBrating: '0',
                HRCFuserating: '0',
                MCCBrating: '0',
                curve: '0',
                earthfaulttime: '0',
                rcd: '0',
                tripcurrent: '0',
                tripmultiplier: '0',
                thermalsetting: '0',
                customrating: '0',
                description: '0',
                IsUnknown: true,
                Xml: undefined,
                TrippingXml: undefined,
                protectionConfigurationModel: {}
            },
            installtablewind: { wind: '' },
            specifyconductor: {
                phase: {
                    mode: '0',
                    size: '0',
                    circuits: '1'
                },
                neutral: {
                    mode: '0',
                    size: '0',
                    circuits: '1'
                },
                earth: {
                    mode: '1',
                    size: '0',
                    circuits: '1'
                }
            },
            tableno: '7',
            currentTable: '4D1A,2',
            voltagedropTable: '4D1B,3',
            armour: '0',
            columnno: '0',
            startingrowindex: '0',
            endingrowindex: '0',
            description: 'Unenclosed Spaced',
            tablectrlvalue: '0',
            voltagefactor: '1.1',
            calculationstandard: '0',
            arcFlashData: {},
            isderatingwiz: '0',
            deratingtemp: ''
        };
        this.CableSize['dcvoltage'] = objData.CableSizeCalcInputXML['dcvoltage'];
        this.CableSize['onephasevoltage'] = objData.CableSizeCalcInputXML['onephasevoltage'];
        this.CableSize['threephasevoltage'] = objData.CableSizeCalcInputXML['threephasevoltage'];
        this.CableSize['insulation'] = objData.CableSizeCalcInputXML['insulation'];
        this.CableSize['conductor'] = objData.CableSizeCalcInputXML['conductor'];
        this.CableSize['earthconductor'] = objData.CableSizeCalcInputXML['earthconductor'];
        this.CableSize['core'] = objData.CableSizeCalcInputXML['core'];
        this.CableSize['armour'] = objData.CableSizeCalcInputXML['armour'];
        this.CableSize['phase'] = objData.CableSizeCalcInputXML['phase'];
        this.CableSize['faultlevel'] = objData.CableSizeCalcInputXML['faultlevel'];


        this.CableSize['voltagedrop'] = objData.CableSizeCalcInputXML['voltagedrop'];
        this.CableSize['powerunit'] = objData.CableSizeCalcInputXML['powerunit'];
        this.CableSize['efficiency'] = objData.CableSizeCalcInputXML['efficiency'];

        this.CableSize['lengthofrun'] = objData.CableSizeCalcInputXML['lengthofrun'];
        this.CableSize['current'] = objData.CableSizeCalcInputXML['current'];
        this.CableSize['powerfactor'] = objData.CableSizeCalcInputXML['powerfactor'];
        this.CableSize['unit'] = objData.CableSizeCalcInputXML['unit'];
        this.CableSize['deratingfactor'] = objData.CableSizeCalcInputXML['deratingfactor'];
        this.CableSize['device'] = objData.CableSizeCalcInputXML['device'];
        this.CableSize['devicename'] = objData.CableSizeCalcInputXML['devicename'];

        this.CableSize['usevd'] = objData.CableSizeCalcInputXML['usevd'];
        this.CableSize['isVoltageDrop'] = objData.CableSizeCalcInputXML['isVoltageDrop'];

        this.CableSize['protection']['shortcircuittime'] = objData.CableSizeCalcInputXML['protection']['shortcircuittime'];
        this.CableSize['protection']['MCBrating'] = objData.CableSizeCalcInputXML['protection']['MCBrating'];
        this.CableSize['protection']['ACBrating'] = objData.CableSizeCalcInputXML['protection']['ACBrating'];
        this.CableSize['protection']['HRCFuserating'] = objData.CableSizeCalcInputXML['protection']['HRCFuserating'];
        this.CableSize['protection']['MCCBrating'] = objData.CableSizeCalcInputXML['protection']['MCCBrating'];
        this.CableSize['protection']['curve'] = objData.CableSizeCalcInputXML['protection']['curve'];
        this.CableSize['protection']['earthfaulttime'] = objData.CableSizeCalcInputXML['protection']['earthfaulttime'];
        this.CableSize['protection']['rcd'] = objData.CableSizeCalcInputXML['protection']['rcd'];
        this.CableSize['protection']['tripcurrent'] = objData.CableSizeCalcInputXML['protection']['tripcurrent'];
        this.CableSize['protection']['tripmultiplier'] = objData.CableSizeCalcInputXML['protection']['tripmultiplier'];
        this.CableSize['protection']['thermalsetting'] = objData.CableSizeCalcInputXML['protection']['thermalsetting'];
        this.CableSize['protection']['customrating'] = objData.CableSizeCalcInputXML['protection']['customrating'];
        this.CableSize['protection']['description'] = objData.CableSizeCalcInputXML['protection']['description'];
        this.CableSize['protection']['IsUnknown'] = objData.CableSizeCalcInputXML['protection']['IsUnknown'];
        this.CableSize['protection']['protectionConfigurationModel'] = objData.CableSizeCalcInputXML['protection']['protectionConfigurationModel'];
        this.CableSize['protection']['Xml'] = objData.CableSizeCalcInputXML['protection']['Xml'];
        this.CableSize['protection']['TrippingXml'] = objData.CableSizeCalcInputXML['protection']['TrippingXml'];

        this.CableSize['installtablewind']['wind'] = objData.CableSizeCalcInputXML['installtablewind']['wind'];


        this.CableSize['specifyconductor']['phase']['mode'] = objData.CableSizeCalcInputXML['specifyconductor']['phase']['mode'];
        this.CableSize['specifyconductor']['phase']['circuits'] = objData.CableSizeCalcInputXML['specifyconductor']['phase']['circuits'];
        this.CableSize['specifyconductor']['phase']['size'] = objData.CableSizeCalcInputXML['specifyconductor']['phase']['size'];

        this.CableSize['specifyconductor']['neutral']['circuits'] = objData.CableSizeCalcInputXML['specifyconductor']['neutral']['circuits'];
        this.CableSize['specifyconductor']['neutral']['size'] = objData.CableSizeCalcInputXML['specifyconductor']['neutral']['size'];
        this.CableSize['specifyconductor']['neutral']['mode'] = objData.CableSizeCalcInputXML['specifyconductor']['neutral']['mode'];

        this.CableSize['specifyconductor']['earth']['mode'] = objData.CableSizeCalcInputXML['specifyconductor']['earth']['mode'];
        this.CableSize['specifyconductor']['earth']['size'] = objData.CableSizeCalcInputXML['specifyconductor']['earth']['size'];
        this.CableSize['specifyconductor']['earth']['circuits'] = objData.CableSizeCalcInputXML['specifyconductor']['earth']['circuits'];

        this.CableSize['tableno'] = objData.CableSizeCalcInputXML['tableno'];
        this.CableSize['currentTable'] = objData.CableSizeCalcInputXML['currentTable'];
        this.CableSize['voltagedropTable'] = objData.CableSizeCalcInputXML['voltagedropTable'];
        this.CableSize['columnno'] = objData.CableSizeCalcInputXML['columnno'];
        this.CableSize['startingrowindex'] = objData.CableSizeCalcInputXML['startingrowindex'];
        this.CableSize['endingrowindex'] = objData.CableSizeCalcInputXML['endingrowindex'];
        this.CableSize['description'] = objData.CableSizeCalcInputXML['description'];
        this.CableSize['tablectrlvalue'] = objData.CableSizeCalcInputXML['tablectrlvalue'];
        this.CableSize['voltagefactor'] = objData.CableSizeCalcInputXML['voltagefactor'];
        this.CableSize['calculationstandard'] = objData.CableSizeCalcInputXML['calculationstandard'];
        this.CableSize['arcFlashData'] = objData.CableSizeCalcInputXML['arcFlashData'];
        this.CableSize['isderatingwiz'] = objData.CableSizeCalcInputXML['isderatingwiz'];
        this.CableSize['deratingtemp'] = objData.CableSizeCalcInputXML['deratingtemp'];


        let wind = 0;
        if (this.CableSize['installtablewind'] && this.CableSize['installtablewind']['wind'] && this.cableCalculationStandard != '2')
            wind = this.CableSize['installtablewind']['wind'];

        this.GetCableSizeData('Load', '', this.CableSize['insulation'], this.CableSize['conductor']
            , this.CableSize['core'], this.CableSize['phase'], this.CableSize['tablectrlvalue'], 0, wind, '', '', this.newCalculationId, 0, 0, this.CableSize['armour'], objData, isFromTreeNode, this.CableSize['earthconductor']);


    }
    //INUSE
    updatecablesizeinarray() {

        let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));

        this.tempcablesizelist = JSON.parse(this.tempinputXML.CableSizeCalcInputs);

        this.tempcablesizelist.forEach(
            itemnode => {
                if (itemnode.Node_Id == selectednode.id || itemnode.Node_Prefix == selectednode.objectcode) {
                    itemnode.CableSizeCalcInputXML['dcvoltage'] = this.CableSize['dcvoltage'];
                    itemnode.CableSizeCalcInputXML['onephasevoltage'] = this.CableSize['onephasevoltage'];
                    itemnode.CableSizeCalcInputXML['threephasevoltage'] = this.CableSize['threephasevoltage'];
                    itemnode.CableSizeCalcInputXML['insulation'] = this.CableSize['insulation'];
                    itemnode.CableSizeCalcInputXML['conductor'] = this.CableSize['conductor'];
                    itemnode.CableSizeCalcInputXML['earthconductor'] = this.CableSize['earthconductor'];
                    itemnode.CableSizeCalcInputXML['core'] = this.CableSize['core'];
                    itemnode.CableSizeCalcInputXML['armour'] = this.CableSize['armour'];
                    itemnode.CableSizeCalcInputXML['phase'] = this.CableSize['phase'];
                    itemnode.CableSizeCalcInputXML['faultlevel'] = this.CableSize['faultlevel'];
                    itemnode.CableSizeCalcInputXML['supplyvoltage'] = this.CableSize['supplyvoltage'];
                    itemnode.CableSizeCalcInputXML['voltagedrop'] = this.CableSize['voltagedrop'];
                    itemnode.CableSizeCalcInputXML['powerunit'] = this.CableSize['powerunit'];
                    itemnode.CableSizeCalcInputXML['efficiency'] = this.CableSize['efficiency'];

                    itemnode.CableSizeCalcInputXML['lengthofrun'] = this.CableSize['lengthofrun'];
                    itemnode.CableSizeCalcInputXML['current'] = this.CableSize['current'];
                    itemnode.CableSizeCalcInputXML['powerfactor'] = this.CableSize['powerfactor'];
                    itemnode.CableSizeCalcInputXML['unit'] = this.CableSize['unit'];
                    itemnode.CableSizeCalcInputXML['deratingfactor'] = this.CableSize['deratingfactor'];
                    itemnode.CableSizeCalcInputXML['device'] = this.CableSize['device'];
                    itemnode.CableSizeCalcInputXML['devicename'] = this.CableSize['devicename'];

                    itemnode.CableSizeCalcInputXML['usevd'] = this.CableSize['usevd'];
                    itemnode.CableSizeCalcInputXML['isVoltageDrop'] = this.CableSize['isVoltageDrop'];
                    //isVoltageDrop
                    itemnode.CableSizeCalcInputXML['protection']['shortcircuittime'] = this.CableSize['protection']['shortcircuittime'];
                    itemnode.CableSizeCalcInputXML['protection']['MCBrating'] = this.CableSize['protection']['MCBrating'];
                    itemnode.CableSizeCalcInputXML['protection']['ACBrating'] = this.CableSize['protection']['ACBrating'];
                    itemnode.CableSizeCalcInputXML['protection']['HRCFuserating'] = this.CableSize['protection']['HRCFuserating'];
                    itemnode.CableSizeCalcInputXML['protection']['MCCBrating'] = this.CableSize['protection']['MCCBrating'];
                    itemnode.CableSizeCalcInputXML['protection']['curve'] = this.CableSize['protection']['curve'];
                    itemnode.CableSizeCalcInputXML['protection']['earthfaulttime'] = this.CableSize['protection']['earthfaulttime'];
                    itemnode.CableSizeCalcInputXML['protection']['rcd'] = this.CableSize['protection']['rcd'];
                    itemnode.CableSizeCalcInputXML['protection']['tripcurrent'] = this.CableSize['protection']['tripcurrent'];
                    itemnode.CableSizeCalcInputXML['protection']['tripmultiplier'] = this.CableSize['protection']['tripmultiplier'];
                    itemnode.CableSizeCalcInputXML['protection']['thermalsetting'] = this.CableSize['protection']['thermalsetting'];
                    itemnode.CableSizeCalcInputXML['protection']['customrating'] = this.CableSize['protection']['customrating'];
                    itemnode.CableSizeCalcInputXML['protection']['description'] = this.CableSize['protection']['description'];
                    itemnode.CableSizeCalcInputXML['protection']['IsUnknown'] = this.CableSize['protection']['IsUnknown'];
                    itemnode.CableSizeCalcInputXML['protection']['protectionConfigurationModel'] = this.CableSize['protection']['protectionConfigurationModel'];
                    itemnode.CableSizeCalcInputXML['protection']['Xml'] = this.CableSize['protection']['Xml'];
                    itemnode.CableSizeCalcInputXML['installtablewind']['wind'] = this.CableSize['installtablewind']['wind'];
                    itemnode.CableSizeCalcInputXML['protection']['TrippingXml'] = this.CableSize['protection']['TrippingXml'];

                    itemnode.CableSizeCalcInputXML['specifyconductor']['phase']['mode'] = this.CableSize['specifyconductor']['phase']['mode'];
                    itemnode.CableSizeCalcInputXML['specifyconductor']['phase']['circuits'] = this.CableSize['specifyconductor']['phase']['circuits'];
                    itemnode.CableSizeCalcInputXML['specifyconductor']['phase']['size'] = this.CableSize['specifyconductor']['phase']['size'];

                    itemnode.CableSizeCalcInputXML['specifyconductor']['neutral']['circuits'] = this.CableSize['specifyconductor']['neutral']['circuits'];
                    itemnode.CableSizeCalcInputXML['specifyconductor']['neutral']['size'] = this.CableSize['specifyconductor']['neutral']['size'];
                    itemnode.CableSizeCalcInputXML['specifyconductor']['neutral']['mode'] = this.CableSize['specifyconductor']['neutral']['mode'];

                    itemnode.CableSizeCalcInputXML['specifyconductor']['earth']['mode'] = this.CableSize['specifyconductor']['earth']['mode'];
                    itemnode.CableSizeCalcInputXML['specifyconductor']['earth']['size'] = this.CableSize['specifyconductor']['earth']['size'];
                    itemnode.CableSizeCalcInputXML['specifyconductor']['earth']['circuits'] = this.CableSize['specifyconductor']['earth']['circuits'];

                    itemnode.CableSizeCalcInputXML['tableno'] = this.CableSize['tableno'];
                    itemnode.CableSizeCalcInputXML['currentTable'] = this.CableSize['currentTable'];
                    itemnode.CableSizeCalcInputXML['voltagedropTable'] = this.CableSize['voltagedropTable'];
                    itemnode.CableSizeCalcInputXML['columnno'] = this.CableSize['columnno'];
                    itemnode.CableSizeCalcInputXML['startingrowindex'] = this.CableSize['startingrowindex'];
                    itemnode.CableSizeCalcInputXML['endingrowindex'] = this.CableSize['endingrowindex'];
                    itemnode.CableSizeCalcInputXML['description'] = this.CableSize['description'];
                    itemnode.CableSizeCalcInputXML['tablectrlvalue'] = this.CableSize['tablectrlvalue'];
                    itemnode.CableSizeCalcInputXML['voltagefactor'] = this.CableSize['voltagefactor'];
                    itemnode.CableSizeCalcInputXML['calculationstandard'] = this.CableSize['calculationstandard'];
                    itemnode.CableSizeCalcInputXML['arcFlashData'] = this.CableSize['arcFlashData'];
                    itemnode.CableSizeCalcInputXML['isderatingwiz'] = this.CableSize['isderatingwiz'];
                    itemnode.CableSizeCalcInputXML['deratingtemp'] = this.CableSize['deratingtemp'];
                }
            });
        this.tempinputXML.CableSizeCalcInputs = JSON.stringify(this.tempcablesizelist);

        this.isCableSizeCalcStatus = false;
    }


    objectKeys(obj) {
        return Object.keys(obj);
    }

    public key() {
        return Object.keys(this.matrix);
    }
    changekeyofmatrix() {

        if (this.matrix != null && this.matrix.length > 0) {
            var subrow = 0;

            if (this.supplyType == 1) {

                subrow = 2;
                delete this.matrix[this.matrix.length - 1];
                delete this.matrix[this.matrix.length - 2];
                //delete this.matrix[this.matrix.length-3];
            }
            else if (this.supplyType == 0 || this.supplyType == 2 || this.supplyType == 3) {

                subrow = 2;
                if (this.isHVRelay)
                    subrow = 2;

                delete this.matrix[this.matrix.length - 1];
                delete this.matrix[this.matrix.length - 2];
                // if(this.isHVRelay)
                // delete this.matrix[this.matrix.length-3];

            }

            this.allnodesinfo = [];

            this.getnodesinfo(this.items[0]);

            for (var m = 0; m < this.matrix.length - subrow; m++) {

                for (var i = 0; i < this.allnodesinfo.length - subrow; i++) {

                    if (i > this.allnodesinfo.length - 3) {

                        delete this.matrix[m][this.allnodesinfo[i]['arrid']];
                    }

                    var oldkey = this.allnodesinfo[i]['arrid'];
                    var newkey = this.allnodesinfo[i]['arrcode'];

                    if (oldkey != newkey) {

                        // this.renameKey(this.matrix[m],oldkey,newkey);
                        this.matrix[m][newkey] = this.matrix[m][oldkey];
                        delete this.matrix[m][oldkey];
                    }
                }
            }
        }

    }

    public clone(obj) {
        obj = Object.assign({}, obj);
        return obj;
    }

    public renameKey(object, key, newKey) {
        const clonedObj = this.clone(object);

        const targetKey = clonedObj[key];



        delete clonedObj[key];

        clonedObj[newKey] = targetKey;

        return clonedObj;

    };

    deleteSelectedNode(treenode) {
        this.isXmlStatus = true;
        this.isMaxDemandDataShown = false;
        this.selectedNetworkData = null;

        //Get the node which was selected by the user  
        //id will be unique, can be used to identify the node
        let idToDelete = treenode.data.id;

        if (idToDelete == 1 || idToDelete == 2) {

        }
        else {
            if (treenode.data.objecttype === 1) {
                $('#switchboard-delete-model').modal('show');
            }
            else {
                this.options.allowDrag = false;
                this.options.allowDrop = false;
                this.addRemoveNode = true;
                document.getElementById('defaultCableOpen2').click();
                if (treenode.data.children.length > 0 && treenode.data.objecttype === 1) {
                    treenode.data.children.forEach(element => {
                        this.deleteNodesHierarchy(element);
                    });
                }

                this.deletecablesize(treenode.data);

                if (treenode.data.objecttype == 1) {
                    this.deleteswitchboard(treenode.data);
                }
                if (treenode.data.objecttype == 2) {
                    this.deleteload(idToDelete);

                }
                if (treenode.data.objecttype == 3) {

                    this.deleteCapacitorBank(idToDelete);
                }

                this.traverseTree(this.items[0], idToDelete, treenode, this.items[0]);


                this.allnodes = [];
                this.matrix = [];
                this.getallnodes(this.items[0]);
                this.printArray(this.items[0]);

                this.changekeyofmatrix();

                this.selectchild(0);
                this.switchboardcount = 0;
                this.loadcount = 0;
                this.capacitorcount = 0;
                this.switchBoardCount(this.items[0]);
                this.capacitorCount(this.items[0]);
                this.loadCount(this.items[0]);

                this.onInputChange();

                if (this.nodeChildIndex != null) {
                    this.changediv(this.nodeChildIndex);
                }
            }
        }
    }


    RemoveSwitchboardNode(treenode) {
        $('#switchboard-delete-model').modal('hide');
        this.isXmlStatus = true;
        this.isMaxDemandDataShown = false;
        this.selectedNetworkData = null;

        let idToDelete = treenode.data.id;

        if (idToDelete == 1 || idToDelete == 2) {
        }
        else {
            this.options.allowDrag = false;
            this.options.allowDrop = false;
            this.addRemoveNode = true;
            document.getElementById('defaultCableOpen2').click();
            if (treenode.data.children.length > 0 && treenode.data.objecttype === 1) {
                treenode.data.children.forEach(element => {
                    this.deleteNodesHierarchy(element);
                });
            }

            this.deletecablesize(treenode.data);

            if (treenode.data.objecttype == 1) {
                this.deleteswitchboard(treenode.data);
            }

            this.traverseTree(this.items[0], idToDelete, treenode, this.items[0]);


            this.allnodes = [];
            this.matrix = [];
            this.getallnodes(this.items[0]);
            this.printArray(this.items[0]);

            this.changekeyofmatrix();

            this.selectchild(0);


            this.onInputChange();

            if (this.nodeChildIndex != null) {
                this.changediv(this.nodeChildIndex);
            }
        }
    }


    deleteNodesHierarchy(childData) {

        if (childData.children.length > 0) {

            if (childData.objecttype === 1) {

                this.deletecablesize(childData);

                this.deleteswitchboard(childData);
            }

            childData.children.forEach(element => {

                if (element.children.length > 0) {

                    if (element.objecttype === 1) {

                        this.deletecablesize(element);

                        this.deleteswitchboard(element);
                    }

                    this.deleteNodesHierarchy(element);
                }
                else {

                    this.deletecablesize(element);

                    if (element.objecttype == 1) {
                        this.deleteswitchboard(element);
                    }
                    if (element.objecttype == 2) {
                        this.deleteload(element.id);

                    }
                    if (element.objecttype == 3) {
                        this.deleteCapacitorBank(element.id);
                    }

                }
            });
        }
        else {

            this.deletecablesize(childData);

            if (childData.objecttype == 1) {
                this.deleteswitchboard(childData);
            }
            if (childData.objecttype == 2) {
                this.deleteload(childData.id);

            }
            if (childData.objecttype == 3) {
                this.deleteCapacitorBank(childData.id);
            }

        }

    }

    deleteswitchboard(data: any) {

        let templist = this.tempinputXML.Switchboards;
        templist = templist.filter(x => x.SwitchBoard_Id !== data.id);
        this.tempinputXML.Switchboards = [];
        this.tempswitchlist = [];
        this.tempinputXML.Switchboards = templist;
        this.tempswitchlist = this.tempinputXML.Switchboards;

        if (this.maxDemandDataArray.length > 0) {
            this.maxDemandDataArray = this.maxDemandDataArray.filter(x => x.Node_Prefix != data.objectcode);
        }
    }

    deleteCapacitorBank(idToDelete: number) {

        let templist = this.tempinputXML.CapacitorBanks;
        templist = templist.filter(x => x.NetworkCapacitorBank_Id !== idToDelete);
        this.tempinputXML.CapacitorBanks = [];
        this.tempcapacitorlist = [];
        this.temppowerfactorcorrections = [];
        this.tempinputXML.CapacitorBanks = templist;
        this.tempcapacitorlist = this.tempinputXML.CapacitorBanks;

        let nodeID = idToDelete.toString();
        this.tempinputXML.PowerFactorCorrections = this.tempinputXML.PowerFactorCorrections.filter(x => x.Node_Id !== nodeID);
        this.temppowerfactorcorrections = this.tempinputXML.PowerFactorCorrections;
    }

    deleteload(idToDelete: number) {

        let templist = this.tempinputXML.Loads;
        templist = templist.filter(x => x.Load_Id !== idToDelete);
        this.tempinputXML.Loads = [];
        this.templist = [];
        this.tempinputXML.Loads = templist;
        this.templist = this.tempinputXML.Loads;
    }

    deletecablesize(data: any) {

        var deletedID = data.id.toString();
        let templist = JSON.parse(this.tempinputXML.CableSizeCalcInputs)
        templist = templist.filter(x => x.Node_Id !== deletedID);

        this.tempcablesizelist = [];
        this.tempcablesizelist = templist;
        this.tempinputXML.CableSizeCalcInputs = "";
        this.tempinputXML.CableSizeCalcInputs = JSON.stringify(templist);
        this.deleteWizardFactorValues(data.objectcode);
        this.deleteProtectionData(data.objectcode);

    }

    traverseTree(obj, idToDelete: string, treenode, parentnode) {

        this.nodeChildIndex = null;
        for (var k in obj) {
            if (typeof obj[k] == "object" && obj[k] !== null) {
                //   var childind=0;
                this.traverseTree(obj[k], idToDelete, treenode, obj);
                // childind=childind+1;
            }

            else {
                if (k === 'id') {
                    let id = obj[k];
                    if (id === idToDelete) {
                        let index = parentnode.findIndex(x => x.id === idToDelete);
                        parentnode.splice(index, 1);

                        for (var j = 0; j < parentnode.length; j++) {
                            parentnode[j].childindex = j;
                        }
                        if (parentnode.length === 0) {

                            parentnode.class = "normal";
                            this.nodeChildIndex = treenode.parent.data;
                        }
                        else {

                            let nodeIndex = parentnode.length - 1;
                            this.nodeChildIndex = parentnode[nodeIndex];
                        }
                        this.tree.treeModel.update();

                        if (treenode.data.parentid === "MSB01" && this.tree.treeModel.nodes[0].children[0].children.length === 0) {

                            this.tree.treeModel.getNodeById(2).setActiveAndVisible();
                            var selectednodeData = this.tree.treeModel.getNodeById(2);

                            this.nodeChildIndex = selectednodeData.data;

                            //this.changediv(selectednodeData.data);
                        }

                        else {

                            this.tree.treeModel.getNodeById(this.nodeChildIndex.id).setActiveAndVisible();
                            //this.changediv(this.nodeChildIndex);
                        }

                        break;
                    }
                }
            }
        }
    }

    removeNode(treenode: any, tree: any) {
        if (treenode != null && treenode.data != null) {
            var id = treenode.data.id;

            if (id == 1 || id == 2) {
                return;
            }
            // tree.removeNode(treenode);
            for (var i = 0; i < (this.tree.treeModel.nodes[0].children)[0].children.length; i++) {
                if (((this.tree.treeModel.nodes[0].children)[0].children)[i].id == id) {
                    this.tree.treeModel.nodes.slice();
                    delete ((this.tree.treeModel.nodes[0].children)[0].children)[i];
                }
                else {
                    for (var j = 0; j < ((this.tree.treeModel.nodes[0].children)[0].children)[i].children.length; j++) {
                        if ((((this.tree.treeModel.nodes[0].children)[0].children)[i].children)[j] == id) {
                            delete (((this.tree.treeModel.nodes[0].children)[0].children)[i].children)[j];
                        }
                    }
                }
            }
            this.tree.treeModel.update();

        }

    }

    activateSubSub(tree: any) {
        // tree.treeModel.getNodeBy((node) => node.data.name === 'subsub')
        tree.treeModel.getNodeById(1001)
            .setActiveAndVisible();

        //alert(0);
    }

    activeNodes(treeModel: any) {
    }

    GetProtectiveNodeItems() {
        let protectiveDrpList = [];

        if (this.activeNode) {
            var currentnode = this.activeNode.objectcode;
            var Parentnode = this.activeNode.parentid;
            if (Parentnode) {
                this.getParentId(this.items, Parentnode);
                var SecondParent = this.secondParentNodeId;
            }

            if (SecondParent) {
                let nextUpstreamNode = this.tempcablesizelist.find(m => m.Node_Prefix == SecondParent);
                if (nextUpstreamNode) {

                    nextUpstreamNode.OptionText = "Next Upstream: " + this.getNodeName(nextUpstreamNode);
                    nextUpstreamNode.CurveText = "Next Upstream: <br>" + this.getNodeName(nextUpstreamNode);
                    nextUpstreamNode.dName = this.getNodeName(nextUpstreamNode);
                    protectiveDrpList.push(nextUpstreamNode);
                }
            }

            if (this.activeNode.objectcode == "MSB01" && this.hvRelayCurve && this.hvRelayCurve.length > 0) {
                let obj: any = {};
                obj.OptionText = "Upstream (US): HV Relay";
                obj.CurveText = "Upstream (US): <br>HV Relay";
                obj.dName = "HV Relay";
                obj.HvRelay = true;
                protectiveDrpList.push(obj);
            }

            if (this.activeNode.objectcode == "MSB01" && this.hvFuseCurve && this.hvFuseCurve.length > 0) {
                let obj: any = {};
                obj.OptionText = "Upstream (US): HV Fuse";
                obj.CurveText = "Upstream (US): <br>HV Fuse";
                obj.dName = "HV Fuse";
                obj.Node_Prefix = this.items[0].objectcode;
                obj.HvFuse = true;
                protectiveDrpList.push(obj);
            }

            if (Parentnode) {
                let UpstreamNode = this.tempcablesizelist.find(m => m.Node_Prefix == Parentnode);
                if (UpstreamNode) {
                    UpstreamNode.OptionText = "Upstream (US): " + this.getNodeName(UpstreamNode);
                    UpstreamNode.CurveText = "Upstream (US): <br>" + this.getNodeName(UpstreamNode);
                    UpstreamNode.dName = this.getNodeName(UpstreamNode);
                    protectiveDrpList.push(UpstreamNode);

                }
            }

            if (currentnode) {
                let downStreamNode = this.tempcablesizelist.find(m => m.Node_Prefix == currentnode);
                if (downStreamNode) {
                    downStreamNode.OptionText = "Downstream (DS): " + this.getNodeName(downStreamNode);
                    downStreamNode.CurveText = "Downstream (DS): <br>" + this.getNodeName(downStreamNode);
                    downStreamNode.dName = this.getNodeName(downStreamNode);
                    protectiveDrpList.push(downStreamNode);

                }
            }
            this.index = protectiveDrpList.length - 1;
            this.currentProtectiveDeivce = this.index.toString();
            this.currentProtectiveDeivceObj = protectiveDrpList[this.index];
        }
        return protectiveDrpList;
    }

    getNodeName(item: any) {
        try {
            let node_Prefix = item.Node_Prefix;
            if (node_Prefix.startsWith("CB", 0)) {
                let obj = this.tempinputXML.CapacitorBanks.find(m => m.NetworkCapacitorBank_Id == item.CableSizeCalcInputId);
                return obj.NetworkCapacitorBank_Name;
            }
            else if (node_Prefix.startsWith("L", 0)) {
                let obj = this.tempinputXML.Loads.find(m => m.Load_Id == item.CableSizeCalcInputId);
                return obj.Load_Name;
            }
            else {
                let obj = this.tempinputXML.Switchboards.find(m => m.SwitchBoard_Id == item.CableSizeCalcInputId);
                return obj.SwitchBoard_Name;
            }
        } catch (error) {
            return item.Node_Name;
        }

    }

    getNodeNameById(item: any) {
        try {
            let node_Prefix = item;
            if (node_Prefix == "T0" || node_Prefix == "PS0") {
                return "HV Fuse";
            }
            else if (node_Prefix.startsWith("CB", 0)) {
                let obj = this.tempinputXML.CapacitorBanks.find(m => m.NetworkCapacitorBank_Matrix_Node == node_Prefix);
                return obj.NetworkCapacitorBank_Name;
            }
            else if (node_Prefix.startsWith("L", 0)) {
                let obj = this.tempinputXML.Loads.find(m => m.Load_Matrix_Node == node_Prefix);
                return obj.Load_Name;
            }
            else {
                let obj = this.tempinputXML.Switchboards.find(m => m.SwitchBoard_Matrix_Node == node_Prefix);
                return obj.SwitchBoard_Name;
            }
        } catch (error) {
            return item.Node_Name;
        }

    }
    protectiveDrpList: any = [];
    addingDeviceForUnknownDevice: boolean = false;
    addingDeviceForHvFuse: boolean = false;
    checkingHVFuse: boolean = false;
    handleUnknownDevice: boolean = false;
    unknownDeviceNode: any;
    unknownDeviceNodeHeader: string;
    isFromCoordination: boolean = false;
    public isSelectorChange: boolean = false;
    ArcingCurrents: boolean = true;
    FaultCurrents: boolean = false;
    CableDamage: boolean = true;
    TransformerDamage: boolean = false;
    arcCurveValue: string = '';

    currentProtectiveDeivce: string = '';
    currentProtectiveDeivceObj: any = undefined;
    showCoordinationPopup() {

        this.protectiveDrpList = this.GetProtectiveNodeItems();

        $(document).off('change', '#drpDeviceToConfigure');
        $(document).on('change', '#drpDeviceToConfigure', (e: any, isTriggered) => {
            this.canHaveHv = false;
            this.currentProtectiveDeivce = $('#drpDeviceToConfigure').val().toString();
            this.coordinationloader = true;
            if (this.protectiveDrpList && this.protectiveDrpList.length > 0) {
                $(".device-configuration").show();
                this.currentProtectiveDeivceObj = this.protectiveDrpList[this.currentProtectiveDeivce];
                this.TransformerDamage = this.currentProtectiveDeivceObj && this.currentProtectiveDeivceObj.Node_Prefix == "MSB01" && (this.networksupplytype == '2' || this.networksupplytype == '4');
                if (this.currentProtectiveDeivceObj && this.currentProtectiveDeivceObj.HvRelay == true) {
                    $("#devicesettingli").hide();
                    if (document.querySelector("#ulprotectioncoordination").querySelector("#protectiveTab2Open") != null) {
                        (<HTMLElement>document.querySelector("#ulprotectioncoordination").querySelector("#protectiveTab2Open")).click();
                    }
                    $("#normalDetail").hide();
                    $("#hvRelayDetail").show();
                    // if (this.HVRelayObjectData) {
                    //     let curveEquation = this.HVRelayObjectData.cluster[1].ew.val;
                    //     let curveEquationName = this.HVRelayObjectData.cluster[1].ew.choice[curveEquation];
                    //     $(".spnequation").text(curveEquationName);
                    //     if (curveEquationName == "IEC Standard 60255") {
                    //         $(".spncurvetype").text(this.HVRelayObjectData.cluster[1].cluster.ew[0].choice[this.HVRelayObjectData.cluster[1].cluster.ew[0].val]).trigger('change', "true");
                    //     }
                    //     if (curveEquationName == "IEEE Standard C37.112") {
                    //         $(".spncurvetype").text(this.HVRelayObjectData.cluster[1].cluster.ew[1].choice[this.HVRelayObjectData.cluster[1].cluster.ew[1].val]).trigger('change', "true");
                    //     }
                    //     if (curveEquationName == "Electromechanical IAC") {
                    //         $(".spncurvetype").text(this.HVRelayObjectData.cluster[1].cluster.ew[2].choice[this.HVRelayObjectData.cluster[1].cluster.ew[2].val]).trigger('change', "true");
                    //     }
                    //     $(".spnpickupcurrent").text(this.HVRelayObjectData.cluster[1].dbl[0].val.toString());
                    //     $(".spnmultiplier").text(this.HVRelayObjectData.cluster[1].dbl[1].val.toString());
                    //     $(".spntolerance").text(this.HVRelayObjectData.cluster[1].dbl[3].val.toString());
                    // }
                    let curveEquation: any = document.getElementById("HVRelay_Curve_Equation");
                    if (curveEquation)
                        $(".spnequation").text(curveEquation.options[curveEquation.selectedIndex].text);

                    let curveType: any = document.getElementById("HVRelay_Curve_Type");
                    if (curveType && curveType.length > 0)
                        $(".spncurvetype").text(curveType.options[curveType.selectedIndex].text);

                    let pickupCurrent = $("#HVRelay_Pick_Up_Current");
                    if (pickupCurrent && pickupCurrent.length > 0)
                        $(".spnpickupcurrent").text(pickupCurrent.val().toString());

                    let multiplier = $("#HVRelay_TMS_TDS");
                    if (multiplier && multiplier.length > 0)
                        $(".spnmultiplier").text(multiplier.val().toString());

                    let tolerance = $("#HVRelay_Tolerance");
                    if (tolerance && tolerance.length > 0)
                        $(".spntolerance").text(tolerance.val().toString());

                    this.coordinationloader = false;
                }
                else if (this.currentProtectiveDeivceObj && this.currentProtectiveDeivceObj.HvFuse == true) {
                    this.canHaveHv = true;
                    this.index = this.ListLoadStorage.findIndex(m => m.nodeid == this.protectiveDrpList[this.currentProtectiveDeivce].Node_Prefix);
                    this.rowClick(this.index, true);
                }
                else {
                    let tempnode = this.tempcablesizelist.find(m => m.Node_Prefix == this.protectiveDrpList[this.currentProtectiveDeivce].Node_Prefix);
                    if (tempnode) {
                        if (tempnode.CableSizeCalcInputXML.protection && tempnode.CableSizeCalcInputXML.protection.protectionConfigurationModel)
                            this.protectionConfigurationModel = tempnode.CableSizeCalcInputXML.protection.protectionConfigurationModel;

                        this.index = this.ListLoadStorage.findIndex(m => m.nodeid == tempnode.Node_Prefix);
                        this.rowClick(this.index, true);
                    }
                }
            }
        });


        if (this.protectiveDrpList && this.protectiveDrpList.length > 0) {
            // let isUnknownAny=this.tempcablesizelist.some((element)=>{
            //     return element.
            // })
            this.ArcingCurrents = true;
            this.FaultCurrents = false;
            this.CableDamage = true;
            this.TransformerDamage = this.currentProtectiveDeivceObj && this.currentProtectiveDeivceObj.Node_Prefix == "MSB01" && (this.networksupplytype == '2' || this.networksupplytype == '4');
            this.arcCurveValue = '';
            let isUnknownAny: boolean = false;
            isUnknownAny = this.protectiveDrpList.some(element => {
                return element.CableSizeCalcInputXML && element.CableSizeCalcInputXML.protection && element.CableSizeCalcInputXML.protection.IsUnknown == true;
                // if (element.HvRelay == true || element.HvFuse == true)
                //     return false;
                // if (this.activeNode && this.activeNode.objectcode == "MSB01") {
                //     return element.CableSizeCalcInputXML.protection.IsUnknown == true && (!this.transformerHVRelay && !this.powerHVRelay && !this.HVFuse);
                // }
                // else
                //     return element.CableSizeCalcInputXML.protection.IsUnknown == true;
            });

            let tempnode = this.protectiveDrpList[this.currentProtectiveDeivce];
            if (tempnode) {
                if (tempnode.CableSizeCalcInputXML.protection && tempnode.CableSizeCalcInputXML.protection.protectionConfigurationModel)
                    this.protectionConfigurationModel = tempnode.CableSizeCalcInputXML.protection.protectionConfigurationModel;
            }
            let objectNodes = Object.keys(this.protectiveDrpList).map((k) => { return this.protectiveDrpList[k].Node_Prefix }).join(",");
            this.getNetworkProtectiveDataByNodeId(this.newCalculationId, objectNodes, () => {
                this.isFromCoordination = true;
                //To clear plotly chart
                this.chartData = undefined;
                let script = this._renderer2.createElement('script');
                script.type = `text/javascript`;
                script.text = `Plotly.purge('myDiv')`;
                this._renderer2.appendChild(this._document.body, script);
                // End To clear plotly chart

                this.coordinationDisplayErrorBtn = false;
                if (!this.handleUnknownDevice)
                    $('#protectiveConfiguration').modal('show');
                setTimeout(() => {
                    $('[data-toggle="tooltip"]').tooltip();
                });
                this.coordinationloader = true;
                if (isUnknownAny) {
                    if (!this.handleUnknownDevice)
                        $('#unknown-device-alert').modal({
                            show: true,
                            keyboard: false,
                            backdrop: 'static'
                        });
                    else
                        this.selectDeviceForUnknown();
                    this.handleUnknownDevice = true;
                }
                else {
                    this.handleUnknownDevice = false;
                }
                setTimeout(() => {
                    if (this.ListLoadStorage.length > 0) {
                        var familyId = this.ListLoadStorage[this.index].family;
                        var arr = familyId.split(' ')[1];
                        $("#devicesettingli").show();
                        $("#normalDetail").show();
                        $("#hvRelayDetail").hide();
                        if (arr != null && arr != "" && (arr == "MCB" || arr == "Fuse")) {
                            $("#devicesettingli").hide();
                            if (document.querySelector("#ulprotectioncoordination").querySelector("#protectiveTab2Open") != null) {
                                (<HTMLElement>document.querySelector("#ulprotectioncoordination").querySelector("#protectiveTab2Open")).click();
                            }
                            if (arr == "MCB") {
                                $(".clsfustype").hide();
                            }
                            if (arr == "Fuse") {
                                $(".clscurveclass").hide();
                            }
                            $(".clstripunit").hide();
                            $(".clsreset").hide();
                            $("#divelectronic").hide();
                            $("#divmagneticonly").hide();
                            $("#divtmagneticonly").hide();
                        }
                        else {
                            $(".clsfustype").hide();
                            $(".clscurveclass").hide();
                            document.getElementById('defaultOpenProtective').click();
                        }
                    }

                    if ($('.c-select').length) {
                        let $singleSelect = $('.c-select'),
                            $singleSelectHasSearch = $('.c-select.has-search'),
                            $multipleSelect = $('.c-select.c-select--multiple'),
                            $disabledSelect = $('.c-select.is-disabled');

                        $singleSelect.select2({
                            width: '100%',
                            minimumResultsForSearch: Infinity // disable search
                        });

                        // multiple select
                        $multipleSelect.select2({
                            width: '100%',
                            multiple: true
                        });

                        // disabled select
                        $disabledSelect.select2({
                            width: '100%',
                            disabled: true
                        });
                    }
                });
            }, true
            );

        }
    }

    cancelUnknownDeviceAlert() {
        this.handleUnknownDevice = false;
        $('#protectiveConfiguration').modal('hide');
    }

    selectDeviceForUnknown() {
        if (this.protectiveDrpList && this.protectiveDrpList.length > 0) {
            let unknownObjects = this.protectiveDrpList.filter(m => m.CableSizeCalcInputXML && m.CableSizeCalcInputXML.protection && m.CableSizeCalcInputXML.protection.IsUnknown == true);
            if (unknownObjects && unknownObjects.length > 0) {
                let unknownObject = unknownObjects[0];
                if (unknownObject) {
                    this.showLoader();
                    this._settingService.getNetworkProtectiveDataByNodeId(this.newCalculationId, unknownObject.Node_Prefix).subscribe(result => {

                        this.protectiveXmlInputList = [];
                        this.ListLoadStorage = [];
                        this.protectiveImgSrc2 = '/assets/cablepro/logo/a.png';
                        if (result !== undefined && result.currentrow) {
                            this.index = result.currentrow;
                        }
                        this.canHaveHv = false;
                        if (result !== undefined && result.items) {
                            for (var i = 0; i < result.items.length; i++) {

                                this.ProtectiveLoadStorage = new ProtectiveItem();
                                this.ProtectiveLoadStorage.model = result.items[i].model
                                if (i == 0)
                                    this.model = result.items[i].model
                                this.ProtectiveLoadStorage.family = result.items[i].family
                                this.ProtectiveLoadStorage.rating = result.items[i].rating
                                this.ProtectiveLoadStorage.manufacturer = result.items[i].manufacturer
                                this.ProtectiveLoadStorage.tripunit = result.items[i].tripunit
                                this.ProtectiveLoadStorage.deviceno = result.items[i].deviceno
                                this.ProtectiveLoadStorage.devicetype = result.items[i].family.split(' ')[1].toString();
                                if (result.items[i].devicenostr != null && result.items[i].devicenostr != "")
                                    this.ProtectiveLoadStorage.devicenostr = result.items[i].devicenostr.trim()
                                this.ProtectiveLoadStorage.desc = result.items[i].desc;
                                this.ProtectiveLoadStorage.nodeid = result.items[i].nodeid;
                                this.ListLoadStorage.push(this.ProtectiveLoadStorage);
                                this.fillDeviceList();
                                this.protectiveXmlInput = new ProtectiveXmlInput();
                                this.protectiveXmlInput.xml = result.items[i].xml;// this.xmlData // Changed to get xml from database
                                this.protectiveXmlInput.model = parseInt(result.items[i].model);
                                this.protectiveXmlInput.manufacturer_id = parseInt(result.items[i].manufacturer);
                                this.protectiveXmlInput.range_id = result.items[i].family;
                                this.protectiveXmlInput.devicenostr = result.items[i].devicenostr;
                                this.protectiveXmlInput.tripunit = result.items[i].tripunit;;
                                this.protectiveXmlInput.rating = result.items[i].rating;
                                this.protectiveXmlInput.chartdata = '';
                                this.protectiveXmlInput.id = result.items[i].id;
                                this.protectiveXmlInput.rownumber = i;
                                this.protectiveXmlInput.nodeid = result.items[i].nodeid;
                                this.protectiveXmlInputList.push(this.protectiveXmlInput);
                            }
                            //this.fillDeviceList();
                            this.index = 0;

                            if (this.protectiveXmlInputList.length > 0) {
                                // this.rowClick(this.index, false);

                                if (this.ListLoadStorage.length > 0) {
                                    var manufacturerId = parseInt(this.ListLoadStorage[this.index].manufacturer);
                                    var familyId = this.ListLoadStorage[this.index].family;

                                    var modelId = parseInt(this.ListLoadStorage[this.index].model.toString());
                                    var d = this.ListLoadStorage[this.index].rating.toString();
                                    var ee = this.ListLoadStorage[this.index].tripunit.toString();
                                    var curve = (ee.toString());
                                    this.ProtectiveLoadStorage = new ProtectiveItem();
                                    this.ProtectiveLoadStorage = this.ListLoadStorage[this.index];
                                    var res = curve;
                                    if (curve.indexOf('-') != -1) {
                                        res = curve.substr(0, curve.lastIndexOf('-'));
                                        res = this.trimChar(res.trim(), "-");
                                    }
                                    this.GetBreakingByManufacturerId(manufacturerId, familyId, modelId, d.toString(), res);
                                    this.GetPolesTableByManufacturerId(manufacturerId, familyId, modelId, d.toString(), res);
                                    this.btnAddDeviceRequestCounter += 1;
                                    this.btnAddDeviceDisabled = true;
                                    // this.modifyindex = this.index;
                                    var data = this.ListLoadStorage[this.index];
                                    if (this.loadProtectiveXmlInput == null || this.loadProtectiveXmlInput == undefined)
                                        this.loadProtectiveXmlInput = new ProtectiveXmlInput();
                                    this.loadProtectiveXmlInput.manufacturer_id = parseInt(data.manufacturer);
                                    this.loadProtectiveXmlInput.range_id = data.family;
                                    this.loadProtectiveXmlInput.tripunit = data.tripunit;
                                    this.loadProtectiveXmlInput.model = parseInt(data.model);
                                    this.loadProtectiveXmlInput.rating = data.rating;
                                    this.loadProtectiveXmlInput.devicenostr = data.devicenostr;
                                    this.deviceNumber = data.devicenostr.trim();
                                    this.GetManufacturer(() => {
                                        let selectedObj = this.manufacturerOutput.find(m => m.id == parseInt(data.manufacturer));
                                        if (!selectedObj)
                                            selectedObj = this.manufacturerOutput[0];
                                        if (selectedObj.name != "Generic") {
                                            this.IsGeneric = true;
                                            var name = selectedObj.name + "logo";
                                            this.protectiveImgSrc2 = '/assets/cablepro/logo/' + name + '.svg';
                                            // $('#divlogo img').attr('src', '/assets/cablepro/logo/' + name + '.svg');
                                        }
                                        else {
                                            this.protectiveImgSrc2 = '/assets/cablepro/logo/a.png';
                                            // $('#divlogo img').attr('src', '/assets/cablepro/logo/a.png');
                                            this.IsGeneric = false;

                                        }
                                    });
                                    this.hideLoader();

                                    this.addingDeviceForUnknownDevice = true;
                                    this.unknownDeviceNode = unknownObject;
                                    this.unknownDeviceNodeHeader = "Protective Device Selector - " + unknownObject.dName;
                                    this.showProtectiveDeviceUI('1');

                                }
                            }
                        }
                    });
                    // this.getNetworkProtectiveDataByNodeId(this.newCalculationId, unknownObject.Node_Prefix, () => {
                    //     this.showProtectiveDeviceUI('1');
                    // });
                }
            }
        }
    }

    RefreshProtectionCoordinationPopup() {
        let objectNodes = Object.keys(this.protectiveDrpList).map((k) => { return this.protectiveDrpList[k].Node_Prefix }).join(",");
        this.getNetworkProtectiveDataByNodeId(this.newCalculationId, objectNodes, () => {
            $('#protectiveConfiguration').modal('show');
            setTimeout(() => {
                $('[data-toggle="tooltip"]').tooltip();
            });
            this.coordinationloader = true;
            setTimeout(() => {
                if (this.ListLoadStorage.length > 0) {
                    var familyId = this.ListLoadStorage[this.index].family;
                    var arr = familyId.split(' ')[1];
                    $("#devicesettingli").show();
                    $("#normalDetail").show();
                    $("#hvRelayDetail").hide();
                    if (arr != null && arr != "" && (arr == "MCB" || arr == "Fuse")) {
                        $("#devicesettingli").hide();
                        if (document.querySelector("#ulprotectioncoordination").querySelector("#protectiveTab2Open") != null) {
                            (<HTMLElement>document.querySelector("#ulprotectioncoordination").querySelector("#protectiveTab2Open")).click();
                        }
                        if (arr == "MCB") {
                            $(".clsfustype").hide();
                        }
                        if (arr == "Fuse") {
                            $(".clscurveclass").hide();
                        }
                        $(".clstripunit").hide();
                        $(".clsreset").hide();
                        $("#divelectronic").hide();
                        $("#divmagneticonly").hide();
                        $("#divtmagneticonly").hide();
                    }
                    else {
                        $(".clsfustype").hide();
                        $(".clscurveclass").hide();
                        document.getElementById('defaultOpenProtective').click();
                    }
                }

                if ($('.c-select').length) {
                    let $singleSelect = $('.c-select'),
                        $singleSelectHasSearch = $('.c-select.has-search'),
                        $multipleSelect = $('.c-select.c-select--multiple'),
                        $disabledSelect = $('.c-select.is-disabled');

                    $singleSelect.select2({
                        width: '100%',
                        minimumResultsForSearch: Infinity // disable search
                    });

                    // multiple select
                    $multipleSelect.select2({
                        width: '100%',
                        multiple: true
                    });

                    // disabled select
                    $disabledSelect.select2({
                        width: '100%',
                        disabled: true
                    });
                }
            });
        }, true
        );
    }

    changediv(item, isFromTreeNode: boolean = false) {
        //this.isScrollforCableSize_MaxDemand = false;
        this.activeNode = undefined;
        this.cd.detectChanges();
        let obj = Object.assign({}, item);
        obj.isFromTreeNode = true;
        this.activeNode = obj;

        this.showScroll = true;
        this.isMaxDemandDataShown = false;
        this.isCableCalValue = true;
        this.isXmlStatus = true;
        this.isCableSizeModified = false;
        this.isCableSizeCalcStatus = true;
        this.isInputAPIStatus = false;
        this.isNewNodeAdded = false;
        this.selectedNetworkData = item;
        if (item.id == 1 && item.objecttype == -1) {
            this.supplyNetworkData = item.children[0];
            this.selectedNetworkData = null;
        }
        else {
            this.supplyNetworkData = null;
        }

        $("#SwitchboardHead").hide();
        $("#LoadHead").hide();
        $("#CapacitorHead").hide();
        $('#PowerfactorHead').hide();

        $("#MaxDemandHead").hide();
        $("#CableSizeHead").hide();
        //$("#HVRelayHead").hide();
        $("#ArcFlashHead").hide();

        //$("#calculationresultstab").show();
        sessionStorage.removeItem("currentnode");
        if (item != null && item.objecttype != null) {
            // this.showLoader();
            document.getElementById('defaultCableOpen2').click();
            sessionStorage.setItem("currentnode", JSON.stringify(item));

            if (item.objecttype == -1) {
                if (this.resetStatus == true) {
                    this.supplyTypeReopenData = true;
                }
                else {
                    this.supplyTypeReopenData = false;
                }
                document.getElementById('defaultOpen').click();

                //document.getElementById('defaultCableOpen2').click();
                // this.tabCableChange(null, 'calculationresulttab2')
                $("#calculationresulttab2").show();
                $('#selectNetwork').val('2');
                this.tree.treeModel.getNodeById(item.id).setActiveAndVisible();

                this.selectchild(2);

            }
            if (item.objecttype == 1) {
                $("#SwitchboardHead").show();
                $("#MaxDemandHead").show();
                $("#CableSizeHead").show();
                $("#ArcFlashHead").show();


                $('#Resultschild').hide();
                $("#LoadHead").hide();
                $("#CapacitorHead").hide();
                $('#PowerfactorHead').hide();

                $('#isCableSizeShown').css('display', 'block');
                sessionStorage.setItem("SwitchboardResults", item.objectcode);
                this.showgeneratorResult = false;
                this.showFeederResult = false;
                this.showPowerStationResult = false;
                this.showTransformerResult = false;
                this.showPowerFactorResult = false;
                this.showSwitchboardResult = true;
                $('#selectNetwork').val('0');
                document.getElementById('aSwitchboard').click();
                //Get Switchboard Data
                this.getswitchboard(item, isFromTreeNode);


            }
            if (item.objecttype == 2) {
                $("#SwitchboardHead").hide();
                $("#MaxDemandHead").hide();
                $("#ArcFlashHead").hide();
                // $("#CableSizeHead").hide();

                $("#LoadHead").show();

                //Get Load Data
                this.getload(item, item.id, item.objectcode, isFromTreeNode);

                $("#CableSizeHead").show();
                $('#isCableSizeShown').css('display', 'none');

                this.showgeneratorResult = false;
                this.showFeederResult = false;
                this.showPowerStationResult = false;
                this.showTransformerResult = false;
                this.showPowerFactorResult = false;
                this.showSwitchboardResult = false;

                let a_element: HTMLElement = document.getElementById('aLoad') as HTMLElement;
                a_element.click();

                $("#CapacitorHead").hide();
                $('#PowerfactorHead').hide();
                //  $("#calculationresulttab2").hide();
                $('#selectNetwork').val('0');

            }
            if (item.objecttype == 3) {
                $("#SwitchboardHead").hide();
                $("#MaxDemandHead").hide();
                $("#ArcFlashHead").hide();


                $("#CableSizeHead").show();
                $("#LoadHead").hide();

                $("#CapacitorHead").show();

                let a_element: HTMLElement = document.getElementById('aCapacitor') as HTMLElement;
                a_element.click();

                // $("#calculationresulttab2").hide();
                $('#isCableSizeShown').css('display', 'none');

                sessionStorage.setItem("CapacitorNode", item.objectcode);
                this.showgeneratorResult = false;
                this.showFeederResult = false;
                this.showPowerStationResult = false;
                this.showTransformerResult = false;
                this.showPowerFactorResult = false;
                this.showSwitchboardResult = false;
                $("#PowerfactorHead").show();
                $('#selectNetwork').val('0');
                this.getCapacitorbank(item, isFromTreeNode);



            }
            let tempnode = this.tempcablesizelist.find(tempnode => tempnode.Node_Id == item.id);
            if (tempnode) {
                if (tempnode && tempnode.CableSizeCalcInputXML.protection)
                    this.protectiveUnknown = tempnode.CableSizeCalcInputXML.protection.IsUnknown;
                if (tempnode.CableSizeCalcInputXML.protection)
                    this.RCD = tempnode.CableSizeCalcInputXML.protection.rcd === '1' ? true : false;
                if (tempnode.CableSizeCalcInputXML.protection && tempnode.CableSizeCalcInputXML.protection.protectionConfigurationModel)
                    this.protectionConfigurationModel = tempnode.CableSizeCalcInputXML.protection.protectionConfigurationModel;

            }
            //this.loadPreviousValues();
        }
        this.resetStatus = false;
        this.UserWizard = false;
        this.CableSize["isderatingwiz"] = "0";

        if (this.cableCalcProtectiveDeviceSource == "1") {
            this.index = 0;
            this.getNetworkProtectiveDataByNodeId(this.newCalculationId, this.activeNode.objectcode, false, isFromTreeNode);
        }

    }

    toggleclass(item) {

        if (item.children != null && item.children.length > 0) {
            this.success = !this.success;
            if (item.class.includes('fa-minus-square-o')) {
                item.class = "fa fa-plus-square-o";
            }
            else if (item.class.includes('fa-plus-square-o')) {
                item.class = "fa fa-minus-square-o";
            }
        }
        else {
            item.class = "normal";
        }


    }

    onFilterChange(value: string): void {
    }

    getDefaultTreeList() {
        let treenodecount = parseInt(sessionStorage.getItem("nodecount"));
        treenodecount = 2;
        sessionStorage.setItem("nodecount", treenodecount.toString());


        var parentnode = ({
            name: "Network feeder", objecttype: -1, objectcode: "NF0", id: 1, class: 'fa fa-plus-square-o', img: '/assets/cablepro/img/Network_Feeder.png', childindex: -1, parentid: 0, children: [{
                name: "Main Switchboard", objecttype: 1, objectcode: "MSB01", id: treenodecount, class: 'normal', img: '/assets/cablepro/img/Main_switchboard.png', parentid: "NF0", childindex: 0, children: [],
                // name:"Main Switchboard",objecttype:0,objectcode:"MSB",id:2,class:'normal',img:'/assets/cablepro/img/mainswitchboard.png',childindex:0,children:[]

            }]

        })

        sessionStorage.setItem("currentnode", JSON.stringify(parentnode.children[0]));
        this.showgeneratorResult = false;
        this.showFeederResult = true;
        this.showPowerStationResult = false;
        this.showTransformerResult = false;
        this.showPowerFactorResult = false;
        this.showSwitchboardResult = true;
        this.supplyNetworkData = parentnode.children[0];
        this.addnewswitchboard(2);
        sessionStorage.removeItem("nodecount");
        sessionStorage.setItem("nodecount", (treenodecount + 1).toString());
        return [parentnode];
    }
    public ct: number = 0;

    switchBoardCount(arr) {

        for (var k in arr) {
            if (typeof arr[k] == "object" && arr[k] !== null) {

                this.switchBoardCount(arr[k])
                // childind=childind+1;
            }
            else {
                if (k == 'objecttype') {
                    if (arr[k] == "1") {

                        this.switchboardcount = this.switchboardcount + 1;


                    }
                }
            }
        }
        //    alert(this.switchboardcount);

        //this.allnodes.pop();
        //this.allnodes.pop();
    }
    loadCount(arr) {

        for (var k in arr) {
            if (typeof arr[k] == "object" && arr[k] !== null) {

                this.loadCount(arr[k])
                // childind=childind+1;
            }
            else {
                if (k == 'objecttype') {
                    if (arr[k] == "2") {

                        this.loadcount = this.loadcount + 1;


                    }
                }
            }
        }

    }
    capacitorCount(arr) {

        for (var k in arr) {
            if (typeof arr[k] == "object" && arr[k] !== null) {

                this.capacitorCount(arr[k])
                // childind=childind+1;
            }
            else {
                if (k == 'objecttype') {
                    if (arr[k] == "3") {

                        this.capacitorcount = this.capacitorcount + 1;


                    }
                }
            }
        }

    }
    getallnodes(arr) {

        for (var k in arr) {
            if (typeof arr[k] == "object" && arr[k] !== null) {

                this.getallnodes(arr[k])
                // childind=childind+1;
            }
            else {
                if (k == 'id') {
                    this.functionCallCount = this.functionCallCount + 1;
                    this.allnodes.push(arr[k]);
                    if (this.objectCount != this.functionCallCount) {

                        // if(this.supplyType == 1 && this.ct==0)
                        // {
                        //     this.allnodes.push('NF'+arr[k]);
                        //     if(this.isHVRelay)
                        //     this.allnodes.push('HVR'+arr[k]);
                        //     this.ct=1;
                        // }
                        // if(this.supplyType == 3 && this.ct==0)
                        // {
                        //     this.allnodes.push('Gen'+arr[k]);
                        //     if(this.isHVRelay)
                        //     this.allnodes.push('HVR'+arr[k]);
                        //     this.ct=1;
                        // }
                        this.allnodes.push('PD' + arr[k]);
                        this.allnodes.push('C' + arr[k]);
                        if (this.supplyType == 0 || this.supplyType == 2) {
                        }
                    }
                    this.allnodesid = this.allnodesid + 1;
                }
            }

        }

        //this.allnodes.pop();
        //this.allnodes.pop();
    }
    getnodesinfo(arr) {
        for (var k in arr) {
            if (typeof arr[k] == "object" && arr[k] !== null) {

                this.getnodesinfo(arr[k])
                // childind=childind+1;
            }
            else {
                if (k == 'id') {

                    this.allnodesinfo.push({ 'arrcode': arr['objectcode'], 'arrid': arr[k] });

                    if (this.supplyType == 0 || this.supplyType == 2) {
                        this.allnodesinfo.push({ 'arrcode': 'PD' + arr[k], 'arrid': 'PD' + arr[k] });
                        this.allnodesinfo.push({ 'arrcode': 'C' + arr[k], 'arrid': 'C' + arr[k] });
                    }
                    else if (this.supplyType == 1) {
                        // if(this.insertrow==false)
                        // {
                        //     this.allnodesinfo.push({'arrcode':'NF'+arr[k],'arrid':'NF'+arr[k]});
                        //     this.insertrow = true;
                        // }
                        this.allnodesinfo.push({ 'arrcode': 'PD' + arr[k], 'arrid': 'PD' + arr[k] });
                        this.allnodesinfo.push({ 'arrcode': 'C' + arr[k], 'arrid': 'C' + arr[k] });

                    }
                    else if (this.supplyType == 3) {
                        if (this.insertrow == false) {
                            this.allnodesinfo.push({ 'arrcode': 'Gen' + arr[k], 'arrid': 'Gen' + arr[k] });
                            if (this.isHVRelay)
                                this.allnodesinfo.push({ 'arrcode': 'HVR' + arr[k], 'arrid': 'HVR' + arr[k] });
                            this.insertrow = true;
                        }
                        this.allnodesinfo.push({ 'arrcode': 'PD' + arr[k], 'arrid': 'PD' + arr[k] });
                        this.allnodesinfo.push({ 'arrcode': 'C' + arr[k], 'arrid': 'C' + arr[k] });

                    }
                    // this.allnodesinfo.push( {'arrcode':'MSB'+arr[k],'arrid':'MSB'+arr[k]});
                    //this.allnodesinfo.push( {'arrcode':'HVR'+arr[k],'arrid':'HVR'+arr[k]});
                    //this.allnodesinfo.push( {'arrcode':'T'+arr[k],'arrid':'T'+arr[k]});

                }
            }
        }
    }
    printArray(arr) {
        for (var k in arr) {
            if (typeof arr[k] == "object" && arr[k] !== null) {
                //   var childind=0;
                this.printArray(arr[k]);
                // childind=childind+1;
            }
            else {
                if (k == 'name') {
                    var id = arr['id'];
                    var matrixobj = {};
                    var matrixobjtype1 = {};
                    var lastcablenodeid = 0;
                    if (this.matrix != null && this.matrix.length > 1) {
                        if (this.matrix[this.matrix.length - 1]['type'] == 'Cable') {
                            this.matrix[this.matrix.length - 1][arr['id']] = 1;
                            lastcablenodeid = this.matrix[this.matrix.length - 1][arr['nodeid']];
                        }
                    }

                    matrixobj['type'] = arr[k];
                    matrixobj['nodeid'] = arr['objectcode'];
                    matrixobj['parentid'] = arr['parentid'];
                    if (this.allnodes != null && this.allnodes.length > 0) {
                        for (var n = 0; n < this.allnodes.length; n++) {

                            if (this.allnodes[n] != arr['id']) {
                                if (this.allnodes[n] == 'PD' + arr['id'] && (this.supplyType == 0 || this.supplyType == 2)) {
                                    matrixobj[this.allnodes[n]] = 1;
                                }
                                else if (this.allnodes[n] == 'PD' + arr['id'] && (this.supplyType == 1) && this.insertrow2) {
                                    matrixobj[this.allnodes[n]] = 1;
                                }//!this.insertrow2
                                else if (this.allnodes[n] == 'NF' + arr['id'] && (this.supplyType == 1)) {
                                    matrixobj[this.allnodes[n]] = 1;
                                }
                                else if (this.allnodes[n] == 'Gen' + arr['id'] && (this.supplyType == 3)) {
                                    matrixobj[this.allnodes[n]] = 1;
                                }
                                else {
                                    matrixobj[this.allnodes[n]] = 0;
                                }
                            }
                            else {
                                matrixobj[this.allnodes[n]] = 0;
                            }
                        }
                    }
                    if (this.matrix != null && this.matrix.length > 1) {
                        if (this.matrix[this.matrix.length - 1]['type'] == 'Cable') {
                            var prevnodeid = this.matrix[this.matrix.length - 1]['nodeid'];
                            matrixobj[prevnodeid] = -1;
                        }
                    }
                    //if(this.supplyType == 0 || this.supplyType == 2)
                    this.matrix.push(matrixobj);
                    // else
                    //     matrixobjtype1=matrixobj;
                    if (this.supplyType == 1) {
                        matrixobj = {};
                        matrixobj['type'] = 'LV Protective Device';
                        matrixobj['parentid'] = arr['objectcode'];
                        if (this.allnodes != null && this.allnodes.length > 0) {
                            for (var n = 0; n < this.allnodes.length; n++) {
                                if (this.allnodes[n] != 'PD' + arr['id']) {
                                    if (this.allnodes[n] == 'NF' + arr['id']) {
                                        matrixobj['NF' + arr['id']] = '-1';
                                    }
                                    else if (this.allnodes[n] == 'C' + arr['id']) {
                                        matrixobj['C' + arr['id']] = '1';
                                    }
                                    else
                                        matrixobj[this.allnodes[n]] = 0;
                                }
                                else {
                                    matrixobj['nodeid'] = 'PD' + arr['id'];
                                    matrixobj[this.allnodes[n]] = 0;

                                }
                            }
                        }
                        this.matrix.push(matrixobj);

                        // cable

                        matrixobj = {};
                        matrixobj['type'] = 'Cable';
                        matrixobj['parentid'] = 'PD' + arr['id'];
                        if (this.allnodes != null && this.allnodes.length > 0) {
                            for (var n = 0; n < this.allnodes.length; n++) {
                                if (this.allnodes[n] != 'C' + arr['id']) {
                                    if (this.allnodes[n] == 'PD' + arr['id']) {
                                        matrixobj['PD' + arr['id']] = '-1';
                                    }
                                    else
                                        matrixobj[this.allnodes[n]] = 0;

                                }
                                else {
                                    matrixobj['nodeid'] = 'C' + arr['id'];
                                    matrixobj[this.allnodes[n]] = 0;
                                }
                            }
                        }
                        this.matrix.push(matrixobj);






                    }
                    else if (this.supplyType == 3) {

                        // lv protective device cable
                        matrixobj = {};
                        matrixobj['type'] = 'LV Protective Device';
                        matrixobj['parentid'] = arr['objectcode'];
                        if (this.allnodes != null && this.allnodes.length > 0) {
                            for (var n = 0; n < this.allnodes.length; n++) {
                                if (this.allnodes[n] != 'PD' + arr['id']) {
                                    if (this.allnodes[n] == 'Gen' + arr['id'] && !this.isHVRelay) {
                                        matrixobj['Gen' + arr['id']] = '-1';
                                    }
                                    if (this.allnodes[n] == 'HVR' + arr['id'] && this.isHVRelay) {
                                        matrixobj['HVR' + arr['id']] = '-1';
                                    }
                                    else if (this.allnodes[n] == 'C' + arr['id']) {
                                        matrixobj['C' + arr['id']] = '1';
                                    }
                                    else
                                        matrixobj[this.allnodes[n]] = 0;
                                }
                                else {
                                    matrixobj['nodeid'] = 'PD' + arr['id'];
                                    matrixobj[this.allnodes[n]] = 0;

                                }
                            }
                        }
                        this.matrix.push(matrixobj);

                        // cable

                        matrixobj = {};
                        matrixobj['type'] = 'Cable';
                        matrixobj['parentid'] = 'PD' + arr['id'];
                        if (this.allnodes != null && this.allnodes.length > 0) {
                            for (var n = 0; n < this.allnodes.length; n++) {
                                if (this.allnodes[n] != 'C' + arr['id']) {
                                    if (this.allnodes[n] == 'PD' + arr['id']) {
                                        matrixobj['PD' + arr['id']] = '-1';
                                    }
                                    else
                                        matrixobj[this.allnodes[n]] = 0;

                                }
                                else {
                                    matrixobj['nodeid'] = 'C' + arr['id'];
                                    matrixobj[this.allnodes[n]] = 0;
                                }
                            }
                        }
                        this.matrix.push(matrixobj);






                    }
                    else if (this.supplyType == 0 || this.supplyType == 2) {

                        // lv protective device cable
                        matrixobj = {};
                        matrixobj['type'] = 'LV Protective Device';
                        matrixobj['parentid'] = arr['objectcode'];
                        if (this.allnodes != null && this.allnodes.length > 0) {
                            for (var n = 0; n < this.allnodes.length; n++) {
                                if (this.allnodes[n] != 'PD' + arr['id']) {
                                    if (this.allnodes[n] == arr['id']) {
                                        matrixobj[arr['id']] = '-1';
                                    }
                                    else if (this.allnodes[n] == 'C' + arr['id']) {
                                        matrixobj['C' + arr['id']] = '1';
                                    }
                                    else
                                        matrixobj[this.allnodes[n]] = 0;
                                }
                                else {
                                    matrixobj['nodeid'] = 'PD' + arr['id'];
                                    matrixobj[this.allnodes[n]] = 0;

                                }
                            }
                        }
                        this.matrix.push(matrixobj);

                        // cable

                        matrixobj = {};
                        matrixobj['type'] = 'Cable';
                        matrixobj['parentid'] = 'PD' + arr['id'];
                        if (this.allnodes != null && this.allnodes.length > 0) {
                            for (var n = 0; n < this.allnodes.length; n++) {
                                if (this.allnodes[n] != 'C' + arr['id']) {
                                    if (this.allnodes[n] == 'PD' + arr['id']) {
                                        matrixobj['PD' + arr['id']] = '-1';
                                    }
                                    else
                                        matrixobj[this.allnodes[n]] = 0;
                                    // else if(this.allnodes[n]=='C'+arr['id'])
                                    // {
                                    //     matrixobj['C'+arr['id']]='1';
                                    // }

                                }
                                else {
                                    matrixobj['nodeid'] = 'C' + arr['id'];
                                    matrixobj[this.allnodes[n]] = 0;
                                }
                            }
                        }
                        this.matrix.push(matrixobj);

                    }
                }
            }
        }

    }


    formatData(arr, selnode) {
        arr.forEach(i => {

            if (_.isEqual(i.text, selnode.text)) {
                var childlevel = i.level + 1;
                if (i.children.length > 0) {
                    childlevel = i.children.length + 1;
                }
                i.children = [...i.children, {
                    text: 'New',
                    value: 50,
                    collapsed: false,
                    level: childlevel,
                    children: []
                }]
            } else {
                this.formatData(i.children, selnode);
            }
        });
    }
    AddClick() {
        if (this.selnode != null) {
            this.formatData(this.items, this.selnode);
        }
    }



    selectresultstable() {
        this.resultSectionData = parseInt(this.selectResults);
        $('#faultleveltable').hide();
        $('#maxdemandtable').hide();
        $('#loadScheduletable').hide();
        $('#protectiveDeviceSourcetable').hide();
        $('#Voltagedroptable').hide();
        $('#Accumulatedvoltagedroptable').hide();
        $('#Faultloopimpedancetable').hide();
        $('#Cablesdatatable').hide();
        $('#Maximumdemandtable').hide();
        $('#Powerfactorcorrectiontable').hide();
        $('#arcFlashResulttable').hide();


        if (this.selectResults == "0") {
            $('#faultleveltable').show();
        }
        else if (this.selectResults == "1") {
            $('#Voltagedroptable').show();
        }
        else if (this.selectResults == "2") {
            $('#Accumulatedvoltagedroptable').show();
        }
        else if (this.selectResults == "3") {
            $('#Faultloopimpedancetable').show();
        }
        else if (this.selectResults == "4") {
            $('#Cablesdatatable').show();
        }
        else if (this.selectResults == "5") {
            $('#Maximumdemandtable').show();
        }
        else if (this.selectResults == "6") {
            $('#Powerfactorcorrectiontable').show();
        }
        else if (this.selectResults == "7") {
            $('#loadScheduletable').show();
        }
        else if (this.selectResults == "8") {
            $('#maxdemandtable').show();
        }
        else if (this.selectResults == "9") {
            $('#protectiveDeviceSourcetable').show();
        }
        else if (this.selectResults == "10") {
            $('#arcFlashResulttable').show();
        }
    }

    ngOnInit(): void {

        this.innerWidth = window.innerWidth;
        this.inputSubject
            .pipe(debounceTime(1000)) // Adjust the debounce time (in milliseconds) as needed
            .subscribe((value: any) => {
                this.GetTable271Dr(value.e, value.type, value.checkdecimalplaces);
            });
        //FOR TOOLTIP
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover({ placement: 'auto', trigger: 'hover' });

        $('#ResultsSelection').hide();
        $('#faultleveltable').hide();
        $('#maxdemandtable').hide();
        $('#loadScheduletable').hide();
        $('#Voltagedroptable').hide();
        $('#Accumulatedvoltagedroptable').hide();
        $('#Faultloopimpedancetable').hide();
        $('#Cablesdatatable').hide();
        $('#Maximumdemandtable').hide();
        $('#Powerfactorcorrectiontable').hide();

        //SUPPLY TYPES
        $('#SupplyNetworkFeeder').hide();
        $('#SupplyTransformer').hide();
        $('#SupplyGenerator').hide();
        $('#SupplyPowerStation').hide();

        this.selectedValmodel = 3;
        setTimeout(() => {
            $('#selectNetwork').on('change', (e: any, isTriggered) => {
                this.tempinputXML.isSingleLineDiagram = 0;
                this.showScroll = true;
                this.selectedNetworkmodel = parseInt(e.target.options[e.target.selectedIndex].value);
                if (this.selectedNetworkmodel == 1) {
                    setTimeout(() => {
                        document.getElementById('networktab3').style.display = "none";
                    });
                }
                if (this.selectedNetworkmodel == 2) {
                    this.tree.treeModel.getNodeById(1).setActiveAndVisible();
                    setTimeout(() => {
                        this.activeNode = this.tree.treeModel.getNodeById(1).data;
                        $('#Supplychild').css('display', 'block');
                        $('#tempresults').css('display', 'block');
                        document.getElementById('networktab3').style.display = "none";
                        document.getElementById('networktab1').style.display = "block";
                    });
                }
                this.supplyTypeReopenData = true;

                this.selectchild(this.selectedNetworkmodel);

                if (this.selectedNetworkmodel === 0) {
                    this.tree.treeModel.getNodeById(2).setActiveAndVisible();
                    this.selectedNetworkData = this.items[0].children[0];
                    this.changediv(this.selectedNetworkData);

                    setTimeout(() => {
                        document.getElementById('networktab3').style.display = "block";
                        document.getElementById('aSwitchboard').click();
                    });
                }
                else if (this.selectedNetworkmodel === 1) {
                    this.tree.treeModel.getNodeById(1).setActiveAndVisible();
                    setTimeout(() => {
                        this.activeNode = this.tree.treeModel.getNodeById(1).data;
                        this.isMobile = false;
                        $('#tempresults').hide();
                        $("#calculationresultstab").hide();
                        document.getElementById('networktab1').style.display = "block";
                    });
                }
                else if (this.selectedNetworkmodel === 2) {
                    setTimeout(() => {
                        this.isMobile = true;
                        $("#calculationresultstab").show();
                    });
                }
                else if (this.selectedNetworkmodel === 4) {
                    this.cd.markForCheck();
                }

                if (this.selectedNetworkmodel == 3 || this.selectedNetworkmodel === 4) {
                    setTimeout(() => {
                        var i, tabcontent;
                        tabcontent = document.getElementById("networktabslist").getElementsByClassName("network-tabcontent");
                        for (i = 0; i < tabcontent.length; i++) {
                            tabcontent[i].style.display = "none";
                        }
                        document.getElementById('networktab1').style.display = "block";
                    });
                }
            });


            //#region Load        

            $(document).off('change', '.loadname');
            $('.loadname').on('change', (e: any) => {
                var items = this.items;
                // this.updatename(items,2,$('.loadname').val().toString());
                this.updatenamebyId(items, 2, $('.loadname').val().toString(), JSON.parse(sessionStorage.getItem("currentnode")).objectcode);
                this.updateloaddata(e, 'loadname');

            });

            $(document).off('change', '#radio3Phase');
            $(document).on('change', '#radio3Phase', (e: any, isTriggered) => {
                // alert('Phase 1 ');
                this.updateloaddata(e, 'phasesradio');
            });

            $(document).off('change', '#radio1Phase');
            $(document).on('change', '#radio1Phase', (e: any, isTriggered) => {
                // alert('Phase 3 ');
                this.updateloaddata(e, 'phasesradio');
            });
            $(document).off('change', '#Phase1Current');

            $('#Phase1Current').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateloaddata(e, 'Phase1Current');
                // alert(e.target.options[e.target.selectedIndex].value);
            });
            $(document).off('change', '#Phase1Phase');

            $('#Phase1Phase').on('change', (e: any, isTriggered) => {
                if (isTriggered == "true") {
                    this.loadStatus = true;
                }
                else {
                    this.loadStatus = false;
                }
                this.updateloaddata(e, 'Phase1Phase');
                // alert(e.target.options[e.target.selectedIndex].value);
            });
            $(document).off('change', '#Phase1Powerfactor');

            $('#Phase1Powerfactor').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateloaddata(e, 'Phase1Powerfactor');
                // alert(e.target.options[e.target.selectedIndex].value);
            });
            $(document).off('change', '#Phase1Harmonics');

            $('#Phase1Harmonics').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateloaddata(e, 'Phase1Harmonics');
                // alert(e.target.options[e.target.selectedIndex].value);
            });

            $(document).off('change', '#balcurrent');
            $(document).on('change', '#balcurrent', (e: any, isTriggered) => {
                // alert('Phase 3 ');
                this.updateloaddata(e, 'balcurrent');
            });

            $(document).off('change', '#unbalcurrent');
            $(document).on('change', '#unbalcurrent', (e: any, isTriggered) => {
                // alert('Phase 3 ');
                this.updateloaddata(e, 'unbalcurrent');
            });

            $(document).off('change', '#Phase3PhaseCurrent');
            $('#Phase3PhaseCurrent').on('change', (e: any, isTriggered) => {
                // alert(e);
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateloaddata(e, 'Phase3PhaseCurrent');
            });

            $('#Phase3Powerfactor').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateloaddata(e, 'Phase3Powerfactor');
            });

            $('#Phase3HarmonicsBal').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateloaddata(e, 'Phase3HarmonicsBal');
            });

            $('#Phase3PhaseCurrentL1').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateloaddata(e, 'Phase3PhaseCurrentL1');
            });
            $('#Phase3PhaseCurrentL2').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateloaddata(e, 'Phase3PhaseCurrentL2');
            });
            $('#Phase3PhaseCurrentL3').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateloaddata(e, 'Phase3PhaseCurrentL3');
            });

            $('#Phase3PowerfactorL1').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateloaddata(e, 'Phase3PowerfactorL1');
            });
            $('#Phase3PowerfactorL2').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateloaddata(e, 'Phase3PowerfactorL2');
            });
            $('#Phase3PowerfactorL3').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateloaddata(e, 'Phase3PowerfactorL3');
            });

            $('#Phase3HarmonicsUnBal').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateloaddata(e, 'Phase3HarmonicsUnBal');
            });

            //#endregion


            //#region  switchboard

            $(document).off('change', '.sbname');
            $('.sbname').on('change', (e: any) => {
                // var items=  this.items;        
                // this.updatename(items,1,$('.sbname').val().toString());
                this.updateswitchdata(e, "switchName");


                var treeitems = this.items;
                this.updatenamebyId(treeitems, 1, $('#switchName').val().toString(), JSON.parse(sessionStorage.getItem("currentnode")).objectcode);


            });

            // $(document).off("change", "#switchName");
            // $("#switchName").on("change", "#switchName", (e: any, isTriggered) => {
            //     this.updateswitchdata(e, "switchName");

            //     var treeitems=  this.items;        
            //     this.updatenamebyId(treeitems,1,$('#switchName').val().toString());
            // });

            $(document).off('change', '#switchradio3phase');
            $('#switchradio3phase').on('change', (e: any, isTriggered) => {
                //alert('Phase 3 ');
                this.updateswitchdata(e, 'switchphasesradio');
            });

            $(document).off('change', '#switchradio1phase');
            $('#switchradio1phase').on('change', (e: any, isTriggered) => {
                //alert('Phase 1 ');
                this.updateswitchdata(e, 'switchphasesradio');
            });



            $(document).off("change", "#SwitchBoard_Total_Maximum_Demand");
            $("#SwitchBoard_Total_Maximum_Demand").on("change", (e: any, isTriggered) => {

                this.selectswitchboardcustom(e);
                if (isTriggered !== "true") {
                    this.updateswitchdata(e, "SwitchBoard_Total_Maximum_Demand");
                }
            });

            $(document).off("change", "#switchvoltagedrop");
            $('#switchvoltagedrop').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateswitchdata(e, 'switchvoltagedrop');
                // alert(e.target.options[e.target.selectedIndex].value);
            });

            //Phase 1
            $(document).off('change', "#SwitchBoard_Phase_1_Current");
            $("#SwitchBoard_Phase_1_Current").on("change", (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateswitchdata(e, 'SwitchBoard_Phase_1_Current');
            });

            $(document).off('change', "#SwitchBoard_Phase_1_Phase");
            $("#SwitchBoard_Phase_1_Phase").on("change", (e: any, isTriggered) => {

                if (isTriggered !== "true") {
                    this.updateswitchdata(e, 'SwitchBoard_Phase_1_Phase');
                }
            });

            $(document).off('change', "#SwitchBoard_Phase_1_Power_factor");
            $("#SwitchBoard_Phase_1_Power_factor").on("change", (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateswitchdata(e, 'SwitchBoard_Phase_1_Power_factor');
            });

            //Phase 3
            $(document).off('change', "#balradio");
            $("#balradio").on("change", (e: any, isTriggered) => {
                this.updateswitchdata(e, 'SwitchBoard_Phase_3_Currents');
            });

            $(document).off('change', "#unbalradio");
            $("#unbalradio").on("change", (e: any, isTriggered) => {
                this.updateswitchdata(e, 'SwitchBoard_Phase_3_Currents');
            });

            $(document).off("change", "#SwitchBoard_Phase_3_Phase_Current");
            $("#SwitchBoard_Phase_3_Phase_Current").on("change", (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateswitchdata(e, "SwitchBoard_Phase_3_Phase_Current");
            });

            $(document).off("change", "#SwitchBoard_Phase_3_Phase_Power_factor");
            $("#SwitchBoard_Phase_3_Phase_Power_factor").on("change", (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateswitchdata(e, "SwitchBoard_Phase_3_Phase_Power_factor");
            });        //#endregion

            $(document).off("change", "#SwitchBoard_Phase_3_Phase_Current_L1");
            $("#SwitchBoard_Phase_3_Phase_Current_L1").on("change", (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateswitchdata(e, "SwitchBoard_Phase_3_Phase_Current_L1");
            });

            $(document).off("change", "#SwitchBoard_Phase_3_Phase_Current_L2");
            $("#SwitchBoard_Phase_3_Phase_Current_L2").on("change", (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateswitchdata(e, "SwitchBoard_Phase_3_Phase_Current_L2");
            });

            $(document).off("change", "#SwitchBoard_Phase_3_Phase_Current_L3");
            $("#SwitchBoard_Phase_3_Phase_Current_L3").on("change", (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateswitchdata(e, "SwitchBoard_Phase_3_Phase_Current_L3");
            });


            $(document).off("change", "#SwitchBoard_Phase_3_Phase_Power_factor_L1");
            $("#SwitchBoard_Phase_3_Phase_Power_factor_L1").on("change", (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateswitchdata(e, "SwitchBoard_Phase_3_Phase_Power_factor_L1");
            });

            $(document).off("change", "#SwitchBoard_Phase_3_Phase_Power_factor_L2");
            $("#SwitchBoard_Phase_3_Phase_Power_factor_L2").on("change", (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateswitchdata(e, "SwitchBoard_Phase_3_Phase_Power_factor_L2");
            });

            $(document).off("change", "#SwitchBoard_Phase_3_Phase_Power_factor_L3");
            $("#SwitchBoard_Phase_3_Phase_Power_factor_L3").on("change", (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updateswitchdata(e, "SwitchBoard_Phase_3_Phase_Power_factor_L3");
            });



            //#endregion


            //#region Capacitor
            $(document).off('change', '#NetworkCapacitorBank_Name');
            $('#NetworkCapacitorBank_Name').on('change', (e: any, isTriggered) => {


                var treeitems = this.items;
                this.updatenamebyId(treeitems, 3, $('#NetworkCapacitorBank_Name').val().toString(), JSON.parse(sessionStorage.getItem("currentnode")).objectcode);

                this.updateCapacitorBank();
                this.onInputChange();
            });
            // $(document).off("change", "#NetworkCapacitorBank_Power_Factor_After_Correction");
            // $("#NetworkCapacitorBank_Power_Factor_After_Correction").on("change", (e: any, isTriggered) => {
            //     this.Allowdecimalwithplaces(e, 3);
            //     this.updateCapacitorBank();
            // });
            // $(document).off("change", "#NetworkCapacitorBank_Power_Factor_Before_Correction");
            // $("#NetworkCapacitorBank_Power_Factor_Before_Correction").on("change", (e: any, isTriggered) => {
            //     this.Allowdecimalwithplaces(e, 3);
            //     this.onInputChange();
            // });
            $(document).off("change", "#NetworkCapacitorBank_Rating");
            $("#NetworkCapacitorBank_Rating").on("change", (e: any, isTriggered) => {
                this.Allowdecimalwithplaces(e, 1);
                this.updateCapacitorBank();
            });
            $(document).off("change", "#NetworkCapacitorBank_Required_Compensation");
            $("#NetworkCapacitorBank_Required_Compensation").on("change", (e: any, isTriggered) => {
                this.Allowdecimalwithplaces(e, 1);
                this.onInputChange();
            });
            let capacitorBank = "10";
            $("#PFC_Capacitor_Bank_To_Install").val(capacitorBank).trigger('change', "true");
            $('#PFC_Auto_Size_Capacitor_Bank').prop('checked', true).trigger('change', 'true');
            $('#powerfactorinstall').css('display', 'none');

            //#endregion


            //#region power factor correction

            $(document).off('change', '#PFC_Target_Power_Factor');
            $('#PFC_Target_Power_Factor').on('change', (e: any, isTriggered) => {

                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);

                this.updatePowerFactorCorrection();

                this.onInputChange();
            });


            $(document).off('change', '#PFC_Capacitor_Bank_To_Install');
            $('#PFC_Capacitor_Bank_To_Install').on('change', (e: any, isTriggered) => {

                if (isTriggered !== "true")
                // alert(e.target.value.toString());
                {
                    $('#PFC_Capacitor_Bank_Custom_Rating_Cell1').hide();
                    $('#PFC_Capacitor_Bank_Custom_Rating_Cell2').hide();

                    if (e.target.value.toString() == "11") {
                        $('#PFC_Capacitor_Bank_Custom_Rating_Cell1').show();
                        $('#PFC_Capacitor_Bank_Custom_Rating_Cell2').show();
                    }

                    this.updatePowerFactorCorrection();

                    this.onInputChange();
                }
            });

            $(document).off('change', '#PFC_Auto_Size_Capacitor_Bank');
            $('#PFC_Auto_Size_Capacitor_Bank').on('change', (e: any, isTriggered) => {

                this.checkPFC(e);
                if (isTriggered != "true") {
                    this.updatePowerFactorCorrection();
                    this.onInputChange();
                }

            });

            // $(document).off('change', '#PFC_Capacitor_Bank_To_Install');
            // $('#PFC_Capacitor_Bank_To_Install').on('change', (e: any, isTriggered) => {            
            //     if(isTriggered != "true")
            //     this.updatePowerFactorCorrection();

            //     this.onInputChange();
            // });

            $(document).off('change', '#PFC_Capacitor_Bank_Custom_Rating');
            $('#PFC_Capacitor_Bank_Custom_Rating').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 3);
                this.updatePowerFactorCorrection();
                this.onInputChange();
            });

            $(document).off('change', '#PFC_Economic_Calculation');
            $('#PFC_Economic_Calculation').on('change', (e: any, isTriggered) => {
                this.checkeco(e);

                if (isTriggered != "true") {
                    this.updatePowerFactorCorrection();
                    this.onInputChange();
                }
            });

            $(document).off('change', '#PFC_Installation_Cost');
            $('#PFC_Installation_Cost').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.AllowOnlyNumericValues(e);
                this.updatePowerFactorCorrection();
                this.onInputChange();
            });

            $(document).off('change', '#PFC_Demand_Charge');
            $('#PFC_Demand_Charge').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 1);
                this.updatePowerFactorCorrection();
                this.onInputChange();
            });


            $(document).off('change', '#PFC_Utility_Factor');
            $('#PFC_Utility_Factor').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updatePowerFactorCorrection();
                this.onInputChange();
            });


            $(document).off('change', '#PFC_Benefit_Time_Period');
            $('#PFC_Benefit_Time_Period').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.AllowOnlyNumericValues(e);
                this.updatePowerFactorCorrection();
                this.onInputChange();
            });

            $(document).off('change', '#PFC_Interest_Rate');
            $('#PFC_Interest_Rate').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.updatePowerFactorCorrection();
                this.onInputChange();
            });


            //#endregion




            //#region Network Settings

            // $(document).off('change', '#SupplyVoltage');
            // $('#SupplyVoltage').on('change', (e: any, isTriggered) => {
            //     if (isTriggered != "true") {
            //         //this.getDefaultSupplyVoltage();
            //         this.onInputChange();
            //     }

            // });

            // $(document).off('change', '#Frequency');
            // $('#Frequency').on('change', (e: any, isTriggered) => {
            //     if (isTriggered != "true") {
            //         this.onInputChange();
            //     }
            // });

            // $(document).off('change', '#VoltageTolerance');
            // $('#VoltageTolerance').on('change', (e: any, isTriggered) => {
            //     if (isTriggered != "true") {
            //         this.onInputChange();
            //     }
            // });

            $(document).off('change', '#AllowableVoltageDrop_Network');
            $('#AllowableVoltageDrop_Network').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.onInputChange();

            });
            $(document).off('change', '#AllowableVoltageDrop_MainsCable');
            $('#AllowableVoltageDrop_MainsCable').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.onInputChange();
            });

            // $(document).off('change', '#CableCalcStandard');
            // $('#CableCalcStandard').on('change', (e: any, isTriggered) => {
            //     if (isTriggered != "true") {
            //         if ((this.cableCalculationStandard == '0' || this.cableCalculationStandard == '1') && (e.target.value == '0' || e.target.value == '1')) {
            //             // this.showLoader();
            //             this.cableCalculationStandard = e.target.value;
            //             this.onEarthinSystemChange();
            //             this.ReportStandardCountry = this.cableCalculationStandard;
            //             if (this.ReportStandardCountry == "2") {
            //                 this.isBritishStandard = true;
            //             }
            //             else
            //                 this.isBritishStandard = false;
            //             let cableRecords = JSON.parse(this.tempinputXML.CableSizeCalcInputs);

            //             if (cableRecords.length > 0) {
            //                 cableRecords.forEach(element => {
            //                     element.CableSizeCalcInputXML['calculationstandard'] = this.cableCalculationStandard;
            //                 });
            //                 this.tempcablesizelist = [];
            //                 this.tempcablesizelist = cableRecords;
            //                 this.tempinputXML.CableSizeCalcInputs = JSON.stringify(cableRecords);
            //             }
            //             // this.UserWizard = false;
            //             // $('#checkboxUseWizard').removeAttr('checked');
            //             // $('#checkboxUseWizard').val('0').trigger('change', "true");
            //             //this.IsCableCalcStandardChanged = true;
            //             this.onInputChange();
            //         }
            //         else {
            //             this.oldCableCalculationStandard = this.cableCalculationStandard;
            //             this.cableCalculationStandard = e.target.value;
            //             this.onEarthinSystemChange();
            //             this.ReportStandardCountry = this.cableCalculationStandard;
            //             if (this.ReportStandardCountry == "2") {
            //                 this.isBritishStandard = true;
            //             }
            //             else
            //                 this.isBritishStandard = false;
            //             // this.tempinputXML.NetworkSettings.CableCalcStandard = this.cableCalculationStandard;
            //             this.showResetStandardPopup();
            //         }
            //     }
            // });

            // $(document).off('change', '#TransformerTypicalDataSource');
            // $('#TransformerTypicalDataSource').on('change', (e: any, isTriggered) => {
            //     if (isTriggered != "true") {
            //         this.onInputChange();
            //     }
            // });

            // $(document).off('change', '#CableCalcProtectiveDeviceSource');
            // $('#CableCalcProtectiveDeviceSource').on('change', (e: any, isTriggered) => {
            //     if (isTriggered != "true") {
            //         this.oldCableCalcProtectiveDeviceSource = this.cableCalcProtectiveDeviceSource;
            //         this.cableCalcProtectiveDeviceSource = e.target.value;
            //         this.showChangeProtectiveDeviceSourcePopup();
            //     }
            // });

            // $(document).off('change', '#ProtectionCoordinationStandard');
            // $('#ProtectionCoordinationStandard').on('change', (e: any, isTriggered) => {
            //     if (isTriggered != "true") {
            //         this.protectionCoordinationStandard = e.target.value;
            //         this.onInputChange();
            //     }
            // });
            //#endregion



            // $(document).off('change', '#networksupplytype');
            // $('#networksupplytype').on('change', (e: any, isTriggered) => {
            //     let oldObjectCode = this.items[0].objectcode;
            //     sessionStorage.setItem('SupplyType', "Supply");
            //     this.selectsupplychildren(e);

            //     if (isTriggered == undefined) {
            //         if (e.target.value == 2 || e.target.value == 4) {
            //             if (this.powerHVRelay || this.transformerHVRelay) {
            //                 this.powerHVRelay = false;
            //                 $('#checkSupplyHVRelay').prop('checked', false);
            //                 this.transformerHVRelay = false;
            //                 $('#PowerStationHVRelay').prop('checked', false);
            //             }
            //             if (this.HVFuse == true) {
            //                 this.HVFuse = false;
            //                 $('#HVFuseChk').prop('checked', false);
            //                 this.deleteProtectionData(oldObjectCode, false);
            //             }
            //         }
            //         if (this.cableCalcProtectiveDeviceSource == "1") {
            //             this.index = 0;
            //             this.getNetworkProtectiveDataByNodeId(this.newCalculationId, this.activeNode.objectcode);
            //         }
            //     }
            //     if (isTriggered !== "true") {
            //         this.onInputChange();
            //     }
            //     this.supplyTypeReopenData = false;
            // });


            $(document).off('change', '#Networkfeeder_Fault_Level');
            $('#Networkfeeder_Fault_Level').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 3);
                this.onInputChange();
            });


            $(document).off('change', '#networksupplyradio1');
            $('#networksupplyradio1').on('change', (e: any, isTriggered) => {
                this.onInputChange();
            });
            $(document).off('change', '#networksupplyradio1');
            $('#networksupplyradio2').on('change', (e: any, isTriggered) => {
                this.onInputChange();
            })

            $(document).off('change', '#ExistingSwitchboard_Fault_Level');
            $('#ExistingSwitchboard_Fault_Level').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 3);
                this.onInputChange();
            });

            // if($('#ExistingSwitchboard_Fault_Level').val() != undefined)
            // {
            //     this.tempinputXML.SupplySettings.ExistingSwitchBoard_Fault_Level = parseInt($('#ExistingSwitchBoard_Fault_Level').val().toString());
            // }

            //#region Transformer

            $(document).off('change', '#Transformer_Primary_Voltage');
            $('#Transformer_Primary_Voltage').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.AllowOnlyNumericValues(e);
                this.onInputChange();
            });
            $(document).off('change', '#Transformer_Primary_Fault_Level');
            $('#Transformer_Primary_Fault_Level').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 3);
                this.onInputChange();
            });



            $(document).off('change', '#checkSupplyHVRelay');
            $('#checkSupplyHVRelay').on('change', (e: any, isTriggered) => {
                this.onInputChange();
            });

            // $(document).off('change', '#Autosizetransformer');
            // $('#Autosizetransformer').on('change', (e: any, isTriggered) => {

            //     if (isTriggered != 'true') {
            //         if (e.target.checked == false) {
            //             $('#Autosizetransformerrow1').show();
            //             $('#Autosizetransformerrow2').show();
            //         }
            //         if (e.target.checked == true) {
            //             $('#Autosizetransformerrow1').hide();
            //             $('#Autosizetransformerrow2').hide();
            //         }

            //         this.onInputChange();
            //     }
            // });


            // $(document).off('change', '#Customtransformer');
            // $('#Customtransformer').on('change', (e: any, isTriggered) => {

            //     $('#Customtransformerrow1').show();
            //     $('#Customtransformerrow2').show();

            //     if (e.target.checked == false) {
            //         $('#Customtransformerrow1').hide();
            //         $('#Customtransformerrow2').hide();

            //     }
            //     this.onInputChange();
            // });

            // $(document).off('change', '#Impedencecorrection');
            // $('#Impedencecorrection').on('change', (e: any, isTriggered) => {

            //     $('#Impedencecorrectionrow').show();

            //     if (e.target.checked == false)
            //         $('#Impedencecorrectionrow').hide();

            //     this.onInputChange();

            // });

            $(document).off('change', '#Transformer_No_Of_Parallel_Transformers');
            $('#Transformer_No_Of_Parallel_Transformers').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.AllowOnlyNumericValues(e);
                this.onInputChange();
            });

            $(document).off('change', '#Transformer_Custom_Transformer_Impedence');
            $('#Transformer_Custom_Transformer_Impedence').on('change', (e: any, isTriggered) => {
                this.onInputChange();

            });
            $(document).off('change', '#Transformer_Custom_Rating');
            $('#Transformer_Custom_Rating').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 3);
                this.onInputChange();

            });
            $(document).off('change', '#Transformer_Impedence_Voltage');
            $('#Transformer_Impedence_Voltage').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.onInputChange();

            });

            $(document).off('change', '#Transformer_XR_Ratio');
            $('#Transformer_XR_Ratio').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 1);
                this.onInputChange();

            });
            // $(document).off('change', '#Transformer_Calculation_Method');
            // $('#Transformer_Calculation_Method').on('change', (e: any, isTriggered) => {

            //     $('#TransformerLoadLosses').hide();
            //     $('#Transformer_Load_Losses').hide();

            //     if (e.target.value == 0) {
            //         $('#TransformerLoadLosses').show();
            //         $('#Transformer_Load_Losses').show();
            //     }
            //     if (isTriggered !== "true") {
            //         this.onInputChange();
            //     }
            // });

            $(document).off('change', '#Transformer_Load_Losses');
            $('#Transformer_Load_Losses').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 1);
                this.onInputChange();

            });

            //#endregion


            //#region Generator

            $(document).off('change', '#Generator_Rated_Voltage');
            $('#Generator_Rated_Voltage').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.AllowOnlyNumericValues(e);
                this.onInputChange();
            });

            $(document).off('change', '#Generator_Voltage_Regulation');
            $('#Generator_Voltage_Regulation').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 1);
                this.onInputChange();
            });


            $(document).off('change', '#Generator_Power_Factor_Of_Generators');
            $('#Generator_Power_Factor_Of_Generators').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 1);
                this.onInputChange();
            });



            // $(document).off('change', '#Autosizegenerator');
            // $('#Autosizegenerator').on('change', (e: any, isTriggered) => {
            //     if (isTriggered != 'true') {

            //         $('#Autosizegeneratorrow1').hide();
            //         $('#Autosizegeneratorrow2').hide();

            //         if (e.target.checked == false) {
            //             $('#Autosizegeneratorrow1').show();
            //             $('#Autosizegeneratorrow2').show();

            //         }
            //         this.onInputChange();
            //     }
            // });


            $(document).off('change', '#Generator_No_Of_Parallel_Generators');
            $('#Generator_No_Of_Parallel_Generators').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.AllowOnlyNumericValues(e);
                this.onInputChange();
            });


            $(document).off('change', '#Generator_Customer_Rating');
            $('#Generator_Customer_Rating').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 3);
                this.onInputChange();
            });




            // $(document).off('change', '#Customgenerator');
            // $('#Customgenerator').on('change', (e: any, isTriggered) => {

            //     $('#Customgeneratorrow1').show();
            //     $('#Customgeneratorrow2').show();

            //     if (e.target.checked == false) {
            //         $('#Customgeneratorrow1').hide();
            //         $('#Customgeneratorrow2').hide();
            //     }
            //     this.onInputChange();
            // });

            $(document).off('change', '#Generator_Subtransient_Reactance');
            $('#Generator_Subtransient_Reactance').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 1);
                this.onInputChange();

            });
            $(document).off('change', '#Generator_XDR_Ratio');
            $('#Generator_XDR_Ratio').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 3);
                this.onInputChange();

            });


            // $(document).off('change', '#GeneratorImpedencecorrection');
            // $('#GeneratorImpedencecorrection').on('change', (e: any, isTriggered) => {

            //     $('#GeneratorImpedencecorrectionrow').show();

            //     if (e.target.checked == false)
            //         $('#GeneratorImpedencecorrectionrow').hide();

            //     this.onInputChange();
            // });

            //#endregion


            //#region Power Station

            $(document).off('change', '#Power_Station_Generator_Rated_Voltage');
            $('#Power_Station_Generator_Rated_Voltage').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.AllowOnlyNumericValues(e);
                this.onInputChange();
            });

            $(document).off('change', '#Power_Station_HV_Relay_Device');
            $('#Power_Station_HV_Relay_Device').on('change', (e: any, isTriggered) => {
                this.onInputChange();

            });

            $(document).off('change', '#PowerStationHVRelay');
            $('#PowerStationHVRelay').on('change', (e: any, isTriggered) => {
                this.onInputChange();

            });

            // $(document).off('change', '#Power_Station_Auto_Size_Power_Station');
            // $('#Power_Station_Auto_Size_Power_Station').on('change', (e: any, isTriggered) => {
            //     this.onInputChange();

            // });

            // $(document).off('change', '#Autosizepower');
            // $('#Autosizepower').on('change', (e: any, isTriggered) => {

            //     if (isTriggered != 'true') {
            //         $('#Autosizepowerrow1').hide();
            //         $('#Autosizepowerrow2').hide();


            //         if (e.target.checked == false) {
            //             $('#Autosizepowerrow1').show();
            //             $('#Autosizepowerrow2').show();
            //         }

            //         this.onInputChange();
            //     }
            // });

            // $(document).off('change', '#PowerStationCustomgenerator');
            // $('#PowerStationCustomgenerator').on('change', (e: any, isTriggered) => {

            //     $('#PowerStationCustomgeneratorrow').show();


            //     if (e.target.checked == false) {
            //         $('#PowerStationCustomgeneratorrow').hide();
            //     }

            //     this.onInputChange();
            // });

            // $(document).off('change', '#PowerStationCustomtransformer');
            // $('#PowerStationCustomtransformer').on('change', (e: any, isTriggered) => {

            //     $('#PowerStationCustomtransformerrow').show();


            //     if (e.target.checked == false) {
            //         $('#PowerStationCustomtransformerrow').hide();
            //     }

            //     this.onInputChange();
            // });

            // $(document).off('change', '#PowerStationImpedencecorrection');
            // $('#PowerStationImpedencecorrection').on('change', (e: any, isTriggered) => {

            //     $('#PowerStationImpedencecorrectionrow1').show();
            //     $('#PowerStationImpedencecorrectionrow2').show();


            //     if (e.target.checked == false) {
            //         $('#PowerStationImpedencecorrectionrow1').hide();
            //         $('#PowerStationImpedencecorrectionrow2').hide();
            //     }

            //     this.onInputChange();
            // });


            // $(document).off('change', '#Power_Station_Calc_Transformer_XandR');
            // $('#Power_Station_Calc_Transformer_XandR').on('change', (e: any, isTriggered) => {

            //     $('#Power_Station_Transformer_Load_Losses').show();
            //     $('#power_station_transformer_section').show();

            //     if (e.target.value == 1) {
            //         $('#power_station_transformer_section').hide();
            //         $('#Power_Station_Transformer_Load_Losses').hide();
            //     }
            //     if (isTriggered != "true") {
            //         this.onInputChange();
            //     }
            // });



            $(document).off('change', '#Power_Station_No_Of_Parallel_Units');
            $('#Power_Station_No_Of_Parallel_Units').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.AllowOnlyNumericValues(e);
                this.onInputChange();
            });

            $(document).off('change', '#Power_Station_Generator_Custom_Rating');
            $('#Power_Station_Generator_Custom_Rating').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 3);
                this.onInputChange();

            });


            $(document).off('change', '#Power_Station_Transformer_Custom_Rating');
            $('#Power_Station_Transformer_Custom_Rating').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 3);
                this.onInputChange();

            });

            $(document).off('change', '#Power_Station_Custom_Generator_Impedence');
            $('#Power_Station_Custom_Generator_Impedence').on('change', (e: any, isTriggered) => {
                this.onInputChange();

            });

            $(document).off('change', '#Power_Station_Subtransient_Reactance');
            $('#Power_Station_Subtransient_Reactance').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 1);
                this.onInputChange();

            });

            $(document).off('change', '#Power_Station_XDR_Ratio');
            $('#Power_Station_XDR_Ratio').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 3);
                this.onInputChange();

            });

            $(document).off('change', '#Power_Station_Custom_Transformer_Impedence');
            $('#Power_Station_Custom_Transformer_Impedence').on('change', (e: any, isTriggered) => {
                this.onInputChange();

            });


            $(document).off('change', '#Power_Station_Impedence_Voltage');
            $('#Power_Station_Impedence_Voltage').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2);
                this.onInputChange();

            });

            $(document).off('change', '#Power_Station_XR_Ratio');
            $('#Power_Station_XR_Ratio').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 1);
                this.onInputChange();

            });


            $(document).off('change', '#Power_Station_Power_Factor_Of_Generator');
            $('#Power_Station_Power_Factor_Of_Generator').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 1);
                this.onInputChange();

            });

            $(document).off('change', '#Power_Station_Generator_Voltage_Regulation');
            $('#Power_Station_Generator_Voltage_Regulation').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 1);
                this.onInputChange();

            });

            // $(document).off('change', '#Power_Station_Calc_Transformer_XandR');
            // $('#Power_Station_Calc_Transformer_XandR').on('change', (e: any, isTriggered) => {
            //     if (isTriggered != "true") {
            //         this.onInputChange();
            //     }
            // });


            $(document).off('change', '#Power_Station_Transformer_Load_Losses');
            $('#Power_Station_Transformer_Load_Losses').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 1);
                this.onInputChange();

            });
            //#endregion



            //#region HV Relay


            $('#HVRelay_Curve_Equation')
                .append(
                    '<option value="0">IEC Standard 60255</option>' +
                    '<option value="1">IEEE Standard C37.112</option>' +
                    '<option value="2">Electromechanical IAC</option>' +
                    '<option value="3">Rapid Inverse (RI)</option>');

            $(document).off('change', '#HVRelay_Curve_Equation');
            $('#HVRelay_Curve_Equation').on('change', (e: any, isTriggered) => {


                $('#HVRelay_Curve_Type').empty();

                $('#Curvetyperow').show();

                // alert(e.target.value.toString()+e.target.name);

                if (e.target.value.toString() == "0") {
                    $('#HVRelay_Curve_Type')
                        .append(
                            '<option value="0"> Standard Inverse Time</option>' +
                            '<option value="1"> Very Inverse Time</option>' +
                            '<option value="2"> Long Time Inverse</option>' +
                            '<option value="3"> Extremely Inverse Time</option>' +
                            '<option value="4"> Ultra Inverse Time</option>');
                }
                else if (e.target.value.toString() == "1") {
                    $('#HVRelay_Curve_Type')
                        .append(
                            '<option value="0"> Moderately Inverse</option>' +
                            '<option value="1"> Very Inverse</option>' +
                            '<option value="2"> Extremely Inverse</option>');

                }
                else if (e.target.value.toString() == "2") {
                    $('#HVRelay_Curve_Type')
                        .append(
                            '<option value="0"> Inverse</option>' +
                            '<option value="1"> Very Inverse</option>' +
                            '<option value="2"> Extremely Inverse</option>');

                }
                else if (e.target.value.toString() == "3") {
                    $('#Curvetyperow').hide();
                }

                if (isTriggered !== "true") {
                    this.updateHVRelay();
                    this.onInputChange();
                }

            });

            $(document).off('change', '#HVRelay_Curve_Type');
            $('#HVRelay_Curve_Type').on('change', (e: any, isTriggered) => {
                if (isTriggered != "true") {
                    this.updateHVRelay();
                    this.onInputChange();
                }
            });

            $(document).off('change', '#HVRelay_Pick_Up_Current');
            $('#HVRelay_Pick_Up_Current').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.AllowOnlyNumericValues(e);
                this.updateHVRelay();
                this.onInputChange();
            });

            $(document).off('change', '#HVRelay_TMS_TDS');
            $('#HVRelay_TMS_TDS').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 2)
                this.updateHVRelay();
                this.onInputChange();
            });

            $(document).off('change', '#HVRelay_Max_Disconnection_Time');
            $('#HVRelay_Max_Disconnection_Time').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 3)
                this.updateHVRelay();
                this.onInputChange();
            });

            $(document).off('change', '#HVRelay_Tolerance');
            $('#HVRelay_Tolerance').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.Allowdecimalwithplaces(e, 1)
                this.updateHVRelay();
                this.onInputChange();
            });

            $(document).off('change', '#HVRelay_Tipping_Current_Max_Disconnection_Time');
            $('#HVRelay_Tipping_Current_Max_Disconnection_Time').on('change', (e: any, isTriggered) => {
                this.CommonValidationFn(e);
                this.updateHVRelay();
                this.onInputChange();
            });


            //#endregion




            //#region Results Tab
            // $(document).off('change', '#selectResults');
            // $('#selectResults').on('change', (e: any, isTriggered) => {

            //     this.selectresultstable(e);
            // });

            //#endregion

            // $(document).off('change', '.loadname');
            // $('.loadname').on('change', (e: any) => {
            //     var items=  this.items;        
            //     this.updatename(items,2,$('.loadname').val().toString());
            // });

            // $(document).off('change', '.sbname');
            // $('.sbname').on('change', (e: any) => {
            //     var items=  this.items;        
            //     this.updatename(items,1,$('.sbname').val().toString());
            // });

            $(document).off('change', '.cbname');
            $('.cbname').on('change', (e: any) => {
                var items = this.items;
                // this.updatename(items,3,$('.cbname').val().toString());
                // this.updatenamebyId(items,3,$('.cbname').val().toString());
                this.updatenamebyId(items, 3, $('.cbname').val().toString(), JSON.parse(sessionStorage.getItem("currentnode")).objectcode);

            });
            $(document).off('change', '.clsHV input:checkbox');
            $(document).on('change', '.clsHV input:checkbox', (e: any) => {

                // if ($(e.target).prop('checked') == true) {
                //     this.CableSize['protection']['rcd'] = '1';
                // } else {
                //     this.CableSize['protection']['rcd'] = '0';
                // }

            });

        });
        //this.items = this.getDefaultTreeList();

        this.CableSize = {
            insulation: '0',
            conductor: '0',
            earthconductor: '0',
            core: '0',
            phase: '0',
            faultlevel: '3',
            supplyvoltage: '',
            voltagedrop: '2.5',
            powerunit: '1',
            efficiency: '100',
            lengthofrun: '50',
            current: '100',
            powerfactor: '0.9',
            unit: '0',
            deratingfactor: '1',
            device: '0',
            usevd: '1',
            isVoltageDrop: true,
            protection: {
                shortcircuittime: '0.1',
                MCBrating: '0',
                ACBrating: '0',
                HRCFuserating: '0',
                MCCBrating: '0',
                curve: '0',
                earthfaulttime: '0',
                rcd: '0',
                tripcurrent: '0',
                tripmultiplier: '0',
                thermalsetting: '0',
                customrating: '0',
                description: '0',
                IsUnknown: true,
                Xml: undefined,
                TrippingXml: undefined,
                protectionConfigurationModel: {}
            },
            installtablewind: { wind: '' },
            specifyconductor: {
                phase: {
                    mode: '0',
                    size: '0',
                    circuits: '1'
                },
                neutral: {
                    mode: '0',
                    size: '0',
                    circuits: '1'
                },
                earth: {
                    mode: '1',
                    size: '0',
                    circuits: '1'
                }
            },
            tableno: '7',
            currentTable: '4D1A,2',
            voltagedropTable: '4D1B,3',
            armour: '0',
            columnno: '0',
            startingrowindex: '0',
            endingrowindex: '0',
            description: 'Unenclosed Spaced',
            tablectrlvalue: '0',
            voltagefactor: '1.1',
            calculationstandard: '0',
            arcFlashData: {},
            isderatingwiz: '0',
            deratingtemp: ''
        };

        this.getExistingCalculationData();

        this.drInstallType = 1;
        this.table271model = new Table271Model();
        this.table271model.UseStandard = true;
        // $('#chkPrintName').prop('checked', true);
        this.CalculationReportInput.printName = true;

        // this.ShowNameChanged();
        this._settingService.getSettings().subscribe((result) => {
            if (result.standards_CableSizing !== undefined) {
                this.settingCalculationStandard = result.standards_CableSizing.toString();
                if (!this.standardSettingsData) {
                    this.cableCalculationStandard = result.standards_CableSizing.toString();
                    this.onEarthinSystemChange();
                    this.ReportStandardCountry = this.cableCalculationStandard;
                    if (this.ReportStandardCountry == "2") {
                        this.isBritishStandard = true;
                    }
                    else
                        this.isBritishStandard = false;



                    // this.tempinputXML.NetworkSettings.CableCalcStandard = this.cableCalculationStandard;
                    // $('#CableCalcStandard').val(result.standards_CableSizing.toString()).trigger('change', "true");
                    this.CableSize['calculationstandard'] = result.standards_CableSizing.toString();
                }
                if (!this.protectionCoordinationStandardData) {
                    if (this.cableCalculationStandard == '0' || this.cableCalculationStandard == '1')
                        this.protectionCoordinationStandard = '0';
                    else
                        this.protectionCoordinationStandard = '1';

                    // $('#ProtectionCoordinationStandard').val(this.protectionCoordinationStandard).trigger('change', "true");
                }
                this.CableSize['voltagefactor'] = result.short_Circuit_VoltageFactor_C.toString();
                this.CableSize['dcvoltage'] = result.deafaultVoltages_DcVoltage.toString();
                this.CableSize['onephasevoltage'] = result.deafaultVoltages_OnePhaseAVoltage.toString();
                this.CableSize['threephasevoltage'] = result.deafaultVoltages_ThreePhaseAcvoltage.toString();
                this.dc_Voltage = result.deafaultVoltages_DcVoltage.toString();
                this.onePhase_Voltage = result.deafaultVoltages_OnePhaseAVoltage.toString();
                // $('input[name="Voltage-Supply"]').val(this.onePhase_Voltage);
                this.voltage_txt = this.onePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
                this.threePhase_Voltage = result.deafaultVoltages_ThreePhaseAcvoltage.toString();

                this.standardCountryReport = result.standards_CableSizing;
                //report fields
                this.companyLogo_ShowLogo = result.companyLogo_ShowLogo;
                this.customeReportFields_ShowCustomField = result.customeReportFields_ShowCustomField;
                this.companyLogo_FileName = result.companyLogo_LogoUrl;
                this.customFields = result.customFields;
            } else {
                this.CableSize['calculationstandard'] = '0';
                this.CableSize['voltagefactor'] = '1.1';
                this.CableSize['dcvoltage'] = '1500';
                this.CableSize['onephasevoltage'] = '230';
                this.CableSize['threephasevoltage'] = '400';
                this.dc_Voltage = '1500';
                this.onePhase_Voltage = '230';
                this.threePhase_Voltage = '400';
                this.standardCountryReport = '0';
                this.companyLogo_ShowLogo = false;
                this.customeReportFields_ShowCustomField = false;
                this.companyLogo_FileName = '';
                this.customFields = null;
            }

            var standard = parseInt(this.CableSize['calculationstandard']);

            if (this.drInstallType == 1) {
                if (standard == 0) {
                    this.table271model.AbientTemp = 40;
                }
                else {
                    this.table271model.AbientTemp = 30;
                }
            }
            else {
                if (standard == 0) {
                    this.table271model.AbientTemp = 25;
                }
                else {
                    this.table271model.AbientTemp = 15;
                }
            }
            this.table271model.ConductorTemp = 75;
            this.table271model.drf = 1;
        });

        document.getElementById('defaultOpen').click();
        this.premiumUser = false;
        this.isPlanAddedManually = false;
        this.displayCurrentError = false;
        this.getCurrentLoginInformations();

        this.drBunchingType = 1;
        this.drBunchingCore = 1;
        this.wizard = undefined;
        this.UserWizard = false;
        this.table22model = new Table22Model();
        this.table22model.Arrangement = 1;
        this.table22model.AddType = 1;
        this.table22model.NoOfCircuits = 1
        this.table22model.drf = 1;
        this.drTable22output = null;
        this.drTable22ArrangementValues = [
            { "name": "Bunched in air", "value": 1 },
            { "name": "Bunched on a surface or enclosed", "value": 2 },
            { "name": "Single layer on wall or floor", "value": 3 },
            { "name": "Single layer under ceiling", "value": 4 },
        ];
        this.drTable22ArrangementValuesBs = [
            { "name": "Bunched in air, on a surface, embedded or enclosed - Reference Methods A to F", "value": 1 },
            { "name": "Single layer on wall or floor - Reference Method C", "value": 2 },
            { "name": "Single layer multicore on a perforated horizontal or vertical cable tray system - Reference Method E", "value": 3 },
            { "name": "Single layer multicore on cable ladder system or cleats - Reference Method E", "value": 4 },
        ];
        this.drTable22CircuitValues = [{ "name": 1, "value": 1 },
        { "name": 2, "value": 2 },
        { "name": 3, "value": 3 },
        { "name": 4, "value": 4 },
        { "name": 5, "value": 5 },
        { "name": 6, "value": 6 }];

        /* Soil installation - step 1 - Group of buried circuits */
        this.table25model = new Table25Model();
        this.table25model.NoOfCircuits = 1;
        this.table25model.CableType = 1;
        this.table25model.TouchingType = 1;
        this.table25model.InstallType = 1;
        this.table25model.Distance = 0;
        this.table25model.drf = 1;
        this.table25model.Arrangement = 1;
        this.table25NoOfCircuitValues = [
            { "name": "No derating (1 group)", "value": 1 },
            { "name": "2 groups", "value": 2 },
            { "name": "3 groups", "value": 3 },
            { "name": "4 groups", "value": 4 },
            { "name": "5 groups", "value": 5 },
            { "name": "6 groups", "value": 6 },
            { "name": "7 groups", "value": 7 },
            { "name": "8 groups", "value": 8 },
            { "name": "9 groups", "value": 9 },
            { "name": "10 groups", "value": 10 },
            { "name": "11 groups", "value": 11 },
            { "name": "12 groups", "value": 12 },
        ];
        this.table25NoOfCircuitValuesBS = [
            { "name": "No derating (1 group)", "value": 1 },
            { "name": "2 groups", "value": 2 },
            { "name": "3 groups", "value": 3 },
            { "name": "4 groups", "value": 4 },
            { "name": "5 groups", "value": 5 },
            { "name": "6 groups", "value": 6 },
        ];
        this.table25ArrangementValues = [
            { "name": "Touching trefoil", "value": 1 },
            { "name": "Touching flat", "value": 2 },
            { "name": "Spaced 0.15 m", "value": 3 },
            { "name": "Spaced 0.30 m", "value": 4 },
            { "name": "Spaced 0.45 m", "value": 5 },
            { "name": "Spaced 0.60 m", "value": 6 },
        ];
        this.table25ArrangementValuesBS = [
            { "name": "Nil (cables touching)", "value": 1 },
            { "name": "One cable diameter", "value": 2 },
            { "name": "Spaced 0.125 m", "value": 3 },
            { "name": "Spaced 0.25 m", "value": 4 },
            { "name": "Spaced 0.5 m", "value": 5 },
        ];
        this.setTable25Values(this.table25model.Arrangement);

        /* Soil installation - step 1 - Group of buried circuits */

        /* Soil installation - step 2 - Depth of Burial (m) */
        this.table28model = new Table28Model();
        this.table28model.UseStandard = true;
        this.table28model.Depth = 0.5;
        this.table28model.Buried = 1;
        this.table28model.ConductorSize = 3;
        this.table28model.Core = 1;
        this.table28model.drf = 1;
        this.table28ConductorSizeValues = [
            { "name": "Up to 50 mm²", "value": 1 },
            { "name": "Above 50 mm² up to 300 mm²", "value": 2 },
            { "name": "Above 300 mm²", "value": 3 },
        ];
        /* Soil installation - step 2 - Depth of Burial (m) */

        /* Soil installation - step 3 - Soil thermal resitivity C.m/W */
        this.table29model = new Table29Model();
        this.table29model.UseStandard = true;
        this.table29model.Resitivity = 1.20;
        this.table29model.Installation = 2;
        this.table29model.drf = 1;
        this.table29InstallationValues = [
            { "name": "Multi-core cable buried direct", "value": 1 },
            { "name": "Two or three single core cables buried direct", "value": 2 },
            { "name": "Multi-core cable in a wiring enclosure", "value": 3 },
            { "name": "Two single core cable in a wiring enclosure", "value": 4 },
            { "name": "Tree single core cable in a wiring enclosure", "value": 5 },
        ];
        /* Soil installation - step 3 - Soil thermal resitivity C.m/W */

        /* Soil installation - step 4 - Soil ambient temperature */
        $('#inputUseStandardSoil').attr('disabled', 'true');
        $('#inputUseStandardAir1').attr('disabled', 'true');
        $('#inputUseStandardAir2').attr('disabled', 'true');
        if (this.drInstallType == 2) {
            this.conductorTempValues = [
                // { "name": "150 &#176;C", "value": 150 },
                { "name": "110 &#176;C", "value": 110 },
                { "name": "90 &#176;C", "value": 90 },
                { "name": "80 &#176;C", "value": 80 },
                { "name": "75 &#176;C", "value": 75 },
            ];
        }
        else {
            this.conductorTempValues = [
                { "name": "150 &#176;C", "value": 150 },
                { "name": "110 &#176;C", "value": 110 },
                { "name": "90 &#176;C", "value": 90 },
                { "name": "80 &#176;C", "value": 80 },
                { "name": "75 &#176;C", "value": 75 },
            ];
        }
        /* Soil installation - step 4 - Soil ambient temperature */

        this.table23model = new Table23Model();
        this.table23model.CableType = this.drBunchingCore;
        this.table23model.Row = 1;
        this.table23model.Cables = 1;
        this.table23model.Installation = 1;
        this.table23model.drf = this.drBunchingCore == 1 ? 0.95 : 0.97;
        if (this.drBunchingCore == 1) {
            this.GetTable23Values();
            this.table23CableValues = [
                { "name": "1 cct", "value": 1 },
                { "name": "2 ccts", "value": 2 },
                { "name": "3 ccts", "value": 3 },
            ];
        }
        else {
            this.GetTable24Values();
            this.table23CableValues = [
                { "name": "1", "value": 1 },
                { "name": "2", "value": 2 },
                { "name": "3", "value": 3 },
                { "name": "4", "value": 4 },
                //{ "name": "5", "value": 5 },
                { "name": "6", "value": 6 },
                { "name": "9", "value": 9 },
            ];
        }
        this.table23RowValues = [
            { "name": "1 row", "value": 1 },
            { "name": "2 rows", "value": 2 },
            { "name": "3 rows", "value": 3 },
        ];
        this.table23RowValuesBs = [
            { "name": "1 tray", "value": 1 },
            { "name": "2 trays", "value": 2 },
            { "name": "3 trays", "value": 3 },
            { "name": "6 trays", "value": 6 },
        ];
        $('#divBunchingType2').css('display', 'none');
        $('#divBunchingType3').css('display', 'none');
        $('#divburiedGroupArrangement').css('display', 'none');

        if (localStorage.getItem("ReportSettingEmails")) {
            this.CalculationReportInput.eamilList = localStorage.getItem("ReportSettingEmails");
        }
        setTimeout(() => {
            $('#optobjectType1').click();
        });

        //#region Cable Size
        this.drInstallType = 1;
        this.table271model = new Table271Model();
        this.table271model.UseStandard = true;
        // $('#chkPrintName').prop('checked', true);
        this.CalculationReportInput.printName = true;

        // this.ShowNameChanged();
        this._settingService.getSettings().subscribe((result) => {
            if (result.standards_CableSizing !== undefined) {
                if (!this.standardSettingsData) {
                    this.cableCalculationStandard = result.standards_CableSizing.toString();
                    this.onEarthinSystemChange();
                    this.ReportStandardCountry = this.cableCalculationStandard;
                    if (this.ReportStandardCountry == "2") {
                        this.isBritishStandard = true;
                    }
                    else
                        this.isBritishStandard = false;
                    this.settingCalculationStandard = this.cableCalculationStandard;

                    // this.tempinputXML.NetworkSettings.CableCalcStandard = this.cableCalculationStandard;
                    // $('#CableCalcStandard').val(result.standards_CableSizing.toString()).trigger('change', "true");
                    this.CableSize['calculationstandard'] = result.standards_CableSizing.toString();
                }

                if (!this.protectionCoordinationStandardData) {
                    if (this.cableCalculationStandard == '0' || this.cableCalculationStandard == '1')
                        this.protectionCoordinationStandard = '0';
                    else
                        this.protectionCoordinationStandard = '1';

                    // $('#ProtectionCoordinationStandard').val(this.protectionCoordinationStandard).trigger('change', "true");
                }

                this.CableSize['voltagefactor'] = result.short_Circuit_VoltageFactor_C.toString();
                this.CableSize['dcvoltage'] = result.deafaultVoltages_DcVoltage.toString();
                this.CableSize['onephasevoltage'] = result.deafaultVoltages_OnePhaseAVoltage.toString();
                this.CableSize['threephasevoltage'] = result.deafaultVoltages_ThreePhaseAcvoltage.toString();
                this.dc_Voltage = result.deafaultVoltages_DcVoltage.toString();
                this.onePhase_Voltage = result.deafaultVoltages_OnePhaseAVoltage.toString();
                // $('input[name="Voltage-Supply"]').val(this.onePhase_Voltage);
                this.voltage_txt = this.onePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
                this.threePhase_Voltage = result.deafaultVoltages_ThreePhaseAcvoltage.toString();

                this.standardCountryReport = result.standards_CableSizing;
                //report fields
                this.companyLogo_ShowLogo = result.companyLogo_ShowLogo;
                this.customeReportFields_ShowCustomField = result.customeReportFields_ShowCustomField;
                this.companyLogo_FileName = result.companyLogo_LogoUrl;
                this.customFields = result.customFields;
            } else {
                this.CableSize['calculationstandard'] = '0';
                this.CableSize['voltagefactor'] = '1.1';
                this.CableSize['dcvoltage'] = '1500';
                this.CableSize['onephasevoltage'] = '230';
                this.CableSize['threephasevoltage'] = '400';
                this.dc_Voltage = '1500';
                this.onePhase_Voltage = '230';
                this.threePhase_Voltage = '400';
                this.standardCountryReport = '0';
                this.companyLogo_ShowLogo = false;
                this.customeReportFields_ShowCustomField = false;
                this.companyLogo_FileName = '';
                this.customFields = null;
            }


            var standard = parseInt(this.CableSize['calculationstandard']);

            if (this.drInstallType == 1) {
                if (standard == 0) {
                    this.table271model.AbientTemp = 40;
                }
                else {
                    this.table271model.AbientTemp = 30;
                }
            }
            else {
                if (standard == 0) {
                    this.table271model.AbientTemp = 25;
                }
                else {
                    this.table271model.AbientTemp = 15;
                }
            }
            this.table271model.ConductorTemp = 75;
            this.table271model.drf = 1;
        });

        setTimeout(() => {

            this.specifyConductorChange();
            this.onDropDownChange();
            this.onCheckBoxChange();
            this.onNoEarthChange();
            document.getElementById('defaultOpen').click();
        });
        this.onretrySave();
        this.premiumUser = false;
        this.isPlanAddedManually = false;
        this.displayCurrentError = false;
        this.getCurrentLoginInformations();

        this.drBunchingType = 1;
        this.drBunchingCore = 1;
        this.wizard = undefined;
        this.UserWizard = false;
        this.table22model = new Table22Model();
        this.table22model.Arrangement = 1;
        this.table22model.AddType = 1;
        this.table22model.NoOfCircuits = 1
        this.table22model.drf = 1;
        this.drTable22output = null;
        this.drTable22ArrangementValues = [
            { "name": "Bunched in air", "value": 1 },
            { "name": "Bunched on a surface or enclosed", "value": 2 },
            { "name": "Single layer on wall or floor", "value": 3 },
            { "name": "Single layer under ceiling", "value": 4 },
        ];
        this.drTable22ArrangementValuesBs = [
            { "name": "Bunched in air, on a surface, embedded or enclosed - Reference Methods A to F", "value": 1 },
            { "name": "Single layer on wall or floor - Reference Method C", "value": 2 },
            { "name": "Single layer multicore on a perforated horizontal or vertical cable tray system - Reference Method E", "value": 3 },
            { "name": "Single layer multicore on cable ladder system or cleats - Reference Method E", "value": 4 },
        ];
        this.drTable22CircuitValues = [{ "name": 1, "value": 1 },
        { "name": 2, "value": 2 },
        { "name": 3, "value": 3 },
        { "name": 4, "value": 4 },
        { "name": 5, "value": 5 },
        { "name": 6, "value": 6 }];

        /* Soil installation - step 1 - Group of buried circuits */
        this.table25model = new Table25Model();
        this.table25model.NoOfCircuits = 1;
        this.table25model.CableType = 1;
        this.table25model.TouchingType = 1;
        this.table25model.InstallType = 1;
        this.table25model.Distance = 0;
        this.table25model.drf = 1;
        this.table25model.Arrangement = 1;
        this.table25NoOfCircuitValues = [
            { "name": "No derating (1 group)", "value": 1 },
            { "name": "2 groups", "value": 2 },
            { "name": "3 groups", "value": 3 },
            { "name": "4 groups", "value": 4 },
            { "name": "5 groups", "value": 5 },
            { "name": "6 groups", "value": 6 },
            { "name": "7 groups", "value": 7 },
            { "name": "8 groups", "value": 8 },
            { "name": "9 groups", "value": 9 },
            { "name": "10 groups", "value": 10 },
            { "name": "11 groups", "value": 11 },
            { "name": "12 groups", "value": 12 },
        ];
        this.table25NoOfCircuitValuesBS = [
            { "name": "No derating (1 group)", "value": 1 },
            { "name": "2 groups", "value": 2 },
            { "name": "3 groups", "value": 3 },
            { "name": "4 groups", "value": 4 },
            { "name": "5 groups", "value": 5 },
            { "name": "6 groups", "value": 6 },
        ];
        this.table25ArrangementValues = [
            { "name": "Touching trefoil", "value": 1 },
            { "name": "Touching flat", "value": 2 },
            { "name": "Spaced 0.15 m", "value": 3 },
            { "name": "Spaced 0.30 m", "value": 4 },
            { "name": "Spaced 0.45 m", "value": 5 },
            { "name": "Spaced 0.60 m", "value": 6 },
        ];
        this.table25ArrangementValuesBS = [
            { "name": "Nil (cables touching)", "value": 1 },
            { "name": "One cable diameter", "value": 2 },
            { "name": "Spaced 0.125 m", "value": 3 },
            { "name": "Spaced 0.25 m", "value": 4 },
            { "name": "Spaced 0.5 m", "value": 5 },
        ];
        /* Soil installation - step 1 - Group of buried circuits */

        /* Soil installation - step 2 - Depth of Burial (m) */
        this.table28model = new Table28Model();
        this.table28model.UseStandard = true;
        this.table28model.Depth = 0.5;
        this.table28model.Buried = 1;
        this.table28model.ConductorSize = 3;
        this.table28model.Core = 1;
        this.table28model.drf = 1;
        this.table28ConductorSizeValues = [
            { "name": "Up to 50 mm²", "value": 1 },
            { "name": "Above 50 mm² up to 300 mm²", "value": 2 },
            { "name": "Above 300 mm²", "value": 3 },
        ];
        /* Soil installation - step 2 - Depth of Burial (m) */

        /* Soil installation - step 3 - Soil thermal resitivity C.m/W */
        this.table29model = new Table29Model();
        this.table29model.UseStandard = true;
        this.table29model.Resitivity = 1.20;
        this.table29model.Installation = 2;
        this.table29model.drf = 1;
        this.table29InstallationValues = [
            { "name": "Multi-core cable buried direct", "value": 1 },
            { "name": "Two or three single core cables buried direct", "value": 2 },
            { "name": "Multi-core cable in a wiring enclosure", "value": 3 },
            { "name": "Two single core cable in a wiring enclosure", "value": 4 },
            { "name": "Tree single core cable in a wiring enclosure", "value": 5 },
        ];
        /* Soil installation - step 3 - Soil thermal resitivity C.m/W */

        /* Soil installation - step 4 - Soil ambient temperature */
        $('#inputUseStandardSoil').attr('disabled', 'true');
        $('#inputUseStandardAir1').attr('disabled', 'true');
        $('#inputUseStandardAir2').attr('disabled', 'true');
        if (this.drInstallType == 2) {
            this.conductorTempValues = [
                // { "name": "150 &#176;C", "value": 150 },
                { "name": "110 &#176;C", "value": 110 },
                { "name": "90 &#176;C", "value": 90 },
                { "name": "80 &#176;C", "value": 80 },
                { "name": "75 &#176;C", "value": 75 },
            ];
        }
        else {
            this.conductorTempValues = [
                { "name": "150 &#176;C", "value": 150 },
                { "name": "110 &#176;C", "value": 110 },
                { "name": "90 &#176;C", "value": 90 },
                { "name": "80 &#176;C", "value": 80 },
                { "name": "75 &#176;C", "value": 75 },
            ];
        }
        /* Soil installation - step 4 - Soil ambient temperature */

        this.table23model = new Table23Model();
        this.table23model.CableType = this.drBunchingCore;
        this.table23model.Row = 1;
        this.table23model.Cables = 1;
        this.table23model.Installation = 1;
        this.table23model.drf = this.drBunchingCore == 1 ? 0.95 : 0.97;
        if (this.drBunchingCore == 1) {
            this.GetTable23Values();
            this.table23CableValues = [
                { "name": "1 cct", "value": 1 },
                { "name": "2 ccts", "value": 2 },
                { "name": "3 ccts", "value": 3 },
            ];
        }
        else {
            this.GetTable24Values();
            this.table23CableValues = [
                { "name": "1", "value": 1 },
                { "name": "2", "value": 2 },
                { "name": "3", "value": 3 },
                { "name": "4", "value": 4 },
                //{ "name": "5", "value": 5 },
                { "name": "6", "value": 6 },
                { "name": "9", "value": 9 },
            ];
        }
        this.table23RowValues = [
            { "name": "1 row", "value": 1 },
            { "name": "2 rows", "value": 2 },
            { "name": "3 rows", "value": 3 },
        ];
        this.table23RowValuesBs = [
            { "name": "1 tray", "value": 1 },
            { "name": "2 trays", "value": 2 },
            { "name": "3 trays", "value": 3 },
            { "name": "6 trays", "value": 6 },
        ];
        $('#divBunchingType2').css('display', 'none');
        $('#divBunchingType3').css('display', 'none');
        $('#divburiedGroupArrangement').css('display', 'none');
        //this.onSelectInstall(1);
        $('#ddlunit').on('change', (e: any, isTriggered) => {

            this.currentUnit = (e.target.options[e.target.selectedIndex].value);
            if (this.currentUnit == "1") {
                if (this.voltageSupplyLabel == "rise") {
                    this.lblcurrent = 'Output current';
                }
                else
                    this.lblcurrent = 'Current';
            }
            else {
                if (this.voltageSupplyLabel == "rise") {
                    this.lblcurrent = 'Output power';
                }
                else
                    this.lblcurrent = 'Power';
            }


            if (this.currentUnit == "4") {
                this.showEfficency = true;
            }
            else {
                this.showEfficency = false;

            }

            if (this.currentUnit != null && this.currentUnit != "") {

                this.CableSize['powerunit'] = this.currentUnit;
                if (isTriggered !== "true") {
                    this.updatecablesizeinarray();
                    this.getAllControlsValue();
                }

            }
        });

        if (localStorage.getItem("ReportSettingEmails")) {
            this.CalculationReportInput.eamilList = localStorage.getItem("ReportSettingEmails");
        }
        //#endregion


        //#region Auto max demand
        this.electricInstallationValues = [
            { "name": "Single domestic or individual living unit per phase", value: 5 },
            { "name": "Blocks of living units - 2 to 5 living units per phase", value: 6 },
            { "name": "Blocks of living units - 6 to 20 living units per phase", value: 7 },
            { "name": "Blocks of living units - 21 or more living units per phase", value: 8 },
            { "name": "Residential institutions, hotels, boarding houses, hospitals, accommodation houses, motels", value: 9 },
            { "name": "Factories, shops, stores, offices, business premises, schools and churches", value: 10 },
            // { "name": "Custom", value: 11 }
        ];
        this.phaseAllocationValues = [
            { "name": "Automatic (balance load across 3 phases)", value: 1 },
            { "name": "Specify (lock) per phase quantities", value: 2 }

        ];
        $('#ddlDiversity').select2({
            width: '100%',
            minimumResultsForSearch: Infinity // disable search
        });
        setTimeout(() => {
            $('#ddlElectricInstallation1').select2({
                width: '100%',
                minimumResultsForSearch: Infinity // disable search
            });

        }, 100);

        this.total = 1;


        //this.GetManufacturer();

        this.slidertmiroptions = {
            floor: "0",
            ceil: "1", step: "1"
        }


        this.slidertmimoptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };
        // this.slidermim ="-";
        this.slidermimoptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };
        this.slidercurrenttolerance = "-";
        this.slidercurrenttoleranceoptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };
        this.slidertimetolerance = "-";
        this.slidertimetoleranceoptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };
        this.slidereio = "-";
        this.slidereiooptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };
        this.slidereir = "-";
        this.slidereiroptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };
        this.slideridmtl = "-";
        this.slideridmtloptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };
        this.slideretr = "-";
        this.slideretroptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };
        this.slidereisd = "-";
        this.slidereisdoptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };
        this.slideretsd1 = "1";
        this.slideretsdoptions = {
            showTicks: true,
            stepsArray: [
                { value: "1" }, { value: "2" }

            ]
        };
        this.slidereli = "-";
        this.sliderelioptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };
        this.slidertimetobreakoptions = {
            showTicks: true,
            stepsArray: [
                { value: "-" }

            ]
        };

        this.messageReport = this._settingService.currentMessageReport.subscribe((message) => {
            if (message == 3 || message == 2) {
                this.showLoader();
                this.reportType = message;
                this.GenerateReport();

            }
            if (message == 4) {
                this.GenerateArcFlashLabelDiagram();

            }
        });
    }

    getCompleteLabelString() {



        this.allLabelStrings = [];
        this.allLabelNameStrings = [];
        this.CollectionLabelStrings = [];
        this.CollectionLabelNameStrings = [];
        let uiControls = [];
        let isResults = false, isPpeDetails = false, isPpeCategories = false, isLabel = false, isUiControls = false;

        var data = this.ArcFlashCompleteResult;
        for (let j = 0; j < this.ArcFlashCompleteResult.length; j++) {
            this.allLabelStrings = [];
            this.CollectionLabelNameStrings.push(this.ArcFlashCompleteResult[j].name);

            for (let i = 0; i < this.ArcFlashCompleteResult[j].items.length; i++) {
                if (this.ArcFlashCompleteResult[j].items[i].Val === "Results") {
                    isResults = true;
                    isPpeDetails = false;
                    isPpeCategories = false;
                    isLabel = false;
                    isUiControls = false;
                    continue;
                }
                else if (this.ArcFlashCompleteResult[j].items[i].Val === "PPE Details") {
                    isResults = false;
                    isPpeDetails = true;
                    isPpeCategories = false;
                    isLabel = false;
                    isUiControls = false;
                    continue;
                }
                else if (this.ArcFlashCompleteResult[j].items[i].Val === "PPE Categories") {
                    isResults = false;
                    isPpeDetails = false;
                    isPpeCategories = true;
                    isLabel = false;
                    isUiControls = false;
                    continue;
                }
                else if (this.ArcFlashCompleteResult[j].items[i].Val === "Label Strings") {
                    isResults = false;
                    isPpeDetails = false;
                    isPpeCategories = false;
                    isLabel = true;
                    isUiControls = false;
                    continue;
                }
                else if (this.ArcFlashCompleteResult[j].items[i].Val === "UI Controls") {
                    isResults = false;
                    isPpeDetails = false;
                    isPpeCategories = false;
                    isLabel = false;
                    isUiControls = true;
                    continue;
                }


                else if (isLabel) {
                    this.allLabelStrings.push(this.ArcFlashCompleteResult[j].items[i]);

                }
            }
            this.CollectionLabelStrings.push(this.allLabelStrings);
            //   this.CollectionLabelNameStrings.push(this.allLabelNameStrings);

        }
    }

    DownloadErrorsAndWarning() {
        const rows = this.warningErrorList
            .map(error => {
                const modifiedIsError = error.IsError == '1' ? "Error" : "Warning";  // Modify the age by adding 5 years
                return `${modifiedIsError}\t${error.ErrorCode}\t${error.Category}\t${error.Message}`;  // Combine name, modified age, and city
            })
            .join('\n');
        const tabDelimitedContent = ["Type\tCode\tCategory\tMessage\n", ...rows].join('');
        this.downloadTextFile(tabDelimitedContent, 'Errors and Warnings.txt')
    }
    DownloadErrorsAndWarningCoordination() {
        const rows = this.coordinationWarningErrorList
            .map(error => {
                return `${error.Type}\t${error.ErrorCode}\t${error.Message}`;  // Combine name, modified age, and city
            })
            .join('\n');
        const tabDelimitedContent = ["Type\tCode\tMessage\n", ...rows].join('');
        this.downloadTextFile(tabDelimitedContent, 'Coordination Errors and Warnings.txt')
    }

    GenerateArcFlashLabelDiagram() {
        if (this.CollectionLabelStrings.length > 0) {
            this.showLoader();
            let obj: ArcFlashLabelDiagramModel = new ArcFlashLabelDiagramModel();
            obj.calculationId = this.newCalculationId;
            obj.calculationMethod = this.tempinputXML.NetworkSettings.CalculationMethod;
            obj.ppeCategory = this.tempinputXML.NetworkSettings.CategorySource;
            obj.labelArrayAll = JSON.stringify(this.CollectionLabelStrings);
            obj.labelNameArrayAll = JSON.stringify(this.CollectionLabelNameStrings);
            this._CableproService.generateArcFlashLabelDiagramNetwork(obj).subscribe((result) => {
                this.hideLoader();
                let blob = new Blob([result.body], { type: 'application/octet-stream' });

                saveAs(blob, "Arc_Flash_Labels_" + this.calculationName + ".zip");
                //saveAs(blob, "ArcFlashLabelDiagram.docx");
            },
                (error) => {

                    this.hideLoader();
                }
            );
        }
    }

    populateCategorySourcesBaedOnMethod() {
        if (this.tempinputXML.NetworkSettings.CalculationMethod == "2" || this.tempinputXML.NetworkSettings.CalculationMethod == "3") {
            this.categorySources = [
                { "id": "4", "text": "DGUV 203-077" }
            ];
        }
        else {
            this.categorySources = [
                { "id": "0", "text": "Electrical Arc Flash Hazard Management Guideline" },
                { "id": "1", "text": "NFPA 70E-2024 Table 130.7(C)(15)(c)" },
                { "id": "2", "text": "NFPA 70E-2024 Table 130.5(G)" },
                { "id": "3", "text": "NFPA 70E-2024 Table H.2" },
                { "id": "5", "text": "Custom from file" }
            ]
        }
        if (this.tempinputXML.NetworkSettings.CategorySource) {
            let obj = this.categorySources.find(m => m.id == this.tempinputXML.NetworkSettings.CategorySource);
            if (!obj)
                this.tempinputXML.NetworkSettings.CategorySource = this.categorySources[0].id;
        }

    }
    onCalculationIsArcFlashChange() {
        this._settingService.hideArcFlashReport = !this.tempinputXML.NetworkSettings.IsArcFlash;
        this.populateCategorySourcesBaedOnMethod();
        if (this.tempinputXML.NetworkSettings.IsArcFlash) {
            if (this.tempcablesizelist && this.tempcablesizelist.length > 0) {
                this.tempcablesizelist.forEach(elements => {
                    if (elements && (elements.Node_Prefix.startsWith("MSB", 0) || elements.Node_Prefix.startsWith("SB", 0)) && elements.CableSizeCalcInputXML) {
                        elements.CableSizeCalcInputXML.arcFlashData = this._CableproService.initArcFlashDefaultData(this.tempinputXML.NetworkSettings);
                    }
                });
            }
        }
        else {
            if (this.tempcablesizelist && this.tempcablesizelist.length > 0) {
                this.tempcablesizelist.forEach(elements => {
                    if (elements && (elements.Node_Prefix.startsWith("MSB", 0) || elements.Node_Prefix.startsWith("SB", 0)) && elements.CableSizeCalcInputXML) {
                        elements.CableSizeCalcInputXML.arcFlashData = {};
                    }
                });
            }
        }
        this.tempinputXML.CableSizeCalcInputs = JSON.stringify(this.tempcablesizelist);
        this.onInputChange();
    }
    onCalculationMethodChange() {
        this.populateCategorySourcesBaedOnMethod();

        if (this.tempcablesizelist && this.tempcablesizelist.length > 0) {
            this.tempcablesizelist.forEach(elements => {
                if (elements && elements.CableSizeCalcInputXML && elements.CableSizeCalcInputXML.arcFlashData && Object.keys(elements.CableSizeCalcInputXML.arcFlashData).length > 0) {
                    elements.CableSizeCalcInputXML.arcFlashData.arcFlash.calculationMethod = this.tempinputXML.NetworkSettings.CalculationMethod;
                    elements.CableSizeCalcInputXML.arcFlashData.ppeCategory.categorySource = this.tempinputXML.NetworkSettings.CategorySource;
                    // elements.CableSizeCalcInputXML.arcFlashData.ppeCategoriesTable = [];
                    // elements.CableSizeCalcInputXML.arcFlashData.ppeCategoriesTableHeaders = [];
                }
            });
        }
        // this.tempinputXML.NetworkSettings.ppeCategoriesTable = [];
        // this.tempinputXML.NetworkSettings.ppeCategoriesTableHeaders = [];

        // if (this.CableSize["arcFlashData"] && this.CableSize["arcFlashData"].ppeCategoriesTable) {
        //     this.CableSize["arcFlashData"].ppeCategoriesTable = [];
        //     this.CableSize["arcFlashData"].ppeCategoriesTableHeaders = [];
        // }


        this.tempinputXML.CableSizeCalcInputs = JSON.stringify(this.tempcablesizelist);
        this.onInputChange();
    }
    //INUSE
    selectsupplychildren(events: any) {
        $('#SupplyNetworkFeeder').hide();
        $('#SupplyExistingswitchboard').hide();
        $('#SupplyTransformer').hide();
        $('#SupplyGenerator').hide();
        $('#SupplyPowerStation').hide();
        this.showgeneratorResult = false;
        this.showFeederResult = false;
        this.showPowerStationResult = false;
        this.showTransformerResult = false;
        this.showPowerFactorResult = false;
        this.showSwitchboardResult = false;
        this.supplyType = events.id;

        if (events.id == 0) {
            $('#SupplyNetworkFeeder').show();
            this.showFeederResult = true;
            this.items[0].objectcode = 'NF0';
            this.items[0].children[0].parentid = 'NF0';
            this.items[0].img = '/assets/cablepro/img/Network_Feeder.png';

        }
        else if (events.id == 1) {
            $('#SupplyExistingswitchboard').show();
            this.showFeederResult = true;
            this.items[0].objectcode = 'NF0';
            this.items[0].children[0].parentid = 'NF0';
            this.items[0].img = '/assets/cablepro/img/Network_Feeder.png';
        }
        else if (events.id == 2) {
            $('#SupplyTransformer').show();
            this.showTransformerResult = true;
            this.items[0].objectcode = 'T0';
            this.items[0].children[0].parentid = 'T0';
            this.items[0].img = '/assets/cablepro/img/Transformer_icon.png';
            sessionStorage.setItem('SupplyType', "Transformer");

        }
        // else if(events.target.value == 2)
        else if (events.id == 3) {
            $('#SupplyGenerator').show();
            this.showgeneratorResult = true;
            this.items[0].objectcode = 'G0';
            this.items[0].children[0].parentid = 'G0';
            this.items[0].img = '/assets/cablepro/img/Generator_icon.png';

        }
        // else if(events.target.value == 3)
        else if (events.id == 4) {
            $('#SupplyPowerStation').show();
            this.showPowerStationResult = true;
            sessionStorage.setItem('SupplyType', "Power Factor");
            this.items[0].objectcode = 'PS0';
            this.items[0].children[0].parentid = 'PS0';
            this.items[0].img = '/assets/cablepro/img/Power_station.png';
        }

        this.items[0].name = events.text;

        sessionStorage.removeItem("currentnode");
        sessionStorage.setItem("currentnode", JSON.stringify(this.items[0]));        
        this.activeNode = this.tree.treeModel.getNodeById(1).data;

    }
    //INUSE
    selectswitchboardcustom(e: any) {


        $('#SwitchBoard_Custom').hide();
        $('#MaxDemandHead').hide();

        if (e.target.value == 0) {
            $('#MaxDemandHead').css('display', 'block');
        }

        if (e.target.value == 2) {
            $('#SwitchBoard_Custom').show();
        }
        // else if (e.target.value == 1)
        // {
        //     $('#SwitchBoard_Custom').show();
        // }

    }

    //INUSE
    checkPFC(e) {
        // e is a boolean, true if checked, otherwise false

        // alert("click from ngModelChecked");
        if (e.target.checked) {
            //   alert("Show");
            $('#powerfactorinstall').css('display', 'none');
        }
        else if (!e.target.checked) {
            // alert("Hide");
            $('#powerfactorinstall').css('display', 'block');
        }


    }

    getCurrentLoginInformations(): void {
        this.user = this._appSessionService.user;
        this.premiumUser = this.user.premiumUser;
        this.planLevel = this.user.userStripePlanLevel
        this.isPlanAddedManually = this.user.isPlanAddedManually;
    }

    //INUSE
    checkeco(e) {
        // e is a boolean, true if checked, otherwise false

        // alert("click from ngModelChecked");
        if (e.target.checked) {
            //   alert("Show");
            $('#economiccalculationgroup').css('display', 'block');
        }
        else if (!e.target.checked) {
            // alert("Hide");
            $('#economiccalculationgroup').css('display', 'none');
        }


    }
    ngDoCheck() {
        $('[data-toggle="tooltip"]').tooltip({
            trigger: 'hover'
        });
    }


    ngAfterContentInit() {
        $('#deratingWizard').on('hidden.bs.modal', () => {
            if (this.CableSize['isderatingwiz'] == "0")
                this.UserWizard = false;
        });
        //#region cable size calculation
        this.loading = true;
        if ($('.c-select').length) {
            let $singleSelect = $('.c-select'),
                $singleSelectHasSearch = $('.c-select.has-search'),
                $multipleSelect = $('.c-select.c-select--multiple'),
                $disabledSelect = $('.c-select.is-disabled');

            $singleSelect.select2({
                width: '100%',
                minimumResultsForSearch: Infinity // disable search
            });

            // multiple select
            $multipleSelect.select2({
                width: '100%',
                multiple: true
            });

            // disabled select
            $disabledSelect.select2({
                width: '100%',
                disabled: true
            });
        }

        setTimeout(() => {
            if ($('#phase').length > 0) {
                $('#phase').select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });
            }
        });
        setTimeout(() => {
            $("#reportDlg" ).on('shown.bs.modal', ()=>{
                this.CalculationReportInput.jobNo = this.route.snapshot.queryParamMap.get('cname');
             });

            $(document).off('change', '#ratingplug');

            $(document).on('change', '#ratingplug', (e: any) => {

                var manufacturerId = parseInt(this.ListLoadStorage[this.index].manufacturer);
                var familyId = this.ListLoadStorage[this.index].family;

                var modelId = parseInt(this.ListLoadStorage[this.index].model.toString());
                var d = this.ListLoadStorage[this.index].rating.toString();
                var ee = this.ListLoadStorage[this.index].tripunit.toString();
                var curve = (ee.toString());
                var ltp = "";

                if ($('#ratingplug option:selected').val() == "OFF")
                    ltp = "0";
                this.ProtectiveGetXml(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', '', '', ''
                    , $('#ratingplug option:selected').val().toString(), ltp, '', ''
                    , '', ''
                    , '', ''
                    , '', ''
                    , '', 1, '', '', true);
            });


            $('#core').on('change', (e: any, isTriggered) => {
                this.isInstallationStatus = false;
                this.isCableSectionValue = false;
                if (isTriggered === "true") {
                    this.isInstallationStatus = true;
                    this.isCableSectionValue = true;
                    let bunchingCore: string = this.PrevCoreId.toString();
                    this.CableSize['core'] = bunchingCore;
                    if (bunchingCore === "0") {
                        this.drBunchingCore = 1;
                    }
                    else {
                        this.drBunchingCore = 2;
                    }
                    if (this.drInstallType == 1) {
                        this.table23model.Row = 1;
                        this.table23model.Cables = 1;
                        this.table23model.Installation = 1;
                        this.table23model.CableType = this.drBunchingCore;
                        this.table23model.drf = this.drBunchingCore == 1 ? 0.95 : 0.97;
                        this.OnChangeCore(null);
                        this.GetTable23Dr(null);
                    }
                    else {
                        if (this.table25model.NoOfCircuits > 1) {
                            this.table25model.CableType = this.drBunchingCore;
                            this.setTable25Dr();
                        }
                        if (!this.table28model.UseStandard && this.table28model.Buried == 2)
                            this.GetTable28Dr(null);
                    }
                    this.UserWizard = false;
                    this.CableSize["isderatingwiz"] = "0";
                    var coreID = this.CableproInput.CoreId.toString();

                    setTimeout(function () {
                        $('#windspeed').select2({
                            width: '100%',
                            minimumResultsForSearch: Infinity // disable search
                        });
                    }, 800);
                    setTimeout(() => {

                    }, 800);
                }
                else {
                    this.isNewNodeAdded = false;
                    this.isCableSizeCalcStatus = false;
                    let d = (e.target as HTMLSelectElement).options
                    this.PrevCoreId = d.selectedIndex;
                    this.CableproInput.CoreId = parseInt((e.target as HTMLOptionElement).value);
                    let bunchingCore: string = (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].getAttribute('xmlvalue').toString();
                    this.CableSize['core'] = bunchingCore;
                    if (bunchingCore === "0") {
                        this.drBunchingCore = 1;
                    }
                    else {
                        this.drBunchingCore = 2;
                    }
                    if (this.drInstallType == 1) {
                        this.table23model.Row = 1;
                        this.table23model.Cables = 1;
                        this.table23model.Installation = 1;
                        this.table23model.CableType = this.drBunchingCore;
                        this.table23model.drf = this.drBunchingCore == 1 ? 0.95 : 0.97;
                        this.OnChangeCore(null);
                        this.GetTable23Dr(null);
                    }
                    else {
                        if (this.table25model.NoOfCircuits > 1) {
                            this.table25model.CableType = this.drBunchingCore;
                            this.setTable25Dr();
                        }
                        if (!this.table28model.UseStandard && this.table28model.Buried == 2)
                            this.GetTable28Dr(null);
                    }
                    this.updatecablesizeinarray();
                    this.UserWizard = false;
                    this.CableSize["isderatingwiz"] = "0";
                    this.GetCableSizeData('core', (e.target as HTMLOptionElement).value, this.CableproInput.InsulationId, this.CableproInput.ConductorId, 0, this.CableproInput.PhaseId, this.CableproInput.MethodIndex, 0, 0, '', '', this.newCalculationId, 0, 0, this.CableproInput.ArmourId, null, false, this.CableproInput.EarthConductorId);
                    setTimeout(function () {
                        $('#windspeed').select2({
                            width: '100%',
                            minimumResultsForSearch: Infinity // disable search
                        });
                    }, 800);
                    setTimeout(() => {

                    }, 800);
                }

            });
            $(document).off('change', '#phase');
            $(document).on('change', '#phase', (e: any, isTriggered) => {
                this.isCableSectionValue = false;
                this.isInstallationStatus = false;
                if (isTriggered === "true") {

                    this.isInstallationStatus = true;
                    this.isCableSectionValue = true;
                    if (this.CableproInput.PhaseId.toString() === '100') {

                        //this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
                    } else if (this.CableproInput.PhaseId.toString() === '101') {

                        //this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
                        if (this.CableSize['tableno'] === '17') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                            }, 100);
                        } else if (this.CableSize['tableno'] === '18') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                                $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                            }, 100);
                        }
                    } else if (this.CableproInput.PhaseId.toString() === '102') {

                        //this.CableSize['supplyvoltage'] = this.dc_Voltage;
                    } else if (this.CableproInput.PhaseId.toString() === '103') {

                        //this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
                        if (this.CableSize['tableno'] === '17') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                            }, 100);
                        } else if (this.CableSize['tableno'] === '18') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                                $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                            }, 100);
                        }
                    } else if (this.CableproInput.PhaseId.toString() === '104') {
                        //this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
                        if (this.CableSize['tableno'] === '17') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                            }, 100);
                        } else if (this.CableSize['tableno'] === '18') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                                $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                            }, 100);
                        }
                    }

                    if ((this.CableproInput.PhaseId.toString() === '100') || (this.CableproInput.PhaseId.toString() === '102')) {
                        if ($('#neutral-toggle').prop('checked')) {
                            $('#neutral-toggle').click();
                        }
                        this.NeutralDisabled = true;
                        // $('#Neutral').attr('disabled', 'true');
                        $('#neutral-toggle').attr('disabled', 'true');
                        $('#Neutral_c').attr('disabled', 'true');

                    } else {
                        this.NeutralDisabled = false;
                        // $('#Neutral').removeAttr('disabled');
                        $('#neutral-toggle').removeAttr('disabled');
                    }
                    // $('input[name="Voltage-Supply"]').val(this.CableSize['supplyvoltage']);
                    this.voltage_txt = this.CableSize['supplyvoltage'];

                    setTimeout(function () {
                        $('#windspeed').select2({
                            width: '100%',
                            minimumResultsForSearch: Infinity // disable search
                        });
                    }, 800);
                    setTimeout(() => {
                    }, 800);
                }
                else {
                    this.isNewNodeAdded = false;
                    this.isCableSizeCalcStatus = false;
                    this.CableproInput.PhaseId = parseInt((e.target as HTMLOptionElement).value);
                    if (this.CableproInput.PhaseId.toString() === '100') {

                        //this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
                    } else if (this.CableproInput.PhaseId.toString() === '101') {
                        //this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
                        if (this.CableSize['tableno'] === '17') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                            }, 100);
                        } else if (this.CableSize['tableno'] === '18') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                                $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                            }, 100);
                        }
                    } else if (this.CableproInput.PhaseId.toString() === '102') {
                        //this.CableSize['supplyvoltage'] = this.dc_Voltage;
                    } else if (this.CableproInput.PhaseId.toString() === '103') {
                        //this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
                        if (this.CableSize['tableno'] === '17') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                            }, 100);
                        } else if (this.CableSize['tableno'] === '18') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                                $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                            }, 100);
                        }
                    } else if (this.CableproInput.PhaseId.toString() === '104') {
                        //this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
                        if (this.CableSize['tableno'] === '17') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img2.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table17_img3.png');
                            }, 100);
                        } else if (this.CableSize['tableno'] === '18') {
                            setTimeout(() => {
                                $('#divInstallTbl table tr:first img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img3.png');
                                $('#divInstallTbl table tr:nth-child(2) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img4.png');
                                $('#divInstallTbl table tr:nth-child(3) img')[0].setAttribute('src', '/assets/cablepro/img/Table18_img5.png');
                            }, 100);
                        }
                    }

                    if ((e.target as HTMLOptionElement).value === '100' || (e.target as HTMLOptionElement).value === '102') {
                        if ($('#neutral-toggle').prop('checked')) {
                            $('#neutral-toggle').click();
                        }
                        this.NeutralDisabled = true;
                        // $('#Neutral').attr('disabled', 'true');
                        $('#neutral-toggle').attr('disabled', 'true');
                        $('#Neutral_c').attr('disabled', 'true');

                    } else {
                        this.NeutralDisabled = false;
                        // $('#Neutral').removeAttr('disabled');
                        $('#neutral-toggle').removeAttr('disabled');
                    }
                    // $('input[name="Voltage-Supply"]').val(this.CableSize['supplyvoltage']);
                    this.voltage_txt = this.CableSize['supplyvoltage'];
                    this.CableSize['phase'] = (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].getAttribute('xmlvalue').toString();
                    this.updatecablesizeinarray();

                    this.GetCableSizeData('phase', (e.target as HTMLOptionElement).value, this.CableproInput.InsulationId, this.CableproInput.ConductorId, this.CableproInput.CoreId, 0, this.CableproInput.MethodIndex, 0, 0, '', '', this.newCalculationId, 0, 0, this.CableproInput.ArmourId, null, false, this.CableproInput.EarthConductorId);
                    setTimeout(function () {
                        $('#windspeed').select2({
                            width: '100%',
                            minimumResultsForSearch: Infinity // disable search
                        });
                    }, 800);
                    setTimeout(() => {
                        // if ($('#active-toggle').is(':checked')) {
                        //     (<any>$('#Active')).val(this.CableproInput.ActiveId).trigger('change');
                        // }
                        // if ($('#neutral-toggle').is(':checked')) {
                        //     (<any>$('#Neutral')).val(this.CableproInput.NeutralId).trigger('change');
                        // }
                    }, 800);
                    this.getDefaultSupplyVoltage(this.activeNode.id);
                }

            });

            $('input[name=current]').change(this.showHideProtectionWarning);
            $('#gotopricing, #gotopricing1, #gotopricing2, #gotopricing4, #spSubscribeNow').on('click', (e) => {
                this._router.navigate(['/app/main/projects/', 'subscriptionplans']);
            });
            $('#spUpgradeNow').on('click', (e) => {
                if (this.isPlanAddedManually === true)
                    this._router.navigate(['/app/main/projects/', 'subscriptionplans']);
                else
                    this.ManageSub();
            });
            $('#okBtn').on('click', (e) => {

                this.onSelectInstallTable();

                $('#confrimation-model').modal('hide');
                this.UserWizard = false;
                this.CableSize["isderatingwiz"] = "0";
                // $('#checkboxUseWizard').removeAttr('checked');
                this.setInstallationType();
                this.onResetControlValues();

                //this.updateDeratingFactorValue(false, this.CalculationReportInput.wizardValues);

            });
            $('#confrimation-model').modal({
                show: false,
                keyboard: false,
                backdrop: 'static'
            })
            //#endregion


            $('input[name=current]').change(this.showHideProtectionWarning);

            $('#protectiveConfiguration').on('hidden.bs.modal', () => {
                if (!this.isFromCoordination)
                    this.protectiveConfigurationCancel();
                else {
                    this.protectiveXmlInputList = [];
                    this.ListLoadStorage = [];
                    this.index = 0;
                    // this.protectionCoordinationAnnotations = undefined;
                    this.getNetworkProtectiveDataByNodeId(this.newCalculationId, this.activeNode.objectcode);
                }
                this.isFromCoordination = false;
                this.protectiveDrpList = [];
                this.currentProtectiveDeivce = '';
                this.currentProtectiveDeivceObj = undefined;
                this.index = 0;
                this.ArcingCurrents = true;
                this.FaultCurrents = false;
                this.CableDamage = true;
                this.TransformerDamage = false;
                this.arcCurveValue = '';
            });

            $('#protectiveSelector').on('hidden.bs.modal', () => {
                if (this.addingDeviceForUnknownDevice) {
                    this.addingDeviceForUnknownDevice = false;
                    this.unknownDeviceNode = undefined;
                    this.unknownDeviceNodeHeader = undefined;
                    this.protectiveImgSrc = '/assets/cablepro/logo/a.png';
                    this.protectiveImgSrc2 = '/assets/cablepro/logo/a.png';
                }
                this.checkingHVFuse = false;

            });

            $('#unknown-device-alert').on('hidden.bs.modal', () => {

            });

            this.supplyTypeReopenData = true;
            $('#selectNetwork').val('1');
            this.selectchild(1);
            $(document).off('click', '.clickp');
            $(document).on('click', '.clickp', (e: any, isTriggered) => {
                $('#MaxDemandHead').hide();
                document.getElementById('defaultOpen').click();
                $('#selectNetwork').val('3').trigger("change");
                this.selectResults = "8";
                // $('#selectResults').val('8').trigger("change");
            });

            // MAX Demand
            $(document).off('change', '#ddlSubGroup');
            $(document).on('change', '#ddlSubGroup', (e: any, isTriggered) => {

                if (isTriggered !== 'true') {

                    if (e.target.options[e.target.selectedIndex] !== undefined) {

                        let editsub = this.SubData.filter(x => x.name === $('#ddlSubGroup option:selected')[0].innerText.trim());

                        this.rules = editsub[0].rules;

                        this.LoadStorage.loadCode = editsub[0].loadCode;
                        this.LoadStorage.subGroupId = editsub[0].subId;

                        this.LoadStorage.loadType = editsub[0].loadType;
                        if (editsub[0].loadType == 2 ||
                            editsub[0].loadType == 4 ||
                            editsub[0].loadType == 6 ||
                            editsub[0].loadType == 8 ||
                            editsub[0].loadType == 7
                        ) {
                            this.isHideForLoadTypes = true;
                            this.LoadStorage.rating = 0;
                        }
                        else {
                            this.isHideForLoadTypes = false;
                        }

                    }
                    else {
                        this.isHideForLoadTypes = false;
                    }
                    this.showGroupAsCircuit(this.LoadStorage.installationId, this.LoadStorage.loadGroupId, this.LoadStorage.subGroupId, this.LoadStorage.phases, this.LoadStorage.diversity)

                }


            });
            $(document).off('change', '#ddlLoadGroup');

            $(document).on('change', '#ddlLoadGroup', (e: any) => {

                if (e.target.options[e.target.selectedIndex] !== undefined) {

                    const loadId = parseInt(e.target.options[e.target.selectedIndex].value);
                    this.editSubGroupId = 0;
                    this.LoadStorage.loadGroupId = loadId;
                    this.LoadStorage.loadGroup = parseInt(e.target.options[e.target.selectedIndex].index);
                    this.GetSubGroupByLoadId(loadId);
                    this.hideControlsForCustomInstallation($('#ddlLoadGroup option:selected')[0].innerText.trim());
                }
                else
                    this.GetSubGroupByLoadId(5);
            });
            $(document).off('change', '#ddlUnits');

            $(document).on('change', '#ddlUnits', (e: any) => {

                this.LoadStorage.units = parseInt(e.target.options[e.target.selectedIndex].value);
                if (e.target.options[e.target.selectedIndex].value === '2') {
                    this.isPowerFactor = false;
                    this.ratingLab = 'W';
                    let val = $('input[name="rating"]').val();
                    if (Number(val) < 0.5) {
                        this.LoadStorage.rating = 0.5;
                    }
                }
                else if (e.target.options[e.target.selectedIndex].value === '3') {
                    this.isPowerFactor = false;
                    this.ratingLab = 'Kw';
                    let val = $('input[name="rating"]').val();
                    if (Number(val) < 0.5) {
                        this.LoadStorage.rating = 0.5;
                    }
                }
                else {
                    this.isPowerFactor = true;
                    this.LoadStorage.powerFactor = 1;
                    this.ratingLab = 'A';
                    let val = $('input[name="rating"]').val();
                    if (Number(val) > 10000) {
                        this.LoadStorage.rating = 10000;
                    }
                }
            });
            $(document).off('change', '#ddlDiversity');
            $(document).on('change', '#ddlDiversity', (e: any) => {

                this.LoadStorage.diversity = parseInt(e.target.options[e.target.selectedIndex].value);
                if (e.target.options[e.target.selectedIndex].value === '1') {

                    this.isShowPhaseQty = false;
                    this.isHideQty = true;

                } else {
                    this.isShowPhaseQty = true;
                    this.isHideQty = false;
                    if (this.blockInstallation && $("#ddlPhases").val() == "3") {
                        this.isHideQty = true;
                    }
                }
                this.showGroupAsCircuit(this.LoadStorage.installationId, this.LoadStorage.loadGroupId, this.LoadStorage.subGroupId, this.LoadStorage.phases, this.LoadStorage.diversity)

            });


            $(document).off('change', '#ddlElectricInstallation1');

            $(document).on('change', '#ddlElectricInstallation1', (e: any, isTriggeredByUser) => {

                this.LoadStorage.installationId = parseInt(e.target.options[e.target.selectedIndex].value);

                if (isTriggeredByUser != 'false') {
                    this.isSubGroupShown = true;
                    this.editLoadGroupId = 0;
                }

                this.blockInstallation(this.LoadStorage.installationId);
                if (this.isBlockInstallation) {
                    this.LoadStorage.diversity = 2;
                    $('#ddlDiversity').val(2).trigger("change");
                }
                else {

                    if (isTriggeredByUser != 'false') {
                        this.LoadStorage.diversity = 1;
                        $('#ddlDiversity').val(1).trigger("change");
                    }
                }

                this.GetLoadGroupByInstallId(this.CalculationId, this.LoadStorage.installationId);
            });

            $(document).off('change', '#ddlPhases');

            $(document).on('change', '#ddlPhases', (e: any) => {

                this.LoadStorage.phases = parseInt(e.target.options[e.target.selectedIndex].value);

                if (this.LoadStorage.phases == 3) {
                    this.LoadStorage.automaticAllocation = 1;
                    this.isHideQty = true;
                }
                else {
                    if (this.LoadStorage.diversity == 2) {
                        this.isHideQty = false;
                    }
                    (<any>$('#ddlDiversity')).select2({
                        width: '100%',
                        minimumResultsForSearch: Infinity // disable search
                    });
                }
                this.showGroupAsCircuit(this.LoadStorage.installationId, this.LoadStorage.loadGroupId, this.LoadStorage.subGroupId, this.LoadStorage.phases, this.LoadStorage.diversity)

            });

            // $('#deleteBtn').on('click', (e) => {
            //     this.isMaxDemandDataShown = true;
            //     let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));
            //     let maxDemandData = this.maxDemandDataArray.find(x => x.Node_Id === selectednode.id || x.Node_Prefix == selectednode.objectcode);

            //     if (maxDemandData !== undefined && maxDemandData !== null) {
            //         maxDemandData.LoadItemsData = maxDemandData.LoadItemsData.filter(x => x.id !== this.deleteLoadId);
            //     }

            //     this.onInputChange();
            //     $('#delete-model').modal('hide');
            //     // if (maxDemandData.LoadItemsData.length == 0) {
            //     //     this.PhaseR = '0';
            //     //     this.PhaseW = '0';
            //     //     this.PhaseB = '0';
            //     //     this.MaximumDemand = '0';
            //     //     this.PhaseImbalance = '0';
            //     // }
            //     this.deleteLoadId = 0;
            // });

            $('#resetBtn').on('click', (e) => {
                this.isMaxDemandDataShown = true;
                let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));
                let loadItemsMaxData = this.maxDemandDataArray.find(x => x.Node_Id === selectednode.id || x.Node_Prefix == selectednode.objectcode);
                loadItemsMaxData.LoadItemsData = [];

                this.onInputChange();
                $('#reset-model').modal('hide');
            });

        });
    }

    getExistingCalculationData() {
        //MAX Demand
        // this.showLoader();
        if (this.newCalculationId) {
            var startTime = performance.now();
            this._CableproService.GetNetworkCalculationById(this.newCalculationId).subscribe(result => {
                this.isCableSizeCalcStatus = true;
                this._projectService.refreshSidebarProjects();

                if (result.treeValue != undefined) {
                    // this.showLoader();
                    if (result.treeValue != null) {
                        this.supplyTypeReopenData = true;
                        this.items = JSON.parse(result.treeValue);
                        this.switchboardcount = 0;
                        this.loadcount = 0;
                        this.capacitorcount = 0;
                        this.switchBoardCount(this.items[0]);
                        this.capacitorCount(this.items[0]);
                        this.loadCount(this.items[0]);
                        sessionStorage.setItem("currentnode", JSON.stringify(this.items[0].children[0]));
                        $('#selectNetwork').val('1');
                        this.selectchild(1);
                        if (this.tree != undefined && this.tree != null) {
                            this.tree.treeModel.nodes = this.items;
                            setTimeout(() => {
                                this.tree.treeModel.update();
                                this.tree.treeModel.expandAll();
                                this.tree.treeModel.getNodeById(2).setActiveAndVisible();
                                this.activeNode = this.tree.treeModel.getNodeById(2).data;
                            });
                        }
                    }
                    if (result.maxDemandCalcData != null) {

                        this.tempinputXML.MaxDemandDataArray = [];
                        this.loadId = 0;
                        this.maxDemandDataArray = [];
                        this.tempinputXML.MaxDemandDataArray = JSON.parse(result.maxDemandCalcData);

                        this.tempinputXML.MaxDemandDataArray.forEach(element => {

                            let maxCalcData = new MaxDemandCalcInput();

                            maxCalcData.LoadItemsData = element.Items;
                            let loadItemsData = element.Items;

                            if (loadItemsData.length > 0) {
                                loadItemsData.forEach(elements => {
                                    if (this.loadId < elements.id) {
                                        this.loadId = elements.id;
                                    }
                                });
                            }
                            maxCalcData.Node_Id = parseInt(element.Node_Id);
                            maxCalcData.Node_Name = element.Node_Name;
                            maxCalcData.Node_Prefix = element.Node_Prefix;

                            maxCalcData.PhaseR = '0';
                            maxCalcData.PhaseW = '0';
                            maxCalcData.PhaseB = '0';
                            maxCalcData.MaximumDemand = '0';
                            maxCalcData.PhaseImbalance = '0';
                            this.maxDemandDataArray.push(maxCalcData);

                        });
                    }
                    if (result.cableSizeData != null) {

                        this.tempcablesizelist = [];
                        this.tempinputXML.CableSizeCalcInputs = "";
                        this.tempcablesizelist = JSON.parse(result.cableSizeData);
                        this.tempinputXML.CableSizeCalcInputs = result.cableSizeData;
                        if (this.tempcablesizelist.length > 0) {
                            if (this.tempcablesizelist[0].CableSizeCalcInputXML.arcFlashData != null && Object.keys(this.tempcablesizelist[0].CableSizeCalcInputXML.arcFlashData).length > 0) {
                                this.tempinputXML.NetworkSettings.ppeCategoriesTable = this.tempcablesizelist[0].CableSizeCalcInputXML.arcFlashData.ppeCategoriesTable;
                                this.tempinputXML.NetworkSettings.ppeCategoriesTableHeaders = this.tempcablesizelist[0].CableSizeCalcInputXML.arcFlashData.ppeCategoriesTableHeaders;
                            }
                        }
                        this.cableSizeReopenData = null;
                        this.CableSizeCount = 1;
                        let count = 0;
                        sessionStorage.setItem("cablesizelist", count.toString());

                    }
                    if (result.inputArray != null) {

                        if (result.inputArray.hasOwnProperty('NetworkSettingsObject')) {
                            let feederObj = { "dataType": "Network settings", "dataValue": result.inputArray.NetworkSettingsObject.cluster1 };
                            this.GetSupplytypeValue(feederObj);
                        }
                        if (result.inputArray.hasOwnProperty('NetworkFeeder')) {

                            let feederObj = { "dataType": "Network feeder", "dataValue": result.inputArray.NetworkFeeder };
                            this.GetSupplytypeValue(feederObj);
                        }
                        if (result.inputArray.hasOwnProperty('HVRelayObject')) {
                            this.HVRelayObjectData = result.inputArray.HVRelayObject.cluster;
                        }
                        if (result.inputArray.hasOwnProperty('Generator')) {
                            let transformerObj = { "dataType": "Generator", "dataValue": result.inputArray.Generator }
                            this.GetSupplytypeValue(transformerObj);
                        }
                        if (result.inputArray.hasOwnProperty('Transformer')) {
                            let transformerObj = { "dataType": "Transformer", "dataValue": result.inputArray.Transformer }
                            this.GetSupplytypeValue(transformerObj);
                        }
                        if (result.inputArray.hasOwnProperty('Powerstation')) {
                            let transformerObj = { "dataType": "Powerstation", "dataValue": result.inputArray.Powerstation }
                            this.GetSupplytypeValue(transformerObj);
                        }

                        if (result.inputArray.hasOwnProperty('SwitchboardObject')) {

                            let transformerObj = { "dataType": "switchboard", "dataValue": result.inputArray.SwitchboardObject.cluster }
                            if (transformerObj.dataValue != null) {
                                this.tempinputXML.Switchboards = [];
                                this.tempswitchlist = [];
                                let count = 1;

                                sessionStorage.removeItem("SwitchboardResults");
                                sessionStorage.setItem("switchlist", (count).toString());
                                sessionStorage.setItem("SwitchboardResults", "MSB01");

                                this.GetNetworkDataToReopenCalc(transformerObj);

                                this.allnodes = [];
                                this.matrix = [];
                                this.getallnodes(this.items[0]);
                                this.printArray(this.items[0]);
                                this.changekeyofmatrix();

                            }
                        }

                        if (result.inputArray.hasOwnProperty('LoadObject')) {
                            if (result.inputArray.LoadObject.dimsize > 0) {
                                let transformerObj = { "dataType": "load", "dataValue": result.inputArray.LoadObject.cluster }

                                if (transformerObj.dataValue != null) {
                                    this.tempinputXML.Loads = [];
                                    this.templist = [];
                                    this.GetNetworkDataToReopenCalc(transformerObj);
                                }
                            }
                        }

                        if (result.inputArray.hasOwnProperty('CapacitorBankObject') && result.inputArray.hasOwnProperty('PowerFactorDataObject')) {
                            if (result.inputArray.CapacitorBankObject.dimsize > 0) {
                                let transformerObj = { "dataType": "capacitor", "dataValue": result.inputArray.CapacitorBankObject.cluster }
                                if (transformerObj.dataValue != null) {
                                    this.tempcapacitorlist = [];
                                    this.temppowerfactorcorrections = [];
                                    this.tempinputXML.CapacitorBanks = [];
                                    this.tempinputXML.PowerFactorCorrections = [];
                                    this.powerFactorClusterData = result.inputArray.PowerFactorDataObject.cluster;
                                    if (this.powerFactorClusterData.length > 0) {
                                        let counts = 0;
                                        sessionStorage.setItem("capacitorlist", counts.toString());
                                        sessionStorage.setItem("powerfactorlist", counts.toString());
                                        this.GetNetworkDataToReopenCalc(transformerObj);
                                    }
                                }
                            }
                        }
                        //$('#HVRelayHead').css('display', 'none');
                    }
                    var endTime = performance.now();
                    var totalTime = endTime - startTime; // time took to run in milliseconds
                    // alert(1);
                    if (this.HVFuse == true) {
                        this.HvFuseXml = result.hvFuseXml;
                        this.HvFuseTrippingXml = result.hvFuseTrippingXml;
                    }
                    this.onInputChange();
                }
                else {
                    this.items = this.getDefaultTreeList();
                    //alert(2);


                    $('#selectNetwork').val('1');
                    this.selectchild(1);
                    if (this.items.length > 0)
                        if (this.tree != undefined && this.tree != null) {
                            this.tree.treeModel.nodes = this.items;
                            this.tree.treeModel.update();
                            this.tree.treeModel.expandAll();
                            this.protectiveUnknown = true;
                            this.protectionConfigurationModel = new ProtectionConfigurationModel();
                            this.saveDefaultProtectiveDetail(this.items[0].children[0].objectcode);// Add default data to ECPNetworkProtectiveCalcDetail table for this node
                            this.tree.treeModel.getNodeById(2).setActiveAndVisible();
                            setTimeout(() => {
                                this.activeNode = this.tree.treeModel.getNodeById(2).data;
                            });
                        }
                }
            });
        }
    }


    getTreeNodeIDForCalculation(treeData, objectcode) {

        treeData.forEach(element => {

            if (element.objectcode == objectcode) {

                this.treeNodeId = element.id;
                this.treeNodeName = element.name;
                return element;
            }
            if (element.children.length > 0) {

                element.children.forEach(elements => {

                    if (elements.objectcode == objectcode) {

                        this.treeNodeId = elements.id;
                        this.treeNodeName = elements.name;
                        return elements;
                    }
                    if (elements.children.length > 0) {

                        this.getTreeNodeIDForCalculation(elements.children, objectcode);
                    }
                });
            }
        });

    }

    GetNetworkDataToReopenCalc(responseData) {

        if (responseData.dataType == 'switchboard') {

            responseData.dataValue.forEach(switchElement => {

                let tempobj = new NetworkSwitchBoard();

                tempobj.SwitchBoard_Matrix_Node = switchElement.cluster[0].string[0].val;
                if (tempobj.SwitchBoard_Matrix_Node == "MSB01") {
                    tempobj.SwitchBoard_Id = 2;
                    this.treeNodeId = 2;
                    sessionStorage.setItem("nodecount", (this.treeNodeId).toString());
                }
                else {

                    if (this.items[0].children.length > 0) {

                        for (let i = 0; i < this.items[0].children.length; i++) {

                            if (this.items[0].children[i].children.length > 0) {

                                this.getTreeNodeIDForCalculation(this.items[0].children[i].children, tempobj.SwitchBoard_Matrix_Node);

                                tempobj.SwitchBoard_Id = this.treeNodeId;
                            }
                            break;
                        }
                    }
                }

                tempobj.SwitchBoard_Name = (switchElement.cluster[0].string[1].val).replace("lessthansymbol", "<");
                tempobj.SwitchBoard_Phases = switchElement.cluster[1].ew.val;
                tempobj.SwitchBoard_Allowable_Voltage_Drop = switchElement.cluster[1].dbl.val;
                tempobj.SwitchBoard_Total_Maximum_Demand = switchElement.cluster[1].cluster.ew.val;
                tempobj.SwitchBoard_Phase_1_Current = switchElement.cluster[1].cluster.cluster.cluster[1].items[2].val;
                tempobj.SwitchBoard_Phase_1_Phase = switchElement.cluster[1].cluster.cluster.cluster[1].items[3].val;
                tempobj.SwitchBoard_Phase_1_Power_factor = switchElement.cluster[1].cluster.cluster.cluster[1].items[4].val;

                tempobj.SwitchBoard_Phase_3_Currents = switchElement.cluster[1].cluster.cluster.ew.val; //For BAL/UNBAL radio buttons

                //BAL
                tempobj.SwitchBoard_Phase_3_Phase_Current = switchElement.cluster[1].cluster.cluster.cluster[1].items[2].val;
                tempobj.SwitchBoard_Phase_3_Phase_Power_factor = switchElement.cluster[1].cluster.cluster.cluster[1].items[4].val;

                // //UNBAL
                tempobj.SwitchBoard_Phase_3_Phase_Current_L1 = switchElement.cluster[1].cluster.cluster.cluster[0].items[2].dbl[0].val;
                tempobj.SwitchBoard_Phase_3_Phase_Current_L2 = switchElement.cluster[1].cluster.cluster.cluster[0].items[2].dbl[1].val;
                tempobj.SwitchBoard_Phase_3_Phase_Current_L3 = switchElement.cluster[1].cluster.cluster.cluster[0].items[2].dbl[2].val;
                tempobj.SwitchBoard_Phase_3_Phase_Power_factor_L1 = switchElement.cluster[1].cluster.cluster.cluster[0].items[3].dbl[0].val;
                tempobj.SwitchBoard_Phase_3_Phase_Power_factor_L2 = switchElement.cluster[1].cluster.cluster.cluster[0].items[3].dbl[1].val;
                tempobj.SwitchBoard_Phase_3_Phase_Power_factor_L3 = switchElement.cluster[1].cluster.cluster.cluster[0].items[3].dbl[2].val;

                this.tempswitchlist.push(tempobj);
                this.tempinputXML.Switchboards = this.tempswitchlist;
                let treenodecountss = parseInt(sessionStorage.getItem("nodecount"));
                if (this.treeNodeId && treenodecountss != this.treeNodeId) {
                    sessionStorage.setItem("nodecount", (this.treeNodeId).toString());
                }
                this.SetcablesizeToReopen();

                if (tempobj.SwitchBoard_Matrix_Node !== "MSB01") {


                    let switchID = tempobj.SwitchBoard_Matrix_Node.split("SB");

                    sessionStorage.setItem("switchlist", switchID[1].toString());
                }
                sessionStorage.setItem("SwitchboardResults", tempobj.SwitchBoard_Matrix_Node);
            });
        }


        if (responseData.dataType == 'load') {

            responseData.dataValue.forEach(switchElement => {

                let tempobj = new NetworkLoad();
                tempobj.Load_Matrix_Node = switchElement.cluster[0].string[0].val;

                if (this.items[0].children.length > 0) {

                    for (let i = 0; i < this.items[0].children.length; i++) {

                        if (this.items[0].children[i].children.length > 0) {

                            this.getTreeNodeIDForCalculation(this.items[0].children[i].children, tempobj.Load_Matrix_Node);

                            tempobj.Load_Id = this.treeNodeId;
                        }
                        break;
                    }
                }

                tempobj.Load_Name = (switchElement.cluster[0].string[1].val).replace("lessthansymbol", "<");;
                tempobj.Load_Phases = switchElement.cluster[1].ew[0].val;
                tempobj.Load_Phase_3_Currents = switchElement.cluster[1].ew[1].val;
                tempobj.Load_Phase_1_Harmonics = switchElement.cluster[1].dbl.val;
                tempobj.Load_Phase_3_Harmonics_Balanced = switchElement.cluster[1].dbl.val;
                tempobj.Load_Phase_3_Harmonics_UnBalanced = switchElement.cluster[1].dbl.val;

                tempobj.Load_Phase_1_Current = switchElement.cluster[1].cluster[1].items[2].val;
                tempobj.Load_Phase_1_Phase = switchElement.cluster[1].cluster[1].items[3].val;
                tempobj.Load_Phase_1_Powerfactor = switchElement.cluster[1].cluster[1].items[4].val;

                //BAL
                tempobj.Load_Phase_3_Phase_Current = switchElement.cluster[1].cluster[1].items[2].val;
                tempobj.Load_Phase_3_Powerfactor = switchElement.cluster[1].cluster[1].items[4].val;

                // //UNBAL
                tempobj.Load_Phase_3_Phase_Current_L1 = switchElement.cluster[1].cluster[0].items[2].dbl[0].val;
                tempobj.Load_Phase_3_Phase_Current_L2 = switchElement.cluster[1].cluster[0].items[2].dbl[1].val;
                tempobj.Load_Phase_3_Phase_Current_L3 = switchElement.cluster[1].cluster[0].items[2].dbl[2].val;
                tempobj.Load_Phase_3_Powerfactor_L1 = switchElement.cluster[1].cluster[0].items[3].dbl[0].val;
                tempobj.Load_Phase_3_Powerfactor_L2 = switchElement.cluster[1].cluster[0].items[3].dbl[1].val;
                tempobj.Load_Phase_3_Powerfactor_L3 = switchElement.cluster[1].cluster[0].items[3].dbl[2].val;

                this.templist.push(tempobj);
                this.tempinputXML.Loads = this.templist;
                let treenodecountss = parseInt(sessionStorage.getItem("nodecount"));
                if (this.treeNodeId && treenodecountss != this.treeNodeId) {
                    sessionStorage.setItem("nodecount", (this.treeNodeId).toString());
                }
                this.SetcablesizeToReopen();
                let switchID = tempobj.Load_Matrix_Node.split("L");
                sessionStorage.setItem("loadlist", switchID[1].toString());
            });
        }

        if (responseData.dataType == 'capacitor') {

            responseData.dataValue.forEach(switchElement => {

                let tempobj = new NetworkCapacitorBank();
                tempobj.NetworkCapacitorBank_Matrix_Node = switchElement.cluster[0].string[0].val;

                if (this.items[0].children.length > 0) {

                    for (let i = 0; i < this.items[0].children.length; i++) {

                        if (this.items[0].children[i].children.length > 0) {

                            this.getTreeNodeIDForCalculation(this.items[0].children[i].children, tempobj.NetworkCapacitorBank_Matrix_Node);

                            tempobj.NetworkCapacitorBank_Id = this.treeNodeId;
                            tempobj.NetworkCapacitorBank_Node_Id = this.treeNodeId.toString();
                            tempobj.NetworkCapacitorBank_Name = this.treeNodeName;
                        }
                        break;
                    }
                }


                tempobj.NetworkCapacitorBank_Power_Factor_After_Correction = this.powerFactorAfterCorrection;
                tempobj.NetworkCapacitorBank_Power_Factor_Before_Correction = this.powerFactorBeforeCorrection;
                tempobj.NetworkCapacitorBank_Rating = this.capacitorRating;
                tempobj.NetworkCapacitorBank_Required_Compensation = this.requiredCompensation;

                this.tempcapacitorlist.push(tempobj);
                this.tempinputXML.CapacitorBanks = this.tempcapacitorlist;


                // Power Factor Correction of Capacitor
                let pIndex = switchElement.cluster[0].cluster.i32.val;
                let powerFactorCluster = this.powerFactorClusterData[pIndex];

                let tempPFC = new PowerFactorCorrection();

                let countPFC = parseInt(sessionStorage.getItem("powerfactorlist"));

                //Capacitor
                tempPFC.Node_Id = tempobj.NetworkCapacitorBank_Node_Id;
                tempPFC.Node_Prefix = tempobj.NetworkCapacitorBank_Matrix_Node;
                tempPFC.Node_Name = tempobj.NetworkCapacitorBank_Name;

                //PFC

                tempPFC.PFC_Id = countPFC.toString();
                tempPFC.PFC_Target_Power_Factor = powerFactorCluster.cluster[0].items[3].val.toString();
                tempPFC.PFC_Auto_Size_Capacitor_Bank = powerFactorCluster.cluster[0].items[4].val.toString();
                tempPFC.PFC_Capacitor_Bank_Custom_Rating = powerFactorCluster.cluster[0].items[5].val;
                tempPFC.PFC_Capacitor_Bank_To_Install = powerFactorCluster.cluster[0].items[5].val.toString();

                tempPFC.PFC_Economic_Calculation = powerFactorCluster.cluster[1].items[2].val.toString();
                tempPFC.PFC_Installation_Cost = powerFactorCluster.cluster[1].items[3].val;
                tempPFC.PFC_Demand_Charge = powerFactorCluster.cluster[1].items[4].val;
                tempPFC.PFC_Utility_Factor = powerFactorCluster.cluster[1].items[5].val;
                tempPFC.PFC_Benefit_Time_Period = powerFactorCluster.cluster[1].items[7].val;
                tempPFC.PFC_Interest_Rate = powerFactorCluster.cluster[1].items[6].val;

                this.temppowerfactorcorrections.push(tempPFC);
                this.tempinputXML.PowerFactorCorrections = this.temppowerfactorcorrections;

                let treenodecountss = parseInt(sessionStorage.getItem("nodecount"));
                if (this.treeNodeId && treenodecountss != this.treeNodeId) {
                    sessionStorage.setItem("nodecount", (this.treeNodeId).toString());
                }


                this.SetcablesizeToReopen();

                let switchID = tempobj.NetworkCapacitorBank_Matrix_Node.split("CB");
                sessionStorage.setItem("CapacitorNode", tempobj.NetworkCapacitorBank_Matrix_Node);
                //PFC and capacitor
                sessionStorage.setItem("capacitorlist", switchID[1].toString());
                sessionStorage.setItem("powerfactorlist", (countPFC + 1).toString());
            });
        }

    }

    SetcablesizeToReopen() {

        let count = parseInt(sessionStorage.getItem("cablesizelist"));
        sessionStorage.setItem("cablesizelist", (count + 1).toString());
        this.CableSizeCount = this.treeNodeId - 1;
        sessionStorage.setItem("NodesCableSize", ("C" + this.CableSizeCount).toString());

        if (this.objectId <= this.treeNodeId) {

            this.objectId = this.treeNodeId + 1;
            sessionStorage.setItem("nodecount", this.objectId.toString());
            let treenodecounts = parseInt(sessionStorage.getItem("nodecount"));

        }
        else {

            sessionStorage.setItem("nodecount", this.objectId.toString());
            let treenodecounts = parseInt(sessionStorage.getItem("nodecount"));
        }
    }
    templateDownload() {
        let link = document.createElement('a');
        link.setAttribute('type', 'hidden');


        if (this.tempinputXML.NetworkSettings.CalculationMethod == "2" || this.tempinputXML.NetworkSettings.CalculationMethod == "3") {
            link.href = 'assets/cablepro/ppe/Custom PPE categories DGUV.csv';
        }
        else {
            link.href = 'assets/cablepro/ppe/Custom PPE categories.csv';
        }

        // link.download = path;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    handleFile(event) {
        const file = event.target.files[0];

        this.parseCSV(file);

        var tempFile;
        tempFile = document.getElementById("customFile");
        tempFile.value = "";
    }
    parseCSV(file: File): void {
        this.tempinputXML.NetworkSettings.ppeCategoriesTable = [];
        this.tempinputXML.NetworkSettings.ppeCategoriesTableHeaders = [];

        this.papa.parse(file, {
            complete: (result: any) => {
                if (result.errors && result.errors.length > 0) {
                    this._messageService.error("Could not import csv due to some errors. Please try with proper csv!");
                    return;
                }
                if (result.data && result.data.length > 0) {
                    result.data.pop();
                    this.tempinputXML.NetworkSettings.ppeCategoriesTable = result.data;
                    if (this.tempinputXML.NetworkSettings.CalculationMethod == "2" || this.tempinputXML.NetworkSettings.CalculationMethod == "3") {
                        this.tempinputXML.NetworkSettings.ppeCategoriesTableHeaders = ["Name", "Electrical Arc energy (kJ)", "Description"];
                    }
                    else {
                        this.tempinputXML.NetworkSettings.ppeCategoriesTableHeaders = ["Name", "Incident Energy Rating (cal/cm2)", "Description"];
                    }
                    // this.tableHeaders = result.data[0];
                    this.tempinputXML.NetworkSettings.ppeCategoriesTable.splice(0, 1);
                    if (this.tempinputXML.NetworkSettings.ppeCategoriesTable && this.tempinputXML.NetworkSettings.ppeCategoriesTable.length > 0) {
                        const columnsCheck = result.data.every(item => item && item.length == 3);
                        if (!columnsCheck) {
                            this.tempinputXML.NetworkSettings.ppeCategoriesTable = [];
                            this.tempinputXML.NetworkSettings.ppeCategoriesTableHeaders = [];
                            this._messageService.error("There Should be only three columns in the custom file.");
                            return;
                        }
                        const nullValidation = result.data.every(item => item[0] && item[1] && item[2]);
                        if (!nullValidation) {
                            this.tempinputXML.NetworkSettings.ppeCategoriesTable = [];
                            this.tempinputXML.NetworkSettings.ppeCategoriesTableHeaders = [];
                            this._messageService.error("There is missing data in the custom file.");
                            return;
                        }
                        const numericValidation = result.data.every(item => !Number.isNaN(Number(item[1])));
                        if (!numericValidation) {
                            this.tempinputXML.NetworkSettings.ppeCategoriesTable = [];
                            this.tempinputXML.NetworkSettings.ppeCategoriesTableHeaders = [];
                            this._messageService.error("The second column of the custom file must contain only numerical values.");
                            return;
                        }
                        if (this.tempcablesizelist && this.tempcablesizelist.length > 0) {
                            this.tempcablesizelist.forEach(elements => {
                                if (elements && elements.CableSizeCalcInputXML && elements.CableSizeCalcInputXML.arcFlashData && Object.keys(elements.CableSizeCalcInputXML.arcFlashData).length > 0) {
                                    elements.CableSizeCalcInputXML.arcFlashData.ppeCategoriesTable = this.tempinputXML.NetworkSettings.ppeCategoriesTable;
                                    elements.CableSizeCalcInputXML.arcFlashData.ppeCategoriesTableHeaders = this.tempinputXML.NetworkSettings.ppeCategoriesTableHeaders;
                                }
                            });
                        }

                        this.tempinputXML.CableSizeCalcInputs = JSON.stringify(this.tempcablesizelist);
                        this.onInputChange();
                    }
                    else {
                        this.tempinputXML.NetworkSettings.ppeCategoriesTable = [];
                        this.tempinputXML.NetworkSettings.ppeCategoriesTableHeaders = [];
                        this._messageService.error("The custom file contains no data.");
                        return;
                    }
                }
                else {
                    this._messageService.error("The custom file contains no data.");
                    return;
                }

            }
        });
    }
    oncategorySourceChange() {
        // this.tempinputXML.NetworkSettings.ppeCategoriesTable = [];
        // this.tempinputXML.NetworkSettings.ppeCategoriesTableHeaders = [];

        if (this.tempcablesizelist && this.tempcablesizelist.length > 0) {
            this.tempcablesizelist.forEach(elements => {
                if (elements && elements.CableSizeCalcInputXML && elements.CableSizeCalcInputXML.arcFlashData && Object.keys(elements.CableSizeCalcInputXML.arcFlashData).length > 0) {
                    elements.CableSizeCalcInputXML.arcFlashData.ppeCategory.categorySource = this.tempinputXML.NetworkSettings.CategorySource;
                    if (this.tempinputXML.NetworkSettings.CategorySource == "5" && (this.tempinputXML.NetworkSettings.ppeCategoriesTable && this.tempinputXML.NetworkSettings.ppeCategoriesTable.length > 0)) {
                        elements.CableSizeCalcInputXML.arcFlashData.ppeCategoriesTable = this.tempinputXML.NetworkSettings.ppeCategoriesTable;
                        elements.CableSizeCalcInputXML.arcFlashData.ppeCategoriesTableHeaders = this.tempinputXML.NetworkSettings.ppeCategoriesTableHeaders;
                    }
                    else {
                        elements.CableSizeCalcInputXML.arcFlashData.ppeCategoriesTable = [];
                        elements.CableSizeCalcInputXML.arcFlashData.ppeCategoriesTableHeaders = [];
                    }

                }
            });
        }

        // if (this.CableSize["arcFlashData"] && this.CableSize["arcFlashData"].ppeCategoriesTable) {
        //     this.CableSize["arcFlashData"].ppeCategoriesTable = [];
        //     this.CableSize["arcFlashData"].ppeCategoriesTableHeaders = [];
        // }

        this.ArcFlashResult = [];
        this.ArcFlashCompleteResult = [];
        this.tempcablesizelist = [...this.tempcablesizelist]

        this.tempinputXML.CableSizeCalcInputs = JSON.stringify(this.tempcablesizelist);

        if (this.tempinputXML.NetworkSettings.CategorySource != '5' || (this.tempinputXML.NetworkSettings.ppeCategoriesTable && this.tempinputXML.NetworkSettings.ppeCategoriesTable.length > 0))
            this.onInputChange();
    }
    GetSupplytypeValue(supplyData) {
        if (supplyData.dataType === "Network settings") {
            let settingsDataCalc = supplyData.dataValue[0];
            this.standardSettingsData = supplyData.dataValue[1];
            $('#AllowableVoltageDrop_Network').val(settingsDataCalc.cluster.dbl[0].val);
            $('#AllowableVoltageDrop_MainsCable').val(settingsDataCalc.cluster.dbl[1].val);
            this.SupplyVoltage = settingsDataCalc.ew[0].val.toString();
            // $('#SupplyVoltage').val(settingsDataCalc.ew[0].val.toString()).trigger('change', "true");
            this.VoltageTolerance = settingsDataCalc.ew[1].val.toString();
            this.Frequency = settingsDataCalc.ew[2].val.toString();
            // $('#Frequency').val(settingsDataCalc.ew[2].val.toString()).trigger('change', "true");
            this.cableCalculationStandard = this.standardSettingsData.ew[0].val.toString();
            if (this.standardSettingsData.ew.length > 3) {
                this.tempinputXML.NetworkSettings.CalculationMethod = this.standardSettingsData.ew[3].val.toString();
                this.tempinputXML.NetworkSettings.CategorySource = this.standardSettingsData.ew[4].val.toString();
                this.tempinputXML.NetworkSettings.IsArcFlash = settingsDataCalc.boolean.val == 1 ? true : false;
                this._settingService.hideArcFlashReport = !this.tempinputXML.NetworkSettings.IsArcFlash;
                this.populateCategorySourcesBaedOnMethod();
            }
            this.cableCalcProtectiveDeviceSource = '2';
            if (settingsDataCalc.ew[3] != undefined && settingsDataCalc.ew[3] != null && settingsDataCalc.ew[3].val != null) {
                let cableCalcProtectiveDeviceSource = settingsDataCalc.ew[3].val.toString();
                if (cableCalcProtectiveDeviceSource == "0")
                    this.cableCalcProtectiveDeviceSource = "1";
            }
            this.onEarthinSystemChange();
            this.ReportStandardCountry = this.cableCalculationStandard;
            if (this.ReportStandardCountry == "2") {
                this.isBritishStandard = true;
            }
            else
                this.isBritishStandard = false;

            // $('#CableCalcProtectiveDeviceSource').val(this.cableCalcProtectiveDeviceSource).trigger('change', "true");
            // $('#CableCalcStandard').val(this.standardSettingsData.ew[0].val.toString()).trigger('change', "true");


            let protectionCoordintionsetting = this.standardSettingsData.ew.find(m => m.name == "Protection coordination standard");
            this.protectionCoordinationStandardData = protectionCoordintionsetting;
            if (protectionCoordintionsetting != undefined && protectionCoordintionsetting != null && protectionCoordintionsetting.name && protectionCoordintionsetting.val != null) {
                this.protectionCoordinationStandard = protectionCoordintionsetting.val.toString();
            }
            else {
                if (this.cableCalculationStandard == '0' || this.cableCalculationStandard == '1')
                    this.protectionCoordinationStandard = '0';
                else
                    this.protectionCoordinationStandard = '1';
            }

            // $('#ProtectionCoordinationStandard').val(this.protectionCoordinationStandard).trigger('change', "true");

            // this.cableCalculationStandard = standardSettingsData.ew[0].val.toString();
            // this.tempinputXML.NetworkSettings.CableCalcStandard = this.cableCalculationStandard;
            let TransformerTypicalSetting = this.standardSettingsData.ew.find(m => m.name == "Typical transformer values");
            if (TransformerTypicalSetting && TransformerTypicalSetting.val != null)
                this.TransformerTypicalDataSource = TransformerTypicalSetting.val.toString();
            else
                this.TransformerTypicalDataSource = "0";
            //  alert(supplyData.dataValue[2].ew[0].val);
            this.networksupplytype = supplyData.dataValue[2].ew[0].val;
            // $('#networksupplytype').val(supplyData.dataValue[2].ew[0].val);

            if (supplyData.dataValue[2].ew[1].val == 1) {
                $('#networksupplyradio2').attr('checked', 'true');
                // $('#networksupplyradio1').attr('checked', 'false');
            }
            else {
                $('#networksupplyradio1').attr('checked', 'true');
                // $('#networksupplyradio2').attr('checked', 'false');
            }
        }

        //$('#networksupplyradio2').attr('checked', 'false');
        // $('#networksupplyradio1').attr('checked', 'true');

        if (supplyData.dataType === "Network feeder") {


            if (this.items[0].name == "Existing switchboard") {

                $('#ExistingSwitchboard_Fault_Level').val(supplyData.dataValue.cluster1[1].dbl.val);
            }
            else {
                $('#Networkfeeder_Fault_Level').val(supplyData.dataValue.cluster1[1].dbl.val);
            }
        }

        if (supplyData.dataType === "Transformer") {


            $('#Transformer_Primary_Voltage').val(supplyData.dataValue.cluster1[1].dbl[0].val);
            $('#Transformer_Primary_Fault_Level').val(supplyData.dataValue.cluster1[1].dbl[1].val);

            if (supplyData.dataValue.cluster1[1].boolean[0].val === 1) {


                if (this.HVRelayObjectData != undefined && this.HVRelayObjectData != null) {
                    $('#checkSupplyHVRelay').attr('checked', 'true');
                    this.transformerHVRelay = true;
                    this.powerHVRelay = false;

                    // $('#HVRelayHead').css('display', 'block');
                    let curveEquation = this.HVRelayObjectData.cluster[1].ew.val;
                    let curveEquationName = this.HVRelayObjectData.cluster[1].ew.choice[curveEquation];
                    $('#HVRelay_Pick_Up_Current').val(this.HVRelayObjectData.cluster[1].dbl[0].val);
                    $('#HVRelay_TMS_TDS').val(this.HVRelayObjectData.cluster[1].dbl[1].val);
                    $('#HVRelay_Max_Disconnection_Time').val(this.HVRelayObjectData.cluster[1].dbl[2].val);
                    if (this.HVRelayObjectData.cluster[1].dbl && this.HVRelayObjectData.cluster[1].dbl.length > 3)
                        $('#HVRelay_Tolerance').val(this.HVRelayObjectData.cluster[1].dbl[3].val);
                    if (this.HVRelayObjectData.cluster[1].dbl && this.HVRelayObjectData.cluster[1].dbl.length > 4)
                        $('#HVFuseChk').prop('checked', this.HVRelayObjectData.cluster[1].dbl[4].val);
                    if (this.HVRelayObjectData.cluster[1].dbl.length > 4)
                        this.HVFuse = this.HVRelayObjectData.cluster[1].dbl[4].val;

                    $('#HVRelay_Curve_Equation').val(curveEquation).trigger('change', "true");

                    if (curveEquationName == "IEC Standard 60255") {
                        $('#HVRelay_Curve_Type').val(this.HVRelayObjectData.cluster[1].cluster.ew[0].val).trigger('change', "true");
                    }
                    if (curveEquationName == "IEEE Standard C37.112") {
                        $('#HVRelay_Curve_Type').val(this.HVRelayObjectData.cluster[1].cluster.ew[1].val).trigger('change', "true");
                    }
                    if (curveEquationName == "Electromechanical IAC") {
                        $('#HVRelay_Curve_Type').val(this.HVRelayObjectData.cluster[1].cluster.ew[2].val).trigger('change', "true");
                    }
                }
            }
            if (supplyData.dataValue.cluster1[1].boolean && supplyData.dataValue.cluster1[1].boolean.length > 1 && supplyData.dataValue.cluster1[1].boolean[1].val === 1) {
                this.HVFuse = true;
                $('#HVFuseChk').prop('checked', true);
            }
            let autosizeTransformer = supplyData.dataValue.cluster1[1].cluster[0];
            let transformerImpedence = supplyData.dataValue.cluster1[1].cluster[1];
            let impedenceCorrection = supplyData.dataValue.cluster1[1].cluster[2];

            //Auto size
            $('#Transformer_No_Of_Parallel_Transformers').val(autosizeTransformer.i32.val);
            $('#Transformer_Custom_Rating').val(autosizeTransformer.dbl[0].val);
            if (autosizeTransformer.boolean.val === 0) {
                this.Autosizetransformer = false;
                // $('#Autosizetransformer').prop('checked', false);//.trigger('change', 'true');
                // $('#Autosizetransformerrow1').show();
                // $('#Autosizetransformerrow2').show();
            }
            else {
                this.Autosizetransformer = true;
                // $('#Autosizetransformer').prop('checked', true);//.trigger('change', 'true');
                // $('#Autosizetransformerrow1').hide();
                // $('#Autosizetransformerrow2').hide();
            }

            //Impedance
            $('#Transformer_Impedence_Voltage').val(transformerImpedence.dbl[0].val);
            $('#Transformer_XR_Ratio').val(transformerImpedence.dbl[1].val);
            if (transformerImpedence.boolean.val === 1) {
                this.Customtransformer = true;
                // $('#Customtransformer').attr('checked', 'true');
                // $('#Customtransformerrow1').show();
                // $('#Customtransformerrow2').show();
            }

            //impedenceCorrection
            this.Transformer_Calculation_Method = impedenceCorrection.ew.val.toString();
            // $('#Transformer_Calculation_Method').val((impedenceCorrection.ew.val).toString()).trigger('change', "true");
            $('#Transformer_Load_Losses').val(impedenceCorrection.dbl[0].val);
            if (impedenceCorrection.boolean.val === 1) {
                this.Impedencecorrection = true;
                // $('#Impedencecorrection').attr('checked', 'true');
                // $('#Impedencecorrectionrow').show();
            }
            else {
                this.Impedencecorrection = false;
                // $('#Impedencecorrection').attr('checked', 'false');
            }

        }


        if (supplyData.dataType === "Generator") {

            $('#Generator_Rated_Voltage').val(supplyData.dataValue.cluster1[1].dbl.val);


            let generatorSizing = supplyData.dataValue.cluster1[1].cluster[0];
            let generatorImpedence = supplyData.dataValue.cluster1[1].cluster[1];
            let impedenceCorrection = supplyData.dataValue.cluster1[1].cluster[2];

            //Auto size
            $('#Generator_No_Of_Parallel_Generators').val(generatorSizing.i32.val);
            $('#Generator_Customer_Rating').val(generatorSizing.dbl[0].val);
            if (generatorSizing.boolean.val === 0) {
                this.Autosizegenerator = false;
                // $('#Autosizegenerator').prop('checked', false).trigger('change', 'true');
                // $('#Autosizegeneratorrow1').show();
                // $('#Autosizegeneratorrow2').show();
            }
            else {
                this.Autosizegenerator = true;
                // $('#Autosizegenerator').prop('checked', true).trigger('change', 'true');
                // $('#Autosizegeneratorrow1').hide();
                // $('#Autosizegeneratorrow2').hide();
            }

            //Impedance
            $('#Generator_Subtransient_Reactance').val(generatorImpedence.dbl[0].val);
            $('#Generator_XDR_Ratio').val(generatorImpedence.dbl[1].val);
            if (generatorImpedence.boolean.val === 1) {
                this.Customgenerator = true;
                // $('#Customgenerator').attr('checked', 'true');
                // $('#Customgeneratorrow1').show();
                // $('#Customgeneratorrow2').show();
            }

            //impedenceCorrection
            $('#Generator_Power_Factor_Of_Generators').val((impedenceCorrection.dbl[0].val).toString());
            $('#Generator_Voltage_Regulation').val(impedenceCorrection.dbl[1].val);
            if (impedenceCorrection.boolean.val === 1) {
                this.GeneratorImpedencecorrection = true;
                // $('#GeneratorImpedencecorrection').attr('checked', 'true');
                // $('#GeneratorImpedencecorrectionrow').show();
            }
        }

        if (supplyData.dataType === "Powerstation") {


            $('#Power_Station_Generator_Rated_Voltage').val(supplyData.dataValue.cluster1[1].dbl.val);

            if (supplyData.dataValue.cluster1[1].boolean[0].val === 1) {

                this.transformerHVRelay = false;
                this.powerHVRelay = true;
                //$('#HVRelayHead').css('display', 'block');

                $('#PowerStationHVRelay').attr('checked', 'true');
                let curveEquation = this.HVRelayObjectData.cluster[1].ew.val;
                let curveEquationName = this.HVRelayObjectData.cluster[1].ew.choice[curveEquation];
                $('#HVRelay_Pick_Up_Current').val(this.HVRelayObjectData.cluster[1].dbl[0].val);
                $('#HVRelay_TMS_TDS').val(this.HVRelayObjectData.cluster[1].dbl[1].val);
                $('#HVRelay_Max_Disconnection_Time').val(this.HVRelayObjectData.cluster[1].dbl[2].val);
                if (this.HVRelayObjectData.cluster[1].dbl && this.HVRelayObjectData.cluster[1].dbl.length > 3)
                    $('#HVRelay_Tolerance').val(this.HVRelayObjectData.cluster[1].dbl[3].val);
                if (this.HVRelayObjectData.cluster[1].dbl && this.HVRelayObjectData.cluster[1].dbl.length > 4) {
                    $('#HVFuseChk').prop('checked', this.HVRelayObjectData.cluster[1].dbl[4].val);
                    this.HVFuse = this.HVRelayObjectData.cluster[1].dbl[4].val;
                }
                $('#HVRelay_Curve_Equation').val(curveEquation).trigger('change', "true");

                if (curveEquationName == "IEC Standard 60255") {
                    $('#HVRelay_Curve_Type').val(this.HVRelayObjectData.cluster[1].cluster.ew[0].val).trigger('change', "true");
                }
                if (curveEquationName == "IEEE Standard C37.112") {
                    $('#HVRelay_Curve_Type').val(this.HVRelayObjectData.cluster[1].cluster.ew[1].val).trigger('change', "true");
                }
                if (curveEquationName == "Electromechanical IAC") {
                    $('#HVRelay_Curve_Type').val(this.HVRelayObjectData.cluster[1].cluster.ew[2].val).trigger('change', "true");
                }
            }

            if (supplyData.dataValue.cluster1[1].boolean[1].val === 1) {
                this.HVFuse = true;
                $('#HVFuseChk').prop('checked', true);
            }

            let powerStationSizing = supplyData.dataValue.cluster1[1].cluster[0];
            let generatorImpedence = supplyData.dataValue.cluster1[1].cluster[1];
            let transformerImpedence = supplyData.dataValue.cluster1[1].cluster[2];
            let impedenceCorrection = supplyData.dataValue.cluster1[1].cluster[3];

            //Auto size
            $('#Power_Station_No_Of_Parallel_Units').val(powerStationSizing.items[3].val);
            $('#Power_Station_Generator_Custom_Rating').val(powerStationSizing.items[4].val);
            $('#Power_Station_Transformer_Custom_Rating').val(powerStationSizing.items[5].val);
            if (powerStationSizing.items[2].val === 0) {
                this.Autosizepower = false;
                // $('#Autosizepower').prop('checked', false).trigger('change', 'true');
                // $('#Autosizepowerrow1').show();
                // $('#Autosizepowerrow2').show();
            }
            else {
                this.Autosizepower = true;
                // $('#Autosizepower').prop('checked', true).trigger('change', 'true');
                // $('#Autosizepowerrow1').hide();
                // $('#Autosizepowerrow2').hide();
            }

            //Generator Impedance
            $('#Power_Station_Subtransient_Reactance').val(generatorImpedence.items[3].val);
            $('#Power_Station_XDR_Ratio').val(generatorImpedence.items[4].val);
            if (generatorImpedence.items[2].val === 1) {
                this.PowerStationCustomgenerator = true;
                // $('#PowerStationCustomgenerator').attr('checked', 'true');
                // $('#PowerStationCustomgeneratorrow').show();
            }

            //Transformer Impedance
            $('#Power_Station_Impedence_Voltage').val(transformerImpedence.items[3].val);
            $('#Power_Station_XR_Ratio').val(transformerImpedence.items[4].val);
            if (transformerImpedence.items[2].val === 1) {
                this.PowerStationCustomtransformer = true;
                // $('#PowerStationCustomtransformer').attr('checked', 'true');
                // $('#PowerStationCustomtransformerrow').show();
            }

            //Transformer Impedance
            $('#Power_Station_Power_Factor_Of_Generator').val(impedenceCorrection.items[3].val);
            $('#Power_Station_Generator_Voltage_Regulation').val(impedenceCorrection.items[4].val);
            this.Power_Station_Calc_Transformer_XandR = impedenceCorrection.items[5].val.toString();
            // $('#Power_Station_Calc_Transformer_XandR').val(impedenceCorrection.items[5].val).trigger('change', "true");
            $('#Power_Station_Transformer_Load_Losses').val(impedenceCorrection.items[6].val);
            if (impedenceCorrection.items[2].val === 1) {
                this.PowerStationImpedencecorrection = true;
                // $('#PowerStationImpedencecorrection').attr('checked', 'true');
                // $('#PowerStationImpedencecorrectionrow1').show();
                // $('#PowerStationImpedencecorrectionrow2').show();
            }
        }
    }

    Allowdecimal(event: any) {
        var num = event.target.value;

        if (num.split('.').length > 0) {
            var beforezero = num.split('.')[0];
            if (beforezero == "") {
                num = "0" + num;
            }
        }
        var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : event.target.value;

        event.target.value = with2Decimals
        this.efficiency = event.target.value;
    }
    allowDecimalSetModal(event: any, type: string) {
        var num = event.target.value;
        if (num.split('.').length > 0) {
            var beforezero = num.split('.')[0];
            if (beforezero == "") {
                num = "0" + num;
            }
        }
        var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : event.target.value;

        event.target.value = with2Decimals
        if (type == "airambtemp") {
            this.table271model.AbientTemp = event.target.value;
        }
        if (type == "AbientTemp") {
            this.table271model.AbientTemp = event.target.value;
        }
        if (type == "Depth") {
            this.table28model.Depth = event.target.value;
        }


    }
    allowDecimalSetModalThree(event: any, type: string) {
        var num = event.target.value;
        if (num.split('.').length > 0) {
            var beforezero = num.split('.')[0];
            if (beforezero == "") {
                num = "0" + num;
            }
        }
        var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,3})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : event.target.value;

        event.target.value = with2Decimals
        if (type == "Resitivity") {
            this.table29model.Resitivity = event.target.value;
        }

    }
    Allowdecimalwithplaces(event: any, places: number) {

        var num = event.target.value;
        var with2Decimals = 0;
        if (num.split('.').length > 0) {
            var beforezero = num.split('.')[0];
            if (beforezero == "") {
                num = "0" + num;
            }
        }
        if (places == 0) {
            with2Decimals = Math.round(num);
        }
        if (places == 2) {
            with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : event.target.value;

        }
        else if (places == 3) {
            with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,3})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : event.target.value;

        }
        else if (places == 4) {
            with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,4})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0] : event.target.value;

        }
        else if (places == 1) {
            with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,1})?/) != null ? num.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0] : event.target.value;

        }

        event.target.value = with2Decimals
        //this.efficiency=event.target.value;
    }

    AllowOnlyNumericValues(event: any) {
        let defaultValue = event.target.min;

        if (event.target.value.indexOf('.') === -1) {
        }
        else {
            //debugger;
            var beforezero = event.target.value.split('.');
            //debugger;
            event.target.value = parseInt(beforezero[0]);
        }
    }

    CommonValidationFn(event: any) {


        if (parseFloat(event.target.value) > parseFloat(event.target.max)) {
            event.target.value = event.target.max;
        }
        if (parseFloat(event.target.value) < parseFloat(event.target.min) || event.target.value === '') {
            event.target.value = event.target.min;
        }

        //this.getAllControlsValue();
    }

    tabheadchange(evt, tabId) {
        if (tabId == "networktab3" && (this.selectedNetworkmodel == 3 || this.selectedNetworkmodel == 4)) {
            tabId = "networktab1";
        }
        //$("#calculationresulttab2").hide();
        this.tabId = tabId;
        this.isMobile = true;
        this.isScrollforCableSize_MaxDemand = false;
        var i, tabcontent, tablinks;
        tabcontent = document.getElementById("networktabslist").getElementsByClassName("network-tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        $('#tempresults').show();
        $("#calculationresultstab").show();
        $("feederTreeview").show();

        if (tabId == 'networktab7') {
            this.isMobile = false;
            $('#tempresults').hide();
            $("#calculationresultstab").hide();
            var sessionData = JSON.parse(sessionStorage.getItem("currentnode"));
            if (sessionData != null && sessionData != undefined) {
                if (sessionData.objecttype == -1 || sessionData.objecttype == 1 || sessionData.objecttype == 3) {
                    this.isMobile = true;
                    $('#tempresults').show();
                    $("#calculationresultstab").show();
                }
            }
        }

        // Calculation Results
        if (tabId == 'networktab1') {
            if (this.selectedNetworkmodel == 1 || this.selectedNetworkmodel == 3 || this.selectedNetworkmodel == 4) {
                this.isMobile = false;
                $('#tempresults').hide();
                $("#calculationresultstab").hide();
                if (this.selectedNetworkData)
                    document.getElementById('networktab3').style.display = "block";
            }
        }

        if (tabId == 'networktab2') {
            this.isMobile = false;
            $('#tempresults').hide();
            $("#calculationresultstab").hide();
        }

        if (tabId == 'networktab11') {
            this.isMobile = false;
            $('#tempresults').hide();
            $("#calculationresultstab").hide();
        }

        if (tabId == 'networktab4') {
            this.isMobile = false;
            $('#tempresults').hide();
            $("#calculationresultstab").hide();
        }

        if (tabId == 'networktab5') {
            this.isMobile = false;
            $('#tempresults').hide();
            $("#calculationresultstab").hide();
        }

        if (tabId == 'networktab10') {
            this.isMobile = false;
            $("#calculationresultstab").hide();
        }

        //Cable Size
        if (tabId == 'networktab9') {

            this.isScrollforCableSize_MaxDemand = true;
            this.isMobile = false;
            $('#networkinputs').css('width', '100%');
            $('#tempresults').hide();
            $("#calculationresultstab").hide();
            $("generatorTreeview").hide();
            $("transformerTreeview").hide();
            $("feederTreeview").hide();
            $("powerFactorTreeview").hide();
            $("powerStationTreeview").hide();
        }
        //Max Demand
        else if (tabId == 'networktab8') {
            this.isScrollforCableSize_MaxDemand = true;
            this.isMobile = false;
            $('#networkinputs').css('width', '100%');
            $('#tempresults').hide();
            $("#calculationresultstab").hide();
            $("generatorTreeview").hide();
            $("transformerTreeview").hide();
            $("feederTreeview").hide();
            $("powerFactorTreeview").hide();
            $("powerStationTreeview").hide();
            this.Loaddata = undefined;
            this.SubData = undefined;
            this.getMaxDemandByCalculationId();
            // this.GetLoadGroupByInstallId(this.CalculationId, 5);
        }
        //
        else {
            $('#networkinputs').css('width', '40%');
        }
        if (tabId == 'networktab6') {
            //Get Capacitor Data
            this.isMobile = true;
            $("#calculationresulttab2").show();
            $('#isCableSizeShown').css('display', 'block');
            this.showgeneratorResult = false;
            this.showFeederResult = false;
            this.showPowerStationResult = false;
            this.showTransformerResult = false;
            this.showPowerFactorResult = true;
            this.showSwitchboardResult = false;
        }


        tablinks = document.getElementById("ulcablesize").getElementsByClassName("network-tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
            tablinks[i].className = tablinks[i].className.replace(" activebold", "");
        }
        document.getElementById(tabId).style.display = "block";
        evt.currentTarget.className += " active activebold";
        if (tabId == 'networktab9') {
            this.loadProtectionCalculation();
        }
        // if (this.selectedNetworkData && evt.target.value == '0') {
        //     this.changediv(this.selectedNetworkData);
        // }
        // if (this.supplyNetworkData && evt.target.value == '0') {

        //     this.selectParentNetwork(this.supplyNetworkData);
        // }

        // if (tabId == 'networktab1' && $('#selectNetwork').val() == "0") {
        //     $('#selectNetwork').val('2').trigger("change", "true")
        // }
    }

    loadProtectionCalculation() {
        // setTimeout(() => {
        if ($('input').filter("[caption='Thermal setting']").length) {
            let fcaption = this.uiControlsLookUpData[0].controles[3].fCaption;
            if (fcaption == "Thermal setting") {
                $('input').filter("[caption='Thermal setting']").val(this.uiControlsLookUpData[0].controles[3].fDefaultValue);
                this.CableSize['protection']['thermalsetting'] = this.uiControlsLookUpData[0].controles[3].fDefaultValue;
                let Val1: any = $('input').filter("[caption='Thermal setting']").val().toString();
                const MultiplierValue = $('input').filter("[caption='Trip Multiplier']").val().toString();
                let currentValue: any = '';
                let Val2: any = '';
                if (!isNaN(Val1) && Val1.toString().indexOf('.') != -1) {
                    Val1 = parseFloat(Val1);
                } else {
                    Val1 = parseInt(Val1);
                }
                if (!Number.isNaN(Number(MultiplierValue)) && MultiplierValue.toString().indexOf('.') != -1) {
                    Val2 = parseFloat(MultiplierValue);
                } else {
                    Val2 = parseInt(MultiplierValue);
                }
                currentValue = Val1 * Val2;
                currentValue = currentValue.toFixed(2);
                if ($('input').filter("[caption='Trip current']").length) {
                    $('input').filter("[caption='Trip current']").val(currentValue);
                    this.CableSize['protection']['tripcurrent'] = currentValue;
                }

                this.updatecablesizeinarray();
            }
        }

        //select_HRCFuse
        if ($('#select_HRCFuse').length) {
            let Val1: any =
                $('#select_HRCFuse')[0][$('#select_HRCFuse').val().toString()].innerHTML.split(" ")[1];
            const MultiplierValue = $('input').filter("[caption='Trip Multiplier']").val().toString();
            let currentValue: any = '';
            let Val2: any = '';
            if (!isNaN(Val1) && Val1.toString().indexOf('.') != -1) {
                Val1 = parseFloat(Val1);
            } else {
                Val1 = parseInt(Val1);
            }
            if (!Number.isNaN(Number(MultiplierValue)) && MultiplierValue.toString().indexOf('.') != -1) {
                Val2 = parseFloat(MultiplierValue);
            } else {
                Val2 = parseInt(MultiplierValue);
            }
            currentValue = Val1 * Val2;
            currentValue = currentValue.toFixed(2);
            if ($('input').filter("[caption='Trip current']").length) {
                $('input').filter("[caption='Trip current']").val(currentValue);
                this.CableSize['protection']['tripcurrent'] = currentValue;

                this.updatecablesizeinarray();
            }
        }

        //select_MCB
        if ($('#select_MCB').length) {
            let Val1: any =
                $('#select_MCB')[0][$('#select_MCB').val().toString()].innerHTML.split(" ")[1];
            const MultiplierValue = $('input').filter("[caption='Trip Multiplier']").val().toString();
            let currentValue: any = '';
            let Val2: any = '';
            if (!isNaN(Val1) && Val1.toString().indexOf('.') != -1) {
                Val1 = parseFloat(Val1);
            } else {
                Val1 = parseInt(Val1);
            }
            if (!Number.isNaN(Number(MultiplierValue)) && MultiplierValue.toString().indexOf('.') != -1) {
                Val2 = parseFloat(MultiplierValue);
            } else {
                Val2 = parseInt(MultiplierValue);
            }
            currentValue = Val1 * Val2;
            currentValue = currentValue.toFixed(2);
            if ($('input').filter("[caption='Trip current']").length) {
                $('input').filter("[caption='Trip current']").val(currentValue);
                this.CableSize['protection']['tripcurrent'] = currentValue;

                this.updatecablesizeinarray();
            }
        }
        // });
    }

    selectParentNetwork(item) {

        $("#SwitchboardHead").hide();
        $("#LoadHead").hide();
        $("#CapacitorHead").hide();
        $('#PowerfactorHead').hide();

        $("#MaxDemandHead").hide();
        $("#CableSizeHead").hide();
        $("#ArcFlashHead").hide();

        sessionStorage.removeItem("currentnode");
        if (item != null && item.objecttype != null) {
            sessionStorage.setItem("currentnode", JSON.stringify(item));
            if (item.objecttype == 1) {
                $("#SwitchboardHead").show();
                $("#MaxDemandHead").show();
                $("#CableSizeHead").show();
                $("#ArcFlashHead").show();

                let a_element: HTMLElement = document.getElementById('aSwitchboard') as HTMLElement;
                a_element.click();

                // $('#drTable23Row').trigger('change');
                $("#LoadHead").hide();
                $("#CapacitorHead").hide();
                $('#PowerfactorHead').hide();

                //Get Switchboard Data
                this.getswitchboard(item);
                $('#selectNetwork').val('0');
            }
        }
        this.tree.treeModel.getNodeById(item.id)
            .setActiveAndVisible();
    }
    tabChangeProtective(evt, tabId) {

        var i, tabcontent, tablinks;
        tabcontent = document.getElementById("protectiveTabContent").getElementsByClassName("protective-tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementById("ulprotectioncoordination").getElementsByClassName("protective-tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
            tablinks[i].className = tablinks[i].className.replace(" activebold", "");
        }
        document.getElementById(tabId).style.display = "block";
        evt.currentTarget.className += " active activebold";
    }
    tabChange(evt, tabId) {

        //if (tabId === 'tab2' && !this.premiumUser) return false;
        var i, tabcontent, tablinks;
        tabcontent = document.getElementById("cablesizeTabContent").getElementsByClassName("cablesizetabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementById("cablesize-summary-tabs").getElementsByClassName("cablesize-tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
            tablinks[i].className = tablinks[i].className.replace(" activebold", "");
        }
        document.getElementById(tabId).style.display = "block";
        evt.currentTarget.className += " active activebold";
    }

    tabCableChange(evt, tabId) {
        //debugger;
        //  $("#calculationresulttab2").hide();

        if (tabId == 'calculationresulttab2') {
            $("#calculationresulttab2").show();
        }
        var i, tabcontent, tablinks;
        tabcontent = document.getElementById("calcResultContent").getElementsByClassName("resulttabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementById("calcResult").getElementsByClassName("calcResult-tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
            tablinks[i].className = tablinks[i].className.replace(" activebold", "");
        }
        document.getElementById(tabId).style.display = "block";
        evt.currentTarget.className += " active activebold";
    }

    SetUpgradeLink() {
        if (this.isPlanAddedManually === true)
            this._router.navigate(['/app/main/projects/', 'subscriptionplans']);
        else
            this.ManageSub();
        $('#reportDlg').modal('hide')
    }

    checkDevice() {

        const screenHeight = window.innerHeight;
        const screenWidth = window.innerWidth;
        //alert(screenWidth);
        let prev = this.isMobile;
        if (screenWidth > 576) {
            this.isMobile = false;
        }
        else {
            this.isMobile = true;
        }
    }

    showLoader(): void {
        this.loaderService.showHeader();
    }
    hideLoader(): void {
        this.loaderService.hideHeader();
    }
    onretrySave() {
        $('#retry-save').on('click', () => {
            if (navigator.onLine === true) {
                // this.UpdateXmlValues(this.cableproXmlInput);
                this.onInputChange();
                $('#popup-mask').hide();
            }
        });
        $('#dismissAlert').on('click', () => {
            $('#popup-mask').hide();
        });
    }
    ClearReport(): void {
        // this.CalculationReportInput.projectname = '';
        // this.CalculationReportInput.jobNo = '';
        this.CalculationReportInput.sendEmail = false;
        this.CalculationReportInput.printName = true;
        // $('#chkPrintName').prop('checked', true);

        //this.CalculationReportInput.eamilList = '';

        $('#sendEmailError1').css('display', 'none');
        $('#sendEmailError2').css('display', 'none');

        $('#reportDlg').modal('hide');
    }
    newObject() {
        $('#new-object').modal('show');

    }
    addObject(treenode) {
        $('#add-object').modal('show');
    }
    resetObject() {
        this.options.allowDrag = false;
        this.options.allowDrop = false;
        this.addRemoveNode = true;
        this.PrevActiveValue = undefined;
        this.PrevNeutralValue = undefined;
        let nodeCount = 0;
        this.objectId = 3;
        this.SupplyVoltage = "0";
        this.Frequency = "0";
        this.VoltageTolerance = "0";
        this.TransformerTypicalDataSource = "0";
        this.networksupplytype = "0";
        this.Transformer_Calculation_Method = "0";
        this.resetStatus = true;
        this.Autosizegenerator = true;
        this.Customtransformer = false;
        this.Autosizetransformer = true;
        this.Impedencecorrection = false;
        this.GeneratorImpedencecorrection = false;
        this.PowerStationCustomgenerator = false;
        this.Customgenerator = false;
        this.PowerStationCustomtransformer = false;
        this.PowerStationImpedencecorrection = false;
        this.Power_Station_Calc_Transformer_XandR = "0";
        this.selectResults = "8";

        //this.IsCableCalcStandardChanged = true;
        this.isMaxDemandDataShown = false;
        this.isXmlStatus = true;
        document.getElementById('defaultCableOpen2').click();
        this.LoadStorage.installationId = 5;
        sessionStorage.setItem('SupplyType', "Supply");
        sessionStorage.setItem('powerfactorlist', nodeCount.toString());
        sessionStorage.setItem('loadlist', nodeCount.toString());
        sessionStorage.setItem('switchlist', nodeCount.toString());
        sessionStorage.setItem('cablesizelist', nodeCount.toString());
        sessionStorage.setItem('capacitorlist', nodeCount.toString());
        sessionStorage.setItem('hvrelaylist', nodeCount.toString());
        this.tempcablesizelist = [];
        this.templist = [];
        this.tempswitchlist = [];
        this.tempcapacitorlist = [];
        this.maxDemandDataArray = [];
        this.temppowerfactorcorrections = [];
        this.tempinputXML.Switchboards = [];
        this.tempinputXML.Loads = [];
        this.tempinputXML.CapacitorBanks = [];
        this.tempinputXML.CableSizeCalcInputs = "";
        this.tempinputXML.NetworkHVRelay = null;
        this.tempinputXML.NetworkSettings = null;
        this.tempinputXML.SupplySettings = null;
        this.tempinputXML.PowerFactorCorrections = null;
        this.tempinputXML.matrix = null;
        this.tempinputXML.isSingleLineDiagram = 0;
        this.matrix = undefined;
        this.tempinputXML.MaxDemandDataArray = [];
        this.warningErrorStrData = '';
        this.warningErrorBtnText = "Warnings";
        this.warningErrorHeader = "Errors";
        this.warningErrorList = [];
        this.displayErrorBtn = false;
        this.tempinputXML.NetworkSettings = new NetworkSettings();
        this.tempinputXML.NetworkSettings.CategorySource = "0";
        this.tempinputXML.NetworkSettings.CalculationMethod = "0";
        this.tempinputXML.NetworkSettings.ppeCategoriesTable = [];
        this.tempinputXML.NetworkSettings.ppeCategoriesTableHeaders = [];
        this.tempinputXML.NetworkSettings.IsArcFlash = false;
        this._settingService.hideArcFlashReport = !this.tempinputXML.NetworkSettings.IsArcFlash;


        this.cableCalculationStandard = this.settingCalculationStandard;
        this.onEarthinSystemChange();
        this.ReportStandardCountry = this.cableCalculationStandard;
        if (this.ReportStandardCountry == "2") {
            this.isBritishStandard = true;
        }
        else
            this.isBritishStandard = false;
        // this.tempinputXML.NetworkSettings.CableCalcStandard = this.cableCalculationStandard;
        // $('#CableCalcStandard').val(this.cableCalculationStandard).trigger('change', "true");
        if (this.cableCalculationStandard == '0' || this.cableCalculationStandard == '1')
            this.protectionCoordinationStandard = '0';
        else
            this.protectionCoordinationStandard = '1';

        // $('#ProtectionCoordinationStandard').val(this.protectionCoordinationStandard).trigger('change', "true");
        this.transformerHVRelay = false;
        this.powerHVRelay = false;
        if (this.activeNode && this.activeNode.objectcode) {
            this.deleteWizardFactorValues(this.activeNode.objectcode, true);
            this.deleteProtectionData(this.activeNode.objectcode, true);

        }
        this.items = this.getDefaultTreeList();
        $('#selectNetwork').val('1');
        this.UserWizard = false;
        this.CableSize["isderatingwiz"] = "0";
        // $('#checkboxUseWizard').removeAttr('checked');
        $('#deratingFactor').val(1);
        this.CableSize['deratingfactor'] = 1;

        this.selectchild(1);
        this.tree.treeModel.getNodeById(2).setActiveAndVisible();

        this.resetControlValues();
        this.switchboardcount = 0;
        this.loadcount = 0;
        this.capacitorcount = 0;
        this.switchBoardCount(this.items[0]);
        this.capacitorCount(this.items[0]);
        this.loadCount(this.items[0]);
        this.activeNode = undefined;
        this.cd.detectChanges();
        this.activeNode = this.tree.treeModel.getNodeById(2).data;
        this.saveDefaultProtectiveDetail(this.activeNode.objectcode, () => {
            this.index = 0;
            this.getNetworkProtectiveDataByNodeId(this.newCalculationId, this.activeNode.objectcode);
        });// Add default data to ECPNetworkProtectiveCalcDetail table for this node

        //this.onInputChange();

        // this._router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this._router.onSameUrlNavigation = 'reload';
        // this._router.navigate(['/app/main/projects/', this.projectId, 'networkcalc', this.CalculationId], { queryParams: { name: this.projectName, cname: this.calculationName } });
        //this.changediv(this.items[0]);
    }

    showResetStandardPopup() {
        $('#reset-standard').modal('show');
    }

    cancelResetStandard() {
        this.cableCalculationStandard = this.oldCableCalculationStandard;
        this.onEarthinSystemChange();
        this.ReportStandardCountry = this.cableCalculationStandard;
        if (this.ReportStandardCountry == "2") {
            this.isBritishStandard = true;
        }
        else
            this.isBritishStandard = false;
        // this.tempinputXML.NetworkSettings.CableCalcStandard = this.cableCalculationStandard;
        // $('#CableCalcStandard').val(this.cableCalculationStandard).trigger('change', "true");
    }

    resetStandard() {
        let supplyType = this.networksupplytype;// $('#networksupplytype').val();
        this.PrevActiveValue = undefined;
        this.PrevNeutralValue = undefined;
        let nodeCount = 0;
        this.objectId = 3;
        this.SupplyVoltage = "0";
        this.Frequency = "0";
        this.VoltageTolerance = "0";
        this.TransformerTypicalDataSource = "0";
        this.networksupplytype = "0";
        this.Transformer_Calculation_Method = "0";
        this.resetStatus = true;
        this.Autosizegenerator = true;
        this.Customtransformer = false;
        this.Autosizetransformer = true;
        this.Impedencecorrection = false;
        this.Customgenerator = false;
        this.GeneratorImpedencecorrection = false;
        this.PowerStationCustomgenerator = false;
        this.PowerStationCustomtransformer = false;
        this.PowerStationImpedencecorrection = false;
        this.Power_Station_Calc_Transformer_XandR = "0";
        this.selectResults = "8";

        this.isMaxDemandDataShown = false;
        this.isXmlStatus = true;
        document.getElementById('defaultCableOpen2').click();
        this.LoadStorage.installationId = 5;
        sessionStorage.setItem('SupplyType', "Supply");
        sessionStorage.setItem('powerfactorlist', nodeCount.toString());
        sessionStorage.setItem('loadlist', nodeCount.toString());
        sessionStorage.setItem('switchlist', nodeCount.toString());
        sessionStorage.setItem('cablesizelist', nodeCount.toString());
        sessionStorage.setItem('capacitorlist', nodeCount.toString());
        sessionStorage.setItem('hvrelaylist', nodeCount.toString());
        this.tempcablesizelist = [];
        this.templist = [];
        this.tempswitchlist = [];
        this.tempcapacitorlist = [];
        this.maxDemandDataArray = [];
        this.temppowerfactorcorrections = [];
        this.tempinputXML.Switchboards = [];
        this.tempinputXML.Loads = [];
        this.tempinputXML.CapacitorBanks = [];
        this.tempinputXML.CableSizeCalcInputs = "";
        // this.tempinputXML.NetworkHVRelay = null;
        // this.tempinputXML.NetworkSettings = null;
        // this.tempinputXML.SupplySettings = null;
        // this.tempinputXML.PowerFactorCorrections = null;
        this.tempinputXML.matrix = null;
        this.tempinputXML.isSingleLineDiagram = 0;
        this.matrix = undefined;
        this.tempinputXML.MaxDemandDataArray = [];
        this.warningErrorStrData = '';
        this.warningErrorBtnText = "Warnings";
        this.warningErrorHeader = "Errors";
        this.warningErrorList = [];
        this.displayErrorBtn = false;


        // this.transformerHVRelay = false;
        // this.powerHVRelay = false;
        if (this.activeNode && this.activeNode.objectcode) {
            this.deleteWizardFactorValues(this.activeNode.objectcode, true);
            this.deleteProtectionData(this.activeNode.objectcode, true);

        }
        this.items = this.getDefaultTreeList();
        // this.cableCalculationStandard = '0';
        // $('#CableCalcStandard').val('0').trigger('change', "true");
        $('#selectNetwork').val('1');
        this.UserWizard = false;
        this.CableSize["isderatingwiz"] = "0";
        // $('#checkboxUseWizard').removeAttr('checked');
        $('#deratingFactor').val(1);
        this.CableSize['deratingfactor'] = 1;

        this.selectchild(1);
        this.tree.treeModel.getNodeById(2).setActiveAndVisible();
        this.networksupplytype = supplyType;
        let obj = this.NetworkSupplyTypeList.find(m => m.id == this.networksupplytype);
        this.onddlNetworkSupplyTypeChange(obj, "false");
        // $('#networksupplytype').val(supplyType).trigger('change', "false");
        // this.showLoader();
        let cableRecords = JSON.parse(this.tempinputXML.CableSizeCalcInputs);
        // this.cableCalculationStandard = e.target.value;
        if (cableRecords.length > 0) {
            cableRecords.forEach(element => {
                element.CableSizeCalcInputXML['calculationstandard'] = this.cableCalculationStandard;
            });
            this.tempcablesizelist = [];
            this.tempcablesizelist = cableRecords;
            this.tempinputXML.CableSizeCalcInputs = JSON.stringify(cableRecords);
        }
        if (this.cableCalculationStandard == '0' || this.cableCalculationStandard == '1')
            this.protectionCoordinationStandard = '0';
        else
            this.protectionCoordinationStandard = '1';

        // $('#ProtectionCoordinationStandard').val(this.protectionCoordinationStandard).trigger('change', "true");
        // this.UserWizard = false;
        // $('#checkboxUseWizard').removeAttr('checked');
        // $('#checkboxUseWizard').val('0').trigger('change', "true");
        //$('#HVRelayHead').css('display', 'none');
        // this.IsCableCalcStandardChanged = true;
        this.activeNode = undefined;
        this.cd.detectChanges();
        this.activeNode = this.tree.treeModel.getNodeById(2).data;
        this.saveDefaultProtectiveDetail(this.activeNode.objectcode, () => {
            this.index = 0;
            this.getNetworkProtectiveDataByNodeId(this.newCalculationId, this.activeNode.objectcode);
        });// Add default data to ECPNetworkProtectiveCalcDetail table for this node


        // this.onInputChange();
        //this.resetControlValues();        
    }

    resetControlValues() {
        // this.tempinputXML.SupplySettings.Networkfeeder_Phases = 0;
        //this.tempinputXML.SupplySettings.ExistingSwitchBoard_Phases = 0;
        this.SupplyVoltage = "0";
        this.Frequency = "0";
        this.VoltageTolerance = "0";
        // $('#SupplyVoltage').val('0').trigger('change', "false");
        // $('#Frequency').val('0').trigger('change', "false");
        // $('#VoltageTolerance').val('0').trigger('change', "false");
        $('#AllowableVoltageDrop_Network').val('5');
        $('#AllowableVoltageDrop_MainsCable').val('0.5');
        // $('#CableCalcStandard').val('0').trigger('change', "false");
        this.TransformerTypicalDataSource = "0";
        // $('#TransformerTypicalDataSource').val('0').trigger('change', "false");

        //Supply
        this.networksupplytype = "0";
        let obj = this.NetworkSupplyTypeList.find(m => m.id == this.networksupplytype);
        this.onddlNetworkSupplyTypeChange(obj, "false");
        // $('#networksupplytype').val('0').trigger('change', "false");

        $('#Networkfeeder_Fault_Level').val('15');


        $('#switchradio3phase').prop('checked', true).trigger('change', "false");;
        // $('#threephasegroup').css('display', 'block');
        // $('#singlephasegroup').css('display', 'none');

        $('#networksupplyradio1').prop('checked', true).trigger('change', "false");

        $('#checkSupplyHVRelay').prop('checked', false).trigger('change', "false");
        this.Autosizetransformer = true;
        // $('#Autosizetransformer').prop('checked', true);//.trigger('change', "false");
        this.Customtransformer = false;
        // $('#Customtransformer').prop('checked', false);//.trigger('change', "false");
        this.Impedencecorrection = false;
        // $('#Impedencecorrection').prop('checked', false).trigger('change', "false");
        this.Transformer_Calculation_Method = "0";
        // $('#Transformer_Calculation_Method').val('0').trigger('change', "false");
        $('#Transformer_Load_Losses').val('3000');
        $('#Transformer_Impedence_Voltage').val('4');
        $('#Transformer_XR_Ratio').val('10');
        $('#Transformer_No_Of_Parallel_Transformers').val('1');
        $('#Transformer_Custom_Rating').val('100');

        $('#Transformer_Primary_Voltage').val('11000');
        $('#Transformer_Primary_Fault_Level').val('15');
        $('#ExistingSwitchboard_Fault_Level').val('15');
        this.Customgenerator = false;
        // $('#Customgenerator').prop('checked', false).trigger('change', "false");
        this.GeneratorImpedencecorrection = false;
        // $('#GeneratorImpedencecorrection').prop('checked', false).trigger('change', "false");
        this.Autosizegenerator = true;
        // $('#Autosizegenerator').prop('checked', true).trigger('change', "false");

        $('#Power_Station_Generator_Rated_Voltage').val('400');
        $('#Generator_Rated_Voltage').val('400');


        $('#PowerStationHVRelay').prop('checked', false).trigger('change', "false");
        this.Autosizepower = true;
        // $('#Autosizepower').prop('checked', true).trigger('change', "false");

        $('#Power_Station_No_Of_Parallel_Units').val('1');
        $('#Power_Station_Generator_Custom_Rating').val('100');
        $('#Power_Station_Transformer_Custom_Rating').val('100');
        this.PowerStationCustomgenerator = false;
        // $('#PowerStationCustomgenerator').prop('checked', false).trigger('change', "false");
        $('#Power_Station_Subtransient_Reactance').val('12');
        $('#Power_Station_XDR_Ratio').val('15');
        this.PowerStationCustomtransformer = false;
        // $('#PowerStationCustomtransformer').prop('checked', false).trigger('change', "false");
        $('#Power_Station_Impedence_Voltage').val('4');
        $('#Power_Station_XR_Ratio').val('10');
        this.PowerStationImpedencecorrection = false;
        // $('#PowerStationImpedencecorrection').prop('checked', false).trigger('change', "false");
        $('#Power_Station_Power_Factor_Of_Generator').val('0.9');
        $('#Power_Station_Generator_Voltage_Regulation').val('5');
        $('#Power_Station_Transformer_Load_Losses').val('3000');
        this.Power_Station_Calc_Transformer_XandR = "0";
        // $('#Power_Station_Calc_Transformer_XandR').val('0').trigger('change', "false");

        $('#Generator_No_Of_Parallel_Generators').val('1');
        $('#Generator_Customer_Rating').val('100');
        $('#Generator_Subtransient_Reactance').val('12');
        $('#Generator_XDR_Ratio').val('15');
        $('#Generator_Power_Factor_Of_Generators').val('0.9');
        $('#Generator_Voltage_Regulation').val('5');


    }

    showHideProtectionWarning() {

        if (this.CableSize != undefined && (<any>this.CableSize).currentamp != undefined) {
            switch (this.device) {
                case '0':
                    this.displayCurrentError = false;
                    break;
                case '1':

                    var tempvalue = parseInt(this.CableSize['protection']['MCBrating']);

                    if (parseFloat((<any>this.CableSize).currentamp) > parseFloat($('option[device=MCB][value=' + tempvalue + ']').text())) {
                        this.displayCurrentError = true;
                    }
                    else {
                        this.displayCurrentError = false;
                    }
                    break;
                case '2':
                    if (parseFloat((<any>this.CableSize).currentamp) > parseFloat(this.CableSize['protection']['thermalsetting'])) {
                        this.displayCurrentError = true;
                    }
                    else {
                        this.displayCurrentError = false;
                    }
                    break;
                case '3':
                    if (parseFloat((<any>this.CableSize).currentamp) > parseFloat(this.CableSize['protection']['thermalsetting'])) {
                        this.displayCurrentError = true;
                    }
                    else {
                        this.displayCurrentError = false;
                    }
                    break;
                case '4':
                    var tempvalue = parseInt(this.CableSize['protection']['HRCFuserating']);
                    if (parseFloat((<any>this.CableSize).currentamp) > parseFloat($('option[device="HRC Fuse"][value=' + tempvalue + ']').text())) {
                        this.displayCurrentError = true;
                    }
                    else {
                        this.displayCurrentError = false;
                    }
                    break;
                case '5':
                    if (parseFloat((<any>this.CableSize).currentamp) > parseFloat(this.CableSize['protection']['thermalsetting'])) {
                        this.displayCurrentError = true;
                    } else {
                        this.displayCurrentError = false;
                    }
                    break;
            }
        }
    }


    /* In air - Bunched Circuits */
    GetTable22Dr(e: any) {
        setTimeout(() => {
            this.drfService.getTable22(this.table22model.Arrangement, this.table22model.AddType, this.table22model.NoOfCircuits, this.cableCalculationStandard).subscribe(result => {
                if (this.cableCalculationStandard == "2")
                    this.table22model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
                else
                    this.table22model.drf = parseFloat(result["Derating Factor:"]);
                this.drTable22output = result;

                if (this.table22model.Arrangement != 1 && this.cableCalculationStandard != "2") {
                    this.drTable22CircuitValues = [
                        { "name": 1, "value": 1 },
                        { "name": 2, "value": 2 },
                        { "name": 3, "value": 3 },
                        { "name": 4, "value": 4 },
                        { "name": 5, "value": 5 },
                        { "name": 6, "value": 6 },
                        { "name": 7, "value": 7 },
                        { "name": 8, "value": 8 },
                        { "name": 9, "value": 9 },
                        { "name": 10, "value": 10 },
                        { "name": 12, "value": 12 },
                        { "name": 14, "value": 14 },
                        { "name": 16, "value": 16 },
                        { "name": 18, "value": 18 },
                        { "name": "20 or more", "value": 20 }];
                }
            });
        }, 500);
    }
    /* In air - Cable Trays */
    GetTable23Dr(e: any) {

        this.drfService.getTable23(this.table23model.CableType, this.table23model.Installation, this.table23model.Row, this.table23model.Cables, this.cableCalculationStandard).subscribe(result => {
            if (this.cableCalculationStandard == "2")
                this.table23model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
            else
                this.table23model.drf = parseFloat(result["Derating Factor:"]);
            this.table23output = result;
            //this.updateWizardFactorValues(1, 'cable trays');
        });
    }
    SetMinMaxAmbientTemp(): void {
        if (this.drInstallType == 1) {
            if (this.table271model.AbientTemp > 55 && this.table271model.Insulation == 1) { this.table271model.AbientTemp = 55; }
            else if (this.table271model.AbientTemp > 60 && (this.table271model.Insulation == 2 || this.table271model.Insulation == 4)) { this.table271model.AbientTemp = 60; }
            else if (this.table271model.AbientTemp > 80 && this.table271model.Insulation == 3) { this.table271model.AbientTemp = 80; }
            else if (this.table271model.AbientTemp > 95 && this.table271model.Insulation == 5) { this.table271model.AbientTemp = 95; }
            else if (this.table271model.AbientTemp < 25) { this.table271model.AbientTemp = 25; }
        }
        if (this.drInstallType == 2) {
            if (this.table271model.AbientTemp > 60 && this.table271model.Insulation == 2) {
                this.table271model.AbientTemp = 60;
            }
            else if (this.table271model.AbientTemp > 80 && this.table271model.Insulation == 3) {
                this.table271model.AbientTemp = 80;
            }
            else if (this.table271model.AbientTemp < 10) {
                this.table271model.AbientTemp = 10;
            }
        }
    }
    /* Air/Soil ambient temprature TABLE 4B1 - Table271*/
    GetTable271DrBS(e: any, checkdecimalplaces: boolean = false) {
        if (checkdecimalplaces)
            this.allowDecimalSetModal(e, 'AmbientTemp');
        setTimeout(() => {

            if (e) {
                if ($(e.target).is(':checked') === true)
                    $('#inputUseStandard').attr('disabled', 'true');
                else
                    $('#inputUseStandard').removeAttr('disabled');
            }
            else {
                // $("#drTable271UseStandard").prop("checked",true);
                if ($("#drTable271UseStandard").is(':checked') === true)
                    $('#inputUseStandard').attr('disabled', 'true');
                else
                    $('#inputUseStandard').removeAttr('disabled');
            }

            let standard: number = 2;

            if (this.table271model.UseStandard == true) {
                if (this.drInstallType == 1) {
                    this.table271model.AbientTemp = 30;
                    this.table271model.Insulation = 2;
                } else {
                    this.table271model.AbientTemp = 20;
                    this.table271model.Insulation = 2;
                }

                // $('#drTable271Insulation').val(this.table271model.Insulation).trigger('change');
                //this.drTable271output = undefined;
                // if (!isCalledByInsulation)
                //     this.SetMinMaxAmbientTemp();
                this.drfServicebs.getTable271(standard, this.table271model.Insulation, this.table271model.AbientTemp, this.drInstallType).subscribe(result => {
                    this.table271model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
                    this.drTable271output = result;
                    this.drTable271output["Type of Insulation:"] = "";
                    //this.finalDrf = this.table271model.drf;
                });
                this.table271model.drf = 1;
                return false;
            } else {
                // if (!isCalledByInsulation)
                //     this.SetMinMaxAmbientTemp();
                this.drfServicebs.getTable271(standard, this.table271model.Insulation, this.table271model.AbientTemp, this.drInstallType).subscribe(result => {
                    this.table271model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
                    this.drTable271output = result;
                    //this.finalDrf = this.table271model.drf;
                });
            }
            this.CableSize['deratingtemp'] = this.table271model.AbientTemp.toString();
        }, 100);
    }
    /* In air/soil  - ambient temperature */
    GetTable271Dr(e: any, type: any = '', checkdecimalplaces: boolean = false) {

        // if (e && e.target.id == "drTable271UseStandardAir2") {
        //     if (!this.table271model.UseStandard) {
        //         setTimeout(() => {
        //             $("#drTable271ConductorTempAir2").select2({
        //                 width: '100%',
        //                 minimumResultsForSearch: Infinity // disable search
        //             });
        //         });
        //     }
        // }
        if (this.cableCalculationStandard == "2") {
            this.GetTable271DrBS(e, checkdecimalplaces);
            return;
        }
        if (checkdecimalplaces && type == 'AbientTemp')
            this.allowDecimalSetModal(e, type);
        if (checkdecimalplaces && type == 'airambtemp')
            this.allowDecimalSetModal(e, type);
        setTimeout(() => {
            if (e) {
                if ($(e.target).is(':checked') === true) {
                    if (this.drInstallType == 2)
                        $('#inputUseStandardSoil').attr('disabled', 'true');
                    else {
                        if (this.drBunchingType == 1)
                            $('#inputUseStandardAir1').attr('disabled', 'true');
                        else
                            $('#inputUseStandardAir2').attr('disabled', 'true');
                    }
                }
                else {
                    if (this.drInstallType == 2)
                        $('#inputUseStandardSoil').removeAttr('disabled');
                    else {
                        if (this.drBunchingType == 1)
                            $('#inputUseStandardAir1').removeAttr('disabled');
                        else
                            $('#inputUseStandardAir2').removeAttr('disabled');
                    }
                }
            }

            var standard = parseInt(this.CableSize['calculationstandard']);

            if (this.table271model.UseStandard == true) {
                if (this.drInstallType == 1) {
                    this.table271model.AbientTemp = standard == 0 ? 40 : 30;
                } else {
                    this.table271model.AbientTemp = standard == 0 ? 25 : 15;
                }
                this.table271model.ConductorTemp = 75;
                this.drfService.getTable271(standard, this.table271model.ConductorTemp, this.table271model.AbientTemp, this.drInstallType).subscribe(result => {
                    if (this.cableCalculationStandard == "2")
                        this.table271model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
                    else
                        this.table271model.drf = parseFloat(result["Derating Factor:"]);
                    this.drTable271output = result;
                    this.drTable271output["Conductor temperature (deg.C.):"] = null;
                    //this.finalDrf = this.table271model.drf;
                    //  this.drTable271output["Reference temperature (deg.C.):"]=null;
                });
                return false;
            }
            if (this.drInstallType == 1) {

                if (
                    (this.table271model.ConductorTemp > 100 && this.table271model.AbientTemp > this.table271model.ConductorTemp - 10)
                    ||
                    (this.table271model.ConductorTemp < 100 && this.table271model.AbientTemp > this.table271model.ConductorTemp - 5)
                ) {
                    if (this.table271model.ConductorTemp > 100) {
                        this.table271model.AbientTemp = this.table271model.ConductorTemp - 10;
                    }
                    else {
                        this.table271model.AbientTemp = this.table271model.ConductorTemp - 5;
                    }
                }
                if (this.table271model.AbientTemp < 15) {
                    this.table271model.AbientTemp = 15;
                }
            }
            else if (this.drInstallType == 2) {
                if (this.table271model.AbientTemp > 40 && standard == 0) {
                    this.table271model.AbientTemp = 40;
                }
                else if (this.table271model.AbientTemp < 10) {
                    this.table271model.AbientTemp = 10;
                }
            }
            if (!this.table271model.UseStandard) {
                this.drfService.getTable271(standard, this.table271model.ConductorTemp, this.table271model.AbientTemp, this.drInstallType).subscribe(result => {
                    if (this.cableCalculationStandard == "2")
                        this.table271model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
                    else
                        this.table271model.drf = parseFloat(result["Derating Factor:"]);
                    this.drTable271output = result;
                    //this.finalDrf = this.table271model.drf;
                });
            }
            this.CableSize['deratingtemp'] = this.table271model.AbientTemp.toString();
        }, 100);
    }
    /* Depth of Burial (m) */
    GetTable25Dr(e: any) {
        this.drTable25output = undefined;
        setTimeout(() => {
            if (this.table25model.NoOfCircuits == 1) {
                this.table25model.drf = 1;

                $('#divburiedGroupArrangement').css('display', 'none');
                return false;
            }
            $('#divburiedGroupArrangement').css('display', 'block');
            if (this.cableCalculationStandard != '2' && this.table25model.CableType == 2 && this.table25model.InstallType == 2) {
                this.table25model.InstallType = 1;
            }
            if (this.table25model.InstallType == 2) {
                this.table25ArrangementValues = [
                    { "name": "Touching", "value": 1 },
                    { "name": "Spaced 0.45 m", "value": 5 },
                    { "name": "Spaced 0.60 m", "value": 6 },
                ];

                this.table25ArrangementValuesBS = [
                    { "name": "Nil (ducts touching)", "value": 1 },
                    { "name": "Spaced 0.25 m", "value": 4 },
                    { "name": "Spaced 0.5 m", "value": 5 },
                    { "name": "Spaced 1.0 m", "value": 6 },
                ];
            }
            else if (this.table25model.InstallType == 3) {
                this.table25ArrangementValues = [
                    { "name": "Touching", "value": 1 },
                    { "name": "Spaced 0.30 m", "value": 4 },
                    { "name": "Spaced 0.45 m", "value": 5 },
                    { "name": "Spaced 0.60 m", "value": 6 },
                ];
                this.table25ArrangementValuesBS = [
                    { "name": "Nil (cables touching)", "value": 1 },
                    { "name": "One cable diameter", "value": 2 },
                    { "name": "Spaced 0.125 m", "value": 3 },
                    { "name": "Spaced 0.25 m", "value": 4 },
                    { "name": "Spaced 0.5 m", "value": 5 },
                ];
            }
            else {
                if (this.table25model.CableType == 1) {
                    this.table25ArrangementValues = [
                        { "name": "Touching trefoil", "value": 1 },
                        { "name": "Touching flat", "value": 2 },
                        { "name": "Spaced 0.15 m", "value": 3 },
                        { "name": "Spaced 0.30 m", "value": 4 },
                        { "name": "Spaced 0.45 m", "value": 5 },
                        { "name": "Spaced 0.60 m", "value": 6 },
                    ];
                }
                else if (this.table25model.CableType == 2) {
                    this.table25ArrangementValues = [
                        { "name": "Touching", "value": 1 },
                        { "name": "Spaced 0.15 m", "value": 3 },
                        { "name": "Spaced 0.30 m", "value": 4 },
                        { "name": "Spaced 0.45 m", "value": 5 },
                        { "name": "Spaced 0.60 m", "value": 6 },
                    ];
                }
                this.table25ArrangementValuesBS = [
                    { "name": "Nil (cables touching)", "value": 1 },
                    { "name": "One cable diameter", "value": 2 },
                    { "name": "Spaced 0.125 m", "value": 3 },
                    { "name": "Spaced 0.25 m", "value": 4 },
                    { "name": "Spaced 0.5 m", "value": 5 },
                ];
            }
            setTimeout(() => {
                let tempSelected = this.table25ArrangementValuesBS.find(m => m.value == this.table25model.Arrangement);
                if (!tempSelected)
                    this.table25model.Arrangement = this.table25ArrangementValuesBS[0].value;
                else
                    this.table25model.Arrangement = this.table25model.Arrangement;
                // $('#drTable25Arrangement').val(this.table25model.Arrangement).select2({
                //     width: '100%',
                //     minimumResultsForSearch: Infinity // disable search
                // }).trigger('change');
                // $('#drTable25Arrangement').trigger('select2:select');
                this.setTable25Values(this.table25model.Arrangement);
                // setTimeout(() => { $('#drTable25Arrangement').trigger('select2:select'); }, 500);
            });
        });
    }
    /* Depth of Burial (m) */
    setTable25Dr() {
        if (this.table25model.NoOfCircuits == 1)
            $('#divburiedGroupArrangement').css('display', 'none');
        else
            $('#divburiedGroupArrangement').css('display', 'block');
        this.drfService.getTable25(this.table25model.CableType, this.table25model.NoOfCircuits, this.table25model.TouchingType, this.table25model.Distance, this.table25model.InstallType, this.cableCalculationStandard).subscribe(result => {
            if (this.cableCalculationStandard == "2")
                this.table25model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
            else
                this.table25model.drf = parseFloat(result["Derating Factor:"]);
            this.drTable25output = result;
            if (this.table25model.NoOfCircuits == 1) {
                this.drTable25output["Spacing (m):"] = null

            }
            //this.updateWizardFactorValues(1, 'buried circuits');
        });
    }

    /* Depth of Burial (m) - arrangement values  */
    setTable25Values(arrangementValue: any) {
        if (this.cableCalculationStandard == "2") {
            this.setTable25ValuesBS(arrangementValue);
            return;
        }
        switch (parseInt(arrangementValue)) {
            case 1:
                this.table25model.Distance = 0;
                switch (this.table25model.InstallType) {
                    case 1:
                        this.table25model.TouchingType = 1;
                        break;
                    case 2:
                        this.table25model.TouchingType = 4;
                        break;
                    case 3:
                        this.table25model.TouchingType = 4;
                        break;
                }
                break;
            case 2:
                this.table25model.Distance = 0;
                this.table25model.TouchingType = 2;
                break;
            case 3:
                this.table25model.Distance = 0.15;
                switch (this.table25model.InstallType) {
                    case 1:
                        this.table25model.TouchingType = 3;
                        break;
                    case 2:
                        this.table25model.TouchingType = 5;
                        break;
                    case 3:
                        this.table25model.TouchingType = 5;
                        break;
                }

                break;
            case 4:
                this.table25model.Distance = 0.30;
                switch (this.table25model.InstallType) {
                    case 1:
                        this.table25model.TouchingType = 3;
                        break;
                    case 2:
                        this.table25model.TouchingType = 5;
                        break;
                    case 3:
                        this.table25model.TouchingType = 5;
                        break;
                }
                break;
            case 5:
                this.table25model.Distance = 0.45;
                switch (this.table25model.InstallType) {
                    case 1:
                        this.table25model.TouchingType = 3;
                        break;
                    case 2:
                        this.table25model.TouchingType = 5;
                        break;
                    case 3:
                        this.table25model.TouchingType = 5;
                        break;
                }
                break;
            case 6:
                this.table25model.Distance = 0.60;
                switch (this.table25model.InstallType) {
                    case 1:
                        this.table25model.TouchingType = 3;
                        break;
                    case 2:
                        this.table25model.TouchingType = 5;
                        break;
                    case 3:
                        this.table25model.TouchingType = 5;
                        break;
                }
                break;
        }
        this.setTable25Dr();
    }

    setTable25ValuesBS(arrangementValue: any) {
        switch (parseInt(arrangementValue)) {
            case 1:
                this.table25model.Distance = 0;
                this.table25model.TouchingType = 1;
                break;
            case 2:
                this.table25model.Distance = 0;
                this.table25model.TouchingType = 2;
                break;
            case 3:
                this.table25model.Distance = 0.125;
                this.table25model.TouchingType = 3;
                break;
            case 4:
                this.table25model.Distance = 0.25;
                this.table25model.TouchingType = 4;
                break;
            case 5:
                this.table25model.Distance = 0.5;
                this.table25model.TouchingType = 5;
                break;
            case 6:
                this.table25model.Distance = 1.0;
                this.table25model.TouchingType = 6;
                break;
        }
        this.setTable25Dr();
    }

    GetTable28Dr(e, type: any = '', checkdecimalplaces: boolean = false) {
        if (this.cableCalculationStandard == "2") {
            this.GetTable28DrBS(e, type, checkdecimalplaces);
            return;
        }

        if (checkdecimalplaces && type == 'Depth')
            this.allowDecimalSetModal(e, 'Depth');

        setTimeout(() => {
            if (this.table28model.UseStandard) {
                // this.table28model.Buried = 1;


                let imageName: string = this.CableSize['description'].toLowerCase().trim();

                if (imageName === 'buried direct') {
                    this.table28model.Buried = 1;
                }
                else {
                    this.table28model.Buried = 2;

                }

                this.table28model.Depth = 0.5;
                this.table28model.ConductorSize = 3;
                this.table28model.Core = 1;
                this.table28model.drf = 1;
                //    this.table28output = undefined;

                this.setTable28Dr();
                return false;
            }

            // if ($('#drTable28CondutorSize')) {
            //     if (!$('#drTable28CondutorSize').data('select2')) {
            //         $('#drTable28CondutorSize').select2({
            //             width: '100%',
            //             minimumResultsForSearch: Infinity // disable search
            //         });
            //         $('#drTable28CondutorSize').on('select2:select', (e) => {
            //             this.table28model.ConductorSize = (<any>e.target).value;
            //             this.setTable28Dr();
            //         });
            //     }
            // }
            if (this.table28model.Depth < 0.5) {
                this.table28model.Depth = 0.5;
            }

            if (this.table28model.Depth > 3) {
                this.table28model.Depth = 3;
            }
            this.setTable28Dr();
        }, 800);
    }

    /*Depth of Burial*/
    GetTable28DrBS(e, type: any = '', checkdecimalplaces: boolean = false) {
        if (checkdecimalplaces && type == 'Depth')
            this.allowDecimalSetModal(e, 'Depth');
        setTimeout(() => {

            if (this.table28model.UseStandard)
                $('#txtdepthofburial').attr('disabled', 'true');
            else
                $('#txtdepthofburial').removeAttr('disabled');

            if (this.table28model.UseStandard) {
                //6  this.table28model.Buried = 1;

                this.table28model.Depth = 0.7;
                this.table28model.drf = 1;
                //this.table28output = undefined;
                //return false;
                this.setTable28Dr();

            }
            if (this.table28model.Depth < 0.5) {
                this.table28model.Depth = 0.5;
            }
            if (this.table28model.Depth > 3) {
                this.table28model.Depth = 3;
            }
            this.setTable28Dr();
        }, 800);
    }

    /* Depth of Burial (m) - set values   Conductor size:*/
    setTable28Dr() {
        this.drfService.getTable28(this.table28model.Depth, this.table28model.ConductorSize, this.table28model.Core, 0, this.table28model.Buried, this.cableCalculationStandard).subscribe(result => {
            if (this.cableCalculationStandard == "2")
                this.table28model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
            else
                this.table28model.drf = parseFloat(result["Derating Factor:"]);
            this.table28output = result;
            //this.updateWizardFactorValues(2, 'soil');
            if (this.table28model.UseStandard) {
                this.table28output["Conductor size:"] = null;

            }
        });
    }
    showGroupAsCircuit(installationId: number, groupId: number, subGroupId: number, phaseId: number, phaseAllocationId: number): void {
        if (installationId == 5 && (groupId == 45 || groupId == 46) && (subGroupId == 63 || subGroupId == 65) && phaseId == 1 && phaseAllocationId == 1) {
            this.isHideGroupasCircuit = false;
        }
        else {
            this.isHideGroupasCircuit = true;
            $('#chkgroup').removeAttr("checked");

        }

    }
    OnInstallationTypeChangeBS(e: any) {
        setTimeout(() => {


            this.table271model.UseStandard = true;
            if (this.drInstallType == 1) {
                this.table271model.AbientTemp = 30;
                this.table271model.Insulation = 2;
                this.insulationValues = [
                    // { "name": "60 &#176;C thermosetting", "value": 1 },
                    { "name": "70 &#176;C thermoplastic", "value": 2 },
                    { "name": "90 &#176;C thermosetting", "value": 3 },
                    { "name": "Mineral - Thermoplastic covered or bare and exposed to touch 70 &#176;C", "value": 4 },
                    { "name": "Mineral - Bare and not exposed to touch 105 &#176;C", "value": 5 },
                ];
            } else {
                this.table271model.AbientTemp = 20;
                this.table271model.Insulation = 2;
                this.insulationValues = [
                    { "name": "70 &#176;C thermoplastic", "value": 2 },
                    { "name": "90 &#176;C thermosetting", "value": 3 },
                ];
            }
            // $('#drTable271Insulation').val(this.table271model.Insulation).trigger('change');
            this.GetTable271DrBS(null);
        });
    }
    OnInstallationTypeChange(e: any) {
        setTimeout(() => {
            this.table271model.UseStandard == true;
            if (this.drInstallType == 1) {
                this.table271model.AbientTemp = 40;
            } else {
                this.table271model.AbientTemp = 25;
            }
            this.table271model.ConductorTemp = 75;
            //alert(this.drInstallType);
            if (this.drInstallType == 2) {
                this.conductorTempValues = [
                    // { "name": "150 &#176;C", "value": 150 },
                    { "name": "110 &#176;C", "value": 110 },
                    { "name": "90 &#176;C", "value": 90 },
                    { "name": "80 &#176;C", "value": 80 },
                    { "name": "75 &#176;C", "value": 75 },
                ];
            }
            else {
                this.conductorTempValues = [
                    { "name": "150 &#176;C", "value": 150 },
                    { "name": "110 &#176;C", "value": 110 },
                    { "name": "90 &#176;C", "value": 90 },
                    { "name": "80 &#176;C", "value": 80 },
                    { "name": "75 &#176;C", "value": 75 },
                ];
            }

            $('#drTable271CondutortTemp').val(this.table271model.ConductorTemp).trigger('change');
            this.GetTable271Dr(null);
        });
    }
    /* Soil thermal resitivity  (e: any , type : any ='', checkdecimalplaces : boolean =false)*/
    GetTable29Dr(e: any, type: any = '', checkdecimalplaces: boolean = false) {
        if (this.cableCalculationStandard == "2") {
            this.GetTable29DrBS(e, checkdecimalplaces);
            return;
        }
        if (checkdecimalplaces && type == 'Resitivity')
            this.allowDecimalSetModalThree(e, 'Resitivity')
        setTimeout(() => {
            if (this.table29model.UseStandard) {
                this.table29model.Resitivity = 1.2;
                this.table29model.Installation = 2;
                this.table29model.drf = 1;
                // this.table29output = undefined;

                this.drfService.getTable29(this.table29model.Resitivity, this.cableCalculationStandard == "2" ? this.table29model.Buried : this.table29model.Installation, this.cableCalculationStandard).subscribe(result => {
                    if (this.cableCalculationStandard == "2")
                        this.table29model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
                    else
                        this.table29model.drf = parseFloat(result["Derating Factor:"]);
                    this.table29output = result;
                    this.table29output["Type of installation:"] = null;

                    //this.updateWizardFactorValues(3, 'soil');
                });
                return false;
            }

            if (this.table29model.Resitivity > 3) {
                this.table29model.Resitivity = 3;
            }
            if (this.table29model.Resitivity < 0.8) {
                this.table29model.Resitivity = 0.8;
            }
            this.drfService.getTable29(this.table29model.Resitivity, this.cableCalculationStandard == "2" ? this.table29model.Buried : this.table29model.Installation, this.cableCalculationStandard).subscribe(result => {
                if (this.cableCalculationStandard == "2")
                    this.table29model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
                else
                    this.table29model.drf = parseFloat(result["Derating Factor:"]);
                this.table29output = result;
                //this.updateWizardFactorValues(3, 'soil');
            });
        }, 800);
    }

    /* Soil Thermal Resistivity - Table 4B3*/
    GetTable29DrBS(e: any, checkdecimalplaces: boolean = false) {

        if (checkdecimalplaces)
            this.allowDecimalSetModalThree(e, 'Resitivity');
        setTimeout(() => {

            if (this.table29model.UseStandard)
                $('#txtresitivity').attr('disabled', 'true');
            else
                $('#txtresitivity').removeAttr('disabled');

            if (this.table29model.UseStandard) {
                this.table29model.Resitivity = 2.5;
                // this.table29model.Buried = 1;
                this.table29model.drf = 1;
                //    this.table29output = undefined;
                //  return false;
                this.drfService.getTable29(this.table29model.Resitivity, this.table29model.Buried, this.cableCalculationStandard).subscribe(result => {
                    this.table29model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
                    this.table29output = result;
                    this.table29output["Type of installation:"] = "";
                });
            }

            if (this.table29model.Resitivity > 3) {
                this.table29model.Resitivity = 3;
            }
            if (this.table29model.Resitivity < 0.5) {
                this.table29model.Resitivity = 0.5;
            }
            this.drfService.getTable29(this.table29model.Resitivity, this.table29model.Buried, this.cableCalculationStandard).subscribe(result => {
                this.table29model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
                this.table29output = result;
            });
        }, 800);
    }

    public Table23InstallationValues: Table23InstallationModel[];
    public Table24InstallationValues: Table23InstallationModel[];
    GetTable23Values() {

        this.drfGetService.getTable23Installations(this.cableCalculationStandard).subscribe(result => {
            this.Table23InstallationValues = result;
        });
    }
    GetTable24Values() {
        this.drfGetService.getTable24Installations(this.cableCalculationStandard).subscribe(result => {
            this.Table24InstallationValues = result;
        });
    }
    onSelectInstall(value: number) {
        if (this.cableCalculationStandard == "2") {
            this.onSelectInstallBS(value);
            return;
        }
        this.table23model.Installation = value;

        //  alert(this.drBunchingCore);
        if (this.drBunchingCore == 1) {
            if (value == 4 || value == 8) {
                if (this.table23model.Row > 2) {
                    this.table23model.Row = 1;
                }
                this.table23RowValues = [
                    { "name": "1 row", "value": 1 },
                    { "name": "2 rows", "value": 2 },
                ];
                this.table23RowValuesBs = [
                    { "name": "1 tray", "value": 1 },
                    { "name": "2 trays", "value": 2 },
                ];
                if (value == 4) {
                    if (this.table23model.Cables > 2) {
                        this.table23model.Cables = 1;
                    }
                    this.table23CableValues = [
                        { "name": "1 cct", "value": 1 },
                        { "name": "2 ccts", "value": 2 },
                    ];
                }
                else {
                    this.table23CableValues = [
                        { "name": "1 cct", "value": 1 },
                        { "name": "2 ccts", "value": 2 },
                        { "name": "3 ccts", "value": 3 },
                    ];
                }
            }
            else {
                this.table23RowValues = [
                    { "name": "1 row", "value": 1 },
                    { "name": "2 rows", "value": 2 },
                    { "name": "3 rows", "value": 3 },
                ];
                this.table23RowValuesBs = [
                    { "name": "1 tray", "value": 1 },
                    { "name": "2 trays", "value": 2 },
                    { "name": "3 trays", "value": 3 },
                ];
                this.table23CableValues = [
                    { "name": "1 cct", "value": 1 },
                    { "name": "2 ccts", "value": 2 },
                    { "name": "3 ccts", "value": 3 },
                ];
            }
        }
        else {
            if (value == 7 || value == 8) {
                this.table23model.Row = 1;
                this.table23RowValues = [
                    { "name": "1 row", "value": 1 },
                    { "name": "2 rows", "value": 2 },
                ];
                this.table23RowValuesBs = [
                    { "name": "1 tray", "value": 1 },
                    { "name": "2 trays", "value": 2 },
                ];
            }
            else {
                this.table23RowValues = [
                    { "name": "1 row", "value": 1 },
                    { "name": "2 rows", "value": 2 },
                    { "name": "3 rows", "value": 3 },
                ];
                this.table23RowValuesBs = [
                    { "name": "1 tray", "value": 1 },
                    { "name": "2 trays", "value": 2 },
                    { "name": "3 trays", "value": 3 },
                ];
            }
            if (value == 2 || value == 4 || value == 6 || value == 8) {
                this.table23model.Cables = 1;
                this.table23CableValues = [
                    { "name": "1", "value": 1 },
                    { "name": "2", "value": 2 },
                    { "name": "3", "value": 3 },
                    { "name": "4", "value": 4 },
                    //{ "name": "5", "value": 5 },
                    { "name": "6", "value": 6 },
                ];
            }
            else {
                this.table23CableValues = [
                    { "name": "1", "value": 1 },
                    { "name": "2", "value": 2 },
                    { "name": "3", "value": 3 },
                    { "name": "4", "value": 4 },
                    //{ "name": "5", "value": 5 },
                    { "name": "6", "value": 6 },
                    { "name": "9", "value": 9 },
                ];
            }
        }
        this.GetTable23Dr(null);
    }

    onSelectInstallBS(value: number) {
        this.table23model.Installation = value;
        //
        // alert(this.CableSize['core']);

        //alert(this.CableSize['core']);
        /* Single Core - 1*/
        if (this.CableSize['core'] == "0") {
            if (value == 2 || value == 5) {
                if (this.table23model.Row > 2) {
                    this.table23model.Row = 1;
                }
                this.table23RowValuesBs = [
                    { "name": "1 tray", "value": 1 },
                    { "name": "2 trays", "value": 2 },
                ];

                if (value == 2) {
                    if (this.table23model.Cables > 2) {
                        this.table23model.Cables = 1;
                    }
                    this.table23CableValues = [
                        { "name": "1 cct", "value": 1 },
                        { "name": "2 ccts", "value": 2 },
                    ];
                } else {
                    this.table23CableValues = [
                        { "name": "1 cct", "value": 1 },
                        { "name": "2 ccts", "value": 2 },
                        { "name": "3 ccts", "value": 3 },
                    ];
                }
            }
            else {
                this.table23RowValuesBs = [
                    { "name": "1 tray", "value": 1 },
                    { "name": "2 trays", "value": 2 },
                    { "name": "3 trays", "value": 3 },
                ];
                this.table23CableValues = [
                    { "name": "1 cct", "value": 1 },
                    { "name": "2 ccts", "value": 2 },
                    { "name": "3 ccts", "value": 3 },
                ];
            }
        }
        else {/* Multi Core - 1*/
            if (value == 1 || value == 5 || value == 6) {
                if (this.table23model.Row > 6) {
                    this.table23model.Row = 1;
                }
                this.table23RowValuesBs = [
                    { "name": "1 tray", "value": 1 },
                    { "name": "2 trays", "value": 2 },
                    { "name": "3 trays", "value": 3 },
                    { "name": "6 trays", "value": 6 },
                ];
                if (this.table23model.Cables > 9) {
                    this.table23model.Cables = 1;
                }
                this.table23CableValues = [
                    { "name": "1", "value": 1 },
                    { "name": "2", "value": 2 },
                    { "name": "3", "value": 3 },
                    { "name": "4", "value": 4 },
                    { "name": "6", "value": 6 },
                    { "name": "9", "value": 9 },
                ];
            }
            else if (value == 2 || value == 7) {
                if (this.table23model.Row > 3) {
                    this.table23model.Row = 1;
                }
                this.table23RowValuesBs = [
                    { "name": "1 tray", "value": 1 },
                    { "name": "2 trays", "value": 2 },
                    { "name": "3 trays", "value": 3 },
                ];
                if (this.table23model.Cables > 6) {
                    this.table23model.Cables = 1;
                }
                this.table23CableValues = [
                    { "name": "1", "value": 1 },
                    { "name": "2", "value": 2 },
                    { "name": "3", "value": 3 },
                    { "name": "4", "value": 4 },
                    { "name": "6", "value": 6 },
                ];
            }
            else if (value == 3 || value == 4) {
                if (this.table23model.Row > 2) {
                    this.table23model.Row = 1;
                }
                this.table23RowValuesBs = [
                    { "name": "1 tray", "value": 1 },
                    { "name": "2 trays", "value": 2 },
                ];
                if (value == 3) {
                    if (this.table23model.Cables > 9) {
                        this.table23model.Cables = 1;
                    }
                    this.table23CableValues = [
                        { "name": "1", "value": 1 },
                        { "name": "2", "value": 2 },
                        { "name": "3", "value": 3 },
                        { "name": "4", "value": 4 },
                        { "name": "6", "value": 6 },
                        { "name": "9", "value": 9 },
                    ];
                } else {
                    if (this.table23model.Cables > 6) {
                        this.table23model.Cables = 1;
                    }
                    this.table23CableValues = [
                        { "name": "1", "value": 1 },
                        { "name": "2", "value": 2 },
                        { "name": "3", "value": 3 },
                        { "name": "4", "value": 4 },
                        { "name": "6", "value": 6 },
                    ];
                }
            }
        }
        this.GetTable23Dr(null);
    }

    updateWizardFactorValues(isReloadList: boolean = false) {
        this.wizardFactorInputList = [];
        var stepId = 1;
        var controlId = 1;
        var standardId = this.CableSize['calculationstandard'];
        this.CalculationReportInput.wizardValues = new DRFWizrd();
        if (this.finalDrf)
            this.CalculationReportInput.wizardValues.finalDrf = parseFloat(this.finalDrf.toFixed(2));
        this.CalculationReportInput.wizardValues.result = [];
        if (this.drInstallType == 1) {
            if (this.drBunchingType == 2) {
                /* control 1 */
                let arrangementName = standardId == "2" ? this.drTable22ArrangementValuesBs.find(x => x.value == this.table22model.Arrangement).name : this.drTable22ArrangementValues.find(x => x.value == this.table22model.Arrangement).name;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Bunched Circuits";
                this.wizardFactorInput.stepTitle = "Bunched in air on a surface or enclosure";
                this.wizardFactorInput.controlId = 1;
                this.wizardFactorInput.controlLabel = "Arrangement of Cables";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table22model.Arrangement.toString();
                this.wizardFactorInput.controlText = arrangementName;
                this.wizardFactorInput.defaultValue = "";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                /* control 2 */
                let circuitName = this.drTable22CircuitValues.find(x => x.value == this.table22model.NoOfCircuits).name;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 1;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Bunched Circuits";
                this.wizardFactorInput.stepTitle = "Bunched in air on a surface or enclosure";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = "No of circuits";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table22model.NoOfCircuits.toString();
                this.wizardFactorInput.controlText = circuitName;
                this.wizardFactorInput.defaultValue = "";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
                if (this.table22model.Arrangement < 3)
                    this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                if (this.table22model.Arrangement == 3 || this.table22model.Arrangement == 4) {
                    let addTypeName = this.table22model.AddType == 1 ? "Touching" : "Spaced";
                    this.wizardFactorInput = new NetworkWizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = 1;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Bunched Circuits";
                    this.wizardFactorInput.stepTitle = "Bunched in air on a surface or enclosure";
                    this.wizardFactorInput.controlId = 3;
                    this.wizardFactorInput.controlLabel = "Bunched arrangement";
                    this.wizardFactorInput.controlType = "radiobutton";
                    this.wizardFactorInput.controlValue = this.table22model.AddType.toString();
                    this.wizardFactorInput.controlText = addTypeName;
                    this.wizardFactorInput.defaultValue = "";
                    this.wizardFactorInput.useDefaultValue = false;
                    this.wizardFactorInput.isLastControl = true;
                    this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                }
                stepId += 1;
            }
            else if (this.drBunchingType == 3) {
                /* control 1 */
                let rowName = standardId == "2" ? this.table23RowValuesBs.find(x => x.value == this.table23model.Row).name : this.table23RowValues.find(x => x.value == this.table23model.Row).name;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 1;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "In trays";
                this.wizardFactorInput.stepTitle = "Cable tray or support";
                this.wizardFactorInput.controlId = 1;
                this.wizardFactorInput.controlLabel = "No. of tiers of rows of support";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table23model.Row.toString();
                this.wizardFactorInput.controlText = rowName;
                this.wizardFactorInput.defaultValue = "";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                /* control 2 */
                let cableName = this.table23CableValues.find(x => x.value == this.table23model.Cables).name;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 1;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "In trays";
                this.wizardFactorInput.stepTitle = "Cable tray or support";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = this.drBunchingCore == 1 ? "No. of circuits per tier or row" : "No. of cables";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table23model.Cables.toString();
                this.wizardFactorInput.controlText = cableName;
                this.wizardFactorInput.defaultValue = "";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                /* control 3 */
                let imgNameandDescription: any;
                if (this.drBunchingCore == 1)
                    imgNameandDescription = this.Table23InstallationValues.find(x => x.value == this.table23model.Installation);
                else
                    imgNameandDescription = this.Table24InstallationValues.find(x => x.value == this.table23model.Installation);

                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = 1;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "In trays";
                this.wizardFactorInput.stepTitle = "Cable tray or support";
                this.wizardFactorInput.controlId = 3;
                this.wizardFactorInput.controlLabel = this.drBunchingCore == 1 ? "Single core - In trays, racks, cleats or other supports in air" : "Multi-core - In trays, racks, cleats or other supports in air";
                this.wizardFactorInput.controlType = "img";
                this.wizardFactorInput.controlValue = this.table23model.Installation.toString();
                this.wizardFactorInput.controlText = imgNameandDescription.name + ' ' + imgNameandDescription.desc;
                this.wizardFactorInput.defaultValue = "";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.imageName = imgNameandDescription.name;
                this.wizardFactorInput.imageDesc = imgNameandDescription.desc;
                this.wizardFactorInput.imageTitle = imgNameandDescription.desc2;
                this.wizardFactorInput.imageSrc = imgNameandDescription.img;
                this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                stepId += 1;
            }

            /* control 1 */
            this.wizardFactorInput = new NetworkWizardFactorInput();
            this.wizardFactorInput.calculationId = this.newCalculationId;
            this.wizardFactorInput.nodeId = this.activeNode.objectcode;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = 1;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = this.drBunchingType;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Air temprature";
            this.wizardFactorInput.stepTitle = "Air ambient temprature (C)";
            this.wizardFactorInput.controlId = 1;
            this.wizardFactorInput.controlLabel = "Standard Temprature";
            this.wizardFactorInput.controlType = "textbox";
            if (this.table271model.UseStandard) {
                this.wizardFactorInput.controlValue = this.wizardFactorInput.calcStandardId == 0 ? "40" : "30";
                this.wizardFactorInput.controlText = this.wizardFactorInput.calcStandardId == 0 ? "Use standard 40 &#176;C" : "Use standard 30 &#176;C";
                this.wizardFactorInput.defaultValue = this.wizardFactorInput.calcStandardId == 0 ? "40" : "30";
                this.wizardFactorInput.useDefaultValue = true;
                this.wizardFactorInput.isLastControl = true;
            }
            else {
                this.wizardFactorInput.controlValue = this.table271model.AbientTemp.toString();
                this.wizardFactorInput.controlText = this.wizardFactorInput.calcStandardId == 0 ? "Use standard 40 &#176;C" : "Use standard 30 &#176;C";
                this.wizardFactorInput.defaultValue = this.wizardFactorInput.calcStandardId == 0 ? "40" : "30";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
            }
            this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

            /* control 2 */
            if (!this.table271model.UseStandard) {
                if (this.cableCalculationStandard == "2") {
                    let insulationName = this.insulationValues.find(x => x.value == this.table271model.Insulation).name;
                    this.wizardFactorInput = new NetworkWizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = 2;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Air temprature";
                    this.wizardFactorInput.stepTitle = "Air ambient temprature (C)";
                    this.wizardFactorInput.controlId = 2;
                    this.wizardFactorInput.controlLabel = "Insulation";
                    this.wizardFactorInput.controlType = "dropdownlist";
                    this.wizardFactorInput.controlValue = this.table271model.Insulation.toString();
                    this.wizardFactorInput.controlText = insulationName;
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = true;
                    this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                }
                else {
                    let conductorTempName = this.conductorTempValues.find(x => x.value == this.table271model.ConductorTemp).name;
                    this.wizardFactorInput = new NetworkWizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = 1;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Air temprature";
                    this.wizardFactorInput.stepTitle = "Air ambient temprature (C)";
                    this.wizardFactorInput.controlId = 2;
                    this.wizardFactorInput.controlLabel = "Conductor Temprature";
                    this.wizardFactorInput.controlType = "dropdownlist";
                    this.wizardFactorInput.controlValue = this.table271model.ConductorTemp.toString();
                    this.wizardFactorInput.controlText = conductorTempName;
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = true;
                    this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                }
            }
            stepId += 1;
            //// Storing results
            var controlId = 1;
            if (this.drBunchingType != 1) {
                if (this.drTable22output && this.drBunchingType == 2) {
                    this.wizardFactorInput = new NetworkWizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = this.cableCalculationStandard == "2" ? "Correction Factor:" : "Derating Factor:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.cableCalculationStandard == "2" ? this.drTable22output["Correction Factor:"] : this.drTable22output["Derating Factor:"];
                    this.wizardFactorInput.controlText = this.cableCalculationStandard == "2" ? this.drTable22output["Correction Factor:"] : this.drTable22output["Derating Factor:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    this.wizardFactorInput = new NetworkWizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "Arrangement of cables:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.drTable22output["Arrangement of cables:"];
                    this.wizardFactorInput.controlText = this.drTable22output["Arrangement of cables:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    this.wizardFactorInput = new NetworkWizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "Bunched arrangement:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.drTable22output["Bunched arrangement:"];
                    this.wizardFactorInput.controlText = this.drTable22output["Bunched arrangement:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table22model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    var item = new TableItem();
                    item.name = "Table22";
                    item.summary = $('#table22').text();;
                    this.CalculationReportInput.wizardValues.result.push(item);
                }
                else if (this.table23output && this.drBunchingType == 3) {
                    this.wizardFactorInput = new NetworkWizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = this.cableCalculationStandard == "2" ? "Correction Factor:" : "Derating Factor:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.cableCalculationStandard == "2" ? this.table23output["Correction Factor:"] : this.table23output["Derating Factor:"];
                    this.wizardFactorInput.controlText = this.cableCalculationStandard == "2" ? this.table23output["Correction Factor:"] : this.table23output["Derating Factor:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    this.wizardFactorInput = new NetworkWizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "Installation:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.table23output["Installation:"];
                    this.wizardFactorInput.controlText = this.table23output["Installation:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    this.wizardFactorInput = new NetworkWizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "No. of tiers or rows of supports:";
                    this.wizardFactorInput.controlType = "label";
                    this.wizardFactorInput.controlValue = this.table23output["No. of tiers or rows of supports:"];
                    this.wizardFactorInput.controlText = this.table23output["No. of tiers or rows of supports:"];
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    this.wizardFactorInput = new NetworkWizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Results";
                    this.wizardFactorInput.stepTitle = "Results";
                    this.wizardFactorInput.controlId = controlId;
                    this.wizardFactorInput.controlLabel = "Conductor size:";
                    this.wizardFactorInput.controlType = "label";
                    if (this.table23output['No.of circuits per tier or row:']) {
                        this.wizardFactorInput.controlValue = this.table23output['No.of circuits per tier or row:'];
                        this.wizardFactorInput.controlText = this.table23output['No.of circuits per tier or row:'];
                    }
                    if (this.table23output['No. of multi-core cables:']) {
                        this.wizardFactorInput.controlValue = this.table23output['No. of multi-core cables:'];
                        this.wizardFactorInput.controlText = this.table23output['No. of multi-core cables:'];
                    }
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = false;
                    this.wizardFactorInput.overallWizardFactor = this.table23model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                    controlId += 1;

                    var item = new TableItem();
                    item.name = "Table23";
                    item.summary = $('#table23').text();
                    this.CalculationReportInput.wizardValues.result.push(item);
                }
            }
            if (this.drTable271output) {
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = this.cableCalculationStandard == "2" ? "Correction Factor:" : "Derating Factor:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.cableCalculationStandard == "2" ? this.drTable271output["Correction Factor:"] : this.drTable271output["Derating Factor:"];
                this.wizardFactorInput.controlText = this.cableCalculationStandard == "2" ? this.drTable271output["Correction Factor:"] : this.drTable271output["Derating Factor:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                controlId += 1;

                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Conductor temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Conductor temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Conductor temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                controlId += 1;

                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Air ambient temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Air ambient temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Air ambient temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                controlId += 1;

                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Reference temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = false;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                controlId += 1;

                var item = new TableItem();
                item.name = "Table27";
                if (this.drBunchingType == 1)
                    item.summary = $('#table27Air1').text();
                else
                    item.summary = $('#table27Air2').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }

            this.wizardFactorInput = new NetworkWizardFactorInput();
            this.wizardFactorInput.calculationId = this.newCalculationId;
            this.wizardFactorInput.nodeId = this.activeNode.objectcode;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = this.drInstallType;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = this.drBunchingType;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Results";
            this.wizardFactorInput.stepTitle = "Results";
            this.wizardFactorInput.controlId = controlId;
            this.wizardFactorInput.controlLabel = this.cableCalculationStandard == "2" ? "Overall Correction Factor:" : "Overall Derating Factor:";
            this.wizardFactorInput.controlType = "label";
            this.wizardFactorInput.controlValue = this.finalDrf.toString();
            this.wizardFactorInput.controlText = this.finalDrf.toString();
            this.wizardFactorInput.defaultValue = null;
            this.wizardFactorInput.useDefaultValue = null;
            this.wizardFactorInput.isLastControl = true;
            this.wizardFactorInput.overallWizardFactor = this.finalDrf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

        }
        else if (this.drInstallType == 2) {
            if (this.cableCalculationStandard != "2") {
                let imageName: string = this.CableSize['description'].toLowerCase().trim();

                if (imageName === 'buried direct') {
                    this.table25model.InstallType = 1;
                    this.table28model.Buried = 1;
                }
                else {
                    this.table28model.Buried = 2;
                    if (imageName === 'buried enclosure combined' || imageName === 'buried enclosure')
                        this.table25model.InstallType = 3;
                    else if (imageName === 'buried enclosure separate')
                        this.table25model.InstallType = 2;
                }
            }
            stepId = 1;
            /* control 1 */
            let noOfCircuitsName = standardId == "2" ? this.table25NoOfCircuitValuesBS.find(x => x.value == this.table25model.NoOfCircuits).name : this.table25NoOfCircuitValues.find(x => x.value == this.table25model.NoOfCircuits).name;
            this.wizardFactorInput = new NetworkWizardFactorInput();
            this.wizardFactorInput.calculationId = this.newCalculationId;
            this.wizardFactorInput.nodeId = this.activeNode.objectcode;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = this.drInstallType;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = this.table25model.InstallType;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Buried groups";
            this.wizardFactorInput.stepTitle = "Groups of buried circuits";
            this.wizardFactorInput.controlId = 1;
            this.wizardFactorInput.controlLabel = "No. of circuits";
            this.wizardFactorInput.controlType = "dropdownlist";
            this.wizardFactorInput.controlValue = this.table25model.NoOfCircuits.toString();
            this.wizardFactorInput.controlText = noOfCircuitsName;
            this.wizardFactorInput.defaultValue = null;
            this.wizardFactorInput.useDefaultValue = null;
            this.wizardFactorInput.isLastControl = false;
            this.wizardFactorInput.overallWizardFactor = this.table25model.drf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

            /* control 2 */
            if (this.table25model.NoOfCircuits > 1) {
                let arrangementName = standardId == "2" ? this.table25ArrangementValuesBS.find(x => x.value == this.table25model.Arrangement).name : this.table25ArrangementValues.find(x => x.value == this.table25model.Arrangement).name;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.table25model.InstallType;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Buried groups";
                this.wizardFactorInput.stepTitle = "Groups of buried circuits";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = "Arrangement";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table25model.Arrangement.toString();
                this.wizardFactorInput.controlText = arrangementName;
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.overallWizardFactor = this.table25model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
            }
            stepId += 1;
            /* control 1 */
            this.wizardFactorInput = new NetworkWizardFactorInput();
            this.wizardFactorInput.calculationId = this.newCalculationId;
            this.wizardFactorInput.nodeId = this.activeNode.objectcode;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = this.drInstallType;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = this.table28model.Buried;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Depth of burial";
            this.wizardFactorInput.stepTitle = "Depth of Burial (m)";
            this.wizardFactorInput.controlId = 1;
            this.wizardFactorInput.controlLabel = "Depth";
            this.wizardFactorInput.controlType = "textbox";
            if (this.table28model.UseStandard) {
                this.wizardFactorInput.controlValue = "0.50";
                this.wizardFactorInput.controlText = "Use standard 0.5 m";
                this.wizardFactorInput.defaultValue = "Use standard 0.5 m";
                this.wizardFactorInput.useDefaultValue = true;
                this.wizardFactorInput.isLastControl = true;
            }
            else {
                this.wizardFactorInput.controlValue = this.table28model.Depth.toString();
                this.wizardFactorInput.controlText = this.table28model.Depth.toString();
                this.wizardFactorInput.defaultValue = "Use standard 0.5 m";
                this.wizardFactorInput.useDefaultValue = false;
                if (this.table28model.Buried == 1)
                    this.wizardFactorInput.isLastControl = false;
                else
                    this.wizardFactorInput.isLastControl = true;
            }
            this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

            if (!this.table28model.UseStandard && this.table28model.Buried == 1) {
                /* control 2 */
                let conductorSize = this.table28ConductorSizeValues.find(x => x.value == this.table28model.ConductorSize).name;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.table28model.Buried;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Depth of burial";
                this.wizardFactorInput.stepTitle = "Depth of Burial (m)";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = "Conductor Size";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table28model.ConductorSize.toString();
                this.wizardFactorInput.controlText = conductorSize;
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
            }
            stepId += 1;
            /* control 1 */
            this.wizardFactorInput = new NetworkWizardFactorInput();
            this.wizardFactorInput.calculationId = this.newCalculationId;
            this.wizardFactorInput.nodeId = this.activeNode.objectcode;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = this.drInstallType;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = null;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Soil resistivity";
            this.wizardFactorInput.stepTitle = "Soil thermal resistivity";
            this.wizardFactorInput.controlId = 1;
            this.wizardFactorInput.controlLabel = "Resistivity";
            this.wizardFactorInput.controlType = "textbox";
            if (this.table29model.UseStandard) {
                this.wizardFactorInput.controlValue = "1.2";
                this.wizardFactorInput.controlText = "Use standard 1.2 &#176;C.m/W";
                this.wizardFactorInput.defaultValue = "1.2";
                this.wizardFactorInput.useDefaultValue = true;
                this.wizardFactorInput.isLastControl = true;
            }
            else {
                this.wizardFactorInput.controlValue = this.table29model.Resitivity.toString();
                this.wizardFactorInput.controlText = "Use standard 1.2 &#176;C.m/W";
                this.wizardFactorInput.defaultValue = "1.2";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
            }
            this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

            /* control 2 */
            if (!this.table29model.UseStandard) {
                let installation = this.table29InstallationValues.find(x => x.value == this.table29model.Installation).name;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = null;
                this.wizardFactorInput.stepId = stepId;
                this.wizardFactorInput.stepName = "Soil resistivity";
                this.wizardFactorInput.stepTitle = "Soil thermal resistivity";
                this.wizardFactorInput.controlId = 2;
                this.wizardFactorInput.controlLabel = "Type of installation";
                this.wizardFactorInput.controlType = "dropdownlist";
                this.wizardFactorInput.controlValue = this.table29model.Installation.toString();
                this.wizardFactorInput.controlText = installation;
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.isLastControl = true;
                this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
            }
            stepId += 1;
            /* control 1 */
            this.wizardFactorInput = new NetworkWizardFactorInput();
            this.wizardFactorInput.calculationId = this.newCalculationId;
            this.wizardFactorInput.nodeId = this.activeNode.objectcode;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = this.drInstallType;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = null;
            this.wizardFactorInput.stepId = stepId;
            this.wizardFactorInput.stepName = "Soil temprature";
            this.wizardFactorInput.stepTitle = "Soil ambient temprature (C)";
            this.wizardFactorInput.controlId = 1;
            this.wizardFactorInput.controlLabel = "Standard Temprature";
            this.wizardFactorInput.controlType = "textbox";
            if (this.table271model.UseStandard) {
                this.wizardFactorInput.controlValue = this.wizardFactorInput.calcStandardId == 0 ? "25" : "15";;
                this.wizardFactorInput.controlText = this.wizardFactorInput.calcStandardId == 0 ? "Use standard 25 &#176;C" : "Use standard 15 &#176;C";
                this.wizardFactorInput.defaultValue = this.wizardFactorInput.calcStandardId == 0 ? "25" : "15";
                this.wizardFactorInput.useDefaultValue = true;
                this.wizardFactorInput.isLastControl = true;
            }
            else {
                this.wizardFactorInput.controlValue = this.table271model.AbientTemp.toString();
                this.wizardFactorInput.controlText = this.wizardFactorInput.calcStandardId == 0 ? "Use standard 25 &#176;C" : "Use standard 15 &#176;C";
                this.wizardFactorInput.defaultValue = this.wizardFactorInput.calcStandardId == 0 ? "25" : "15";
                this.wizardFactorInput.useDefaultValue = false;
                this.wizardFactorInput.isLastControl = false;
            }
            this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);

            /* control 2 */
            if (!this.table271model.UseStandard) {
                if (this.cableCalculationStandard == "2") {
                    let insulationName = this.insulationValues.find(x => x.value == this.table271model.Insulation).name;
                    this.wizardFactorInput = new NetworkWizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = this.drBunchingType;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Soil temprature";
                    this.wizardFactorInput.stepTitle = "Soil ambient temprature (C)";
                    this.wizardFactorInput.controlId = 2;
                    this.wizardFactorInput.controlLabel = "Insulation";
                    this.wizardFactorInput.controlType = "dropdownlist";
                    this.wizardFactorInput.controlValue = this.table271model.Insulation.toString();
                    this.wizardFactorInput.controlText = insulationName;
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = true;
                    this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                }
                else {
                    let conductorTempName = this.conductorTempValues.find(x => x.value == this.table271model.ConductorTemp).name;
                    this.wizardFactorInput = new NetworkWizardFactorInput();
                    this.wizardFactorInput.calculationId = this.newCalculationId;
                    this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                    this.wizardFactorInput.calcStandardId = standardId
                    this.wizardFactorInput.installationType = this.drInstallType;
                    this.wizardFactorInput.cores = this.drBunchingCore;
                    this.wizardFactorInput.buriedType = null;
                    this.wizardFactorInput.stepId = stepId;
                    this.wizardFactorInput.stepName = "Soil temprature";
                    this.wizardFactorInput.stepTitle = "Soil ambient temprature (C)";
                    this.wizardFactorInput.controlId = 2;
                    this.wizardFactorInput.controlLabel = "Conductor Temprature";
                    this.wizardFactorInput.controlType = "dropdownlist";
                    this.wizardFactorInput.controlValue = this.table271model.ConductorTemp.toString();
                    this.wizardFactorInput.controlText = conductorTempName;
                    this.wizardFactorInput.defaultValue = null;
                    this.wizardFactorInput.useDefaultValue = null;
                    this.wizardFactorInput.isLastControl = true;
                    this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                    this.wizardFactorInputList.push(this.wizardFactorInput);
                }
            }

            /* Storing Results */
            controlId = 1;
            if (this.drTable25output) {
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = this.cableCalculationStandard == "2" ? "Correction Factor:" : "Derating Factor:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.cableCalculationStandard == "2" ? this.drTable25output["Correction Factor:"] : this.drTable25output["Derating Factor:"];
                this.wizardFactorInput.controlText = this.cableCalculationStandard == "2" ? this.drTable25output["Correction Factor:"] : this.drTable25output["Derating Factor:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table25model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Spacing (m):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable25output["Spacing (m):"];
                this.wizardFactorInput.controlText = this.drTable25output["Spacing (m):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table25model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "No. of circuits:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable25output["No. of circuits:"];
                this.wizardFactorInput.controlText = this.drTable25output["No. of circuits:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table25model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                ////////////////////////////////////////////////////////
                var item = new TableItem();
                item.name = "Table25";
                item.summary = $('#table25').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.table28output) {
                controlId += 1;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = this.cableCalculationStandard == "2" ? "Correction Factor:" : "Derating Factor:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.cableCalculationStandard == "2" ? this.table28output["Correction Factor:"] : this.table28output["Derating Factor:"];
                this.wizardFactorInput.controlText = this.cableCalculationStandard == "2" ? this.table28output["Correction Factor:"] : this.table28output["Derating Factor:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Depth of burial (m):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table28output["Depth of burial (m):"];
                this.wizardFactorInput.controlText = this.table28output["Depth of burial (m):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Reference depth (m):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table28output["Reference depth (m):"];
                this.wizardFactorInput.controlText = this.table28output["Reference depth (m):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Conductor size:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table28output["Conductor size:"];
                this.wizardFactorInput.controlText = this.table28output["Conductor size:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table28model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                /////////////////////////////////////////////////////////
                var item = new TableItem();
                item.name = "Table28";
                item.summary = $('#table28').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.table29output) {
                controlId += 1;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = this.cableCalculationStandard == "2" ? "Correction Factor:" : "Derating Factor:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.cableCalculationStandard == "2" ? this.table29output["Correction Factor:"] : this.table29output["Derating Factor:"];
                this.wizardFactorInput.controlText = this.cableCalculationStandard == "2" ? this.table29output["Correction Factor:"] : this.table29output["Derating Factor:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Thermal resistivity of soil (&#176;C.m/W):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table29output["Thermal resistivity of soil (deg.C.m/W):"];
                this.wizardFactorInput.controlText = this.table29output["Thermal resistivity of soil (deg.C.m/W):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Reference resistivity (&#176;C.m/W):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table29output["Reference resistivity (deg.C.m/W):"];
                this.wizardFactorInput.controlText = this.table29output["Reference resistivity (deg.C.m/W):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Type of installation:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.table29output["Type of installation:"];
                this.wizardFactorInput.controlText = this.table29output["Type of installation:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table29model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);
                ////////////////////////////////////////////////////////
                var item = new TableItem();
                item.name = "Table29";
                item.summary = $('#table29').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.drTable271output) {
                controlId += 1;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = this.cableCalculationStandard == "2" ? "Correction Factor:" : "Derating Factor:";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.cableCalculationStandard == "2" ? this.drTable271output["Correction Factor:"] : this.drTable271output["Derating Factor:"];
                this.wizardFactorInput.controlText = this.cableCalculationStandard == "2" ? this.drTable271output["Correction Factor:"] : this.drTable271output["Derating Factor:"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Conductor temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Conductor temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Conductor temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Soil ambient temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Soil ambient temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Soil ambient temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Reference temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                controlId += 1;
                this.wizardFactorInput = new NetworkWizardFactorInput();
                this.wizardFactorInput.calculationId = this.newCalculationId;
                this.wizardFactorInput.nodeId = this.activeNode.objectcode;
                this.wizardFactorInput.calcStandardId = standardId
                this.wizardFactorInput.installationType = this.drInstallType;
                this.wizardFactorInput.cores = this.drBunchingCore;
                this.wizardFactorInput.buriedType = this.drBunchingType;
                this.wizardFactorInput.stepId = 5;
                this.wizardFactorInput.stepName = "Results";
                this.wizardFactorInput.stepTitle = "Results";
                this.wizardFactorInput.controlId = controlId;
                this.wizardFactorInput.controlLabel = "Reference temperature (&#176;C):";
                this.wizardFactorInput.controlType = "label";
                this.wizardFactorInput.controlValue = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.controlText = this.drTable271output["Reference temperature (deg.C.):"];
                this.wizardFactorInput.defaultValue = null;
                this.wizardFactorInput.useDefaultValue = null;
                this.wizardFactorInput.overallWizardFactor = this.table271model.drf.toString();
                this.wizardFactorInputList.push(this.wizardFactorInput);

                var item = new TableItem();
                item.name = "Table27";
                item.summary = $('#table27Soil').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            controlId += 1;
            this.wizardFactorInput = new NetworkWizardFactorInput();
            this.wizardFactorInput.calculationId = this.newCalculationId;
            this.wizardFactorInput.nodeId = this.activeNode.objectcode;
            this.wizardFactorInput.calcStandardId = standardId
            this.wizardFactorInput.installationType = this.drInstallType;
            this.wizardFactorInput.cores = this.drBunchingCore;
            this.wizardFactorInput.buriedType = this.drBunchingType;
            this.wizardFactorInput.stepId = 5;
            this.wizardFactorInput.stepName = "Results";
            this.wizardFactorInput.stepTitle = "Results";
            this.wizardFactorInput.controlId = controlId;
            this.wizardFactorInput.controlLabel = this.cableCalculationStandard == "2" ? "Overall Correction Factor:" : "Overall Derating Factor:";
            this.wizardFactorInput.controlType = "label";
            this.wizardFactorInput.controlValue = this.finalDrf.toString();
            this.wizardFactorInput.controlText = this.finalDrf.toString();
            this.wizardFactorInput.defaultValue = null;
            this.wizardFactorInput.useDefaultValue = null;
            this.wizardFactorInput.isLastControl = true;
            this.wizardFactorInput.overallWizardFactor = this.finalDrf.toString();
            this.wizardFactorInputList.push(this.wizardFactorInput);
        }
        this.OriDrfWizard = this.CalculationReportInput.wizardValues;
        this._wizardService.storeNetworkWizardFactor(this.wizardFactorInputList).subscribe((result) => {
            //this.updateDeratingFactorValue(true, this.CalculationReportInput.wizardValues);
            if (isReloadList) {
                $('#deratingFactor').val(1);
                this.CableSize['deratingfactor'] = 1;
                this.UserWizard = false;
                this.CableSize["isderatingwiz"] = "0";
                // $('#checkboxUseWizard').removeAttr('checked');
                this.setWizardFactorValues();
            }
        }, error => {
            this.options.allowDrag = true;
            this.options.allowDrop = true;
            this.addRemoveNode = false;
        });
    }
    OnChangeCore(e: any) {
        setTimeout(() => {
            if (this.drBunchingCore == 1) {
                this.table23model.CableType = 1;
                this.table25model.CableType = 1;
                this.table28model.Core = 1;
                this.GetTable23Values();
                this.table23CableValues = [
                    { "name": "1 cct", "value": 1 },
                    { "name": "2 ccts", "value": 2 },
                    { "name": "3 ccts", "value": 3 },
                ];
                this.table25ArrangementValues = [
                    { "name": "Touching trefoil", "value": 1 },
                    { "name": "Touching flat", "value": 2 },
                    { "name": "Spaced 0.15 m", "value": 3 },
                    { "name": "Spaced 0.30 m", "value": 4 },
                    { "name": "Spaced 0.45 m", "value": 5 },
                    { "name": "Spaced 0.60 m", "value": 6 },
                ];
                this.table25ArrangementValuesBS = [
                    { "name": "Nil (cables touching)", "value": 1 },
                    { "name": "One cable diameter", "value": 2 },
                    { "name": "Spaced 0.125 m", "value": 3 },
                    { "name": "Spaced 0.25 m", "value": 4 },
                    { "name": "Spaced 0.5 m", "value": 5 },
                ];
            }
            else {
                this.table23model.CableType = 2;
                this.table25model.CableType = 2;
                this.table28model.Core = 2;
                this.GetTable24Values();
                this.table23CableValues = [
                    { "name": "1", "value": 1 },
                    { "name": "2", "value": 2 },
                    { "name": "3", "value": 3 },
                    { "name": "4", "value": 4 },
                    //{ "name": "5", "value": 5 },
                    { "name": "6", "value": 6 },
                    { "name": "9", "value": 9 },
                ];
                this.table25ArrangementValues = [
                    { "name": "Touching", "value": 1 },
                    { "name": "Spaced 0.15 m", "value": 3 },
                    { "name": "Spaced 0.30 m", "value": 4 },
                    { "name": "Spaced 0.45 m", "value": 5 },
                    { "name": "Spaced 0.60 m", "value": 6 },
                ];
                this.table25ArrangementValuesBS = [
                    { "name": "Nil (cables touching)", "value": 1 },
                    { "name": "One cable diameter", "value": 2 },
                    { "name": "Spaced 0.125 m", "value": 3 },
                    { "name": "Spaced 0.25 m", "value": 4 },
                    { "name": "Spaced 0.5 m", "value": 5 },
                ];
            }

            this.onSelectInstall(this.table23model.Installation);

        }, 200);
    }
    OnNextBS() {
        this.setWizardFactorDefaultValuesBS();
        setTimeout(() => {
            this.finalDrf = 1;

            if (this.drInstallType == 1) {
                if (this.drBunchingType != 1) {
                    if (this.drTable22output && this.drBunchingType == 2)
                        this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.drTable22output["Correction Factor:"]).toFixed(2));
                    else if (this.table23output && this.drBunchingType == 3)
                        this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.table23output["Correction Factor:"]).toFixed(2));
                }
                if (this.drTable271output) {
                    this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.drTable271output["Correction Factor:"]).toFixed(2));
                    //$("#table27Air1").show();
                }
            }
            else if (this.drInstallType == 2) {
                if (this.drTable25output)
                    this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.drTable25output["Correction Factor:"]).toFixed(2));
                if (this.table28output)
                    this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.table28output["Correction Factor:"]).toFixed(2));
                if (this.table29output)
                    this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.table29output["Correction Factor:"]).toFixed(2));
                if (this.drTable271output)
                    this.finalDrf = this.finalDrf * parseFloat(parseFloat(this.drTable271output["Correction Factor:"]).toFixed(2));
            }

            if (this.wizard.currentStep > 1 && this.wizard.currentStep <= 4 && this.drInstallType == 2) {
                if (this.wizard.currentStep == 2 && this.table25model.InstallType != 0) {
                    this.table28model.Buried = this.table25model.InstallType;
                    this.setTable28Dr();

                }
                if (this.wizard.currentStep == 3 && this.table28model.Buried != 0) {
                    if (this.table28model.Buried != this.table25model.InstallType) {
                        this.table25model.InstallType = this.table28model.Buried;
                    }
                    this.table29model.Buried = this.table28model.Buried;
                    this.calltable29(this.table29model.Buried);
                } if (this.wizard.currentStep == 4 && this.table29model.Buried != 0) {
                    if (this.table29model.Buried != this.table28model.Buried) {
                        this.table25model.InstallType = this.table29model.Buried;
                        this.table28model.Buried = this.table29model.Buried;
                        this.setTable28Dr();
                    }
                }
            }
        }, 250);
    }
    calltable29(Buried: number) {
        this.drfServicebs.getTable29(this.table29model.Resitivity, Buried).subscribe(result => {
            this.table29model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
            this.table29output = result;
        });
    }
    OnNext() {
        this.setWizardFactorDefaultValues();
        if (this.drInstallType == 1 && this.wizard.currentStep == 1) {

            if (this.drBunchingType == 2) {
                $('#divBunchingType2').css('display', 'block');
                $('#divBunchingType3').css('display', 'none');
            }
            else if (this.drBunchingType == 3) {
                $('#divBunchingType3').css('display', 'block');
                $('#divBunchingType2').css('display', 'none');
            }
        }
        if (this.drInstallType == 1 && this.drBunchingType != 1 && this.wizard.currentStep == 3) {
            this.wizard.steps = (<any>$('#m_wizard')).find('.m-wizard__step');
            this.wizard.totalSteps = this.wizard.steps.length;
        }
        setTimeout(() => {
            this.finalDrf = 1;
            if (this.drInstallType == 1) {
                if (this.drBunchingType != 1) {
                    if (this.drTable22output && this.drBunchingType == 2)
                        this.finalDrf = this.finalDrf * parseFloat(this.drTable22output["Derating Factor:"]);
                    else if (this.table23output && this.drBunchingType == 3)
                        this.finalDrf = this.finalDrf * parseFloat(this.table23output["Derating Factor:"]);
                }
                if (this.drTable271output) {
                    this.finalDrf = this.finalDrf * parseFloat(this.drTable271output["Derating Factor:"]);
                    //$("#table27Air1").show();
                }
            }
            else if (this.drInstallType == 2) {
                if (this.drTable25output)
                    this.finalDrf = this.finalDrf * parseFloat(this.drTable25output["Derating Factor:"]);
                if (this.table28output)
                    this.finalDrf = this.finalDrf * parseFloat(this.table28output["Derating Factor:"]);
                if (this.table29output)
                    this.finalDrf = this.finalDrf * parseFloat(this.table29output["Derating Factor:"]);
                if (this.drTable271output)
                    this.finalDrf = this.finalDrf * parseFloat(this.drTable271output["Derating Factor:"]);
            }
            //  this.setWizardFactorDefaultValues();

        }, 250);
    }
    onResetControlValues() {
        if (this.cableCalculationStandard == "2") {
            this.onResetControlValuesBS();
            return;
        }
        var standard = parseInt(this.CableSize['calculationstandard']);
        var imageName = this.CableSize['description'].toLowerCase().trim();

        this.drInstallType = 1;
        if (imageName.search('buried') === -1) {
            this.drBunchingType = 1;
            $('#divBunchingType2').css('display', 'none');
            $('#divBunchingType3').css('display', 'none');

            this.table22model.Arrangement = 1;
            //(<any>$('#drTable22Arrangement')).select2('val', '1');
            this.table22model.AddType = 1;
            this.table22model.NoOfCircuits = 1;
            // (<any>$('#drTable22NoOfCircuits')).select2('val', '1');
            this.table22model.drf = 1;

            this.table23model.CableType = this.drBunchingCore;
            this.table23model.Row = 1;
            // (<any>$('#drTable23Row')).select2('val', '1');
            this.table23model.Cables = 1;
            // (<any>$('#drTable23Cable')).select2('val', '1');
            this.table23model.Installation = 1;
            this.table23model.drf = this.drBunchingCore == 1 ? 0.95 : 0.97;

            this.table271model.UseStandard = true;
            this.table271model.ConductorTemp = 75;
            //(<any>$('#drTable271ConductorTempAir1')).select2('val', '75');
            //(<any>$('#drTable271ConductorTempAir2')).select2('val', '75');
            this.table271model.drf = 1;
            this.table271model.AbientTemp = standard == 0 ? 40 : 30;

            this.conductorTempValues = [
                { "name": "150 &#176;C", "value": 150 },
                { "name": "110 &#176;C", "value": 110 },
                { "name": "90 &#176;C", "value": 90 },
                { "name": "80 &#176;C", "value": 80 },
                { "name": "75 &#176;C", "value": 75 },
            ];

        }
        else {
            this.drInstallType = 2;
            this.conductorTempValues = [
                // { "name": "150 &#176;C", "value": 150 },
                { "name": "110 &#176;C", "value": 110 },
                { "name": "90 &#176;C", "value": 90 },
                { "name": "80 &#176;C", "value": 80 },
                { "name": "75 &#176;C", "value": 75 },
            ];

            this.table25model.NoOfCircuits = 1;
            // (<any>$('#drTable25NoOfCicruits')).select2('val', '1');
            this.table25model.CableType = this.drBunchingCore;
            if (imageName === 'buried direct') {
                this.table25model.InstallType = 1;
                if (this.table25model.CableType == 1) {
                    this.table25ArrangementValues = [
                        { "name": "Touching trefoil", "value": 1 },
                        { "name": "Touching flat", "value": 2 },
                        { "name": "Spaced 0.15 m", "value": 3 },
                        { "name": "Spaced 0.30 m", "value": 4 },
                        { "name": "Spaced 0.45 m", "value": 5 },
                        { "name": "Spaced 0.60 m", "value": 6 },
                    ];
                }
                else {
                    this.table25ArrangementValues = [
                        { "name": "Touching", "value": 1 },
                        { "name": "Spaced 0.15 m", "value": 3 },
                        { "name": "Spaced 0.30 m", "value": 4 },
                        { "name": "Spaced 0.45 m", "value": 5 },
                        { "name": "Spaced 0.60 m", "value": 6 },
                    ];
                }
            }
            else {
                if (imageName === 'buried enclosure separate') {
                    this.table25model.InstallType = 2;
                    this.table25ArrangementValues = [
                        { "name": "Touching", "value": 1 },
                        { "name": "Spaced 0.45 m", "value": 5 },
                        { "name": "Spaced 0.60 m", "value": 6 },
                    ];
                }
                else if (imageName === 'buried enclosure combined' || imageName === 'buried enclosure') {
                    this.table25model.InstallType = 3;
                    this.table25ArrangementValues = [
                        { "name": "Touching", "value": 1 },
                        { "name": "Spaced 0.30 m", "value": 4 },
                        { "name": "Spaced 0.45 m", "value": 5 },
                        { "name": "Spaced 0.60 m", "value": 6 },
                    ];
                }
            }

            this.table25model.TouchingType = 1;
            this.table25model.Distance = 0;
            this.table25model.drf = 1;
            this.table25model.Arrangement = 1;
            this.setTable25Values(this.table25model.Arrangement);
            // (<any>$('#drTable25Arrangement')).select2('val', '1');

            this.table28model.Depth = 0.5;
            this.table28model.UseStandard = true;
            if (imageName.toLowerCase().trim() === 'buried direct')
                this.table28model.Buried = 1;
            else
                this.table28model.Buried = 2;
            this.table28model.ConductorSize = 3;
            this.table28model.Core = this.drBunchingCore;
            this.table28model.drf = 1;
            // (<any>$('#drTable28CondutorSize')).select2('val', '3');

            this.table29model.UseStandard = true;
            this.table29model.Resitivity = 1.20;
            this.table29model.Installation = 2;
            this.table29model.drf = 1;

            this.table271model.UseStandard = true;
            this.table271model.ConductorTemp = 75;
            //(<any>$('#drTable271ConductorTempSoil')).select2('val', '75');
            this.table271model.drf = 1;
            this.table271model.AbientTemp = standard == 0 ? 25 : 15;
        }

        this.drTable22output = undefined;
        this.table23output = undefined;
        this.drTable25output = undefined;
        this.drTable271output = undefined;
        this.table28output = undefined;
        this.table29output = undefined;
        this.drTable271output = undefined;
        this.wizardFactorInputList = [];
        this.wizardFactorOutput = [];
    }

    onResetControlValuesBS() {
        this.drTable22output = undefined;
        this.table23output = undefined;
        this.drTable25output = undefined;
        this.drTable271output = undefined;
        this.table28output = undefined;
        this.table29output = undefined;

        this.drInstallType = 1;
        this.drBunchingType = 1;
        this.drBunchingCore = 1;
        if (this.CableSize['core'] == "0")
            this.drBunchingCore = 1;
        else
            this.drBunchingCore = 2;


        var imageName = (this.CableSize['description'] != null && this.CableSize['description'] != undefined) ? this.CableSize['description'].toLowerCase().trim() : "";

        if (imageName.search('ground') == -1) {

            this.table22model.Arrangement = 1;
            setTimeout(() => {


                // (<any>$('#drTable22Arrangement')).select2('val', '1');
                this.table22model.NoOfCircuits = 1;
                // (<any>$('#drTable22NoOfCircuits')).select2('val', '1');
                this.table22model.drf = 1;

                this.table23model.CableType = this.drBunchingCore;
                this.table23model.Row = 1;
                (<any>$('#table23Row')).select2('val', '1');
                this.table23model.Cables = 1;
                // (<any>$('#drTable23Cable')).select2('val', '1');
                this.table23model.Installation = 1;
                this.table23model.drf = 0.98;

                this.table271model.UseStandard = true;
                this.table271model.AbientTemp = 30;
                this.table271model.Insulation = 2;
                this.table271model.drf = 1;
            });
        }
        else {
            this.drInstallType = 2;
            this.table25model.NoOfCircuits = 1;
            // (<any>$('#drTable25NoOfCicruits')).select2('val', '1');
            this.table25model.CableType = this.drBunchingCore;
            this.table25model.TouchingType = 1;
            this.table25model.InstallType = 1;
            this.table25model.Distance = 0;
            this.table25model.drf = 1;
            this.table25model.Arrangement = 1;
            this.setTable25Values(this.table25model.Arrangement);
            // (<any>$('#drTable25Arrangement')).select2('val', '1');

            this.table28model.UseStandard = true;
            this.table28model.Depth = 0.7;
            this.table28model.Buried = 1;
            this.table28model.drf = 1;

            this.table29model.UseStandard = true;
            this.table29model.Resitivity = 2.50;
            this.table29model.Buried = 1;
            this.table29model.drf = 1;
        }
    }

    onCancel() {
        if (this.cableCalculationStandard == "2") {
            this.onCancelBS();
            return;
        }

        this.drTable22output = undefined;
        this.table23output = undefined;
        this.drTable25output = undefined;
        this.drTable271output = undefined;
        this.table28output = undefined;
        this.table29output = undefined;

        let imageName = this.CableSize['description'].toLowerCase().trim();

        var standard = parseInt(this.CableSize['calculationstandard']);

        this.drBunchingType = 1;
        this.drInstallType = 1;
        $('#divBunchingType2').css('display', 'none');
        $('#divBunchingType3').css('display', 'none');

        if (imageName.search('buried') === -1) {
            $('#m_wizard_soil').css('display', 'none');
            $('#m_wizard').css('display', 'block');
            $("#m_wizard_form_step_1").addClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_2").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_3").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_4").removeClass('m-wizard__form-step--current');
        }
        else {
            this.drInstallType = 2;
            $('#m_wizard').css('display', 'none');
            $('#m_wizard_soil').css('display', 'block');
            $("#m_wizard_form_step_soil_1").addClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_soil_2").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_soil_3").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_soil_4").removeClass('m-wizard__form-step--current');
        }

        this.table22model.Arrangement = 1;
        // (<any>$('#drTable22Arrangement')).select2('val', '1');
        this.table22model.AddType = 1;
        this.table22model.NoOfCircuits = 1;
        // (<any>$('#drTable22NoOfCircuits')).select2('val', '1');
        this.table22model.drf = 1;

        this.table23model.CableType = this.drBunchingCore;
        this.table23model.Row = 1;
        // (<any>$('#drTable23Row')).select2('val', '1');
        this.table23model.Cables = 1;
        // (<any>$('#drTable23Cable')).select2('val', '1');
        this.table23model.Installation = 1;
        this.table23model.drf = this.drBunchingCore == 1 ? 0.95 : 0.97;

        this.table271model.UseStandard = true;
        this.table271model.ConductorTemp = 75;
        // if (this.drInstallType == 1)
        //     (<any>$('#drTable271ConductorTempAir1')).select2('val', '75');
        // else
        //     (<any>$('#drTable271ConductorTempSoil')).select2('val', '75');
        this.table271model.drf = 1;
        this.table271model.AbientTemp = this.drInstallType == 1 ? (standard == 0 ? 40 : 30) : (standard == 0 ? 25 : 15);

        /* In ground controls reset */
        this.table25model.NoOfCircuits = 1;
        // (<any>$('#drTable25NoOfCicruits')).select2('val', '1');
        this.table25model.CableType = this.drBunchingCore;
        if (imageName.toLowerCase().trim() === 'buried direct') {
            this.table25model.InstallType = 1;
        }
        else {
            if (imageName === 'buried enclosure combined' || imageName === 'buried enclosure')
                this.table25model.InstallType = 3;
            else if (imageName === 'buried enclosure separate')
                this.table25model.InstallType = 2;
        }
        this.table25model.TouchingType = 1;
        this.table25model.Distance = 0;
        this.table25model.drf = 1;
        this.table25model.Arrangement = 1;
        this.setTable25Values(this.table25model.Arrangement);
        // (<any>$('#drTable25Arrangement')).select2('val', '1');

        if (imageName.toLowerCase().trim() === 'buried direct') {
            this.table28model.Buried = 1;
        }
        else {
            this.table28model.Buried = 2;
        }
        this.table28model.UseStandard = true;
        this.table28model.Depth = 0.5;
        this.table28model.ConductorSize = 3;
        this.table28model.Core = this.drBunchingCore;
        this.table28model.drf = 1;
        // (<any>$('#drTable28CondutorSize')).select2('val', '3');

        this.table29model.UseStandard = true;
        this.table29model.Resitivity = 1.20;
        this.table29model.Installation = 2;
        this.table29model.drf = 1;



        if (this.drInstallType == 1) {
            this.wizard = (<any>$('#m_wizard')).mWizard({
                startStep: 1,
            });
        }
        else {
            this.wizard = (<any>$('#m_wizard_soil')).mWizard({
                startStep: 1,
            });
        }

        this.wizard.goTo(1);
    }

    onCancelBS(fromSubmit: boolean = false) {
        if (!fromSubmit && this.OriDrfWizard) {
            this.CalculationReportInput.wizardValues = this.OriDrfWizard
        }
        else {
            this.CalculationReportInput.wizardValues = new DRFWizrd();
            if (this.finalDrf)
                this.CalculationReportInput.wizardValues.finalDrf = parseFloat(this.finalDrf.toFixed(2));
            else
                this.CalculationReportInput.wizardValues.finalDrf = 1;
            this.CalculationReportInput.wizardValues.result = [];
            if (this.drTable22output) {
                var item = new TableItem();
                item.name = "Table22";
                item.summary = $('#table22').text();;
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.table23output) {
                var item = new TableItem();
                item.name = "Table23";
                item.summary = $('#table23').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.drTable25output) {
                var item = new TableItem();
                item.name = "Table25";
                item.summary = $('#table25').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.drTable271output) {
                var item = new TableItem();
                item.name = "Table27";
                item.summary = $('#table27').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.table28output) {
                var item = new TableItem();
                item.name = "Table28";
                item.summary = $('#table28').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            if (this.table29output) {
                var item = new TableItem();
                item.name = "Table29";
                item.summary = $('#table29').text();
                this.CalculationReportInput.wizardValues.result.push(item);
            }
            this.OriDrfWizard = this.CalculationReportInput.wizardValues;
        }
        this.onResetControlValues();
        if (this.wizard)
            this.wizard.goTo(1);
    }

    OnSubmit() {
        this.UserWizard = true;
        this.CableSize["isderatingwiz"] = "1";
        // $('#checkboxUseWizard').attr('checked', 'true');
        $('#deratingFactor').val(this.finalDrf.toFixed(2)).change();

        $('#startStep1').click();
        this.CableSize['deratingfactor'] = this.finalDrf.toFixed(2);
        this.updateWizardFactorValues();
        this.UseWizardChange(true);
        this.SetFirstWizardScreen();
        this.updatecablesizeinarray();
        this.onInputChange();
        $('#deratingWizard').modal('toggle');
    }
    onClose() {
        //if (this.wizardFactorOutput.length == 0 && this.wizardFactorInputList.length == 0) {
        //    this.UserWizard = false;
        //    $('#checkboxUseWizard').removeAttr('checked');
        //}
        this.SetFirstWizardScreen();
    }
    setInstallationType() {
        if (this.cableCalculationStandard == "2") {
            this.setInstallationTypeBS();
            return;
        }
        let newImageName = this.CableSize['description'].toLowerCase().trim();

        if (newImageName.search('buried') === -1)
            this.drInstallType = 1;
        else
            this.drInstallType = 2;
    }

    setInstallationTypeBS() {
        let newImageName = (this.CableSize['description'] != null && this.CableSize['description'] != undefined) ? this.CableSize['description'].toLowerCase().trim() : "";

        if (newImageName.search('ground') == -1)
            this.drInstallType = 1;
        else
            this.drInstallType = 2;
    }

    SetFirstWizardScreen() {
        if (this.cableCalculationStandard == "2") {
            this.SetFirstWizardScreenBS();
            return;
        }
        // this.GetTable271DrBS(null);

        this.setInstallationType();
        if (this.drInstallType === 1) {
            this.wizard = (<any>$('#m_wizard')).mWizard({
                startStep: 1,
            });
            $('#m_wizard_soil').css('display', 'none');
            $('#m_wizard').css('display', 'block');
            $("#m_wizard_form_step_1").addClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_2").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_3").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_4").removeClass('m-wizard__form-step--current');

            this.wizard.steps = (<any>$('#m_wizard')).find('.m-wizard__step');
            this.wizard.totalSteps = this.wizard.steps.length;
        }
        else if (this.drInstallType === 2) {
            this.wizard = (<any>$('#m_wizard_soil')).mWizard({
                startStep: 1,
            });
            $('#m_wizard').css('display', 'none');
            $('#m_wizard_soil').css('display', 'block');
            $("#m_wizard_form_step_soil_1").addClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_soil_2").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_soil_3").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_soil_4").removeClass('m-wizard__form-step--current');

            this.wizard.steps = (<any>$('#m_wizard_soil')).find('.m-wizard__step');
            this.wizard.totalSteps = this.wizard.steps.length;
        }
        this.wizard.goTo(1);
    }

    OnChangeObjectType(objType: number) {

        this.objectType = objType;
    }
    OnChangeBunchingType(bunchingType: number) {

        if (bunchingType == 1)
            this.wizard.totalSteps = 3;
        else
            this.wizard.totalSteps = 4;

        $('#divBunchingType2').css('display', 'none');
        $('#divBunchingType3').css('display', 'none');

        if (bunchingType == 2)
            $('#divBunchingType2').css('display', 'block');
        else if (bunchingType == 3)
            $('#divBunchingType3').css('display', 'block');
    }
    EmailReportChanged() {
        setTimeout(() => {
            if (this.CalculationReportInput.sendEmail) {
                $('#chkEmailReport').attr('checked', 'true');
            } else {
                $('#chkEmailReport').removeAttr('checked');
            }
        }, 100);
    }
    // ShowNameChanged() {
    //     setTimeout(() => {
    //         if (this.CalculationReportInput.printName) {
    //             $('#chkPrintName').attr('checked', 'true');
    //         } else {
    //             $('#chkPrintName').removeAttr('checked');
    //         }
    //     }, 100);
    // }
    rebind() {
        //setTimeout(() => {
        //    this.calculationName = this.route.snapshot.queryParamMap.get('cname');
        //    this.projectName = this.route.snapshot.queryParamMap.get('name');
        //    this.route.params.subscribe((params) => {
        //
        //        this.newCalculationId = parseInt(params.cid);
        //        this.projectId = parseInt(params.pid);
        //        this.GetData('Load', '', 0, 0, 0, 0, 0, 0, 0, '', '', this.newCalculationId, 0, 0);
        //    });
        //}, 250);
    }

    UpdateVoltageLabelValue() {
        //
        if (this.voltageSupplyLabel === undefined || this.voltageSupplyLabel === null || this.voltageSupplyLabel.trim() == '')
            return false;

        if (this.newCalculationId === undefined || this.newCalculationId === null || this.newCalculationId == 0)
            return false;

        let isVoltageDrop = false;
        isVoltageDrop = (this.voltageSupplyLabel == 'drop');

        this.CableSize['isVoltageDrop'] = isVoltageDrop;
        this.updatecablesizeinarray();
        this.getAllControlsValue();
        // this._calculationService.updateVoltageStatus(this.newCalculationId, isVoltageDrop).subscribe((result) => {

        // }, error => {
        // });
    }
    CableSizeValuesUpdate(): boolean {
        if (this.PrevCableSize === null) {

            this.PrevCableSize = {
                insulation: '0',
                conductor: '0',
                earthconductor: '0',
                core: '0',
                phase: '0',
                faultlevel: '3',
                supplyvoltage: '',
                voltagedrop: '2.5',
                powerunit: '1',
                efficiency: '100',
                lengthofrun: '50',
                current: '100',
                powerfactor: '0.9',
                unit: '0',
                deratingfactor: '1',
                device: '0',
                usevd: '1',
                isVoltageDrop: true,
                protection: {
                    shortcircuittime: '0.1',
                    MCBrating: '0',
                    ACBrating: '0',
                    HRCFuserating: '0',
                    MCCBrating: '0',
                    curve: '0',
                    earthfaulttime: '0',
                    rcd: '0',
                    tripcurrent: '0',
                    tripmultiplier: '0',
                    thermalsetting: '0',
                    customrating: '0',
                    description: '0',
                    IsUnknown: true,
                    Xml: undefined,
                    TrippingXml: undefined,
                    protectionConfigurationModel: {}
                },
                installtablewind: { wind: '' },
                specifyconductor: {
                    phase: {
                        mode: '0',
                        size: '0',
                        circuits: '1'
                    },
                    neutral: {
                        mode: '0',
                        size: '0',
                        circuits: '1'
                    },
                    earth: {
                        mode: '1',
                        size: '0',
                        circuits: '1'
                    }
                },
                tableno: '7',
                currentTable: '4D1A,2',
                voltagedropTable: '4D1B,3',
                armour: '0',
                columnno: '0',
                startingrowindex: '0',
                endingrowindex: '0',
                description: 'Unenclosed Spaced',
                tablectrlvalue: '0',
                voltagefactor: '1.1',
                calculationstandard: '0',
                arcFlashData: {},
                isderatingwiz: '0',
                deratingtemp: ''
            };

            this.PrevCableSize['dcvoltage'] = this.CableSize['dcvoltage'];
            this.PrevCableSize['onephasevoltage'] = this.CableSize['onephasevoltage'];
            this.PrevCableSize['threephasevoltage'] = this.CableSize['threephasevoltage'];
            this.PrevCableSize['insulation'] = this.CableSize['insulation'];
            this.PrevCableSize['conductor'] = this.CableSize['conductor'];
            this.PrevCableSize['earthconductor'] = this.CableSize['earthconductor'];
            this.PrevCableSize['core'] = this.CableSize['core'];
            this.PrevCableSize['phase'] = this.CableSize['phase'];
            this.PrevCableSize['armour'] = this.CableSize['armour'];
            this.PrevCableSize['faultlevel'] = this.CableSize['faultlevel'];
            this.PrevCableSize['supplyvoltage'] = this.CableSize['supplyvoltage'];
            this.PrevCableSize['voltagedrop'] = this.CableSize['voltagedrop'];
            this.PrevCableSize['powerunit'] = this.CableSize['powerunit'];
            this.PrevCableSize['efficiency'] = this.CableSize['efficiency'];

            this.PrevCableSize['lengthofrun'] = this.CableSize['lengthofrun'];
            this.PrevCableSize['current'] = this.CableSize['current'];
            this.PrevCableSize['powerfactor'] = this.CableSize['powerfactor'];
            this.PrevCableSize['unit'] = this.CableSize['unit'];
            this.PrevCableSize['deratingfactor'] = this.CableSize['deratingfactor'];
            this.PrevCableSize['device'] = this.CableSize['device'];
            this.PrevCableSize['usevd'] = this.CableSize['usevd'];
            this.PrevCableSize['isVoltageDrop'] = this.CableSize['isVoltageDrop'];
            //isVoltageDrop
            this.PrevCableSize['protection']['shortcircuittime'] = this.CableSize['protection']['shortcircuittime'];
            this.PrevCableSize['protection']['MCBrating'] = this.CableSize['protection']['MCBrating'];
            this.PrevCableSize['protection']['ACBrating'] = this.CableSize['protection']['ACBrating'];
            this.PrevCableSize['protection']['HRCFuserating'] = this.CableSize['protection']['HRCFuserating'];
            this.PrevCableSize['protection']['MCCBrating'] = this.CableSize['protection']['MCCBrating'];
            this.PrevCableSize['protection']['curve'] = this.CableSize['protection']['curve'];
            this.PrevCableSize['protection']['earthfaulttime'] = this.CableSize['protection']['earthfaulttime'];
            this.PrevCableSize['protection']['rcd'] = this.CableSize['protection']['rcd'];
            this.PrevCableSize['protection']['tripcurrent'] = this.CableSize['protection']['tripcurrent'];
            this.PrevCableSize['protection']['tripmultiplier'] = this.CableSize['protection']['tripmultiplier'];
            this.PrevCableSize['protection']['thermalsetting'] = this.CableSize['protection']['thermalsetting'];
            this.PrevCableSize['protection']['customrating'] = this.CableSize['protection']['customrating'];
            this.PrevCableSize['protection']['description'] = this.CableSize['protection']['description'];
            this.PrevCableSize['protection']['IsUnknown'] = this.CableSize['protection']['IsUnknown'];
            this.PrevCableSize['protection']['protectionConfigurationModel'] = this.CableSize['protection']['protectionConfigurationModel'];
            this.PrevCableSize['protection']['Xml'] = this.CableSize['protection']['Xml'];
            this.PrevCableSize['installtablewind']['wind'] = this.CableSize['installtablewind']['wind'];
            this.PrevCableSize['protection']['TrippingXml'] = this.CableSize['protection']['TrippingXml'];

            this.PrevCableSize['specifyconductor']['phase']['mode'] = this.CableSize['specifyconductor']['phase']['mode'];
            this.PrevCableSize['specifyconductor']['phase']['circuits'] = this.CableSize['specifyconductor']['phase']['circuits'];
            this.PrevCableSize['specifyconductor']['phase']['size'] = this.CableSize['specifyconductor']['phase']['size'];

            this.PrevCableSize['specifyconductor']['neutral']['circuits'] = this.CableSize['specifyconductor']['neutral']['circuits'];
            this.PrevCableSize['specifyconductor']['neutral']['size'] = this.CableSize['specifyconductor']['neutral']['size'];
            this.PrevCableSize['specifyconductor']['neutral']['mode'] = this.CableSize['specifyconductor']['neutral']['mode'];

            this.PrevCableSize['specifyconductor']['earth']['mode'] = this.CableSize['specifyconductor']['earth']['mode'];
            this.PrevCableSize['specifyconductor']['earth']['size'] = this.CableSize['specifyconductor']['earth']['size'];
            this.PrevCableSize['specifyconductor']['earth']['circuits'] = this.CableSize['specifyconductor']['earth']['circuits'];

            this.PrevCableSize['tableno'] = this.CableSize['tableno'];
            this.PrevCableSize['currentTable'] = this.CableSize['currentTable'];
            this.PrevCableSize['voltagedropTable'] = this.CableSize['voltagedropTable'];
            this.PrevCableSize['columnno'] = this.CableSize['columnno'];
            this.PrevCableSize['startingrowindex'] = this.CableSize['startingrowindex'];
            this.PrevCableSize['endingrowindex'] = this.CableSize['endingrowindex'];
            this.PrevCableSize['description'] = this.CableSize['description'];
            this.PrevCableSize['tablectrlvalue'] = this.CableSize['tablectrlvalue'];
            this.PrevCableSize['voltagefactor'] = this.CableSize['voltagefactor'];
            this.PrevCableSize['calculationstandard'] = this.CableSize['calculationstandard'];
            this.PrevCableSize['currentTable'] = this.CableSize['currentTable'];
            this.PrevCableSize['voltagedropTable'] = this.CableSize['voltagedropTable'];
            this.PrevCableSize['arcFlashData'] = this.CableSize['arcFlashData'];
            this.PrevCableSize['isderatingwiz'] = this.CableSize['isderatingwiz'];
            this.PrevCableSize['deratingtemp'] = this.CableSize['deratingtemp'];

            return true;
        }
        else {

            if (this.PrevCableSize['dcvoltage'] != this.CableSize['dcvoltage']) {
                this.PrevCableSize['dcvoltage'] = this.CableSize['dcvoltage'];
                return true;
            }

            if (this.PrevCableSize['onephasevoltage'] != this.CableSize['onephasevoltage']) {
                this.PrevCableSize['onephasevoltage'] = this.CableSize['onephasevoltage'];
                return true;
            }

            if (this.PrevCableSize['threephasevoltage'] != this.CableSize['threephasevoltage']) {
                this.PrevCableSize['threephasevoltage'] = this.CableSize['threephasevoltage'];
                return true;
            }

            if (this.PrevCableSize['insulation'] != this.CableSize['insulation']) {
                this.PrevCableSize['insulation'] = this.CableSize['insulation'];
                return true;
            }

            if (this.PrevCableSize['conductor'] != this.CableSize['conductor']) {
                this.PrevCableSize['conductor'] = this.CableSize['conductor'];
                return true;
            }
            if (this.PrevCableSize['earthconductor'] != this.CableSize['earthconductor']) {
                this.PrevCableSize['earthconductor'] = this.CableSize['earthconductor'];
                return true;
            }
            if (this.PrevCableSize['core'] != this.CableSize['core']) {
                this.PrevCableSize['core'] = this.CableSize['core'];
                return true;
            }

            if (this.PrevCableSize['phase'] != this.CableSize['phase']) {
                this.PrevCableSize['phase'] = this.CableSize['phase'];
                return true;
            }

            if (this.PrevCableSize['armour'] != this.CableSize['armour']) {
                this.PrevCableSize['armour'] = this.CableSize['armour'];
                return true;
            }

            if (this.PrevCableSize['faultlevel'] != this.CableSize['faultlevel']) {
                this.PrevCableSize['faultlevel'] = this.CableSize['faultlevel'];
                return true;
            }

            if (this.PrevCableSize['supplyvoltage'] != this.CableSize['supplyvoltage']) {
                this.PrevCableSize['supplyvoltage'] = this.CableSize['supplyvoltage'];
                return true;
            }

            if (this.PrevCableSize['voltagedrop'] != this.CableSize['voltagedrop']) {
                this.PrevCableSize['voltagedrop'] = this.CableSize['voltagedrop'];
                return true;
            }
            if (this.PrevCableSize['powerunit'] != this.CableSize['powerunit']) {
                this.PrevCableSize['powerunit'] = this.CableSize['powerunit'];
                return true;
            }
            if (this.PrevCableSize['efficiency'] != this.CableSize['efficiency']) {
                this.PrevCableSize['efficiency'] = this.CableSize['efficiency'];
                return true;
            }

            if (this.PrevCableSize['lengthofrun'] != this.CableSize['lengthofrun']) {
                this.PrevCableSize['lengthofrun'] = this.CableSize['lengthofrun'];
                return true;
            }

            if (this.PrevCableSize['current'] != this.CableSize['current']) {
                this.PrevCableSize['current'] = this.CableSize['current'];
                return true;
            }

            if (this.PrevCableSize['powerfactor'] != this.CableSize['powerfactor']) {
                this.PrevCableSize['powerfactor'] = this.CableSize['powerfactor'];
                return true;
            }

            if (this.PrevCableSize['unit'] != this.CableSize['unit']) {
                this.PrevCableSize['unit'] = this.CableSize['unit'];
                return true;
            }

            if (this.PrevCableSize['deratingfactor'] != this.CableSize['deratingfactor']) {
                this.PrevCableSize['deratingfactor'] = this.CableSize['deratingfactor'];
                return true;
            }

            if (this.PrevCableSize['device'] != this.CableSize['device']) {
                this.PrevCableSize['device'] = this.CableSize['device'];
                return true;
            }

            if (this.PrevCableSize['usevd'] != this.CableSize['usevd']) {
                this.PrevCableSize['usevd'] = this.CableSize['usevd'];
                return true;
            }
            if (this.PrevCableSize['isVoltageDrop'] != this.CableSize['isVoltageDrop']) {
                this.PrevCableSize['isVoltageDrop'] = this.CableSize['isVoltageDrop'];
                return true;
            }

            let protectionValuesUpdateCount: number = 0;

            if (this.PrevCableSize['protection']['shortcircuittime'] != this.CableSize['protection']['shortcircuittime']) {
                this.PrevCableSize['protection']['shortcircuittime'] = this.CableSize['protection']['shortcircuittime'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['MCBrating'] != this.CableSize['protection']['MCBrating']) {
                this.PrevCableSize['protection']['MCBrating'] = this.CableSize['protection']['MCBrating'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['ACBrating'] != this.CableSize['protection']['ACBrating']) {
                this.PrevCableSize['protection']['ACBrating'] = this.CableSize['protection']['ACBrating'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['HRCFuserating'] != this.CableSize['protection']['HRCFuserating']) {
                this.PrevCableSize['protection']['HRCFuserating'] = this.CableSize['protection']['HRCFuserating'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['MCCBrating'] != this.CableSize['protection']['MCCBrating']) {
                this.PrevCableSize['protection']['MCCBrating'] = this.CableSize['protection']['MCCBrating'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['curve'] != this.CableSize['protection']['curve']) {
                this.PrevCableSize['protection']['curve'] = this.CableSize['protection']['curve'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['earthfaulttime'] != this.CableSize['protection']['earthfaulttime']) {
                this.PrevCableSize['protection']['earthfaulttime'] = this.CableSize['protection']['earthfaulttime'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['rcd'] != this.CableSize['protection']['rcd']) {
                this.PrevCableSize['protection']['rcd'] = this.CableSize['protection']['rcd'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['tripcurrent'] != this.CableSize['protection']['tripcurrent']) {
                this.PrevCableSize['protection']['tripcurrent'] = this.CableSize['protection']['tripcurrent'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['tripmultiplier'] != this.CableSize['protection']['tripmultiplier']) {
                this.PrevCableSize['protection']['tripmultiplier'] = this.CableSize['protection']['tripmultiplier'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['thermalsetting'] != this.CableSize['protection']['thermalsetting']) {
                this.PrevCableSize['protection']['thermalsetting'] = this.CableSize['protection']['thermalsetting'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['customrating'] != this.CableSize['protection']['customrating']) {
                this.PrevCableSize['protection']['customrating'] = this.CableSize['protection']['customrating'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['description'] != this.CableSize['protection']['description']) {
                this.PrevCableSize['protection']['description'] = this.CableSize['protection']['description'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['IsUnknown'] != this.CableSize['protection']['IsUnknown']) {
                this.PrevCableSize['protection']['IsUnknown'] = this.CableSize['protection']['IsUnknown'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['protectionConfigurationModel'] != this.CableSize['protection']['protectionConfigurationModel']) {
                this.PrevCableSize['protection']['protectionConfigurationModel'] = this.CableSize['protection']['protectionConfigurationModel'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['Xml'] != this.CableSize['protection']['Xml']) {
                this.PrevCableSize['protection']['Xml'] = this.CableSize['protection']['Xml'];
                protectionValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['protection']['TrippingXml'] != this.CableSize['protection']['TrippingXml']) {
                this.PrevCableSize['protection']['TrippingXml'] = this.CableSize['protection']['TrippingXml'];
                protectionValuesUpdateCount += 1;
            }

            let specifyConductorValuesUpdateCount: number = 0;

            if (this.PrevCableSize['specifyconductor']['phase']['mode'] != this.CableSize['specifyconductor']['phase']['mode']) {
                this.PrevCableSize['specifyconductor']['phase']['mode'] = this.CableSize['specifyconductor']['phase']['mode'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['phase']['circuits'] != this.CableSize['specifyconductor']['phase']['circuits']) {
                this.PrevCableSize['specifyconductor']['phase']['circuits'] = this.CableSize['specifyconductor']['phase']['circuits'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['phase']['size'] != this.CableSize['specifyconductor']['phase']['size']) {
                this.PrevCableSize['specifyconductor']['phase']['size'] = this.CableSize['specifyconductor']['phase']['size'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['neutral']['circuits'] != this.CableSize['specifyconductor']['neutral']['circuits']) {
                this.PrevCableSize['specifyconductor']['neutral']['circuits'] = this.CableSize['specifyconductor']['neutral']['circuits']
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['neutral']['size'] != this.CableSize['specifyconductor']['neutral']['size']) {
                this.PrevCableSize['specifyconductor']['neutral']['size'] = this.CableSize['specifyconductor']['neutral']['size'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['neutral']['mode'] != this.CableSize['specifyconductor']['neutral']['mode']) {
                this.PrevCableSize['specifyconductor']['neutral']['mode'] = this.CableSize['specifyconductor']['neutral']['mode'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['earth']['mode'] != this.CableSize['specifyconductor']['earth']['mode']) {
                this.PrevCableSize['specifyconductor']['earth']['mode'] = this.CableSize['specifyconductor']['earth']['mode'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['earth']['size'] != this.CableSize['specifyconductor']['earth']['size']) {
                this.PrevCableSize['specifyconductor']['earth']['size'] = this.CableSize['specifyconductor']['earth']['size'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['specifyconductor']['earth']['circuits'] != this.CableSize['specifyconductor']['earth']['circuits']) {
                this.PrevCableSize['specifyconductor']['earth']['circuits'] = this.CableSize['specifyconductor']['earth']['circuits'];
                specifyConductorValuesUpdateCount += 1;
            }

            if (this.PrevCableSize['installtablewind']['wind'] != this.CableSize['installtablewind']['wind']) {
                this.PrevCableSize['installtablewind']['wind'] = this.CableSize['installtablewind']['wind'];
                return true;
            }

            if (this.PrevCableSize['tableno'] != this.CableSize['tableno']) {
                this.PrevCableSize['tableno'] = this.CableSize['tableno'];
                return true;
            }

            if (this.PrevCableSize['currentTable'] != this.CableSize['currentTable']) {
                this.PrevCableSize['currentTable'] = this.CableSize['currentTable'];
                return true;
            }

            if (this.PrevCableSize['voltagedropTable'] != this.CableSize['voltagedropTable']) {
                this.PrevCableSize['voltagedropTable'] = this.CableSize['voltagedropTable'];
                return true;
            }

            if (this.PrevCableSize['columnno'] != this.CableSize['columnno']) {
                this.PrevCableSize['columnno'] = this.CableSize['columnno'];
                return true;
            }

            if (this.PrevCableSize['startingrowindex'] != this.CableSize['startingrowindex']) {
                this.PrevCableSize['startingrowindex'] = this.CableSize['startingrowindex'];
                return true;
            }

            if (this.PrevCableSize['endingrowindex'] != this.CableSize['endingrowindex']) {
                this.PrevCableSize['endingrowindex'] = this.CableSize['endingrowindex']
                return true;
            }

            if (this.PrevCableSize['description'] != this.CableSize['description']) {
                this.PrevCableSize['description'] = this.CableSize['description']
                return true;
            }

            if (this.PrevCableSize['tablectrlvalue'] != this.CableSize['tablectrlvalue']) {
                this.PrevCableSize['tablectrlvalue'] = this.CableSize['tablectrlvalue']
                return true;
            }

            if (this.PrevCableSize['voltagefactor'] != this.CableSize['voltagefactor']) {
                this.PrevCableSize['voltagefactor'] = this.CableSize['voltagefactor']
                return true;
            }

            if (this.PrevCableSize['calculationstandard'] != this.CableSize['calculationstandard']) {
                this.PrevCableSize['calculationstandard'] = this.CableSize['calculationstandard'];
                return true;
            }
            if (this.PrevCableSize['currentTable'] != this.CableSize['currentTable']) {
                this.PrevCableSize['currentTable'] = this.CableSize['currentTable'];
                return true;
            }

            if (this.PrevCableSize['voltagedropTable'] != this.CableSize['voltagedropTable']) {
                this.PrevCableSize['voltagedropTable'] = this.CableSize['voltagedropTable'];
                return true;
            }

            if (this.PrevCableSize['arcFlashData'] != this.CableSize['arcFlashData']) {
                this.PrevCableSize['arcFlashData'] = this.CableSize['arcFlashData'];
                return true;
            }

            if (this.PrevCableSize['isderatingwiz'] != this.CableSize['isderatingwiz']) {
                this.PrevCableSize['isderatingwiz'] = this.CableSize['isderatingwiz'];
                return true;
            }

            if (this.PrevCableSize['deratingtemp'] != this.CableSize['deratingtemp']) {
                this.PrevCableSize['deratingtemp'] = this.CableSize['deratingtemp'];
                return true;
            }

            //this.hideLoader();;
            if (specifyConductorValuesUpdateCount > 0 || protectionValuesUpdateCount > 0) {

                return true;
            }
            else {

                return false;
            }
        }
    }
    IsReportEmailValid(): boolean {

        let isValidFlag: boolean = true;

        $('#sendEmailError1').css('display', 'none');
        $('#sendEmailError2').css('display', 'none');

        if ($('#chkEmailReport').is(':checked')) {

            let reportEmailAddress: string = $('#sendEmail').val().toString().trim();
            let reportEmailAddressArray: string[] = reportEmailAddress.split(',');

            let emailPattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,13}\b$/i;

            if (reportEmailAddress.length === 0) {
                isValidFlag = false;
                $('#sendEmailError1').css('display', '');
            }
            else if (reportEmailAddressArray.length > 0) {
                for (let i = 0; i < reportEmailAddressArray.length; i++) {
                    if (reportEmailAddressArray[i].trim().length > 0 && !emailPattern.test(reportEmailAddressArray[i].trim())) {
                        isValidFlag = false;
                        $('#sendEmailError2').css('display', '');
                        break;
                    }
                }
            }
            else if (!emailPattern.test(reportEmailAddress)) {
                isValidFlag = false;
                $('#sendEmailError2').css('display', '');
            }

            $('#sendEmail').focus();
        }
        return isValidFlag;
    }

    //INUSE
    ngOnDestroy() {
        this.toRemoveData.unsubscribe();
        //   this._settingService.currentMessage = null;
        this.messageReport.unsubscribe();
        // this.newCalculationId = null;
        this._calculationService.setPrevValue('').subscribe((result) => { }, error => { });
    }
    /* to copy object and avoid global value passing*/
    copy(obj) {
        return JSON.parse(JSON.stringify(obj));
    }
    RemoveTrailingZeros(numberWithZero: string): string {
        try {
            if (numberWithZero.indexOf('.') != -1) {
                var splitnumberList = numberWithZero.split('.');

                let afterDecimalValue = parseInt(splitnumberList[1]);

                if (afterDecimalValue < 10)
                    return parseFloat(numberWithZero).toString();
                else {
                    let modulusValue = afterDecimalValue % 10;

                    if (modulusValue == 0)
                        return parseFloat(numberWithZero).toFixed(1);
                    else
                        return parseFloat(numberWithZero).toFixed(2);
                }
            }
            else
                return parseInt(numberWithZero).toString();
        } catch (e) {
            return numberWithZero;

        }
    }
    SetFirstWizardScreenBS() {
        this.GetTable271DrBS(null);
        this.setInstallationType();

        if (this.drInstallType == 1) {
            this.wizard = (<any>$('#m_wizard')).mWizard({
                startStep: 1,
            });
            //  $('#m_wizard_soil').css('display', 'none');
            $('#m_wizard').css('display', 'block');

            $("#m_wizard_form_step_2").addClass('m-wizard__form-step--current');
            //  $(".typetwo").removeClass('m-wizard__step--current');
            if (!$(".typeone").hasClass("ng-star-inserted m-wizard__step--done"))
                $(".typeone .m-wizard__step-number span").css("background-color", '#34bfa3');

            $("#m_wizard_form_step_3").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_4").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_5").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_6").removeClass('m-wizard__form-step--current');

            // type 2
            $("#m_wizard_form_step_7").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_8").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_9").removeClass('m-wizard__form-step--current');

            this.wizard.steps = (<any>$('#m_wizard')).find('.m-wizard__step');
            this.wizard.totalSteps = this.wizard.steps.length;
        }
        else if (this.drInstallType == 2) {
            this.wizard = (<any>$('#m_wizard')).mWizard({
                startStep: 1,
            });

            $('#m_wizard').css('display', 'block');
            //   $('#m_wizard_soil').css('display', 'block');
            //$(".typeone").removeClass('m-wizard__step--current');
            if (!$(".typetwo").hasClass("ng-star-inserted m-wizard__step--done"))
                $(".typetwo .m-wizard__step-number span").css("background-color", '#34bfa3');

            $("#m_wizard_form_step_7").addClass('m-wizard__form-step--current');
            //            $(".typetwo").addClass('m-wizard__step--current');

            $("#m_wizard_form_step_4").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_5").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_soil_8").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_soil_9").removeClass('m-wizard__form-step--current');

            // type 1
            $("#m_wizard_form_step_2").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_3").removeClass('m-wizard__form-step--current');
            $("#m_wizard_form_step_6").removeClass('m-wizard__form-step--current');


            this.wizard.steps = (<any>$('#m_wizard')).find('.m-wizard__step');
            this.wizard.totalSteps = this.wizard.steps.length;
        }
        this.wizard.goTo(1);
    }
    setWizardFactorDefaultValuesBS() {
        // this._wizardService.getWizardFactors(this.bsCalculationId, this.drInstallType).subscribe(result => {
        //     if (result.items.length > 0) {
        //         this.wizardFactorOutput = result.items;
        //         for (let wizardFactor of this.wizardFactorOutput) {
        let stepId = this.wizard.currentStep;
        let callby = ($('.m-wizard__step--current')).find('.m-wizard__step-label').text().trim().toLowerCase();
        switch (stepId) {
            case 1:
                //  this.drBunchingType = 1;
                if (callby === 'bunched circuits') {/* In air - Bunched Circuits */
                    //    this.drBunchingType = 2;
                    //if (wizardFactor.controlId == 1) {/* control 1 */
                    //   this.table22model.Arrangement = parseInt(wizardFactor.controlValue);
                    // $('#drTable22Arrangement').trigger('change');
                    //}
                    //if (wizardFactor.controlId == 2) {/* control 2 */
                    //  this.table22model.NoOfCircuits = parseInt(wizardFactor.controlValue);
                    // $('#drTable22NoOfCircuits').trigger('change');
                    //}
                    //if (wizardFactor.isLastControl)
                    this.GetTable22Dr(null);
                }
                else if (callby === 'in trays') { /* In air - Cable Trays */
                    // this.drBunchingType = 3;
                    //if (wizardFactor.controlId == 1) {/* control 1 */
                    //   this.table23model.Row = parseInt(wizardFactor.controlValue);
                    // $('#drTable23Row').trigger('change');
                    //}
                    //  else if (wizardFactor.controlId == 2) {/* control 2 */
                    //  this.table23model.Cables = parseInt(wizardFactor.controlValue);
                    // $('#drTable23Cable').trigger('change');
                    //}
                    // else if (wizardFactor.controlId == 3) {/* control 3 */
                    //     this.table23model.Installation = parseInt(wizardFactor.controlValue);
                    // }
                    //if (wizardFactor.isLastControl) {
                    //  this.table23model.CableType = this.drBunchingCore;
                    this.GetTable23Dr(null);
                    //}
                }
                else if (callby === 'buried groups' || callby === 'arrangement  buried groups' || (callby === '' && this.drInstallType == 2)) { /* In ground - Buried Circuits */
                    //if (wizardFactor.controlId == 1) {/* control 1 */
                    //  this.table25model.NoOfCircuits = parseInt(wizardFactor.controlValue);
                    // $('#drTable25NoOfCicruits').trigger('change');
                    //}
                    //if (this.table25model.NoOfCircuits > 1 && wizardFactor.controlId == 2) {/* control 2 */
                    //  this.table25model.Arrangement = parseInt(wizardFactor.controlValue);
                    // $('#drTable25Arrangement').trigger('change');
                    //}
                    // if (wizardFactor.isLastControl) {
                    //     this.drInstallType = 2;
                    //     this.table25model.CableType = this.drBunchingCore;
                    //     this.table25model.InstallType = wizardFactor.buriedType;
                    this.GetTable25Dr(null);
                    this.setTable25Values(this.table25model.Arrangement);
                    //}
                }
                else if (this.drInstallType == 1 && (callby === 'air temprature' || callby === 'air temperature')) {/* In air */
                    //if (wizardFactor.controlId == 1) {/* control 1 */
                    //  this.table271model.AmbientTemp = parseFloat(wizardFactor.controlValue);
                    // this.table271model.UseStandard = wizardFactor.useDefaultValue;
                    $('#inputUseStandard').trigger('change');
                    $('#drTable271UseStandard').trigger('change');
                    //}
                    if (!this.table271model.UseStandard) {/* control 2 */
                        // this.table271model.Insulation = parseInt(wizardFactor.controlValue);
                        // $('#drTable271Insulation').trigger('change');
                    }
                    //if (wizardFactor.isLastControl)
                    this.GetTable271Dr(null);
                }
                break;
            case 2:
            case 4:
                if (stepId == 2) {
                    if (this.drInstallType == 1 && (callby === 'air temprature' || callby === 'air temperature')) {/* In air */
                        // if (wizardFactor.controlId == 1) {/* control 1 */
                        //  this.table271model.AmbientTemp = parseFloat(wizardFactor.controlValue);
                        // this.table271model.UseStandard = wizardFactor.useDefaultValue;
                        $('#inputUseStandard').trigger('change');
                        $('#drTable271UseStandard').trigger('change');
                        // }
                        if (!this.table271model.UseStandard) {/* control 2 */
                            //   this.table271model.Insulation = parseInt(wizardFactor.controlValue);
                            // $('#drTable271Insulation').trigger('change');
                        }
                        //if (wizardFactor.isLastControl)
                        this.GetTable271Dr(null);
                    }
                    if (callby === 'bunched circuits') {/* In air - Bunched Circuits */
                        // $('#drTable22Arrangement').trigger('change');

                        // $('#drTable22NoOfCircuits').trigger('change');

                        this.GetTable22Dr(null);
                    }

                    if (callby === 'in trays') { /* In air - Cable Trays */
                        //this.drBunchingType = 3;
                        //if (wizardFactor.controlId == 1) {/* control 1 */
                        //   this.table23model.Row = parseInt(wizardFactor.controlValue);
                        // $('#drTable23Row').trigger('change');
                        //}
                        //else if (wizardFactor.controlId == 2) {/* control 2 */
                        //  this.table23model.Cables = parseInt(wizardFactor.controlValue);
                        // $('#drTable23Cable').trigger('change');
                        //}
                        //else if (wizardFactor.controlId == 3) {/* control 3 */
                        //   this.table23model.Installation = parseInt(wizardFactor.controlValue);
                        // }
                        // if (wizardFactor.isLastControl) {
                        //     this.table23model.CableType = this.drBunchingCore;
                        this.GetTable23Dr(null);
                        // }
                    }
                    else if (this.drInstallType == 2 && callby === 'depth of burial') {/* In ground */

                        //   if (wizardFactor.controlId == 1) {/* control 1 */
                        // this.table28model.Depth = parseFloat(wizardFactor.controlValue);
                        // this.table28model.UseStandard = wizardFactor.useDefaultValue;
                        // this.table28model.Buried = wizardFactor.buriedType;
                        $('#inputDepth').trigger('change');
                        //  }
                        //if (wizardFactor.isLastControl)
                        this.GetTable28Dr(null);
                    }
                }
                else if (stepId == 4 && this.drInstallType == 2 && (callby === 'soil temprature' || callby === 'soil temperature')) {/* In ground */
                    // if (wizardFactor.controlId == 1) {/* control 1 */
                    //     this.table271model.AmbientTemp = parseFloat(wizardFactor.controlValue);
                    //     this.table271model.UseStandard = wizardFactor.useDefaultValue;
                    // }
                    //  if (!this.table271model.UseStandard) {/* control 2 */
                    //     this.table271model.Insulation = parseInt(wizardFactor.controlValue);
                    // }
                    // if (wizardFactor.isLastControl)
                    this.GetTable271DrBS(null);
                }
                break;
            case 3:
                if (this.drInstallType == 2 && callby === 'soil resistivity') {/* In ground */
                    // if (wizardFactor.controlId == 1) {/* control 1 */
                    //     this.table29model.Resitivity = parseFloat(wizardFactor.controlValue);
                    //     this.table29model.UseStandard = wizardFactor.useDefaultValue;
                    // }
                    //if (wizardFactor.isLastControl)
                    this.GetTable29Dr(null);
                }
                break;
        }
        //         }
        //     }
        // });
    }
    setWizardFactorDefaultValues() {

        let stepId = this.wizard.currentStep;
        let callby = ($('#m_wizard_soil .m-wizard__step--current:first')).find('.m-wizard__step-label').text().trim().toLowerCase();
        if (this.drInstallType === 1)
            callby = ($('#m_wizard .m-wizard__step--current')).find('.m-wizard__step-label').text().trim().toLowerCase();
        switch (stepId) {
            case 1:
                //  this.drBunchingType = 1;
                if (callby === 'bunched circuits') {/* In air - Bunched Circuits */
                    // if (wizardFactor.controlId == 1) {/* control 1 */
                    //     this.table22model.Arrangement = parseInt(wizardFactor.controlValue);
                    // $('#drTable22Arrangement').trigger('change');
                    // }
                    //if (wizardFactor.controlId == 2) {/* control 2 */
                    //  this.table22model.NoOfCircuits = parseInt(wizardFactor.controlValue);
                    // $('#drTable22NoOfCircuits').trigger('change');
                    //}
                    if ((this.table22model.Arrangement == 3 || this.table22model.Arrangement == 4)) {
                        if (this.table22model.AddType == 1)
                            $('#drTable22AddType1').click();
                        else
                            $('#drTable22AddType2').click();
                    }
                    this.GetTable22Dr(null);
                    //this.table22model.drf = parseFloat(wizardFactor.overallWizardFactor);
                }
                else if (callby === 'in trays') { /* In air - Cable Trays */
                    // if (wizardFactor.controlId == 1) {/* control 1 */
                    //     this.table23model.Row = parseInt(wizardFactor.controlValue);
                    // $('#drTable23Row').trigger('change');
                    //}
                    //else if (wizardFactor.controlId == 2) {/* control 2 */
                    //  this.table23model.Cables = parseInt(wizardFactor.controlValue);
                    // $('#drTable23Cable').trigger('change');
                    //}
                    //else if (wizardFactor.controlId == 3) {/* control 3 */
                    //}
                    //                                if (wizardFactor.isLastControl) {
                    //                                this.table23model.CableType = this.drBunchingCore;
                    this.GetTable23Dr(null);
                    //this.table23model.drf = parseFloat(wizardFactor.overallWizardFactor);
                    //                              }
                }
                else if (callby === 'buried groups' || callby === 'arrangement  buried groups') { /* In ground - Buried Circuits */
                    // $('#drTable25NoOfCicruits').trigger('change');
                    if (this.table25model.NoOfCircuits > 1) {/* control 2 */
                        // $('#drTable25Arrangement').trigger('change');
                    }
                    //  if (wizardFactor.isLastControl) {
                    //this.GetTable25Dr(null);
                    //this.setTable25Values(this.table25model.Arrangement);
                    //this.table25model.drf = parseFloat(wizardFactor.overallWizardFactor);
                    //   }
                }
                else if (this.drInstallType === 1 && (callby === 'air temprature' || callby === "air temperature")) {/* In air */
                    /* control 1 */

                    if (this.drBunchingType === 1) {
                        $('#inputUseStandardAir1').trigger('change');
                        $('#drTable271UseStandardAir1').trigger('change');
                    }
                    else {
                        $('#inputUseStandardAir2').trigger('change');
                        $('#drTable271UseStandardAir2').trigger('change');
                    }
                    if (this.cableCalculationStandard == "2") {
                        $('#inputUseStandard').trigger('change');
                        $('#drTable271UseStandard').trigger('change');
                    }
                    //}
                    if (!this.table271model.UseStandard) {/* control 2 */
                        //  this.table271model.ConductorTemp = parseInt(wizardFactor.controlValue);
                        // if (this.drBunchingType === 1)
                        //     $('#drTable271ConductorTempAir1').trigger('change');
                        // else
                        //     $('#drTable271ConductorTempAir2').trigger('change');

                        if (this.cableCalculationStandard == "2") {
                            // $('#drTable271Insulation').trigger('change');
                        }
                    }
                    //if (wizardFactor.isLastControl) {
                    this.GetTable271Dr(null);
                    //this.table271model.drf = parseFloat(wizardFactor.overallWizardFactor);
                    //}
                }
                break;
            case 2:
            case 4:
                if (stepId === 2) {
                    if (callby === 'bunched circuits') {/* In air - Bunched Circuits */
                        // if (wizardFactor.controlId == 1) {/* control 1 */
                        //     this.table22model.Arrangement = parseInt(wizardFactor.controlValue);
                        // $('#drTable22Arrangement').trigger('change');
                        // }
                        //if (wizardFactor.controlId == 2) {/* control 2 */
                        //  this.table22model.NoOfCircuits = parseInt(wizardFactor.controlValue);
                        // $('#drTable22NoOfCircuits').trigger('change');
                        //}
                        if ((this.table22model.Arrangement == 3 || this.table22model.Arrangement == 4)) {
                            if (this.table22model.AddType == 1)
                                $('#drTable22AddType1').click();
                            else
                                $('#drTable22AddType2').click();
                        }
                        this.GetTable22Dr(null);
                        //this.table22model.drf = parseFloat(wizardFactor.overallWizardFactor);
                    }

                    if (callby === 'in trays') { /* In air - Cable Trays */
                        // if (wizardFactor.controlId == 1) {/* control 1 */
                        //     this.table23model.Row = parseInt(wizardFactor.controlValue);
                        // $('#drTable23Row').trigger('change');
                        //}
                        //else if (wizardFactor.controlId == 2) {/* control 2 */
                        //  this.table23model.Cables = parseInt(wizardFactor.controlValue);
                        // $('#drTable23Cable').trigger('change');
                        //}
                        //else if (wizardFactor.controlId == 3) {/* control 3 */
                        //}
                        //                                if (wizardFactor.isLastControl) {
                        //                                this.table23model.CableType = this.drBunchingCore;
                        this.GetTable23Dr(null);
                        //this.table23model.drf = parseFloat(wizardFactor.overallWizardFactor);
                        //                              }
                    }
                    if (this.drInstallType === 1 && (callby === 'air temprature' || callby === "air temperature")) {/* In air */
                        //  if (wizardFactor.controlId == 1) {/* control 1 */

                        if (this.drBunchingType === 1) {
                            $('#inputUseStandardAir1').trigger('change');
                            $('#drTable271UseStandardAir1').trigger('change');
                        }
                        else {
                            $('#inputUseStandardAir2').trigger('change');
                            $('#drTable271UseStandardAir2').trigger('change');
                        }
                        if (this.cableCalculationStandard == "2") {
                            $('#inputUseStandard').trigger('change');
                            $('#drTable271UseStandard').trigger('change');
                        }
                        //  }
                        if (!this.table271model.UseStandard) {/* control 2 */
                            // this.table271model.ConductorTemp = parseInt(wizardFactor.controlValue);
                            // if (this.drBunchingType === 1)
                            //     $('#drTable271ConductorTempAir1').trigger('change');
                            // else
                            //     $('#drTable271ConductorTempAir2').trigger('change');
                            // if (this.cableCalculationStandard == "2") {
                            //     // $('#drTable271Insulation').trigger('change');
                            // }
                        }

                        this.GetTable271Dr(null);
                    }
                    else if (this.drInstallType === 2 && (callby === 'depth of burial' || callby === 'arrangement  depth of burial')) {/* In ground */
                        //this.table28model.Buried = wizardFactor.buriedType;
                        //if (wizardFactor.controlId == 1) {/* control 1 */
                        // this.table28model.Depth = parseFloat(wizardFactor.controlValue);
                        // this.table28model.UseStandard = wizardFactor.useDefaultValue;
                        $('#inputDepth').trigger('change');
                        // }
                        if (!this.table28model.UseStandard && this.table28model.Buried == 1) {/* control 2 */
                            //  this.table28model.ConductorSize = parseInt(wizardFactor.controlValue);
                            // $('#drTable28CondutorSize').trigger('change');
                        }
                        this.table28model.Core = this.drBunchingCore;
                        this.GetTable28Dr(null);
                    }
                }
                else if (stepId === 4 && this.drInstallType === 2 && (callby === 'soil temprature' || callby === 'soil temperature' || callby === 'arrangement  soil temperature')) {/* In ground */
                    //  if (wizardFactor.controlId == 1) {/* control 1 */
                    //this.table271model.AbientTemp = parseFloat(wizardFactor.controlValue);
                    //this.table271model.UseStandard = wizardFactor.useDefaultValue;
                    $('#inputUseStandardSoil').trigger('change');
                    $('#drTable271UseStandardSoil').trigger('change');
                    //   }
                    if (!this.table271model.UseStandard) {/* control 2 */
                        //this.table271model.ConductorTemp = parseInt(wizardFactor.controlValue);
                        // $('#drTable271ConductorTempSoil').trigger('change');
                    }
                    this.GetTable271Dr(null);
                }
                break;
            case 3:
                if (this.drInstallType === 1 && (callby === 'air temprature' || callby === "air temperature")) {/* In air */
                    //  if (wizardFactor.controlId == 1) {/* control 1 */

                    if (this.drBunchingType === 1) {
                        $('#inputUseStandardAir1').trigger('change');
                        $('#drTable271UseStandardAir1').trigger('change');
                    }
                    else {
                        $('#inputUseStandardAir2').trigger('change');
                        $('#drTable271UseStandardAir2').trigger('change');
                    }
                    if (this.cableCalculationStandard == "2") {
                        $('#inputUseStandard').trigger('change');
                        $('#drTable271UseStandard').trigger('change');
                    }
                    //  }
                    if (!this.table271model.UseStandard) {/* control 2 */
                        // this.table271model.ConductorTemp = parseInt(wizardFactor.controlValue);
                        // if (this.drBunchingType === 1)
                        //     $('#drTable271ConductorTempAir1').trigger('change');
                        // else
                        //     $('#drTable271ConductorTempAir2').trigger('change');
                        // if (this.cableCalculationStandard == "2") {
                        //     // $('#drTable271Insulation').trigger('change');
                        // }
                    }

                    this.GetTable271Dr(null);
                }
                if (this.drInstallType === 2 && (callby === 'soil resistivity' || callby === 'arrangement  soil resistivity')) {/* In ground */
                    // if (wizardFactor.controlId == 1) {/* control 1 */
                    //this.table29model.Resitivity = parseFloat(wizardFactor.controlValue);
                    //this.table29model.UseStandard = wizardFactor.useDefaultValue;
                    $('#inputUseStandardSoil').trigger('change');
                    $('#drTable29UseStandard').trigger('change');
                    // }

                    this.GetTable29Dr(null);
                    //this.table29model.drf = parseFloat(wizardFactor.overallWizardFactor);
                }
                break;
        }
    }


    setWizardFactorValues() {
        if (this.activeNode) {
            // this.requestCount++;
            this._wizardService.getNetworkWizardFactors(this.activeNode.objectcode, this.newCalculationId, this.drInstallType).subscribe(result => {
                if (result.items.length > 0) {
                    this.wizardFactorOutput = result.items;
                    for (let wizardFactor of this.wizardFactorOutput) {
                        let stepId = wizardFactor.stepId;
                        let callby = wizardFactor.stepName.toLowerCase();

                        switch (stepId) {
                            case 1:
                                this.drBunchingType = 1;
                                if (callby === 'bunched circuits') {/* In air - Bunched Circuits */
                                    this.drBunchingType = 2;
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table22model.Arrangement = parseInt(wizardFactor.controlValue);
                                        // $('#drTable22Arrangement').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    if (wizardFactor.controlId == 2) {/* control 2 */
                                        this.table22model.NoOfCircuits = parseInt(wizardFactor.controlValue);
                                        // $('#drTable22NoOfCircuits').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    if (wizardFactor.controlId == 3 && (this.table22model.Arrangement == 3 || this.table22model.Arrangement == 4)) {
                                        this.table22model.AddType = parseInt(wizardFactor.controlValue);
                                        if (this.table22model.AddType == 1)
                                            $('#drTable22AddType1').click();
                                        else
                                            $('#drTable22AddType2').click();
                                    }
                                    if (wizardFactor.isLastControl) {
                                        this.GetTable22Dr(null);
                                        //this.table22model.drf = parseFloat(wizardFactor.overallWizardFactor);
                                    }
                                }
                                else if (callby === 'in trays') { /* In air - Cable Trays */
                                    this.drBunchingType = 3;
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table23model.Row = parseInt(wizardFactor.controlValue);
                                        // $('#drTable23Row').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    else if (wizardFactor.controlId == 2) {/* control 2 */
                                        this.table23model.Cables = parseInt(wizardFactor.controlValue);
                                        // $('#drTable23Cable').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    else if (wizardFactor.controlId == 3) {/* control 3 */
                                        this.table23model.Installation = parseInt(wizardFactor.controlValue);
                                        this.onSelectInstall(this.table23model.Installation);
                                    }
                                    if (wizardFactor.isLastControl) {
                                        this.table23model.CableType = this.drBunchingCore;
                                        this.GetTable23Dr(null);
                                        //this.table23model.drf = parseFloat(wizardFactor.overallWizardFactor);
                                    }
                                }
                                else if (callby === 'buried groups') { /* In ground - Buried Circuits */
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table25model.NoOfCircuits = parseInt(wizardFactor.controlValue);
                                        // $('#drTable25NoOfCicruits').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    if (this.table25model.NoOfCircuits > 1 && wizardFactor.controlId == 2) {/* control 2 */
                                        this.table25model.Arrangement = parseInt(wizardFactor.controlValue);
                                        this.setTable25Values(this.table25model.Arrangement);
                                        // $('#drTable25Arrangement').val(wizardFactor.controlValue).select2({
                                        //     width: '100%',
                                        //     minimumResultsForSearch: Infinity // disable search
                                        // }).trigger('change');
                                    }
                                    if (wizardFactor.isLastControl) {
                                        this.drInstallType = 2;
                                        this.table25model.CableType = this.drBunchingCore;
                                        this.table25model.InstallType = wizardFactor.buriedType;
                                        this.GetTable25Dr(null);
                                        this.setTable25Values(this.table25model.Arrangement);
                                        //this.table25model.drf = parseFloat(wizardFactor.overallWizardFactor);
                                    }
                                }
                                else if (wizardFactor.installationType === 1 && callby === 'air temprature') {/* In air */
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table271model.AbientTemp = parseFloat(wizardFactor.controlValue);
                                        this.table271model.UseStandard = wizardFactor.useDefaultValue;
                                        if (this.drBunchingType === 1) {
                                            $('#inputUseStandardAir1').val(wizardFactor.controlValue).trigger('change');
                                            $('#drTable271UseStandardAir1').val(wizardFactor.controlValue).trigger('change');
                                        }
                                        else {
                                            $('#inputUseStandardAir2').val(wizardFactor.controlValue).trigger('change');
                                            $('#drTable271UseStandardAir2').val(wizardFactor.controlValue).trigger('change');
                                        }
                                        if (this.cableCalculationStandard == "2") {
                                            $('#inputUseStandard').val(wizardFactor.controlValue).trigger('change');
                                            $('#drTable271UseStandard').val(wizardFactor.controlValue).trigger('change');
                                        }
                                    }
                                    else if (!this.table271model.UseStandard && wizardFactor.controlId == 2) {/* control 2 */
                                        this.table271model.ConductorTemp = parseInt(wizardFactor.controlValue);
                                        // if (this.drBunchingType === 1)
                                        //     $('#drTable271ConductorTempAir1').val(wizardFactor.controlValue).trigger('change');
                                        // else
                                        //     $('#drTable271ConductorTempAir2').val(wizardFactor.controlValue).trigger('change');

                                        if (this.cableCalculationStandard == "2") {
                                            this.table271model.Insulation = parseInt(wizardFactor.controlValue);
                                            // $('#drTable271Insulation').val(wizardFactor.controlValue).trigger('change');
                                        }
                                    }
                                    if (wizardFactor.isLastControl) {
                                        this.GetTable271Dr(null);
                                        //this.table271model.drf = parseFloat(wizardFactor.overallWizardFactor);
                                    }
                                }
                                break;
                            case 2:
                            case 4:
                                if (stepId === 2) {
                                    if (wizardFactor.installationType === 1 && callby === 'air temprature') {/* In air */
                                        if (wizardFactor.controlId == 1) {/* control 1 */
                                            this.table271model.AbientTemp = parseFloat(wizardFactor.controlValue);
                                            this.table271model.UseStandard = wizardFactor.useDefaultValue;
                                            if (this.drBunchingType === 1) {
                                                $('#inputUseStandardAir1').val(wizardFactor.controlValue).trigger('change');
                                                $('#drTable271UseStandardAir1').val(wizardFactor.controlValue).trigger('change');
                                            }
                                            else {
                                                $('#inputUseStandardAir2').val(wizardFactor.controlValue).trigger('change');
                                                $('#drTable271UseStandardAir2').val(wizardFactor.controlValue).trigger('change');
                                            }
                                            if (this.cableCalculationStandard == "2") {
                                                $('#inputUseStandard').val(wizardFactor.controlValue).trigger('change');
                                                $('#drTable271UseStandard').val(wizardFactor.controlValue).trigger('change');
                                            }
                                        }
                                        else if (!this.table271model.UseStandard && wizardFactor.controlId == 2) {/* control 2 */
                                            this.table271model.ConductorTemp = parseInt(wizardFactor.controlValue);
                                            // if (this.drBunchingType === 1)
                                            //     $('#drTable271ConductorTempAir1').val(wizardFactor.controlValue).trigger('change');
                                            // else
                                            //     $('#drTable271ConductorTempAir2').val(wizardFactor.controlValue).trigger('change');

                                            if (this.cableCalculationStandard == "2") {
                                                this.table271model.Insulation = parseInt(wizardFactor.controlValue);
                                                // $('#drTable271Insulation').val(wizardFactor.controlValue).trigger('change');
                                            }
                                        }
                                        if (wizardFactor.isLastControl) {
                                            this.GetTable271Dr(null);
                                            //this.table271model.drf = parseFloat(wizardFactor.overallWizardFactor);
                                        }
                                    }
                                    else if (wizardFactor.installationType === 2 && callby === 'depth of burial') {/* In ground */
                                        //this.table28model.Buried = wizardFactor.buriedType;
                                        if (wizardFactor.controlId == 1) {/* control 1 */
                                            this.table28model.Depth = parseFloat(wizardFactor.controlValue);
                                            this.table28model.UseStandard = wizardFactor.useDefaultValue;
                                            this.table28model.Buried = wizardFactor.buriedType;
                                            $('#inputDepth').val(wizardFactor.controlValue).trigger('change');
                                        }
                                        else if (!this.table28model.UseStandard && this.table28model.Buried == 1 && wizardFactor.controlId == 2) {/* control 2 */
                                            this.table28model.ConductorSize = parseInt(wizardFactor.controlValue);
                                            // $('#drTable28CondutorSize').val(wizardFactor.controlValue).trigger('change');
                                        }
                                        if (wizardFactor.isLastControl) {
                                            this.table28model.Core = this.drBunchingCore;
                                            this.GetTable28Dr(null);
                                            //this.table28model.drf = parseFloat(wizardFactor.overallWizardFactor);
                                        }
                                    }
                                }
                                else if (stepId === 4 && wizardFactor.installationType === 2 && (callby === 'soil temprature' || callby === 'soil temperature')) {/* In ground */
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table271model.AbientTemp = parseFloat(wizardFactor.controlValue);
                                        this.table271model.UseStandard = wizardFactor.useDefaultValue;
                                        $('#inputUseStandardSoil').val(wizardFactor.controlValue).trigger('change');
                                        $('#drTable271UseStandardSoil').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    else if (!this.table271model.UseStandard && wizardFactor.controlId == 2) {/* control 2 */
                                        if (this.cableCalculationStandard == "2") {
                                            this.table271model.Insulation = parseInt(wizardFactor.controlValue);
                                            // $('#drTable271Insulation').val(wizardFactor.controlValue).trigger('change');
                                        }
                                        else {
                                            this.table271model.ConductorTemp = parseInt(wizardFactor.controlValue);
                                            // $('#drTable271ConductorTempSoil').val(wizardFactor.controlValue).trigger('change');
                                        }
                                    }
                                    if (wizardFactor.isLastControl) {
                                        this.GetTable271Dr(null);
                                        //this.table271model.drf = parseFloat(wizardFactor.overallWizardFactor);
                                    }
                                }
                                break;
                            case 3:
                                if (wizardFactor.installationType === 2 && callby === 'soil resistivity') {/* In ground */
                                    if (wizardFactor.controlId == 1) {/* control 1 */
                                        this.table29model.Resitivity = parseFloat(wizardFactor.controlValue);
                                        this.table29model.UseStandard = wizardFactor.useDefaultValue;
                                        $('#inputUseStandardSoil').val(wizardFactor.controlValue).trigger('change');
                                        $('#drTable29UseStandard').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    else if (!this.table29model.UseStandard && wizardFactor.controlId == 2) {/* control 2 */
                                        this.table29model.Installation = parseInt(wizardFactor.controlValue);
                                        // $('#drTable29Installation').val(wizardFactor.controlValue).trigger('change');
                                    }
                                    if (wizardFactor.isLastControl) {
                                        this.GetTable29Dr(null);
                                        //this.table29model.drf = parseFloat(wizardFactor.overallWizardFactor);
                                    }
                                }
                                break;
                        }
                        if (wizardFactor.controlLabel == "Overall Derating Factor:") {
                            // $('#checkboxUseWizard').attr('checked', 'true');
                            this.UserWizard = true;
                            this.CableSize["isderatingwiz"] = "1";
                            if (wizardFactor.controlValue) {

                                $('#deratingFactor').val(parseFloat(wizardFactor.controlValue).toFixed(2));
                                this.CableSize['deratingFactor'] = parseFloat(wizardFactor.controlValue).toFixed(2);
                                this.UserWizard = true;
                                this.CableSize["isderatingwiz"] = "1";
                            }
                            this.updatecablesizeinarray();
                        }
                    }

                }
                // this.responseCount++;
                // if (this.requestCount == this.responseCount) {
                //     this.hideLoader();
                // }
                //this.hideLoader();
            });
        }
    }

    deleteWizardFactorValues(nodeId: string, isDeleteAllForCalculation: boolean = false) {
        this._wizardService.deleteNetworkWizardFactors(nodeId, this.newCalculationId, this.drInstallType, isDeleteAllForCalculation).subscribe(result => {
            this.UserWizard = false;
            this.CableSize["isderatingwiz"] = "0";
            // $('#checkboxUseWizard').removeAttr('checked');
            //$('#checkboxUseWizard').attr('checked', 'false');
        });
    }
    deleteProtectionData(nodeId: string, isDeleteAllForCalculation: boolean = false) {
        this._wizardService.deleteNetworkProtectionData(nodeId, this.newCalculationId, isDeleteAllForCalculation).subscribe(result => {
        });
    }
    loadPreviousValues() {
        this.setWizardFactorValues();
    }

    resetWizardControlValuesOnInstallationChange(newImageName: string) {
        var isInstallTypeChanged = false;

        if (newImageName === 'buried direct' && this.table25model.InstallType != 1) {
            this.table25model.InstallType = 1;
            this.table25model.Arrangement = 1;
            this.table28model.Buried = 1;
            this.table28model.Core = 1;
            this.table25model.CableType = this.drBunchingCore;
            isInstallTypeChanged = true;
            if (this.table25model.CableType == 1) {
                this.table25ArrangementValues = [
                    { "name": "Touching trefoil", "value": 1 },
                    { "name": "Touching flat", "value": 2 },
                    { "name": "Spaced 0.15 m", "value": 3 },
                    { "name": "Spaced 0.30 m", "value": 4 },
                    { "name": "Spaced 0.45 m", "value": 5 },
                    { "name": "Spaced 0.60 m", "value": 6 },
                ];
                this.table25ArrangementValuesBS = [
                    { "name": "Nil (cables touching)", "value": 1 },
                    { "name": "One cable diameter", "value": 2 },
                    { "name": "Spaced 0.125 m", "value": 3 },
                    { "name": "Spaced 0.25 m", "value": 4 },
                    { "name": "Spaced 0.5 m", "value": 5 },
                ];
            }
            else {
                this.table25ArrangementValues = [
                    { "name": "Touching", "value": 1 },
                    { "name": "Spaced 0.15 m", "value": 3 },
                    { "name": "Spaced 0.30 m", "value": 4 },
                    { "name": "Spaced 0.45 m", "value": 5 },
                    { "name": "Spaced 0.60 m", "value": 6 },
                ];
                this.table25ArrangementValuesBS = [
                    { "name": "Nil (ducts touching)", "value": 1 },
                    { "name": "Spaced 0.25 m", "value": 4 },
                    { "name": "Spaced 0.5 m", "value": 5 },
                    { "name": "Spaced 1.0 m", "value": 6 },
                ];
            }
        }
        else if (newImageName === 'buried enclosure separate' && this.table25model.InstallType != 2) {
            this.table25model.InstallType = 2;
            this.table25model.Arrangement = 1;
            this.table28model.Buried = 2;
            this.table28model.Core = this.drBunchingCore;
            isInstallTypeChanged = true;
            this.table25ArrangementValues = [
                { "name": "Touching", "value": 1 },
                { "name": "Spaced 0.45 m", "value": 5 },
                { "name": "Spaced 0.60 m", "value": 6 },
            ];
        }
        else if ((newImageName === 'buried enclosure combined' || newImageName === 'buried enclosure') && this.table25model.InstallType != 3) {
            this.table25model.InstallType = 3;
            this.table25model.Arrangement = 1;
            this.table28model.Buried = 2;
            this.table28model.Core = this.drBunchingCore;
            isInstallTypeChanged = true;
            this.table25ArrangementValues = [
                { "name": "Touching", "value": 1 },
                { "name": "Spaced 0.30 m", "value": 4 },
                { "name": "Spaced 0.45 m", "value": 5 },
                { "name": "Spaced 0.60 m", "value": 6 },
            ];
        }
        if (isInstallTypeChanged) {
            this.setTable25Values(this.table25model.Arrangement);
            this.GetTable28Dr(null);
        }
    }

    ShowResultsCalculationByColumn(rowsData, columnCount, colVal, firstIndex, lastIndex) {

        var startTime = performance.now();

        if (colVal === 3) {
            let generatorRows = rowsData;
            let noOfColumn = columnCount;
            let resultsSectionArray = [];
            let rowsArrayData = [];
            this.SupplyResultsArray = [];
            let rowHeader = generatorRows[0].Val;
            for (let i = noOfColumn + 1; i < generatorRows.length; i++) {

                if (i % noOfColumn === 0 && generatorRows[i].Val !== '' && generatorRows[i + 1].Val === '' && generatorRows[i + 2].Val === '') {

                    let generatorObj = {
                        "Header": rowHeader,
                        "ResultsData": resultsSectionArray
                    }

                    this.SupplyResultsArray.push(generatorObj);
                    rowHeader = generatorRows[i].Val;
                    resultsSectionArray = [];
                }
                else {
                    if (i % noOfColumn === 1) {
                        rowsArrayData.push(generatorRows[i]);
                    }
                    else if (i % noOfColumn === 2) {
                        rowsArrayData.push(generatorRows[i]);
                        resultsSectionArray.push(rowsArrayData);
                        rowsArrayData = [];
                    }
                }
            }

            let generatorObj = {
                "Header": rowHeader,
                "ResultsData": resultsSectionArray
            }

            this.SupplyResultsArray.push(generatorObj);
            return this.SupplyResultsArray;
        }

        //Power Factor and Switchboards
        if (colVal === 4) {

            let factorRows = rowsData;
            let resultsSectionArray = [];
            let childrenArray = [];
            let rowsArrayData = [];
            let firstRowIndex = firstIndex + 6;
            this.SupplyResultsArray = [];
            let noOfColumn = columnCount;
            let powerFactorJson = factorRows[firstIndex + 1].Val;

            for (let i = firstRowIndex; i <= lastIndex; i++) {

                if (i % noOfColumn === 1 && factorRows[i].Val !== '' && factorRows[i + 3].Val === '') {
                    let generatorObj = {
                        "SubHeading": powerFactorJson,
                        "ResultsData": resultsSectionArray
                    }
                    childrenArray.push(generatorObj);
                    powerFactorJson = factorRows[i].Val;
                    resultsSectionArray = [];
                    i = i + 4;
                }
                else {
                    if (i % noOfColumn === 2) {
                        rowsArrayData.push(factorRows[i]);
                    }
                    else if (i % noOfColumn === 3) {
                        rowsArrayData.push(factorRows[i]);
                        resultsSectionArray.push(rowsArrayData);
                        rowsArrayData = [];
                    }
                }
            }
            let generatorObj = {
                "SubHeading": powerFactorJson,
                "ResultsData": resultsSectionArray
            }
            childrenArray.push(generatorObj);
            let generatorObj1 = {
                "Header": '',
                "HeaderData": childrenArray
            }
            this.SupplyResultsArray.push(generatorObj1)

            return this.SupplyResultsArray;
        }

        //Power Station
        if (colVal === 5) {
            let factorRows = rowsData;
            let noOfColumn = columnCount;
            let resultsSectionArray = [];
            let rowsArrayData = [];
            let columnArray = [];
            let secondChildRow = [];
            let secondChildColumn = [];
            this.SupplyResultsArray = [];
            let rowHeader = factorRows[0].Val;
            let powerFactorJson = factorRows[1].Val;
            for (let i = noOfColumn + 1; i < factorRows.length; i++) {
                if (i % noOfColumn === 0 && factorRows[i].Val !== '') {
                    let obj1 = {
                        'isChild': true,
                        'Header': powerFactorJson,
                        'childrenNodes': secondChildRow
                    }

                    rowsArrayData.push(obj1);
                    secondChildRow = [];
                    secondChildColumn = [];
                    powerFactorJson = factorRows[i].Val;
                    i = i + 5;
                }
                else {
                    //Remainder 1
                    if (i % noOfColumn === 1 && factorRows[i + 1].Val === '' && factorRows[i - 1].Val === '') {
                        if (factorRows[i + 2].Val != '') {
                            columnArray.push(factorRows[i]);
                            columnArray.push(factorRows[i + 2]);
                            resultsSectionArray.push(columnArray);
                            columnArray = [];
                            i = i + 2;
                        }
                        else {
                            if (factorRows[i].Val === 'Transformer') {
                                if (resultsSectionArray.length == 2) {
                                    let obj2 = {
                                        'isChild': true,
                                        'Header': rowHeader,
                                        'childrenNodes': resultsSectionArray
                                    }
                                    rowsArrayData.push(obj2);
                                }
                                let obj1 = {
                                    'isChild': true,
                                    'Header': powerFactorJson,
                                    'childrenNodes': secondChildRow
                                }
                                rowsArrayData.push(obj1);
                            }
                            powerFactorJson = factorRows[i].Val;
                            secondChildRow = [];
                            secondChildColumn = [];
                            i = i + 4;
                        }
                    }
                    // Remainder 2
                    else if (i % noOfColumn === 2 && factorRows[i].Val !== '') {
                        secondChildColumn.push(factorRows[i]);
                    }
                    else if (i % noOfColumn === 3 && factorRows[i].Val !== '') {
                        secondChildColumn.push(factorRows[i]);
                        secondChildRow.push(secondChildColumn);
                        secondChildColumn = [];
                    }

                }

            }
            let obj1 = {
                'isChild': true,
                'Header': powerFactorJson,
                'childrenNodes': secondChildRow
            }
            rowsArrayData.push(obj1);

            let generatorObj = {
                "MainHeading": '',
                "ResultsData": rowsArrayData
            }
            this.SupplyResultsArray.push(generatorObj);
            return this.SupplyResultsArray;
        }

        //Cable Size Calc
        if (colVal === 7) {
            let cableData = rowsData;
            let mainNodesArray = [];
            let mainParentNode;
            let subParentNode;
            let subParentNode1;
            let subParentNodeValue;
            let subParentNode1Value;
            let count = 0;
            let CableSizeHeadingArray = [];
            let thirdLevelRows = [];

            let mainCableCalcArray = [];
            let resultsSectionArray = [];
            let rowsArrayData = [];
            let thirdLevelData = [];
            let nodesDataArray = [];
            let secondChildRow = [];
            let secondChildColumn = [];
            this.SupplyResultsArray = [];
            for (let i = 0; i < cableData.length; i++) {
                for (let j = 0; j < columnCount; j++) {
                    if (j == 2 && cableData[i][j].Val != '') {

                        if (count == 0) {
                            mainParentNode = cableData[i][j].Val;
                        }
                        else {

                            if (mainCableCalcArray.length > 0) {
                                let obj1 = {
                                    "HeaderNodes": mainParentNode,
                                    "RowsData": mainCableCalcArray
                                }
                                CableSizeHeadingArray.push(obj1);
                                mainCableCalcArray = [];
                                secondChildRow = [];
                                rowsArrayData = [];
                                nodesDataArray = [];
                                mainParentNode = cableData[i][j].Val;
                            }
                        }
                        j = 7;
                    }
                    else if (j == 3 && cableData[i][j].Val != '') {

                        let k = j + 3;
                        if (i == cableData.length - 1) {

                            secondChildColumn.push(cableData[i][j])
                            secondChildColumn.push(cableData[i][k])
                            secondChildRow.push(secondChildColumn);
                            let obj1 = {
                                "isChild": "fifth",
                                "fifthNodesData": secondChildRow
                            }

                            nodesDataArray.push(obj1);
                        }
                        else {

                            if (cableData[i + 1][j + 1].Val != '' && cableData[i + 1][j - 1].Val == '') {

                                subParentNode = cableData[i][j].Val;
                                subParentNodeValue = cableData[i][k].Val;
                                if (secondChildRow.length > 0) {
                                    let obj1 = {
                                        "isChild": "fifth",
                                        "fifthNodesData": secondChildRow
                                    }
                                    rowsArrayData.push(obj1);
                                    secondChildRow = [];
                                }
                            }
                            else {

                                secondChildColumn.push(cableData[i][j])
                                secondChildColumn.push(cableData[i][k])
                                secondChildRow.push(secondChildColumn);
                                secondChildColumn = [];
                            }
                        }
                        j = 7;
                    }

                    else if (j == 4 && cableData[i][j].Val != '') {

                        let k = j + 2;
                        //If object is parent Node
                        if (cableData[i + 1][j + 1].Val != '' && cableData[i + 1][2].Val == '' && cableData[i + 1][3].Val == '' && cableData[i + 1][j].Val == '') {

                            if (mainNodesArray.length > 0) {
                                let obj = { "isChild": "third", "thirdNodesData": mainNodesArray }
                                resultsSectionArray.push(obj);
                                mainNodesArray = [];
                            }
                            subParentNode1 = cableData[i][j].Val;
                            subParentNode1Value = cableData[i][k].Val;
                        }
                        //If object is not parent node but a child of above parent node
                        else if (cableData[i + 1][j + 1].Val == '' && cableData[i + 1][2].Val == '' && cableData[i + 1][3].Val == '' && cableData[i + 1][j].Val != '') {

                            let objArray = [];
                            objArray.push(cableData[i][j]);
                            objArray.push(cableData[i][k]);
                            mainNodesArray.push(objArray);
                        }

                        else if (cableData[i + 1][j + 1].Val == '' && cableData[i + 1][2].Val == '' && cableData[i + 1][3].Val != '' && cableData[i + 1][j].Val == '') {

                            if (rowsArrayData.length > 0) {
                                nodesDataArray = rowsArrayData;
                            }

                            let objArray = [];
                            objArray.push(cableData[i][j]);
                            objArray.push(cableData[i][k]);
                            mainNodesArray.push(objArray);
                            let obj1 = {
                                "isChild": "fourth",
                                "SubParent": subParentNode,
                                "SubParentValue": subParentNodeValue,
                                "FourthData": mainNodesArray
                            }
                            nodesDataArray.push(obj1);
                            mainNodesArray = [];
                        }

                        // Last Hierarchy to insert Nodes!!!
                        else if (cableData[i + 1][j + 1].Val == '' && cableData[i + 1][2].Val != '' && cableData[i + 1][3].Val == '' && cableData[i + 1][j].Val == '') {
                            if (nodesDataArray.length > 0) {
                                let data = [];
                                mainCableCalcArray = [];
                                data.push(cableData[i][j]);
                                data.push(cableData[i][k]);
                                mainNodesArray.push(data);
                                let obj = {
                                    "isChild": "fourth",
                                    "SubParent": subParentNode,
                                    "SubParentValue": subParentNodeValue,
                                    "FourthData": mainNodesArray
                                }
                                nodesDataArray.push(obj);
                                mainCableCalcArray = nodesDataArray;
                                nodesDataArray = [];
                                mainNodesArray = [];
                            }
                            else {

                                let secondLevelData = [];
                                secondLevelData.push(cableData[i][j]);
                                secondLevelData.push(cableData[i][k]);

                                thirdLevelData = [];
                                if (resultsSectionArray.length > 0) {
                                    thirdLevelData = resultsSectionArray;

                                }
                                resultsSectionArray = [];
                                if (secondLevelData.length > 0) {
                                    let objData = [];
                                    objData.push(secondLevelData);
                                    let obj = { "isChild": "first", "FirstRowData": objData }
                                    thirdLevelData.push(obj);
                                }

                                if (rowsArrayData.length > 0) {
                                    rowsArrayData.forEach(element => {
                                        mainCableCalcArray.push(element);
                                    });
                                }
                                if (thirdLevelData.length > 0) {
                                    let obj1 = {
                                        "isChild": "third",
                                        "SubParentName": subParentNode,
                                        "SubParentNodeValue": subParentNodeValue,
                                        "ThirdData": thirdLevelData
                                    }
                                    mainCableCalcArray.push(obj1)
                                }

                                thirdLevelData = [];
                                rowsArrayData = [];
                                subParentNode = '';
                                subParentNodeValue = '';
                            }
                        }
                        j = 7;
                    }

                    else if (j == 5 && cableData[i][j].Val != '') {
                        let k = j + 1;
                        if (cableData[i + 1][j].Val !== '') {

                            let thirdLevelData = [];
                            thirdLevelData.push(cableData[i][j])
                            thirdLevelData.push(cableData[i][k])
                            //Row
                            thirdLevelRows.push(thirdLevelData);
                        }
                        else if (cableData[i + 1][j].Val === '' && cableData[i + 1][j - 1].Val != '') {

                            let thirdLevelData = [];
                            thirdLevelData.push(cableData[i][j]);
                            thirdLevelData.push(cableData[i][k]);
                            //Rows
                            thirdLevelRows.push(thirdLevelData);
                            let obj = {
                                "isChild": "fourth",
                                "LastParent": subParentNode1,
                                "LastParentValue": subParentNode1Value,
                                "FourthData": thirdLevelRows
                            }
                            resultsSectionArray.push(obj);
                            thirdLevelRows = [];
                            subParentNode1 = '';
                            subParentNode1Value = '';
                        }

                        else if (cableData[i + 1][j].Val === '' && cableData[i + 1][2].Val != '') {


                            let thirdLevelData = [];
                            thirdLevelData.push(cableData[i][j]);
                            thirdLevelData.push(cableData[i][k]);
                            //Rows
                            thirdLevelRows.push(thirdLevelData);
                            let obj = {
                                "isChild": "fourth",
                                "LastParent": subParentNode1,
                                "LastParentValue": subParentNode1Value,
                                "FourthData": thirdLevelRows
                            }
                            resultsSectionArray.push(obj);
                            thirdLevelRows = [];
                            subParentNode1 = '';
                            subParentNode1Value = '';
                            if (cableData[i + 1][2].Val != '') {
                                mainCableCalcArray = [];
                                let obj = {
                                    "isChild": "second",
                                    "ParentName": subParentNode,
                                    "ParentValue": subParentNodeValue,
                                    "SecondNodesData": resultsSectionArray
                                }

                                mainCableCalcArray = rowsArrayData;
                                mainCableCalcArray.push(obj);
                                resultsSectionArray = [];
                                secondChildRow = [];
                                rowsArrayData = [];
                            }
                        }
                        count = 1;
                        j = 7;
                    }
                }

            }

            if (nodesDataArray.length > 0) {
                let obj2 = {
                    "HeaderNodes": mainParentNode,
                    "RowsData": nodesDataArray
                }

                CableSizeHeadingArray.push(obj2);
            }

            this.SupplyResultsArray = CableSizeHeadingArray
            return this.SupplyResultsArray;

        }

        //For Results Section Tables
        if (colVal == 0) {
            let noOfColumn = columnCount;
            let faultArray = [];
            let outputResultsData = [];
            let outputRowsData = rowsData;
            for (let i = noOfColumn; i < outputRowsData.length; i++) {
                if (i != noOfColumn && i % noOfColumn === 0) {
                    outputResultsData.push(faultArray);
                    faultArray = [];
                    faultArray.push(outputRowsData[i]);
                }
                else {
                    faultArray.push(outputRowsData[i]);
                }
            }
            outputResultsData.push(faultArray);
            return outputResultsData;
        }

        if (colVal == 1) {

            let noOfColumn = columnCount;
            let faultArray = [];
            let outputResultsData = [];
            let outputRowsData = rowsData;
            for (let i = noOfColumn; i < outputRowsData.length; i++) {
                if (i != noOfColumn && i % noOfColumn === 0) {
                    if (faultArray.length > 0) {
                        if (outputRowsData[i].Val.includes('From source to')) {
                            let object1 = { "isHeading": "false", "VoltageData": faultArray };
                            faultArray = [];
                            outputResultsData.push(object1);
                            //If heading
                            faultArray.push(outputRowsData[i]);
                            let object2 = { "isHeading": "true", "VoltageData": faultArray };
                            faultArray = [];
                            outputResultsData.push(object2);
                            i = i + 9;
                        }
                        else {
                            let object1 = { "isHeading": "false", "VoltageData": faultArray };
                            outputResultsData.push(object1);
                            faultArray = [];
                            faultArray.push(outputRowsData[i]);
                        }
                    }
                    else {
                        if (outputRowsData[i].Val.includes('From source to')) {
                            faultArray.push(outputRowsData[i]);
                            let object1 = { "isHeading": "true", "VoltageData": faultArray };
                            faultArray = [];
                            outputResultsData.push(object1);
                            i = i + 9;
                        }
                        else {
                            faultArray.push(outputRowsData[i]);
                        }
                    }
                }
                else {
                    if (outputRowsData[i].Val.includes('From source to')) {
                        faultArray.push(outputRowsData[i]);
                        let object1 = { "isHeading": "true", "VoltageData": faultArray };
                        faultArray = [];
                        outputResultsData.push(object1);
                        i = i + 9;
                    }
                    else {
                        faultArray.push(outputRowsData[i]);
                    }
                }
            }


            let rows = [];
            rows.push(faultArray);
            let object1 = { "isHeading": "false", "VoltageData": faultArray };
            faultArray = [];
            outputResultsData.push(object1);

            return outputResultsData;
        }
        if (colVal == 111) {

            let noOfColumn = columnCount;
            let faultArray = [];
            let outputResultsData = [];
            let outputRowsData = rowsData;
            for (let i = noOfColumn; i < outputRowsData.length; i++) {
                if (i != noOfColumn && i % noOfColumn === 0) {
                    if (faultArray.length > 0) {
                        if (outputRowsData[i].Val.includes('-')) {
                            let object1 = { "isHeading": "false", "VoltageData": faultArray };
                            faultArray = [];
                            outputResultsData.push(object1);
                            //If heading
                            faultArray.push(outputRowsData[i]);
                            let object2 = { "isHeading": "true", "VoltageData": faultArray };
                            faultArray = [];
                            outputResultsData.push(object2);
                            i = i + 9;
                        }
                        else {
                            let object1 = { "isHeading": "false", "VoltageData": faultArray };
                            outputResultsData.push(object1);
                            faultArray = [];
                            faultArray.push(outputRowsData[i]);
                        }
                    }
                    else {
                        if (outputRowsData[i].Val.includes('-')) {
                            faultArray.push(outputRowsData[i]);
                            let object1 = { "isHeading": "true", "VoltageData": faultArray };
                            faultArray = [];
                            outputResultsData.push(object1);
                            i = i + 9;
                        }
                        else {
                            faultArray.push(outputRowsData[i]);
                        }
                    }
                }
                else {
                    if (outputRowsData[i].Val.includes('-')) {
                        faultArray.push(outputRowsData[i]);
                        let object1 = { "isHeading": "true", "VoltageData": faultArray };
                        faultArray = [];
                        outputResultsData.push(object1);
                        i = i + 9;
                    }
                    else {
                        faultArray.push(outputRowsData[i]);
                    }
                }
            }


            let rows = [];
            rows.push(faultArray);
            let object1 = { "isHeading": "false", "VoltageData": faultArray };
            faultArray = [];
            outputResultsData.push(object1);

            return outputResultsData;
        }
        var endTime = performance.now();
        var totalTime = endTime - startTime; // time took to run in milliseconds
    }
    GetArcandFaultCurrent(factorResults, objectcode) {
        var arccurrent: any;
        var faultcurrent: any;
        // var factorResults = response.Maximumdemandofswitchboard;
        var noOfColumn = factorResults["Array"].Dimsize[1];
        var rows = factorResults["Array"].String;
        let factorRows = rows;
        this.switchboardResultsArray.length = 0;
        if (noOfColumn > 0) {
            if (factorRows.length > noOfColumn) {
                let indexes = factorRows.findIndex(x => x.Val === objectcode);
                if (indexes >= 0 && indexes != -1 && indexes != undefined) {
                    let lastIndexes = indexes + 55;
                    arccurrent = rows[lastIndexes];
                    faultcurrent = rows[lastIndexes - 8];
                }
            }
        }
        return { ac: arccurrent?.Val, fc: faultcurrent?.Val };
    }

    getArcCurrentForCbOrLoad(factorResults, cablesSizeData) {
        var noOfColumn = factorResults["Array"].Dimsize[1];
        var rows = factorResults["Array"].String;
        let cablesizeRowsArray = [];
        let cablesizeColumnArray = [];
        let factorRows = rows;
        let cableSizeResultsArray = [];
        //this.cableSizeResultsArray.length = 0;
        var arcCurrent: any;
        var faultCurrent: any;

        if (noOfColumn > 0) {
            if (factorRows.length > noOfColumn) {
                // this.cableSizeSwitchboardData = response.Cablesizecalculationsresult;                

                if (cablesSizeData != null && cablesSizeData != undefined) {
                    let firstIndex = 0;
                    let lastIndex = 0;
                    let firstIndex1 = 0;
                    let lastIndex1 = 0;
                    let ID = parseInt(cablesSizeData.Node_Id);
                    let capacitorName = "C" + (ID - 1);

                    for (let i = 0; i < factorRows.length; i++) {
                        if (factorRows[i].Val === capacitorName && factorRows[i + 1].Val === "Summary") {
                            firstIndex1 = i;
                            lastIndex1 = firstIndex1 + 76;
                            break;
                        }
                    }


                    for (let i = lastIndex1 + 1; i < factorRows.length; i++) {

                        if (factorRows[i].Val === capacitorName && factorRows[i + 1].Val === "Details") {
                            firstIndex = i;
                            lastIndex = firstIndex + 265;
                            break;
                        }
                    }

                    if (lastIndex1 != 0) {

                        let cablesizeSummary = [];
                        let cablesizeSummaryData = [];
                        for (let j = firstIndex1; j <= lastIndex1; j++) {

                            if (cablesizeSummary.length === noOfColumn - 1) {

                                cablesizeSummary.push(factorRows[j])
                                cablesizeSummaryData.push(cablesizeSummary);
                                cablesizeSummary = [];
                            }
                            else {

                                cablesizeSummary.push(factorRows[j]);
                            }
                        }
                    }

                    if (firstIndex != 0 && lastIndex != 0) {
                        for (let i = firstIndex; i <= lastIndex; i++) {
                            if (cablesizeColumnArray.length == noOfColumn - 1) {
                                cablesizeColumnArray.push(factorRows[i])
                                cablesizeRowsArray.push(cablesizeColumnArray);
                                cablesizeColumnArray = [];
                            }
                            else {
                                cablesizeColumnArray.push(factorRows[i])
                            }
                        }

                        let resultData = this.ShowResultsCalculationByColumn(cablesizeRowsArray, noOfColumn, 7, 0, 0);
                        cableSizeResultsArray = resultData;
                        if (cableSizeResultsArray && cableSizeResultsArray.length > 0) {
                            arcCurrent = cableSizeResultsArray[2].RowsData[0].FourthData[2][1].Val;
                            faultCurrent = cableSizeResultsArray[2].RowsData[0].FourthData[1][1].Val;
                        }
                    }
                }

            }
        }

        return { ac: arcCurrent, fc: faultCurrent };
    }

    getDamageCurve(factorResults, cablesSizeData) {
        var noOfColumn = factorResults["Array"].Dimsize[1];
        var rows = factorResults["Array"].String;
        let timesData = [];
        let currentData = [];
        let active_res = '';
        let active_NoOf_circuit = '';
        let factorRows = rows;

        if (noOfColumn > 0) {
            if (factorRows.length > noOfColumn) {
                // this.cableSizeSwitchboardData = response.Cablesizecalculationsresult;                

                if (cablesSizeData != null && cablesSizeData != undefined) {
                    let firstIndex = 0;
                    let lastIndex = 0;
                    let firstIndex1 = 0;
                    let lastIndex1 = 0;
                    let firstIndex99 = 0;
                    let lastIndex99 = 0;
                    let ID = parseInt(cablesSizeData.Node_Id);
                    let capacitorName = "C" + (ID - 1);

                    for (let i = 0; i < factorRows.length; i++) {
                        if (factorRows[i].Val === capacitorName && factorRows[i + 1].Val === "Summary") {
                            firstIndex99 = i;
                            lastIndex99 = firstIndex99 + 76;
                            break;
                        }
                    }

                    for (let i = 0; i < factorRows.length; i++) {
                        if (factorRows[i].Val === capacitorName && factorRows[i + 1].Val === "Time (s)") {
                            firstIndex1 = i;
                            lastIndex1 = firstIndex1 + 7;
                            firstIndex = lastIndex1 + 1;
                            lastIndex = firstIndex + 5;
                            break;
                        }
                    }

                    if (lastIndex1 != 0) {
                        if (factorRows[firstIndex1 + 2])
                            timesData = factorRows[firstIndex1 + 2].Val.toString().trimEnd("\n").split("\t");
                    }

                    if (firstIndex != 0 && lastIndex != 0) {
                        if (factorRows[firstIndex + 1])
                            currentData = factorRows[firstIndex + 1].Val.toString().trimEnd("\n").split("\t");
                    }

                    if (lastIndex99 != 0) {

                        let cablesizeSummary = [];
                        let cablesizeSummaryData = [];
                        for (let j = firstIndex99; j <= lastIndex99; j++) {

                            if (cablesizeSummary.length === noOfColumn - 1) {

                                cablesizeSummary.push(factorRows[j])
                                cablesizeSummaryData.push(cablesizeSummary);
                                cablesizeSummary = [];
                            }
                            else {

                                cablesizeSummary.push(factorRows[j]);
                            }
                        }

                        if (cablesizeSummaryData.length > 0) {

                            active_res = cablesizeSummaryData[1][3].Val;
                            active_NoOf_circuit = cablesizeSummaryData[2][3].Val;

                        }
                    }
                }

            }
        }

        // return { ts: timesData.map(val => Math.log10(parseFloat(val))), cr: currentData.map(val => Math.log10(parseFloat(val))) };
        return { ts: timesData, cr: currentData, annot: 'Cable ' + active_NoOf_circuit + ' x ' + active_res };
    }

    getTransformerDamageCurve(factorResults, cablesSizeData) {
        var noOfColumn = factorResults["Array"].Dimsize[1];
        var rows = factorResults["Array"].String;
        let timesData = [];
        let currentData = [];
        let No_Of_Parallel_Units = '';
        let Rating_Per_Transformer = '';
        let factorRows = rows;

        if (noOfColumn > 0) {
            if (factorRows.length > noOfColumn) {
                // this.cableSizeSwitchboardData = response.Cablesizecalculationsresult;                

                if (cablesSizeData != null && cablesSizeData != undefined) {
                    let firstIndex = 0;
                    let lastIndex = 0;
                    let firstIndex1 = 0;
                    let lastIndex1 = 0;
                    let ID = parseInt(cablesSizeData.Node_Id);
                    let capacitorName = "C" + (ID - 1);

                    let occurrence = 0;
                    for (let i = 0; i < factorRows.length; i++) {
                        if (factorRows[i].Val === capacitorName && factorRows[i + 1].Val === "Time (s)") {
                            occurrence += 1;
                            if (occurrence == 2) {
                                firstIndex1 = i;
                                lastIndex1 = firstIndex1 + 7;
                                firstIndex = lastIndex1 + 1;
                                lastIndex = firstIndex + 5;
                                break;
                            }
                        }
                    }

                    if (lastIndex1 != 0) {
                        if (factorRows[firstIndex1 + 2])
                            timesData = factorRows[firstIndex1 + 2].Val.toString().trimEnd("\n").split("\t");
                    }

                    if (firstIndex != 0 && lastIndex != 0) {
                        if (factorRows[firstIndex + 1])
                            currentData = factorRows[firstIndex + 1].Val.toString().trimEnd("\n").split("\t");
                    }
                }
            }
        }

        if (this.networksupplytype == '4')//Power Station
        {
            No_Of_Parallel_Units = this.powerStationResultsArray[0].ResultsData[0].childrenNodes[0][1].Val;
            Rating_Per_Transformer = this.powerStationResultsArray[0].ResultsData[2].childrenNodes[0][1].Val;
        }
        else if (this.networksupplytype == '2')//Transformer
        {
            No_Of_Parallel_Units = this.transformerResultsArray[0].MainRecords[0].ResultsData[0][1].Val;
            Rating_Per_Transformer = this.transformerResultsArray[0].MainRecords[0].ResultsData[2][1].Val;
        }
        // return { ts: timesData.map(val => Math.log10(parseFloat(val))), cr: currentData.map(val => Math.log10(parseFloat(val))) };
        this.CalculationReportInput.damagetime = JSON.stringify(timesData);
        this.CalculationReportInput.damagecurrent = JSON.stringify(currentData);

        return { ts: timesData, cr: currentData, annot: 'Transformer ' + No_Of_Parallel_Units + ' x ' + Rating_Per_Transformer };
    }

    //Get all results Data
    GetAllResultSectionValues(response) {
        var startTime = performance.now();

        //Protective Device Source 
        if (response.protectiveDeviceSource != null && response.protectiveDeviceSource != undefined && response.protectiveDeviceSource.Array) {
            var maxLevel = response.protectiveDeviceSource;
            var noOfColumn = maxLevel["Array"].Dimsize[1];
            var rows = maxLevel["Array"].String;
            this.protectiveDeviceSourceArray = [];
            if (noOfColumn > 0) {
                if (rows.length > noOfColumn) {
                    let maxDemandData = this.ShowResultsCalculationByColumn(rows, noOfColumn, 0, 0, 0);
                    this.protectiveDeviceSourceArray = maxDemandData;
                }
            }
        }

        //Protective Device Source 
        if (response.ArcFlashTableResult != null && response.ArcFlashTableResult != undefined && response.ArcFlashTableResult.Array) {
            var maxLevel = response.ArcFlashTableResult;
            var noOfColumn = maxLevel["Array"].Dimsize[1];
            var rows = maxLevel["Array"].String;
            this.arcFlashResultArray = [];
            if (noOfColumn > 0) {
                if (rows.length > noOfColumn) {
                    let maxDemandData = this.ShowResultsCalculationByColumn(rows, noOfColumn, 0, 0, 0);
                    this.arcFlashResultArray = maxDemandData;


                    this.arcFlashResultHeader = [];
                    for (let i = 0; i < noOfColumn; i++) {
                        this.arcFlashResultHeader.push(rows[i]);
                    }

                }
            }
        }

        //Loads Schedule 
        if (response.loadschedule != null && response.loadschedule != undefined && response.loadschedule.Array) {
            var loadsLevel = response.loadschedule;
            var noOfColumn = loadsLevel["Array"].Dimsize[1];
            var rows = loadsLevel["Array"].String;
            this.loadScheduleArray = [];
            let loadColumns = [];
            let loadRows = [];
            let loadsRowsData = [];
            if (noOfColumn > 0) {
                if (rows.length > noOfColumn) {
                    loadColumns.push(rows[0]);
                    for (let j = 1; j < rows.length; j++) {
                        if (j % noOfColumn == 0) {
                            loadRows.push(loadColumns);
                            loadColumns = [];
                            loadColumns.push(rows[j]);
                        }
                        else {
                            loadColumns.push(rows[j]);
                        }
                    }
                    loadRows.push(loadColumns);

                    for (let i = 1; i < loadRows.length; i++) {
                        if ((loadRows[i][1].Val != '-' || loadRows[i][1].Val != 'L1 (A)') && loadRows[i][2].Val != '-' && loadRows[i][3].Val != '-' &&
                            loadRows[i][4].Val != '-' && loadRows[i][5].Val != '-' && loadRows[i][6].Val != '-' && loadRows[i][7].Val != '-') {
                            let objLoad = { "isHeading": "false", "loadsData": loadRows[i] }
                            loadsRowsData.push(objLoad);
                        }
                        else {
                            let objLoad = { "isHeading": "true", "loadsData": loadRows[i] }
                            loadsRowsData.push(objLoad);
                        }
                    }
                    this.loadScheduleArray = loadsRowsData;
                }
            }
        }

        //Fault Loop Impedance
        if (response.Faultloopimpedence != null && response.Faultloopimpedence != undefined && response.Faultloopimpedence.Array) {
            var Faultlevel = response.Faultloopimpedence;
            var noOfColumn = Faultlevel["Array"].Dimsize[1];
            var rows = Faultlevel["Array"].String;
            this.resultFaultImpedence = [];
            if (noOfColumn > 0) {
                if (rows.length > noOfColumn) {
                    let faultData = this.ShowResultsCalculationByColumn(rows, noOfColumn, 0, 0, 0);
                    this.resultFaultImpedence = faultData;
                }
            }
        }


        //Accumulated Voltage Drop
        if (response.Accumulatedvoltagedrop != null && response.Accumulatedvoltagedrop != undefined && response.Accumulatedvoltagedrop.Array) {
            var accumulatedVoltage = response.Accumulatedvoltagedrop;
            var noOfColumn = accumulatedVoltage["Array"].Dimsize[1];
            var rows = accumulatedVoltage["Array"].String;
            this.resultAccumulatedvoltage = [];
            if (noOfColumn > 0) {
                if (rows.length > noOfColumn) {

                    let accVoltageData = this.ShowResultsCalculationByColumn(rows, noOfColumn, 1, 0, 0);
                    this.resultAccumulatedvoltage = accVoltageData;
                }
            }
        }

        //Result Section Cable Data
        if (response.Cabledata != null && response.Cabledata != undefined && response.Cabledata.Array) {
            var CableLabels = response.Cabledata;
            var noOfColumn = CableLabels["Array"].Dimsize[1];
            var rows = CableLabels["Array"].String;
            this.resultSectionCableData = [];
            if (noOfColumn > 0) {
                if (rows.length > noOfColumn) {
                    let cableData = this.ShowResultsCalculationByColumn(rows, noOfColumn, 0, 0, 0);
                    this.resultSectionCableData = cableData;
                }
            }
        }

        //Result Section Fault Level
        if (response.Faultlevel != null && response.Faultlevel != undefined && response.Faultlevel.Array) {
            var Faultlevel = response.Faultlevel;
            var noOfColumn = Faultlevel["Array"].Dimsize[1];
            var rows = Faultlevel["Array"].String;
            this.resultFaultLevel = [];
            if (noOfColumn > 0) {
                if (rows.length > noOfColumn) {
                    let fault1Data = this.ShowResultsCalculationByColumn(rows, noOfColumn, 0, 0, 0);
                    this.resultFaultLevel = fault1Data;
                }
            }
        }

        //Max Demand Table
        // if (response.Maxdemand != null && response.Maxdemand != undefined) {
        //     var maxLevel = response.Maxdemand;
        //     var noOfColumn = maxLevel["Array"].Dimsize[1];
        //     var rows = maxLevel["Array"].String;
        //     this.resultMaxdemand = [];
        //     if (noOfColumn > 0) {
        //         if (rows.length > noOfColumn) {
        //             //this.(rows, noOfColumn, 1, 0, 0);
        //             let maxDemandData = this.ShowResultsCalculationByColumn(rows, noOfColumn, 1, 0, 0);
        //             this.resultMaxdemand = maxDemandData;
        //             debugger
        //         }
        //     }
        // }

        if (response.Maxdemand != null && response.Maxdemand != undefined && response.Maxdemand.Array) {
            var maxLevel = response.Maxdemand;
            var noOfColumn = maxLevel["Array"].Dimsize[1];
            var rows = maxLevel["Array"].String;
            this.resultMaxDemand = [];
            if (noOfColumn > 0) {
                if (rows.length > noOfColumn) {
                    let maxDemandData = this.ShowResultsCalculationByColumn(rows, noOfColumn, 0, 0, 0);
                    this.resultMaxDemand = maxDemandData;
                }
            }
        }
        //Power Factor Correction Table
        if (response.Powerfactorcorrection != null && response.Powerfactorcorrection != undefined && response.Powerfactorcorrection.Array) {
            var powerFactorLevel = response.Powerfactorcorrection;
            var noOfColumn = powerFactorLevel["Array"].Dimsize[1];
            var rows = powerFactorLevel["Array"].String;
            this.resultSectionPowerfactor = [];
            if (noOfColumn > 0) {
                if (rows.length > noOfColumn) {
                    let factorArray = this.ShowResultsCalculationByColumn(rows, noOfColumn, 0, 0, 0);
                    this.resultSectionPowerfactor = factorArray;
                }
            }
        }

        //Voltage Drop table
        if (response.Voltagedrop != null && response.Voltagedrop != undefined && response.Voltagedrop.Array) {
            var maxLevel = response.Voltagedrop;
            var noOfColumn = maxLevel["Array"].Dimsize[1];
            var rows = maxLevel["Array"].String;
            this.resultVoltageDrop = [];
            if (noOfColumn > 0) {
                if (rows.length > noOfColumn) {
                    let voltageDropData = this.ShowResultsCalculationByColumn(rows, noOfColumn, 0, 0, 0);
                    this.resultVoltageDrop = voltageDropData;
                }
            }
        }
        if (response.Maxdemandtableresult != null && response.Maxdemandtableresult != undefined && response.Maxdemandtableresult.Array) {
            var loadsLevel = response.Maxdemandtableresult;
            var noOfColumn = loadsLevel["Array"].Dimsize[1];
            var rows = loadsLevel["Array"].String;
            this.resultMaxDemandtable = [];
            let loadColumns = [];
            let loadRows = [];
            let loadsRowsData = [];
            if (noOfColumn > 0) {
                if (rows.length > noOfColumn) {
                    loadColumns.push(rows[0]);
                    for (let j = 1; j < rows.length; j++) {
                        if (j % noOfColumn == 0) {
                            loadRows.push(loadColumns);
                            loadColumns = [];
                            loadColumns.push(rows[j]);
                        }
                        else {
                            loadColumns.push(rows[j]);
                        }
                    }
                    loadRows.push(loadColumns);

                    for (let i = 1; i < loadRows.length; i++) {

                        let isHeader: string = "true";
                        for (let j = 1; j < loadRows[i].length; j++) {
                            if (loadRows[i][j].Val != '-' && isHeader == "true") {
                                isHeader = "false";
                                break;
                            }
                        }
                        let objLoad = { "isHeading": isHeader, "loadsData": loadRows[i] }
                        loadsRowsData.push(objLoad);
                        // if ((loadRows[i][1].Val != '-' || loadRows[i][1].Val != 'L1 (A)') && loadRows[i][2].Val != '-' && loadRows[i][3].Val != '-' &&
                        //     loadRows[i][4].Val != '-' && loadRows[i][5].Val != '-' && loadRows[i][6].Val != '-' && loadRows[i][7].Val != '-') {
                        //     let objLoad = { "isHeading": "false", "loadsData": loadRows[i] }
                        //     loadsRowsData.push(objLoad);
                        // }
                        // else {
                        //     let objLoad = { "isHeading": "true", "loadsData": loadRows[i] }
                        //     loadsRowsData.push(objLoad);
                        // }
                    }
                    this.resultMaxDemandtable = loadsRowsData;
                }
            }
        }

        //Generator Results Section 
        if (response.Generatorsizing != undefined && response.Generatorsizing.Array !== null && response.Generatorsizing["Array"].String.length > 0) {
            var generatorResults = response.Generatorsizing;
            var noOfColumn = generatorResults["Array"].Dimsize[1];
            var rows = generatorResults["Array"].String;
            this.generatorResultsArray.length = 0;
            if (noOfColumn > 0) {
                if (rows.length > noOfColumn) {
                    let generatorData = this.ShowResultsCalculationByColumn(rows, noOfColumn, 3, 0, 0);
                    let mainObjectDTO = { "MainHeading": "", "MainRecords": generatorData };
                    this.generatorResultsArray.push(mainObjectDTO);
                }
            }
        }

        //Transformer Results Section 
        if (response.Transformersizing != undefined && response.Transformersizing.Array !== null && response.Transformersizing["Array"].String.length > 0) {
            var transformerResults = response.Transformersizing;
            var noOfColumn = transformerResults["Array"].Dimsize[1];
            var rows = transformerResults["Array"].String;
            this.transformerResultsArray.length = 0;
            if (noOfColumn > 0) {
                if (rows.length > noOfColumn) {
                    let resultData = this.ShowResultsCalculationByColumn(rows, noOfColumn, 3, 0, 0);
                    let mainObjectDTO = { "MainHeading": "", "MainRecords": resultData };
                    this.transformerResultsArray.push(mainObjectDTO);
                }
            }
        }

        //Network feeder Results Section 
        if (response.Networkfeederresults && response.Networkfeederresults.Array !== null && response.Networkfeederresults["Array"].String.length > 0) {
            var feederResults = response.Networkfeederresults;
            var noOfColumn = feederResults["Array"].Dimsize[1];
            var rows = feederResults["Array"].String;
            this.networkFeederResultsArray.length = 0;
            if (noOfColumn > 0) {
                if (rows.length > noOfColumn) {
                    let resultData = this.ShowResultsCalculationByColumn(rows, noOfColumn, 3, 0, 0);
                    this.networkFeederResultsArray = resultData;
                }
            }
        }


        //Power Factor Results Section 
        if (response.Powerfactorcorrectionresults && response.Powerfactorcorrectionresults.Array !== null && response.Powerfactorcorrectionresults["Array"].String.length > 0) {
            var factorResults = response.Powerfactorcorrectionresults;
            var noOfColumn = factorResults["Array"].Dimsize[1];
            var rows = factorResults["Array"].String;
            let factorRows = rows;
            this.powerFactorResultsArray.length = 0;
            if (noOfColumn > 0) {
                if (factorRows.length > noOfColumn) {

                    let capacitors = sessionStorage.getItem("CapacitorNode");
                    let indexes = factorRows.findIndex(x => x.Val === capacitors);
                    if (indexes >= 0 && indexes != -1 && indexes != undefined) {
                        let lastIndexes = indexes + 83;

                        let powerFactorData = this.ShowResultsCalculationByColumn(rows, noOfColumn, 4, indexes, lastIndexes);
                        this.powerFactorResultsArray = powerFactorData;

                        let requiredCompensation = powerFactorData[0].HeaderData[0].ResultsData[0][1].Val;
                        let capacitorRating = powerFactorData[0].HeaderData[0].ResultsData[1][1].Val;
                        let powerFactorBeforeCorrection = powerFactorData[0].HeaderData[1].ResultsData[0][1].Val;
                        let powerFactorAfterCorrection = powerFactorData[0].HeaderData[2].ResultsData[0][1].Val;

                        if (powerFactorBeforeCorrection == '') {
                            this.powerFactorBeforeCorrection = powerFactorBeforeCorrection;
                        }
                        else {
                            this.powerFactorBeforeCorrection = powerFactorBeforeCorrection;
                        }

                        if (powerFactorAfterCorrection == '') {
                            this.powerFactorAfterCorrection = powerFactorAfterCorrection;
                        }
                        else {
                            this.powerFactorAfterCorrection = powerFactorAfterCorrection;
                        }

                        this.capacitorRating = parseFloat(capacitorRating);
                        this.requiredCompensation = parseFloat(requiredCompensation);


                        $('#NetworkCapacitorBank_Power_Factor_After_Correction').val(this.powerFactorAfterCorrection);
                        $('#NetworkCapacitorBank_Power_Factor_Before_Correction').val(this.powerFactorBeforeCorrection);
                        $('#NetworkCapacitorBank_Rating').val(this.capacitorRating);
                        $('#NetworkCapacitorBank_Required_Compensation').val(this.requiredCompensation);
                    }

                }
            }
        }


        //Power Station Results Section 
        if (response.Powerstationsizing && response.Powerstationsizing.Array !== null && response.Powerstationsizing["Array"].String.length > 0) {
            var stationResults = response.Powerstationsizing;
            var noOfColumn = stationResults["Array"].Dimsize[1];
            var rows = stationResults["Array"].String;
            let factorRows = rows;
            this.powerStationResultsArray.length = 0;
            if (noOfColumn > 0) {
                if (factorRows.length > noOfColumn) {
                    let powerStationData = this.ShowResultsCalculationByColumn(rows, noOfColumn, 5, 0, 0);
                    this.powerStationResultsArray = powerStationData;
                }
            }
        }

        //Switchboard Results Section 
        if (response.Maximumdemandofswitchboard && response.Maximumdemandofswitchboard.Array !== null && response.Maximumdemandofswitchboard["Array"].String.length > 0) {

            var factorResults = response.Maximumdemandofswitchboard;
            this.factorResults = factorResults;
            var noOfColumn = factorResults["Array"].Dimsize[1];
            var rows = factorResults["Array"].String;
            let factorRows = rows;
            this.switchboardResultsArray.length = 0;

            if (noOfColumn > 0) {
                if (factorRows.length > noOfColumn) {

                    let switchData = JSON.parse(sessionStorage.getItem("currentnode"));
                    if (switchData != null && switchData != undefined) {
                        if (switchData.objecttype == 1) {
                            let indexes = factorRows.findIndex(x => x.Val === switchData.objectcode);

                            if (indexes >= 0 && indexes != -1 && indexes != undefined) {

                                let lastIndexes = indexes + 55;
                                let resultData = this.ShowResultsCalculationByColumn(rows, noOfColumn, 4, indexes, lastIndexes);
                                this.switchboardResultsArray = resultData;

                            }
                        }
                        else {

                            let indexes = factorRows.findIndex(x => x.Val === 'MSB01');

                            if (indexes >= 0 && indexes != -1 && indexes != undefined) {

                                let lastIndexes = indexes + 55;
                                let resultData = this.ShowResultsCalculationByColumn(rows, noOfColumn, 4, indexes, lastIndexes);
                                this.switchboardResultsArray = resultData;

                            }
                        }

                    }


                }
            }
        }

        //Switchboard Maximum Demand Output calculation
        if (response.Automaximumdemandcalculationsresult && response.Automaximumdemandcalculationsresult.Array !== null && response.Automaximumdemandcalculationsresult["Array"].String.length > 0) {

            var factorResults = response.Automaximumdemandcalculationsresult;

            var noOfColumn = factorResults["Array"].Dimsize[1];
            var rows = factorResults["Array"].String;
            let factorRows = rows;
            if (noOfColumn > 0) {

                if (factorRows.length > noOfColumn) {

                    let maxColumns = [];
                    let maxRows = [];
                    maxColumns.push(factorRows[0]);
                    for (let j = 1; j < factorRows.length; j++) {
                        if (j % noOfColumn == 0) {
                            maxRows.push(maxColumns);
                            maxColumns = [];
                            maxColumns.push(factorRows[j]);
                        }
                        else {
                            maxColumns.push(factorRows[j]);
                        }
                    }
                    maxRows.push(maxColumns);

                    let itemsIndex = 0;
                    let maxStatus = false;
                    //let secondMaxData = 0;

                    for (let m = 0; m < this.maxDemandDataArray.length; m++) {
                        maxStatus = false;
                        itemsIndex = 0;
                        if (this.maxDemandDataArray[m].LoadItemsData.length > 0) {

                            for (let k = 0; k < maxRows.length; k++) {
                                if (maxRows[k][0].Val === this.maxDemandDataArray[m].Node_Prefix) {
                                    if (k === maxRows.length - 1) {
                                        break;
                                    } else {
                                        if (maxRows[k + 1][0].Val === '') {
                                            for (let i = k + 1; i < maxRows.length; i++) {

                                                if (maxRows[i][0].Val == '') {

                                                    let networkMaxData = this.maxDemandDataArray.find(x => x.Node_Prefix === this.maxDemandDataArray[m].Node_Prefix);
                                                    if (networkMaxData.LoadItemsData.length > 0) {
                                                        //Second Max Demand Table
                                                        networkMaxData.PhaseR = maxRows[k + 1][7].Val;
                                                        networkMaxData.PhaseW = maxRows[k + 1][8].Val;
                                                        networkMaxData.PhaseB = maxRows[k + 1][9].Val;
                                                        networkMaxData.MaximumDemand = maxRows[k + 1][10].Val;
                                                        networkMaxData.PhaseImbalance = maxRows[k + 1][11].Val;
                                                        networkMaxData.LoadItemsData[itemsIndex].red = maxRows[i][1].Val;
                                                        networkMaxData.LoadItemsData[itemsIndex].white = maxRows[i][2].Val;
                                                        networkMaxData.LoadItemsData[itemsIndex].blue = maxRows[i][3].Val;
                                                        networkMaxData.LoadItemsData[itemsIndex].resultR = "-"; //maxRows[i][4].Val;
                                                        networkMaxData.LoadItemsData[itemsIndex].resultW = "-";//maxRows[i][5].Val;
                                                        networkMaxData.LoadItemsData[itemsIndex].resultB = "-";//maxRows[i][6].Val;
                                                    }
                                                }
                                                else {

                                                    maxStatus = true;
                                                    break;
                                                }

                                                itemsIndex++;
                                            }
                                        }
                                        else {
                                            if (maxStatus == true) {
                                                break;
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }

                    if (this.isMaxDemandDataShown) {

                        this.getMaxDemandByCalculationId();
                    }

                }
                else {

                    if (this.isMaxDemandDataShown) {

                        this.PhaseR = '0';
                        this.PhaseW = '0';
                        this.PhaseB = '0';
                        this.MaximumDemand = '0';
                        this.PhaseImbalance = '0';
                        this.getMaxDemandByCalculationId();
                    }
                }
            }
        }

        //Cable Size Calc Results Section 
        if (response.Cablesizecalculationsresult && response.Cablesizecalculationsresult.Array !== null && response.Cablesizecalculationsresult["Array"].String.length > 0) {
            var factorResults = response.Cablesizecalculationsresult;
            this.Cablesizecalculationsresult = factorResults;
            var noOfColumn = factorResults["Array"].Dimsize[1];
            var rows = factorResults["Array"].String;
            let cablesizeRowsArray = [];
            let cablesizeColumnArray = [];
            let factorRows = rows;

            this.cableSizeResultsArray.length = 0;

            if (noOfColumn > 0) {
                if (factorRows.length > noOfColumn) {
                    // this.cableSizeSwitchboardData = response.Cablesizecalculationsresult;
                    let cablesSizeData = JSON.parse(sessionStorage.getItem("currentnode"));

                    if (cablesSizeData != null && cablesSizeData != undefined) {
                        let firstIndex = 0;
                        let lastIndex = 0;
                        let firstIndex1 = 0;
                        let lastIndex1 = 0;
                        let firstIndex2 = 0;
                        let lastIndex2 = 0;
                        let ID = cablesSizeData.id;
                        let capacitorName = "C" + (ID - 1);

                        for (let i = 0; i < factorRows.length; i++) {
                            if (factorRows[i].Val === capacitorName && factorRows[i + 1].Val === "Summary") {
                                firstIndex1 = i;
                                lastIndex1 = firstIndex1 + 76;
                                break;
                            }
                        }

                        for (let i = lastIndex1 + 1; i < factorRows.length; i++) {

                            if (factorRows[i].Val === capacitorName && factorRows[i + 1].Val === "Details") {
                                firstIndex = i;
                                lastIndex = firstIndex + 265;
                                break;
                            }
                        }

                        for (let i = lastIndex1 + 1; i < factorRows.length; i++) {

                            if (factorRows[i].Val === capacitorName && factorRows[i + 1].Val === "UI Controls") {
                                firstIndex2 = i;
                                lastIndex2 = firstIndex2 + 27;
                                break;
                            }
                        }

                        if (lastIndex1 != 0) {

                            let cablesizeSummary = [];
                            let cablesizeSummaryData = [];
                            for (let j = firstIndex1; j <= lastIndex1; j++) {

                                if (cablesizeSummary.length === noOfColumn - 1) {

                                    cablesizeSummary.push(factorRows[j])
                                    cablesizeSummaryData.push(cablesizeSummary);
                                    cablesizeSummary = [];
                                }
                                else {

                                    cablesizeSummary.push(factorRows[j]);
                                }
                            }

                            if (cablesizeSummaryData.length > 0) {

                                this.active_res = cablesizeSummaryData[1][3].Val;
                                this.active_NoOf_circuit = cablesizeSummaryData[2][3].Val;
                                this.Spare_current_capacity_of_circuit = cablesizeSummaryData[3][3].Val;
                                this.neutral_res = cablesizeSummaryData[4][3].Val;
                                this.neutral_NoOf_circuit = cablesizeSummaryData[5][3].Val;
                                this.earth_res = cablesizeSummaryData[6][3].Val;
                                this.earth_NoOf_circuit = cablesizeSummaryData[7][3].Val;
                                this.voltage_res = cablesizeSummaryData[8][3].Val;
                                this.faultLoop_res = cablesizeSummaryData[9][3].Val;
                                this.ConditSizeFound = cablesizeSummaryData[10][3].Val;
                            }
                        }

                        if (firstIndex != 0 && lastIndex != 0) {
                            for (let i = firstIndex; i <= lastIndex; i++) {
                                if (cablesizeColumnArray.length == noOfColumn - 1) {
                                    cablesizeColumnArray.push(factorRows[i])
                                    cablesizeRowsArray.push(cablesizeColumnArray);
                                    cablesizeColumnArray = [];
                                }
                                else {
                                    cablesizeColumnArray.push(factorRows[i])
                                }
                            }

                            let resultData = this.ShowResultsCalculationByColumn(cablesizeRowsArray, noOfColumn, 7, 0, 0);
                            this.cableSizeResultsArray = resultData;
                        }

                        if (lastIndex2 != 0) {

                            let uiControlSummary = [];
                            let uiControlSummaryData = [];
                            for (let j = firstIndex2; j <= lastIndex2; j++) {

                                if (uiControlSummary.length === noOfColumn - 1) {

                                    uiControlSummary.push(factorRows[j])
                                    uiControlSummaryData.push(uiControlSummary);
                                    uiControlSummary = [];
                                }
                                else {

                                    uiControlSummary.push(factorRows[j]);
                                }
                            }

                            if (uiControlSummaryData.length > 0) {
                                try { this.loadCurrent = uiControlSummaryData[0][3].Val; }
                                catch { this.loadCurrent = "-"; }
                                try { this.loadPowerFactor = uiControlSummaryData[1][3].Val; }
                                catch { this.loadPowerFactor = "-"; }
                                try { this.supplyVoltageDrop = uiControlSummaryData[2][3].Val; }
                                catch { this.supplyVoltageDrop = "-"; }
                                try { this.supplyFaultLevel = uiControlSummaryData[3][3].Val; }
                                catch { this.supplyFaultLevel = "-"; }
                            }
                            else {
                                this.loadCurrent = "-";
                                this.loadPowerFactor = "-";
                                this.supplyVoltageDrop = "-";
                                this.supplyFaultLevel = "-";
                            }
                        }
                    }
                    else {
                        this.cableSizeResultsArray = [];
                    }


                }
            }
        }

        //HV Relay Results Section 
        if (response.HVrelaycalculationsresult && response.HVrelaycalculationsresult.Array !== null && response.HVrelaycalculationsresult["Array"].String.length > 1) {
            var factorResults = response.HVrelaycalculationsresult;
            var noOfColumn = factorResults["Array"].Dimsize[1];
            var rows = factorResults["Array"].String;

            if (noOfColumn > 0) {

                let data = rows[2].Val.split(' ');

                this.HVRelayTrippingCurrentValue = data[0];
                $('#HVRelay_Tipping_Current_Max_Disconnection_Time').val(this.HVRelayTrippingCurrentValue);
            }
        }

        //ArcFlash Results Section
        if (response.ArcFlashResult && response.ArcFlashResult.Array !== null && response.ArcFlashResult["Array"].String.length > 0) {
            var factorResults = response.ArcFlashResult;
            this.ArcFlashResult = [];
            this.ArcFlashCompleteResult = [];
            var noOfColumn = factorResults["Array"].Dimsize[1];
            var rows = factorResults["Array"].String;
            let factorRows = rows;


            if (noOfColumn > 0) {
                if (factorRows.length > noOfColumn) {
                    if (this.activeNode != null && this.activeNode != undefined) {
                        let switchboardResults = [];
                        let currentSwitchboardArr = [];
                        for (let i = 0; i < factorRows.length; i++) {
                            if (i < (factorRows.length - 1) && factorRows[i + 1].Val === "Results" && i > 0) {
                                let sname = this.tempinputXML.Switchboards.find(tempnode => tempnode.SwitchBoard_Matrix_Node == currentSwitchboardArr[0].Val);

                                switchboardResults.push({ key: currentSwitchboardArr[0].Val, items: currentSwitchboardArr.slice(1), name: sname.SwitchBoard_Name });

                                currentSwitchboardArr = [];
                                currentSwitchboardArr.push(factorRows[i]);
                                continue;
                            }
                            currentSwitchboardArr.push(factorRows[i]);
                            if (i == (factorRows.length - 1) && currentSwitchboardArr && currentSwitchboardArr.length > 0) {
                                let sname = this.tempinputXML.Switchboards.find(tempnode => tempnode.SwitchBoard_Matrix_Node == currentSwitchboardArr[0].Val);

                                switchboardResults.push({ key: currentSwitchboardArr[0].Val, items: currentSwitchboardArr.slice(1), name: sname.SwitchBoard_Name });
                            }
                        }
                        let currentData = switchboardResults.find(m => m.key == this.activeNode.objectcode);
                        if (currentData && currentData.items)
                            this.ArcFlashResult = { noOfColumn: noOfColumn, items: currentData.items };

                        this.ArcFlashCompleteResult = switchboardResults;
                    }
                    else {
                        this.ArcFlashResult = [];
                        let switchboardResults = [];
                        let currentSwitchboardArr = [];
                        for (let i = 0; i < factorRows.length; i++) {
                            if (i < (factorRows.length - 1) && factorRows[i + 1].Val === "Results" && i > 0) {
                                let sname = this.tempinputXML.Switchboards.find(tempnode => tempnode.SwitchBoard_Matrix_Node == currentSwitchboardArr[0].Val);

                                switchboardResults.push({ key: currentSwitchboardArr[0].Val, items: currentSwitchboardArr.slice(1), name: sname.SwitchBoard_Name });

                                currentSwitchboardArr = [];
                                currentSwitchboardArr.push(factorRows[i]);
                                continue;
                            }
                            currentSwitchboardArr.push(factorRows[i]);
                            if (i == (factorRows.length - 1) && currentSwitchboardArr && currentSwitchboardArr.length > 0) {
                                let sname = this.tempinputXML.Switchboards.find(tempnode => tempnode.SwitchBoard_Matrix_Node == currentSwitchboardArr[0].Val);

                                switchboardResults.push({ key: currentSwitchboardArr[0].Val, items: currentSwitchboardArr.slice(1), name: sname.SwitchBoard_Name });
                            }
                        }
                        // let currentData = switchboardResults.find(m => m.key == this.activeNode.objectcode);
                        // if (currentData && currentData.items)
                        //     this.ArcFlashResult = { noOfColumn: noOfColumn, items: currentData.items };

                        this.ArcFlashCompleteResult = switchboardResults;
                    }
                }
            }
            this.getCompleteLabelString();
        }

        var endTime = performance.now();
        var totalTime = endTime - startTime; // time took to run in milliseconds
        //this.hideLoader();;
    }
    testResult(selectedValue) {
        //alert(1);
        this.stringVar.next(selectedValue);
    }
    //INUSE
    onInputChange(
        isFromTreeNode: boolean = false
    ) {
        var startTimes = performance.now();

        this.showLoader();
        this.updateSupplySettings(null);
        this.updateNetworkSettings();

        if (this.maxDemandDataArray.length > 0) {
            this.tempinputXML.MaxDemandDataArray = [];
            this.maxDemandDataArray.forEach(element => {
                let maxDataCalc = new MaxDemandData();
                maxDataCalc.Items = element.LoadItemsData;
                maxDataCalc.Node_Id = element.Node_Id.toString();
                maxDataCalc.Node_Name = element.Node_Name;
                maxDataCalc.Node_Prefix = element.Node_Prefix;
                maxDataCalc.Settings = this.maxDemandData.Settings;
                this.tempinputXML.MaxDemandDataArray.push(maxDataCalc);
            });
        }

        this.tempinputXML.matrix = JSON.stringify(this.matrix);
        if (this.tempinputXML.isSingleLineDiagram == null || this.tempinputXML.isSingleLineDiagram == undefined) {
            this.tempinputXML.isSingleLineDiagram = 0;
        }
        if (this.networkCalculationResponse != null && isFromTreeNode) {
            this.getResponseFromLocal(this.networkCalculationResponse);
        }
        else {
            this.modelChanged.next();

        }


    }

    //Protection section
    OnInputChangeCableSize(e: any, numberofplaces: number) {

        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, numberofplaces);
        const caption: string = e.target.getAttribute('caption');
        const device: string = e.target.getAttribute('device');
        if (caption === 'Short-circuit time') {
            this.CableSize['protection']['shortcircuittime'] = e.target.value;
            this.updatecablesizeinarray();
        } else if (caption === 'Trip current') {
            this.CableSize['protection']['tripcurrent'] = e.target.value;
            this.isCableCalValue = false;
            this.ProtectionCalcForCurrentandMultiplier(e, device, caption);
        } else if (caption === 'Trip Multiplier') {
            this.CableSize['protection']['tripmultiplier'] = e.target.value;
            this.isCableCalValue = false;
            this.ProtectionCalcForCurrentandMultiplier(e, device, caption);
        } else if (caption === 'Thermal setting') {
            this.CableSize['protection']['thermalsetting'] = e.target.value;
            this.isCableCalValue = false;
            this.ProtectionCalcForCurrentandMultiplier(e, device, caption);
        } else if (caption === 'Custom Rating') {
            this.CableSize['protection']['customrating'] = e.target.value;
            this.PrevCustomeRatingVal = e.target.value;
            this.isCableCalValue = false;
            this.ProtectionCalcForCustomRating();
        }
        this.getAllControlsValue();
    }

    onDescriptionChnage(e: any) {
        this.CommonValidationFn(e);

        const caption: string = e.target.getAttribute('caption');
        if (caption === 'Description') {
            this.CableSize['protection']['description'] = e.target.value;
        }
        this.updatecablesizeinarray();
        this.getAllControlsValue();
    }

    //
    ProtectionCalcForCurrentandMultiplier(e: any, device: any, caption: any) {

        let RatingValue: any = ''
        if (device === 'MCB') {
            RatingValue = $('#select_MCB')[0][this.PrevMCBVal].innerHTML.split(" ")[1];
        } else if (device === 'MCCB') {
            RatingValue = $('input').filter("[caption='Thermal setting']").val();
            // RatingValue = $('#select_MCCB')[0][this.PrevMCCBVal].innerHTML.split(" ")[1];
        } else if (device === 'ACB') {
            RatingValue = $('input').filter("[caption='Thermal setting']").val();
            // RatingValue = $('#select_ACB')[0][this.PrevACBVal].innerHTML.split(" ")[1];
        } else if (device === 'HRC Fuse') {
            RatingValue = $('#select_HRCFuse')[0][this.PrevHRCVal].innerHTML.split(" ")[1];
        } else if (device === 'Custom') {
            RatingValue = $('input').filter("[caption='Thermal setting']").val();
        }
        let currentValue: any = '';
        let Val1: any = '';
        let Val2: any = '';
        if (!isNaN(RatingValue) && RatingValue.toString().indexOf('.') != -1) {
            Val1 = parseFloat(RatingValue);
        } else {
            Val1 = parseInt(RatingValue);
        }
        if (!isNaN(e.target.value) && e.target.value.toString().indexOf('.') != -1) {
            Val2 = parseFloat(e.target.value);
        } else {
            Val2 = parseInt(e.target.value);
        }
        if (caption === 'Trip current') {
            currentValue = Math.round(Val2 / Val1 * 100) / 100;
            if ($('input').filter("[caption='Trip Multiplier']").length) {
                $('input').filter("[caption='Trip Multiplier']").val(currentValue);
                this.CableSize['protection']['tripmultiplier'] = currentValue;
            }
        } else if (caption === 'Trip Multiplier') {
            currentValue = Val1 * Val2;
            currentValue = currentValue.toFixed(2);
            if ($('input').filter("[caption='Trip current']").length) {
                $('input').filter("[caption='Trip current']").val(currentValue);
                this.CableSize['protection']['tripcurrent'] = currentValue;
            }
        } else if (caption === 'Thermal setting') {
            const MultiplierValue = $('input').filter("[caption='Trip Multiplier']").val().toString();
            if (e.target.value < 100) {
                this.showLable = true;
            } else {
                this.showLable = false;
            }
            if (!Number.isNaN(Number(MultiplierValue)) && MultiplierValue.toString().indexOf('.') != -1) {
                Val1 = parseFloat(MultiplierValue);
            } else {
                Val1 = parseInt(MultiplierValue);
            }
            if (!isNaN(e.target.value) && e.target.value.toString().indexOf('.') != -1) {
                Val2 = parseFloat(e.target.value);
            } else {
                Val2 = parseInt(e.target.value);
            }
            currentValue = Val1 * Val2;
            currentValue = currentValue.toFixed(2);
            $('input').filter("[caption='Trip current']").val(currentValue);
            this.CableSize['protection']['tripcurrent'] = currentValue;
        }
        if (!this.isCableCalValue) {


            this.updatecablesizeinarray();
            this.getAllControlsValue();
        }
    }
    ArcFlashExportToExcel() {

        this._pdfReportSerivceProxy.ArcFlashExportToExcel(this.CalculationReportInput).subscribe(result => {
            this.showreportloader = false;
            this.hideLoader();
            this._fileDownloadService.downloadTempFile(result);

        });

    }
    CableScheduleExportToExcel() {

        this._pdfReportSerivceProxy.NetworkCableScheduleExportToExcel(this.CalculationReportInput).subscribe(result => {
            this.showreportloader = false;
            this.hideLoader();

            this._fileDownloadService.downloadTempFile(result);

        });

    }


    //INUSE
    UpdateXmlValues(data: CablePullingXmlInput, isreport: boolean = false) {
        //alert('2');
        var startTime = performance.now();
        //this.showLoader();
        data.ProjId = this.route.snapshot.params.pid;
        //alert('Save XML Values!!!!');
        this._CableproService.storeNetworkCalculationXml(data).subscribe(
            (result) => {
                //this.hideLoader();
                var endTime = performance.now();
                var totalTime = endTime - startTime; // time took to run in milliseconds
                //this.hideLoader();;
                if (isreport) {
                    if (this.reportType == 3) {
                        this.showLoader();

                        this.ArcFlashExportToExcel();
                        this.reportType = 0;
                    }
                    else if (this.reportType == 2) {
                        //this.showLoader();
                        this.showLoader();

                        this.CableScheduleExportToExcel();
                        this.reportType = 0;
                    }

                    else {
                        this._pdfReportSerivceProxy.NetworkCalculationGenerateReport(this.CalculationReportInput).subscribe((result) => {
                            $('#reportDlg').modal('hide');
                            if (!this.CalculationReportInput.sendEmail) {
                                let blob = new Blob([result.body], { type: 'application/pdf' });
                                saveAs(blob, this.CalculationReportInput.reportAttachmentName);
                            }
                            else {
                                $('#EmailSent').modal('show');
                            }
                            this.ClearReport();
                            //  this.hideLoader();
                            this.showreportloader = false;

                        });
                    }
                }


            },
            (error) => {
                this.hideLoader();;
                this.showreportloader = false;
            }
        );
    }

    //INUSE
    updateNetworkSettings() {
        let CategorySource = this.tempinputXML.NetworkSettings.CategorySource;
        let CalculationMethod = this.tempinputXML.NetworkSettings.CalculationMethod;
        let IsArcFlash = this.tempinputXML.NetworkSettings.IsArcFlash;
        let ppeCategoriesTable = this.tempinputXML.NetworkSettings.ppeCategoriesTable;
        let ppeCategoriesTableHeaders = this.tempinputXML.NetworkSettings.ppeCategoriesTableHeaders;

        this.tempinputXML.NetworkSettings = new NetworkSettings();

        // if ($('#SupplyVoltage').val() != undefined) {
        this.tempinputXML.NetworkSettings.SupplyVoltage = this.SupplyVoltage;
        // }
        // if ($('#Frequency').val() != undefined) {
        this.tempinputXML.NetworkSettings.Frequency = this.Frequency;
        // }
        //if ($('#VoltageTolerance').val() != undefined) {
        this.tempinputXML.NetworkSettings.VoltageTolerance = this.VoltageTolerance;
        //}
        if ($('#AllowableVoltageDrop_Network').val() != undefined) {
            this.tempinputXML.NetworkSettings.AllowableVoltageDrop_Network = $('#AllowableVoltageDrop_Network').val().toString();
        }
        if ($('#AllowableVoltageDrop_MainsCable').val() != undefined) {
            this.tempinputXML.NetworkSettings.AllowableVoltageDrop_MainsCable = $('#AllowableVoltageDrop_MainsCable').val().toString();
        }
        // if ($('#CableCalcStandard').val() != undefined) {
        this.tempinputXML.NetworkSettings.CableCalcStandard = this.cableCalculationStandard;//$('#CableCalcStandard').val().toString();
        // }
        //if ($('#TransformerTypicalDataSource').val() != undefined) {
        this.tempinputXML.NetworkSettings.TransformerTypicalDataSource = this.TransformerTypicalDataSource; //$('#TransformerTypicalDataSource').val().toString();
        //}
        this.tempinputXML.NetworkSettings.CategorySource = CategorySource;
        this.tempinputXML.NetworkSettings.CalculationMethod = CalculationMethod;
        this.tempinputXML.NetworkSettings.IsArcFlash = IsArcFlash;
        this._settingService.hideArcFlashReport = !IsArcFlash;
        this.tempinputXML.NetworkSettings.ppeCategoriesTable = ppeCategoriesTable;
        this.tempinputXML.NetworkSettings.ppeCategoriesTableHeaders = ppeCategoriesTableHeaders;
        this.tempinputXML.NetworkSettings.CableCalcProtectiveDeviceSource = this.cableCalcProtectiveDeviceSource;
        this.tempinputXML.NetworkSettings.ProtectionCoordinationStandard = this.protectionCoordinationStandard;
    }

    openFile() {
        $("#customFile").click();
    }
    //INUSE
    updateSupplySettings(anyevent?: any) {

        // alert(0);

        this.tempinputXML.SupplySettings = new SupplySettings();
        // alert($('#networksupplytype').val() + "zx");
        this.tempinputXML.SupplySettings.SupplyType = parseInt(this.networksupplytype);
        let obj = this.NetworkSupplyTypeList.find(m => m.id == this.networksupplytype);
        this.tempinputXML.SupplySettings.SupplyTypeName = obj.text;

        //COMMMON
        if ($("#networksupplyradio1").is(":checked")) {
            this.tempinputXML.SupplySettings.Networkfeeder_Phases = 0;
            this.tempinputXML.SupplySettings.ExistingSwitchBoard_Phases = 0;
        }
        else if ($("#networksupplyradio2").is(":checked")) {
            this.tempinputXML.SupplySettings.Networkfeeder_Phases = 1;
            this.tempinputXML.SupplySettings.ExistingSwitchBoard_Phases = 1;
        }

        if ($('#Generator_Rated_Voltage').val() != undefined) {
            this.tempinputXML.SupplySettings.Generator_Rated_Voltage = parseFloat($('#Generator_Rated_Voltage').val().toString());
        }

        if ($('#Networkfeeder_Fault_Level').val() != undefined) {

            this.tempinputXML.SupplySettings.Networkfeeder_Fault_Level = parseFloat($('#Networkfeeder_Fault_Level').val().toString());
        }

        if ($('#ExistingSwitchboard_Fault_Level').val() != undefined) {

            this.tempinputXML.SupplySettings.ExistingSwitchBoard_Fault_Level = parseFloat($('#ExistingSwitchboard_Fault_Level').val().toString());//parseInt($('#ExistingSwitchBoard_Fault_Level').val().toString());
        }

        this.tempinputXML.SupplySettings.Transformer_Phases = 0;

        //#region Transformer

        // checkSupplyHVRelay


        if ($('#checkSupplyHVRelay').is(":checked") == true) {
            this.tempinputXML.SupplySettings.Transformer_HV_Relay_Device = 1;

            if (!this.tempinputXML.hasOwnProperty('NetworkHVRelay')) {
                this.tempinputXML.NetworkHVRelay = [];
                this.temphvrelaylist = [];
                //$('#HVRelay_Curve_Type').val('1');
                this.addHVRelay();
            }
        }

        if ($('#checkSupplyHVRelay').is(":checked") == false) {
            this.tempinputXML.SupplySettings.Transformer_HV_Relay_Device = 0;
        }


        if ($('#Transformer_Primary_Voltage').val() != undefined) {
            this.tempinputXML.SupplySettings.Transformer_Primary_Voltage = parseFloat($('#Transformer_Primary_Voltage').val().toString());
        }

        if ($('#Transformer_Primary_Fault_Level').val() != undefined) {
            this.tempinputXML.SupplySettings.Transformer_Primary_Fault_Level = parseFloat($('#Transformer_Primary_Fault_Level').val().toString());
        }

        this.tempinputXML.SupplySettings.Transformer_Auto_Size_Transformer = 0;
        if (this.Autosizetransformer == true) {
            this.tempinputXML.SupplySettings.Transformer_Auto_Size_Transformer = 1;
        }

        if ($('#Transformer_No_Of_Parallel_Transformers').val() != undefined) {
            this.tempinputXML.SupplySettings.Transformer_No_Of_Parallel_Transformers = parseInt($('#Transformer_No_Of_Parallel_Transformers').val().toString());
        }

        if ($('#Transformer_Custom_Rating').val() != undefined) {
            this.tempinputXML.SupplySettings.Transformer_Custom_Rating = parseFloat($('#Transformer_Custom_Rating').val().toString());
        }

        this.tempinputXML.SupplySettings.Transformer_Custom_Transformer_Impedence = 0;
        if (this.Customtransformer == true) {
            this.tempinputXML.SupplySettings.Transformer_Custom_Transformer_Impedence = 1;
        }

        if ($('#Transformer_Impedence_Voltage').val() != undefined) {
            this.tempinputXML.SupplySettings.Transformer_Impedence_Voltage = parseFloat($('#Transformer_Impedence_Voltage').val().toString());
        }

        if ($('#Transformer_XR_Ratio').val() != undefined) {
            this.tempinputXML.SupplySettings.Transformer_XR_Ratio = parseFloat($('#Transformer_XR_Ratio').val().toString());
        }

        this.tempinputXML.SupplySettings.Transformer_Impedence_Correction_Factor = 0;
        if (this.Impedencecorrection == true) {
            this.tempinputXML.SupplySettings.Transformer_Impedence_Correction_Factor = 1;
        }

        //if ($('#Transformer_Calculation_Method').val() != undefined) {
        this.tempinputXML.SupplySettings.Transformer_Calculation_Method = parseInt(this.Transformer_Calculation_Method);
        //}
        if ($('#Transformer_Load_Losses').val() != undefined) {
            this.tempinputXML.SupplySettings.Transformer_Load_Losses = parseFloat($('#Transformer_Load_Losses').val().toString());
        }
        //#endregion


        //#region Generator


        if (this.Autosizegenerator == true) {
            this.tempinputXML.SupplySettings.Generator_Auto_Size_Generator = 1;
        }
        else if (this.Autosizegenerator == false) {
            this.tempinputXML.SupplySettings.Generator_Auto_Size_Generator = 0;
        }

        if ($('#Generator_Phases').val() != undefined) {
            this.tempinputXML.SupplySettings.Generator_Phases = parseFloat($('#Generator_Phases').val().toString());
        }





        if (this.Customgenerator == true) {
            this.tempinputXML.SupplySettings.Generator_Custom_Generator_Impedence = 1;
        }
        else if (this.Customgenerator == false) {
            this.tempinputXML.SupplySettings.Generator_Custom_Generator_Impedence = 0;
        }





        if ($('#Generator_No_Of_Parallel_Generators').val()) {
            this.tempinputXML.SupplySettings.Generator_No_Of_Parallel_Generators = parseInt($('#Generator_No_Of_Parallel_Generators').val().toString());
        }
        if ($('#Generator_Customer_Rating').val() != undefined) {
            this.tempinputXML.SupplySettings.Generator_Customer_Rating = parseFloat($('#Generator_Customer_Rating').val().toString());
        }
        if ($('#Generator_Subtransient_Reactance').val() != undefined) {
            this.tempinputXML.SupplySettings.Generator_Subtransient_Reactance = parseFloat($('#Generator_Subtransient_Reactance').val().toString());
        }
        if ($('#Generator_XDR_Ratio').val() != undefined) {
            this.tempinputXML.SupplySettings.Generator_XDR_Ratio = parseFloat($('#Generator_XDR_Ratio').val().toString());
        }

        if (this.GeneratorImpedencecorrection == true) {
            this.tempinputXML.SupplySettings.Generator_Impedence_Correction_Factor = 1;
        }
        else if (this.GeneratorImpedencecorrection == false) {
            this.tempinputXML.SupplySettings.Generator_Impedence_Correction_Factor = 0;
        }

        if ($('#Generator_Power_Factor_Of_Generators').val() != undefined) {
            this.tempinputXML.SupplySettings.Generator_Power_Factor_Of_Generators = parseFloat($('#Generator_Power_Factor_Of_Generators').val().toString());
        }
        if ($('#Generator_Voltage_Regulation').val() != undefined) {
            this.tempinputXML.SupplySettings.Generator_Voltage_Regulation = parseFloat($('#Generator_Voltage_Regulation').val().toString());
        }
        //#endregion

        //#region  Power Station




        if ($('#Power_Station_Generator_Rated_Voltage').val() != undefined) {
            this.tempinputXML.SupplySettings.Power_Station_Generator_Rated_Voltage = parseFloat($('#Power_Station_Generator_Rated_Voltage').val().toString());
        }

        if ($('#PowerStationHVRelay').is(":checked") == true) {

            if (!this.tempinputXML.hasOwnProperty('NetworkHVRelay')) {
                this.tempinputXML.NetworkHVRelay = [];
                this.temphvrelaylist = [];
                //$('#HVRelay_Curve_Type').val('1');
                this.addHVRelay();
            }
            this.tempinputXML.SupplySettings.Power_Station_HV_Relay_Device = 1;
        }

        if ($('#PowerStationHVRelay').is(":checked") == false) {
            this.tempinputXML.SupplySettings.Power_Station_HV_Relay_Device = 0;
        }


        this.tempinputXML.SupplySettings.Power_Station_Auto_Size_Power_Station = 0;
        if (this.Autosizepower == true) {
            this.tempinputXML.SupplySettings.Power_Station_Auto_Size_Power_Station = 1;
        }

        if ($('#Power_Station_No_Of_Parallel_Units').val() != undefined) {
            this.tempinputXML.SupplySettings.Power_Station_No_Of_Parallel_Units = parseFloat($('#Power_Station_No_Of_Parallel_Units').val().toString());
        }

        if ($('#Power_Station_Generator_Custom_Rating').val() != undefined) {
            this.tempinputXML.SupplySettings.Power_Station_Generator_Custom_Rating = parseFloat($('#Power_Station_Generator_Custom_Rating').val().toString());
        }
        if ($('#Power_Station_Transformer_Custom_Rating').val() != undefined) {
            this.tempinputXML.SupplySettings.Power_Station_Transformer_Custom_Rating = parseFloat($('#Power_Station_Transformer_Custom_Rating').val().toString());
        }

        this.tempinputXML.SupplySettings.Power_Station_Custom_Generator_Impedence = 0;
        if (this.PowerStationCustomgenerator == true) {
            this.tempinputXML.SupplySettings.Power_Station_Custom_Generator_Impedence = 1;
        }
        if ($('#Power_Station_Subtransient_Reactance').val() != undefined) {
            this.tempinputXML.SupplySettings.Power_Station_Subtransient_Reactance = parseFloat($('#Power_Station_Subtransient_Reactance').val().toString());
        }
        if ($('#Power_Station_XDR_Ratio').val() != undefined) {
            this.tempinputXML.SupplySettings.Power_Station_XDR_Ratio = parseFloat($('#Power_Station_XDR_Ratio').val().toString());
        }

        this.tempinputXML.SupplySettings.Power_Station_Custom_Transformer_Impedence = 0;
        if (this.PowerStationCustomtransformer == true) {
            this.tempinputXML.SupplySettings.Power_Station_Custom_Transformer_Impedence = 1;
        }

        if ($('#Power_Station_Impedence_Voltage').val() != undefined) {
            this.tempinputXML.SupplySettings.Power_Station_Impedence_Voltage = parseFloat($('#Power_Station_Impedence_Voltage').val().toString());
        }

        if ($('#Power_Station_XR_Ratio').val() != undefined) {
            this.tempinputXML.SupplySettings.Power_Station_XR_Ratio = parseFloat($('#Power_Station_XR_Ratio').val().toString());
        }


        this.tempinputXML.SupplySettings.Power_Station_Impedence_Correction_Factor = 0;
        if (this.PowerStationImpedencecorrection == true) {
            this.tempinputXML.SupplySettings.Power_Station_Impedence_Correction_Factor = 1;
        }


        if ($('#Power_Station_Power_Factor_Of_Generator').val() != undefined) {
            this.tempinputXML.SupplySettings.Power_Station_Power_Factor_Of_Generator = parseFloat($('#Power_Station_Power_Factor_Of_Generator').val().toString());
        }
        if ($('#Power_Station_Generator_Voltage_Regulation').val() != undefined) {
            this.tempinputXML.SupplySettings.Power_Station_Generator_Voltage_Regulation = parseFloat($('#Power_Station_Generator_Voltage_Regulation').val().toString());
        }
        // if ($('#Power_Station_Calc_Transformer_XandR').val() != undefined) {
        this.tempinputXML.SupplySettings.Power_Station_Calc_Transformer_XandR = parseFloat(this.Power_Station_Calc_Transformer_XandR);
        // }

        if ($('#Power_Station_Transformer_Load_Losses').val() != undefined) {
            this.tempinputXML.SupplySettings.Power_Station_Transformer_Load_Losses = parseFloat($('#Power_Station_Transformer_Load_Losses').val().toString());
        }
        //#endregion
        this.tempinputXML.SupplySettings.HVFuse = this.HVFuse;
        this.tempinputXML.SupplySettings.HvFuseXml = this.HvFuseXml;
        this.tempinputXML.SupplySettings.HvFuseTrippingXml = this.HvFuseTrippingXml;
    }

    //INUSE auto max demand load
    addLoad() {

        let isValid: boolean = true;

        if ((!this.premiumUser || this.planLevel == '1') && this.LoadDataXml.items != undefined) {

            if (this.LoadDataXml.items[0] != undefined)

                isValid = !($(this.LoadDataXml.items[0]).length >= 6)
        }
        if (!isValid) {
            this.freeTrialLimitationExceededFlow(this.user, parseInt(this.cableCalculationStandard), this._companyAccountService, this._router);
        }
        else {

            /**/
            $('#addEditItem').modal('show');
            this.LoadStorage = new LoadItem();
            //this.LoadStorage = LoadItem = new LoadItem();
            this.mode = 'Add';
            this.modalHeader = 'Add Load';
            this.editLoadGroupId = 0;
            this.editSubGroupId = 0;
            this.LoadStorage.installationId = 5;
            this.LoadStorage.phases = 1;
            this.LoadStorage.rating = 1;
            this.LoadStorage.units = 1;
            this.LoadStorage.quantity = 1;
            this.LoadStorage.powerFactor = 1;
            this.LoadStorage.red = 0;
            this.LoadStorage.white = 0;
            this.LoadStorage.blue = 0;
            this.LoadStorage.diversity = 1;
            this.LoadStorage.factor = 100;
            this.LoadStorage.description = '';
            this.isSubGroupShown = true;

            if (this.Loaddata === undefined)
                this.LoadStorage.loadGroupId = 5;
            else
                this.LoadStorage.loadGroupId = this.Loaddata[0] === undefined ? 5 : (this.Loaddata[0].loadId === undefined ? 5 : this.Loaddata[0].loadId);

            if (this.SubData === undefined)
                this.LoadStorage.subGroupId = 1;
            else
                this.LoadStorage.subGroupId = this.SubData[0] === undefined ? 1 : (this.SubData[0].subId === undefined ? 1 : this.SubData[0].subId);


            this.showError = false;
            this.showQuantityError = false;
            this.showMaxError = false;
            this.editDataIndex = undefined;
            setTimeout(() => {

                $('#ddlElectricInstallation1').val(this.LoadStorage.installationId).trigger('change');
                // $('#ddlLoadGroup').val(this.LoadStorage.loadGroupId).trigger('change');
                // $('#ddlSubGroup').val(this.LoadStorage.subGroupId).trigger('change');
                $('#ddlPhases').val(this.LoadStorage.phases).trigger('change');
                $('#ddlUnits').val(this.LoadStorage.units).trigger('change');
                $('#ddlDiversity').val(this.LoadStorage.diversity).trigger('change');
            });
        }
    }

    LoadDataReset() {
        this.isMaxDemandDataShown = true;
        let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));
        if (this.maxDemandDataArray.length > 0) {
            let maxDemandData = this.maxDemandDataArray.find(x => x.Node_Id === selectednode.id || x.Node_Prefix == selectednode.objectcode);
            if (maxDemandData !== undefined && maxDemandData !== null) {
                // this.PhaseR = '0';
                // this.PhaseW = '0';
                // this.PhaseB = '0';
                // this.MaximumDemand = '0';
                // this.PhaseImbalance = '0';
                if (maxDemandData.LoadItemsData.length > 0) {
                    $('#reset-model').modal('show');
                }
            }
        }
    }

    LoadDataUp(record: LoadItem) {
        this.isMaxDemandDataShown = true;
        let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));
        var maxdata = this.maxDemandDataArray.find(x => x.Node_Id == selectednode.id || x.Node_Prefix == selectednode.objectcode);

        if (maxdata !== undefined && maxdata !== null) {

            const index = maxdata.LoadItemsData.findIndex(x => x.id === record.id);

            var previous = maxdata.LoadItemsData[index - 1];
            maxdata.LoadItemsData[index - 1] = record;
            maxdata.LoadItemsData[index] = previous;
            this.onInputChange();
        }
    }

    LoadDataDown(record: LoadItem) {
        this.isMaxDemandDataShown = true;
        let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));
        var maxdata = this.maxDemandDataArray.find(x => x.Node_Id == selectednode.id || x.Node_Prefix == selectednode.objectcode);

        if (maxdata !== undefined && maxdata !== null) {

            const index = maxdata.LoadItemsData.findIndex(x => x === record);
            var previous = maxdata.LoadItemsData[index + 1];
            maxdata.LoadItemsData[index + 1] = record;
            maxdata.LoadItemsData[index] = previous;
            this.onInputChange();
        }
    }

    getMaxDemandByCalculationId() {

        this.isCableSizeCalcStatus = true;
        this.isMaxDemandDataShown = false;
        this.isSubGroupShown = false;
        let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));
        if (selectednode !== null && selectednode !== undefined) {
            if (this.maxDemandDataArray.length > 0) {

                var data = this.maxDemandDataArray.find(x => x.Node_Id == selectednode.id || x.Node_Prefix == selectednode.objectcode);
                if (data != undefined && data != null) {

                    if (data.LoadItemsData.length > 0) {
                        this.editLoadGroupId = 1;
                        this.PhaseR = data.PhaseR
                        this.PhaseW = data.PhaseW;
                        this.PhaseB = data.PhaseB
                        this.MaximumDemand = data.MaximumDemand
                        this.PhaseImbalance = data.PhaseImbalance;
                        this.primengTableHelper.showLoadingIndicator();
                        this.primengTableHelper.records = data.LoadItemsData && data.LoadItemsData.length > 0 ? data.LoadItemsData : [];
                        this.dataSet = this.primengTableHelper.records;
                        let arrayLength = this.dataSet.length - 1;
                        this.firstElement = this.dataSet[0].id;
                        this.lastElement = this.dataSet[arrayLength].id;
                        this.LoadDataXml.electricalInstallation = this.installId;
                        this.LoadDataXml.items = [];
                        this.LoadDataXml.items.push(this.dataSet);
                        this.maxDemandData.Items = [];

                        for (var i = 0; i < this.dataSet.length; i++) {
                            this.maxDemandData.Items.push(this.dataSet[i]);
                        }
                        this.primengTableHelper.hideLoadingIndicator();
                    }
                    else {
                        this.editSubGroupId = 0;
                        this.editLoadGroupId = 0;
                        // this.GetLoadGroupByInstallId(this.CalculationId, 5);
                        this.PhaseR = '0';
                        this.PhaseW = '0';
                        this.PhaseB = '0';
                        this.MaximumDemand = '0';
                        this.PhaseImbalance = '0';
                        this.primengTableHelper.records = [];
                        this.primengTableHelper.hideLoadingIndicator();
                    }
                }
                else {

                    this.editSubGroupId = 0;
                    this.editLoadGroupId = 0;
                    //this.GetLoadGroupByInstallId(this.CalculationId, 5);
                    this.primengTableHelper.records = [];
                    this.primengTableHelper.hideLoadingIndicator();
                }
            }
            else {
                this.editSubGroupId = 0;
                this.editLoadGroupId = 0;
                //this.GetLoadGroupByInstallId(this.CalculationId, 5);
                this.primengTableHelper.records = [];
                this.primengTableHelper.hideLoadingIndicator();
            }
        }
        else {
            this.editSubGroupId = 0;
            this.editLoadGroupId = 0;
            // this.GetLoadGroupByInstallId(this.CalculationId, 5);
        }

    }

    removeTrailingZeros(value: any) {
        value = value.toString();
        //alert(value);
        if (value == "") {
            value = "0";
            return value;

        }
        //  # if not containing a dot, we do not need to do anything
        if (value.indexOf('.') === -1) {
            return value;
        }

        // # as long as the last character is a 0 or a dot, remove it
        while ((value.slice(-1) === '0' || value.slice(-1) === '.') && value.indexOf('.') !== -1) {
            value = value.substr(0, value.length - 1);
        }

        // if(value=="")
        // value="0";
        return value;
    }

    GetShortNameByInstallId(installId: number = 0) {
        if (installId == 5) {
            return 'Domestic';
        }
        else if (installId == 6 || installId == 7 || installId == 8) {
            return 'Living units';
        }
        else if (installId == 9 || installId == 10) {
            return 'Non-domestic';
        }
        else {
            return 'Non-domestic';
        }
    }

    CommonValidationFnqty(event: any) {
        this.AllowOnlyNumericValues(event);
        if (parseFloat(event.target.value) > parseFloat(event.target.max)) {
            event.target.value = event.target.max;
        }
        if (parseFloat(event.target.value) < parseFloat(event.target.min) || event.target.value === '') {
            event.target.value = event.target.min;
        }
        this.LoadStorage.quantity = event.target.value;
    }

    SaveMaxDemandCal() {

        this.isMaxDemandDataShown = true;
        this.isXmlStatus = true;
        if (this.LoadStorage.phases === 3 && this.isBlockInstallation) {
            this.LoadStorage.red = this.LoadStorage.quantity;
            this.LoadStorage.white = this.LoadStorage.quantity;
            this.LoadStorage.blue = this.LoadStorage.quantity;
        }
        if (($('#ddlDiversity').val() == "1" || $('#ddlPhases').val() == "3") && this.isBlockInstallation == false) {
            this.LoadStorage.automaticAllocation = 1;
        }
        else {
            this.LoadStorage.automaticAllocation = 0;

        }
        if ($('#chkgroup').is(':checked')) {
            this.LoadStorage.groupAsCircuit = 1;
        }
        else {
            this.LoadStorage.groupAsCircuit = 0;

        }
        if (this.LoadStorage.loadType == 2 ||
            this.LoadStorage.loadType == 4 ||
            this.LoadStorage.loadType == 6 ||
            this.LoadStorage.loadType == 8 ||
            this.LoadStorage.loadType == 7) {
            if (!this.isCustomInstallation) {
                this.LoadStorage.rating = 0;
            }
        }

        if (this.mode === 'Add') {

            this.loadId = this.loadId + 1;
            this.LoadStorage.id = this.loadId;
            this.LoadStorage.loadName = $('#ddlLoadGroup option:selected')[0].innerText.trim();
            this.LoadStorage.loadGroup = parseInt($('#ddlLoadGroup option:selected')[0].getAttribute('xmlValue'));

            if (!this.isCustomInstallation) {
                this.LoadStorage.subName = $('#ddlSubGroup option:selected')[0].innerText.trim();
                this.LoadStorage.subGroup = parseInt($('#ddlSubGroup option:selected')[0].getAttribute('xmlValue'));

            }
            else {
                this.LoadStorage.subName = '';
                this.LoadStorage.subGroup = 0;

            }
            this.LoadStorage.phaseAllocation = 3;

        }
        if ($('#ddlLoadGroup option:selected')[0].innerText.trim() == 'Custom') // for custom        
        {
            this.LoadStorage.loadCode = 1000;
        }
        this.LoadStorage.red = this.LoadStorage.red != null ? this.LoadStorage.red : 0;
        this.LoadStorage.white = this.LoadStorage.white != null ? this.LoadStorage.white : 0;
        this.LoadStorage.blue = this.LoadStorage.blue != null ? this.LoadStorage.blue : 0;
        if (this.LoadStorage.rating == null)
            this.LoadStorage.rating = 0;
        if (this.LoadStorage.automaticAllocation == 0) {
            if (this.LoadStorage.red === 0 && this.LoadStorage.white === 0 && this.LoadStorage.blue === 0) {
                this.showError = true;
                return true;
            }
            if (this.LoadStorage.red > 10000 || this.LoadStorage.white > 10000 || this.LoadStorage.blue > 10000) {
                this.showMaxError = true;
                return true;
            }
        }


        if (this.LoadStorage.quantity <= 0 && this.isHideQty == true) {
            this.showQuantityError = true;
            return true;
        }
        this.LoadStorage.calculatedRating = this.calculateRating(this.LoadStorage.units, this.LoadStorage.rating,
            this.LoadStorage.phases, this.LoadStorage.powerFactor);
        let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));

        if (this.dataSet !== undefined) {
            if (this.mode === 'Edit') {

                let networkMaxData = this.maxDemandDataArray.find(x => x.Node_Id === selectednode.id || x.Node_Prefix == selectednode.objectcode);

                networkMaxData.LoadItemsData.forEach(element => {

                    if (element.id === this.editDataIndex) {

                        element.red = this.LoadStorage.red;
                        element.installationId = this.LoadStorage.installationId;
                        element.loadType = this.LoadStorage.loadType;
                        element.quantity = this.LoadStorage.quantity;
                        element.loadCode = this.LoadStorage.loadCode;
                        element.blue = this.LoadStorage.blue
                        element.description = this.LoadStorage.description
                        element.diversity = this.LoadStorage.diversity
                        element.factor = this.LoadStorage.factor
                        element.loadGroupId = this.LoadStorage.loadGroupId
                        element.phases = this.LoadStorage.phases
                        element.powerFactor = this.LoadStorage.powerFactor
                        element.rating = this.LoadStorage.rating;
                        element.calculatedRating = this.LoadStorage.calculatedRating;
                        element.subGroupId = this.LoadStorage.subGroupId;
                        element.units = this.LoadStorage.units;
                        element.white = this.LoadStorage.white;
                        element.automaticAllocation = this.LoadStorage.automaticAllocation;
                        element.groupAsCircuit = this.LoadStorage.groupAsCircuit;
                        element.loadName = $('#ddlLoadGroup option:selected')[0].innerText.trim();
                        element.loadGroup = parseInt($('#ddlLoadGroup option:selected')[0].getAttribute('xmlValue'));

                        if (!this.isCustomInstallation) {
                            element.subName = $('#ddlSubGroup option:selected')[0].innerText.trim();
                            element.subGroup = parseInt($('#ddlSubGroup option:selected')[0].getAttribute('xmlValue'));
                        }
                        else {
                            element.subName = '';
                            element.subGroup = 0;
                        }
                    }
                });
            }
            else {
                this.LoadDataXml = new LoadData();
                this.LoadDataXml.electricalInstallation = this.installId;
                if (this.maxDemandDataArray.length > 0) {

                    var MSdata = this.maxDemandDataArray.find(x => x.Node_Id == selectednode.id || x.Node_Prefix == selectednode.objectcode);
                    if (MSdata != undefined && MSdata != null) {
                        MSdata.LoadItemsData.push(this.LoadStorage);
                    }
                    else {

                        let maxData = new MaxDemandCalcInput();
                        maxData.CableSizeCalcInputId = this.CalculationId;
                        maxData.Node_Id = selectednode.id;
                        maxData.Node_Name = selectednode.name;
                        maxData.Node_Prefix = selectednode.objectcode;
                        maxData.LoadItemsData.push(this.LoadStorage);
                        this.maxDemandDataArray.push(maxData);
                    }
                }
                else {

                    let maxData = new MaxDemandCalcInput();
                    maxData.CableSizeCalcInputId = this.CalculationId;
                    maxData.Node_Id = selectednode.id;
                    maxData.Node_Name = selectednode.name;
                    maxData.Node_Prefix = selectednode.objectcode;
                    maxData.LoadItemsData.push(this.LoadStorage);
                    this.maxDemandDataArray.push(maxData);
                }
            }
        } else {

            var data = this.maxDemandDataArray.find(x => x.Node_Id == selectednode.id || x.Node_Prefix == selectednode.objectcode);

            if (data != undefined && data != null) {
                this.LoadDataXml.items = [];
                this.LoadDataXml.items.push(this.LoadStorage);
                data.LoadItemsData = this.LoadDataXml.items;
            }
        }

        $('#addEditItem').modal('hide');



        this.onInputChange();

    }

    edit(id: any) {

        let editRowData = Object.assign([], this.dataSet.filter(x => x.id === id));
        this.LoadStorage = Object.assign({}, editRowData[0]);;
        this.editLoadData(editRowData);
    }

    copyrecord(loadData) {
        this.isMaxDemandDataShown = true;
        let hasRights: boolean = true;
        if (this.dataSet != undefined) {
            if (this.dataSet[0] != undefined)
                hasRights = !(this.dataSet.length >= 6)
            if ((!this.premiumUser || this.planLevel == '1') && !hasRights)
                this.freeTrialLimitationExceededFlow(this.user, parseInt(this.cableCalculationStandard), this._companyAccountService, this._router);
            else {
                let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));
                let networkMaxData = this.maxDemandDataArray.find(x => x.Node_Id === selectednode.id || x.Node_Prefix == selectednode.objectcode);
                if (networkMaxData !== undefined && networkMaxData !== null) {
                    this.LoadStorage = new LoadItem();
                    this.loadId = this.loadId + 1;
                    this.LoadStorage.id = this.loadId;
                    this.LoadStorage.loadGroup = loadData.loadGroup;
                    this.LoadStorage.description = loadData.description;
                    this.LoadStorage.loadGroupId = loadData.loadGroupId;
                    this.LoadStorage.installationId = loadData.installationId;
                    this.LoadStorage.subGroupId = loadData.subGroupId;
                    this.LoadStorage.description = loadData.description;
                    this.LoadStorage.phases = loadData.phases;
                    this.LoadStorage.rating = loadData.rating;
                    this.LoadStorage.units = loadData.units;
                    this.LoadStorage.powerFactor = loadData.powerFactor;
                    this.LoadStorage.red = loadData.red;
                    this.LoadStorage.white = loadData.white;
                    this.LoadStorage.blue = loadData.blue;
                    this.LoadStorage.diversity = loadData.diversity;
                    this.LoadStorage.factor = loadData.factor;
                    this.LoadStorage.loadName = loadData.loadName;
                    this.LoadStorage.subName = loadData.subName;
                    this.LoadStorage.loadGroup = loadData.loadGroup;
                    this.LoadStorage.subGroup = loadData.subGroup;
                    this.LoadStorage.quantity = loadData.quantity;
                    this.LoadStorage.automaticAllocation = loadData.automaticAllocation;
                    this.LoadStorage.groupAsCircuit = loadData.groupAsCircuit;
                    this.LoadStorage.loadCode = loadData.loadCode;
                    this.LoadStorage.phaseAllocation = loadData.phaseAllocation;
                    this.LoadStorage.loadType = loadData.loadType;
                    networkMaxData.LoadItemsData.push(this.LoadStorage);

                    this.onInputChange();

                }
            }
        }
    }

    duplicateLoadData() {

    }
    editLoadData(loadData) {

        this.isSubGroupShown = true;
        this.isMaxDemandDataShown = true;
        this.LoadStorage.loadGroupId = loadData[0].loadGroupId;
        this.editLoadGroupId = loadData[0].loadGroupId;
        this.LoadStorage.installationId = loadData[0].installationId;
        this.LoadStorage.subGroupId = loadData[0].subGroupId;
        this.editSubGroupId = loadData[0].subGroupId;
        this.LoadStorage.description = loadData[0].description;
        this.LoadStorage.phases = loadData[0].phases;
        this.LoadStorage.rating = loadData[0].rating;
        this.LoadStorage.units = loadData[0].units;
        this.LoadStorage.powerFactor = loadData[0].powerFactor;
        this.LoadStorage.red = loadData[0].red;
        this.LoadStorage.white = loadData[0].white;
        this.LoadStorage.blue = loadData[0].blue;
        if (this.LoadStorage.phases === 3) {
            this.total = this.LoadStorage.red;
        }
        this.LoadStorage.diversity = loadData[0].diversity;
        this.LoadStorage.factor = loadData[0].factor;
        this.LoadStorage.id = loadData[0].id;
        this.LoadStorage.loadName = loadData[0].loadName;
        this.LoadStorage.subName = loadData[0].subName;
        this.LoadStorage.loadGroup = loadData[0].loadGroup;
        this.LoadStorage.subGroup = loadData[0].subGroup;
        this.LoadStorage.quantity = loadData[0].quantity;
        this.LoadStorage.automaticAllocation = loadData[0].automaticAllocation;
        this.LoadStorage.loadCode = loadData[0].loadCode;
        this.LoadStorage.phaseAllocation = loadData[0].phaseAllocation;
        this.LoadStorage.loadType = loadData[0].loadType;
        this.LoadStorage.groupAsCircuit = loadData[0].groupAsCircuit;
        if (loadData[0].groupAsCircuit == 1) {
            $("#chkgroup").attr("checked", "checked");
        }
        else {
            $("#chkgroup").removeAttr("checked");

        }
        this.mode = 'Edit';
        this.modalHeader = 'Edit Load';
        this.showError = false;
        this.showQuantityError = false;
        this.showMaxError = false;
        $('#addEditItem').modal('show');
        if (((this.LoadStorage.installationId == 7 || this.LoadStorage.installationId == 8) && (this.LoadStorage.loadGroupId == 90 || this.LoadStorage.loadGroupId == 77) && (this.LoadStorage.subGroupId == 124 || this.LoadStorage.subGroupId == 106)) ||
            this.LoadStorage.loadType == 2 ||
            this.LoadStorage.loadType == 4 ||
            this.LoadStorage.loadType == 6 ||
            this.LoadStorage.loadType == 8
            ||
            this.LoadStorage.loadType == 7
        ) {
            this.isHideForLoadTypes = true;
            if ((this.LoadStorage.loadName != "Custom"))
                this.LoadStorage.rating = 0;
        }
        else {
            this.isHideForLoadTypes = false;
        }
        this.editDataIndex = this.LoadStorage.id;
        this.showGroupAsCircuit(this.LoadStorage.installationId, this.LoadStorage.loadGroupId, this.LoadStorage.subGroupId, this.LoadStorage.phases, this.LoadStorage.diversity)
        this.isSubGroupShown = false;
        setTimeout(() => {
            $('#ddlElectricInstallation1').val(this.LoadStorage.installationId).trigger('change', 'false');
            $('#ddlPhases').val(this.LoadStorage.phases).trigger('change');
            $('#ddlUnits').val(this.LoadStorage.units).trigger('change');
            $('#ddlDiversity').val(this.LoadStorage.diversity).trigger('change');

            // $('#ddlElectricInstallation1').val(this.LoadStorage.installationId).trigger('change');
            //     $('#ddlLoadGroup').val(this.LoadStorage.loadGroupId).trigger('change');
            //     $('#ddlSubGroup').val(this.LoadStorage.subGroupId).trigger('change');
            //     $('#ddlPhases').val(this.LoadStorage.phases).trigger('change');
            //     $('#ddlUnits').val(this.LoadStorage.units).trigger('change');
            //     $('#ddlDiversity').val(this.LoadStorage.diversity).trigger('change');
        }, 100);

        if (((this.LoadStorage.installationId == 7 || this.LoadStorage.installationId == 8) && (this.LoadStorage.loadGroupId == 90 || this.LoadStorage.loadGroupId == 77) && (this.LoadStorage.subGroupId == 124 || this.LoadStorage.subGroupId == 106)) ||
            this.LoadStorage.loadType == 2 ||
            this.LoadStorage.loadType == 4 ||
            this.LoadStorage.loadType == 6 ||
            this.LoadStorage.loadType == 8 ||
            this.LoadStorage.loadType == 7) {
            this.isHideForLoadTypes = true;
            if ((this.LoadStorage.loadName != "Custom"))
                this.LoadStorage.rating = 0;
        }
        else {
            this.isHideForLoadTypes = false;
        }

    }
    deleteLoadData(id: any) {
        this.deleteLoadId = id;
        $('#delete-model').modal('show');
    }

    calculateRating(units: any, rating: any, phase: any, powerfactor: any) {
        if (units === 1) {
            return rating;
        }

        if (phase === 1) {
            if (units === 2) {
                //  return  rating/((400/1.73)*powerfactor);
                return (rating / ((231.21) * powerfactor)).toFixed(3);
            }
            else if (units === 3) {
                //            return  rating*1000/((400/1.73)*powerfactor);
                return (rating * 1000 / ((231.21) * powerfactor)).toFixed(3);
            }
        }
        else {
            if (units === 2) {
                //return  rating/((400*1.73)*powerfactor);
                return (rating / ((692) * powerfactor)).toFixed(3);
            }
            else if (units === 3) {
                //return  rating*1000/((400*1.73)*powerfactor);
                return (rating * 1000 / ((692) * powerfactor)).toFixed(3);
            }
        }
    }
    getRatingAsUnit(units: any, rating: any) {
        if (units === 1) {
            return rating + ' ' + 'A';
        } else if (units === 2) {
            return rating + ' ' + 'W';
        }
        else if (units === 3) {
            return rating + ' ' + 'kW';
        }
    }
    getRating(units: any, rating: any, phase: any, powerfactor: any) {
        if (units === 1) {
            return rating;
        }



        if (phase === 1) {
            if (units === 2) {
                //  return  rating/((400/1.73)*powerfactor);
                return (rating * ((231.21) * powerfactor)).toFixed(3);
            }
            else if (units === 3) {
                //            return  rating*1000/((400/1.73)*powerfactor);
                return (rating * ((231.21) * powerfactor) / 1000).toFixed(3);
            }
        }
        else {
            if (units === 2) {
                //return  rating/((400*1.73)*powerfactor);
                return (rating * ((692) * powerfactor)).toFixed(3);
            }
            else if (units === 3) {
                //return  rating*1000/((400*1.73)*powerfactor);
                return (rating * ((692) * powerfactor) / 1000).toFixed(3);
            }
        }
    }

    GetLoadGroupByInstallId(calculationId: number, installId: number = 0) {

        this._calculationService.getLoadGroupByInstallId(calculationId, installId).subscribe(result => {

            if (result && result.items && result.items.length > 0) {

                this.Loaddata = result.items;

                if (this.editLoadGroupId != null && this.editLoadGroupId != 0) {

                    this.LoadStorage.loadGroupId = this.editLoadGroupId;
                    if (this.LoadStorage.loadGroupId != undefined) {
                        let editload = this.Loaddata.filter(x => x.loadId === this.LoadStorage.loadGroupId);
                        if (editload != null && editload != undefined && editload.length > 0) {
                            this.hideControlsForCustomInstallation(editload[0].name.trim());
                        }
                    }
                    this.GetSubGroupByLoadId(this.LoadStorage.loadGroupId);
                }
                else {

                    this.LoadStorage.loadGroupId = this.Loaddata[0].loadId;
                    this.installationName = this.Loaddata[0].installationName;
                    this.installId = this.Loaddata[0].installId;
                    if (this.LoadStorage.loadGroupId != undefined) {
                        let editload = this.Loaddata.filter(x => x.loadId === this.LoadStorage.loadGroupId);
                        if (editload != null && editload != undefined && editload.length > 0) {
                            this.hideControlsForCustomInstallation(editload[0].name.trim());
                        }
                        this.GetSubGroupByLoadId(this.LoadStorage.loadGroupId);
                    }

                }

                (<any>$('#ddlLoadGroup')).select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });

            }
        },
            err => {

            });
    }

    GetSubGroupByLoadId(loadId: number) {
        if (loadId === undefined)
            loadId = 1;

        this._calculationService.getSubGroupByLoadId(loadId).subscribe(result => {

            this.SubData = result.items;
            if (this.SubData.length === 1 && this.SubData[0].name == '') {
                this.SubData[0].name = "No subgroup";
            }

            if (this.SubData.length > 0) {
                this.LoadStorage.loadType = this.SubData[0].loadType;
                this.rules = this.SubData[0].rules;
                this.LoadStorage.loadCode = this.SubData[0].loadCode;
                this.LoadStorage.subGroupId = this.SubData[0].subId;
            }

            (<any>$('#ddlSubGroup')).select2({
                width: '100%',
                minimumResultsForSearch: Infinity // disable search
            });



            // if (this.SubData.length > 0) {
            //    
            //     this.LoadStorage.loadType = this.SubData[0].loadType;
            //     this.rules = this.SubData[0].rules;
            //     this.LoadStorage.loadCode = this.SubData[0].loadCode;

            // }
            //  alert(this.SubData[0].loadType);
            //
            // this.SubData = result.items;
            // if (this.SubData.length > 0) {
            //     this.LoadStorage.subGroupId = this.SubData[0].subId;
            //     this.rules = this.SubData[0].rules;
            //     this.LoadStorage.loadCode = this.SubData[0].loadCode;
            //     this.LoadStorage.loadType = this.SubData[0].loadType;
            // }
            // else {

            //     // else {
            //     //    
            //     //     this.LoadStorage.subGroupId = this.SubData[0].subId;
            //     //     this.LoadStorage.subGroup = 0;
            //     //     this.LoadStorage.subName = this.SubData[0].name;
            //     // }

            // }

            if (this.editDataIndex !== undefined) {

                if (this.editSubGroupId != null && this.editSubGroupId != 0) {

                    this.SubData = result.items;
                    this.LoadStorage.subGroupId = this.editSubGroupId;
                    let esub = this.SubData.filter(x => x.subId === this.LoadStorage.subGroupId);
                    if (esub != null && esub.length > 0) {
                        this.rules = esub[0].rules;
                        this.LoadStorage.loadCode = esub[0].loadCode;
                        this.LoadStorage.loadType = esub[0].loadType;

                    }
                }
                else if (this.editSubGroupId == 0) {
                    this.SubData = result.items;
                    if (this.SubData.length > 0) {
                        this.LoadStorage.subGroupId = this.SubData[0].subId;
                        this.rules = this.SubData[0].rules;
                        this.LoadStorage.loadCode = this.SubData[0].loadCode;
                        this.LoadStorage.loadType = this.SubData[0].loadType;
                    }
                }
            }
            else {

                //if (this.isSubGroupShown) {
                this.SubData = result.items;
                if (this.SubData.length > 0) {
                    this.LoadStorage.subGroupId = this.SubData[0].subId;
                    this.rules = this.SubData[0].rules;
                    this.LoadStorage.loadCode = this.SubData[0].loadCode;
                    this.LoadStorage.loadType = this.SubData[0].loadType;
                }
                //}
            }

            if (((this.LoadStorage.installationId == 7 || this.LoadStorage.installationId == 8) && (this.LoadStorage.loadGroupId == 90 || this.LoadStorage.loadGroupId == 77) && (this.LoadStorage.subGroupId == 124 || this.LoadStorage.subGroupId == 106)) ||
                this.LoadStorage.loadType == 2 ||
                this.LoadStorage.loadType == 4 ||
                this.LoadStorage.loadType == 6 ||
                this.LoadStorage.loadType == 8
                ||
                this.LoadStorage.loadType == 7
            ) {
                this.isHideForLoadTypes = true;
                if (!this.isCustomInstallation)
                    this.LoadStorage.rating = 0;
            }
            else {
                this.isHideForLoadTypes = false;
            }
            this.showGroupAsCircuit(this.LoadStorage.installationId, this.LoadStorage.loadGroupId, this.LoadStorage.subGroupId, this.LoadStorage.phases, this.LoadStorage.diversity)


        }, err => {

        });
    }
    GetAllSubGroup() {
        this._calculationService.getAllAutoMaxSubGroup().subscribe(result => {
            this.allSubGroup = result.items;
        })
    }

    hideControlsForCustomInstallation(loadGroup: any): void {
        if (loadGroup == 'Custom') {
            this.isCustomInstallation = true;

            this.isFactor = false;
        }
        else {
            this.isCustomInstallation = false;
            this.isFactor = true;

        }
        //alert(this.isCustomInstallation);
    }

    blockInstallation(instId: any): void {

        if (instId == 6 || instId == 7 || instId == 8) {
            this.isBlockInstallation = true;
        }
        else
            this.isBlockInstallation = false;
    }

    getSettingReportsMaxDemand() {
        this._settingServiceProxy.getSettings().subscribe(result => {
            this.maxDemandData.Settings = new SettingsData();
            if (result.standards_CableSizing !== undefined) {
                try {

                    this.ReportShowLogo = result.companyLogo_ShowLogo !== undefined ? result.companyLogo_ShowLogo : false;
                    this.ReportShowCustomFields = result.customeReportFields_ShowCustomField !== undefined ? result.customeReportFields_ShowCustomField : false;
                    this.ReportLogoFileName = result.companyLogo_LogoUrl !== undefined ? result.companyLogo_LogoUrl : '';
                    this.ReportCustomFields = result.customFields !== undefined ? result.customFields : undefined;
                    // if (!this.standardSettingsData) {
                    //     this.cableCalculationStandard = result.standards_CableSizing.toString();
                    //     this.ReportStandardCountry = result.standards_CableSizing !== undefined ? result.standards_CableSizing.toString() : '0';

                    //     // this.tempinputXML.NetworkSettings.CableCalcStandard = this.cableCalculationStandard;
                    //     if (this.ReportStandardCountry == "2") {
                    //         this.isBritishStandard = true;
                    //     }
                    //     else
                    //         this.isBritishStandard = false;
                    // }
                } catch (e) { }

                this.maxDemandData.Settings.Standard = result.standards_CableSizing.toString();
                this.maxDemandData.Settings.VoltageFactor = result.short_Circuit_VoltageFactor_C.toString();

                if (result.deafaultVoltages_OnePhaseAVoltage !== undefined)
                    this.maxDemandData.Settings.OnePhaseVoltage = result.deafaultVoltages_OnePhaseAVoltage.toString();

                if (result.deafaultVoltages_ThreePhaseAcvoltage !== undefined)
                    this.maxDemandData.Settings.ThreePhaseVoltage = result.deafaultVoltages_ThreePhaseAcvoltage.toString();

                if (result.deafaultVoltages_DcVoltage !== undefined)
                    this.maxDemandData.Settings.DcVoltage = result.deafaultVoltages_DcVoltage.toString();

            } else {
                try {
                    this.ReportShowLogo = false;
                    this.ReportShowCustomFields = false;
                    this.ReportLogoFileName = '';
                    this.ReportCustomFields = undefined;
                    this.ReportStandardCountry = "0";
                    this.cableCalculationStandard = "0";
                } catch (e) { }

                this.maxDemandData.Settings.Standard = '0';
                this.maxDemandData.Settings.VoltageFactor = '1.1'
                if (this.maxDemandData.Settings.OnePhaseVoltage !== undefined)
                    this.maxDemandData.Settings.OnePhaseVoltage = '230'

                if (this.maxDemandData.Settings.ThreePhaseVoltage !== undefined)
                    this.maxDemandData.Settings.ThreePhaseVoltage = '400'

                if (this.maxDemandData.Settings.DcVoltage !== undefined)
                    this.maxDemandData.Settings.DcVoltage = '1500'

            }
        }, error => {
        });
    }

    GenerateCoordinationReport(): void {
        if (this.IsReportEmailValid() && !this.coordinationloader) {
            this.coordinationloader = true;
            if (this.CalculationReportInput.eamilList)
                localStorage.setItem('ReportSettingEmails', this.CalculationReportInput.eamilList);
            this.CalculationReportInput.companyLogo_ShowLogo = this.ReportShowLogo;
            this.CalculationReportInput.customeReportFields_ShowCustomField = this.ReportShowCustomFields;
            this.CalculationReportInput.companyLogo_FileName = this.ReportLogoFileName;
            this.CalculationReportInput.customFields = this.ReportCustomFields;
            this.CalculationReportInput.reportAttachmentName = 'Protection_Coordination_Report_';
            this.CalculationReportInput.calculationName = this.calculationName;
            this.CalculationReportInput.calculationId = this.newCalculationId;
            this.CalculationReportInput.reportAttachmentName += this.projectName + '_';
            this.CalculationReportInput.reportAttachmentName += this.calculationName + '.pdf';
            this.CalculationReportInput.reportHeaderDate = moment().format('DD-MMM-YYYY hh:mm A').toString();
            this.CalculationReportInput.plotdata = $(".clsimg").val().toString();
            this.CalculationReportInput.nodeids = Object.keys(this.protectiveDrpList).map((k) => { return this.protectiveDrpList[k].Node_Prefix }).join(",");
            this.CalculationReportInput.outputxml = this.protectivecoordinationoutputxml;
            for (var j = 0; j < this.protectiveXmlInputList.length; j++) {
                this.protectiveXmlInputList[j].nodename = this.getNodeNameById(this.protectiveXmlInputList[j].nodeid) + " (" + (this.protectiveXmlInputList[j].devicenostr != null && this.protectiveXmlInputList[j].devicenostr != undefined ? this.protectiveXmlInputList[j].devicenostr.trim() + ")" : '');
            }
            this.CalculationReportInput.rptdata = JSON.stringify(this.protectiveXmlInputList);
            this.CalculationReportInput.errordata = JSON.stringify(this.coordinationWarningErrorList);
            if (!this.TransformerDamage) {
                this.CalculationReportInput.damagetime = null;
                this.CalculationReportInput.damagecurrent = null;
            }

            if (this.powerHVRelay == true || this.transformerHVRelay == true) {
                let hvRelayData: any = {}
                let curveEquation: any = document.getElementById("HVRelay_Curve_Equation");
                if (curveEquation)
                    hvRelayData.curveEquation = curveEquation.options[curveEquation.selectedIndex].text;

                let curveType: any = document.getElementById("HVRelay_Curve_Type");
                if (curveType && curveType.length > 0)
                    hvRelayData.curveType = curveType.options[curveType.selectedIndex].text;

                let pickupCurrent = $("#HVRelay_Pick_Up_Current");
                if (pickupCurrent && pickupCurrent.length > 0)
                    hvRelayData.pickupCurrent = pickupCurrent.val().toString();

                let multiplier = $("#HVRelay_TMS_TDS");
                if (multiplier && multiplier.length > 0)
                    hvRelayData.multiplier = multiplier.val().toString();

                let tolerance = $("#HVRelay_Tolerance");
                if (tolerance && tolerance.length > 0)
                    hvRelayData.tolerance = tolerance.val().toString();

                this.CalculationReportInput.hvrelay = hvRelayData;
            }
            this._pdfReportSerivceProxy.ProtectiveCoordinationGenerateReport(this.CalculationReportInput).subscribe((result) => {
                $('#reportDlg').modal('hide');
                if (!this.CalculationReportInput.sendEmail) {
                    let blob = new Blob([result.body], { type: 'application/pdf' });
                    saveAs(blob, this.CalculationReportInput.reportAttachmentName);
                }
                else {
                    $('#EmailSent').modal('show');
                }
                this.coordinationloader = false;

                this.ClearReport();
            });
        }
    }

    GenerateReport(): void {
        if (this.IsReportEmailValid()) {
            // this.showLoader();
            this.showreportloader = true;
            localStorage.setItem('ReportSettingEmails', this.CalculationReportInput.eamilList);
            this.CalculationReportInput.companyLogo_ShowLogo = this.ReportShowLogo;
            this.CalculationReportInput.customeReportFields_ShowCustomField = this.ReportShowCustomFields;
            this.CalculationReportInput.companyLogo_FileName = this.ReportLogoFileName;
            this.CalculationReportInput.customFields = this.ReportCustomFields;
            this.CalculationReportInput.reportAttachmentName = 'Network_Calculation_Report_';
            this.CalculationReportInput.calculationName = this.calculationName;
            this.CalculationReportInput.calculationId = this.newCalculationId;
            this.CalculationReportInput.reportAttachmentName += this.projectName + '_';
            this.CalculationReportInput.reportAttachmentName += this.calculationName + '.pdf';

            this.CalculationReportInput.reportHeaderDate = moment().format('DD-MMM-YYYY hh:mm A').toString();
            this.CalculationReportInput.warningErrorStrData = this.warningErrorStrData;
            this.tempinputXML.isSingleLineDiagram = 1;
            this._CableproCalc
                .NetworkCalculation(this.tempinputXML).subscribe((response) => {
                    // this.responseCount++;
                    // if (this.requestCount == this.responseCount) {
                    //     this.hideLoader();
                    // }
                    //  this.showLoader();
                    let calculationRes = response.calculationResponse;
                    if (calculationRes == 1) {
                        this.isNewNodeAdded = true;
                        this.tempinputXML.isSingleLineDiagram = 0;

                        // var endTimes = performance.now();
                        // var totalTimes = endTimes - startTimes; // time took to run in milliseconds
                        // this.sldbase64string = response.sld;

                        // $("#newtxtxml").val(response.labviewinput);
                        //$("#txtxml").val(response.input);
                        // $("#xmloutput_text").val(response.output);

                        //this.executionTime = response.executionTimeDll;

                        //this.GetAllResultSectionValues(response);

                        if (this.newCalculationId) {
                            if (this.isXmlStatus === true) {
                                if (navigator.onLine === true) {
                                    //this.showLoader();
                                    this.cablePullingXmlInput = new CablePullingXmlInput();
                                    this.cablePullingXmlInput.id = this.newCalculationId;
                                    this.cablePullingXmlInput.TreeValue = JSON.stringify(this.items);
                                    this.cablePullingXmlInput.InputxmlValue = response.input;
                                    this.cablePullingXmlInput.OutputxmlValue = response.output;
                                    this.cablePullingXmlInput.SwitchboardCount = response.SwitchboardCount;
                                    this.cablePullingXmlInput.Supply = response.Supply;
                                    this.cablePullingXmlInput.cableSizeData = this.tempinputXML.CableSizeCalcInputs;
                                    this.cablePullingXmlInput.HVFuseXml = this.tempinputXML.SupplySettings.HvFuseXml;
                                    this.cablePullingXmlInput.HVFuseTrippingXml = this.tempinputXML.SupplySettings.HvFuseTrippingXml;
                                    this.cablePullingXmlInput.sld = response.sld;
                                    if (this.maxDemandDataArray.length > 0) {
                                        this.cablePullingXmlInput.maxDemandCalcData = JSON.stringify(this.tempinputXML.MaxDemandDataArray);
                                    }
                                    //alert(this.cablePullingXmlInput.TreeValue);

                                    this.UpdateXmlValues(this.cablePullingXmlInput, true);
                                } else {
                                    //this.hideLoader();;
                                    $("#popup-mask").show();
                                }
                            }
                            //this.hideLoader();;
                        }
                    }
                    else {
                        $('#reportDlg').modal('hide');

                        this.ClearReport();
                        //  this.hideLoader();
                        this.showreportloader = false;
                    }
                    //   this.isXmlStatus = true;
                    // this.totaltime = totalTimes.toString();
                    //this.hideLoader();;
                },
                    (error) => {
                        this.hideLoader();;
                        this.showreportloader = false;

                    });



        }
    }
    CloseReport() {
        if (this.Isfromunknownchk) {
            this.protectiveUnknown = true;
            this.CableSize['protection']['IsUnknown'] = this.protectiveUnknown;
            this.updatecablesizeinarray();
            this.onInputChange();
            this.Isfromunknownchk = false;
        }
        let skipCoordination = false;
        if (this.handleUnknownDevice == true) {
            $('#protectiveConfiguration').modal('hide');
            skipCoordination = true;
        }
        this.handleUnknownDevice = false;
        if (this.checkingHVFuse == true) {
            this.HVFuse = false;
            $('#HVFuseChk').prop('checked', false);
        }
        if (this.isFromCoordination && !skipCoordination) {
            let objectNodes = Object.keys(this.protectiveDrpList).map((k) => { return this.protectiveDrpList[k].Node_Prefix }).join(",");
            this.getNetworkProtectiveDataByNodeId(this.newCalculationId, objectNodes, () => {
                setTimeout(() => {
                    if (this.ListLoadStorage.length > 0) {
                        var familyId = this.ListLoadStorage[this.index].family;
                        var arr = familyId.split(' ')[1];
                        $("#devicesettingli").show();
                        $("#normalDetail").show();
                        $("#hvRelayDetail").hide();
                        if (arr != null && arr != "" && (arr == "MCB" || arr == "Fuse")) {
                            $("#devicesettingli").hide();
                            if (document.querySelector("#ulprotectioncoordination").querySelector("#protectiveTab2Open") != null) {
                                (<HTMLElement>document.querySelector("#ulprotectioncoordination").querySelector("#protectiveTab2Open")).click();
                            }
                            if (arr == "MCB") {
                                $(".clsfustype").hide();
                            }
                            if (arr == "Fuse") {
                                $(".clscurveclass").hide();
                            }
                            $(".clstripunit").hide();
                            $(".clsreset").hide();
                            $("#divelectronic").hide();
                            $("#divmagneticonly").hide();
                            $("#divtmagneticonly").hide();
                        }
                        else {
                            $(".clsfustype").hide();
                            $(".clscurveclass").hide();
                            document.getElementById('defaultOpenProtective').click();
                        }
                    }

                    if ($('.c-select').length) {
                        let $singleSelect = $('.c-select'),
                            $singleSelectHasSearch = $('.c-select.has-search'),
                            $multipleSelect = $('.c-select.c-select--multiple'),
                            $disabledSelect = $('.c-select.is-disabled');

                        $singleSelect.select2({
                            width: '100%',
                            minimumResultsForSearch: Infinity // disable search
                        });

                        // multiple select
                        $multipleSelect.select2({
                            width: '100%',
                            multiple: true
                        });

                        // disabled select
                        $disabledSelect.select2({
                            width: '100%',
                            disabled: true
                        });
                    }
                });
            }, true
            );
        }
        else if (!this.isFromCoordination) {
            this.getNetworkProtectiveDataByNodeId(this.newCalculationId, this.activeNode.objectcode);
        }
        $('#protectiveSelector').modal('hide');

    }
    chnagei2tcurve() {

        this.updateElectronic("", { value: 0 });

    }

    showlongtimeprot() {
        if ($("#chcklongtime").is(":checked")) {
            $("#divlongtimeprot").show();
        }
        else {
            $("#divlongtimeprot").hide();
        }

        this.updateElectronic("", { value: 0 });

    }
    showshorttimeprot() {
        if ($("#chckshorttime").is(":checked")) {
            $("#divshorttime").show();
        }
        else {
            $("#divshorttime").hide();
        }
        this.updateElectronic("", { value: 0 });
    }

    //      

    showinstantprot() {
        if ($("#chckInstant").is(":checked")) {
            $("#divinstant").show();
        }
        else {
            $("#divinstant").hide();
        }
        this.updateElectronic("", { value: 0 });

    }
    resetDevice() {
        this.ProtectiveLoadDataXml = new ProtectiveData();


        var manufacturerId = parseInt(this.ListLoadStorage[this.index].manufacturer);
        var familyId = this.ListLoadStorage[this.index].family;

        var modelId = parseInt(this.ListLoadStorage[this.index].model.toString());
        var d = this.ListLoadStorage[this.index].rating.toString();
        var ee = this.ListLoadStorage[this.index].tripunit.toString();
        var curve = (ee.toString());
        this.ProtectiveLoadStorage.desc = this.ListLoadStorage[this.index].desc.toString()
        var res = curve;
        if (curve.indexOf('-') != -1) {
            res = curve.substr(0, curve.lastIndexOf('-'));
            res = this.trimChar(res.trim(), "-");
        }
        this.GetBreakingByManufacturerId(manufacturerId, familyId, modelId, d.toString(), res);
        this.GetPolesTableByManufacturerId(manufacturerId, familyId, modelId, d.toString(), res);


        this.multiplierEIR = 1;
        this.multiplierIN = 1;
        this.multiplierEIO = 1;
        $('#mcbacb-device').text(this.ProtectiveLoadStorage.desc);
        var arr = familyId.split(' ')[1];
        $(".clsreset").show();
        $(".clstripunit").show();
        $(".clscurveclass").show();
        $(".clsfustype").show();
        $("#devicesettingli").show();
        $("#normalDetail").show();
        $("#hvRelayDetail").hide();
        if (arr != null && arr != "" && (arr == "MCB" || arr == "Fuse")) {
            $("#devicesettingli").hide();
            //document.getElementById('tab2Open').click();
            if (arr == "MCB") {
                $(".clsfustype").hide();
            }
            if (arr == "Fuse") {
                $(".clscurveclass").hide();

            }
            $(".clstripunit").hide();
            $(".clsreset").hide();
            $("#divmcbacbonly").show();
            $("#divelectronic").hide();
            $("#divmagneticonly").hide();
            $("#divtmagneticonly").hide();
            this.ProtectiveGetXml(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', '', '', ''
                , '', '', '', ''
                , '', ''
                , '', ''
                , '', ''
                , '', 1, '', '', true);
        }
        else {
            $(".clsfustype").hide();
            $(".clscurveclass").hide();

            $("#divmcbacbonly").hide();

            this.ProtectiveGetXml(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', '', '', ''
                , '', '', '', ''
                , '', ''
                , '', ''
                , '', ''
                , '', 1, '---', '', true);

        }

    }
    exportCad(): void {
        this.tempinputXML.isSingleLineDiagram = 1;
        this.tempinputXML.isDXF = 1;
        this.onInputChange();
    }

    private dxfElement = {
        element: {
            dynamicDownload: null as HTMLElement
        }
    }

    private textToFileDownload(fileName: string, text: string) {
        if (!this.dxfElement.element.dynamicDownload) {
            this.dxfElement.element.dynamicDownload = document.createElement('a');
        }
        const element = this.dxfElement.element.dynamicDownload;
        const fileType = 'text/plain';
        element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(text)}`);
        element.setAttribute('download', fileName);

        var event = new MouseEvent("click");
        element.dispatchEvent(event);
    }

    showWarningErrorPopup() {
        $('#warnings-errors-model').modal('show');
    }

    coordinationShowWarningErrorPopup() {
        $('#coordination-warnings-errors-model').modal('show');
    }

    pageChanged(event) {
        this.paginationConfig.currentPage = event;
    }

    coordinationPageChanged(event) {
        this.coordinationPaginationConfig.currentPage = event;
    }

    generateErrorsModel(errorStr) {
        this.warningErrorList = errorStr;
        if (this.warningErrorList && errorStr != "[]" && this.warningErrorList.length > 0) {

            this.warningErrorList = this.warningErrorList.filter(m => m.ErrorCode);
            this.warningErrorListPristine = Object.assign([], this.warningErrorList);
            if (this.warningErrorList && this.warningErrorList.length > 0) {
                this.warningErrorList = this.warningErrorList.filter(m => m.ErrorCode != "5009");
                if (this.warningErrorList && this.warningErrorList.length > 0) {
                    this.warningErrorList = this.warningErrorList.sort((a, b) => a.ErrorCode < b.ErrorCode ? -1 : 1).sort((a, b) => a.Type < b.Type ? -1 : 1).sort((a, b) => parseInt(a.IsError) > parseInt(b.IsError) ? -1 : 1);
                    this.warningErrorStrData = JSON.stringify(this.warningErrorList);
                    this.displayErrorBtn = true;
                    this.paginationConfig.totalItems = this.warningErrorList.length;
                    let errors = this.warningErrorList.filter(m => m.IsError == "1");
                    let warnings = this.warningErrorList.filter(m => m.IsError == "0");
                    if (errors && errors.length > 0) {
                        this.warningErrorBtnText = "Errors (" + errors.length + ")";
                    }
                    else {
                        this.warningErrorBtnText = "Warnings (" + warnings.length + ")";
                    }

                    if ((errors && errors.length > 0) && (warnings && warnings.length > 0)) {
                        this.warningErrorHeader = "Errors (" + errors.length + ") and Warnings (" + warnings.length + ")";
                    }
                    else if ((errors && errors.length > 0) && (!warnings || warnings.length == 0))
                        this.warningErrorHeader = "Errors (" + errors.length + ") and Warnings";
                    else if ((warnings && warnings.length > 0) && (!errors || errors.length == 0))
                        this.warningErrorHeader = "Errors and Warnings (" + warnings.length + ")";
                    else {
                        this.warningErrorHeader = "Errors and Warnings";
                    }
                }
                else
                    this.displayErrorBtn = false;
            }
        }
        else {
            this.displayErrorBtn = false;
        }

    }
    detectBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase()
        switch (true) {
            case agent.indexOf('edge') > -1:
                return 'edge';
            case agent.indexOf('opr') > -1 && !!(<any>window).opr:
                return 'opera';
            case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
                return 'chrome';
            case agent.indexOf('trident') > -1:
                return 'ie';
            case agent.indexOf('firefox') > -1:
                return 'firefox';
            case agent.indexOf('safari') > -1:
                return 'safari';
            default:
                return 'other';
        }
    }
    coordinationGenerateErrorsModel(error1: string | undefined, error2: string | undefined) {
        this.coordinationWarningErrorList = [];
        this.coordinationDisplayErrorBtn = false;
        if (error1 == "0" || error2 == "0")
            this.coordinationDisplayErrorBtn = true;
        else {
            return;
        }

        if (error1 == "0" && error2 == "0")
            this.coordinationWarningErrorBtnText = "Errors (2)";
        else
            this.coordinationWarningErrorBtnText = "Errors (1)";

        this.coordinationWarningErrorList = [];

        if (error1 == "0") {
            if (this.protectiveDrpList.length > 2) {
                this.coordinationWarningErrorList.push({ IsError: "1", Message: "Inner curve of the next upstream device should be greater than " + parseFloat(parseFloat(this.discriminatedData.discriminatedfirstone).toFixed(2).replace(/\.0+$/, '')) + " A at the time of minimum separation (" + parseFloat(parseFloat(this.discriminatedData.discriminatedfirsttwo).toFixed(2).replace(/\.0+$/, '')) + " s) to achieve discrimination with the upstream device.", ErrorCode: "5501", Type: "Error" });
            }
            else {
                this.coordinationWarningErrorList.push({ IsError: "1", Message: "Inner curve of the upstream device should be greater than " + parseFloat(parseFloat(this.discriminatedData.discriminatedfirstone).toFixed(2).replace(/\.0+$/, '')) + " A at the time of minimum separation (" + parseFloat(parseFloat(this.discriminatedData.discriminatedfirsttwo).toFixed(2).replace(/\.0+$/, '')) + " s) to achieve discrimination with the downstream device.", ErrorCode: "5501", Type: "Error" });
            }
        }

        if (error2 == "0") {
            this.coordinationWarningErrorList.push({ IsError: "1", Message: "Inner curve of the upstream device should be greater than " + parseFloat(parseFloat(this.discriminatedData.discriminatedsecondone).toFixed(2).replace(/\.0+$/, '')) + " A at the time of minimum separation (" + parseFloat(parseFloat(this.discriminatedData.discriminatedsecondtwo).toFixed(2).replace(/\.0+$/, '')) + " s) to achieve discrimination with the downstream device.", ErrorCode: "5501", Type: "Error" });
        }

        this.coordinationWarningErrorHeader = "Coordination Errors and Warnings";
        this.coordinationPaginationConfig.totalItems = this.coordinationWarningErrorList.length;
    }

    public getDefaultSupplyVoltage(activeNodeId: any) {

        let networkSupplyVoltage = this.SupplyVoltage;// $('#SupplyVoltage').val().toString();
        // this.CableproInput.PhaseId
        if (networkSupplyVoltage == "0") {
            if (this.CableproInput.PhaseId == 100 || this.CableproInput.PhaseId == 103 || this.CableproInput.PhaseId == 104) {
                this.onePhase_Voltage = '230';
                // $('input[name="Voltage-Supply"]').val(this.onePhase_Voltage);
                this.voltage_txt = this.onePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
            }
            else if (this.CableproInput.PhaseId == 101) {
                this.threePhase_Voltage = '400';
                // $('input[name="Voltage-Supply"]').val(this.threePhase_Voltage);
                this.voltage_txt = this.threePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
            }
            else if (this.CableproInput.PhaseId == 102) {
                this.dc_Voltage = '1500';
                // $('input[name="Voltage-Supply"]').val(this.dc_Voltage);
                this.voltage_txt = this.dc_Voltage;
                this.CableSize['supplyvoltage'] = this.dc_Voltage;
            }

        }
        else if (networkSupplyVoltage == "1") {
            if (this.CableproInput.PhaseId == 100 || this.CableproInput.PhaseId == 103 || this.CableproInput.PhaseId == 104) {
                this.onePhase_Voltage = '240';
                // $('input[name="Voltage-Supply"]').val(this.onePhase_Voltage);
                this.voltage_txt = this.onePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
            }
            else if (this.CableproInput.PhaseId == 101) {
                this.threePhase_Voltage = '415';
                // $('input[name="Voltage-Supply"]').val(this.threePhase_Voltage);
                this.voltage_txt = this.threePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
            }
            else if (this.CableproInput.PhaseId == 102) {
                this.dc_Voltage = '1500';
                // $('input[name="Voltage-Supply"]').val(this.dc_Voltage);
                this.voltage_txt = this.dc_Voltage;
                this.CableSize['supplyvoltage'] = this.dc_Voltage;
            }
        }
        else if (networkSupplyVoltage == "2") {
            if (this.CableproInput.PhaseId == 100 || this.CableproInput.PhaseId == 103 || this.CableproInput.PhaseId == 104) {
                this.onePhase_Voltage = '254';
                // $('input[name="Voltage-Supply"]').val(this.onePhase_Voltage);
                this.voltage_txt = this.onePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
            }
            else if (this.CableproInput.PhaseId == 101) {
                this.threePhase_Voltage = '440';
                // $('input[name="Voltage-Supply"]').val(this.threePhase_Voltage);
                this.voltage_txt = this.threePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
            }
            else if (this.CableproInput.PhaseId == 102) {
                this.dc_Voltage = '1500';
                // $('input[name="Voltage-Supply"]').val(this.dc_Voltage);
                this.voltage_txt = this.dc_Voltage;
                this.CableSize['supplyvoltage'] = this.dc_Voltage;
            }
        }
        else if (networkSupplyVoltage == "3") {
            if (this.CableproInput.PhaseId == 100 || this.CableproInput.PhaseId == 103 || this.CableproInput.PhaseId == 104) {
                this.onePhase_Voltage = '220';
                // $('input[name="Voltage-Supply"]').val(this.onePhase_Voltage);
                this.voltage_txt = this.onePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
            }
            else if (this.CableproInput.PhaseId == 101) {
                this.threePhase_Voltage = '380';
                // $('input[name="Voltage-Supply"]').val(this.threePhase_Voltage);
                this.voltage_txt = this.threePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
            }
            else if (this.CableproInput.PhaseId == 102) {
                this.dc_Voltage = '1500';
                // $('input[name="Voltage-Supply"]').val(this.dc_Voltage);
                this.voltage_txt = this.dc_Voltage;
                this.CableSize['supplyvoltage'] = this.dc_Voltage;
            }
        }
        else if (networkSupplyVoltage == "4") {
            if (this.CableproInput.PhaseId == 100 || this.CableproInput.PhaseId == 103 || this.CableproInput.PhaseId == 104) {
                this.onePhase_Voltage = '400';
                // $('input[name="Voltage-Supply"]').val(this.onePhase_Voltage);
                this.voltage_txt = this.onePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
            }
            else if (this.CableproInput.PhaseId == 101) {
                this.threePhase_Voltage = '690';
                // $('input[name="Voltage-Supply"]').val(this.threePhase_Voltage);
                this.voltage_txt = this.threePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
            }
            else if (this.CableproInput.PhaseId == 102) {
                this.dc_Voltage = '1500';
                // $('input[name="Voltage-Supply"]').val(this.dc_Voltage);
                this.voltage_txt = this.dc_Voltage;
                this.CableSize['supplyvoltage'] = this.dc_Voltage;
            }
        }
        else if (networkSupplyVoltage == "5") {
            if (this.CableproInput.PhaseId == 100 || this.CableproInput.PhaseId == 103 || this.CableproInput.PhaseId == 104) {
                this.onePhase_Voltage = '380';
                // $('input[name="Voltage-Supply"]').val(this.onePhase_Voltage);
                this.voltage_txt = this.onePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
            }
            else if (this.CableproInput.PhaseId == 101) {
                this.threePhase_Voltage = '660';
                // $('input[name="Voltage-Supply"]').val(this.threePhase_Voltage);
                this.voltage_txt = this.threePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
            }
            else if (this.CableproInput.PhaseId == 102) {
                this.dc_Voltage = '1500';
                // $('input[name="Voltage-Supply"]').val(this.dc_Voltage);
                this.voltage_txt = this.dc_Voltage;
                this.CableSize['supplyvoltage'] = this.dc_Voltage;
            }
        }
        else if (networkSupplyVoltage == "6") {
            if (this.CableproInput.PhaseId == 100 || this.CableproInput.PhaseId == 103 || this.CableproInput.PhaseId == 104) {
                this.onePhase_Voltage = '577';
                // $('input[name="Voltage-Supply"]').val(this.onePhase_Voltage);
                this.voltage_txt = this.onePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.onePhase_Voltage;
            }
            else if (this.CableproInput.PhaseId == 101) {
                this.threePhase_Voltage = '1000';
                // $('input[name="Voltage-Supply"]').val(this.threePhase_Voltage);
                this.voltage_txt = this.threePhase_Voltage;
                this.CableSize['supplyvoltage'] = this.threePhase_Voltage;
            }
            else if (this.CableproInput.PhaseId == 102) {
                this.dc_Voltage = '1500';
                // $('input[name="Voltage-Supply"]').val(this.dc_Voltage);
                this.voltage_txt = this.dc_Voltage;
                this.CableSize['supplyvoltage'] = this.dc_Voltage;
            }
        }
        if (activeNodeId) {
            this.tempcablesizelist.forEach(
                itemnode => {
                    if (itemnode.Node_Id == activeNodeId.toString()) {
                        itemnode.CableSizeCalcInputXML['supplyvoltage'] = this.CableSize['supplyvoltage'];

                    }
                });

            this.tempinputXML.CableSizeCalcInputs = JSON.stringify(this.tempcablesizelist);
            //this.onInputChange();
        }
    }

    getErrorMsg(error: string): string {
        let ret: string = '';
        if (error) {
            return error.replace(/\\"/g, '"').replace(/lessthansymbol/g, "<");
        }
        return ret;
    }
    // protective device changes
    GetManufacturer(callback: any = undefined) {

        this._settingService.getManufacturer(this.canHaveHv).subscribe(result => {

            if (result !== undefined && result !== null) {

                this.manufacturerOutput = result[0].items;
                if (this.manufacturerOutput.length > 0) {
                    var manId = 104;
                    if (this.canHaveHv) {
                        manId = this.manufacturerOutput[0].id;
                        this.defaultManId = manId;
                        let selectedObj = this.manufacturerOutput.find(m => m.id == manId);
                        var name = selectedObj.name + "logo";
                        this.protectiveImgSrc2 = '/assets/cablepro/logo/' + name + '.svg';
                    }

                    if (this.loadProtectiveXmlInput != null && this.loadProtectiveXmlInput.manufacturer_id > 0) {

                        manId = this.loadProtectiveXmlInput.manufacturer_id;
                        this.defaultManId = manId;


                    }

                    if (callback)
                        callback();
                    this.GetFamilyGroupByManufacturerId(manId, true);

                }

            }
        }, error => {
        });
    }
    GetFamilyGroupByManufacturerId(manufacturerId: number, pageload: boolean = false) {

        this._settingService.getFamilyByManufacturer(manufacturerId, this.canHaveHv).subscribe(result => {

            if (result !== undefined && result !== null) {
                this.selectedFamily = result[0].items[0];

                this.familyOutput = result[0].items;
                this.model = result[0].items[0].familyid;

                this.defaultFamilyId = this.model;
                if (this.loadProtectiveXmlInput != null && this.loadProtectiveXmlInput.range_id != null && this.loadProtectiveXmlInput.range_id != "") {
                    this.model = this.loadProtectiveXmlInput.range_id;
                    this.defaultFamilyId = this.model;
                }

                if (this.model != null && this.model != "") {
                    var arr = this.model.split(' ')[1];
                    if (arr != null && arr != "" && (arr == "MCCB" || arr == "ACB")) {
                        this.selectListFive = "Trip unit";
                        // this.curveClassOrTripUnit = "Trip unit";
                    }
                    else if (arr != null && arr != "" && arr == "Fuse") {
                        this.selectListFive = "Fuse type";
                        // this.curveClassOrTripUnit = "Fuse type";
                    }
                    else {
                        this.selectListFive = "Curve class";
                        // this.curveClassOrTripUnit = "Curve class";
                    }
                }
                this.GetModelGroupByManufacturerIdnew(manufacturerId, this.model, this.FilterBreaking);



            }

        },
            err => {
                this.btnAddDeviceRequestCounter = 0;
            });
    }
    GetModelGroupByManufacturerIdnew(manufacturerId: number, familyId: string, filterBreaking: number) {

        if (this.chkexportlimited == true) {

        }
        else {
            filterBreaking = 0;
        }

        this.deviceOutput = new DeviceOutput();
        this._settingService.getModelByManufacturernew(manufacturerId, familyId, filterBreaking, this.canHaveHv).subscribe(result => {
            if (result !== undefined && result.one !== null) {
                this.modelOutput = result.one;


            }
            if (result !== undefined && result.two !== null) {
                this.mcbOutput = result.two;

            }
            else {
                this.mcbOutput = [];
            }
            if (this.selectedModalId != null && this.selectedModalId != 0) {
                let selectedModel = this.modelOutput.find(m => m.id == this.selectedModalId);
                // this.txtmodal = (selectedModel != null ? selectedModel.name : '');
            }
            if (result !== undefined && result.three !== null) {
                this.curveOutput = result.three;

            }
            else {
                this.curveOutput = [];
            }
            if (this.loadProtectiveXmlInput != null && this.loadProtectiveXmlInput.tripunit != null && this.loadProtectiveXmlInput.tripunit != "" && this.loadProtectiveXmlInput.tripunit != undefined) {
                this.defaultCurveId = this.loadProtectiveXmlInput.tripunit;
            }
            else {
                if (this.curveOutput && this.curveOutput.length > 0)
                    this.defaultCurveId = this.curveOutput[0].cc;
            }

            if (this.loadProtectiveXmlInput != null && this.loadProtectiveXmlInput.model != null && this.loadProtectiveXmlInput.model != 0 && this.loadProtectiveXmlInput.model != undefined) {
                this.defaultModelId = this.loadProtectiveXmlInput.model;

                this.GetRatingByManufacturerId(manufacturerId, familyId, this.defaultModelId, true);

            } else {

                this.defaultModelId = this.modelOutput[0].id;
                this.GetRatingByManufacturerId(manufacturerId, familyId, this.modelOutput[0].id, true);
            }


            if (this.loadProtectiveXmlInput != null && this.loadProtectiveXmlInput.xml != null && this.loadProtectiveXmlInput.xml != "" && this.loadProtectiveXmlInput.xml != undefined) {
                this.xmlData = this.loadProtectiveXmlInput.xml;

            }



        },
            err => {
                this.btnAddDeviceRequestCounter = 0;
            });
    }
    GetRatingByManufacturerId(manufacturerId: number, familyId: string, modelId: number, triggerEvent: boolean) {
        this.mcbOutput = [];

        this._settingService.getRatingByManufacturer(manufacturerId, familyId, modelId).subscribe(result => {
            if (result !== undefined && result !== null) {
                this.mcbOutput = result[0].items;
                if (this.isloadfirsttime) {
                    this.defaultRatingId = '100';
                }
                else {
                    if (this.mcbOutput && this.mcbOutput.length > 0)
                        this.defaultRatingId = (this.mcbOutput[0].ina.toString());
                }

                this.isloadfirsttime = false;
                if (this.loadProtectiveXmlInput != null && this.loadProtectiveXmlInput.rating != null && this.loadProtectiveXmlInput.rating != "") {
                    this.defaultRatingId = this.loadProtectiveXmlInput.rating;
                }


                if (triggerEvent && this.mcbOutput.length > 0) {
                    this.GetCurveByManufacturerId(manufacturerId, familyId, modelId, true, this.defaultRatingId);
                }
            }
        },
            err => {
                this.btnAddDeviceRequestCounter = 0;
            });
    }
    GetCurveByManufacturerId(manufacturerId: number, familyId: string, modelId: number, triggerEvent: boolean, rating: string) {
        this.curveOutput = [];
        this._settingService.getCurveByManufacturer(manufacturerId, familyId, modelId, rating).subscribe(result => {

            this.btnAddDeviceDisabled = false;

            if (result !== undefined && result !== null) {
                this.curveOutput = result[0].items;

                if (this.curveOutput.length > 0) {
                    if (!this.isloadedfirst) {
                        if (this.curveOutput && this.curveOutput.length > 0)
                            this.defaultCurveId = (this.curveOutput[0].cc.toString());

                        if (this.loadProtectiveXmlInput != null && this.loadProtectiveXmlInput.tripunit != null && this.loadProtectiveXmlInput.tripunit != "") {
                            this.defaultCurveId = this.loadProtectiveXmlInput.tripunit;
                        }
                        this.GetBreakingByManufacturerId(manufacturerId, familyId, modelId, rating, this.defaultCurveId);
                    }
                    else {
                        this.isloadedfirst = false;
                    }
                }

            }
            setTimeout(() => {
                if (this.btnAddDeviceRequestCounter > 0)
                    this.btnAddDeviceRequestCounter -= 1;
            }, 1000);

        },
            err => {
                this.btnAddDeviceDisabled = false;
                this.btnAddDeviceRequestCounter = 0;

            });
    }
    GetPolesTableByManufacturerId(manufacturerId: number, familyId: string, modelId: number, rating: string, curve: string) {
        this.polesTableOutput = [];
        this._settingService.getPolesTableByManufacturer(manufacturerId, familyId, modelId, rating, curve).subscribe(result => {


            if (result !== undefined && result !== null) {
                this.polesTableOutput = result[0].items;


            }
        },
            err => {

            });

    }
    GetBreakingByManufacturerId(manufacturerId: number, familyId: string, modelId: number, rating: string, curve: string) {

        this._settingService.getBreakingByManufacturerId(manufacturerId, familyId, modelId, rating, curve).subscribe(result => {
            if (result !== undefined && result !== null) {
                this.breakingOutput = result[0].items;


            }
        },
            err => {

            });
    }
    protectiveImgSrc = '/assets/cablepro/logo/a.png';
    protectiveImgSrc2 = '/assets/cablepro/logo/a.png';
    onManufacturerChange(val) {
        if (val != undefined) {
            this.btnAddDeviceDisabled = true;
            this.isSelectorChange = true;
            this.btnAddDeviceRequestCounter += 1;
            this.loadProtectiveXmlInput = new ProtectiveXmlInput();
            let selectedObj = this.manufacturerOutput.find(m => m.id == parseInt(val));
            this.GetFamilyGroupByManufacturerId(parseInt(val));
            if (selectedObj.name != "Generic") {
                this.IsGeneric = true;
                var name = selectedObj.name + "logo";
                this.protectiveImgSrc2 = '/assets/cablepro/logo/' + name + '.svg';
                // $('#divlogo img').attr('src', '/assets/cablepro/logo/' + name + '.svg');
            }
            else {
                this.protectiveImgSrc2 = '/assets/cablepro/logo/a.png';
                // $('#divlogo img').attr('src', '/assets/cablepro/logo/a.png');
                this.IsGeneric = false;

            }
            this.defaultManId = val;
        }
    }

    onFamilyChange(val) {
        if (val !== undefined) {
            this.btnAddDeviceDisabled = true;
            this.isSelectorChange = true;
            this.btnAddDeviceRequestCounter += 1;
            this.loadProtectiveXmlInput = new ProtectiveXmlInput();
            var familyId = val.toString();
            if (familyId != null && familyId != "") {
                var arr = familyId.split(' ')[1];
                if (arr != null && arr != "" && (arr == "MCCB" || arr == "ACB")) {
                    this.selectListFive = "Trip unit";
                    // this.curveClassOrTripUnit = "Trip unit";

                }
                else if (arr != null && arr != "" && arr == "Fuse") {
                    this.selectListFive = "Fuse type";
                    // this.curveClassOrTripUnit = "Fuse type";

                }
                else {
                    this.selectListFive = "Curve class";
                    // this.curveClassOrTripUnit = "Curve class";

                }
            }
            this.defaultFamilyId = val;
            this.model = val;
            this.gfamilyId = familyId;
            this.gmanufacturerId = this.defaultManId;
            this.changed();


        }
    }

    onModelChange(val) {
        if (val !== undefined) {
            this.btnAddDeviceDisabled = true;
            this.isSelectorChange = true;
            this.btnAddDeviceRequestCounter += 1;
            this.loadProtectiveXmlInput = new ProtectiveXmlInput();
            var a = this.defaultManId;//$('#ddlManufacturer option:selected').val();
            var manufacturerId = parseInt(a.toString());

            this.ddlmodel = val;//parseInt($('#ddlModel option:selected').val().toString());

            this.defaultModelId = val;
            this.gfamilyId = this.defaultFamilyId;
            this.gmanufacturerId = manufacturerId;
            this.gmodelId = this.defaultModelId;
            this.ddlchanged();

        }
    }
    changed() {
        this.protectiveModelChanged.next();
    }
    ddlchanged() {
        this.ddlmodelChanged.next();
    }
    onRatingChange(val) {
        if (val !== undefined) {
            this.btnAddDeviceDisabled = true;
            this.isSelectorChange = true;
            this.btnAddDeviceRequestCounter += 1;
            this.loadProtectiveXmlInput = new ProtectiveXmlInput();
            this.defaultRatingId = val;
            this.GetCurveByManufacturerId(this.defaultManId, this.defaultFamilyId.toString(), this.defaultModelId, true, val);
        }
    }

    onCurveChange(val) {
        if (val !== undefined) {
            this.defaultCurveId = val;
            this.isSelectorChange = true;
        }
    }

    public deviceList: string[] = [];
    public deviceNumber: string = 'Device 01';
    deviceNoInString(number: number) {
        // if (number <= 9) {
        //     return "Device 0" + number;

        // }
        // else {
        //     return "Device " + number;

        // }
        var indexCoordinationCurve = this.deviceList[number - 1];
        if (indexCoordinationCurve == undefined) {
            if (number <= 9) {
                return "Device 0" + number;

            }
            else {
                return "Device " + number;

            }
        }

        return indexCoordinationCurve.replace(/\\'/g, "'").replace(/'/g, "\\'");

    }

    showProtectiveDeviceUI(fromclient = "") {
        this.FilterBreaking = 5;
        this.IsFormvalid = true;
        this.deviceNumber = "";

        if (fromclient == "1") {
            this.modifyindex = -1;
            this.btnAddDeviceDisabled = false;
            this.btnAddDeviceRequestCounter = 0;
            this.deviceNumber = "Device 01";//this.deviceNoInString(this.deviceno + 1);

        }
        if (this.modifyindex == -1) {
            this.deviceNumber = "Device 01";//this.deviceNoInString(this.deviceno + 1);

        }

        if (!this.isFromCoordination && this.ListLoadStorage && this.ListLoadStorage.length > 0)
            this.deviceNumber = this.ListLoadStorage[0].devicenostr;
        else if (this.isFromCoordination && this.ListLoadStorage && this.ListLoadStorage.length > 0)
            this.deviceNumber = this.ListLoadStorage[this.index].devicenostr;

        if (!this.isFromCoordination && (!this.premiumUser || this.planLevel == '1' || this.planLevel == '2') && this.ListLoadStorage.length == 2 && this.modifyindex == -1) {
            $('#limit-trial-device').modal('show');
        }
        else if (!this.isFromCoordination && this.ListLoadStorage.length == 8 && this.modifyindex == -1) {
            $('#limit-device').modal('show');
        }
        else {
            $('#protectiveSelector').modal('show');
            this.btnAddDeviceRequestCounter = 0;
        }


    }

    trimChar(string, charToRemove) {
        while (string.charAt(0) == charToRemove) {
            string = string.substring(1);
        }

        while (string.charAt(string.length - 1) == charToRemove) {
            string = string.substring(0, string.length - 1);
        }

        return string;
    }

    callProtectiveXmlWithExisting() {
        var objinput: any = {};
        objinput.calculationid = this.newCalculationId;
        objinput.nodeid = this.activeNode.objectcode;

        let trippingtime;
        if (!this.protectionConfigurationModel.MaxDisconnectionTime)
            trippingtime = "0.4";
        else {
            if (this.protectionConfigurationModel.MaxDisconnectionTime == "Custom")
                trippingtime = this.protectionConfigurationModel.CustomTime;
            else
                trippingtime = this.protectionConfigurationModel.MaxDisconnectionTime;
        }
        objinput.trippingtime = trippingtime;
        objinput.items = this.protectiveXmlInputList;
        objinput.xml_Original = this.xmlData_Original;
        this.coordinationloader = true;
        if (this.xmlData_Original) {
            this._settingService.updateNetworkProtectiveForConnectionTime(objinput).subscribe(result => {
                if (result !== undefined && result !== null) {
                    var arr = JSON.parse(result);
                    this.xmlData = arr.xml;
                    this.xmlData_Original = arr.xml_Original;
                    this.xmlData_Tripping = arr.xml_TrippingCalculation;
                    this.deviceType = arr.type;

                    this.CableSize['xml'] = this.xmlData;
                    this.CableSize['row'] = this.index;
                    if (this.xmlData) {
                        this.CableSize['BrowserName'] = this.detectBrowserName();
                        this.CableSize['calculationType'] = "Network Calculation";
                        this.CableSize['calculationId'] = this.newCalculationId;
                        this.CableSize['UserName'] = this.user.userName;
                        this._CableproCalc.ProtectiveCalculate(this.CableSize).subscribe((result) => {
                            this.coordinationloader = false;
                            this.trippingCurrentAtSpecifiedTime = result.trippingCurrentAtSpecifiedTime;
                            this.GenerateMaxAllowedEFLImpedance();
                            this.runCurveEvent(this.index);
                        });
                    }
                }
            });
        }

    }

    ProtectiveGetXml(manufacturerId: number, familyId: string, modelId: number, rating: string, curve: string
        , tmir: string, tmim: string, mim: string, currenttolerance: string, timetolerance: string, timetobreak: string
        , eratingplug: string, eltbool: string, estbool: string, eintbool: string
        , eio: string, eir: string
        , etr: string, eisd: string
        , etsd: string, ei2tcurve: string
        , eli: string, setvalue: number = 0

        , controlchangedId: string = "", idmtl: string, rpchanged: boolean = false, modifyonlydevice: boolean = false
        , modifydevicenameonly: boolean = false
    ) {

        var objinput = new ListProtectiveInput();
        objinput.calculationid = this.newCalculationId;
        if (this.protectiveDrpList && this.protectiveDrpList.length > 0) {
            let ind = this.index
            if (this.protectiveDrpList[0].HvRelay == true) {
                ind += 1;
            }
            objinput.nodeid = this.protectiveDrpList[ind].Node_Prefix;
        }
        else {
            objinput.nodeid = this.activeNode.objectcode;
        }
        objinput.tmir = tmir;
        objinput.tmim = tmim;
        objinput.mim = mim;
        // if (ei2tcurve == '') {
        //     ei2tcurve = "-";

        // } 
        objinput.currenttolerance = currenttolerance;
        objinput.timetolerance = timetolerance;
        objinput.timetobreak = timetobreak;
        objinput.eltboolproperty = eltbool;
        objinput.estboolproperty = estbool;
        objinput.eintboolproperty = eintbool;
        objinput.eio = eio;
        objinput.eir = eir;
        objinput.etr = etr;
        objinput.eisd = eisd;
        objinput.eratingplug = eratingplug;
        objinput.etsd = etsd;
        objinput.ei2tcurve = ei2tcurve;
        objinput.eli = eli;
        objinput.idmtl = idmtl;
        let trippingtime;
        if (!this.protectionConfigurationModel.MaxDisconnectionTime)
            trippingtime = "0.4";
        else {
            if (this.protectionConfigurationModel.MaxDisconnectionTime == "Custom")
                trippingtime = this.protectionConfigurationModel.CustomTime;
            else
                trippingtime = this.protectionConfigurationModel.MaxDisconnectionTime;
        }
        objinput.trippingtime = trippingtime;
        //objinput.trippingcurrent = $("#current").val().toString()//s.trippingcurrent;
        if (this.modifyindex >= 0) {
            // if (modifyonlydevice == false) {
            this.protectiveXmlInputList.find(x => x.rownumber == this.index).model = modelId;
            this.protectiveXmlInputList.find(x => x.rownumber == this.index).manufacturer_id = manufacturerId;
            this.protectiveXmlInputList.find(x => x.rownumber == this.index).range_id = familyId;
            this.protectiveXmlInputList.find(x => x.rownumber == this.index).tripunit = curve;
            this.protectiveXmlInputList.find(x => x.rownumber == this.index).rating = rating;

            // }
            this.protectiveXmlInputList.find(x => x.rownumber == this.index).devicenostr = this.deviceNumber.trim();

            // for (var j = 0; j < this.protectiveXmlInputList.length; j++) {
            //     this.protectiveXmlInputList[j].ischanged =false;
            // }

            // if (modifyonlydevice) {
            //     for (var j = 0; j < this.protectiveXmlInputList.length; j++) {

            //         this.protectiveXmlInputList[j].ischanged = false;
            //     }
            //     //  this.saveCoordinationResult();
            // }

            // if (modifydevicenameonly) {
            //     for (var j = 0; j < this.protectiveXmlInputList.length; j++) {


            //         this.protectiveXmlInputList[j].ischanged = false;
            //         if (this.protectiveXmlInputList[j].rownumber == this.index)

            //             this.protectiveXmlInputList[j].isnamechanged = true;
            //         else
            //             this.protectiveXmlInputList[j].isnamechanged = false;

            //     }
            // }
        }
        else {
            if (this.index >= 0) {

                if (modifyonlydevice == false) {
                    if (this.protectiveXmlInputList.length == 1) {
                        this.protectiveXmlInputList[0].ischanged = true;
                    }
                }
                else {
                    for (var j = 0; j < this.protectiveXmlInputList.length; j++) {

                        if (this.protectiveXmlInputList[j].rownumber == this.index)

                            this.protectiveXmlInputList[j].ischanged = true;
                        else
                            this.protectiveXmlInputList[j].ischanged = false;

                    }
                }
            }
        }
        objinput.devices = this.protectiveXmlInputList;
        this.isProtectiveConfigChanged = true;
        this.ListProtectiveInputCurrentObj = objinput;
        objinput.generatexmlonly = modifyonlydevice;

        objinput.standard = this.protectionCoordinationStandard;

        this._settingService.updateNetworkProtectiveXmlTwo(objinput).subscribe(result => {
            if (result !== undefined && result !== null) {
                var arr = JSON.parse(result);
                this.xmlData = arr.xml;
                this.xmlData_Original = arr.xml_Original;
                this.xmlData_Tripping = arr.xml_TrippingCalculation;
                this.deviceType = arr.type;
                if (this.deviceType == "3") {
                    $("#divelectronic").hide();
                    $("#divmagneticonly").show();
                    $("#divtmagneticonly").hide();

                }
                if (this.deviceType == "2") {
                    $("#divelectronic").hide();
                    $("#divmagneticonly").hide();
                    $("#divtmagneticonly").show();
                }
                if (this.deviceType == "1") {
                    $("#divelectronic").show();
                    $("#divmagneticonly").hide();
                    $("#divtmagneticonly").hide();
                    var multiplier = 1;
                    var multipliercolumn = $(controlchangedId).attr('multiplier');
                    if (multipliercolumn != undefined) {
                        if (multipliercolumn == "E_Io") {
                            multiplier = this.multiplierEIO;
                        }
                        if (multipliercolumn == "E_Ir") {
                            multiplier = this.multiplierEIR;

                        }
                        if (multipliercolumn == "In") {
                            multiplier = this.multiplierIN;

                        }
                    }
                }

                this.CableSize['xml'] = this.xmlData;
                this.CableSize['row'] = this.index;
                if (this.protectiveXmlInputList.find(x => x.nodeid == objinput.nodeid) != undefined
                    && this.protectiveXmlInputList.find(x => x.nodeid == objinput.nodeid) != null) {
                    this.protectiveXmlInputList.find(x => x.nodeid == objinput.nodeid).xml = arr.xml_Original;
                }

                if (this.xmlData) {
                    this.CableSize['BrowserName'] = this.detectBrowserName();
                    this.CableSize['calculationType'] = "Network Calculation";
                    this.CableSize['calculationId'] = this.newCalculationId;
                    this.CableSize['UserName'] = this.user.userName;
                    this._CableproCalc.ProtectiveCalculate(this.CableSize).subscribe((result) => {
                        this.coordinationloader = false;
                        this.discriminatedData = {
                            discriminatedfirst: result.discriminatedfirst,
                            discriminatedfirstone: result.discriminatedfirstone,
                            discriminatedfirsttwo: result.discriminatedfirsttwo,
                            discriminatedfirstthree: result.discriminatedfirstthree,
                            discriminatedfirstfour: result.discriminatedfirstfour,
                            discriminatedsecond: result.discriminatedsecond,
                            discriminatedsecondone: result.discriminatedsecondone,
                            discriminatedsecondtwo: result.discriminatedsecondtwo,
                            discriminatedsecondthree: result.discriminatedsecondthree,
                            discriminatedsecondfour: result.discriminatedsecondfour,
                        }
                        if (this.protectiveDrpList && this.protectiveDrpList.length > 0) {
                            let tempnode = this.protectiveDrpList[this.currentProtectiveDeivce];
                            if (tempnode) {
                                // if (tempnode.HvFuse == true) {
                                //     this.HvFuseXml = this.xmlData_Original;
                                //     this.HvFuseTrippingXml = this.xmlData_Tripping;
                                // }
                                // else {
                                tempnode.Xml = this.xmlData_Original;
                                tempnode.TrippingXml = this.xmlData_Tripping;
                                tempnode.isXmlUpdated = true;
                                // }
                            }
                            this.protectivecoordinationoutputxml = result.xml;
                            this.coordinationGenerateErrorsModel(result.discriminatedfirst, result.discriminatedsecond);
                        }
                        else {
                            //Keep Protective Input xml to perform network calculation
                            this.CableSize['protection']['Xml'] = this.xmlData_Original;
                            this.CableSize['protection']['TrippingXml'] = this.xmlData_Tripping;
                            this.updatecablesizeinarray();
                            this.onInputChange();
                        }


                        this.chartData = result.curve;
                        this.dimSizeData = result.finaldimsize;
                        this.outputXmlData = result.xml;
                        this.trippingCurrentAtSpecifiedTime = result.trippingCurrentAtSpecifiedTime;
                        this.GenerateMaxAllowedEFLImpedance();
                        $('.spnmanufacurer').text(result.manufacturer);
                        $('.spnfamily').text(result.family);
                        $('.spnmodel').text(result.model);
                        $('.spnteststandard').text(result.teststandard);
                        $('.spntripunit').text(result.tripunit);
                        $('.spnrating').text(result.rating);
                        $('.spntripunittype').text(result.tripunittype);
                        $('.spnratedvoltage').text(result.ratedmaximum);
                        $('.spncurveclass').text(result.curveclass);
                        $('.spnfusetype').text(result.fusetype);
                        this.txtrating = result.rating;
                        this.selectedModalId = this.protectiveXmlInputList[0].model;
                        // let selectedModel = this.modelOutput.find(m => m.id == this.protectiveXmlInputList[0].model);
                        this.txtmodal = result.model;
                        this.txtfamily = result.familywithdevicetype;
                        if (result.manufacturer != "Generic") {
                            this.IsGeneric = true;
                            var name = result.manufacturer + "logo";
                            this.protectiveImgSrc = '/assets/cablepro/logo/' + name + '.svg';
                            this.protectiveImgSrc2 = '/assets/cablepro/logo/' + name + '.svg';
                        }
                        else {
                            this.protectiveImgSrc = '/assets/cablepro/logo/a.png';
                            this.protectiveImgSrc2 = '/assets/cablepro/logo/a.png';
                            this.IsGeneric = false;
                        }

                        var devicearr = this.defaultFamilyId.split(' ')[1];
                        if (devicearr == "MCCB" || devicearr == "ACB") {
                            this.txtTripUnit = result.tripunit;
                            this.selectListFive = "Trip unit";
                            this.curveClassOrTripUnit = "Trip unit";
                        }
                        else if (devicearr == "Fuse") {
                            this.txtTripUnit = result.fusetype;
                            this.selectListFive = "Fuse type";
                            this.curveClassOrTripUnit = "Fuse type";
                        }
                        else {
                            this.txtTripUnit = result.curveclass;
                            this.selectListFive = "Curve class";
                            this.curveClassOrTripUnit = "Curve class";
                        }
                        this.txtTripUnitfuse=this.txtTripUnit;
                        var shownumber = 1;
                        // for (var i = 0; i < this.listCoordinationCriteria.length; i++) {
                        //     this.addCurve(this.getDevicenumber(this.listCoordinationCriteria[i].devicenostr), this.listCoordinationCriteria[i].model.toString(), this.getDevicenumber(this.listCoordinationCriteria[i].devicenostr)
                        //         , parseFloat(this.listCoordinationCriteria[i].manufacturer), parseFloat(this.listCoordinationCriteria[i].rating), parseFloat(this.listCoordinationCriteria[i].tripunit), parseInt(this.listCoordinationCriteria[i].desc), i, this.listCoordinationCriteria[i].devicenostr);

                        // }
                        // if ($("#current").val().toString() != "")
                        //     this.sequenceofoperation = result.sequenceofoperation;

                        this.runCurveEvent(this.index);

                        // this.highLightRow(this.index);
                        //this.saveOtherDetail();
                        //  this.hideLoader();
                    });
                }
                if (rpchanged || this.modifyindex >= 0) {
                    this.modifyindex = -1;
                    var devicearr = familyId.split(' ')[1];
                    if (devicearr != null && devicearr != "" && (devicearr == "MCB" || devicearr == "Fuse")) {
                        $("#divmcbacbonly").show();
                        $("#divelectronic").hide();
                        $("#divmagneticonly").hide();
                        $("#divtmagneticonly").hide();
                    }
                    else {
                        $("#divmcbacbonly").hide();
                        //this.deviceType="3";
                        if (arr.type != null) {
                            this.deviceType = arr.type;

                            this.multiplierIN = arr.inrating;
                        }
                        var setvalue = 1;
                        if (this.deviceType == "3") {
                            $("#divelectronic").hide();
                            $("#divmagneticonly").show();
                            $("#divtmagneticonly").hide();
                            this.getInputValues(arr.mim, '#pickupcurrmgpr', "#pickupcurrmgprspn", "#pickupcurrmgprhdn", "#pickupcurrmgprdiv", setvalue, arr.defaultmim);
                        }
                        if (this.deviceType == "2") {
                            $("#divelectronic").hide();
                            $("#divmagneticonly").hide();
                            $("#divtmagneticonly").show();
                            this.getInputValues(arr.tmir, '#pickupcurrth', "#gridSizeValue", "#pickupcurrthhdn", "#pickupcurrthdiv", setvalue, arr.defaulttmir);
                            this.getInputValues(arr.tmim, '#pickupcurrmg', "#pickupcurrmgspn", "#pickupcurrmgshdn", "#pickupcurrmgsdiv", setvalue, arr.defaulttmim);
                        }
                        if (this.deviceType == "1") {
                            $("#divelectronic").show();
                            $("#divmagneticonly").hide();
                            $("#divtmagneticonly").hide();


                            this.multiplierEIR = arr.defaulteir != "-" ? arr.defaulteir : 1;
                            if (arr.rp != null && arr.rp != "") {
                                $("#ratingplugdiv").show();

                                $("#ratingplug").val(arr.rp);
                                this.ratingPlugList = arr.rplist;
                                this.rp = arr.rp;
                            }
                            else {
                                $('#ratingplug option:selected').val('');
                                $("#ratingplugdiv").hide();
                            }

                            this.multiplierEIO = arr.defaulteio != "-" ? arr.defaulteio : 1;
                            if (arr.ei2tcurve != "1" && arr.ei2tcurve != "0") {
                                $("#I2tdiv").hide();
                                $('#chcki2curve').prop('checked', false);
                            }
                            else {
                                $("#I2tdiv").show();
                                if (arr.ei2tcurve == "1") {

                                    $('#chcki2curve').prop('checked', true);
                                }
                                else {
                                    $('#chcki2curve').prop('checked', false);

                                }
                            }

                            this.getInputValues(arr.currenttolerance, '#current-tolerance-range', "#current-tolerance-rangespn", "#current-tolerance-rangehdn", "#current-tolerance-rangediv", setvalue, arr.defaultcurrenttolerance);
                            this.getInputValues(arr.timetolerance, '#time-tolerance-range', "#time-tolerance-rangespn", "#time-tolerance-rangehdn", "#time-tolerance-rangediv", setvalue, arr.defaulttimetolerance);
                            this.getInputValues(arr.timetobreak, '#time-tobreak-range', "#time-tobreak-rangespn", "#time-tobreak-rangehdn", "#time-tobreak-rangediv", setvalue, arr.defaulttimetobreak);
                            this.getInputValues(arr.eio, '#pickupcurrpre-range', "#pickupcurrpre-rangespn", "#pickupcurrpre-rangehdn", "#pickupcurrpre-rangediv", setvalue, arr.defaulteio);
                            this.getInputValues(arr.eir, '#pickupcurr-range', "#pickupcurr-rangespn", "#pickupcurr-rangehdn", "#pickupcurr-rangediv", setvalue, arr.defaulteir);
                            this.getInputValues(arr.etr, '#timedelay-range', "#timedelay-rangespn", "#timedelay-rangehdn", "#timedelay-rangediv", setvalue, arr.defaultetr);
                            this.getInputValues(arr.eisd, '#pickupshort-range', "#pickupshort-rangespn", "#pickupshort-rangehdn", "#pickupshort-rangediv", setvalue, arr.defaulteisd);
                            this.getInputValues(arr.etsd, '#timedelayshort-range', "#timedelayshort-rangespn", "#timedelayshort-rangehdn", "#timedelayshort-rangediv", setvalue, arr.defaultetsd);
                            this.getInputValues(arr.eli, '#pickupcurrinst-range', "#pickupcurrinst-rangespn", "#pickupcurrinst-rangehdn", "#pickupcurrinst-rangediv", setvalue, arr.defaulteli);
                            this.getInputValues(arr.idmtl, '#idmtl-settings', "#idmtl-settingsspn", "#idmtl-settingshdn", "#idmtl-settingsdiv", setvalue, arr.defaultidmtl);
                            if (arr.eltboolproperty == "show") {
                                //
                                $('#chcklongtime').prop('checked', true);
                                $("#divlongtimeprot").show();
                                $("#divlongtimeprotchk").show();
                                $("#divlongtimeprotchk :input").removeAttr('disabled');
                            }
                            else if (arr.eltboolproperty == "hide") {

                                $('#chcklongtime').prop('checked', false);
                                if (arr.isrpnumber == "1") {
                                    $('#chcklongtime').prop('checked', true);
                                    $("#timedelay-rangediv").hide();
                                }
                                $("#divlongtimeprotchk").hide();
                            }
                            else if (arr.eltboolproperty == "disabled" || arr.eltboolproperty == null) {
                                $('#chcklongtime').prop('checked', true);
                                $("#divlongtimeprot").show();
                                $("#divlongtimeprotchk").show();
                                $("#divlongtimeprotchk :input").attr('disabled', 'true');
                            }

                            if ($('#ratingplug option:selected').val() == "OFF") {
                                $("#pickupcurr-rangediv").hide();
                                $("#timedelay-rangediv").hide();
                            }
                            if (arr.estboolproperty == "show") {
                                $("#divshorttime").show();
                                $("#divshorttimechk").show();
                                $("#divshorttimechk :input").removeAttr('disabled');
                                $('#chckshorttime').prop('checked', true);

                            }
                            else if (arr.estboolproperty == "hide") {
                                $("#divshorttime").hide();
                                $("#divshorttimechk").hide();

                                $('#chckshorttime').prop('checked', false);
                                if (arr.isrpnumber == "1") {
                                    $('#chckshorttime').prop('checked', true);
                                }


                            }
                            else if (arr.estboolproperty == "disabled" || arr.estboolproperty == null) {
                                $("#divshorttime").show();
                                $("#divshorttimechk").show();
                                $("#divshorttimechk :input").attr('disabled', 'true');
                                $('#chckshorttime').prop('checked', true);

                            }

                            if (arr.eintboolproperty == "show") {
                                // $("#divinstant").show();
                                //  $("#divinstantchk").show();
                                // $('#chckInstant').prop('checked', true);
                                //  $("#divinstantchk :input").removeAttr('disabled');

                                $("#divinstant").show();
                                $("#divinstantchk").show();
                                // $('#chckInstant').prop('checked', true);
                                if (arr.chkinst == "0") {
                                    $("#divinstant").hide();
                                    $('#chckInstant').prop('checked', false);
                                }
                                else
                                    $('#chckInstant').prop('checked', true);

                                $("#divinstantchk :input").removeAttr('disabled');
                            }
                            else if (arr.eintboolproperty == "hide") {
                                $("#divinstant").hide();
                                $('#chckInstant').prop('checked', false);
                                if (arr.isrpnumber == "1") {
                                    $('#chckInstant').prop('checked', true);
                                }

                                $("#divinstantchk").hide();
                            }
                            else if (arr.eintboolproperty == "disabled" || arr.eintboolproperty == null) {
                                $("#divinstant").show();
                                $('#chckInstant').prop('checked', true);
                                $("#divinstantchk").show();
                                $("#divinstantchk :input").attr('disabled', 'true');
                            }
                        }
                    }
                }
            }
            $('#protectiveSelector').modal('hide');
        },
            err => {
                this.hideLoader();
                $('#protectiveSelector').modal('hide');
            });
    }


   

    addDevice(savexml: string = "") {
        if (this.modifyindex >= 0) {
            savexml = "";
        }
        this.Isfromunknownchk = false;

        if (this.addingDeviceForUnknownDevice) {
            this.addDeviceForUnknownNode(savexml);
            return;
        }

        if (this.isFromCoordination) {
            this.editDeviceFromCoordination(savexml);
            return;
        }

        var modifynameonly = true;
        if (this.isSelectorChange)
            modifynameonly = false;
        // this.devicenomsg = "";
        // if (this.deviceNumber.trim() == "") {
        //     this.devicenomsg = "Unique device name is required.";
        //     return false;
        // }
        // else {
        //     if (this.checkdevicenameunique(this.deviceNumber.trim(), this.modifyindex + 1)) {
        //         this.devicenomsg = "Unique device name is required.";
        //         return false;
        //     }
        // }
        this.ProtectiveLoadDataXml = new ProtectiveData();
        if (true) {
            this.showLoader();
            this.btnAddDeviceDisabled = true;
            this.btnAddDeviceRequestCounter += 1;
            var a = this.defaultManId;
            var manufacturerId = parseInt(a.toString());
            var familyId = this.model.toString();
            var modelId = this.defaultModelId;
            var d = this.defaultRatingId;
            var ee = this.defaultCurveId;
            var curve = (ee.toString());
            var res = curve;
            if (curve.indexOf('-') != -1) {
                res = curve.substr(0, curve.lastIndexOf('-'));
                res = this.trimChar(res.trim(), "-");
            }
            this.GetBreakingByManufacturerId(manufacturerId, familyId, modelId, this.defaultRatingId.toString(), res);
            this.GetPolesTableByManufacturerId(manufacturerId, familyId, modelId, this.defaultRatingId.toString(), res);
            this.ProtectiveLoadStorage = new ProtectiveItem();
            this.ProtectiveLoadStorage.model = modelId.toString();
            this.ProtectiveLoadStorage.family = this.model.toString();
            this.ProtectiveLoadStorage.devicetype = this.ProtectiveLoadStorage.family.split(' ')[1].toString();
            this.ProtectiveLoadStorage.rating = this.defaultRatingId.toString();
            this.ProtectiveLoadStorage.manufacturer = a.toString();
            this.ProtectiveLoadStorage.tripunit = ee.toString();
            if (this.modifyindex == -1) {
                this.ProtectiveLoadStorage.deviceno = 1;
            }
            //        if (this.deviceno <= 9) {
            //                this.LoadStorage.devicenostr = "Device 0" + this.deviceno;
            this.ProtectiveLoadStorage.devicenostr = this.deviceNumber.trim();

            //      }
            //    else {
            //      this.LoadStorage.devicenostr = "Device " + this.deviceno;

            //}
            let selectedMan = this.manufacturerOutput.find(m => m.id == this.defaultManId);
            let selectedFamily = this.familyOutput.find(m => m.familyid == this.defaultFamilyId);
            let selectedModel = this.modelOutput.find(m => m.id == this.defaultModelId);
            let selectedRating = this.mcbOutput.find(m => m.ina == this.defaultRatingId);
            this.txtrating = (selectedRating != null ? selectedRating.ina : '');
            this.txtmodal = (selectedModel != null ? selectedModel.name : '');
            this.txtfamily = (selectedFamily != null ? selectedFamily.name : '');
            if(this.addingDeviceForHvFuse)
            {
                this.txtratingfuse = (selectedRating != null ? selectedRating.ina : '');
                this.txtmodalfuse = (selectedModel != null ? selectedModel.name : '');
                this.txtfamilyfuse = (selectedFamily != null ? selectedFamily.name : '');


            }
            this.ProtectiveLoadStorage.desc = ((selectedMan != null ? selectedMan.name.trim() : '') + ", "
                + (selectedFamily != null ? selectedFamily.name.trim() : '') + ", " +
                (selectedModel != null ? selectedModel.name.split('-')[0].trim() : '') + ", " + (selectedRating != null ? selectedRating.ina.trim() : '') + " A"
                + ", " + res.trim()).trim();
            if (savexml == "1") {
                this.ListLoadStorage = [this.ProtectiveLoadStorage];
                this.fillDeviceList();
                this.index = this.ListLoadStorage.length - 1;
            }
            else {

                if (this.modifyindex == -1) {
                    manufacturerId = parseInt(this.ListLoadStorage[this.index].manufacturer);
                    familyId = this.ListLoadStorage[this.index].family;

                    modelId = parseInt(this.ListLoadStorage[this.index].model.toString());
                    d = this.ListLoadStorage[this.index].rating.toString();
                    ee = this.ListLoadStorage[this.index].tripunit.toString();
                    curve = (ee.toString());
                    this.ProtectiveLoadStorage.desc = this.ListLoadStorage[this.index].desc.toString()
                }
                else {

                    this.ListLoadStorage[this.index].model = modelId.toString();
                    this.ListLoadStorage[this.index].family = this.model.toString();
                    this.ListLoadStorage[this.index].devicetype = this.ProtectiveLoadStorage.family.split(' ')[1].toString();
                    this.ListLoadStorage[this.index].rating = d.toString();
                    this.ListLoadStorage[this.index].manufacturer = a.toString();
                    this.ListLoadStorage[this.index].tripunit = ee.toString();
                    this.ListLoadStorage[this.index].desc = this.ProtectiveLoadStorage.desc;
                    this.ListLoadStorage[this.index].devicenostr = this.deviceNumber.trim();

                }
                this.fillDeviceList();
            }
            this.CableSize['devicename'] = this.deviceNumber;
            let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));
            this.tempcablesizelist.forEach(
                itemnode => {
                    if (itemnode.Node_Id == selectednode.id || itemnode.Node_Prefix == selectednode.objectcode) {
                        itemnode.DeviceName = this.deviceNumber;
                    }
                });

            if (!this.isFromCoordination) {
                this.tempinputXML.CableSizeCalcInputs = JSON.stringify(this.tempcablesizelist);
                this.updatecablesizeinarray();
                this.onInputChange();
            }
            var res = curve;
            if (curve.indexOf('-') != -1) {
                res = curve.substr(0, curve.lastIndexOf('-'));
                res = this.trimChar(res.trim(), "-");
            }

            this.multiplierEIR = 1;
            this.multiplierIN = 1;
            this.multiplierEIO = 1;
            $('#mcbacb-device').text(this.ProtectiveLoadStorage.desc);
            var arr = this.model.split(' ')[1];
            $(".clsreset").show();
            $(".clstripunit").show();
            $(".clscurveclass").show();
            $(".clsfustype").show();
            $("#devicesettingli").show();
            $("#normalDetail").show();
            $("#hvRelayDetail").hide();
            if (this.modifyindex >= 0) {
                // savexml="1";
            }
            if (arr != null && arr != "" && (arr == "MCB" || arr == "Fuse")) {
                $("#devicesettingli").hide();
                //document.getElementById('tab2Open').click();
                if (arr == "MCB") {
                    $(".clsfustype").hide();
                }
                if (arr == "Fuse") {
                    $(".clscurveclass").hide();

                }
                $(".clstripunit").hide();
                $(".clsreset").hide();
                $("#divmcbacbonly").show();
                $("#divelectronic").hide();
                $("#divmagneticonly").hide();
                $("#divtmagneticonly").hide();
                if (this.modifyindex >= 0) {
                    this.ProtectiveGetXml(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', '', '', ''
                        , '', '', '', ''
                        , '', ''
                        , '', ''
                        , '', ''
                        , '', 1, '', '', false, false);
                }
                else {
                    this.ProtectiveLoadControls(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', '', '', ''
                        , '', '', '', ''
                        , '', ''
                        , '', ''
                        , '', ''
                        , '', '', savexml, false, false, modifynameonly);
                }
            }
            else {

                $(".clsfustype").hide();
                $(".clscurveclass").hide();

                $("#divmcbacbonly").hide();

                if (this.modifyindex >= 0) {
                    this.ProtectiveGetXml(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', '', '', ''
                        , '', '', '', ''
                        , '', ''
                        , '', ''
                        , '', ''
                        , '', 1, '', '', false, false);
                }
                else {
                    this.ProtectiveLoadControls(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', '', '', ''
                        , '', '', '', ''
                        , '', ''
                        , '', ''
                        , '', ''
                        , '', '', savexml, false, false, modifynameonly);
                }

            }
            if (!this.isFromCoordination) {
                if (this.activeNode && this.activeNode.objectcode != "T0" && this.activeNode.objectcode != "PS0") {
                    this.protectiveUnknown = false;
                    this.onProtectiveUnknownChange(true);
                }
            }
        }
        if (this.isFromCoordination)
            this.RefreshProtectionCoordinationPopup();




        this.isSelectorChange = false;
        //        this.saveOtherDetail();

    }

    addDeviceForUnknownNode(savexml: string = "") {

        if (this.unknownDeviceNode) {
            this.showLoader();
            this.Isfromunknownchk = false;
            this.ProtectiveLoadDataXml = new ProtectiveData();
            this.btnAddDeviceDisabled = true;
            this.btnAddDeviceRequestCounter += 1;
            var a = this.defaultManId;
            var manufacturerId = parseInt(a.toString());
            var familyId = this.model.toString();
            var modelId = this.defaultModelId;
            var d = this.defaultRatingId;
            var ee = this.defaultCurveId;
            var curve = (ee.toString());
            var res = curve;
            if (curve.indexOf('-') != -1) {
                res = curve.substr(0, curve.lastIndexOf('-'));
                res = this.trimChar(res.trim(), "-");
            }
            // this.GetBreakingByManufacturerId(manufacturerId, familyId, modelId, this.defaultRatingId.toString(), res);
            // this.GetPolesTableByManufacturerId(manufacturerId, familyId, modelId, this.defaultRatingId.toString(), res);
            this.ProtectiveLoadStorage = new ProtectiveItem();
            this.ProtectiveLoadStorage.model = modelId.toString();
            this.ProtectiveLoadStorage.family = this.model.toString();
            this.ProtectiveLoadStorage.devicetype = this.ProtectiveLoadStorage.family.split(' ')[1].toString();
            this.ProtectiveLoadStorage.rating = this.defaultRatingId.toString();
            this.ProtectiveLoadStorage.manufacturer = a.toString();
            this.ProtectiveLoadStorage.tripunit = ee.toString();
            if (this.modifyindex == -1) {
                this.ProtectiveLoadStorage.deviceno = 1;
            }
            //        if (this.deviceno <= 9) {
            //                this.LoadStorage.devicenostr = "Device 0" + this.deviceno;
            this.ProtectiveLoadStorage.devicenostr = this.deviceNumber.trim();

            //      }
            //    else {
            //      this.LoadStorage.devicenostr = "Device " + this.deviceno;

            //}
            let selectedMan = this.manufacturerOutput.find(m => m.id == this.defaultManId);
            let selectedFamily = this.familyOutput.find(m => m.familyid == this.defaultFamilyId);
            let selectedModel = this.modelOutput.find(m => m.id == this.defaultModelId);
            let selectedRating = this.mcbOutput.find(m => m.ina == this.defaultRatingId);
            // this.txtrating = (selectedRating != null ? selectedRating.ina : '');
            //this.txtmodal = (selectedModel != null ? selectedModel.name.split('-')[0] : '');
            // this.txtmodal = (selectedModel != null ? selectedModel.name : '');
            // this.txtfamily = (selectedFamily != null ? selectedFamily.name : '');

            this.ProtectiveLoadStorage.desc = ((selectedMan != null ? selectedMan.name.trim() : '') + ", "
                + (selectedFamily != null ? selectedFamily.name.trim() : '') + ", " +
                (selectedModel != null ? selectedModel.name.split('-')[0].trim() : '') + ", " + (selectedRating != null ? selectedRating.ina.trim() : '') + " A"
                + ", " + res.trim()).trim();

            this.ListLoadStorage = [this.ProtectiveLoadStorage];
            this.fillDeviceList();
            this.index = this.ListLoadStorage.length - 1;

            // this.CableSize['devicename'] = this.deviceNumber;
            // let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));


            var res = curve;
            if (curve.indexOf('-') != -1) {
                res = curve.substr(0, curve.lastIndexOf('-'));
                res = this.trimChar(res.trim(), "-");
            }

            this.multiplierEIR = 1;
            this.multiplierIN = 1;
            this.multiplierEIO = 1;

            // this.ProtectiveLoadControls(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', '', '', ''
            //     , '', '', '', ''
            //     , '', ''
            //     , '', ''
            //     , '', ''
            //     , '', '', savexml);



            var objinput = new ListProtectiveInput();
            objinput.calculationid = this.newCalculationId;

            objinput.nodeid = this.unknownDeviceNode.Node_Prefix;
            // objinput.nodeid = this.activeNode.objectcode;
            objinput.tmir = "";
            objinput.tmim = "";
            objinput.mim = "";
            objinput.currenttolerance = "";
            objinput.timetolerance = "";
            objinput.timetobreak = "";
            objinput.eltboolproperty = "";
            objinput.estboolproperty = "";
            objinput.eintboolproperty = "";
            objinput.eio = "";
            objinput.eir = "";
            objinput.etr = "";
            objinput.eisd = "";

            //  objinput.trippingcurrent = $("#current").val().toString()//s.trippingcurrent;
            objinput.eratingplug = "";
            objinput.etsd = "";
            objinput.ei2tcurve = "";
            objinput.eli = "";
            objinput.idmtl = "";
            objinput.rownumber = this.index;
            let trippingtime;
            if (!this.protectionConfigurationModel.MaxDisconnectionTime)
                trippingtime = "0.4";
            else {
                if (this.protectionConfigurationModel.MaxDisconnectionTime == "Custom")
                    trippingtime = this.protectionConfigurationModel.CustomTime;
                else
                    trippingtime = this.protectionConfigurationModel.MaxDisconnectionTime;
            }
            objinput.trippingtime = trippingtime;

            if (savexml == "1") {

                this.protectiveXmlInput = new ProtectiveXmlInput();
                this.protectiveXmlInput.xml = this.xmlData;
                this.protectiveXmlInput.model = modelId;
                this.protectiveXmlInput.manufacturer_id = manufacturerId;
                this.protectiveXmlInput.range_id = familyId;
                this.protectiveXmlInput.tripunit = curve;
                this.protectiveXmlInput.rating = d.toString();
                this.protectiveXmlInput.chartdata = '';
                this.protectiveXmlInput.id = 0;
                this.protectiveXmlInput.rownumber = this.protectiveXmlInputList.length;
                this.protectiveXmlInput.devicenostr = this.deviceNumber.trim();
                this.protectiveXmlInput.nodeid = this.activeNode.objectcode;
                this.protectiveXmlInputList = [this.protectiveXmlInput];


            }

            if (this.protectiveXmlInputList.find(x => x.rownumber - 1 == this.index) != null
                && this.protectiveXmlInputList.find(x => x.rownumber - 1 == this.index) != undefined
            )
                this.protectiveXmlInputList.find(x => x.rownumber - 1 == this.index).ischanged = true;
            if (this.protectiveXmlInputList.find(x => x.rownumber - 1 != this.index) != null &&
                this.protectiveXmlInputList.find(x => x.rownumber - 1 != this.index) != undefined) {

                for (var j = 0; j < this.protectiveXmlInputList.length; j++) {
                    if (this.protectiveXmlInputList[j].rownumber - 1 != this.index) {
                        this.protectiveXmlInputList[j].ischanged = false;
                    }
                }

            }

            objinput.devices = this.protectiveXmlInputList;
            this.ListProtectiveInputCurrentObj = objinput;
            // objinput.generatexmlonly = generatexmlonly;
            // Call when we change the slider
            // generatexmlonly is true when we use new coordination popup.
            // If user changed the slider then we are not updating the xml in ECPNetworkProtectiveCalcDetail table we are just updating the clientside temp list..
            // To reload the slider we need to send the updated xml from templist.
            this._settingService.storeNetworkProtectiveXml(objinput).subscribe(result => {
                this.btnAddDeviceDisabled = false;
                this.btnAddDeviceRequestCounter = 0;
                if (result !== undefined && result !== null) {
                    var arr = JSON.parse(result);
                    // this.xmlData = arr.xml;

                    // this.xmlData_Original = arr.xml_Original;
                    // this.xmlData_Tripping = arr.xml_TrippingCalculation;
                    if (this.protectiveXmlInputList.find(x => x.rownumber == arr.rownumber) != undefined
                        && this.protectiveXmlInputList.find(x => x.rownumber == arr.rownumber) != null
                        && this.protectiveXmlInputList.find(x => x.rownumber == arr.rownumber).id == 0)
                        this.protectiveXmlInputList.find(x => x.rownumber == arr.rownumber).id = arr.calcdetailid;

                    // if (this.protectiveXmlInputList.find(x => x.nodeid == arr.nodeid) != undefined
                    //     && this.protectiveXmlInputList.find(x => x.nodeid == arr.nodeid) != null) {
                    //     this.protectiveXmlInputList.find(x => x.nodeid == arr.nodeid).xml = arr.xml_Original;
                    // }

                    // if (arr.isrpnumber == "1") {
                    //     this.rpcaption = "Characteristics";
                    // }
                    // else {
                    //     this.rpcaption = "Rating plug";
                    // }

                    // this.CableSize['xml'] = this.xmlData;
                    // this.CableSize['row'] = this.index;

                    if (arr.xml) {
                        this.CableSize['BrowserName'] = this.detectBrowserName();
                        this.CableSize['calculationType'] = "Network Calculation";
                        this.CableSize['calculationId'] = this.newCalculationId;
                        this.CableSize['UserName'] = this.user.userName;
                        this._CableproCalc.ProtectiveCalculate({ xml: arr.xml, row: this.index }).subscribe((result) => {

                            this.tempcablesizelist.forEach(
                                itemnode => {
                                    if (itemnode.Node_Id == this.unknownDeviceNode.Node_Id || itemnode.Node_Prefix == this.unknownDeviceNode.Node_Prefix) {
                                        itemnode.DeviceName = this.deviceNumber;
                                        itemnode.CableSizeCalcInputXML['protection']['IsUnknown'] = false;
                                        itemnode.CableSizeCalcInputXML['protection']['Xml'] = arr.xml_Original;
                                        itemnode.CableSizeCalcInputXML['protection']['TrippingXml'] = arr.xml_TrippingCalculation;
                                    }

                                    if (this.activeNode.objectcode == this.unknownDeviceNode.Node_Prefix) {
                                        this.protectiveUnknown = false;
                                        this.CableSize['protection']['IsUnknown'] = this.protectiveUnknown;
                                    }
                                });


                            this.tempinputXML.CableSizeCalcInputs = JSON.stringify(this.tempcablesizelist);
                            this.updatecablesizeinarray();
                            this.onInputChange();
                            $('#protectiveSelector').modal('hide');
                            this.showCoordinationPopup();
                        });
                    }

                }
            },
                err => {
                    this.btnAddDeviceDisabled = false;
                    this.btnAddDeviceRequestCounter = 0;
                    $('#protectiveSelector').modal('hide');
                    this.hideLoader();
                });




            // this.protectiveUnknown = false;

            // this.onProtectiveUnknownChange(true);
        }
        else {

        }
    }

    editDeviceFromCoordination(savexml: string = "") {
        if (this.modifyindex >= 0) {
            savexml = "";
        }
        this.coordinationloader = true;
        this.Isfromunknownchk = false;
        var generatexmlonly = false;
        if (this.isFromCoordination) {
            generatexmlonly = true;
        }
        this.modifyindex = this.index;
        this.ProtectiveLoadDataXml = new ProtectiveData();
        if (true) {
            this.showLoader();
            this.btnAddDeviceDisabled = true;
            this.btnAddDeviceRequestCounter += 1;
            var a = this.defaultManId;
            var manufacturerId = parseInt(a.toString());
            var familyId = this.model.toString();
            var modelId = this.defaultModelId;
            var d = this.defaultRatingId;
            var ee = this.defaultCurveId;
            var curve = (ee.toString());
            var res = curve;
            if (curve.indexOf('-') != -1) {
                res = curve.substr(0, curve.lastIndexOf('-'));
                res = this.trimChar(res.trim(), "-");
            }
            this.GetBreakingByManufacturerId(manufacturerId, familyId, modelId, this.defaultRatingId.toString(), res);
            this.GetPolesTableByManufacturerId(manufacturerId, familyId, modelId, this.defaultRatingId.toString(), res);
            this.ProtectiveLoadStorage = new ProtectiveItem();
            this.ProtectiveLoadStorage.model = modelId.toString();
            this.ProtectiveLoadStorage.family = this.model.toString();
            this.ProtectiveLoadStorage.devicetype = this.ProtectiveLoadStorage.family.split(' ')[1].toString();
            this.ProtectiveLoadStorage.rating = this.defaultRatingId.toString();
            this.ProtectiveLoadStorage.manufacturer = a.toString();
            this.ProtectiveLoadStorage.tripunit = ee.toString();
            if (this.modifyindex == -1) {
                this.ProtectiveLoadStorage.deviceno = 1;
            }
            //        if (this.deviceno <= 9) {
            //                this.LoadStorage.devicenostr = "Device 0" + this.deviceno;
            this.ProtectiveLoadStorage.devicenostr = this.deviceNumber.trim();

            //      }
            //    else {
            //      this.LoadStorage.devicenostr = "Device " + this.deviceno;

            //}
            let selectedMan = this.manufacturerOutput.find(m => m.id == this.defaultManId);
            let selectedFamily = this.familyOutput.find(m => m.familyid == this.defaultFamilyId);
            let selectedModel = this.modelOutput.find(m => m.id == this.defaultModelId);
            let selectedRating = this.mcbOutput.find(m => m.ina == this.defaultRatingId);
            this.txtrating = (selectedRating != null ? selectedRating.ina : '');
            //this.txtmodal = (selectedModel != null ? selectedModel.name.split('-')[0] : '');
            this.txtmodal = (selectedModel != null ? selectedModel.name : '');
            this.txtfamily = (selectedFamily != null ? selectedFamily.name : '');

            this.ProtectiveLoadStorage.desc = ((selectedMan != null ? selectedMan.name.trim() : '') + ", "
                + (selectedFamily != null ? selectedFamily.name.trim() : '') + ", " +
                (selectedModel != null ? selectedModel.name.split('-')[0].trim() : '') + ", " + (selectedRating != null ? selectedRating.ina.trim() : '') + " A"
                + ", " + res.trim()).trim();


            this.ListLoadStorage[this.index].model = modelId.toString();
            this.ListLoadStorage[this.index].family = this.model.toString();
            this.ListLoadStorage[this.index].devicetype = this.ProtectiveLoadStorage.family.split(' ')[1].toString();
            this.ListLoadStorage[this.index].rating = d.toString();
            this.ListLoadStorage[this.index].manufacturer = a.toString();
            this.ListLoadStorage[this.index].tripunit = ee.toString();
            this.ListLoadStorage[this.index].desc = this.ProtectiveLoadStorage.desc;
            this.ListLoadStorage[this.index].devicenostr = this.deviceNumber.trim();


            this.fillDeviceList();
            // this.CableSize['devicename'] = this.deviceNumber;
            // let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));
            // this.tempcablesizelist.forEach(
            //     itemnode => {
            //         if (itemnode.Node_Id == this.protectiveDrpList[this.index].Node_Id || itemnode.Node_Prefix == this.protectiveDrpList[this.index].Node_Prefix) {
            //             itemnode.DeviceName = this.deviceNumber;
            //         }
            //     });


            var res = curve;
            if (curve.indexOf('-') != -1) {
                res = curve.substr(0, curve.lastIndexOf('-'));
                res = this.trimChar(res.trim(), "-");
            }

            this.multiplierEIR = 1;
            this.multiplierIN = 1;
            this.multiplierEIO = 1;
            $('#mcbacb-device').text(this.ProtectiveLoadStorage.desc);
            var arr = this.model.split(' ')[1];
            $(".clsreset").show();
            $(".clstripunit").show();
            $(".clscurveclass").show();
            $(".clsfustype").show();
            $("#devicesettingli").show();
            $("#normalDetail").show();
            $("#hvRelayDetail").hide();
            if (this.modifyindex >= 0) {
                // savexml="1";
            }
            if (arr != null && arr != "" && (arr == "MCB" || arr == "Fuse")) {
                $("#devicesettingli").hide();
                //document.getElementById('tab2Open').click();
                if (document.querySelector("#ulprotectioncoordination").querySelector("#protectiveTab2Open") != null) {
                    (<HTMLElement>document.querySelector("#ulprotectioncoordination").querySelector("#protectiveTab2Open")).click();
                }
                if (arr == "MCB") {
                    $(".clsfustype").hide();
                }
                if (arr == "Fuse") {
                    $(".clscurveclass").hide();

                }
                $(".clstripunit").hide();
                $(".clsreset").hide();
                $("#divmcbacbonly").show();
                $("#divelectronic").hide();
                $("#divmagneticonly").hide();
                $("#divtmagneticonly").hide();
                // if (this.modifyindex >= 0) {
            }
            else {
                document.getElementById('defaultOpenProtective').click();
                $(".clsfustype").hide();
                $(".clscurveclass").hide();

                $("#divmcbacbonly").hide();


            }
            var modifynameonly = true;
            if (this.isSelectorChange)
                modifynameonly = false;
            this.ProtectiveGetXml(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', '', '', ''
                , '', '', '', ''
                , '', ''
                , '', ''
                , '', ''
                , '', 1, '', '', false, true, modifynameonly);
            // this.protectiveUnknown = false;
            // this.onProtectiveUnknownChange(true);
        }
        // if(this.isFromCoordination)
        // {
        //     if (this.protectiveXmlInputList.length > 0) {
        //         this.rowClick(this.index, generatexmlonly);
        //     }
        // }

        this.isSelectorChange = false;
    }

    public canHaveHv: boolean = false;
    getNetworkProtectiveDataByNodeId(newCalculationId: any, nodeId: any, callback?: any, generatexmlonly: boolean = false, isFromTreeNode: boolean = false,nodeid: number=0) {
        // this.showLoader();
        if (nodeId == "PS0" || nodeId == "T0"||(nodeid ==1 && (this.networksupplytype == '2' || this.networksupplytype == '4'))) {

            this.canHaveHv = true;
        }
        else {
            this.canHaveHv = false;
        }       
        this._settingService.getNetworkProtectiveDataByNodeId(newCalculationId, nodeId).subscribe(result => {

            this.protectiveXmlInputList = [];
            this.ListLoadStorage = [];

            if (result !== undefined && result.currentrow) {
                this.index = result.currentrow;
            }

            if (result !== undefined && result.items) {
                for (var i = 0; i < result.items.length; i++) {

                    this.ProtectiveLoadStorage = new ProtectiveItem();
                    this.ProtectiveLoadStorage.model = result.items[i].model
                    if (i == 0)
                        this.model = result.items[i].model
                    this.ProtectiveLoadStorage.family = result.items[i].family
                    this.ProtectiveLoadStorage.rating = result.items[i].rating
                    this.ProtectiveLoadStorage.manufacturer = result.items[i].manufacturer
                    this.ProtectiveLoadStorage.tripunit = result.items[i].tripunit
                    this.ProtectiveLoadStorage.deviceno = result.items[i].deviceno
                    this.ProtectiveLoadStorage.devicetype = result.items[i].family.split(' ')[1].toString();
                    if (result.items[i].devicenostr != null && result.items[i].devicenostr != "")
                        this.ProtectiveLoadStorage.devicenostr = result.items[i].devicenostr.trim()
                    this.ProtectiveLoadStorage.desc = result.items[i].desc;
                    this.ProtectiveLoadStorage.nodeid = result.items[i].nodeid;
                    this.ListLoadStorage.push(this.ProtectiveLoadStorage);
                    this.fillDeviceList();
                    this.protectiveXmlInput = new ProtectiveXmlInput();
                    this.protectiveXmlInput.xml = result.items[i].xml;// this.xmlData // Changed to get xml from database
                    this.protectiveXmlInput.model = parseInt(result.items[i].model);
                    this.protectiveXmlInput.manufacturer_id = parseInt(result.items[i].manufacturer);
                    this.protectiveXmlInput.range_id = result.items[i].family;
                    this.protectiveXmlInput.devicenostr = result.items[i].devicenostr;
                    this.protectiveXmlInput.tripunit = result.items[i].tripunit;;
                    this.protectiveXmlInput.rating = result.items[i].rating;
                    this.protectiveXmlInput.chartdata = '';
                    this.protectiveXmlInput.id = result.items[i].id;
                    this.protectiveXmlInput.rownumber = i;
                    this.protectiveXmlInput.nodeid = result.items[i].nodeid;
                    this.protectiveXmlInputList.push(this.protectiveXmlInput);
                }
                //this.fillDeviceList();

            }

            if (callback && generatexmlonly) {
                if (this.protectiveDrpList && this.protectiveDrpList.length > 0) {
                    let tempnode = this.tempcablesizelist.find(m => m.Node_Prefix == this.protectiveDrpList[this.currentProtectiveDeivce].Node_Prefix);
                    if (tempnode) {
                        this.index = this.ListLoadStorage.findIndex(m => m.nodeid == tempnode.Node_Prefix);
                    }
                }
            }
            else
                this.index = 0;

            if (this.protectiveXmlInputList.length > 0) {
                this.rowClick(this.index, generatexmlonly, isFromTreeNode);
                // this.deviceno = this.protectiveXmlInputList.length;
            }
            else {
                this.txtrating = undefined;
                this.txtmodal = undefined;
                this.txtfamily = undefined;
                this.txtTripUnit = undefined;
                this.loadProtectiveXmlInput = null;
                this.defaultManId = 104;
                this.protectiveImgSrc = '/assets/cablepro/logo/a.png';
                this.protectiveImgSrc2 = '/assets/cablepro/logo/a.png';
                this.GetManufacturer();
                // this.showProtectiveDeviceUI()
                // this.hideLoader();
            }
            if (callback) {
                // this.hideLoader();
                callback();
            }
            //this.highLightRow(this.index);
        });
    }
    rowClick(index: any, generatexmlonly: boolean = false, isFromTreeNode: boolean = false) {
        // this.showLoader();
        //this.highLightRow(index)
        this.index = index;
        var savexml = "0";
        // if (saveanotherdetail)
        //     this.saveOtherDetail();
        if (this.ListLoadStorage.length > 0) {
            var manufacturerId = parseInt(this.ListLoadStorage[index].manufacturer);
            var familyId = this.ListLoadStorage[index].family;

            var modelId = parseInt(this.ListLoadStorage[index].model.toString());
            var d = this.ListLoadStorage[index].rating.toString();
            var ee = this.ListLoadStorage[index].tripunit.toString();
            var curve = (ee.toString());
            this.ProtectiveLoadStorage = new ProtectiveItem();
            this.ProtectiveLoadStorage = this.ListLoadStorage[index];
            var res = curve;
            if (curve.indexOf('-') != -1) {
                res = curve.substr(0, curve.lastIndexOf('-'));
                res = this.trimChar(res.trim(), "-");
            }
            this.GetBreakingByManufacturerId(manufacturerId, familyId, modelId, d.toString(), res);
            this.GetPolesTableByManufacturerId(manufacturerId, familyId, modelId, d.toString(), res);

            this.multiplierEIR = 1;
            this.multiplierIN = 1;
            this.multiplierEIO = 1;

            var arr = familyId.split(' ')[1];


            this.btnAddDeviceRequestCounter += 1;
            this.btnAddDeviceDisabled = true;
            // this.modifyindex = this.index;
            var data = this.ListLoadStorage[this.index];
            if (this.loadProtectiveXmlInput == null || this.loadProtectiveXmlInput == undefined)
                this.loadProtectiveXmlInput = new ProtectiveXmlInput();
            this.loadProtectiveXmlInput.manufacturer_id = parseInt(data.manufacturer);
            this.loadProtectiveXmlInput.range_id = data.family;
            this.loadProtectiveXmlInput.tripunit = data.tripunit;
            this.loadProtectiveXmlInput.model = parseInt(data.model);
            this.loadProtectiveXmlInput.rating = data.rating;
            this.loadProtectiveXmlInput.devicenostr = data.devicenostr;
            this.deviceNumber = data.devicenostr.trim();
            this.GetManufacturer();
            $(".clstripunit").show();
            $(".clscurveclass").show();
            $(".clsfustype").show();
            $(".clsreset").show();
            $("#devicesettingli").show();
            $("#normalDetail").show();
            $("#hvRelayDetail").hide();
            if (arr != null && arr != "" && (arr == "MCB" || arr == "Fuse")) {
                $("#devicesettingli").hide();
                if (document.querySelector("#ulprotectioncoordination").querySelector("#protectiveTab2Open") != null && $("#protectiveConfiguration").data('bs.modal') && $("#protectiveConfiguration").data('bs.modal')._isShown) {
                    (<HTMLElement>document.querySelector("#ulprotectioncoordination").querySelector("#protectiveTab2Open")).click();
                }
                if (arr == "MCB") {
                    $(".clsfustype").hide();
                }
                if (arr == "Fuse") {
                    $(".clscurveclass").hide();

                }
                $(".clstripunit").hide();
                $(".clsreset").hide();
                $("#divmcbacbonly").show();
                $("#divelectronic").hide();
                $("#divmagneticonly").hide();
                $("#divtmagneticonly").hide();
                this.ProtectiveLoadControls(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', '', '', ''
                    , '', '', '', ''
                    , '', ''
                    , '', ''
                    , '', ''
                    , '', '', savexml, generatexmlonly, false, false, isFromTreeNode);
            }
            else {
                $(".clsfustype").hide();
                $(".clscurveclass").hide();

                $("#divmcbacbonly").hide();
                if (this.isFromCoordination)
                    document.getElementById('defaultOpenProtective').click();
                this.ProtectiveLoadControls(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', '', '', ''
                    , '', '', '', ''
                    , '', ''
                    , '', ''
                    , '', ''
                    , '', '', savexml, generatexmlonly, false, false, isFromTreeNode);

            }
        }
    }
    getInputValues(str: string, controlid: string, controlspanid: string, controlhdn: string, controldiv: string, setvalue: number = 0, defaultvalue: string = "") {
        $(controlid).removeClass('hastype3');
        $(controlid).removeAttr('multiplier');
        $(controlid).removeClass('showmin');
        $(controlid).removeClass('excesselements');
        $(controlid).removeClass('classtype');
        if (str != null && str != "-" && str != "") {

            if (str.split(' ')[0].split('-')[0] == "Type1") {

                var val = str.split(' ')[0].split('-')[1].split(',');
                var Arr = [];
                var obj: any;
                var i = 0;
                val.forEach(element => {
                    obj = new Object();
                    obj.value = i++;
                    obj.legend = element.split(',')[0].toString();
                    Arr.push(obj);
                });
                if (val != null && val.length > 14) {
                    $(controlid).addClass('excesselements');
                }

                $(controlid).removeAttr('multiplier');
                this.setOptions(controlid, { stepsArray: Arr, showTicks: true });
                if (setvalue == 1) {
                    this.setDefaultValues(controlid, defaultvalue);
                    this.setCalculatedValues(controlid, defaultvalue);


                }
            }
            else if (str.split(' ')[0].split('-')[0] == "Type2") {
                var val = str.split(' ')[0].split('-')[1].split(',');
                var minval = val[0].split("=")[1];
                var maxval = val[1].split("=")[1];
                var step = val[2].split("=")[1];

                $(controlid).addClass('showmin');
                $(controlid).removeAttr('multiplier');
                this.setOptions(controlid, {
                    floor: minval,
                    ceil: maxval, step: step
                });

                if (setvalue == 1) {
                    this.setDefaultValues(controlid, defaultvalue);
                    this.setCalculatedValues(controlid, defaultvalue);

                }

            }
            else if (str.split(' ')[0].split('-')[0] == "Type3") {
                // $(controlid).addClass('hastype3'); 
                $(controlid).addClass('showmin');
                var val = str.split(' ')[0].split('-')[2].split(',');
                var multipliercolumn = str.split(' ')[0].split('-')[1];
                var multipliervalue = 1;
                var minval = val[0].split("=")[1];
                var maxval = val[1].split("=")[1];
                var step = val[2].split("=")[1];

                if (setvalue == 1) {

                    if (multipliercolumn == "E_Io") {
                        multipliervalue = this.multiplierEIO;
                    }
                    if (multipliercolumn == "E_Ir") {
                        multipliervalue = this.multiplierEIR;

                    }
                    if (multipliercolumn == "In") {
                        multipliervalue = this.multiplierIN;

                    }


                    this.setDefaultValues(controlid, (parseFloat(defaultvalue)).toString());
                    this.setCalculatedValues(controlid, (parseFloat(defaultvalue)).toString());
                }

                this.setOptions(controlid, {
                    floor: (parseFloat(minval) * multipliervalue),
                    ceil: (parseFloat(maxval) * multipliervalue), step: step
                });

            }
            else if (str.split(' ')[0].split('-')[0] == "Type4") {
                var val = str.split(' ')[0].split('-')[2].split(',');
                var multipliercolumn = str.split(' ')[0].split('-')[1];
                var multipliervalue = 1;
                var Arr = [];
                var obj: any;

                var i = 0;
                val.forEach(element => {
                    obj = new Object();
                    obj.value = i++;
                    obj.legend = element.split(',')[0].toString();
                    Arr.push(obj);
                });
                if (val != null && val.length > 14) {
                    $(controlid).addClass('excesselements');
                }
                this.setOptions(controlid, { stepsArray: Arr, showTicks: true });

                if (setvalue == 1) {


                    if (multipliercolumn == "E_Io") {
                        multipliervalue = this.multiplierEIO;
                    }
                    if (multipliercolumn == "E_Ir") {
                        multipliervalue = this.multiplierEIR;

                    }
                    if (multipliercolumn == "In") {
                        multipliervalue = this.multiplierIN;
                    }

                    $(controlid).attr('multiplier', multipliercolumn);
                    $(controlid).addClass('multiplier');

                    this.setDefaultValues(controlid, defaultvalue);
                    this.setCalculatedValues(controlid, this.RemoveTrailingZeros((Math.round((parseFloat(defaultvalue) * multipliervalue) * 100) / 100).toFixed(2)));
                }


            }
            else if (str.split(' ')[0].split('-')[0] == "Type5") {
                $(controlid).addClass('showmin');
                var val = str.split(' ')[0].split('-')[2].split(',');
                var multipliercolumn = str.split(' ')[0].split('-')[1];

                var minval = val[0].split("=")[1];
                var maxval = val[1].split("=")[1];
                var step = val[2].split("=")[1];
                var multipliervalue = 1;
                this.setOptions(controlid, {
                    floor: minval,
                    ceil: maxval, step: step
                });


                if (setvalue == 1) {
                    if (multipliercolumn == "E_Io") {
                        multipliervalue = this.multiplierEIO;
                    }
                    if (multipliercolumn == "E_Ir") {
                        multipliervalue = this.multiplierEIR;

                    }
                    if (multipliercolumn == "In") {
                        multipliervalue = this.multiplierIN;

                    }
                    $(controlid).attr('multiplier', multipliercolumn);
                    $(controlid).addClass('multiplier');
                    this.setDefaultValues(controlid, defaultvalue);
                    this.setCalculatedValues(controlid, this.RemoveTrailingZeros((Math.round((parseFloat(defaultvalue) * multipliervalue) * 100) / 100).toFixed(2)));

                }



            }
            else if (str.split(' ')[0].split('-')[0] == "Type6") {
                var val = str.split('(')[0].split('-')[1].split(',');
                var Arr = [];
                var obj: any;
                $(controlid).removeAttr('multiplier');
                var i = 0;
                val.forEach(element => {
                    obj = new Object();
                    obj.value = i++;
                    obj.legend = element.split(',')[0].toString();
                    Arr.push(obj);
                });
                if (val != null && val.length > 0) {
                    if (val[0] == 'Class 5') {
                        $(controlid).addClass('classtype');
                    }

                }
                if (val != null && val.length > 14) {

                    $(controlid).addClass('excesselements');
                }
                this.setOptions(controlid, { stepsArray: Arr, showTicks: true });
                if (setvalue == 1) {
                    this.setDefaultValues(controlid, defaultvalue);
                    this.setCalculatedValues(controlid, defaultvalue);
                }

            }

            if (str.split(' ')[0].split('-')[0] == "Type7") {
                var val = str.split(' ')[0].split('-')[1].split(',,');
                var Arr = [];
                var obj: any;
                var i = 0;
                $(controlid).removeAttr('multiplier');
                val.forEach(element => {
                    obj = new Object();
                    obj.value = i++;
                    obj.legend = element.split(',')[0].toString();
                    Arr.push(obj);
                });
                if (val != null && val.length > 14) {
                    $(controlid).addClass('excesselements');
                }
                this.setOptions(controlid, { stepsArray: Arr, showTicks: true, value: defaultvalue });
                if (setvalue == 1) {
                    this.setDefaultValues(controlid, defaultvalue);
                    this.setCalculatedValues(controlid, defaultvalue);
                }
            }
            $(controldiv).show();
        }
        else {
            defaultvalue = '-';
            this.setDefaultValues(controlid, defaultvalue);
            $(controldiv).hide();
        }

        if (controlid == "#pickupcurr-range") {
            // this.slidereir=event;
            // this.multiplierEIR=event;
            if (this.slidereircalc != null && this.slidereircalc != "-" && this.slidereircalc != "")
                this.multiplierEIR = parseFloat(this.slidereircalc);
        }
    }
    setOptions(controlid: string, defaultarr: any) {
        if (controlid == "#pickupcurrth") {
            this.slidertmiroptions = defaultarr;
        }
        else if (controlid == "#pickupcurrmg") {
            this.slidertmimoptions = defaultarr;

        }
        else if (controlid == "#pickupcurrmgpr") {
            this.slidermimoptions = defaultarr;

        }



        if (controlid == "#current-tolerance-range") {
            this.slidercurrenttoleranceoptions = defaultarr;
        }
        else if (controlid == "#time-tolerance-range") {
            this.slidertimetoleranceoptions = defaultarr;

        }
        else if (controlid == "#time-tobreak-range") {
            this.slidertimetobreakoptions = defaultarr;

        }

        if (controlid == "#pickupcurrpre-range") {
            this.slidereiooptions = defaultarr;
        }
        else if (controlid == "#pickupcurr-range") {
            this.slidereiroptions = defaultarr;

        }
        else if (controlid == "#idmtl-settings") {
            this.slideridmtloptions = defaultarr;

        }

        else if (controlid == "#timedelay-range") {
            this.slideretroptions = defaultarr;

        }

        else if (controlid == "#pickupshort-range") {
            this.slidereisdoptions = defaultarr;

        }
        else if (controlid == "#timedelayshort-range") {
            this.slideretsdoptions = defaultarr;

        }
        else if (controlid == "#pickupcurrinst-range") {
            this.sliderelioptions = defaultarr;

        }
    }
    setDefaultValues(controlid: string, defaultvalue: string) {

        if (controlid == "#pickupcurrth") {
            this.slidertmir = this.getValueFromLegend(defaultvalue, this.slidertmiroptions);
        }
        else if (controlid == "#pickupcurrmg") {
            this.slidertmim = this.getValueFromLegend(defaultvalue, this.slidertmimoptions);

        }
        else if (controlid == "#pickupcurrmgpr") {

            // this.slidermim=this.getValueFromLegend(defaultvalue,this.slidermimoptions);
            this.slidermim = this.getValueFromLegend(defaultvalue, this.slidermimoptions);

        }

        else if (controlid == "#idmtl-settings") {
            this.slideridmtl = this.getValueFromLegend(defaultvalue, this.slideridmtloptions);

        }


        if (controlid == "#current-tolerance-range") {
            this.slidercurrenttolerance = this.getValueFromLegend(defaultvalue, this.slidercurrenttoleranceoptions);
        }
        if (controlid == "#time-tolerance-range") {
            this.slidertimetolerance = this.getValueFromLegend(defaultvalue, this.slidertimetoleranceoptions);

        }

        if (controlid == "#time-tobreak-range") {
            this.slidertimetobreak = this.getValueFromLegend(defaultvalue, this.slidertimetobreakoptions);
        }
        if (controlid == "#pickupcurrpre-range") {
            this.slidereio = this.getValueFromLegend(defaultvalue, this.slidereiooptions);

        }

        if (controlid == "#pickupcurr-range") {
            this.slidereir = this.getValueFromLegend(defaultvalue, this.slidereiroptions);
        }
        if (controlid == "#timedelay-range") {
            this.slideretr = this.getValueFromLegend(defaultvalue, this.slideretroptions);

        }
        if (controlid == "#pickupshort-range") {
            this.slidereisd = this.getValueFromLegend(defaultvalue, this.slidereisdoptions);

        }
        if (controlid == "#timedelayshort-range") {

            this.slideretsd1 = this.getValueFromLegend(defaultvalue, this.slideretsdoptions);
        }
        if (controlid == "#pickupcurrinst-range") {
            this.slidereli = this.getValueFromLegend(defaultvalue, this.sliderelioptions);

        }


    }
    getValueFromLegend(showValue: string, arry: any): any {
        if (arry["showTicks"]) {
            if (showValue != "-") {
                let returnedValue: any = arry.stepsArray.find(e => {
                    return e['legend'] == showValue.toString();
                });
                if (returnedValue != null && returnedValue != undefined && isNaN(returnedValue["value"]) == false) {
                    return returnedValue["value"];
                }
                else {
                    return showValue;
                }
            }
        }
        else {
            return showValue;
        }
    }
    getLegendFromValue(showValue: string, arry: any): string {
        if (showValue == "NaN") {
            return "-";
        }
        if (arry["showTicks"]) {
            let returnedValue: any = arry.stepsArray.find(e => {
                return e.value == showValue;
            });

            if (returnedValue != null && returnedValue != undefined) {
                return returnedValue["legend"];
            }
            else {
                return showValue;
            }
        }
        else {
            return showValue;
        }

    }
    setCalculatedValues(controlid: string, calcvalue: string) {
        if (controlid == "#pickupcurrth") {
            this.slidertmircalc = calcvalue;
        }
        else if (controlid == "#pickupcurrmg") {
            this.slidertmimcalc = calcvalue;

        }
        else if (controlid == "#pickupcurrmgpr") {
            this.slidermimcalc = calcvalue;

        }
        else if (controlid == "#idmtl-settings") {
            this.slideridmtlcalc = calcvalue;

        }

        if (controlid == "#current-tolerance-range") {
            this.slidercurrenttolerancecalc = Math.round(100 * parseFloat(calcvalue)).toString();
        }
        if (controlid == "#time-tolerance-range") {
            this.slidertimetolerancecalc = Math.round(100 * parseFloat(calcvalue)).toString();

        }

        if (controlid == "#time-tobreak-range") {
            this.slidertimetobreakcalc = calcvalue;
        }
        if (controlid == "#pickupcurrpre-range") {
            this.slidereiocalc = calcvalue;

        }

        if (controlid == "#pickupcurr-range") {
            this.slidereircalc = calcvalue;
        }
        if (controlid == "#timedelay-range") {
            this.slideretrcalc = calcvalue;

        }
        if (controlid == "#idmtl-settings") {
            this.slideridmtlcalc = calcvalue;

        }
        if (controlid == "#pickupshort-range") {
            this.slidereisdcalc = calcvalue;

        }
        if (controlid == "#timedelayshort-range") {

            this.slideretsdcalc = calcvalue;

        }
        if (controlid == "#pickupcurrinst-range") {
            this.sliderelicalc = calcvalue;

        }

    }
    ProtectiveLoadControls(manufacturerId: number, familyId: string, modelId: number, rating: string, curve: string
        , tmir: string, tmim: string, mim: string, currenttolerance: string, timetolerance: string, timetobreak: string
        , eratingplug: string, eltbool: string, estbool: string, eintbool: string
        , eio: string, eir: string
        , etr: string, eisd: string
        , etsd: string, ei2tcurve: string
        , eli: string, idmtl: string, savexml: string = "", generatexmlonly: boolean = false
        , callfromcoordination: boolean = false, modifyonlydevice: boolean = false, isFromTreeNode: boolean = false
    ) {
        var objinput = new ListProtectiveInput();
        objinput.calculationid = this.newCalculationId;
        if (this.protectiveDrpList && this.protectiveDrpList.length > 0) {
            let ind = this.index;
            if (this.isFromCoordination == true && this.activeNode.objectcode == "MSB01" && this.hvRelayCurve && this.hvRelayCurve.length > 0) {
                ind = ind + 1;
            }

            objinput.nodeid = this.protectiveDrpList[ind].Node_Prefix;
        }
        else {
            objinput.nodeid = this.activeNode.objectcode;
        }
        // objinput.nodeid = this.activeNode.objectcode;
        objinput.tmir = tmir;
        objinput.tmim = tmim;
        objinput.mim = mim;
        objinput.currenttolerance = currenttolerance;
        objinput.timetolerance = timetolerance;
        objinput.timetobreak = timetobreak;
        objinput.eltboolproperty = eltbool;
        objinput.estboolproperty = estbool;
        objinput.eintboolproperty = eintbool;
        objinput.eio = eio;
        objinput.eir = eir;
        objinput.etr = etr;
        objinput.eisd = eisd;

        //  objinput.trippingcurrent = $("#current").val().toString()//s.trippingcurrent;
        objinput.eratingplug = eratingplug;
        objinput.etsd = etsd;
        objinput.ei2tcurve = ei2tcurve;
        objinput.eli = eli;
        objinput.idmtl = idmtl;
        objinput.rownumber = this.index;
        let trippingtime;
        if (!this.protectionConfigurationModel.MaxDisconnectionTime)
            trippingtime = "0.4";
        else {
            if (this.protectionConfigurationModel.MaxDisconnectionTime == "Custom")
                trippingtime = this.protectionConfigurationModel.CustomTime;
            else
                trippingtime = this.protectionConfigurationModel.MaxDisconnectionTime;
        }
        objinput.trippingtime = trippingtime;

        if (savexml == "1") {

            this.protectiveXmlInput = new ProtectiveXmlInput();
            this.protectiveXmlInput.xml = this.xmlData;
            this.protectiveXmlInput.model = modelId;
            this.protectiveXmlInput.manufacturer_id = manufacturerId;
            this.protectiveXmlInput.range_id = familyId;
            this.protectiveXmlInput.tripunit = curve;
            this.protectiveXmlInput.rating = rating;
            this.protectiveXmlInput.chartdata = '';
            this.protectiveXmlInput.id = 0;
            this.protectiveXmlInput.rownumber = this.protectiveXmlInputList.length;
            this.protectiveXmlInput.devicenostr = this.deviceNumber.trim();
            this.protectiveXmlInput.nodeid = this.activeNode.objectcode;
            this.protectiveXmlInputList = [this.protectiveXmlInput];


        }

        if (modifyonlydevice) {
            for (var j = 0; j < this.protectiveXmlInputList.length; j++) {
                //if (this.protectiveXmlInputList[j].rownumber != this.index) {
                this.protectiveXmlInputList[j].ischanged = false;
                //}
            }
        }
        else {
            if (this.protectiveXmlInputList.length == 1) {
                for (var j = 0; j < this.protectiveXmlInputList.length; j++) {
                    if (j == 0) {
                        this.protectiveXmlInputList[j].ischanged = true;
                    }
                }
            }
            else {
                for (var j = 0; j < this.protectiveXmlInputList.length; j++) {
                    if (this.protectiveXmlInputList[j].rownumber != this.index) {
                        this.protectiveXmlInputList[j].ischanged = false;
                    }
                    else {
                        this.protectiveXmlInputList[j].ischanged = true;

                    }
                }
            }
        }

        objinput.devices = this.protectiveXmlInputList;
        this.ListProtectiveInputCurrentObj = objinput;
        objinput.generatexmlonly = generatexmlonly;
        if (generatexmlonly) {
            objinput.standard = this.protectionCoordinationStandard;
        }
        // Call when we change the slider
        // generatexmlonly is true when we use new coordination popup.
        // If user changed the slider then we are not updating the xml in ECPNetworkProtectiveCalcDetail table we are just updating the clientside temp list..
        // To reload the slider we need to send the updated xml from templist.
        this._settingService.storeNetworkProtectiveXml(objinput).subscribe(result => {
            this.btnAddDeviceDisabled = false;
            this.btnAddDeviceRequestCounter = 0;
            if (result !== undefined && result !== null) {
                var arr = JSON.parse(result);
                this.xmlData = arr.xml;
                if (!generatexmlonly) {
                    this.xmlData_Original = arr.xml_Original;
                    this.xmlData_Tripping = arr.xml_TrippingCalculation;
                }
                if (this.protectiveXmlInputList.find(x => x.rownumber == arr.rownumber) != undefined
                    && this.protectiveXmlInputList.find(x => x.rownumber == arr.rownumber) != null
                    && this.protectiveXmlInputList.find(x => x.rownumber == arr.rownumber).id == 0)
                    this.protectiveXmlInputList.find(x => x.rownumber == arr.rownumber).id = arr.calcdetailid;


                if (arr.isrpnumber == "1") {
                    this.rpcaption = "Characteristics";
                }
                else {
                    this.rpcaption = "Rating plug";
                }

                this.CableSize['xml'] = this.xmlData;
                this.CableSize['row'] = this.index;
                this.CableSize['BrowserName'] = this.detectBrowserName();
                this.CableSize['calculationType'] = "Network Calculation";
                this.CableSize['calculationId'] = this.newCalculationId;
                this.CableSize['UserName'] = this.user.userName;
                if (this.xmlData) {
                    this._CableproCalc.ProtectiveCalculate(this.CableSize).subscribe((result) => {
                        //Keep Protective Input xml to perform network calculation
                        this.coordinationloader = false;
                        this.discriminatedData = {
                            discriminatedfirst: result.discriminatedfirst,
                            discriminatedfirstone: result.discriminatedfirstone,
                            discriminatedfirsttwo: result.discriminatedfirsttwo,
                            discriminatedfirstthree: result.discriminatedfirstthree,
                            discriminatedfirstfour: result.discriminatedfirstfour,
                            discriminatedsecond: result.discriminatedsecond,
                            discriminatedsecondone: result.discriminatedsecondone,
                            discriminatedsecondtwo: result.discriminatedsecondtwo,
                            discriminatedsecondthree: result.discriminatedsecondthree,
                            discriminatedsecondfour: result.discriminatedsecondfour,
                        }
                        if (!generatexmlonly) {
                            this.CableSize['protection']['Xml'] = this.xmlData_Original;
                            this.CableSize['protection']['TrippingXml'] = this.xmlData_Tripping;
                            this.updatecablesizeinarray();
                            this.onInputChange(isFromTreeNode);
                        }
                        else {
                            this.protectivecoordinationoutputxml = result.xml;
                            this.coordinationGenerateErrorsModel(result.discriminatedfirst, result.discriminatedsecond);
                        }

                        if (this.addingDeviceForHvFuse) {
                            // if (this.txtfamily && this.txtfamily.toLowerCase().includes("fuse")) {
                            this.HvFuseXml = this.xmlData_Original;
                            this.HvFuseTrippingXml = this.xmlData_Tripping;
                            this.HVFuse = true;
                            $('#HVFuseChk').prop('checked', true);
                           
                            this.addingDeviceForHvFuse = false;

                            this.onInputChange(isFromTreeNode);
                        }


                        this.chartData = result.curve;
                        this.dimSizeData = result.finaldimsize;
                        this.outputXmlData = result.xml;
                        this.trippingCurrentAtSpecifiedTime = result.trippingCurrentAtSpecifiedTime;
                        // this.saveOtherDetail();
                        $('.spnmanufacurer').text(result.manufacturer);
                        $('.spnfamily').text(result.family);
                        $('.spnmodel').text(result.model);
                        $('.spnteststandard').text(result.teststandard);
                        $('.spntripunit').text(result.tripunit);
                        $('.spnrating').text(result.rating);
                        $('.spntripunittype').text(result.tripunittype);
                        $('.spnratedvoltage').text(result.ratedmaximum);
                        $('.spncurveclass').text(result.curveclass);
                        $('.spnfusetype').text(result.fusetype);
                        this.selectedModalId = this.protectiveXmlInputList[0].model;

                        this.txtmodal = result.model;
                        this.txtrating = result.rating;
                        // this.txtmodal = result.model;//.split('-')[0];
                        this.txtfamily = result.familywithdevicetype;

                        if (result.manufacturer != "Generic") {
                            this.IsGeneric = true;
                            var name = result.manufacturer + "logo";
                            this.protectiveImgSrc = '/assets/cablepro/logo/' + name + '.svg';
                            this.protectiveImgSrc2 = '/assets/cablepro/logo/' + name + '.svg';
                        }
                        else {
                            this.protectiveImgSrc = '/assets/cablepro/logo/a.png';
                            this.protectiveImgSrc2 = '/assets/cablepro/logo/a.png';
                            this.IsGeneric = false;
                        }

                        var devicearr = this.defaultFamilyId.split(' ')[1];
                        if (devicearr == "MCCB" || devicearr == "ACB") {
                            this.txtTripUnit = result.tripunit;
                            this.selectListFive = "Trip unit";
                            this.curveClassOrTripUnit = "Trip unit";
                        }
                        else if (devicearr == "Fuse") {
                            this.txtTripUnit = result.fusetype;
                            this.txtTripUnitfuse=this.txtTripUnit;
                            this.selectListFive = "Fuse type";
                            this.curveClassOrTripUnit = "Fuse type";

                            this.txtmodalfuse = result.model;
                            this.txtratingfuse = result.rating;
                            this.txtfamilyfuse = result.familywithdevicetype;
                        }
                        else {
                            this.txtTripUnit = result.curveclass;
                            this.selectListFive = "Curve class";
                            this.curveClassOrTripUnit = "Curve class";
                        }
                        if (result.tripunit != null && result.tripunit != '') {
                            this.txtTripUnit = result.tripunit;
                        }
                        if (result.fusetype != null && result.fusetype != '') {
                            this.txtTripUnit = result.fusetype;
                            this.txtTripUnitfuse=this.txtTripUnit;
                        }
                        if (result.curveclass != null && result.curveclass != '') {
                            this.txtTripUnit = result.curveclass;
                        }
                       

                        if (!callfromcoordination)
                            this.runCurveEvent(this.index);


                    });
                }
                var devicearr = familyId.split(' ')[1];
                if (devicearr != null && devicearr != "" && (devicearr == "MCB" || devicearr == "Fuse")) {
                    $("#divmcbacbonly").show();
                    $("#divelectronic").hide();
                    $("#divmagneticonly").hide();
                    $("#divtmagneticonly").hide();
                }
                else {
                    $("#divmcbacbonly").hide();
                    //this.deviceType="3";
                    this.deviceType = arr.type;
                    this.multiplierIN = arr.inrating;
                    var setvalue = 1;
                    if (this.deviceType == "3") {
                        $("#divelectronic").hide();
                        $("#divmagneticonly").show();
                        $("#divtmagneticonly").hide();
                        this.getInputValues(arr.mim, '#pickupcurrmgpr', "#pickupcurrmgprspn", "#pickupcurrmgprhdn", "#pickupcurrmgprdiv", setvalue, arr.defaultmim);
                    }
                    if (this.deviceType == "2") {
                        $("#divelectronic").hide();
                        $("#divmagneticonly").hide();
                        $("#divtmagneticonly").show();
                        this.getInputValues(arr.tmir, '#pickupcurrth', "#gridSizeValue", "#pickupcurrthhdn", "#pickupcurrthdiv", setvalue, arr.defaulttmir);
                        this.getInputValues(arr.tmim, '#pickupcurrmg', "#pickupcurrmgspn", "#pickupcurrmgshdn", "#pickupcurrmgsdiv", setvalue, arr.defaulttmim);
                    }
                    if (this.deviceType == "1") {
                        $("#divelectronic").show();
                        $("#divmagneticonly").hide();
                        $("#divtmagneticonly").hide();

                        this.multiplierEIR = arr.defaulteir != "-" ? arr.defaulteir : 1;
                        if (arr.rp != null && arr.rp != "") {
                            $("#ratingplugdiv").show();

                            $("#ratingplug").val(arr.rp);
                            this.ratingPlugList = arr.rplist;
                            this.rp = arr.rp;
                        }
                        else {
                            $('#ratingplug option:selected').val('');
                            $("#ratingplugdiv").hide();
                        }

                        this.multiplierEIO = arr.defaulteio != "-" ? arr.defaulteio : 1;
                        if (arr.ei2tcurve != "1" && arr.ei2tcurve != "0") {
                            $("#I2tdiv").hide();
                            $('#chcki2curve').prop('checked', false);
                        }
                        else {
                            $("#I2tdiv").show();
                            if (arr.ei2tcurve == "1") {

                                $('#chcki2curve').prop('checked', true);
                            }
                            else {
                                $('#chcki2curve').prop('checked', false);

                            }
                        }

                        this.getInputValues(arr.currenttolerance, '#current-tolerance-range', "#current-tolerance-rangespn", "#current-tolerance-rangehdn", "#current-tolerance-rangediv", setvalue, arr.defaultcurrenttolerance);
                        this.getInputValues(arr.timetolerance, '#time-tolerance-range', "#time-tolerance-rangespn", "#time-tolerance-rangehdn", "#time-tolerance-rangediv", setvalue, arr.defaulttimetolerance);
                        this.getInputValues(arr.timetobreak, '#time-tobreak-range', "#time-tobreak-rangespn", "#time-tobreak-rangehdn", "#time-tobreak-rangediv", setvalue, arr.defaulttimetobreak);
                        this.getInputValues(arr.eio, '#pickupcurrpre-range', "#pickupcurrpre-rangespn", "#pickupcurrpre-rangehdn", "#pickupcurrpre-rangediv", setvalue, arr.defaulteio);
                        this.getInputValues(arr.eir, '#pickupcurr-range', "#pickupcurr-rangespn", "#pickupcurr-rangehdn", "#pickupcurr-rangediv", setvalue, arr.defaulteir);
                        this.getInputValues(arr.etr, '#timedelay-range', "#timedelay-rangespn", "#timedelay-rangehdn", "#timedelay-rangediv", setvalue, arr.defaultetr);
                        this.getInputValues(arr.eisd, '#pickupshort-range', "#pickupshort-rangespn", "#pickupshort-rangehdn", "#pickupshort-rangediv", setvalue, arr.defaulteisd);
                        this.getInputValues(arr.etsd, '#timedelayshort-range', "#timedelayshort-rangespn", "#timedelayshort-rangehdn", "#timedelayshort-rangediv", setvalue, arr.defaultetsd);
                        this.getInputValues(arr.eli, '#pickupcurrinst-range', "#pickupcurrinst-rangespn", "#pickupcurrinst-rangehdn", "#pickupcurrinst-rangediv", setvalue, arr.defaulteli);
                        this.getInputValues(arr.idmtl, '#idmtl-settings', "#idmtl-settingsspn", "#idmtl-settingshdn", "#idmtl-settingsdiv", setvalue, arr.defaultidmtl);
                        if (arr.eltboolproperty == "show") {
                            //
                            if (arr.chklong == "0") {
                                $("#divlongtimeprot").hide();
                                $('#chcklongtime').prop('checked', false);
                            }
                            else {
                                $('#chcklongtime').prop('checked', true);
                                $("#divlongtimeprot").show();

                            }
                            $("#divlongtimeprotchk").show();
                            $("#divlongtimeprotchk :input").removeAttr('disabled');
                        }
                        else if (arr.eltboolproperty == "hide") {

                            $('#chcklongtime').prop('checked', false);
                            if (arr.isrpnumber == "1") {
                                $('#chcklongtime').prop('checked', true);
                                $("#timedelay-rangediv").hide();
                            }
                            $("#divlongtimeprotchk").hide();
                        }
                        else if (arr.eltboolproperty == "disabled" || arr.eltboolproperty == null) {
                            $('#chcklongtime').prop('checked', true);
                            $("#divlongtimeprot").show();
                            $("#divlongtimeprotchk").show();
                            $("#divlongtimeprotchk :input").attr('disabled', 'true');
                        }
                        if ($('#ratingplug option:selected').val() == "OFF" || this.rp == "OFF") {
                            $("#pickupcurr-rangediv").hide();
                            $("#timedelay-rangediv").hide();
                        }
                        if (arr.estboolproperty == "show") {
                            $("#divshorttime").show();
                            $("#divshorttimechk").show();
                            $("#divshorttimechk :input").removeAttr('disabled');
                            $('#chckshorttime').prop('checked', true);
                            if (arr.chkshort == "0") {
                                $("#divshorttime").hide();
                                $('#chckshorttime').prop('checked', false);
                            }
                            else {
                                $('#chckshorttime').prop('checked', true);
                                $("#divshorttime").show();

                            }


                        }
                        else if (arr.estboolproperty == "hide") {
                            $("#divshorttime").hide();
                            $("#divshorttimechk").hide();

                            $('#chckshorttime').prop('checked', false);
                            if (arr.isrpnumber == "1") {
                                $('#chckshorttime').prop('checked', true);
                            }


                        }
                        else if (arr.estboolproperty == "disabled" || arr.estboolproperty == null) {
                            $("#divshorttime").show();
                            $("#divshorttimechk").show();
                            $("#divshorttimechk :input").attr('disabled', 'true');
                            $('#chckshorttime').prop('checked', true);

                        }

                        if (arr.eintboolproperty == "show") {
                            $("#divinstant").show();
                            $("#divinstantchk").show();
                            // $('#chckInstant').prop('checked', true);
                            if (arr.chkinst == "0") {
                                $("#divinstant").hide();
                                $('#chckInstant').prop('checked', false);
                            }
                            else
                                $('#chckInstant').prop('checked', true);

                            $("#divinstantchk :input").removeAttr('disabled');
                        }
                        else if (arr.eintboolproperty == "hide") {
                            $("#divinstant").hide();
                            $('#chckInstant').prop('checked', false);
                            if (arr.isrpnumber == "1") {
                                $('#chckInstant').prop('checked', true);
                            }

                            $("#divinstantchk").hide();
                        }
                        else if (arr.eintboolproperty == "disabled" || arr.eintboolproperty == null) {
                            $("#divinstant").show();
                            //  $('#chckInstant').prop('checked', true);
                            $("#divinstantchk").show();
                            $("#divinstantchk :input").attr('disabled', 'true');

                            if (arr.chkinst == "0") {
                                $("#divinstant").hide();
                                $("#divinstantchk").hide();
                                $('#chckInstant').prop('checked', false);
                            }
                            else
                                $('#chckInstant').prop('checked', true);
                        }
                    }
                }
            }
            $('#protectiveSelector').modal('hide');
        },
            err => {
                this.btnAddDeviceDisabled = false;
                this.btnAddDeviceRequestCounter = 0;
                $('#protectiveSelector').modal('hide');
                this.hideLoader();
            });
    }

    fillDeviceList() {
        var str = ""
        this.deviceList = [];

        for (var i = 0; i < this.ListLoadStorage.length; i++) {
            // if (this.ListLoadStorage[i].deviceno <= 9) {
            //     str = "Device 0" + this.ListLoadStorage[i].deviceno;

            // }
            // if (this.ListLoadStorage[i].deviceno > 9) {
            //     str = "Device " + this.ListLoadStorage[i].deviceno;

            // }
            this.deviceList.push(this.ListLoadStorage[i].devicenostr);


        }
    }

    GenerateMaxAllowedEFLImpedance() {
        if (this.RCD == true) {
            this.protectionConfigurationModel.MaxAllowedEFLImpedance = "RCD";
        }
        else {
            if (this.trippingCurrentAtSpecifiedTime == "Not Applicable")
                this.protectionConfigurationModel.MaxAllowedEFLImpedance = "N/A";
            else {
                let phase = this.CableproInput.PhaseId;
                let voltage = parseFloat(this.voltage_txt);
                if (this.cableCalculationStandard == '2' || this.cableCalculationStandard == '3') {
                    let voltageTolerance = this.VoltageTolerance;// $('#VoltageTolerance').val().toString();
                    let voltageFactorConst = 0.95;
                    if (voltageTolerance == "0") {
                        voltageFactorConst = 0.9;
                    }
                    else if (voltageTolerance == "0") {
                        voltageFactorConst = 0.95;
                    }

                    if (phase == 101) {//3 Phase
                        let volateAfterSqrt = voltage / Math.sqrt(3);
                        this.protectionConfigurationModel.MaxAllowedEFLImpedance = ((volateAfterSqrt / parseFloat(this.trippingCurrentAtSpecifiedTime) * voltageFactorConst).toFixed(4)).toString();
                    }
                    else {
                        this.protectionConfigurationModel.MaxAllowedEFLImpedance = ((voltage / parseFloat(this.trippingCurrentAtSpecifiedTime) * voltageFactorConst).toFixed(4)).toString();
                    }
                }
                else {
                    if (phase == 101) {//3 Phase
                        let volateAfterSqrt = voltage / Math.sqrt(3);
                        this.protectionConfigurationModel.MaxAllowedEFLImpedance = ((volateAfterSqrt / parseFloat(this.trippingCurrentAtSpecifiedTime)).toFixed(4)).toString();
                    }
                    else {
                        this.protectionConfigurationModel.MaxAllowedEFLImpedance = ((voltage / parseFloat(this.trippingCurrentAtSpecifiedTime)).toFixed(4)).toString();
                    }
                }
            }
        }
    }

    showProtectiveDeviceConfigurationUI() {

        this.index = 0;
        this.protectiveDrpList = [];
        this.isProtectiveConfigChanged = false;
        let isUpdated = false;
        this.trippingCurrentAtSpecifiedTime_Original = this.trippingCurrentAtSpecifiedTime;
        this.onEarthinSystemChange();
        this.GenerateMaxAllowedEFLImpedance();

        this.onMaxDisconnectionTimeChange();

        if (this.cableCalculationStandard == '2' || this.cableCalculationStandard == '3') {
            if (!this.protectionConfigurationModel.EarthingSystem) {
                this.protectionConfigurationModel.EarthingSystem = "TN";
            }
            $('#drpEarthingSystem').val(this.protectionConfigurationModel.EarthingSystem).select2({
                width: '100%',
                minimumResultsForSearch: Infinity // disable search
            }).trigger('change', "true");
            this.getMaxDisconnectionTimeOptions();
            if (!this.protectionConfigurationModel.MaxDisconnectionTime) {
                this.protectionConfigurationModel.MaxDisconnectionTime = this.MaxDisconnectionTimeOptions[0].id;
                isUpdated = true;
            }
            else {
                let currentObj = this.MaxDisconnectionTimeOptions.find(m => m.id == this.protectionConfigurationModel.MaxDisconnectionTime);
                if (!currentObj) {
                    this.protectionConfigurationModel.MaxDisconnectionTime = this.MaxDisconnectionTimeOptions[0].id;
                    isUpdated = true;
                }
            }
        }
        else {
            if (!this.protectionConfigurationModel.MaxDisconnectionTime) {
                this.protectionConfigurationModel.MaxDisconnectionTime = "0.4";
                isUpdated = true;
            }
            // this.protectionConfigurationModel.EarthingSystem = undefined;
            this.MaxDisconnectionTimeOptions = [
                { text: "0.4 s", id: "0.4" },
                { text: "5 s", id: "5" },
                { text: "Custom", id: "Custom" }
            ];
        }
        if (isUpdated) {
            $('#maxDisconnectionTime').val(this.protectionConfigurationModel.MaxDisconnectionTime).select2({
                width: '100%',
                minimumResultsForSearch: Infinity // disable search
            }).trigger('change', "true");
        }

        $('#protectiveConfiguration').modal('show');
        setTimeout(() => {
            $('[data-toggle="tooltip"]').tooltip();
        });

        if (this.ListLoadStorage.length > 0) {
            var familyId = this.ListLoadStorage[0].family;
            var arr = familyId.split(' ')[1];
            $("#devicesettingli").show();
            $("#normalDetail").show();
            $("#hvRelayDetail").hide();
            if (arr != null && arr != "" && (arr == "MCB" || arr == "Fuse")) {
                $("#devicesettingli").hide();
                if (document.querySelector("#ulprotectioncoordination").querySelector("#protectiveTab2Open") != null) {
                    (<HTMLElement>document.querySelector("#ulprotectioncoordination").querySelector("#protectiveTab2Open")).click();
                }
                if (arr == "MCB") {
                    $(".clsfustype").hide();
                }
                if (arr == "Fuse") {
                    $(".clscurveclass").hide();
                }
                $(".clstripunit").hide();
                $(".clsreset").hide();
                $("#divelectronic").hide();
                $("#divmagneticonly").hide();
                $("#divtmagneticonly").hide();
            }
            else {
                $(".clsfustype").hide();
                $(".clscurveclass").hide();
                document.getElementById('defaultOpenProtective').click();
            }
        }
        this.protectionConfigurationModel_Pristine = Object.assign({}, this.protectionConfigurationModel);
        setTimeout(() => {

            if ($('.c-select').length) {
                let $singleSelect = $('.c-select'),
                    $singleSelectHasSearch = $('.c-select.has-search'),
                    $multipleSelect = $('.c-select.c-select--multiple'),
                    $disabledSelect = $('.c-select.is-disabled');

                $singleSelect.select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });

                // multiple select
                $multipleSelect.select2({
                    width: '100%',
                    multiple: true
                });

                // disabled select
                $disabledSelect.select2({
                    width: '100%',
                    disabled: true
                });
            }

            var data = this.chartData;
            var dimsize = this.dimSizeData;
            this.getChartLatest(data, dimsize);
        }, 200);

    }

    getMaxDisconnectionTimeOptions() {
        this.MaxDisconnectionTimeOptions = [
            { text: "Custom", id: "Custom" }
        ];
        let phase = this.CableproInput.PhaseId;
        let voltage = parseFloat(this.voltage_txt);
        let earthingSystem = this.protectionConfigurationModel.EarthingSystem;
        if (phase == 102) {//DC
            if (earthingSystem == 'TN') {
                if (voltage <= 120) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.8 s", id: "0.8" },
                        { text: "5 s", id: "5" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
                else if (voltage > 120 && voltage <= 230) {
                    if (this.cableCalculationStandard == '2') {
                        this.MaxDisconnectionTimeOptions = [
                            { text: "1 s", id: "1" },
                            { text: "5 s", id: "5" },
                            { text: "Custom", id: "Custom" }
                        ];
                    }
                    else if (this.cableCalculationStandard == '3') {
                        {
                            this.MaxDisconnectionTimeOptions = [
                                { text: "5 s", id: "5" },
                                { text: "Custom", id: "Custom" }
                            ];
                        }
                    }
                }
                else if (voltage > 230 && voltage <= 400) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.4 s", id: "0.4" },
                        { text: "5 s", id: "5" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
                else if (voltage > 400) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.1 s", id: "0.1" },
                        { text: "5 s", id: "5" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
            }
            else if (earthingSystem == 'TT') {
                if (voltage <= 120) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.3 s", id: "0.3" },
                        { text: "1 s", id: "1" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
                else if (voltage > 120 && voltage <= 230) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.4 s", id: "0.4" },
                        { text: "1 s", id: "1" },
                        { text: "Custom", id: "Custom" },
                    ];
                }
                else if (voltage > 230 && voltage <= 400) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.2 s", id: "0.2" },
                        { text: "1 s", id: "1" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
                else if (voltage > 400) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.1 s", id: "0.1" },
                        { text: "1 s", id: "1" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
            }
        }
        else if (phase == 101) {//3 Phase
            let volateAfterSqrt = voltage / Math.sqrt(3);
            if (earthingSystem == 'TN') {
                if (volateAfterSqrt <= 120) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.8 s", id: "0.8" },
                        { text: "5 s", id: "5" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
                else if (volateAfterSqrt > 120 && volateAfterSqrt <= 231) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.4 s", id: "0.4" },
                        { text: "5 s", id: "5" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
                else if (volateAfterSqrt > 231 && volateAfterSqrt <= 400) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.2 s", id: "0.2" },
                        { text: "5 s", id: "5" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
                else if (volateAfterSqrt > 400) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.1 s", id: "0.1" },
                        { text: "5 s", id: "5" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
            }
            else if (earthingSystem == 'TT') {
                if (volateAfterSqrt <= 120) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.3 s", id: "0.3" },
                        { text: "1 s", id: "1" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
                else if (volateAfterSqrt > 120 && volateAfterSqrt <= 231) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.2 s", id: "0.2" },
                        { text: "1 s", id: "1" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
                else if (volateAfterSqrt > 231 && volateAfterSqrt <= 400) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.07 s", id: "0.07" },
                        { text: "1 s", id: "1" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
                else if (volateAfterSqrt > 400) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.04 s", id: "0.04" },
                        { text: "1 s", id: "1" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
            }
        }
        else {//1 Phase or 2 Phase
            if (earthingSystem == 'TN') {
                if (voltage <= 120) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.8 s", id: "0.8" },
                        { text: "5 s", id: "5" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
                else if (voltage > 120 && voltage <= 230) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.4 s", id: "0.4" },
                        { text: "5 s", id: "5" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
                else if (voltage > 230 && voltage <= 400) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.2 s", id: "0.2" },
                        { text: "5 s", id: "5" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
                else if (voltage > 400) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.1 s", id: "0.1" },
                        { text: "5 s", id: "5" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
            }
            else if (earthingSystem == 'TT') {
                if (voltage <= 120) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.3 s", id: "0.3" },
                        { text: "1 s", id: "1" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
                else if (voltage > 120 && voltage <= 230) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.2 s", id: "0.2" },
                        { text: "1 s", id: "1" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
                else if (voltage > 230 && voltage <= 400) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.07 s", id: "0.07" },
                        { text: "1 s", id: "1" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
                else if (voltage > 400) {
                    this.MaxDisconnectionTimeOptions = [
                        { text: "0.04 s", id: "0.04" },
                        { text: "1 s", id: "1" },
                        { text: "Custom", id: "Custom" }
                    ];
                }
            }
        }
    }

    updateElectronic2(controlchangedId: string = "", $event: any) {
        if ($event.value.toString() != "NaN") {
            this.coordinationloader = true;
            var manufacturerId = parseInt(this.ListLoadStorage[this.index].manufacturer);
            var familyId = this.ListLoadStorage[this.index].family;

            var modelId = parseInt(this.ListLoadStorage[this.index].model.toString());
            var d = this.ListLoadStorage[this.index].rating.toString();
            var ee = this.ListLoadStorage[this.index].tripunit.toString();
            var curve = (ee.toString());



            var multiplier = 1;
            var multipliercolumn = $(controlchangedId).attr('multiplier');
            if (multipliercolumn != undefined) {
                if (multipliercolumn == "E_Io") {
                    multiplier = this.multiplierEIO;
                }
                if (multipliercolumn == "E_Ir") {
                    multiplier = this.multiplierEIR;

                }
                if (multipliercolumn == "In") {
                    multiplier = this.multiplierIN;

                }
            }
            if (controlchangedId == "#timedelayshort-range") {
                this.slideretsd1 = $event.value;
                this.slideretsdcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideretsd1, this.slideretsdoptions), multiplier);

            }


            var currenttolerance = this.getLegendFromValue(this.slidercurrenttolerance, this.slidercurrenttoleranceoptions);
            if ($("#current-tolerance-range").hasClass("hastype3")) {
                currenttolerance = (parseFloat(currenttolerance) / this.multiplierIN).toFixed(2).toString();
            }

            var timetolerance = this.getLegendFromValue(this.slidertimetolerance, this.slidertimetoleranceoptions);
            if ($("#time-tolerance-range").hasClass("hastype3")) {
                timetolerance = (parseFloat(timetolerance) / this.multiplierIN).toFixed(2).toString();
            }

            var timetobreak = this.getLegendFromValue(this.slidertimetobreak, this.slidertimetobreakoptions);

            if ($("#time-tobreak-range").hasClass("hastype3")) {
                timetobreak = (parseFloat(timetobreak) / this.multiplierIN).toFixed(2).toString();
            }
            var eratingplug = "";
            if ($('#ratingplug option:selected').val() != undefined)
                eratingplug = $('#ratingplug option:selected').val().toString();//$('#ratingplug').val().toString();
            var eltbool = "0";
            if ($("#chcklongtime").is(":checked") && $('#ratingplug option:selected').val() != "OFF") {
                eltbool = "1";
            }


            var estbool = "0";
            if ($("#chckshorttime").is(":checked")) {
                estbool = "1";
            }
            else {
                $("#chcki2curve").prop("checked", false);
            }
            var eintbool = "0";
            if ($("#chckInstant").is(":checked")) {
                eintbool = "1";
            }



            var eio = this.getLegendFromValue(this.slidereio, this.slidereiooptions)
                ;
            if ($("#pickupcurrpre-range").hasClass("hastype3")) {
                eio = (parseFloat(eio) / this.multiplierIN).toFixed(2).toString();
            }
            var eir = this.getLegendFromValue(this.slidereir, this.slidereiroptions)
                ;

            if ($("#pickupcurr-range").hasClass("hastype3")) {
                eir = (parseFloat(eir) / this.multiplierIN).toFixed(2).toString();
            }
            var etr = this.getLegendFromValue(this.slideretr, this.slideretroptions)
                ;
            if ($("#timedelay-range").hasClass("hastype3")) {
                etr = (parseFloat(etr) / this.multiplierIN).toFixed(2).toString();
            }
            var idmtl = this.getLegendFromValue(this.slideridmtl, this.slideridmtloptions)
                ;
            if ($("#idmtl-settings").hasClass("hastype3")) {
                idmtl = (parseFloat(idmtl) / this.multiplierIN).toFixed(2).toString();
            }
            var eisd = this.getLegendFromValue(this.slidereisd, this.slidereisdoptions)
                ;
            if ($("#pickupshort-range").hasClass("hastype3")) {
                eisd = (parseFloat(eisd) / this.multiplierIN).toFixed(2).toString();
            }
            var etsd = this.getLegendFromValue(this.slideretsd1, this.slideretsdoptions)
                ;
            if ($("#timedelayshort-range").hasClass("hastype3")) {
                etsd = (parseFloat(etsd) / this.multiplierIN).toFixed(2).toString();
            }

            var ei2tcurve = "0";
            if ($("#chcki2curve").is(":checked")) {
                ei2tcurve = "1";
            }


            if ($('#I2tdiv').css('display') == 'none') {
                ei2tcurve = "";

            }
            var eli = this.getLegendFromValue(this.slidereli, this.sliderelioptions);

            if ($("#pickupcurrinst-range").hasClass("hastype3")) {
                eli = (parseFloat(eli) / this.multiplierIN).toFixed(2).toString();
            }






            this.ProtectiveGetXml(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', currenttolerance, timetolerance, timetobreak
                , eratingplug, eltbool, estbool, eintbool
                , eio, eir
                , etr, eisd
                , etsd, ei2tcurve
                , eli, 0, controlchangedId, idmtl);
        }
    }


    updateElectronic(controlchangedId: string = "", $event: any) {

        if ($event.value.toString() != "NaN") {
            this.coordinationloader = true;
            var manufacturerId = parseInt(this.ListLoadStorage[this.index].manufacturer);
            var familyId = this.ListLoadStorage[this.index].family;

            var modelId = parseInt(this.ListLoadStorage[this.index].model.toString());
            var d = this.ListLoadStorage[this.index].rating.toString();
            var ee = this.ListLoadStorage[this.index].tripunit.toString();
            var curve = (ee.toString());



            var multiplier = 1;
            var multipliercolumn = $(controlchangedId).attr('multiplier');
            if (multipliercolumn != undefined) {
                if (multipliercolumn == "E_Io") {
                    multiplier = this.multiplierEIO;
                }
                if (multipliercolumn == "E_Ir") {
                    multiplier = this.multiplierEIR;

                }
                if (multipliercolumn == "In") {
                    multiplier = this.multiplierIN;
                }
            }


            if (controlchangedId == "#current-tolerance-range") {
                this.slidercurrenttolerance = $event.value;
                this.slidercurrenttolerancecalc = Math.round(100 * parseFloat(this.getCalculatedResult
                    (this.getLegendFromValue($event.value, this.slidercurrenttoleranceoptions), multiplier))).toString();
            }
            if (controlchangedId == "#time-tolerance-range") {
                this.slidertimetolerance = $event.value;
                this.slidertimetolerancecalc = Math.round(100 * parseFloat(this.getCalculatedResult(this.getLegendFromValue($event.value
                    , this.slidertimetoleranceoptions), multiplier))).toString();

            }

            if (controlchangedId == "#time-tobreak-range") {
                this.slidertimetobreak = $event.value;
                this.slidertimetobreakcalc = this.getCalculatedResult(this.getLegendFromValue($event.value
                    , this.slidertimetobreakoptions), multiplier);
            }
            if (controlchangedId == "#pickupcurrpre-range") {

                this.slidereio = $event.value;
                this.slidereiocalc = this.getCalculatedResult(this.getLegendFromValue($event.value
                    , this.slidereiooptions), multiplier);
                this.multiplierEIO = parseFloat(this.slidereiocalc);
                var elementonwhichevents = "";
                $(".multiplier").each(function () {
                    var a = $(this);
                    if ($(a).attr('multiplier') == "E_Io") {
                        elementonwhichevents = elementonwhichevents + "," + $(a).attr("id");
                    }
                });
                var elementonwhicheventsarry = elementonwhichevents.split(',');
                for (var i = 0; i < elementonwhicheventsarry.length; i++) {
                    if (elementonwhicheventsarry[i] != "") {
                        if (elementonwhicheventsarry[i] == "current-tolerance-range") {
                            this.slidercurrenttolerancecalc = Math.round(100 * parseFloat(this.getCalculatedResult
                                (this.getLegendFromValue(this.slidercurrenttolerance, this.slidercurrenttoleranceoptions), this.multiplierEIO))).toString();
                        }
                        if (elementonwhicheventsarry[i] == "time-tolerance-range") {
                            this.slidertimetolerancecalc = Math.round(100 * parseFloat(this.getCalculatedResult(this.getLegendFromValue(this.slidertimetolerance, this.slidertimetoleranceoptions)
                                , this.multiplierEIO))).toString();

                        }
                        if (elementonwhicheventsarry[i] == "time-tobreak-range") {
                            this.slidertimetobreakcalc = this.getCalculatedResult(this.getLegendFromValue(this.slidertimetobreak, this.slidertimetobreakoptions)
                                , this.multiplierEIO);
                        }

                        if (elementonwhicheventsarry[i] == "pickupcurr-range") {
                            // this.slidereir=event;
                            // this.multiplierEIR=event;
                            this.slidereircalc = this.getCalculatedResult(this.getLegendFromValue(this.slidereir, this.slidereiroptions)
                                , this.multiplierEIO);
                        }
                        if (elementonwhicheventsarry[i] == "idmtl-settings") {

                            this.slideridmtlcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideridmtl, this.slideridmtloptions)
                                , this.multiplierEIO);
                        }
                        if (elementonwhicheventsarry[i] == "timedelay-range") {
                            this.slideretrcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideretr, this.slideretroptions)
                                , this.multiplierEIO);


                        }
                        if (elementonwhicheventsarry[i] == "pickupshort-range") {
                            this.slidereisdcalc = this.getCalculatedResult(
                                this.getLegendFromValue(this.slidereisd, this.slidereisdoptions), this.multiplierEIO);

                        }
                        if (elementonwhicheventsarry[i] == "timedelayshort-range") {

                            this.slideretsdcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideretsd1, this.slideretsdoptions)
                                , this.multiplierEIO);

                        }
                        if (elementonwhicheventsarry[i] == "pickupcurrinst-range") {

                            this.sliderelicalc = this.getCalculatedResult(this.getLegendFromValue(this.slidereli, this.sliderelioptions)
                                , this.multiplierEIO);

                        }
                    }
                }

            }

            if (controlchangedId == "#pickupcurr-range") {
                this.slidereir = $event.value;
                this.slidereircalc = this.getCalculatedResult(this.getLegendFromValue(this.slidereir, this.slidereiroptions)
                    , multiplier);

                this.multiplierEIR = parseFloat(this.slidereircalc);

                var elementonwhichevents = "";
                $(".multiplier").each(function () {
                    var a = $(this);
                    if ($(a).attr('multiplier') == "E_Ir") {
                        elementonwhichevents = elementonwhichevents + "," + $(a).attr("id");
                    }
                });
                var elementonwhicheventsarry = elementonwhichevents.split(',');
                for (var i = 0; i < elementonwhicheventsarry.length; i++) {
                    if (elementonwhicheventsarry[i] != "") {
                        if (elementonwhicheventsarry[i] == "current-tolerance-range") {
                            this.slidercurrenttolerancecalc = Math.round(100 * parseFloat(this.getCalculatedResult(this.getLegendFromValue(this.slidercurrenttolerance, this.slidercurrenttoleranceoptions)
                                , this.multiplierEIR))).toString();
                        }
                        if (elementonwhicheventsarry[i] == "time-tolerance-range") {
                            this.slidertimetolerancecalc = Math.round(100 * parseFloat(this.getCalculatedResult(this.getLegendFromValue($event.value
                                , this.slidertimetoleranceoptions), this.multiplierEIR))).toString();



                        }
                        if (elementonwhicheventsarry[i] == "time-tobreak-range") {
                            this.slidertimetobreakcalc = this.getCalculatedResult(this.getLegendFromValue(this.slidertimetobreak, this.slidertimetobreakoptions)
                                , this.multiplierEIR);
                        }

                        if (elementonwhicheventsarry[i] == "pickupcurrpre-range") {
                            // this.slidereir=event;
                            // this.multiplierEIR=event;
                            this.slidereiocalc = this.getCalculatedResult(this.getLegendFromValue(this.slidereio, this.slidereiooptions)
                                , this.multiplierEIR);
                        }
                        if (elementonwhicheventsarry[i] == "idmtl-settings") {
                            this.slideridmtlcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideridmtl, this.slideridmtloptions)
                                , this.multiplierEIR);


                        }
                        if (elementonwhicheventsarry[i] == "timedelay-range") {
                            this.slideretrcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideretr, this.slideretroptions)
                                , this.multiplierEIR);


                        }
                        if (elementonwhicheventsarry[i] == "pickupshort-range") {
                            this.slidereisdcalc = this.getCalculatedResult(this.getLegendFromValue(this.slidereisd, this.slidereisdoptions)
                                , this.multiplierEIR);

                        }
                        if (elementonwhicheventsarry[i] == "timedelayshort-range") {
                            this.slideretsdcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideretsd1, this.slideretsdoptions)
                                , this.multiplierEIR);

                        }
                        if (elementonwhicheventsarry[i] == "pickupcurrinst-range") {

                            this.sliderelicalc = this.getCalculatedResult(this.getLegendFromValue(this.slidereli, this.sliderelioptions)
                                , this.multiplierEIR);

                        }
                    }
                }

            }
            if (controlchangedId == "#timedelay-range") {
                this.slideretr = $event.value;
                this.slideretrcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideretr, this.slideretroptions), multiplier);


            }
            if (controlchangedId == "#idmtl-settings") {
                this.slideridmtl = $event.value;
                this.slideridmtlcalc = this.getCalculatedResult(this.getLegendFromValue(this.slideridmtl, this.slideridmtloptions)
                    , multiplier);


            }

            if (controlchangedId == "#pickupshort-range") {
                this.slidereisd = $event.value;
                this.slidereisdcalc = this.getCalculatedResult(this.getLegendFromValue(this.slidereisd, this.slidereisdoptions)
                    , multiplier);

            }
            if (controlchangedId == "#pickupcurrinst-range") {
                this.slidereli = $event.value;
                this.sliderelicalc = this.getCalculatedResult(this.getLegendFromValue(this.slidereli, this.sliderelioptions)
                    , multiplier);

            }

            var currenttolerance = this.getLegendFromValue(this.slidercurrenttolerance, this.slidercurrenttoleranceoptions);
            if ($("#current-tolerance-range").hasClass("hastype3")) {
                currenttolerance = (parseFloat(currenttolerance) / this.multiplierIN).toFixed(2).toString();
            }

            var timetolerance = this.getLegendFromValue(this.slidertimetolerance, this.slidertimetoleranceoptions);
            if ($("#time-tolerance-range").hasClass("hastype3")) {
                timetolerance = (parseFloat(timetolerance) / this.multiplierIN).toFixed(2).toString();
            }

            var timetobreak = this.getLegendFromValue(this.slidertimetobreak, this.slidertimetobreakoptions);

            if ($("#time-tobreak-range").hasClass("hastype3")) {
                timetobreak = (parseFloat(timetobreak) / this.multiplierIN).toFixed(2).toString();
            }
            var eratingplug = "";
            if ($('#ratingplug option:selected').val() != undefined)
                eratingplug = $('#ratingplug option:selected').val().toString();//$('#ratingplug').val().toString();
            var eltbool = "0";
            if ($("#chcklongtime").is(":checked") && $('#ratingplug option:selected').val() != "OFF") {
                eltbool = "1";
            }

            var estbool = "0";
            if ($("#chckshorttime").is(":checked")) {
                estbool = "1";
            }
            else {
                $("#chcki2curve").prop("checked", false);
            }
            var eintbool = "0";
            if ($("#chckInstant").is(":checked")) {
                eintbool = "1";
            }

            var eio = this.getLegendFromValue(this.slidereio, this.slidereiooptions)
                ;
            if ($("#pickupcurrpre-range").hasClass("hastype3")) {
                eio = (parseFloat(eio) / this.multiplierIN).toFixed(2).toString();
            }
            var eir = this.getLegendFromValue(this.slidereir, this.slidereiroptions)
                ;

            if ($("#pickupcurr-range").hasClass("hastype3")) {
                eir = (parseFloat(eir) / this.multiplierIN).toFixed(2).toString();
            }
            var etr = this.getLegendFromValue(this.slideretr, this.slideretroptions)
                ;
            if ($("#timedelay-range").hasClass("hastype3")) {
                etr = (parseFloat(etr) / this.multiplierIN).toFixed(2).toString();
            }
            var idmtl = this.getLegendFromValue(this.slideridmtl, this.slideridmtloptions)
                ;
            if ($("#idmtl-settings").hasClass("hastype3")) {
                idmtl = (parseFloat(idmtl) / this.multiplierIN).toFixed(2).toString();
            }
            var eisd = this.getLegendFromValue(this.slidereisd, this.slidereisdoptions)
                ;
            if ($("#pickupshort-range").hasClass("hastype3")) {
                eisd = (parseFloat(eisd) / this.multiplierIN).toFixed(2).toString();
            }
            var etsd = this.getLegendFromValue(this.slideretsd1, this.slideretsdoptions)
                ;
            if ($("#timedelayshort-range").hasClass("hastype3")) {
                etsd = (parseFloat(etsd) / this.multiplierIN).toFixed(2).toString();
            }

            var ei2tcurve = "0";
            if ($("#chcki2curve").is(":checked")) {
                ei2tcurve = "1";
            }
            if ($('#I2tdiv').css('display') == 'none') {
                ei2tcurve = "";
            }
            var eli = this.getLegendFromValue(this.slidereli, this.sliderelioptions);

            if ($("#pickupcurrinst-range").hasClass("hastype3")) {
                eli = (parseFloat(eli) / this.multiplierIN).toFixed(2).toString();
            }


            this.ProtectiveGetXml(manufacturerId, familyId, modelId, d.toString(), curve, '', '', '', currenttolerance, timetolerance, timetobreak
                , eratingplug, eltbool, estbool, eintbool
                , eio, eir
                , etr, eisd
                , etsd, ei2tcurve
                , eli, 0, controlchangedId, idmtl);
        }
    }
    getCalculatedResult(event: string, multiplier: number) {
        try {
            let isnum = /^\d+(\.\d{1,4})?$/.test(event);
            if (!isnum) {
                return event;

            }
            else {
                return this.RemoveTrailingZeros((Math.round((parseFloat(event) * multiplier) * 100) / 100).toFixed(2));

            }
        }
        catch (err) {
            return event;

        }

    }
    updatem(controlchangedId: string = "", $event: any) {
        if ($event.value.toString() != "NaN") {
            this.coordinationloader = true;
            var manufacturerId = parseInt(this.ListLoadStorage[this.index].manufacturer);
            var familyId = this.ListLoadStorage[this.index].family;

            var modelId = parseInt(this.ListLoadStorage[this.index].model.toString());
            var d = this.ListLoadStorage[this.index].rating.toString();
            var ee = this.ListLoadStorage[this.index].tripunit.toString();
            var curve = (ee.toString());

            var multiplier = 1;
            var multipliercolumn = $(controlchangedId).attr('multiplier');
            if (multipliercolumn != undefined) {
                if (multipliercolumn == "E_Io") {
                    multiplier = this.multiplierEIO;
                }
                if (multipliercolumn == "E_Ir") {
                    multiplier = this.multiplierEIR;

                }
                if (multipliercolumn == "In") {
                    multiplier = this.multiplierIN;

                }
            }


            if (controlchangedId == "#pickupcurrmgpr") {
                this.slidermim = $event.value;
                this.slidermimcalc = this.RemoveTrailingZeros((Math.round((parseFloat(this.getLegendFromValue(this.slidermim, this.slidermimoptions)) * multiplier) * 100) / 100).toFixed(2));
            }


            var mim = this.getLegendFromValue(this.slidermim, this.slidermimoptions)

            this.ProtectiveGetXml(manufacturerId, familyId, modelId, d.toString(), curve, '', '', mim, '', '', ''
                , '', '', '', ''
                , '', ''
                , '', ''
                , '', ''
                , '', 0, controlchangedId, '');
        }
    }
    updateTm(controlchangedId: string = "", $event: any) {

        if ($event.value.toString() != "NaN") {
            this.coordinationloader = true;
            var manufacturerId = parseInt(this.ListLoadStorage[this.index].manufacturer);
            var familyId = this.ListLoadStorage[this.index].family;

            var modelId = parseInt(this.ListLoadStorage[this.index].model.toString());
            var d = this.ListLoadStorage[this.index].rating.toString();
            var ee = this.ListLoadStorage[this.index].tripunit.toString();
            var curve = (ee.toString());

            var multiplier = 1;
            var multipliercolumn = $(controlchangedId).attr('multiplier');
            if (multipliercolumn != undefined) {
                if (multipliercolumn == "E_Io") {
                    multiplier = this.multiplierEIO;
                }
                if (multipliercolumn == "E_Ir") {
                    multiplier = this.multiplierEIR;

                }
                if (multipliercolumn == "In") {
                    multiplier = this.multiplierIN;

                }
            }


            if (controlchangedId == "#pickupcurrth") {
                this.slidertmir = $event.value;
                this.slidertmircalc = this.RemoveTrailingZeros((Math.round((parseFloat(this.getLegendFromValue(this.slidertmir, this.slidertmiroptions)) * multiplier) * 100) / 100).toFixed(2));
            }
            if (controlchangedId == "#pickupcurrmg") {
                this.slidertmim = $event.value;
                this.slidertmimcalc = this.RemoveTrailingZeros((Math.round((parseFloat(this.getLegendFromValue(this.slidertmim, this.slidertmimoptions)) * multiplier) * 100) / 100).toFixed(2));

            }
            if (this.deviceType == "2") {
                var tmir = this.getLegendFromValue(this.slidertmir, this.slidertmiroptions);

                var tmim = this.getLegendFromValue(this.slidertmim, this.slidertmimoptions);


            }
            this.ProtectiveGetXml(manufacturerId, familyId, modelId, d.toString(), curve, tmir, tmim, '', '', '', ''
                , '', '', '', ''
                , '', ''
                , '', ''
                , '', ''
                , '', 0, controlchangedId, '');
        }
    }
    getChartLatest(data: any, dimsize: number) {
        var str = ``;
        var colorarray = new Array();
        var colorDefinedArray = ['rgba(255,0,0,1.0)', 'rgba(0,0,255,1.0)', 'rgba(128,0,255,1.0)', 'rgba(255,127,0,1.0)', 'rgba(0,255,255,1.0)', 'rgba(255,0,255,1.0)', 'rgba(255,255,0,1.0)'];
        var fillDefinedArray = ['rgba(255,0,0,0.5)', 'rgba(0,0,255,0.5)', 'rgba(128,0,255,0.5)', 'rgba(255,127,0,0.5)', 'rgba(0,255,255,0.5)', 'rgba(255,0,255,0.5)', 'rgba(255,255,0,0.5)'];
        var strdata = ``;
        var j = 0;
        var annot = [

        ];

        var shapes = [

        ];
        this.arcCurveValue = '';

        if (this.activeNode && this.activeNode.objectcode == "MSB01" && this.hvRelayCurve && this.hvRelayCurve.length > 0 && this.isFromCoordination == true) {
            var t1x = [];
            var t1y = [];

            var t2x = [];
            var t2y = [];


            colorarray.push(colorDefinedArray[2].replace(',1.0)', ',0.8)'));
            colorarray.push(colorDefinedArray[2].replace(',1.0)', ',0.8)'));




            // for (let i = 0; i < length - 1; (i = i + 4)) {


            t1x = this.hvRelayCurve[0];
            t1y = this.hvRelayCurve[1];
            t2x = this.hvRelayCurve[2];
            t2y = this.hvRelayCurve[3];
            // }

            let deviceName = "Upstream (US): <br>HV Relay";

            str = str + ` ` + `var hvtrace` + 0 + `1 = {
            x: [`+ t1x + `],
            y: [`+ t1y + `],
            mode: 'lines',
            showlegend:false,
            autorange: true,
            name: '`+ this.deviceNoInString(0 + 1) + `'
          };
          
          var hvtrace`+ 0 + `2 = {
            x: [`+ t2x + `],
            y: [`+ t2y + `],
            mode: 'lines',
            fill:'tonexty' ,
            autorange: true,
            fillcolor: '`+ (this.currentProtectiveDeivceObj && this.currentProtectiveDeivceObj.HvRelay == true ? fillDefinedArray[2].replace(',0.5)', ',0.8)') : fillDefinedArray[2]) + `',
            name: '`+ deviceName + `',      
            showlegend:'`+ (this.isFromCoordination ? true : false) + `'
          };`




            if (strdata == ``) {
                strdata = `hvtrace` + 0 + `1, hvtrace` + 0 + `2`;
            }
            else {
                strdata = `hvtrace` + 0 + `1, hvtrace` + 0 + `2` + `,` + strdata;
            }
        }

        if (this.activeNode && this.activeNode.objectcode == "MSB01" && this.hvFuseCurve && this.hvFuseCurve.length > 0 && this.isFromCoordination == true) {
            var t1x = [];
            var t1y = [];

            var t2x = [];
            var t2y = [];

            if (this.index == j) {
                colorarray.push(colorDefinedArray[j].replace(',1.0)', ',0.8)'));
                colorarray.push(colorDefinedArray[j].replace(',1.0)', ',0.8)'));
            }
            else {
                colorarray.push(colorDefinedArray[j]);
                colorarray.push(colorDefinedArray[j]);
            }




            var length = (data[0].length);
            for (let i = 0; i < length - 1; (i = i + parseInt(dimsize[0].toString()))) {
                if (parseFloat(data[0][i].Val) > 0)
                    t1x.push([parseFloat((Number(data[0][i].Val) * Number(this.HVFuseCurveMultiplier)).toString()).toFixed(4)]);
                if (parseFloat(data[0][i + 1].Val) > 0)
                    t1y.push([parseFloat((Number(data[0][i + 1].Val)).toString())]);
                if (parseFloat(data[0][i + 2].Val) > 0)
                    t2x.push([parseFloat((Number(data[0][i + 2].Val) * Number(this.HVFuseCurveMultiplier)).toString()).toFixed(4)]);
                if (parseFloat(data[0][i + 3].Val) > 0)
                    t2y.push([parseFloat((Number(data[0][i + 3].Val)).toString())]);
            }

            // t1x = this.hvFuseCurve[0];
            // t1y = this.hvFuseCurve[1];
            // t2x = this.hvFuseCurve[2];
            // t2y = this.hvFuseCurve[3];

            let deviceName = "Upstream (US): <br>HV Fuse";

            str = str + ` ` + `var hvfusetrace` + 0 + `1 = {
            x: [`+ t1x + `],
            y: [`+ t1y + `],
            mode: 'lines',
            showlegend:false,
            autorange: true,
            name: '`+ this.deviceNoInString(0 + 1) + `'
          };
          
          var hvfusetrace`+ 0 + `2 = {
            x: [`+ t2x + `],
            y: [`+ t2y + `],
            mode: 'lines',
            fill:'tonexty' ,
            autorange: true,
            fillcolor: '`+ (this.index == j ? fillDefinedArray[j].replace(',0.5)', ',0.8)') : fillDefinedArray[j]) + `',
            name: '`+ deviceName + `',      
            showlegend:'`+ (this.isFromCoordination ? true : false) + `'
          };`




            if (strdata == ``) {
                strdata = `hvfusetrace` + 0 + `1, hvfusetrace` + 0 + `2`;
            }
            else {
                strdata = `hvfusetrace` + 0 + `1, hvfusetrace` + 0 + `2` + `,` + strdata;
            }
        }

        let isUnknownDevice: boolean = false;
        if (this.activeNode && this.activeNode.objectcode == "MSB01" && ((this.hvFuseCurve && this.hvFuseCurve.length > 0) || (this.hvRelayCurve && this.hvRelayCurve.length > 0)) && this.isFromCoordination == true) {
            if (this.currentProtectiveDeivceObj && this.currentProtectiveDeivceObj.CableSizeCalcInputXML && this.currentProtectiveDeivceObj.CableSizeCalcInputXML.protection && this.currentProtectiveDeivceObj.CableSizeCalcInputXML.protection.IsUnknown) {
                isUnknownDevice = true;
                $(".device-configuration").hide();
                let i = this.protectiveDrpList.findIndex(m => m.Node_Prefix == "MSB01");
                $('#drpDeviceToConfigure option').each(function (index) {
                    if (index == i) {
                        $(this).attr('disabled', "true");
                    }
                });
                $("#drpDeviceToConfigure").val(0).trigger('change');
            }
        }

        if (this.coordinationWarningErrorList && this.coordinationWarningErrorList.length > 0) {
            this.coordinationWarningErrorList = this.coordinationWarningErrorList.filter(m => m.ErrorCode != "5009");
            if (!this.coordinationWarningErrorList || this.coordinationWarningErrorList.length == 0)
                this.coordinationDisplayErrorBtn = false;
        }

        if (!isUnknownDevice) {
            for (j = 0; j < data.length; j++) {
                if (j == 0 && this.HVFuse == true && this.activeNode && this.activeNode.objectcode == "MSB01" && this.isFromCoordination == true) {
                    continue;
                }
                if (((this.hvFuseCurve && this.hvFuseCurve.length > 0) || (this.hvRelayCurve && this.hvRelayCurve.length > 0)) && this.activeNode && this.activeNode.objectcode == "MSB01" && this.isFromCoordination == true) {
                    let index = this.protectiveDrpList.findIndex(m => m.Node_Prefix == "MSB01");
                    if (this.hvRelayCurve && this.hvRelayCurve.length > 0) {
                        index -= 1;
                    }
                    let obj = this.protectiveDrpList.find(m => m.Node_Prefix == "MSB01");
                    if (obj && obj.CableSizeCalcInputXML && obj.CableSizeCalcInputXML.protection && obj.CableSizeCalcInputXML.protection.IsUnknown && j == index)
                        continue;
                }
                if (data[j] != undefined && data[j] != null && data[j] != "") {

                    var t1x = [];
                    var t1y = [];

                    var t2x = [];
                    var t2y = [];
                    var t3x = [];
                    var t3y = [];
                    if (this.index == j) {
                        colorarray.push(colorDefinedArray[j].replace(',1.0)', ',0.8)'));
                        colorarray.push(colorDefinedArray[j].replace(',1.0)', ',0.8)'));
                        if (dimsize[j] > 4) {
                            colorarray.push(colorDefinedArray[j].replace(',1.0)', ',0.8)'));
                        }
                    }
                    else {
                        colorarray.push(colorDefinedArray[j]);
                        colorarray.push(colorDefinedArray[j]);
                        if (dimsize[j] > 4) {
                            colorarray.push(colorDefinedArray[j]);
                        }
                    }


                    var length = (data[j].length);

                    for (let i = 0; i < length - 1; (i = i + parseInt(dimsize[j].toString()))) {


                        if (parseFloat(data[j][i].Val) > 0)
                            t1x.push([parseFloat(data[j][i].Val).toFixed(4)]);
                        if (parseFloat(data[j][i + 1].Val) > 0)
                            t1y.push([parseFloat(data[j][i + 1].Val).toFixed(4)]);
                        if (parseFloat(data[j][i + 2].Val) > 0)
                            t2x.push([parseFloat(data[j][i + 2].Val).toFixed(4)]);
                        if (parseFloat(data[j][i + 3].Val) > 0)
                            t2y.push([parseFloat(data[j][i + 3].Val).toFixed(4)]);
                        if (dimsize[j] > 4) {

                            if (parseFloat(data[j][i + 4].Val) > 0)
                                t3x.push([parseFloat(data[j][i + 4].Val).toFixed(4)]);
                            if (parseFloat(data[j][i + 5].Val) > 0)
                                t3y.push([parseFloat(data[j][i + 5].Val).toFixed(4)]);
                        }


                    }
                    let deviceName;
                    let ind = j;
                    if (this.activeNode && this.activeNode.objectcode == "MSB01" && ((this.hvRelayCurve && this.hvRelayCurve.length > 0)) && this.isFromCoordination == true) {
                        if (this.hvRelayCurve && this.hvRelayCurve.length > 0) {
                            ind += 1;
                        }
                        // if (this.hvFuseCurve && this.hvFuseCurve.length > 0) {
                        //     ind += 1;
                        // }
                        deviceName = this.protectiveDrpList[ind].CurveText + " (" + this.deviceNoInString(j + 1) + ")";
                    }
                    else {
                        deviceName = this.isFromCoordination == true ? this.protectiveDrpList[j].CurveText + " (" + this.deviceNoInString(j + 1) + ")" : this.deviceNoInString(j + 1)
                    }
                    if (this.index == j) {
                        str = str + ` ` + `var trace` + j + `1 = {
        x: [`+ t1x + `],
        y: [`+ t1y + `],
        mode: 'lines',
        showlegend:false,
        autorange: true,
        name: '`+ this.deviceNoInString(j + 1) + `'
      };
      
      var trace`+ j + `2 = {
        x: [`+ t2x + `],
        y: [`+ t2y + `],
        mode: 'lines',
        fill:'tonexty' ,
        autorange: true,
        fillcolor: '`+ fillDefinedArray[j].replace(',0.5)', ',0.8)') + `',
        name: '`+ deviceName + `',      
        showlegend:'`+ (this.isFromCoordination ? true : false) + `'
      };`


                    }
                    else {
                        str = str + ` ` + `var trace` + j + `1 = {
            x: [`+ t1x + `],
            y: [`+ t1y + `],
            mode: 'lines',
            showlegend:false,
            autorange: true,
            name: '`+ this.deviceNoInString(j + 1) + `'
          };
          
          var trace`+ j + `2 = {
            x: [`+ t2x + `],
            y: [`+ t2y + `],
            mode: 'lines',
            fill:'tonexty',
            autorange: true,
            fillcolor: '`+ fillDefinedArray[j] + `',
            name: '`+ deviceName + `',      
            showlegend:'`+ (this.isFromCoordination ? true : false) + `'
          };`


                    }

                    if (dimsize[j] > 4) {
                        str = str + ` ` + ` var trace` + j + `3 = {
        x: [`+ t3x + `],
        y: [`+ t3y + `],
        mode: 'lines' ,
        showlegend:false,
        autorange: true,
        name: '`+ this.deviceNoInString(j + 1) + `'
      };`

                    }

                    if (strdata == ``) {
                        if (dimsize[j] > 4) {
                            strdata = `trace` + j + `1, trace` + j + `2, trace` + j + `3`;
                        }
                        else {
                            strdata = `trace` + j + `1, trace` + j + `2`;
                        }
                    }
                    else {

                        if (dimsize[j] > 4) {
                            strdata = `trace` + j + `1, trace` + j + `2, trace` + j + `3` + `,` + strdata;
                        }
                        else {
                            strdata = `trace` + j + `1, trace` + j + `2` + `,` + strdata;
                        }


                    }

                    if (this.isFromCoordination && (j != 0 || (this.activeNode && this.activeNode.objectcode == "MSB01" && ((this.hvRelayCurve && this.hvRelayCurve.length > 0)) && this.isFromCoordination == true))) {
                        let currentObj;
                        let ind = j;
                        if (this.activeNode && this.activeNode.objectcode == "MSB01" && this.isFromCoordination == true) {
                            if (this.hvRelayCurve && this.hvRelayCurve.length > 0) {
                                ind += 1;
                            }
                            // if (this.hvFuseCurve && this.hvFuseCurve.length > 0) {
                            //     ind += 1;
                            // }
                        }
                        currentObj = this.protectiveDrpList[ind];
                        if (this.ArcingCurrents) {
                            let current = null;
                            if (currentObj.Node_Prefix.startsWith("CB", 0) || currentObj.Node_Prefix.startsWith("L", 0)) {
                                current = this.getArcCurrentForCbOrLoad(this.Cablesizecalculationsresult, currentObj);
                            }
                            else {
                                current = this.GetArcandFaultCurrent(this.factorResults, currentObj.Node_Prefix);
                            }
                            if (current) {
                                shapes.push(
                                    {
                                        type: 'line',
                                        yref: 'paper',
                                        x0: parseFloat(current.ac),
                                        y0: 0,
                                        x1: parseFloat(current.ac),
                                        y1: 1,
                                        line: {
                                            dash: 'dash',
                                            color: colorDefinedArray[j]
                                        }
                                    });
                                let labelText = '';
                                if (this.protectiveDrpList.length == 2) {
                                    labelText = "IA-DS";
                                }
                                else {
                                    if (j == 1) {
                                        labelText = "IA-US";
                                    }
                                    else {
                                        labelText = "IA-DS";
                                    }
                                }
                                let val = j == 1 ? t1y[t1y.length - 1][0] : this.getNextValueForCurve(t1y, t1y.length - 1);
                                this.arcCurveValue = val;
                                annot.push({
                                    "x": Math.log10(parseFloat(current.ac)),
                                    "y": Math.log10(parseFloat(val)),
                                    "xref": "x",
                                    "yref": "y",
                                    "text": labelText,
                                    showarrow: true,
                                    font: {
                                        family: 'Courier New, monospace',
                                        size: 12,
                                        color: fillDefinedArray[j].replace(',0.5)', ',0.8)')
                                    },
                                    align: 'center',
                                    arrowhead: 2,
                                    arrowsize: 1,
                                    arrowwidth: 2,
                                    arrowcolor: fillDefinedArray[j].replace(',0.5)', ',0.8)'),
                                    ax: 50,
                                    ay: 0,
                                    bordercolor: fillDefinedArray[j].replace(',0.5)', ',0.8)'),
                                    borderwidth: 2,
                                    borderpad: 4,
                                    bgcolor: '#f3f5f8',
                                    opacity: 0.8
                                })
                            }
                        }

                        if (this.FaultCurrents) {
                            let current = null;
                            if (currentObj.Node_Prefix.startsWith("CB", 0) || currentObj.Node_Prefix.startsWith("L", 0)) {
                                current = this.getArcCurrentForCbOrLoad(this.Cablesizecalculationsresult, currentObj);
                            }
                            else {
                                current = this.GetArcandFaultCurrent(this.factorResults, currentObj.Node_Prefix);
                            }
                            if (current) {
                                shapes.push(
                                    {
                                        type: 'line',
                                        yref: 'paper',
                                        x0: parseFloat(current.fc),
                                        y0: 0,
                                        x1: parseFloat(current.fc),
                                        y1: 1,
                                        line: {
                                            dash: 'dash',
                                            color: colorDefinedArray[j]
                                        }
                                    });
                                let labelText = '';
                                if (this.protectiveDrpList.length == 2) {
                                    labelText = "IF-DS";
                                }
                                else {
                                    if (j == 1) {
                                        labelText = "IF-US";
                                    }
                                    else {
                                        labelText = "IF-DS";
                                    }
                                }
                                let val = this.getNextValueForCurve(t1y, t1y.length - 1);
                                this.arcCurveValue = val;
                                annot.push({
                                    "x": Math.log10(parseFloat(current.fc)),
                                    "y": Math.log10(parseFloat(val)),
                                    "xref": "x",
                                    "yref": "y",
                                    "text": labelText,
                                    showarrow: true,
                                    font: {
                                        family: 'Courier New, monospace',
                                        size: 12,
                                        color: fillDefinedArray[j].replace(',0.5)', ',0.8)')
                                    },
                                    align: 'center',
                                    arrowhead: 2,
                                    arrowsize: 1,
                                    arrowwidth: 2,
                                    arrowcolor: fillDefinedArray[j].replace(',0.5)', ',0.8)'),
                                    ax: 50,
                                    ay: 0,
                                    bordercolor: fillDefinedArray[j].replace(',0.5)', ',0.8)'),
                                    borderwidth: 2,
                                    borderpad: 4,
                                    bgcolor: '#f3f5f8',
                                    opacity: 0.8
                                })
                            }


                        }
                    }


                }
            }

            if (this.isFromCoordination && this.CableDamage) {
                let index = parseInt(this.currentProtectiveDeivce);
                if (((this.hvRelayCurve && this.hvRelayCurve.length > 0)) && this.activeNode && this.activeNode.objectcode == "MSB01") {
                    index = this.protectiveDrpList.findIndex(m => m.Node_Prefix == "MSB01");
                    if (this.hvRelayCurve && this.hvRelayCurve.length > 0) {
                        index -= 1;
                    }
                }
                let currentObj = this.protectiveDrpList[this.currentProtectiveDeivce];
                if (currentObj && !currentObj.HvFuse) {
                    let current = null;
                    current = this.getDamageCurve(this.Cablesizecalculationsresult, currentObj);
                    if (current) {
                        str = str + ` ` + ` var cableDamage = {
                        x: [`+ current.cr + `],
                        y: [`+ current.ts + `],
                        mode: 'lines',
                        name: 'Cable damage',
                        autorange: true,
                        showlegend:false,
                        line: {                        
                            color:'`+ colorDefinedArray[index] + `'
                        }                
                      };`

                        strdata = strdata + ` , cableDamage`;

                        annot.push({
                            "x": Math.log10(parseFloat(current.cr[Math.floor(current.cr.length * 15 / 100)])),
                            "y": Math.log10(parseFloat(current.ts[Math.floor(current.ts.length * 15 / 100)])),
                            "xref": "x",
                            "yref": "y",
                            "text": current.annot,
                            showarrow: true,
                            font: {
                                family: 'Courier New, monospace',
                                size: 12,
                                color: colorDefinedArray[index].replace(',0.5)', ',0.8)')
                            },
                            align: 'center',
                            arrowhead: 2,
                            arrowsize: 1,
                            arrowwidth: 2,
                            arrowcolor: colorDefinedArray[index].replace(',0.5)', ',0.8)'),
                            ax: 100,
                            ay: -50,
                            bordercolor: colorDefinedArray[index].replace(',0.5)', ',0.8)'),
                            borderwidth: 2,
                            borderpad: 4,
                            bgcolor: '#f3f5f8',
                            opacity: 0.8
                        })

                    }
                }
            }

            if (this.isFromCoordination && this.TransformerDamage && (this.networksupplytype == '2' || this.networksupplytype == '4')) {
                let currentObj = this.protectiveDrpList[this.currentProtectiveDeivce];
                if (currentObj && !currentObj.HvFuse) {
                    if (this.warningErrorListPristine && this.warningErrorListPristine.length > 0) {
                        let warnings: ErrorWarningModel[] = this.warningErrorListPristine.filter(m => m.ErrorCode == "5009");
                        if (warnings && warnings.length > 0) {
                            this.coordinationWarningErrorList.push(...warnings);
                            this.coordinationDisplayErrorBtn = true;
                            if (this.coordinationWarningErrorList.length > 1) {
                                this.coordinationWarningErrorBtnText += ' and Warnings';
                            }
                            else {
                                this.coordinationWarningErrorBtnText = 'Warnings';
                            }
                        }
                    }
                    let current = null;
                    current = this.getTransformerDamageCurve(this.Cablesizecalculationsresult, currentObj);
                    if (current) {
                        str = str + ` ` + ` var transformerDamage = {
                        x: [`+ current.cr + `],
                        y: [`+ current.ts + `],
                        mode: 'lines',
                        name: 'Cable damage',
                        autorange: true,
                        showlegend:false,
                        line: {                        
                            color:'rgb(0,0,0)'
                        }                
                      };`

                        strdata = strdata + ` , transformerDamage`;

                        annot.push({
                            "x": Math.log10(parseFloat(current.cr[Math.floor(current.cr.length * 30 / 100)])),
                            "y": Math.log10(parseFloat(current.ts[Math.floor(current.ts.length * 30 / 100)])),
                            "xref": "x",
                            "yref": "y",
                            "text": current.annot,
                            showarrow: true,
                            font: {
                                family: 'Courier New, monospace',
                                size: 12,
                                color: 'rgb(0,0,0)'
                            },
                            align: 'center',
                            arrowhead: 2,
                            arrowsize: 1,
                            arrowwidth: 2,
                            arrowcolor: 'rgb(0,0,0)',
                            ax: 100,
                            ay: -50,
                            bordercolor: 'rgb(0,0,0)',
                            borderwidth: 2,
                            borderpad: 4,
                            bgcolor: '#f3f5f8',
                            opacity: 0.8
                        })

                    }
                }
            }
            else {

                this.CalculationReportInput.damagetime = null;
                this.CalculationReportInput.damagecurrent = null;

            }
        }


        // if (annot.length > 0)
        //     this.protectionCoordinationAnnotations = annot;
        var shapesvalues = ``;
        var no = 0;

        var seqx = [];
        var seqy = [];
        var seqx2 = [];
        var seqy2 = [];
        if (!this.isFromCoordination) {
            if (this.trippingCurrentAtSpecifiedTime != "Not Applicable" && !this.RCD) {
                shapesvalues = shapesvalues + ` ` + "x";
                let trippingtime;
                if (this.protectionConfigurationModel.MaxDisconnectionTime == "Custom")
                    trippingtime = this.protectionConfigurationModel.CustomTime;
                else
                    trippingtime = this.protectionConfigurationModel.MaxDisconnectionTime;

                str = str + ` ` + ` var tracesequence2 = {
                x: [`+ this.trippingCurrentAtSpecifiedTime + `],
                y: [`+ trippingtime + `],
                mode: 'markers',
                name:'',                
                autorange: true,
                showlegend:false,
                line: {                                  
                    color:'rgb(0, 0, 255)'                    
                } 
              };`

                annot.push({
                    x: Math.log10(parseFloat(this.trippingCurrentAtSpecifiedTime)),
                    y: Math.log10(parseFloat(trippingtime)),
                    xref: 'x',
                    yref: 'y',
                    text: parseFloat(parseFloat(this.trippingCurrentAtSpecifiedTime).toFixed(2).replace(/\.0+$/, '')) + ' A @ ' + trippingtime + ' s',
                    showarrow: false,
                    font: {
                        family: 'Source Sans Pro',
                        size: 14,
                        color: 'rgb(0, 0, 255)'
                    },
                    align: 'right',
                    bgcolor: '#ffffff',
                    opacity: 0.8,
                    xanchor: 'left',
                    xshift: 10
                });

                seqx.push([this.trippingCurrentAtSpecifiedTime]);
                seqx.push([this.trippingCurrentAtSpecifiedTime]);
                seqy.push([trippingtime]);
                seqy.push([0]);
                str = str + ` ` + ` var tracesequence = {
                x: [`+ seqx + `],
                y: [`+ seqy + `],
                mode: 'lines',
                name: 'Lines',
                autorange: true,    
                showlegend:false,
                line: {
                    dash: 'dash',                    
                    color:'rgb(0, 0, 255)'                    
                }                
              };`

                seqx2.push([0]);
                seqx2.push([this.trippingCurrentAtSpecifiedTime]);
                seqy2.push([trippingtime]);
                seqy2.push([trippingtime]);
                str = str + ` ` + ` var tracesequence1 = {
                x: [`+ seqx2 + `],
                y: [`+ seqy2 + `],
                mode: 'lines',
                name: 'Lines',
                autorange: true,
                showlegend:false,
                line: {
                    dash: 'dash',                
                    color:'rgb(0, 0, 255)'                    
                }                
              };`

                strdata = strdata + ` , tracesequence` + `,` + `tracesequence1 ` + `,` + `tracesequence2 `;
                // shapes = ` `;
            }
        }

        if (this.isFromCoordination && this.discriminatedData && this.activeNode && this.activeNode.objectcode != "MSB01") {
            if (this.discriminatedData.discriminatedfirst == "0") {// Check for discriminated

                let isFirstGreater = parseFloat(this.discriminatedData.discriminatedfirstfour) >= parseFloat(this.discriminatedData.discriminatedfirstthree);
                let firstText = '';
                let secondText = '';
                if (isFirstGreater)
                    firstText = parseFloat(parseFloat(this.discriminatedData.discriminatedfirstthree).toFixed(2).replace(/\.0+$/, '')) + " A (Req: ≥ " + parseFloat(parseFloat(this.discriminatedData.discriminatedfirstone).toFixed(2).replace(/\.0+$/, '')) + " A)";
                else
                    secondText = parseFloat(parseFloat(this.discriminatedData.discriminatedfirstthree).toFixed(2).replace(/\.0+$/, '')) + " A (Req: ≥ " + parseFloat(parseFloat(this.discriminatedData.discriminatedfirstone).toFixed(2).replace(/\.0+$/, '')) + " A)";


                str = str + ` ` + ` var tracesequence2 = {
                x: [`+ parseFloat(this.discriminatedData.discriminatedfirstfour) + `],
                y: [`+ parseFloat(this.discriminatedData.discriminatedfirsttwo) + `],
                mode: 'markers',
                name:'',
                autorange: true,
                showlegend:false,
                line: {                                  
                    color:'rgb(0, 0, 0)'                    
                } 
              };`

                str = str + ` ` + ` var tracesequence22 = {
                x: [`+ parseFloat(this.discriminatedData.discriminatedfirstthree) + `],
                y: [`+ parseFloat(this.discriminatedData.discriminatedfirsttwo) + `],
                mode: 'markers',
                name:'',                 
                autorange: true,
                showlegend:false,
                line: {                                  
                    color:'rgb(0, 0, 0)'                    
                } 
              };`

                annot.push({
                    x: Math.log10(parseFloat(this.discriminatedData.discriminatedfirstthree)),
                    y: Math.log10(isFirstGreater ? parseFloat(this.discriminatedData.discriminatedfirsttwo) : parseFloat(this.discriminatedData.discriminatedfirsttwo)),
                    xref: 'x',
                    yref: 'y',
                    text: isFirstGreater ? firstText : secondText,
                    showarrow: false,
                    font: {
                        family: 'Source Sans Pro',
                        size: 14,
                        color: '#000000'
                    },
                    align: 'right',
                    bgcolor: '#ffffff',
                    opacity: 0.8,
                    xanchor: 'left',
                    xshift: 10
                });

                var seq1x = [];
                var seq1y = [];
                seq1x.push([parseFloat(this.discriminatedData.discriminatedfirstthree)]);
                seq1x.push([parseFloat(this.discriminatedData.discriminatedfirstthree)]);
                seq1y.push([parseFloat(this.discriminatedData.discriminatedfirsttwo)]);
                seq1y.push([0]);
                str = str + ` ` + ` var tracesequence = {
                    x: [`+ seq1x + `],
                    y: [`+ seq1y + `],
                    mode: 'lines',
                    name: 'Lines',
                    autorange: true,    
                    showlegend:false,
                    line: {
                        dash: 'dash',                    
                        color:'rgb(0, 0, 0)'                    
                    }                
                  };`

                var seq1x2 = [];
                var seq1y2 = [];
                seq1x2.push([0]);
                if (isFirstGreater)
                    seq1x2.push([parseFloat(this.discriminatedData.discriminatedfirstfour)]);
                else
                    seq1x2.push([parseFloat(this.discriminatedData.discriminatedfirstthree)]);

                seq1y2.push([parseFloat(this.discriminatedData.discriminatedfirsttwo)]);
                seq1y2.push([parseFloat(this.discriminatedData.discriminatedfirsttwo)]);
                str = str + ` ` + ` var tracesequence1 = {
                    x: [`+ seq1x2 + `],
                    y: [`+ seq1y2 + `],
                    mode: 'lines',
                    name: 'Lines',
                    autorange: true,
                    showlegend:false,
                    line: {
                        dash: 'dash',                
                        color:'rgb(0, 0, 0)'                    
                    }                
                  };`

                strdata = strdata + ` , tracesequence` + `,` + `tracesequence1 ` + `,` + `tracesequence22 ` + `,` + `tracesequence2 `;

            }

            if (this.discriminatedData.discriminatedsecond == "0") {// Check for discriminated
                let isFirstGreater = parseFloat(this.discriminatedData.discriminatedsecondfour) >= parseFloat(this.discriminatedData.discriminatedsecondthree);
                let firstText = '';
                let secondText = '';
                if (isFirstGreater)
                    firstText = parseFloat(parseFloat(this.discriminatedData.discriminatedsecondthree).toFixed(2).replace(/\.0+$/, '')) + " A (Req: ≥ " + parseFloat(parseFloat(this.discriminatedData.discriminatedsecondone).toFixed(2).replace(/\.0+$/, '')) + " A)";
                else
                    secondText = parseFloat(parseFloat(this.discriminatedData.discriminatedsecondthree).toFixed(2).replace(/\.0+$/, '')) + " A (Req: ≥ " + parseFloat(parseFloat(this.discriminatedData.discriminatedsecondone).toFixed(2).replace(/\.0+$/, '')) + " A)";
                str = str + ` ` + ` var tracesequence3 = {
                x: [`+ parseFloat(this.discriminatedData.discriminatedsecondfour) + `],
                y: [`+ parseFloat(this.discriminatedData.discriminatedsecondtwo) + `],
                mode: 'markers',
                name:'',
                autorange: true,
                showlegend:false,
                line: {                                  
                    color:'rgb(0, 0, 0)'                    
                } 
              };`

                str = str + ` ` + ` var tracesequence33 = {
                x: [`+ parseFloat(this.discriminatedData.discriminatedsecondthree) + `],
                y: [`+ parseFloat(this.discriminatedData.discriminatedsecondtwo) + `],
                mode: 'markers',
                name:'',                
                autorange: true,
                showlegend:false,
                line: {                                  
                    color:'rgb(0, 0, 0)'                    
                } 
              };`

                annot.push({
                    x: Math.log10(parseFloat(this.discriminatedData.discriminatedsecondthree)),
                    y: Math.log10(isFirstGreater ? parseFloat(this.discriminatedData.discriminatedsecondtwo) : parseFloat(this.discriminatedData.discriminatedsecondtwo)),
                    xref: 'x',
                    yref: 'y',
                    text: isFirstGreater ? firstText : secondText,
                    showarrow: false,
                    font: {
                        family: 'Source Sans Pro',
                        size: 14,
                        color: '#000000'
                    },
                    align: 'right',
                    bgcolor: '#ffffff',
                    opacity: 0.8,
                    xanchor: 'left',
                    xshift: 10
                });

                var seq1x = [];
                var seq1y = [];
                seq1x.push([parseFloat(this.discriminatedData.discriminatedsecondthree)]);
                seq1x.push([parseFloat(this.discriminatedData.discriminatedsecondtwo)]);
                seq1y.push([parseFloat(this.discriminatedData.discriminatedsecondtwo)]);
                seq1y.push([0]);
                str = str + ` ` + ` var tracesequence4 = {
                    x: [`+ seq1x + `],
                    y: [`+ seq1y + `],
                    mode: 'lines',
                    name: 'Lines',
                    autorange: true,    
                    showlegend:false,
                    line: {
                        dash: 'dash',                    
                        color:'rgb(0, 0, 0)'                    
                    }                
                  };`

                var seq1x2 = [];
                var seq1y2 = [];
                seq1x2.push([0]);
                if (isFirstGreater)
                    seq1x2.push([parseFloat(this.discriminatedData.discriminatedsecondfour)]);
                else
                    seq1x2.push([parseFloat(this.discriminatedData.discriminatedsecondthree)]);

                seq1y2.push([parseFloat(this.discriminatedData.discriminatedsecondtwo)]);
                seq1y2.push([parseFloat(this.discriminatedData.discriminatedsecondtwo)]);
                str = str + ` ` + ` var tracesequence5 = {
                    x: [`+ seq1x2 + `],
                    y: [`+ seq1y2 + `],
                    mode: 'lines',
                    name: 'Lines',
                    autorange: true,
                    showlegend:false,
                    line: {
                        dash: 'dash',                
                        color:'rgb(0, 0, 0)'                    
                    }                
                  };`

                strdata = strdata + ` , tracesequence3` + `,` + `tracesequence4 ` + `,` + `tracesequence33 ` + `,` + `tracesequence5 `;

            }
        }

        // var shapes = '';

        var ii = 1;

        var strdatastart = `var data = [`;
        var strdataend = `];`;

        var strcolortart = `[`;
        var strcolorend = `]`;
        var colorarry = ``;

        colorarray.forEach(element => {

            if (element != null && element != "") {
                if (colorarry == ``)
                    colorarry = "'" + element + "'";
                else
                    colorarry = "'" + element + "'" + `,` + colorarry;

            }
        });

        strdata = strdatastart + strdata + strdataend;
        if (shapesvalues != '') {
            colorarry = strcolortart + colorarry + " , 'rgba(0, 0, 0, 0.5)' , 'rgba(0, 0, 0, 1.0) '" + strcolorend;
        }
        else {
            colorarry = strcolortart + colorarry + strcolorend;
        }
        // width: 500,
        //             height: 500,

        // colorway : ['#FF3333', '#FFFC33', '#6BFF33', '#33FFE3', '#339CFF', '#B833FF', '#FF337A']
        let script = this._renderer2.createElement('script');
        script.type = `text/javascript`;
        script.text = `
            {
                `+ str + strdata + `
                 
               
                  
                  var layout = { 
                    // autosize: true,  
                    // height:650,  
                    showlegend: `+ this.isFromCoordination + `,
                    legend: {
                      x: 1,
                      xanchor: 'right',
                      y: 1,
                      font: {
                        family: 'Source Sans Pro',
                        size: 14
                      },
                      title: {
                        font: {
                          family: 'Source Sans Pro',
                          size: 14
                        }
                      },
                      item: {
                        font: {
                          family: 'Source Sans Pro',
                          size: 14
                        }
                      }
                    },
                    annotations: `+ JSON.stringify(annot) + `, 
                    shapes: `+ JSON.stringify(shapes) + `,                     
                    colorway : `+ colorarry + `,               
                    margin: {
                        l: 40,
                        r: 40,
                        b: 40,
                        t: 30,
                        pad: 4
                      },
                    xaxis: {                        
                        title: {
                            text: 'Current (A)',
                            font: {
                              family: 'Source Sans Pro',
                              size: 14,
                              color: '#7f7f7f'
                            }
                          },
                          autosize: false,
                      type: 'log',
                      autorange: true,
                      showspikes: true,
                      spikemode: 'toaxis'
                    },
                    yaxis: {                        
                        title: {
                            text: 'Time (s)',
                            font: {
                              family: 'Source Sans Pro',
                              size: 14,
                              color: '#7f7f7f'
                            }
                          },
                      type: 'log',
                      autosize: true,                      
                      autorange: true,
                      showspikes: true,
                      spikemode: 'toaxis'
                    },

                   
  hovermode: 'closest',
  dragmode: 'pan'
                  };
                 
                  var config = {
                    scrollZoom: true,
                    responsive: true,
                    displaylogo: false,
                    displayModeBar: true,
                    autoscaleYAxis: true ,
                    editable: false,
                    modeBarButtonsToRemove: ['select2d','lasso2d','hoverCompareCartesian']}
                  Plotly.newPlot('myDiv' , data, layout, config).then(
                    function(gd)
                     {
                        setTimeout(function() {
                      Plotly.toImage(gd,{height:580})
                         .then(
                             function(url)
                         {
                            $(".clsimg").val(url.split(',')[1]);
                           // this.plotData=url.split(',')[1];
                          
                            
                          //  img_jpg.attr("src", url);
                         }
                         )
                    });
                }, 1000); // 1 second delay
                    }
        `;

        // if (this.isFromCoordination) {
        // $("#myDiv").on('plotly_relayout', (e: any) => {
        //     if (e && e.target && e.target.layout && e.target.layout.annotations) {
        //         if (e.target.layout.annotations.length > 0) {
        //             for (let index = 0; index < e.target.layout.annotations.length; index++) {
        //                 const annotObj = e.target.layout.annotations[index];
        //                 if (this.protectionCoordinationAnnotations && this.protectionCoordinationAnnotations.length >= index) {
        //                     let isChanged = this.protectionCoordinationAnnotations[index].ax != annotObj.ax || this.protectionCoordinationAnnotations[index].ay != annotObj.ay;
        //                     this.protectionCoordinationAnnotations[index].x = annotObj.x;
        //                     this.protectionCoordinationAnnotations[index].y = annotObj.y;
        //                     this.protectionCoordinationAnnotations[index].ax = annotObj.ax;
        //                     this.protectionCoordinationAnnotations[index].ay = annotObj.ay;
        //                     this.protectionCoordinationAnnotations[index].isChanged = isChanged;
        //                 }
        //             }
        //         }

        //     }
        // });
        // }

        if (this.isFromCoordination) {
            $("#myDiv").on('plotly_legendclick', () => false)
        }
        this._renderer2.appendChild(this._document.body, script);
        // var sub = Observable.interval(2000)
        //     .subscribe((val) => {

        //         this.saveOtherDetail()
        //     });

        // setTimeout(function () {


        //     sub.unsubscribe();
        // }, 2000);
        //sub.unsubscribe();

    }
    runCurveEvent(rownumber) {
        // if (this.listCoordinationCurve.length > 0 && $(".tblcurveelements").find('tr') != null && $(".tblcurveelements").find('tr') != undefined && $(".tblcurveelements").find('tr').length > 1 && this.listCoordinationCurve.find(x => x.rownumber == rownumber) != undefined) {
        //     this.listCoordinationCurve.forEach(element => {
        //         if (element.rownumber == rownumber) {
        //             this.changeCriteriaCurrent(element.criteriarownumber);
        //         }
        //     });
        // }
        // else {
        var data = this.chartData;
        var dimsize = this.dimSizeData;
        this.getChartLatest(data, dimsize);
        // this.saveOtherDetail();
        // }
    }

    showChangeProtectiveDeviceSourcePopup() {
        $('#change-protection-device-source').modal('show');
    }

    cancelChangeProtectiveDeviceSource() {
        this.cableCalcProtectiveDeviceSource = this.oldCableCalcProtectiveDeviceSource;
        // $('#CableCalcProtectiveDeviceSource').val(this.cableCalcProtectiveDeviceSource).trigger('change', "true");
    }
    ExportLimitedChange(e) {

        if (this.chkexportlimited == true) {
            this.showFilter = true;
        }
        else {
            this.showFilter = false;

        }
        var a = this.defaultManId;
        var manufacturerId = parseInt(a.toString());

        var familyId = this.model.toString();
        var modelId = this.defaultModelId;
        this.GetBreakingByManufacturerId(manufacturerId, familyId, modelId, this.defaultRatingId.toString(), this.defaultCurveId);


    }

    changeProtectiveDeviceSource() {
        let supplyType = this.networksupplytype;// $('#networksupplytype').val();
        this.PrevActiveValue = undefined;
        this.PrevNeutralValue = undefined;
        let nodeCount = 0;
        this.objectId = 3;
        this.SupplyVoltage = "0";
        this.Frequency = "0";
        this.VoltageTolerance = "0";
        this.TransformerTypicalDataSource = "0";
        this.networksupplytype = "0";
        this.Transformer_Calculation_Method = "0";
        this.resetStatus = true;
        this.Autosizegenerator = true;
        this.Customtransformer = false;
        this.Autosizetransformer = true;
        this.Impedencecorrection = false;
        this.Customgenerator = false;
        this.GeneratorImpedencecorrection = false;
        this.PowerStationCustomgenerator = false;
        this.PowerStationCustomtransformer = false;
        this.PowerStationImpedencecorrection = false;
        this.Power_Station_Calc_Transformer_XandR = "0";
        this.selectResults = "8";

        this.isMaxDemandDataShown = false;
        this.isXmlStatus = true;
        document.getElementById('defaultCableOpen2').click();
        this.LoadStorage.installationId = 5;
        sessionStorage.setItem('SupplyType', "Supply");
        sessionStorage.setItem('powerfactorlist', nodeCount.toString());
        sessionStorage.setItem('loadlist', nodeCount.toString());
        sessionStorage.setItem('switchlist', nodeCount.toString());
        sessionStorage.setItem('cablesizelist', nodeCount.toString());
        sessionStorage.setItem('capacitorlist', nodeCount.toString());
        sessionStorage.setItem('hvrelaylist', nodeCount.toString());
        this.tempcablesizelist = [];
        this.templist = [];
        this.tempswitchlist = [];
        this.tempcapacitorlist = [];
        this.maxDemandDataArray = [];
        this.temppowerfactorcorrections = [];
        this.tempinputXML.Switchboards = [];
        this.tempinputXML.Loads = [];
        this.tempinputXML.CapacitorBanks = [];
        this.tempinputXML.CableSizeCalcInputs = "";
        // this.tempinputXML.NetworkHVRelay = null;
        // this.tempinputXML.NetworkSettings = null;
        // this.tempinputXML.SupplySettings = null;
        // this.tempinputXML.PowerFactorCorrections = null;
        this.tempinputXML.matrix = null;
        this.tempinputXML.isSingleLineDiagram = 0;
        this.matrix = undefined;
        this.tempinputXML.MaxDemandDataArray = [];
        this.warningErrorStrData = '';
        this.warningErrorBtnText = "Warnings";
        this.warningErrorHeader = "Errors";
        this.warningErrorList = [];
        this.displayErrorBtn = false;


        // this.transformerHVRelay = false;
        // this.powerHVRelay = false;
        if (this.activeNode && this.activeNode.objectcode) {
            this.deleteWizardFactorValues(this.activeNode.objectcode, true);
            this.deleteProtectionData(this.activeNode.objectcode, true);

        }

        this.items = this.getDefaultTreeList();
        // this.cableCalculationStandard = '0';
        // $('#CableCalcStandard').val('0').trigger('change', "true");
        $('#selectNetwork').val('1');
        this.UserWizard = false;
        this.CableSize["isderatingwiz"] = "0";
        // $('#checkboxUseWizard').removeAttr('checked');
        $('#deratingFactor').val(1);
        this.CableSize['deratingfactor'] = 1;

        this.selectchild(1);
        this.tree.treeModel.getNodeById(2).setActiveAndVisible();
        this.networksupplytype = supplyType;
        let obj = this.NetworkSupplyTypeList.find(m => m.id == this.networksupplytype);
        this.onddlNetworkSupplyTypeChange(obj, "false");
        // $('#networksupplytype').val(supplyType).trigger('change', "false");
        // this.showLoader();
        let cableRecords = JSON.parse(this.tempinputXML.CableSizeCalcInputs);
        // this.cableCalculationStandard = e.target.value;
        if (cableRecords.length > 0) {
            cableRecords.forEach(element => {
                element.CableSizeCalcInputXML['calculationstandard'] = this.cableCalculationStandard;
            });
            this.tempcablesizelist = [];
            this.tempcablesizelist = cableRecords;
            this.tempinputXML.CableSizeCalcInputs = JSON.stringify(cableRecords);
        }
        if (this.cableCalculationStandard == '0' || this.cableCalculationStandard == '1')
            this.protectionCoordinationStandard = '0';
        else
            this.protectionCoordinationStandard = '1';


        this.IsCableCalcStandardChanged = false;
        this.oldCableCalcProtectiveDeviceSource = this.cableCalcProtectiveDeviceSource;
        this.activeNode = undefined;
        this.cd.detectChanges();
        this.activeNode = this.tree.treeModel.getNodeById(2).data;
        this.saveDefaultProtectiveDetail(this.activeNode.objectcode, () => {
            this.index = 0;
            this.getNetworkProtectiveDataByNodeId(this.newCalculationId, this.activeNode.objectcode);
        });// Add default data to ECPNetworkProtectiveCalcDetail table for this node

        // this.onInputChange();
        //this.resetControlValues();        
    }

    onProtectiveUnknownChange(isFromCode: boolean = false) {
        this.Isfromunknownchk = false;
        this.CableSize['protection']['IsUnknown'] = this.protectiveUnknown;
        this.updatecablesizeinarray();
        this.onInputChange();
        if (this.protectiveUnknown == true) {

        }
        else {
            if (!isFromCode) {
                this.Isfromunknownchk = true;
                this.showProtectiveDeviceUI('1');
            }
        }
    }

    onProtectiveRcdChange() {
        if (this.RCD == true) {
            this.CableSize['protection']['rcd'] = "1";
        }
        else {
            this.CableSize['protection']['rcd'] = "0";
        }

        this.updatecablesizeinarray();
        this.onInputChange();
    }

    saveDefaultProtectiveDetail(nodeId: string | undefined, callback?: any) {
        var objinput = new ListProtectiveInput();
        objinput.calculationid = this.newCalculationId;
        objinput.nodeid = nodeId;
        var protectiveXmlInput = new ProtectiveXmlInput();
        protectiveXmlInput.xml = "";
        protectiveXmlInput.model = 100;
        protectiveXmlInput.manufacturer_id = 104;
        protectiveXmlInput.range_id = "100 MCB";
        protectiveXmlInput.tripunit = "B";
        protectiveXmlInput.rating = "100";
        protectiveXmlInput.chartdata = '';
        protectiveXmlInput.id = 0;
        protectiveXmlInput.rownumber = this.protectiveXmlInputList.length;
        protectiveXmlInput.devicenostr = "Device 01";
        protectiveXmlInput.nodeid = nodeId;
        objinput.devices = [protectiveXmlInput];
        this._settingService.storeNetworkProtectiveXml(objinput).subscribe(result => {
            // this.ProtectiveLoadStorage = undefined;
            // this.ListLoadStorage = [];
            if (callback)
                callback();
        },
            err => {
                if (callback)
                    callback();
            });
    }

    protectiveConfigurationAccept() {
        // (<HTMLElement>document.querySelector("#ulcablesize").querySelector("#aCableSize")).click();
        if (!this.isFromCoordination) {
            if ($("#devicesettingli").css('display') != "none" && this.isProtectiveConfigChanged == true) {
                this.coordinationloader = true;
                this._settingService.updateNetworkProtectiveXml({ calculationid: this.newCalculationId, nodeid: this.activeNode.objectcode, xml: this.xmlData_Original }).subscribe(result => {
                    if (result) {
                        this.coordinationloader = false;
                        $('#protectiveConfiguration').modal('hide');
                    }
                }, (err) => {

                }, () => {
                    this.coordinationloader = false;
                });
            }
            else
                $('#protectiveConfiguration').modal('hide');
        }
        else {
            this.coordinationloader = true;
            this._settingService.UpdateNetworkProtectionCoordinationXml({ calculationid: this.newCalculationId, xmlData: this.protectiveXmlInputList }).subscribe(result => {
                if (result == "true") {
                    this.protectiveDrpList.forEach(protectionObj => {
                        if (protectionObj.isXmlUpdated == true) {
                            let tempnode = this.tempcablesizelist.find(m => m.Node_Prefix == protectionObj.Node_Prefix);
                            if (tempnode) {
                                tempnode.CableSizeCalcInputXML['protection']['Xml'] = protectionObj.Xml;
                                tempnode.CableSizeCalcInputXML['protection']['TrippingXml'] = protectionObj.TrippingXml;
                                let node = this.protectiveXmlInputList.find(m => m.nodeid == protectionObj.Node_Prefix);
                                if (node) {
                                    tempnode.DeviceName = node.devicenostr;
                                }
                            }
                        }
                    });
                    this.tempinputXML.CableSizeCalcInputs = JSON.stringify(this.tempcablesizelist);
                    this.coordinationloader = false;
                    $('#protectiveConfiguration').modal('hide');
                }
            }, (err) => {

            }, () => {
                this.coordinationloader = false;
            });
        }
        if (!this.isFromCoordination) {
            this.CableSize['protection']['protectionConfigurationModel'] = this.protectionConfigurationModel;
            this.protectionConfigurationModel_Pristine = Object.assign({}, this.protectionConfigurationModel);
            this.trippingCurrentAtSpecifiedTime_Original = this.trippingCurrentAtSpecifiedTime;

            let trippingtime;

            if (this.protectionConfigurationModel.MaxDisconnectionTime == "Custom")
                trippingtime = this.protectionConfigurationModel.CustomTime;
            else
                trippingtime = this.protectionConfigurationModel.MaxDisconnectionTime;

            let update_Tripping_Xml = this.Tripping_Xml_Original;
            update_Tripping_Xml = update_Tripping_Xml.replace("[Tripping_time]", trippingtime);
            this.xmlData_Tripping = update_Tripping_Xml;
            this.CableSize['protection']['TrippingXml'] = update_Tripping_Xml;


            this.updatecablesizeinarray();
            this.onInputChange();
        }
    }

    protectiveConfigurationCancel() {
        if ($("#devicesettingli").css('display') != "none" && this.isProtectiveConfigChanged == true) {
            this.index = 0;
            this.getNetworkProtectiveDataByNodeId(this.newCalculationId, this.activeNode.objectcode);
        }
        // (<HTMLElement>document.querySelector("#ulcablesize").querySelector("#aCableSize")).click();
        this.protectionConfigurationModel = Object.assign({}, this.protectionConfigurationModel_Pristine);
        this.trippingCurrentAtSpecifiedTime = this.trippingCurrentAtSpecifiedTime_Original;
    }

    onProtectiveCustomTimeChange(e: any) {
        this.CommonValidationFn(e);
        this.Allowdecimalwithplaces(e, 2);
        this.protectionConfigurationModel.CustomTime = e.target.value;

        let trippingtime;
        if (!this.protectionConfigurationModel.MaxDisconnectionTime)
            trippingtime = "0.4";
        else {
            if (this.protectionConfigurationModel.MaxDisconnectionTime == "Custom")
                trippingtime = this.protectionConfigurationModel.CustomTime;
            else
                trippingtime = this.protectionConfigurationModel.MaxDisconnectionTime;
        }

        // let update_Tripping_Xml = this.Tripping_Xml_Original;
        // update_Tripping_Xml = update_Tripping_Xml.replace("[Tripping_time]", trippingtime);
        // this.xmlData_Tripping = update_Tripping_Xml;
        if (this.ListProtectiveInputCurrentObj)
            this.ListProtectiveInputCurrentObj.trippingtime = trippingtime;
        // this.updatecablesizeinarray();
        // this.onInputChange();
        this.callProtectiveXmlWithExisting();
    }

    onEarthinSystemChange() {
        $(document).off('change', '#drpEarthingSystem');
        $('#drpEarthingSystem').on('change', (e: any, isTriggered) => {

            if (isTriggered === "true") {

            }
            else {
                this.protectionConfigurationModel.EarthingSystem = (e.target as HTMLOptionElement).value;
                this.getMaxDisconnectionTimeOptions();
                this.protectionConfigurationModel.MaxDisconnectionTime = this.MaxDisconnectionTimeOptions[0].id;
                $('#maxDisconnectionTime').val(this.protectionConfigurationModel.MaxDisconnectionTime).select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                }).trigger('change', "true");
            }
        });
    }

    onMaxDisconnectionTimeChange() {
        $('#maxDisconnectionTime').on('change', (e: any, isTriggered) => {
            if (isTriggered === "true") {
                let trippingtime;

                if (this.protectionConfigurationModel.MaxDisconnectionTime == "Custom")
                    trippingtime = this.protectionConfigurationModel.CustomTime;
                else
                    trippingtime = this.protectionConfigurationModel.MaxDisconnectionTime;

                // let update_Tripping_Xml = this.Tripping_Xml_Original;
                // update_Tripping_Xml = update_Tripping_Xml.replace("[Tripping_time]", trippingtime);
                // this.xmlData_Tripping = update_Tripping_Xml;
                if (this.ListProtectiveInputCurrentObj)
                    this.ListProtectiveInputCurrentObj.trippingtime = trippingtime;
                // this.updatecablesizeinarray();
                // this.onInputChange();
                this.callProtectiveXmlWithExisting();
            }
            else {

                this.protectionConfigurationModel.MaxDisconnectionTime = (e.target as HTMLOptionElement).value;

                if (this.protectionConfigurationModel.MaxDisconnectionTime != "Custom")
                    this.protectionConfigurationModel.CustomTime = undefined;
                else {
                    this.protectionConfigurationModel.CustomTime = "0.4";
                }
                let trippingtime;
                if (!this.protectionConfigurationModel.MaxDisconnectionTime)
                    trippingtime = "0.4";
                else {
                    if (this.protectionConfigurationModel.MaxDisconnectionTime == "Custom")
                        trippingtime = this.protectionConfigurationModel.CustomTime;
                    else
                        trippingtime = this.protectionConfigurationModel.MaxDisconnectionTime;
                }

                // let update_Tripping_Xml = this.Tripping_Xml_Original;
                // update_Tripping_Xml = update_Tripping_Xml.replace("[Tripping_time]", trippingtime);
                // this.xmlData_Tripping = update_Tripping_Xml;
                if (this.ListProtectiveInputCurrentObj)
                    this.ListProtectiveInputCurrentObj.trippingtime = trippingtime;
                // this.updatecablesizeinarray();
                // this.onInputChange();
                this.callProtectiveXmlWithExisting();
            }


            if (this.deviceType == "3") {
                // this.updatem('#pickupcurrmgpr')

            }
            else if (this.deviceType == "2") {
                // this.updateTm('#pickupcurrth', $event);
            }
            else if (this.deviceType == "1") {
                // this.updateElectronic('#current-tolerance-range',$event);            
            }
        });
    }


    convertBase64ToBlobData(base64Data: string, contentType: string = 'image/png', sliceSize = 512) {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    handleEvent(event: any) {

        if (event.name == 'download') {
            const blobData = this.convertBase64ToBlobData(this.sldbase64string);
            if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
                window.navigator.msSaveOrOpenBlob(blobData, 'SingleLineDiagram_IMAGE.png');
            } else { // chrome
                // const blob = new Blob([blobData], { type: 'image/png' });
                // const url = window.URL.createObjectURL(blobData);
                const linkSource = 'data:image/png;base64,' + this.sldbase64string;
                // window.open(url);
                const link = document.createElement('a');
                link.href = linkSource;
                link.download = 'SingleLineDiagram_IMAGE.png';
                link.click();
            }
        }
        else if (event.name == 'reset') {
            $("ngx-image-viewer .img-container img").css("transform", "");
        }
    }


    imageViewerConfig = {
        btnClass: 'default', // The CSS class(es) that will apply to the buttons
        zoomFactor: 0.1, // The amount that the scale will be increased by
        containerBackgroundColor: '#fff', // The color to use for the background. This can provided in hex, or rgb(a).
        wheelZoom: true, // If true, the mouse wheel can be used to zoom in
        allowFullscreen: true, // If true, the fullscreen button will be shown, allowing the user to entr fullscreen mode
        allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
        btnIcons: { // The icon classes that will apply to the buttons. By default, font-awesome is used.
            zoomIn: 'fa fa-plus',
            zoomOut: 'fa fa-minus',
            rotateClockwise: 'fa fa-repeat',
            rotateCounterClockwise: 'fa fa-undo',
            fullscreen: 'fa fa-arrows-alt',
        },
        customBtns: [
            { name: 'reset', icon: 'fa fa-refresh' },
            { name: 'download', icon: 'fa fa-download' }
        ],
        btnShow: {
            zoomIn: true,
            zoomOut: true,
            rotateClockwise: false,
            rotateCounterClockwise: false,
            next: false,
            prev: false
        }
    }

    deleteMaxDemandLoad() {
        this.isMaxDemandDataShown = true;
        let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));
        let maxDemandData = this.maxDemandDataArray.find(x => x.Node_Id === selectednode.id || x.Node_Prefix == selectednode.objectcode);

        if (maxDemandData !== undefined && maxDemandData !== null) {
            maxDemandData.LoadItemsData = maxDemandData.LoadItemsData.filter(x => x.id !== this.deleteLoadId);
        }

        this.onInputChange();
        $('#delete-model').modal('hide');
        // if (maxDemandData.LoadItemsData.length == 0) {
        //     this.PhaseR = '0';
        //     this.PhaseW = '0';
        //     this.PhaseB = '0';
        //     this.MaximumDemand = '0';
        //     this.PhaseImbalance = '0';
        // }
        this.deleteLoadId = 0;
    }

    ArcingCurrentsChange(e) {
        var data = this.chartData;
        var dimsize = this.dimSizeData;
        this.getChartLatest(data, dimsize);
    }

    FaultCurrentsChange() {
        var data = this.chartData;
        var dimsize = this.dimSizeData;
        this.getChartLatest(data, dimsize);
    }

    CableDamageChange() {
        var data = this.chartData;
        var dimsize = this.dimSizeData;
        this.getChartLatest(data, dimsize);
    }

    TransformerDamageChange() {
        var data = this.chartData;
        var dimsize = this.dimSizeData;

        this.CalculationReportInput.damagetime = null;
        this.CalculationReportInput.damagecurrent = null;
        this.getChartLatest(data, dimsize);
    }

    getNextValueForCurve(list, lastUseIndex) {
        for (let index = lastUseIndex; index > 0; index--) {
            if (list[index][0] != list[lastUseIndex][0]) {
                if (this.arcCurveValue && list[index][0] > this.arcCurveValue) {
                    return list[index - 2][0];
                }
                else if (!this.arcCurveValue)
                    return list[index - 2][0];
            }

        }
    }

    onArcFlashDataUpdate(arcFlashData: ArcFlashData) {
        this.CableSize['arcFlashData'] = arcFlashData;
        let selectednode = JSON.parse(sessionStorage.getItem("currentnode"));
        if (selectednode) {
            this.tempcablesizelist.forEach(
                itemnode => {
                    if (itemnode.Node_Id == selectednode.id || itemnode.Node_Prefix == selectednode.objectcode) {
                        itemnode.CableSizeCalcInputXML['arcFlashData'] = this.CableSize['arcFlashData'];

                    }
                });
            this.tempinputXML.CableSizeCalcInputs = JSON.stringify(this.tempcablesizelist);
            this.onInputChange();
        }
    }

    onddlDeviceChange(value, isTriggered: any = "false", isFromTreeNode: boolean = false) {
        let val: string = '';
        let device = this.devices.find(m => m.id == value);
        if (value === '0') {
            val = 'Unknown';
            if (isTriggered !== "true") {
                this.CableSize['protection'] = {
                    shortcircuittime: '0.1', MCBrating: '0', ACBrating: '0', HRCFuserating: '0', MCCBrating: '0',
                    curve: '0', earthfaulttime: '0', rcd: '0', tripcurrent: '0', tripmultiplier: '0', thermalsetting: '0', customrating: '0', description: '0',
                    IsUnknown: undefined, Xml: undefined, TrippingXml: undefined, protectionConfigurationModel: {}
                }
                this.CableSize['device'] = value;
                this.CableSize['devicename'] = device.text;

                this.updatecablesizeinarray();
                this.onInputChange(isFromTreeNode);
            }
        } else if (value === '1') {
            val = 'MCB';
        } else if (value === '2') {
            val = 'MCCB';
        } else if (value === '3') {
            val = 'ACB';
        } else if (value === '4') {
            val = 'HRC Fuse';
        } else if (value === '5') {
            val = 'Custom';
        }
        this.CableSize['device'] = value;
        this.CableSize['devicename'] = device.text;

        if (isTriggered !== "true") {
            this.cableSizeReopenData = null;
            this.isCableSizeModified = true;
            this.isCableSizeCalcStatus = true;
            this.isCableCalValue = false;
            this.isCableProtectionValue = true;
            this.updatecablesizeinarray();
        }
        else {
            this.isCableSizeModified = false;
            this.isCableSizeCalcStatus = false;
            this.isCableCalValue = true;
            this.isCableProtectionValue = false;

        }
        if (this.flag !== true && isTriggered !== true) {
            this._calculationService.setPrevValue('-1').subscribe((result) => {
                // var armour = 0;
                // if ($('#armour').length > 0 && $('#armour').val())
                //     armour = parseInt((<any>$('#armour')).val());
                this.GetCableSizeData('protection', '', 0, 0, 0, 0, 0, 0, 0, '', val, this.newCalculationId, 0, 0, this.CableproInput.ArmourId, null, isFromTreeNode, this.CableproInput.EarthConductorId);

            }, error => { });
        }
        setTimeout(function () {
            $('#protection').find('select').each(function () {
                $(this).select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });
            });
        }, 1000);/**/
        this.showHideProtectionWarning();
    }

    onddlActiveChange(e) {
        this.CableproInput.ActiveId = e.id;
        this.PrevActiveValue = e.value;
        this.CableSize['specifyconductor']['phase']['mode'] = '1';
        this.CableSize['specifyconductor']['phase']['size'] = parseFloat(this.PrevActiveValue.replace(' mm²', ''));

        let ActiveValue = this.PrevActiveValue.trim();

        let val = this.specifyConductorData.filter(x => x.value === ActiveValue);
        if (val.length > 0) {
            this.CableproInput.ActiveId = val[0].id;
            this.active_res = this.PrevActiveValue;
            this.updatecablesizeinarray();
            this.onInputChange();

        }
    }

    onddlNeutralChange(e) {
        this.CableproInput.NeutralId = e.id;
        this.PrevNeutralValue = e.value;
        this.CableSize['specifyconductor']['neutral']['mode'] = '1';
        this.CableSize['specifyconductor']['neutral']['size'] = parseFloat(this.PrevNeutralValue);

        let ActiveValue = this.PrevNeutralValue.trim();

        let val = this.specifyConductorData.filter(x => x.value === ActiveValue);

        if (val.length > 0) {
            this.CableproInput.NeutralId = val[0].id;
            this.neutral_res = this.PrevNeutralValue;
            this.updatecablesizeinarray();
            this.onInputChange();
        }
    }

    onddlEarthChange(e) {
        this.CableproInput.EarthId = e.id;
        let val = e.text;
        this.CableSize['specifyconductor']['earth']['mode'] = '2';
        this.CableSize['specifyconductor']['earth']['size'] = parseFloat(val);

        this.earth_res = val;
        this.updatecablesizeinarray();
        this.onInputChange();
    }

    onddlInsulationChange(e, isTriggered: any = "false") {
        this.CableproInput.InsulationId = parseInt(e.id);
        this.isCableSectionValue = false;
        this.isInstallationStatus = false;
        this.isInsulationChange = false;
        if (isTriggered === "true") {

            this.isCableSectionValue = true;
            this.isInstallationStatus = true;

            if ((this.CableproInput.InsulationId == 102 && this.PrevConductorId == 2) || (this.CableproInput.InsulationId == 105 && this.PrevConductorId == 2) || (this.CableproInput.InsulationId == 106 && this.PrevConductorId == 2)) {
                this.PrevConductorId = 0;
            }
            if ((this.CableproInput.InsulationId == 100 && this.PrevConductorId == 3) || (this.CableproInput.InsulationId == 103 && this.PrevConductorId == 3) || (this.CableproInput.InsulationId == 104 && this.PrevConductorId == 3) || (this.CableproInput.InsulationId == 105 && this.PrevConductorId == 3) || (this.CableproInput.InsulationId == 106 && this.PrevConductorId == 3) || (this.CableproInput.InsulationId == 107 && this.PrevConductorId == 3)) {
                this.PrevConductorId = 0;
            }
            this.UserWizard = false;
            this.CableSize["isderatingwiz"] = "0";
            var insulationID = this.CableproInput.InsulationId.toString();
            // $('#checkboxUseWizard').removeAttr('checked');
            setTimeout(function () {
                $('#windspeed').select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });
            }, 800);
            if (this.CableproInput.InsulationId === 107 && this.cableCalculationStandard != '2') {
                this.hasWind = true;
                $('#windspeed').select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });
            }
            else {
                this.hasWind = false;
            }
        }
        else {

            this.isNewNodeAdded = false;
            this.isCableSizeCalcStatus = false;
            this.isInsulationChange = true;
            if ((this.CableproInput.InsulationId == 102 && this.PrevConductorId == 2) || (this.CableproInput.InsulationId == 105 && this.PrevConductorId == 2) || (this.CableproInput.InsulationId == 106 && this.PrevConductorId == 2)) {
                this.PrevConductorId = 0;
            }
            if ((this.CableproInput.InsulationId == 100 && this.PrevConductorId == 3) || (this.CableproInput.InsulationId == 103 && this.PrevConductorId == 3) || (this.CableproInput.InsulationId == 104 && this.PrevConductorId == 3) || (this.CableproInput.InsulationId == 105 && this.PrevConductorId == 3) || (this.CableproInput.InsulationId == 106 && this.PrevConductorId == 3) || (this.CableproInput.InsulationId == 107 && this.PrevConductorId == 3)) {
                this.PrevConductorId = 0;
            }
            this.UserWizard = false;
            this.CableSize["isderatingwiz"] = "0";
            // $('#checkboxUseWizard').removeAttr('checked');
            this.CableSize['insulation'] = e.xmlValue.toString()
            this.updatecablesizeinarray();
            // var armour = 0;
            // if ($('#armour').length > 0 && $('#armour').val())
            //     armour = parseInt((<any>$('#armour')).val());
            this.GetCableSizeData('insulation', e.id, 0, 0, 0, this.CableproInput.PhaseId, this.CableproInput.MethodIndex, 0, 0, '', '', this.newCalculationId, this.PrevConductorId, this.PrevCoreId, this.CableproInput.ArmourId, null, false, this.CableproInput.EarthConductorId);
            setTimeout(function () {
                $('#windspeed').select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });
            }, 800);
            if (this.CableproInput.InsulationId === 107 && this.cableCalculationStandard != '2') {
                this.hasWind = true;
                $('#windspeed').select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });
            }
            else {
                this.hasWind = false;
            }
        }
    }

    onddlConductorChange(value, isTriggered: any = "false") {
        this.CableproInput.ConductorId = value;
        this.isCableSectionValue = false;
        this.isInstallationStatus = false;
        if (isTriggered === "true") {
            this.isInstallationStatus = true;
            this.isCableSectionValue = true;
            this.CableSize['conductor'] = this.PrevConductorId.toString();
            setTimeout(function () {
                $('#windspeed').select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });
            }, 800);
        }
        else {
            let obj = this.conductorData.find(m => m.id == value);
            this.isNewNodeAdded = false;
            this.isCableSizeCalcStatus = false;
            // let d = (e.target as HTMLSelectElement).options
            this.PrevConductorId = parseInt(obj.xmlValue.toString());
            // this.CableproInput.ConductorId = parseInt((e.target as HTMLOptionElement).value);
            this.CableSize['conductor'] = obj.xmlValue.toString();
            this.updatecablesizeinarray();
            // var armour = 0;
            // if ($('#armour').length > 0 && $('#armour').val())
            //     armour = parseInt((<any>$('#armour')).val());
            this.GetCableSizeData('conductor', value, this.CableproInput.InsulationId, 0, 0, this.CableproInput.PhaseId, this.CableproInput.MethodIndex, 0, 0, '', '', this.newCalculationId, 0, this.PrevCoreId, this.CableproInput.ArmourId, null, false, this.CableproInput.EarthConductorId);
            setTimeout(function () {
                $('#windspeed').select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });
            }, 800);
        }
    }
    onddlEarthConductorChange(value, isTriggered: any = "false") {
        this.CableproInput.EarthConductorId = value;
        this.isCableSectionValue = false;
        this.isInstallationStatus = false;
        if (isTriggered === "true") {
            this.isInstallationStatus = true;
            this.isCableSectionValue = true;
            this.CableSize['earthconductor'] = this.PrevEarthConductorId.toString();
            setTimeout(function () {
                $('#windspeed').select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });
            }, 800);
        }
        else {
            this.isNewNodeAdded = false;
            this.isCableSizeCalcStatus = false;
            this.PrevEarthConductorId = value;
            this.CableSize['earthconductor'] = value;
            this.updatecablesizeinarray();
            this.getAllControlsValue();
            // this.GetCableSizeData('earthconductor', value, this.CableproInput.InsulationId, 0, 0, this.CableproInput.PhaseId, this.CableproInput.MethodIndex, 0, 0, '', '', this.newCalculationId, 0, this.PrevCoreId, this.CableproInput.ArmourId,null,false,0);
            // setTimeout(function () {
            //     $('#windspeed').select2({
            //         width: '100%',
            //         minimumResultsForSearch: Infinity // disable search
            //     });
            // }, 800);
        }
    }
    onddlCoreChange(e, isTriggered: any = "false") {
        this.CableproInput.CoreId = parseInt(e.id);
        this.isInstallationStatus = false;
        this.isCableSectionValue = false;
        if (isTriggered === "true") {
            this.isInstallationStatus = true;
            this.isCableSectionValue = true;
            let bunchingCore: string = this.PrevCoreId.toString();
            this.CableSize['core'] = bunchingCore;
            if (bunchingCore === "0") {
                this.drBunchingCore = 1;
            }
            else {
                this.drBunchingCore = 2;
            }
            if (this.drInstallType == 1) {
                this.table23model.Row = 1;
                this.table23model.Cables = 1;
                this.table23model.Installation = 1;
                this.table23model.CableType = this.drBunchingCore;
                this.table23model.drf = this.drBunchingCore == 1 ? 0.95 : 0.97;
                this.OnChangeCore(null);
                this.GetTable23Dr(null);
            }
            else {
                if (this.table25model.NoOfCircuits > 1) {
                    this.table25model.CableType = this.drBunchingCore;
                    this.setTable25Dr();
                }
                if (!this.table28model.UseStandard && this.table28model.Buried == 2)
                    this.GetTable28Dr(null);
            }
            this.UserWizard = false;
            this.CableSize["isderatingwiz"] = "0";
            var coreID = this.CableproInput.CoreId.toString();
            // $('#checkboxUseWizard').removeAttr('checked');
            setTimeout(function () {
                $('#windspeed').select2({
                    width: '100%',
                    minimumResultsForSearch: Infinity // disable search
                });
            }, 800);
        }
        else {
            this.isNewNodeAdded = false;
            this.isCableSizeCalcStatus = false;
            // let d = (e.target as HTMLSelectElement).options
            this.PrevCoreId = parseInt(e.xmlValue.toString());
            let bunchingCore: string = e.xmlValue.toString();
            this.CableSize['core'] = bunchingCore;
            if (bunchingCore === "0") {
                this.drBunchingCore = 1;
            }
            else {
                this.drBunchingCore = 2;
            }
            if (this.drInstallType == 1) {
                this.table23model.Row = 1;
                this.table23model.Cables = 1;
                this.table23model.Installation = 1;
                this.table23model.CableType = this.drBunchingCore;
                this.table23model.drf = this.drBunchingCore == 1 ? 0.95 : 0.97;
                this.OnChangeCore(null);
                this.GetTable23Dr(null);
            }
            else {
                if (this.table25model.NoOfCircuits > 1) {
                    this.table25model.CableType = this.drBunchingCore;
                    this.setTable25Dr();
                }
                if (!this.table28model.UseStandard && this.table28model.Buried == 2)
                    this.GetTable28Dr(null);
            }
            this.updatecablesizeinarray();
            this.UserWizard = false;
            this.CableSize["isderatingwiz"] = "0";
            // $('#checkboxUseWizard').removeAttr('checked');
            //debugger;
            // var armour = 0;
            // if ($('#armour').length > 0 && $('#armour').val())
            //     armour = parseInt((<any>$('#armour')).val());
            this.GetCableSizeData('core', e.id.toString(), this.CableproInput.InsulationId, this.CableproInput.ConductorId, 0, this.CableproInput.PhaseId, this.CableproInput.MethodIndex, 0, 0, '', '', this.newCalculationId, 0, 0, this.CableproInput.ArmourId, null, false, this.CableproInput.EarthConductorId);
        }
    }

    onddlWindChange(e) {
        this.CableproInput.WindSpeedId = parseInt(e.id);
        this.CableSize['installtablewind']['wind'] = e.xmlValue.toString();
        this.updatecablesizeinarray();
        this.getAllControlsValue();
    }

    onddldrTable22ArrangementChange(e) {
        this.table22model.Arrangement = parseInt(e.value);
        this.GetTable22Dr(e);
        if (this.table22model.Arrangement === 1) {
            this.drTable22CircuitValues = [{ "name": 1, "value": 1 },
            { "name": 2, "value": 2 },
            { "name": 3, "value": 3 },
            { "name": 4, "value": 4 },
            { "name": 5, "value": 5 },
            { "name": 6, "value": 6 }];
        }
        else {
            this.drTable22CircuitValues = [
                { "name": 1, "value": 1 },
                { "name": 2, "value": 2 },
                { "name": 3, "value": 3 },
                { "name": 4, "value": 4 },
                { "name": 5, "value": 5 },
                { "name": 6, "value": 6 },
                { "name": 7, "value": 7 },
                { "name": 8, "value": 8 },
                { "name": 9, "value": 9 },
                { "name": 10, "value": 10 },
                { "name": 12, "value": 12 },
                { "name": 14, "value": 14 },
                { "name": 16, "value": 16 },
                { "name": 18, "value": 18 },
                { "name": "20 or more", "value": 20 }];
        }
        if (this.table22model.NoOfCircuits > 6 && this.table22model.Arrangement == 1) {
            this.table22model.NoOfCircuits = 6;
        }
    }

    onddldrTable22NoOfCircuitsChange(e) {
        this.table22model.NoOfCircuits = parseInt(e.value);
        if (this.table22model.NoOfCircuits == 1) {
            this.table22model.drf = 1;
            this.drTable22output = undefined;
            return false;
        }
        this.GetTable22Dr(null);
    }

    onddldrTable23RowChange(e) {
        this.table23model.Row = e.value;
        setTimeout(() => {
            this.GetTable23Dr(null);
        }, 800);
    }

    onddldrTable23CableChange(e) {
        this.table23model.Cables = parseInt(e.value);
        setTimeout(() => {
            this.GetTable23Dr(null);
        }, 800);
    }

    onddldrTable25NoOfCicruitsChange(e) {
        this.table25model.NoOfCircuits = parseInt(e.value);
        if (this.table25model.NoOfCircuits == 1) {
            this.table25model.drf = 1;
            this.drTable25output = undefined;
            this.setTable25Dr();
            $('#divburiedGroupArrangement').css('display', 'none');
            return false;
        }

        $('#divburiedGroupArrangement').css('display', 'block');

        setTimeout(() => {
            this.setTable25Dr();
        }, 500);
    }

    onddldrTable25ArrangementChange(e) {
        this.table25model.Arrangement = parseInt(e.value);
        this.setTable25Values(e.value);
    }

    onddldrTable28CondutorSizeChange(e) {
        this.table28model.ConductorSize = parseInt(e.value);
        this.setTable28Dr();
    }

    onddldrTable29InstallationChange(e) {
        this.table29model.Installation = parseInt(e.value);
        this.GetTable29Dr(null);
    }

    onddldrTable271ConductorChange(e) {
        this.table271model.ConductorTemp = parseInt(e.value);

        if (this.drInstallType == 2)
            $('#inputUseStandardSoil').removeAttr('disabled');
        else {
            if (this.drBunchingType == 1)
                $('#inputUseStandardAir1').removeAttr('disabled');
            else
                $('#inputUseStandardAir2').removeAttr('disabled');
        }

        var standard = parseInt(this.CableSize['calculationstandard']);

        if ((this.table271model.ConductorTemp > 100 && this.table271model.AbientTemp > this.table271model.ConductorTemp - 10) ||
            (this.table271model.ConductorTemp < 100 && this.table271model.AbientTemp > this.table271model.ConductorTemp - 5)) {

            if (this.table271model.ConductorTemp > 100) {
                this.table271model.AbientTemp = this.table271model.ConductorTemp - 10;
            }
            else {
                this.table271model.AbientTemp = this.table271model.ConductorTemp - 5;
            }
        }
        if (this.table271model.AbientTemp < 15) {
            this.table271model.AbientTemp = 15;
        }
        this.drfService.getTable271(standard, this.table271model.ConductorTemp, this.table271model.AbientTemp, this.drInstallType).subscribe(result => {
            if (this.cableCalculationStandard == "2")
                this.table271model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
            else
                this.table271model.drf = parseFloat(result["Derating Factor:"]);
            if (this.table271model.UseStandard != true) {
                this.drTable271output = result;
            }
        });
    }

    onddldrTable271InsulationChange(e) {
        this.table271model.Insulation = parseInt(e.value);
        this.SetMinMaxAmbientTemp();
        this.drfServicebs.getTable271(2, this.table271model.Insulation, this.table271model.AbientTemp, this.drInstallType).subscribe(result => {
            this.table271model.drf = parseFloat(parseFloat(result["Correction Factor:"]).toFixed(2));
            if (this.table271model.UseStandard != true) {
                this.drTable271output = result;
            }
        });
    }

    onddlArmourChange(e) {
        this.CableproInput.ArmourId = parseInt(e.id);
        this.isCableSectionValue = false;
        this.isInstallationStatus = false;
        this.isNewNodeAdded = false;
        this.isCableSizeCalcStatus = false;
        this.PrevArmourId = e.xmlValue;
        this.CableSize['armour'] = e.xmlValue.toString();
        this.updatecablesizeinarray();
        this.GetCableSizeData('armour', this.CableproInput.ArmourId.toString(), this.CableproInput.InsulationId, this.CableproInput.ConductorId, this.CableproInput.CoreId, this.CableproInput.PhaseId, this.CableproInput.MethodIndex, this.CableproInput.InstallTableId, 0, '', '', this.newCalculationId, this.PrevConductorId, this.PrevCoreId, this.CableproInput.ArmourId, null, false, this.CableproInput.EarthConductorId);
    }

    onddlSupplyVoltageChange(e) {
        this.SupplyVoltage = e.id;
        this.onInputChange();
    }

    onddlFrequencyChange(e) {
        this.Frequency = e.id;
        this.onInputChange();
    }

    onddlVoltageToleranceChange(e) {
        this.VoltageTolerance = e.id;
        this.onInputChange();
    }

    onddlCableCalcStandardChange(newVal) {
        // this.cableCalculationStandard = e.id;
        if ((this.cableCalculationStandard == '0' || this.cableCalculationStandard == '1') && (newVal == '0' || newVal == '1')) {
            // this.showLoader();
            this.cableCalculationStandard = newVal;
            this.onEarthinSystemChange();
            this.ReportStandardCountry = this.cableCalculationStandard;
            if (this.ReportStandardCountry == "2") {
                this.isBritishStandard = true;
            }
            else
                this.isBritishStandard = false;
            let cableRecords = JSON.parse(this.tempinputXML.CableSizeCalcInputs);

            if (cableRecords.length > 0) {
                cableRecords.forEach(element => {
                    element.CableSizeCalcInputXML['calculationstandard'] = this.cableCalculationStandard;
                });
                this.tempcablesizelist = [];
                this.tempcablesizelist = cableRecords;
                this.tempinputXML.CableSizeCalcInputs = JSON.stringify(cableRecords);
            }
            this.onInputChange();
        }
        else {
            this.oldCableCalculationStandard = this.cableCalculationStandard;
            this.cableCalculationStandard = newVal;
            this.onEarthinSystemChange();
            this.ReportStandardCountry = this.cableCalculationStandard;
            if (this.ReportStandardCountry == "2") {
                this.isBritishStandard = true;
            }
            else
                this.isBritishStandard = false;
            this.showResetStandardPopup();
        }
    }

    onddlCableCalcProtectiveDeviceSourceChange(newVal) {
        this.oldCableCalcProtectiveDeviceSource = this.cableCalcProtectiveDeviceSource;
        this.cableCalcProtectiveDeviceSource = newVal;
        this.showChangeProtectiveDeviceSourcePopup();
    }

    onddlProtectionCoordinationStandardChange(newVal) {
        this.protectionCoordinationStandard = newVal;
        this.onInputChange();
    }

    onddlNetworkSupplyTypeChange(e, isTriggered: any = undefined) {
        this.networksupplytype = e.id;
        let oldObjectCode = this.items[0].objectcode;
        sessionStorage.setItem('SupplyType', "Supply");
        this.selectsupplychildren(e);

        if (isTriggered == undefined) {
            if (this.networksupplytype == 2 || this.networksupplytype == 4) {
                if (this.powerHVRelay || this.transformerHVRelay) {
                    this.powerHVRelay = false;
                    $('#checkSupplyHVRelay').prop('checked', false);
                    this.transformerHVRelay = false;
                    $('#PowerStationHVRelay').prop('checked', false);
                }
                if (this.HVFuse == true) {
                    this.HVFuse = false;
                    this.txtratingfuse = '';
                    this.txtmodalfuse = '';
                    this.txtfamilyfuse = '';
                    this.txtTripUnitfuse = '';
                    $('#HVFuseChk').prop('checked', false);
                    this.deleteProtectionData(oldObjectCode, false);
                }
            }
            else
            {
                this.HVFuse = false;
                $('#HVFuseChk').prop('checked', false);
                this.deleteProtectionData(oldObjectCode, false);
                this.txtratingfuse = '';
                this.txtmodalfuse = '';
                this.txtfamilyfuse = '';
                this.txtTripUnitfuse = '';   
            }
            if (this.cableCalcProtectiveDeviceSource == "1") {
                this.index = 0;
                this.getNetworkProtectiveDataByNodeId(this.newCalculationId, this.activeNode.objectcode,null,null,null,this.activeNode.id);
            }
        }
        if (isTriggered !== "true") {
            this.onInputChange();
        }
        this.supplyTypeReopenData = false;
    }

    private inputSubject = new Subject<any>();
    GetTable271DrInputChange(e: any, type: any = '', checkdecimalplaces: boolean = false) {
        this.inputSubject.next({ e: e, type: type, checkdecimalplaces: checkdecimalplaces });
    }

    ManageSub() {
        this.ManageSubscription(this.user, this.loaderService, this._subscriptionService, "subscription");
    }

    UpgradeSub() {
        this.UpgradeSubscription(this.user, this.loaderService, this._subscriptionService, this._companyAccountService);
    }
}